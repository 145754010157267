import {
  crudGetList,
  CRUD_GET_LIST_SUCCESS,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@imminently/imminently_platform";
import { all, takeLatest, put, select, delay, race, take } from "redux-saga/effects";


const defaultPagination = { perPage: 12, page: 1 };
const getCrudGetListWithPagination: (p?: Partial< typeof defaultPagination >) => any = (
  pagination = defaultPagination,
) => (
  crudGetList(
    "workspaces",
    "default",
    {
      pagination: {
        ...defaultPagination,
        ...pagination,
      },
    },
  )
);


export const ActionTypes = {
  requestToLoad: "workspaces/requestToLoad",
};

export interface IActions {
  requestToLoad: {
    type: typeof ActionTypes.requestToLoad;
    payload: (
      /**
       * this should be called as workspaces modal gets mounted and\
       * we want to hydrate it with some workspaces. It should always\
       * load first page of workspaces and not more. BUT it also \
       * has to trigger this action with type "fillScroll" (more \
       * details below)
       */
      | { type: "initial"; ref: React.MutableRefObject< HTMLDivElement > }
      /**
       * this should be called by "initial" typed action and its main \
       * purpose is to try and fill scroll area so that either there are\
       * no more workspaces, or scroll appears (otherwise if we have \
       * more workspaces but scroll is not visible - it's not clear that\
       * we should scroll to load more)
       */
      | { type: "fillScroll"; ref: React.MutableRefObject< HTMLDivElement > }
      | { type: "ref", ref: React.MutableRefObject< HTMLDivElement > }
    )
  }
}
export const ActionCreators = {
  requestToLoad: (
    payload: IActions[ "requestToLoad" ][ "payload" ],
  ): IActions[ "requestToLoad" ] => ({
    type: ActionTypes.requestToLoad,
    payload,
  }),
};


export function* saga(): Gen {
  yield all([
    takeLatest(ActionTypes.requestToLoad, function* onRequestToLoad(
      { payload }: IActions[ "requestToLoad" ],
    ): Gen {
      const state = yield select();
      const maybeList = state?.resources?.workspaces?.default?.default?.list || {
        loadedOnce: false,
        params: { pagination: defaultPagination },
        total: 0,
      };

      const { total, params: { pagination: { perPage, page } } } = maybeList;
      const numLoaded = perPage * page;

      const loadMorEffect = put(getCrudGetListWithPagination({ perPage: defaultPagination.perPage + perPage }));
      const raceGetListSuccess = race({
        timeout: delay(5_000),
        action: take(a => Boolean(a.type === CRUD_GET_LIST_SUCCESS && a.meta && a.meta.resource === "workspaces") )
      });
      const getFilterScrollEffect = (ref: React.MutableRefObject< HTMLDivElement >) => (
        put(ActionCreators.requestToLoad({ type: "fillScroll", ref }))
      );

      if (payload.type === "initial") {
        yield put(getCrudGetListWithPagination());
        yield raceGetListSuccess;
        yield getFilterScrollEffect(payload.ref);

        return;
      }


      if ( total <= numLoaded ) return;

      if ( payload.type === "fillScroll" ) {
        // allow repaint and reflow to happen as we are about to access
        // DOM sizes
        yield delay (0);

        const { ref } = payload;
        if ( ref.current.scrollHeight > ref.current.clientHeight ) return;

        yield loadMorEffect;
        yield raceGetListSuccess;
        yield getFilterScrollEffect(payload.ref);

        return;
      }


      yield loadMorEffect;

      yield delay(0);
      const { current } = payload.ref;
      current.scrollTop = current.scrollHeight - current.clientHeight;
    }),
  ]);
}
