import { Pane, SplitPane } from "@common/SplitPane";
import { SourceEditor, createSourcePlugins } from "@common/editor/RuleAuthor/SourceEditor";
import { FixedRichTextToolbar } from "@common/editor/RuleAuthor/toolbar/FixedToolbar";
import { FloatingToolbar } from "@common/editor/RuleAuthor/toolbar/FloatingToolbar";
import { RichTextToollbar } from "@common/editor/RuleAuthor/toolbar/components";
import { createSectionPlugin } from "@common/editor/components/section/createSectionPlugin";
import { scrollableMixin } from "@common/scrollbar";
import { PrimaryButton } from "@components/buttons";
import { config } from "@config";
import { LoadingDotsJSX } from "@icons";
import Box from "@material-ui/core/Box";
import ButtonBase from "@material-ui/core/ButtonBase";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { PlateProvider, type Value, createPlugins } from "@udecode/plate";
import { global } from "global";
import { once } from "lodash";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import type { SplitProps } from "react-split";
import styled from "styled-components";
import { SUGGEST_RULES_EDITOR_ID } from "./constants";

// this needs to be lazily initialized, otherwise it will cause a circular dependency
const createSuggestLeftPanePlugins = once(() =>
  createSourcePlugins([
    createSectionPlugin({
      options: { disableSuggestModals: true },
    }),
  ]),
);

//# region CollapsableArea

const StyledButtonBaseForCollapsableArea = styled(ButtonBase)`
  height: 100%;
  align-items: flex-end;

  .MuiTypography-root {
    transform: rotateZ( -90deg ) translateX( 50% );
  }
`;

type CollapsableAreaProps = React.PropsWithChildren<{
  collapsed: boolean;
  title: string;
  onCollapsedClick: () => unknown;
  className?: string;
}>;
const CollapsableArea = React.memo<CollapsableAreaProps>((p) => {
  const { collapsed, children, title, onCollapsedClick, className } = p;

  const delayedOnClick = React.useCallback(() => {
    // allow ripple animation to play forward a bit
    setTimeout(onCollapsedClick, 175);
  }, [onCollapsedClick]);

  const collapsedContents = (
    <StyledButtonBaseForCollapsableArea onClick={delayedOnClick}>
      <Typography variant="h6">{title}</Typography>
    </StyledButtonBaseForCollapsableArea>
  );

  return <Pane className={className}>{collapsed ? collapsedContents : children}</Pane>;
});
CollapsableArea.displayName = "suggest/SuggestRulesModal/LeftPane/CollapsableArea";

//# endregion

const StyledFixedRichTextToolbar = styled(FixedRichTextToolbar)`
  overflow: visible hidden;

  &::-webkit-scrollbar {
    width: 0.125rem;
    height: 0.125rem;
  }

  &::-webkit-scrollbar-thumb {
    border: none;
  }
`;

const Wrap = styled(Pane)`
  height: 100%;
`;

const PromptCollasableArea = styled(CollapsableArea)`
  &.hidden {
    display: none;
  }
`;
const PromptTextField = styled(TextField)`
  flex-grow: 1;

  .MuiInputBase-root {
    height: 100%;
  }

  .MuiOutlinedInput-input {
    ${scrollableMixin};
    height: 100% !important;
    overflow: auto !important;
  }
`;

export type SuggestRulesLeftPaneProps = {
  sourceLocal: Value;
  setSourceLocal: (v: Value) => unknown;
  prompt: string;
  setPrompt: (v: string) => unknown;
};

export const SuggestRulesLeftPane: React.FC<SuggestRulesLeftPaneProps> = React.memo((p) => {
  const { setSourceLocal, sourceLocal, prompt, setPrompt } = p;
  const { t } = useTranslation();
  const [mode, setMode] = React.useState<"source" | "prompt">("source");
  const setModePrompt = React.useCallback(() => setMode("prompt"), []);
  const setModeSource = React.useCallback(() => setMode("source"), []);

  const [isSaving, setIsSaving] = React.useState(false);

  const editableProps = React.useMemo(
    () => ({
      autoFocus: true,
      spellCheck: false,
    }),
    [],
  );

  // TODO use a proper ENV var when we add one
  // if BACKEND_URL includes dev or staging
  const showPrompt = config.BACKEND_URL.includes("dev") || config.BACKEND_URL.includes("staging");
  const hidePrompt = !showPrompt;

  const paneProps = React.useMemo<SplitProps>(() => {
    const collapsed = "40px";
    const expanded = `calc(100% - ${collapsed})`;
    const sizes = (() => {
      if (hidePrompt) {
        return [0, 100];
      }

      return mode === "source" ? [collapsed, expanded] : [expanded, collapsed];
    })();
    // typescript complains, but inline styles are propagated
    // correctly, so this is a forced typecast (I would much
    // rather not do it)
    const sizesTyped = sizes as unknown as number[];

    return {
      sizes: sizesTyped,
      gutterStyle: () => (hidePrompt ? { display: "none" } : { display: "block", width: "8px" }),
      snapOffset: 0,
      style: { height: "100%" },
    };
  }, [mode, hidePrompt]);

  const onSave = React.useCallback(() => {
    setIsSaving(true);

    global
      .client("/auth/updatePrompt", {
        method: "PATCH",
        /**
         * it seems that client does stringify internally, but\
         * expects BodyInit type, which is already stringified\
         * so then we stringify twice, and that's not good. Ugly,\
         * but necessary cast
         */
        body: { content: prompt } as unknown as BodyInit,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        void res;
        /**
         * success response returns with { message: 'ok' }, so \
         * not really sure what to do with this
         */
      })
      .finally(() => {
        setIsSaving(false);
      });
  }, [prompt, setIsSaving]);

  return (
    <Wrap>
      <SplitPane {...paneProps}>
        <PromptCollasableArea
          collapsed={mode !== "prompt"}
          title={t("ai.prompt")}
          onCollapsedClick={setModePrompt}
          className={hidePrompt ? "hidden" : undefined}
        >
          <Box
            display="flex"
            flexDirection="column"
            padding="0.5rem 1rem"
            gridGap="1rem"
            flex={1}
          >
            <Typography variant="h5">
              <Trans i18nKey="ai.prompt" />
            </Typography>

            <PromptTextField
              multiline
              variant="outlined"
              value={prompt}
              onChange={React.useCallback(({ currentTarget: { value } }) => setPrompt(value), [setPrompt])}
            />

            <Box
              display="flex"
              justifyContent="space-between"
            >
              <Typography style={{ maxWidth: "25rem" }}>
                <Trans i18nKey="ai.prompt_change_description" />
              </Typography>

              <PrimaryButton
                onClick={onSave}
                disabled={isSaving}
              >
                {isSaving ? LoadingDotsJSX : t("save")}
              </PrimaryButton>
            </Box>
          </Box>
        </PromptCollasableArea>

        <CollapsableArea
          collapsed={mode !== "source"}
          title="Source"
          onCollapsedClick={setModeSource}
        >
          <PlateProvider
            id={SUGGEST_RULES_EDITOR_ID}
            plugins={createSuggestLeftPanePlugins()}
            value={sourceLocal}
            onChange={setSourceLocal}
          >
            <StyledFixedRichTextToolbar />
            <SourceEditor
              id={SUGGEST_RULES_EDITOR_ID}
              editableProps={editableProps}
            >
              <FloatingToolbar>
                <RichTextToollbar />
              </FloatingToolbar>
            </SourceEditor>
          </PlateProvider>
        </CollapsableArea>
      </SplitPane>
    </Wrap>
  );
});
SuggestRulesLeftPane.displayName = "suggest/SuggestRulesModal/LeftPane";
