import { EditorState } from "@common/editor/RuleAuthor/multiEditor";
import { ELEMENT_TABLE } from "@udecode/plate-table";
import { EditorToolbarButton, type EditorToolbarButtonProps } from "../../plugins/muiToolbar";

// Table toolbar button needs to only be enabled if within a table

export type TableToolbarButtonProps = Omit<EditorToolbarButtonProps, "onClick" | "type"> & {
  transform: (editor: any, options?: any) => void;
  options?: any;
};

/**
 * Table Toolbar button to perform transforms on selection.
 */
export const TableToolbarButton = ({ transform, options, ...props }: TableToolbarButtonProps) => {
  const handleClick = (editor) => transform(editor, options);

  return (
    <EditorToolbarButton
      id={EditorState.rules}
      type={ELEMENT_TABLE}
      onClick={handleClick}
      {...props}
    />
  );
};
