import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
`;

const Background = styled.div`
  position: fixed;
  inset: 0;
  z-index: -1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const CenteredLayout = ({ page }: any) => {
  return (
    <Container>
      <Background>
        <img src="/bkgnd-min.png" alt="background" />
      </Background>
      {page}
    </Container>
  );
};

export default CenteredLayout;


