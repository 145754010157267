import { config } from "../config";
import moment from "moment";
import type { Scope } from "../redux/scope";


export type DeriveFileNameArg = {
  type: "models" | "releases" | "scenarios" | "debugRawPayload";
  id: string;
  storeState: any;
  fallbackProjectName?: string;
};

export function deriveFileName (arg: DeriveFileNameArg) {
  const { id, type, storeState: store, fallbackProjectName } = arg;

  if ( type === "scenarios" ) return id;

  const { scope, resources } = store;
  const typedScope = scope as Scope;

  const workspaceId = String(typedScope.workspace);
  const projectIdInScope = String(typedScope.project);

  const env = (() => {
    const url = String( config.BACKEND_URL );

    switch ( true ) {
      case Boolean( url.match( /\.dev\./ ) ): return "Development";
      case Boolean( url.match( /\.staging\./ ) ): return "Staging";
      default: return "Production";
    }
  })();
  const timestamp = moment().format("YYYY-MM-DD HHmmss");


  const maybeWorkspace = resources.workspaces.data[ workspaceId ];
  const maybeWorkspaceName = String( maybeWorkspace ? maybeWorkspace.name : "" );

  const maybeRelease = resources.releases.data[ id ];

  const effectiveProjectId = type === "models" ? id : (String( maybeRelease ? maybeRelease.model : '' ) || projectIdInScope);
  const maybeProject = resources.models.data[ effectiveProjectId ];
  const maybeProjectName = String( maybeProject ? maybeProject.name : ( fallbackProjectName || "" ) );

  const namePrefix = `${ maybeWorkspaceName } - ${ maybeProjectName }`;
  const nameSuffix = `${ env } - ${ timestamp }`;


  if ( type === "models" ) {
    return `${ namePrefix } - ${nameSuffix}`;
  }


  const maybeReleaseNum = String( maybeRelease ? maybeRelease.releaseNo : "" );

  return `${ namePrefix } - Release #${ maybeReleaseNum }${ type === "debugRawPayload" ? " - debugger" : "" } - ${ nameSuffix }`;
}
