import { useDispatch } from "react-redux";
import { useList, crudGetList } from '@imminently/imminently_platform'
import React, { useEffect, useState } from "react";
import { EmptyContent } from "@common";
import styled from "styled-components";
import "jsondiffpatch/dist/formatters-styles/html.css";
import { Skeleton } from "@material-ui/lab";
import { Grid } from "@material-ui/core";
import { ChangeHistoryTimeline } from './Timeline';
import { CurrentChange } from './CurrentChange'


const Wrap = styled.div`
  display: flex;
  gap: 0.5rem;
  height: 100%;
  overflow: auto;
`;


type ChangeHistoryModalCoreProps = {
  changes: any;
  setCurrentChange: (v: unknown) => unknown;
  currentChange: any;
  pagination: { page: number; perPage: number; };
  count: number;
  setCurrentPage: (num: number) => unknown;
}
const ChangeHistoryModalCore: React.FC< ChangeHistoryModalCoreProps > = p => {
  const { changes, currentChange, setCurrentChange, pagination, count, setCurrentPage } = p;

  return (
    <>
      <ChangeHistoryTimeline
        changes={ changes }
        setCurrentChange={ setCurrentChange }
        pagination={ pagination }
        count={ count }
        setCurrentPage={ setCurrentPage }
      />
      <CurrentChange currentChange={ currentChange } />
    </>
  );
};

const ChangeHistoryModal = ({release}) => {
  const dispatch = useDispatch();

  // ===================================================================================

  const [ pagination, setPagination ] = React.useState({ page: 1, perPage: 10 });
  const paginationRef = React.useRef(pagination);
  if(paginationRef.current !== pagination) paginationRef.current = pagination;

  const loadChanges = React.useCallback( (nextPagination?: typeof pagination) => {
    if (!release) return;

    dispatch( crudGetList( 'releases', 'changes', {
      subResource: 'changes',
      componentId: release.id,
      pagination: nextPagination || paginationRef.current,
    } ) );
  }, [ release ] );

  useEffect(() => {
    loadChanges();
  }, [release, loadChanges]);

  const setCurrentPage: ChangeHistoryModalCoreProps[ 'setCurrentPage' ] = React.useCallback(
    page => {
      const nextPagination: typeof pagination = { ...paginationRef.current, page, };
      setPagination(nextPagination);
      loadChanges(nextPagination);
    },
    [ setPagination, loadChanges ]
  );

  // ===================================================================================

  const changes = useList('changes', 'changes', release?.id, { pagination });
  const count = changes?.total || 0;

  const [currentChange, setCurrentChange] = useState();

  if (!release) return null;

  return (
    <Wrap>
      {(() => {
        if (!changes || changes.isLoading || !changes.loadedOnce) {
          return (
            <>
              <Grid item xs={12} sm={3}>
                {Array(5).fill(0).map((_, i) => (
                  <Skeleton key={ i } variant="rect" height={67} style={{ marginBottom: 8 }} />
                ))}
              </Grid>

              <Grid item xs={12} sm={9}>
                <Skeleton variant="rect" width='60%' height={300} style={{marginBottom: 10}} />
              </Grid>
            </>
          )
        }
        if (changes && changes.total === 0) {
          return <EmptyContent variant="h4" messages={[ "No change history recorded." ]} />;
        }

        return (
          <ChangeHistoryModalCore
            changes={ changes }
            currentChange={ currentChange }
            // @ts-ignore
            setCurrentChange={ setCurrentChange }
            pagination={ pagination }
            count={ count }
            setCurrentPage={ setCurrentPage }
          />
        );
      })()}
    </Wrap>
  );
}
export default ChangeHistoryModal;
