import get from 'lodash/get';
import type { defaultJSpreadSheetCtxValue } from '@components/JSpreadSheetWrap/Ctx';

//# region extractRowsFromResp

export type ExpectedRespShape = {
  data: {
    contents: {
      rows: Array< string | number >[];
    }
  }
};
export const extractRowsFromResp = (resp: unknown, offset: number, limit: number): Array< string | number >[] => {
  return get( resp, ['data', 'contents', 'rows' ], [] );
  // /**
  //  * when response from BE is correctly paginated, replace contents of this\
  //  * function with:
  //  *
  //  * "return get( resp, ['data', 'contents', 'rows' ], [] );"
  //  */
  // const maybeRows = get( resp, ['data', 'contents', 'rows' ], [] );
  // if( maybeRows.length === 0 ) return maybeRows;

  // return maybeRows.slice( offset, offset + limit );
}

//# endregion


export const aTypes = {
  captureRefs: 'release-documents-datatable/capture-refs',
  signalizeThatRefsAreCaptured: 'release-documents-datatable/signalize-that-refs-are-captured',
  requestToLoadNextPage: 'release-documents-datatable/request-to-load-next-page',
  requestToLoadPrevPage: 'release-documents-datatable/request-to-load-prev-page',
  /**
   * used when we append new rows and need to refresh after call\
   * to BE succeeds (as new rows might have appeared on current\
   * open page)
   */
  requestToRefreshCurPage: 'release-documents-datatable/request-to-refresh-cur-page',
} as const;

export type Actions = {
  captureRefs: {
    type: typeof aTypes.captureRefs;
    payload: Pick< typeof defaultJSpreadSheetCtxValue, 'valueRef' | 'setValueCb' >;
  };
  signalizeThatRefsAreCaptured: {
    type: typeof aTypes.signalizeThatRefsAreCaptured;
    payload: Actions[ 'captureRefs' ][ 'payload' ];
  };
  requestToLoadNextPage: { type: typeof aTypes.requestToLoadNextPage };
  requestToLoadPrevPage: { type: typeof aTypes.requestToLoadPrevPage };
  requestToRefreshCurPage: { type: typeof aTypes.requestToRefreshCurPage };
}
export const aCreators: {
  [ K in keyof Actions ]: Actions[ K ] extends { payload: any }
    ? (p: Actions[ K ][ 'payload' ]) => Actions[ K ]
    : () => Actions[ K ]
} = {
  captureRefs: p => ({ type: aTypes.captureRefs, payload: p }),
  signalizeThatRefsAreCaptured: p => ({ type: aTypes.signalizeThatRefsAreCaptured, payload: p }),
  requestToLoadNextPage: () => ({ type: aTypes.requestToLoadNextPage }),
  requestToLoadPrevPage: () => ({ type: aTypes.requestToLoadPrevPage }),
  requestToRefreshCurPage: () => ({ type: aTypes.requestToRefreshCurPage }),
}
