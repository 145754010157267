import { ELEMENT_PARAGRAPH, type TElement, type TNode, findDescendant, removeNodes, select } from "@udecode/plate";
import { ELEMENT_TABLE, ELEMENT_TD, ELEMENT_TH, ELEMENT_TR } from "@udecode/plate-table";
import {
  type Ancestor,
  type BaseEditor,
  Editor,
  type EditorAboveOptions,
  type NodeEntry,
  Path,
  Transforms,
} from "slate";
import { v4 as uuidv4 } from "uuid";

import { getSelectedNode } from "../../plugins/util";
import { ELEMENT_CONCLUSION, ELEMENT_CONDITION, ELEMENT_RULE } from "../elements";
import { type TConnector, createConnector } from "../operator/connector";
import { type TOperator, createOperator } from "../operator/operatorTransforms";
import { createRule } from "../rule/createRule";
import type { TRule } from "../rule/rule.types";

// header is conclusion
// row -> first child is conclusion, rest are conditions
// last row is else
// alt conclusion should be able to enforce as a plugin

// TH and TD now must have a child node, defaults to paragraph

export interface THeader<T extends TElement> extends TElement {
  type: typeof ELEMENT_TH;
  children: T[];
}

export interface TDataCell<T extends TElement> extends TElement {
  type: typeof ELEMENT_TD;
  children: T[];
}

export interface TRow extends TElement {
  type: typeof ELEMENT_TR;
  isHeader?: boolean;
  isLast?: boolean;
  children: (THeader<TRule> | TDataCell<TRule | TOperator | TConnector>)[];
}

export interface TDecisionTable extends TElement {
  type: "table";
  children: TRow[];
}

export const createElement = (type: string, children: string | any[] = "", other: Record<string, any> = {}) => ({
  type,
  children: typeof children === "string" ? [{ text: children }] : children,
  id: uuidv4(),
  ...other,
});

// export const createHeader = (children: any[] = []) => createElement(ELEMENT_TR, [createElement(ELEMENT_TH, [createElement(TYPES.HEADER, children)])], { isHeader: true });
export const createHeader = () =>
  createElement(ELEMENT_TR, [createElement(ELEMENT_TH, [createRule(ELEMENT_CONCLUSION)])], { isHeader: true });
export const createRow = (children: any[] = [], isLast = false) =>
  createElement(ELEMENT_TR, [createElement(ELEMENT_TD, children)], { isLast });
export const createFullRow = () =>
  createRow([createConnector([createRule(ELEMENT_CONCLUSION), createOperator([createRule(ELEMENT_CONDITION)])])]);

export const getParentTable = (editor: BaseEditor, options: EditorAboveOptions<Ancestor> = {}) => {
  return Editor.above(editor, {
    // @ts-ignore
    match: (n: TNode) => n.type === ELEMENT_TABLE,
    ...options,
  });
};

/**
 * Determines if the cursor is currently within a table
 */
export const isInTable = (editor, options: EditorAboveOptions<Ancestor> = {}) => {
  try {
    const [table] = (editor && getParentTable(editor, options)) ?? [false];
    return !!table;
  } catch (err) {
    // error while trying determine status, this means selection is weird so infer not in table
    return false;
  }
};

export const notInTable = (editor) => !isInTable(editor);

export const insertTable = (editor) => {
  const { selection } = editor;
  // Can't add table in table or not in a section
  if (isInTable(editor)) return undefined;

  const table = {
    type: ELEMENT_TABLE,
    children: [createHeader(), createFullRow(), createRow([createRule(ELEMENT_CONCLUSION)], true)],
  };

  // also insert blank line below
  const nodes = [table, createElement(ELEMENT_PARAGRAPH)];

  if (selection) {
    Transforms.insertNodes(editor, nodes);
  } else {
    // Insert at the bottom
    Transforms.insertNodes(editor, nodes, { at: [editor.children.length] });
  }
};

export const getRow = (editor, options = {}) => {
  const node = getSelectedNode<TRow>(editor);
  if (node[0] === undefined) return [undefined, undefined];
  return node[0].type === ELEMENT_TR
    ? node
    : (Editor.above(editor, {
        // @ts-ignore
        match: (n: TNode) => n.type === ELEMENT_TR,
        ...options,
      }) as NodeEntry<TRow>) || [undefined, undefined];
};

export type InsertRowOptions = {
  /** If true, will insert before the current selected row. */
  before?: boolean;
  /** The path location to insert the row */
  at?: Path;
};

export const insertRow = (editor, options: InsertRowOptions = { before: false }) => {
  const [rowNode, rowPath] = options.at ? [true, options.at] : getRow(editor, options);
  if (!rowNode) return;

  const newRow = createFullRow();
  const path = options.before ? rowPath : Path.next(rowPath);
  try {
    // console.log('inserting row', path, newRow);
    Transforms.insertNodes(editor, newRow, { at: path, select: false });
    // select the first text node
    const leaf = findDescendant(editor, { at: path, match: (n) => n.type === ELEMENT_RULE });
    if (leaf) {
      select(editor, leaf[1]);
    }
  } catch (e) {
    console.error("Failed to insert row", e);
  }
};

export const insertRowAbove = (e, path) => insertRow(e, { at: path, before: true });
export const insertRowBelow = (e, path) => insertRow(e, { at: path, before: false });

export const deleteRow = (editor) => {
  const [rowNode, rowPath] = getRow(editor);
  // must be in a row that is not first or last row
  if (!rowNode || rowNode.isHeader || rowNode.isLast) return;

  const parent = getParentTable(editor);
  if (!parent) return; // we are not in a table for some reason
  const [table] = parent;
  // @ts-ignore
  const numConditions = table.children.filter((r: TRow) => !r.isLast && !r.isHeader).length;
  if (numConditions > 1) {
    removeNodes(editor, { at: rowPath });
  }
};

export const deleteTable = (editor) => {
  const parent = getParentTable(editor);
  if (!parent) return; // we are not in a table for some reason
  const [, path] = parent;
  removeNodes(editor, { at: path });
};

export const getFirstRow = (editor, options = {}) => {
  return (
    Editor.above(editor, {
      // @ts-ignore
      match: (n: TNode) => n.type === ELEMENT_TH,
      ...options,
    }) || [undefined, undefined]
  );
};

export const isInTableHeader = (editor) => {
  const [row] = getFirstRow(editor);
  return !!row;
};

export const isLastRow = (editor, options = {}) => {
  const [row] = getRow(editor, options);
  return row?.isLast ?? false;
};

export const isTextElement = (node) => {
  return !!node.text || (node.children?.length === 1 && !!node.children[0].text);
};
