import { useSelector } from 'react-redux';
import type { Props } from '@components/RuleGraph_FullRelease/DatesFilter';


export const tabs: Array< { label: string; value: string; disabled?: true } > = [
  { label: 'Guided', value: 'Guided' },
  { label: 'Conversation', value: 'Conversation' },
  { label: 'Generative', value: 'Generative' },
  { label: 'Compact', value: 'Compact' },
  { label: 'Raw', value: 'Raw' },
];

export type Control = import('@decisively-io/interview-sdk').Control;

export type IState = {
  tab: string;
  graphTab: 'full' | 'highlight' | 'report' | 'data';
  controls: Control[];
  focusModeClickHistory: Array<{ id: string; description?: string; }>;
  datesFilter: NonNullable< Props[ 'value' ] >;
};


export const graphTabs: Array<{ label: string; value: IState[ 'graphTab' ]; disabled?: true }> = [
  { label: 'Full', value: 'full' },
  { label: 'Highlight', value: 'highlight' },
  { label: 'Report', value: 'report' },
  { label: 'Data', value: 'data' },
];

export const REDUX_PROP = 'build_debug';

export const getState: (s: any) => IState = s => s[ REDUX_PROP ];


type ATypes = {
  setTab: 'build__debug/setTab';
  focusCompact: 'build__debug/focusCompact';
  setGraphTab: 'build__deubg/setGraphTab';
  setControls: 'build__debug/setControls';
  addFocusNodeToClickHistory: 'build__debug/addFocusNodeToClickHistory';
  setFocusNodeClickHistory: 'build__debug/setFocusNodeClickHistory';
  requestToSelectNodeFromFocusHistory: 'build__debug/requestToSelectNodeFromFocusHistory',
  clearFocusNodeClickHistory: 'build__debug/clearFocusNodeClickHistory';
  setDatesFilter: 'build__debug/setDatesFilter';
};
export const ActionTypes: ATypes = {
  setTab: 'build__debug/setTab',
  focusCompact: 'build__debug/focusCompact',
  setGraphTab: 'build__deubg/setGraphTab',
  setControls: 'build__debug/setControls',
  addFocusNodeToClickHistory: 'build__debug/addFocusNodeToClickHistory',
  setFocusNodeClickHistory: 'build__debug/setFocusNodeClickHistory',
  requestToSelectNodeFromFocusHistory: 'build__debug/requestToSelectNodeFromFocusHistory',
  clearFocusNodeClickHistory: 'build__debug/clearFocusNodeClickHistory',
  setDatesFilter: 'build__debug/setDatesFilter',
};

export type IActions = {
  setTab: {
    type: typeof ActionTypes.setTab;
    payload: IState[ 'tab' ];
  };
  focusCompact: {
    type: typeof ActionTypes.focusCompact;
  };
  setGraphTab: {
    type: typeof ActionTypes.setGraphTab;
    payload: IState[ 'graphTab' ];
  };
  setControls: {
    type: typeof ActionTypes.setControls;
    payload: IState[ 'controls' ];
  };
  addFocusNodeToClickHistory: {
    type: typeof ActionTypes.addFocusNodeToClickHistory;
    payload: IState[ 'focusModeClickHistory' ][ 0 ];
  }
  setFocusNodeClickHistory: {
    type: typeof ActionTypes.setFocusNodeClickHistory;
    payload: IState[ 'focusModeClickHistory' ];
  }
  requestToSelectNodeFromFocusHistory: {
    type: typeof ActionTypes.requestToSelectNodeFromFocusHistory;
    payload: {
      setNode: (n: any) => unknown;
      index: number;
    }
  }
  clearFocusNodeClickHistory: { type: typeof ActionTypes.clearFocusNodeClickHistory; }
  setDatesFilter: {
    type: typeof ActionTypes.setDatesFilter;
    payload: IState[ 'datesFilter' ];
  }
}
type Actions = IActions;


/* eslint-disable no-unused-vars */
type ACreators = {
  // setTab: (arg: IActions[ 'setTab' ][ 'payload' ]) => IActions[ 'setTab' ];
  // setGraphTab: (arg: IActions[ 'setGraphTab' ][ 'payload' ]) => IActions[ 'setGraphTab' ];
  // setControls: (arg: IActions[ 'setControls' ][ 'payload' ]) => IActions[ 'setControls' ];
  // addFocusNodeToClickHistory: (arg: IActions[ 'addFocusNodeToClickHistory' ][ 'payload' ]) => IActions[ 'addFocusNodeToClickHistory' ];
  // setFocusNodeClickHistory: (arg: IActions[ 'setFocusNodeClickHistory' ][ 'payload' ]) => IActions[ 'setFocusNodeClickHistory' ];
  // requestToSelectNodeFromFocusHistory: (
  //   arg: IActions[ 'requestToSelectNodeFromFocusHistory' ][ 'payload' ]
  // ) => IActions[ 'requestToSelectNodeFromFocusHistory' ];
  // clearFocusNodeClickHistory: () => IActions[ 'clearFocusNodeClickHistory' ];
  // setDatesFilter: (arg: IActions[ 'setDatesFilter' ][ 'payload' ]) => IActions[ 'setDatesFilter' ];
  [ K in keyof Actions ]: Actions[ K ] extends { payload: unknown }
    ? (p: Actions[ K ][ 'payload' ]) => Actions[ K ]
    : () => Actions[ K ]
};
/* eslint-enable no-unused-vars */


export const ActionCreators: ACreators = {
  setTab: payload => ({ type: ActionTypes.setTab, payload }),
  focusCompact: () => ({ type: ActionTypes.focusCompact }),
  setGraphTab: payload => ({ type: ActionTypes.setGraphTab, payload }),

  setControls: payload => ({ type: ActionTypes.setControls, payload }),

  addFocusNodeToClickHistory: payload => ({ type: ActionTypes.addFocusNodeToClickHistory, payload }),
  setFocusNodeClickHistory: payload => ({ type: ActionTypes.setFocusNodeClickHistory, payload }),
  requestToSelectNodeFromFocusHistory: payload => ({
    type: ActionTypes.requestToSelectNodeFromFocusHistory,
    payload,
  }),
  clearFocusNodeClickHistory: () => ({ type: ActionTypes.clearFocusNodeClickHistory }),
  setDatesFilter: p => ({ type: ActionTypes.setDatesFilter, payload: p }),
};


export const defaultState: IState = {
  tab: tabs[ 0 ].value,
  controls: [],
  focusModeClickHistory: [],
  graphTab: graphTabs[ 0 ].value,
  datesFilter: [null, null],
};


export const reducer = (s = defaultState, a: IActions[ keyof IActions ]): typeof s => {
  switch (a.type) {
    case ActionTypes.setTab:
      return {
        ...s,
        tab: a.payload,
      };
    case ActionTypes.focusCompact:
      if(s.tab !== tabs[ 3 ].value) return s;

      return {
        ...s,
        tab: tabs[ 2 ].value,
      };

    case ActionTypes.setGraphTab:
      return {
        ...s,
        graphTab: a.payload,
      };

    case ActionTypes.setControls:
      return {
        ...s,
        controls: a.payload,
      };

    case ActionTypes.addFocusNodeToClickHistory:
      const { payload: node } = a;
      const arr = s.focusModeClickHistory;

      // const nextArr = (() => {
      //   const defaultConcat = arr.concat(node);
      //   if(arr.length === 0) return defaultConcat;

      //   const last = arr[ arr.length - 1 ];
      //   if(last.id === node.id) return arr;
      //   if(arr.length === 1) return defaultConcat;

      //   const beforeLast = arr[ arr.length - 2 ];
      //   if(beforeLast.id === node.id) return arr.slice(0, -2);

      //   return defaultConcat;
      // })();

      return {
        ...s,
        focusModeClickHistory: arr.length > 0 && arr[ arr.length - 1 ].id === node.id
          ? arr
          : arr.concat(node),
      };

    case ActionTypes.setFocusNodeClickHistory:
      return {
        ...s,
        focusModeClickHistory: a.payload,
      };

    case ActionTypes.clearFocusNodeClickHistory:
      return {
        ...s,
        focusModeClickHistory: [],
      };

    case ActionTypes.setDatesFilter:
      return {
        ...s,
        datesFilter: a.payload,
      };

    default:
      return s;
  }
};


export const useDatesFilter: () => IState[ 'datesFilter' ] = () => useSelector(s => getState(s).datesFilter);
