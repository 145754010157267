import React from 'react';
import type { HashOfNodeIdToNode, ITreeViewNode, NodeIdToParentId } from './__treeViewNode';
import type { IState } from './reducer';


export const holder = new class GraphTestTreeViewHolder {
  __hashOfNodeIdToNode: HashOfNodeIdToNode = {}

  set(v: GraphTestTreeViewHolder['__hashOfNodeIdToNode']): void {
    this.__hashOfNodeIdToNode = v;
  }

  get(): GraphTestTreeViewHolder['__hashOfNodeIdToNode'] {
    return this.__hashOfNodeIdToNode;
  }

  // ===================================================================================

  __nodeIdToParentId: NodeIdToParentId = {};

  setParentHash(v: GraphTestTreeViewHolder['__nodeIdToParentId']): void {
    this.__nodeIdToParentId = v;
  }

  getParentHash(): GraphTestTreeViewHolder['__nodeIdToParentId'] {
    return this.__nodeIdToParentId;
  }

  // ===================================================================================

  __wrap: React.RefObject< HTMLDivElement > = React.createRef();

  setWrap(v: GraphTestTreeViewHolder['__wrap']): void {
    this.__wrap = v;
  }

  getWrap(): GraphTestTreeViewHolder['__wrap'] {
    return this.__wrap;
  }
}();


export const getSelectedNode = ({ selectedId }: IState): ITreeViewNode | null => (
  selectedId === null ? null : (
    holder.get()[ selectedId ] || null
  )
);

export const getTree = (treeRoots: string[]): ITreeViewNode[] => (
  treeRoots.reduce< ITreeViewNode[] >(
    (a, id) => a.concat(holder.get()[ id ] || []),
    [],
  )
);

export const gatherAncestors = (nodeId: string, acc: string[] = []): string[] => {
  const maybeParent = holder.getParentHash()[ nodeId ];

  return maybeParent === undefined ? acc : (
    gatherAncestors(maybeParent, acc.concat(maybeParent))
  );
};
