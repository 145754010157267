import { all, takeLatest, select, put } from 'redux-saga/effects';
import * as reducerNS from './reducer';
import { SET_RELEASE } from '../../../../../redux/scope';

type IReqToSelectNodeFromFocusHistory = reducerNS.IActions[ 'requestToSelectNodeFromFocusHistory' ];


const workerSaga = takeLatest(
  reducerNS.ActionTypes.requestToSelectNodeFromFocusHistory,
  function* onRequestToSelectNodeFromFocusHistory(
    { payload: { setNode, index } }: IReqToSelectNodeFromFocusHistory,
  ) {
    const state: ReturnType< typeof reducerNS.getState > = yield select(reducerNS.getState);
    const { focusModeClickHistory: cur } = state;
    if(cur[ index ] === undefined) return;

    const nodeToFocus = cur[ index ];
    const nextHistory = cur.slice(0, index);

    yield put(reducerNS.ActionCreators.setFocusNodeClickHistory(nextHistory));

    if (nodeToFocus) setNode(nodeToFocus);
  },
);


export function* saga(): Gen {
  yield all([
    workerSaga,
    takeLatest(SET_RELEASE, function* resetClickHistory() {
      yield put(reducerNS.ActionCreators.clearFocusNodeClickHistory());
    }),
  ]);
}
