import { normalizeRange, withHotKeys } from "@common/editor/plugins/util";
import { ELEMENT_PARAGRAPH } from "@udecode/plate";
import {
  type PlateEditor,
  type TElement,
  type TNode,
  createPluginFactory,
  getNode,
  getNodeEntry,
  liftNodes,
  moveNodes,
  removeNodes,
  setNodes,
  unwrapNodes,
  withoutNormalizing,
  wrapNodes,
} from "@udecode/plate-core";
import clsx from "clsx";
import { useLocalStorage } from "react-use";
import { Path, Range, Text } from "slate";
import styled from "styled-components";
import { ELEMENT_OPERATOR } from "../elements";
import { type TOperator, createOperator } from "./operatorTransforms";

const ConnectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &.debug {
    border: 1px solid red;
  }

  .children {
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    border-left: 1px solid #e5e5e5;
  }
`;

export const ELEMENT_CONNECTOR = "connector";

export interface TConnector extends TElement {
  type: typeof ELEMENT_CONNECTOR;
}

export const createConnector = (children: any[] = []) => ({
  type: ELEMENT_CONNECTOR,
  children,
});

const ensureRangeIsNodes = (editor: PlateEditor, range: Range) => {
  const [anchor, aPath] = getNodeEntry(editor, range.anchor);
  const [focus, fPath] = getNodeEntry(editor, range.focus);
  // convert anchor and focus to the parent if they are leaf nodes (ie text)
  return {
    anchor: Text.isText(anchor) ? { path: Path.parent(aPath), offset: 0 } : range.anchor,
    focus: Text.isText(focus) ? { path: Path.parent(fPath), offset: 0 } : range.focus,
  };
};

export const insertConnector = (editor: PlateEditor, path: Path | Range) => {
  withoutNormalizing(editor, () => {
    // ensure we are working with a normalized range
    const range = ensureRangeIsNodes(
      editor,
      normalizeRange(
        Range.isRange(path)
          ? path
          : {
              anchor: { path: path, offset: 0 },
              focus: { path: path, offset: 0 },
            },
      ),
    );

    try {
      // check if the previous node is a connector
      const [prev, prevPath] = getNodeEntry<TElement>(editor, Path.previous(range.anchor.path));
      if (prev?.type === ELEMENT_CONNECTOR) {
        // add to existing connector
        moveNodes(editor, { at: path, to: [...prevPath, prev.children.length] });
        return;
      }
    } catch (e) {}

    // TODO we want to convert all into conditions

    // try use the previous node as the parent, if no previous, use the first node
    let fullRange = range;
    try {
      fullRange = {
        anchor: { path: Path.previous(range.anchor.path), offset: 0 },
        focus: { path: range.focus.path, offset: 0 },
      };
    } catch (e) {}
    wrapNodes(editor, createConnector(), { at: fullRange });
  });
};

export const Connector = ({ className, attributes, children, element, editor }: any) => {
  const [debug] = useLocalStorage("rule-author-debug", false);
  const [parent, ...rest] = children;
  return (
    <ConnectorContainer
      className={clsx(className, { debug })}
      {...attributes}
    >
      <div className={clsx("parent")}>{parent}</div>
      <div className={clsx("children")}>{...rest}</div>
    </ConnectorContainer>
  );
};

export const createConnectorPlugin = createPluginFactory({
  key: ELEMENT_CONNECTOR,
  isElement: true,
  component: Connector,
  // handlers: {
  //   onChange: (editor) => () => {
  //     // normalizeOperators(editor);
  //   },
  // },
  withOverrides: (editor: PlateEditor) => {
    const { normalizeNode } = editor;
    editor.normalizeNode = ([node, path]) => {
      if (node.type === ELEMENT_CONNECTOR) {
        const { children } = node as TElement;

        // lift all paragraphs
        if (children[0]?.type === ELEMENT_PARAGRAPH) {
          liftNodes(editor, { at: [...path, 0] });
          children.splice(0, 1);
        }

        if (children.length > 2) {
          // we could have a list of rules and operators, we want to move them all into a single operator
          // easiest method will be;
          //  wrap all children into an operator
          //  unwrap any inner operators
          //  use the first found operator type as the new type (any subsequent operators will be forced into the same type)

          // deprecated path
          // if the second child is already an operator, move into it
          // if (children[1].type === ELEMENT_OPERATOR) {
          //   // remember, path context is the connector
          //   const operator = children[1] as TElement;
          //   const destination = [...path, 1, operator.children.length];
          //   const range = {
          //     anchor: { path: [...path, 2], offset: 0 },
          //     focus: { path: [...path, children.length], offset: 0 },
          //   };
          //   moveNodes(editor, { at: range, to: destination });
          //   return;
          // }

          // NOTE remember, the path context is the connector
          // wrap all children into an operator
          const range = {
            anchor: { path: [...path, 1], offset: 0 },
            focus: { path: [...path, children.length], offset: 0 },
          };
          wrapNodes(editor, createOperator(), { at: range });
          // now, path.children[1] is the child operator
          const opPath = [...path, 1];
          const opNode = getNode(editor, opPath) as unknown as TOperator;

          // update the type of the operator
          const opType = opNode.children.find((n) => n.type === ELEMENT_OPERATOR)?.operator;
          if (opType) {
            setNodes<TNode>(editor, { operator: opType }, { at: opPath });
          }

          // unwrap any inner operators
          opNode.children.forEach((child, index) => {
            if (child.type === ELEMENT_OPERATOR) {
              const cPath = [...path, 1, index];
              unwrapNodes(editor, { at: cPath });
            }
          });
          return;
        }
        // trying out not doing this, as it gives the user slightly more control and feels less weird
        // if (children.length === 2) {
        //   // if its 2 and the second child is an operator with 1 child, we can remove the operator
        //   const cPath = [...path, 1];
        //   const child = getNode(editor, cPath);
        //   debugger;
        //   if (child?.type === ELEMENT_OPERATOR && (child as TElement).children.length === 1) {
        //     // need an exception, if the child is an operator, leave it, as they might be building structure
        //     if((child as TElement).children[0].type === ELEMENT_OPERATOR) return;
        //     // remove the operator
        //     unwrapNodes(editor, { at: cPath });
        //     return;
        //   }
        // }
        if (children.length === 1) {
          // we dont want a connect with only a single child, as that means its not actually doing anything
          // unwrap it and move the child up
          unwrapNodes(editor, { at: path });
        }
        if (children.length === 0) {
          // no children, remove the connector
          // if the editor contains empty operators, remove them
          // match: (n) => n.type === ELEMENT_OPERATOR && Editor.isEmpty(editor as BaseEditor, n as BaseElement),
          removeNodes(editor, { at: path });
        }
      }

      return normalizeNode([node, path]);
    };
    return editor;
  },
  ...withHotKeys([
    {
      keys: ["mod+g"],
      action: (editor, plugin, node) => {
        const range = editor.selection;
        if (range) {
          insertConnector(editor, range);
        }
        return true;
      },
    },
  ]),
});
