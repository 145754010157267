/* eslint-disable camelcase */
import { v4 as uuid } from 'uuid';
import escapeRegExp from 'lodash/escapeRegExp';

export type ITreeViewNode = ({ original: any }) & (
  | { duplicate: true; id: string; test_status?: string; }
  | {
    duplicate: false;
    label: string;
    id: string;
    test_status?: string;
    children: ITreeViewNode[];
    entity: string;
  }
);

export type HashOfNodeIdToNode = { [ nodeId: string ]: ITreeViewNode };


export function aggregateNodeChildren(
  g: any, id: string, usedIds: { [ id: string ]: true } = {},
): ITreeViewNode | undefined {
  const node = g.node(id);

  if(usedIds[ id ] === true) {
    return { duplicate: true, id: uuid(), test_status: node.test_status, original: node };
  }

  // eslint-disable-next-line no-param-reassign
  usedIds[ id ] = true;

  if (node.identifier || node.fk) return;

  const childrenIds = g.successors(id);
  if(!Array.isArray(childrenIds) || childrenIds.length === 0) {
    return;
  }

  const treeViewNode: ITreeViewNode = {
    original: node,
    label: node.publicId ? node.publicId : node.description,
    test_status: node.test_status,
    id,
    duplicate: false,
    entity: node.entity,
    children: Array.isArray(childrenIds)
      ? childrenIds.reduce< ITreeViewNode[] >(
        (a, cId) => a.concat(aggregateNodeChildren(g, cId, usedIds) || []),
        [],
      )
      : [],
  };

  return treeViewNode;
}

export const deriveHashOfNodeIdToNodeFromNode = (n: ITreeViewNode): HashOfNodeIdToNode => {
  const hash: HashOfNodeIdToNode = { [ n.id ]: n };
  if(n.duplicate === true || n.children.length === 0) return hash;

  return n.children.reduce(
    (a, c) => ({
      ...a,
      ...deriveHashOfNodeIdToNodeFromNode(c),
    }),
    hash,
  );
};


export function gatherLevel1Ids(n: ITreeViewNode, level = 1): string[] {
  if(level > 1) return [];

  const childIds = n.duplicate === true ? [] : (
    n.children.reduce< string[] >((a, child) => a.concat(
      gatherLevel1Ids(child, level + 1)),
    [],
    )
  );

  return [n.id].concat(childIds);
}

export const gatherDescendants = (n: ITreeViewNode, isRoot?: true): string[] => (
  (isRoot ? [] : [n.id])
    .concat(
      n.duplicate ? [] : n.children.reduce< string[] >(
        (a, it) => a.concat(gatherDescendants(it)),
        [],
      ),
    )
);

export const gatherAllIds: (n: ITreeViewNode) => string[] = n => gatherDescendants(n);


export type NodeIdToVerifiedDescendantsPercentage = { [ nodeId: string ]: number };

export function getNodeIdToVerifiedDescendantsPercentage(
  ns: ITreeViewNode[],
): NodeIdToVerifiedDescendantsPercentage {
  const nodeIdToNode: HashOfNodeIdToNode = ns.reduce(
    (a, n) => ({
      ...a,
      ...deriveHashOfNodeIdToNodeFromNode(n),
    }),
    {},
  );

  return Object.keys(nodeIdToNode).reduce< NodeIdToVerifiedDescendantsPercentage >(
    (a, nId) => {
      const node = nodeIdToNode[ nId ];
      const descendants = node ? gatherDescendants(node, true) : [];

      const verifiedCount = descendants.reduce(
        (a, descId) => a + (nodeIdToNode[ descId ]?.test_status === 'verified' ? 1 : 0),
        0,
      );

      // eslint-disable-next-line no-param-reassign
      a[ nId ] = descendants.length === 0 ? 0 : Math.floor(verifiedCount / descendants.length * 100);

      return a;
    },
    {},
  );
}

export interface FilterRtrn {
  filteredTree: ITreeViewNode | null;
  matchedIds: { [ nodeId: string ]: true };
}


const defaultFilterRtrn = { filteredTree: null, matchedIds: {} };

function innerFilterBySearch(n: ITreeViewNode, search: RegExp): FilterRtrn {
  if(n.duplicate) return defaultFilterRtrn;

  const nMatches = n.label.match(search) !== null;

  const buf = n.children.reduce< FilterRtrn >(
    (a, child) => {
      const rez = innerFilterBySearch(child, search);

      if(rez.filteredTree === null || a.filteredTree === null) return a;

      const nextFilteredTree = {
        ...a.filteredTree,
        children: (
          a.filteredTree.duplicate
            ? []
            : a.filteredTree.children
        ).concat(rez.filteredTree),
      };
      const nextMatchedIds = {
        ...a.matchedIds,
        ...rez.matchedIds,
      };

      return rez.filteredTree === null ? a : ({
        filteredTree: nextFilteredTree,
        matchedIds: nextMatchedIds,
      });
    },
    {
      filteredTree: { ...n, children: [] },
      matchedIds: nMatches ? { [ n.id ]: true } : {},
    },
  );

  const { filteredTree: f } = buf;
  if(f === null || f.duplicate === true || (f.children.length === 0 && nMatches === false)) {
    return defaultFilterRtrn;
  }

  return buf;
}

export const filterBySearch = (ns: ITreeViewNode[], search: string): FilterRtrn => {
  return innerFilterBySearch(
    {
      duplicate: false,
      id: '',
      label: '',
      children: ns,
      original: {},
      entity: '',
    },
    new RegExp(escapeRegExp(search), 'i'),
  );
};


// ===================================================================================

export type NodeIdToParentId = { [ nodeId: string ]: string };

const innerGatherHashOfNodeIdToParentId = (n: ITreeViewNode): NodeIdToParentId => {
  if(n.duplicate) return {};

  return n.children.reduce< NodeIdToParentId >(
    (a, c) => ({
      ...a,
      [ c.id ]: n.id,
      ...innerGatherHashOfNodeIdToParentId(c),
    }),
    {},
  );
};

export const gatherHashOfNodeIdToParentId = (ns: ITreeViewNode[]): NodeIdToParentId => {
  return ns.reduce(
    (a, n) => ({
      ...a,
      ...innerGatherHashOfNodeIdToParentId(n),
    }),
    {},
  );
};
