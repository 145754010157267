
import React from 'react';
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import moment from 'moment';
import { ActionCreators, getState, START_TIME, END_TIME } from './redux';
import { autoFormatIgnoreTimezone, roundToMaxDigitsAfterDot } from '@common';


// ===================================================================================

const Label = styled(Typography)`
  width: 4rem;
  display: inline-block;
`;
const Value = styled(Typography)`
  display: inline-block;
`;

export const operateHolder = (() => {
  let shouldReset = false;
  /**
    @type {
      (
        dispatch: import( 'redux' ).Dispatch,
        payload: Pick< import( './redux' ).IActiveState, 'x' | 'y' >
      ) => void
    }
  */
  const operate = (dispatch, arg) => {
    const { x, y } = arg;
    const node = document.elementFromPoint(x, y);
    if (!(node instanceof HTMLElement)) return;

    const {
      value,
      start,
      end,
      title,
    } = node.dataset;

    shouldReset = true;
    dispatch(ActionCreators.set({
      active: true,
      x,
      y,
      value,
      start: start || '',
      end: end || '',
      title: title || '',
    }));
  };
  const debouncedOperate = debounce(operate, 100);


  /** @type { ( dispatch: import( 'redux' ).Dispatch ) => void } */
  const reset = dispatch => {
    debouncedOperate.cancel();
    if (shouldReset === false) return;

    shouldReset = false;
    void dispatch(ActionCreators.reset());
  };


  return {
    debouncedOperate,
    reset,
  };
})();


// ===================================================================================
/** @type { ( value: any, type: 'title' | 'datetime' | 'start' | 'end' | 'value' ) => string } */
export const formatValue = (value, type) => {
  if(type === 'title') return value;

  return type === 'value'
    ? (() => {
      // eslint-disable-next-line default-case
      switch (value) {
        case 'true': return 'True';
        case true: return 'True';
        case 'false': return 'False';
        case false: return 'False';
        case 'undefined': return 'Unknown';
        case 'Not Valid':
        case 'null': return 'Uncertain';
      }

      if (typeof value === 'object' && value !== null && 'increment' in value) {
        const { increment, offset } = value;
        const offsetText = offset === undefined
          ? ''
          : ` (Offset ${ offset })`;

        return `By ${ increment.replace(/./, ch => ch.toUpperCase()) }${ offsetText }`;
      }

      const numbered = Number(value);
      return Number.isNaN(numbered)
        ? (() => {
          const maybeDate = new Date(value);

          return maybeDate.toString() === 'Invalid Date'
            ? value
            : autoFormatIgnoreTimezone(value)
        })()
        : roundToMaxDigitsAfterDot(numbered);
    })()
    : (() => {
      if (value === START_TIME || value === '-8640000000000000') return 'Beginning of Time';
      if (value === END_TIME || value === '8640000000000000') return 'End of Time';
      if (!isNaN(value)) value = parseInt(value);
      return autoFormatIgnoreTimezone(value);
    })();
};

// ===================================================================================


const TitleWrap = styled.div`
  position: fixed;
  z-index: 1301;
  background-color: white;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 2px 2px 10px rgba(0,0,0,0.3);
  max-width: 20rem;
`;


export const Title = () => {
  const state = useSelector(getState);

  if (state.active === false) {
    return null;
  }

  const { x, y, start, value, end, title } = state;
  const labelValue = [
    ['Title:', title],
    ['Start:', start],
    ['End:', end],
    ['Value:', value],
  ];

  return (
    <TitleWrap style={{ left: x + 10, top: y + 10 }}>
      {
        labelValue.map(([label, value], i) => (
          <div key={label}>
            <Label variant='h5'>{label}</Label>
            <Value variant='body1'>
              {formatValue(
                value,
                (() => {
                  switch(i) {
                    case 0: return 'title';
                    case 1: return 'start';
                    case 2: return 'end';
                    default: return 'value';
                  }
                })(),
              )}

            </Value>
          </div>
        ))
      }
    </TitleWrap>
  );
};
Title.displayName = 'DecisionDashboard/Reports/Details/TempTimeline/Title';
