import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import * as Yup from 'yup';
import { ReleaseTestCasesNS } from '@packages/commons';
import { TextFieldProps } from '@material-ui/core/TextField';
import * as TabsNS from '@components/Tabs';
import Typography from "@material-ui/core/Typography";
import { TextField } from "@components/input";
import * as ContextMenuNS from '../../../../redux/contextMenu'
import { TestCasesTree } from '../core';
import { useTestCasesRootCtx, defaultTestCasesRootCtxValue } from "@components/TestCasesTree/RootCtx";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Checkbox, PrimaryButton } from "@components/buttons";
import { showModal } from "@modals";
import type { TestCasesRootCtxState } from "@components/TestCasesTree/types";
import produce from "immer";
import { toSortableTree } from "@components/TestCasesTree/utils";
import { useFullRelease } from "@common/hooks_useFullRelease";
import { LoadingDotsJSX } from "@icons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import cloneDeep from "lodash/cloneDeep";


//# region

const SelectFolderPopoverContentWrap = styled.div`
  padding: 0.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 450px;
`;

const SelectFolderPopoverContent = React.memo(() => {
  return (
    <SelectFolderPopoverContentWrap>
      <Typography variant='h6'>
        Folders
      </Typography>

      <TestCasesTree />
    </SelectFolderPopoverContentWrap>
  );
});
SelectFolderPopoverContent.displayName = 'testCasesTree/Comp/SaveAsModalContents/SelectFolderPopoverContent';

const SelectFolderTextField = styled( TextField )`
  * { cursor: pointer; }

  input {
    caret-color: transparent;
  }
`;

const SlectFolderInput = React.memo(() => {
  const dispatch = useDispatch();

  const {
    value: {
      tree,
      testCasesTreeCompState: {
        highlightedId,
      }
    }
  } = useTestCasesRootCtx();

  const textFieldValue = React.useMemo(() => {
    const maybeNode = highlightedId === null ? null : ReleaseTestCasesNS.utilsNS.tCasesFindById({
      rootItem: tree,
      id: highlightedId,
    });
    return maybeNode === null ? '' : maybeNode.title;
  }, [ highlightedId, tree ]);

  const showTestCasesTreePopover = React.useCallback< NonNullable< TextFieldProps[ 'onClick' ] > >(e => {
    const { bottom, left, width } = e.currentTarget.getBoundingClientRect();

    dispatch( ContextMenuNS.aCreators.requestToShow({
      content: <SelectFolderPopoverContent />,
      pos: {
        coordinates: {
          top: bottom,
          left: left,
        }
      },
      propsOverride: {
        style: {
          zIndex: 1301,
        },
        PaperProps: {
          style: {
            width,
          }
        }
      }
    }));
  }, [dispatch]);

  return (
    <SelectFolderTextField
      label='Parent'
      value={ textFieldValue }
      onClick={ showTestCasesTreePopover }
    />
  );
});
SlectFolderInput.displayName = 'TestCasesTree/Comp/SaveAsMdalContents/SlectFolderInput';

//# endregion

const StyledTabs = styled( TabsNS.Tabs )`
  align-self: flex-start;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

type SaveAsFormData = {
  name: string;
  useCurrentDebuggerState: boolean;
};
const defaultSaveAsFormData: SaveAsFormData = { name: '', useCurrentDebuggerState: true };



export type OnSubmitInSaveAsModalArg = {
  newItem: ReleaseTestCasesNS.TestCaseTItem;
  testCasesRootCtxValureRef: TestCasesRootCtxState[ 'valueRef' ];
}
export type OnSubmitInSaveAsModal = ( arg: OnSubmitInSaveAsModalArg ) => Promise< void >
export type SaveAsModalContentsProps = {
  initialItemToAdd: ReleaseTestCasesNS.TestCaseTItem;
}
export const SaveAsModalContents: React.FC< SaveAsModalContentsProps > = React.memo(props => {
  const { initialItemToAdd } = props;
  const dispatch = useDispatch();
  const { valueRef, setValue } = useTestCasesRootCtx();
  const fullRelease = useFullRelease();
  const releaseId =  fullRelease === null ? null : fullRelease.id;

  const isAddingFolder = initialItemToAdd.type === 'folder';

  const schema = React.useMemo< Yup.SchemaOf< SaveAsFormData > >(() => Yup.object().shape({
    name: Yup.string().required('Required')
      .test({
        name: 'isUniqueTitle',
        message: 'Duplicated title',
        test: v => {
          const { tree, testCasesTreeCompState: { highlightedId } } = valueRef.current;
          if(v === undefined || v === '' || highlightedId === null) return false;

          return ReleaseTestCasesNS.utilsNS.tCasesAssertChildTitleUniq({
            parentId: highlightedId,
            root: tree,
            title: String(v)
          });
        }
      }),
    useCurrentDebuggerState: Yup.boolean().required(),
  }), [valueRef]);

  const {
    handleSubmit,
    control,
    formState: { isSubmitting }
  } = useForm({ defaultValues: defaultSaveAsFormData, resolver: yupResolver(schema) })

  const onSubmitHandler = React.useMemo< React.FormEventHandler<HTMLFormElement> >(
    () => handleSubmit(
      async data => {
        const useDefaults = data.useCurrentDebuggerState === false;
        const newTreeItem = produce(initialItemToAdd, draft => {
          draft.title = data.name;

          if(draft.type !== 'payload') return;

          draft.goal = useDefaults ? '' : draft.goal;
          draft.data = useDefaults ? {} : cloneDeep( draft.data );
        });

        return valueRef.current.actions.createItem({
          idToAddTo: valueRef.current.testCasesTreeCompState.highlightedId,
          newTreeItem,
          releaseId,
          setValue,
          treeItemRoot: valueRef.current.tree,
          type: 'saveAsModal',
        })
      },
      e => console.error('nzwkwNWORx | RHF: onInvalid', e)
    ),
    [ handleSubmit, valueRef, dispatch, initialItemToAdd, releaseId, setValue ]
  );

  return (
    <>
      { isAddingFolder ? null : (
        <StyledTabs
          current='testCase'
          onChange={console.info}
          tabs={[
            { label: 'Test Case', value: 'testCase', },
            { label: 'Scenario', value: 'scenario', disabled: true },
          ]}
        />
      ) }

      <Form onSubmit={ onSubmitHandler }>
        { isAddingFolder ? null : (
          <Typography>
            Test cases enable you to save various payloads under a chosen target attribute.
          </Typography>
        )}

        <SlectFolderInput />

        <Controller
          control={ control }
          name="name"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextField
              label={ `${ initialItemToAdd.type === 'payload'  ? 'Test case' : 'Folder'} name` }
              value={ value }
              onChange={ onChange }
              error={ Boolean( error) }
              helperText={ error && error.message }
            />
          )}
        />

        { isAddingFolder ? null : ( <Controller
          control={ control }
          name="useCurrentDebuggerState"
          render={({ field: { value, onChange } }) => (
            <FormControlLabel
              control={(
                <Checkbox
                  checked={value}
                  onChange={onChange}
                  style={{ marginRight: '1rem' }}
                />
              )}
              label='Use current debugger state'
              style={{ margin: 0 }}
            />
          )}
        />)}

        <PrimaryButton
          type='submit'
          style={{ alignSelf: 'flex-end' }}
          disabled={isSubmitting}
        >
          {(() => {
            if(isSubmitting) return LoadingDotsJSX;

            return `Add ${ initialItemToAdd.type === 'folder' ? 'folder' : 'test case' }`;
          })()}
        </PrimaryButton>
      </Form>
    </>
  );
});
SaveAsModalContents.displayName = 'TestCasesTree/Comp/SaveAsModalContents';


//# region useOpenSaveAsModal

export type OpenSaveTestCaseAsModalArg = Pick< SaveAsModalContentsProps, 'initialItemToAdd' > & {
  /** by default uses "" */
  initialIdToAddTo?: ReleaseTestCasesNS.TestCaseTItem[ 'id' ];
  mode: TestCasesRootCtxState[ 'value' ][ 'testCasesTreeCompState' ][ 'mode' ];
}
export type OpenSaveTestCaseAsModalF = ( arg: OpenSaveTestCaseAsModalArg ) => unknown;
export const useOpenSaveTestCaseAsModal = (): OpenSaveTestCaseAsModalF => {
  const dispatch = useDispatch();
  const { setValue } = useTestCasesRootCtx();

  return React.useCallback(arg => {
    const { initialIdToAddTo, mode, initialItemToAdd } = arg;

    setValue(prev => produce(prev, draft => {
      draft.testCasesTreeCompState = {
        ...defaultTestCasesRootCtxValue.testCasesTreeCompState,
        mode,
        tree: {
          ...toSortableTree( { root: draft.tree } ),
          expanded: true,
        },
        highlightedId: initialIdToAddTo || "",
        // isOpenedInCtxMenu: true,
      };
    }));

    dispatch(showModal(
      {
        open: true,
        title: initialItemToAdd.type === 'folder' ? 'Create Folder' : 'Save as',
        style: { gap: "1rem" },
      },
      <SaveAsModalContents initialItemToAdd={ initialItemToAdd } />,
    ));
  }, [dispatch, setValue]);
}
//# endregion
