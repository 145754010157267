/**
 * cleans the results of `worksheetInstance.getData` call,\
 * as those are really badly typed. **IMPORTANT** does not\
 * set up proper first column ids, that would be handled in\
 * a separate method
 */
export const cleanupJSpreadsheetData = (data: unknown, numCols: number): string[][] => {
  const fallbackRow = new Array( numCols ).fill( '' );

  /**
   * **IMPORTANT** we need at least one row for JSpreadsheet as \
   * it errors otherwise
   */
  if( !Array.isArray( data ) || data.length === 0 ) return [ fallbackRow ];

  return data.map(row => {
    if( !Array.isArray( row ) ) return fallbackRow;

    const mapped = row.map(cell => String( cell ) );
    /** should be impossible, but just for completeness */
    if ( mapped.length > numCols ) return mapped.slice( 0, numCols );
    if ( mapped.length === numCols ) return mapped;

    return mapped.concat( new Array( numCols - mapped.length ).fill( '' ) );
  })
}

export const normalizeJSpreadsheetData = (data: string[][]): typeof data => (
  data.map( ( row, i ) => [ String( i + 1 ) ].concat( row.slice( 1 ) ) )
);
