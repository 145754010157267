import React, { memo } from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


// ===================================================================================

export const WithHeadingWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;


export const WithHeading = memo< React.PropsWithChildren<{ h: string }> >(({ h, children }) => {
  return (
    <WithHeadingWrap>
      <Typography variant='h6'>{ h }</Typography>

      { children }
    </WithHeadingWrap>
  );
});
WithHeading.displayName = 'components/TemporalValue/modalCommon/WithHeading';

// ===================================================================================

const DefaultModalTitleC = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  :first-letter {
    text-transform: uppercase;
  }
`;
export const DefaultModalTitle: React.FC< React.PropsWithChildren > = memo(({ children }) => (
  <Tooltip title={typeof children === 'string' ? children : ''}>
    <DefaultModalTitleC variant='h3'>
      {children}
    </DefaultModalTitleC>
  </Tooltip>
));
DefaultModalTitle.displayName = 'components/TemporalValue/DefaultModalTitle';

// ===================================================================================

export const ModalRootWrap = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const ModalCloseBtn = styled(IconButton)`
  padding: 0;

  .MuiSvgIcon-root {
    width: 29px;
    height: 29px;
  }
`;

export interface ModalRootProps {
  title: string | JSX.Element;
  close: () => unknown;
}

export const ModalRoot: React.FC< React.PropsWithChildren< ModalRootProps > > = memo(({ close, children, title }) => {
  return (
    <ModalRootWrap>
      <Box display='flex' width='100%' justifyContent='space-between' padding='2rem 2rem 0 2rem'>
        { typeof title === 'string' ? <DefaultModalTitle>{title}</DefaultModalTitle> : title }

        <ModalCloseBtn onClick={close}>
          <CloseIcon />
        </ModalCloseBtn>
      </Box>

      { children }
    </ModalRootWrap>
  );
});
ModalRoot.displayName = 'components/TemporalValue/modalCommon/ModalRoot';
