// import cloneDeep from 'lodash/cloneDeep';
import fastCopy from "fast-copy";

export const deepClone = <T>(obj: T): T => {
  // return cloneDeep(obj);
  return fastCopy(obj);
};

export const removeUndefinedFields = (obj: any) => {

  const keys = Object.keys(obj);

  for (const key of keys) {
    const keyVal = obj[ key ];

    if (keyVal && typeof keyVal === "object") {
      removeUndefinedFields(keyVal);
    // } else if (keyVal == null) {
    } else if (keyVal === undefined) {
      delete obj[ key ];
    }
  }
};

export const flattenObject = (ob: any, terminatorKey?: string) => {

  const result = {};
  for (const i in ob) {
    if ((typeof ob[i]) === "object" && !Array.isArray(ob[i])) {
      const temp = flattenObject(ob[i], terminatorKey);

      if (terminatorKey && terminatorKey in temp) {
        // don't keep breaking up this object - let's keep it together as a single value
        result[i] = temp;
      } else {
        // keep breaking up this object
        for (const j in temp) {
          result[i + "." + j] = temp[j];
        }
      }
    } else {
      result[i] = ob[i];
    }
  }
  return (result);
};

export const objectToArray = (obj: any) => {

  const result = [] as any[];
  for (const key in obj) {
    result.push(obj[key]);
  }
  return result;
}
