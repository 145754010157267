import styled from "styled-components";
import { CopyIconButton, CopyTextField } from "@components/CopyTextField";
import { GuidIcon } from "@icons";

const IDField = styled(CopyTextField)`
  .MuiInputBase-root::before, .MuiInputBase-root::after {
    content: none;
  }
  .MuiInputBase-root {
    input.MuiInputBase-input {
      font-size: 1rem;
      padding: 0;
      &:focus {
        background-color: transparent !important;
      }
    }
  }
`;

/** Common display for attribute IDs which can be copied */
export const AttributeIDField = ({ id }: { id: string }) => <IDField variant="standard" value={id} />;
export const AttributeIDCopy = ({ id }: { id: string }) => <CopyIconButton value={id} icon={<GuidIcon />} tooltip="Copied attribute ID" />;