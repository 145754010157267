import React from "react";

import { CssBaseline } from "@material-ui/core";
import { StylesProvider } from "@material-ui/styles";

export const Styling = ({ children }) => {
  return (
    <>
      <CssBaseline />
      <StylesProvider injectFirst>{children}</StylesProvider>
      {/*TODO: <Notification/>*/}
    </>
  );
};
