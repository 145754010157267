import { Redirect } from "react-router-dom";
import { projects } from "./projects";
import { build } from "./build";
import { monitor } from "./monitor";
import { verify } from "./verify";
import { admin } from './admin';
import SignInPage from "@pages/SignIn";
import LoginError from "@pages/LoginError";
import LoggedOut from "@pages/LoggedOut";
import ErrorPage from "@pages/404";
import Authcallback from "@pages/Authcallback";
import DesignSystem from "@pages/DesignSystem";
import { TaskModal } from "@common/tasks/TaskModal";

const HomeRedirect = () => {
  return <Redirect to={{ pathname: "/projects" }} />;
};

const routes = [
  {
    path: "/",
    component: HomeRedirect,
    layout: "decisively",
    exact: true,
    role: "user",
  },
  ...projects,
  ...build,
  ...monitor,
  ...verify,
  ...admin,
  {
    path: "/loggedOut",
    component: LoggedOut,
    layout: "centered",
    exact: true,
  },
  {
    path: "/signin",
    component: SignInPage,
    exact: true,
    layout: "auth",
  },
  {
    path: "/authcallback",
    layout: "centered",
    component: Authcallback,
    exact: true,
  },
  {
    path: "/login_error",
    layout: "centered",
    component: LoginError,
    exact: true,
  },
  {
    path: "/task/:id",
    component: TaskModal,
    layout: "decisively",
    exact: true,
    role: "user",
  },
]
  .concat(
    import.meta && import.meta.env && import.meta.env.DEV
      ? {
        path: "/design",
        component: DesignSystem,
        layout: "decisively",
        exact: true,
        role: "user",
      }
      : [],
  )
  .concat({
    path: "/:NoMatch",
    component: ErrorPage,
    layout: "centered",
    exact: false
  });

export default routes;
