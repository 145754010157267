import * as React from "react";
import * as HoverCardPrimitive from "@radix-ui/react-hover-card";
import { css } from "@emotion/react";
import cn from "classnames";

const styles = {
  content: (theme) => css`
    // z-50 w-64 rounded-md border bg-popover p-4 text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2
    z-index: 1000; // will make parent element inherit it
    display: flex;
    flex-flow: column nowrap;
    min-width: 22.5rem;
    border-radius: 0.5rem;
    background-color: #fff;
    color: #0a0a0a;
    border: 1px solid ${theme.palette?.background?.border};
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    outline: none;
    overflow: hidden;

    &[data-side="top"] {
      transform-origin: bottom;
    }
    &[data-side="right"] {
      transform-origin: left;
    }
    &[data-side="bottom"] {
      transform-origin: top;
    }
    &[data-side="left"] {
      transform-origin: right;
    }
  `,
};

const HoverCard = HoverCardPrimitive.Root;
const HoverCardTrigger = HoverCardPrimitive.Trigger;
const HoverCardPortal = HoverCardPrimitive.Portal;

const HoverCardContent = React.forwardRef<
  React.ElementRef<typeof HoverCardPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof HoverCardPrimitive.Content>
>(({ className, align = "center", sideOffset = 4, ...props }, ref) => (
  <HoverCardPrimitive.Content
    ref={ref}
    align={align}
    sideOffset={sideOffset}
    // @ts-ignore css works
    css={styles.content}
    className={className}
    {...props}
  />
));
HoverCardContent.displayName = HoverCardPrimitive.Content.displayName;

export { HoverCard, HoverCardTrigger, HoverCardPortal, HoverCardContent };
