import { createBrowserHistory } from 'history';
import { persistStore } from 'redux-persist';
import { middleware, reducers, sagas } from "./redux";
import { createStore } from '@imminently/imminently_platform';
import { authProvider, dataProvider } from './providers';
import { Store } from '@reduxjs/toolkit';

export const globalStore = (function() {
  const history = createBrowserHistory();
  const refreshInterval = 1000 * 60 * 20; // 20 minutes
  const store = createStore({
    history,
    dataProvider,
    authProvider,
    locale: 'en',
    config: { auth_error_page: "/login_error", refreshInterval },
    customSagas: sagas,
    customReducers: reducers,
    customMiddleware: middleware,
  }) as Store;
  let persistor = persistStore(store);
  return { store, persistor, history };
})();