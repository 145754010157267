import React from 'react';
import * as EEContextNS from '@common/EEContext';


export type State = {
  treeRef: React.MutableRefObject< Record< string, unknown > >;
  modals: {
    createOrBrowseAll;
  };
}

export const defaultState: State = {
  treeRef: { current: {} },
  modals: {
    createOrBrowseAll: null,
  },
};


const { ctx, useDerive, useSelector, useUpdate, WithCtx } = EEContextNS.init(defaultState);

export const WithMutableCtx = WithCtx;
WithMutableCtx.displayName = 'release/Decision/context/mutable';


// ===================================================================================

type CreateOrBrowseAllModal = State[ 'modals' ][ 'createOrBrowseAll' ];
export const useGetCreateOrBrowseAllModal = (): CreateOrBrowseAllModal => useSelector(s => s.modals.createOrBrowseAll);

export type SetCreateOrBrowseAllModal = (
  (recipe: (modal: CreateOrBrowseAllModal) => CreateOrBrowseAllModal | void) => unknown
);
export const useSetCreateOrBrowseModal = (): SetCreateOrBrowseAllModal => {
  const update = useUpdate();

  return React.useCallback(fn => update(draft => {
    const rez = fn(draft.modals.createOrBrowseAll);

    // eslint-disable-next-line no-param-reassign
    if(rez !== undefined) draft.modals.createOrBrowseAll = rez;
  }), [update]);
};


// ===================================================================================

export const useTreeRef = (): State[ 'treeRef' ] => useSelector(s => s.treeRef);

