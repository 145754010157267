import type { RuleSheet, RuleSheetContents } from "@packages/commons";
import { DESERIALIZED_SYMBOL, type DeserializedRuleSheet, type DeserializedRuleSheetContents } from "@pages/documents";
import { findIndex } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import { v4 as uuid } from "uuid";
import { OPM_TYPES, newApplySheet, newSheet, typeFromHeading } from "./OPM";

const APPLY_SHEET_TITLE = "Apply";

const serializeSheet = ({ title, columns, data }, sheets?: DeserializedRuleSheet[]) => {
  // console.log('converting sheet', columns, data);
  if (!title || !columns || !data) {
    // It's likely an empty sheet - just preventing an error
    return;
  }
  let hasContent = false;
  const headings = columns.map(({ type, value }) => ({ type, legend: value }));
  const rows = data.map((d, i) => {
    const isLast = i === data.length - 1;
    return columns.map((col) => {
      let type = typeFromHeading(col.type);
      // convert last row condition types into else types
      if (title !== APPLY_SHEET_TITLE && isLast && type === OPM_TYPES.CONDITION) {
        type = OPM_TYPES.ELSE;
      }
      let value = d[col.id] || "";
      if (value !== "") hasContent = true;
      console.log("checking aPPL", title, sheets, isLast);
      if (title === APPLY_SHEET_TITLE && sheets) {
        const sheetIndex = findIndex(sheets, { title: value });
        console.log("got index", sheetIndex);
        value = sheetIndex > -1 ? sheetIndex : value;
      }

      return {
        type,
        text: value,
      };
    });
  });
  if (title === APPLY_SHEET_TITLE && !hasContent) return; // The apply sheet should not be included
  return { title, headings, rows };
};

/** serialize */
export const serializeSheetsV1Contents = ({
  declarations,
  applySheet,
  sheets: dataSheets,
}: DeserializedRuleSheetContents) => {
  // convert local datasheet structure into backend
  const sheets = cloneDeep(dataSheets).map((sheet) => serializeSheet(sheet));
  return {
    declarations,
    sheets: [...sheets, serializeSheet(applySheet || {}, dataSheets)].filter((x) => x),
  };
};

const deserializeSheet = ({ title, headings, rows }: RuleSheet, sheets?: RuleSheet[]): DeserializedRuleSheet => {
  // sheet: headings, rows, title => columns, data, title
  const columns = headings.map(({ type, legend }) => ({ type, value: legend, id: uuid() }));
  const data = rows.map((r) => {
    return r.reduce((acc, val, idx) => {
      const key = columns[idx].id;
      if (title === APPLY_SHEET_TITLE && sheets && idx === columns.length - 1) {
        // Fix conclusion
        // The value is the sheet with this name - not the index
        const sheetIndex = Number.parseInt(val.text);
        acc[key] = Number.isNaN(sheetIndex) ? "" : sheets[sheetIndex]?.title ?? "";
      } else acc[key] = val.text;
      return acc;
    }, {});
  });
  return { title, columns, data };
};

export const deserializeSheetsV1Contents = (contents: RuleSheetContents | undefined): DeserializedRuleSheetContents => {
  // convert backend into datasheet structure
  const declarations = contents?.declarations.map((d) => ({ ...d, type: d.type.toLowerCase().replaceAll(" ", "") }));
  let sheets = contents?.sheets.filter((s) => s.title !== APPLY_SHEET_TITLE) ?? [];
  const applySheet = contents?.sheets.find((s) => s.title === APPLY_SHEET_TITLE);
  sheets = sheets.length > 0 ? sheets : [newSheet("Sheet 1")];
  return {
    [DESERIALIZED_SYMBOL]: true,
    declarations: declarations?.length ? declarations : [{}],
    sheets: sheets.map((sheet) => deserializeSheet(sheet)),
    applySheet: deserializeSheet(applySheet || newApplySheet(), sheets),
  } as const;
};
