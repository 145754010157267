import React, { forwardRef } from "react";
import debounce from "lodash/debounce";
import SearchAndSelect from "../SearchAndSelect";
import { isStrNotNullOrBlank, isStrNullOrBlank } from "../../util/UtilString";
import { escapeRegExp, isEqual, uniqWith } from "lodash";
import SearchAndSelectResultItem from "./SearchAndSelectResultItem";
import { trimDocName, useDocuments } from "../../../common/hooks/HooksDocuments";
import { SearchResult } from "./Search.types";
import { useGraph } from "@pages/models/release/GraphContext";
import { normalizeText } from "@packages/commons";
import { useTranslation } from "react-i18next";

interface SearchDocumentsProps {
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SearchDocuments = (props: SearchDocumentsProps) => {
  const [searchValue, setSearchValue] = React.useState<string>("");
  /** where [] is "no results" and null is "no search performed yet" */
  const [searchResults, setSearchResults] = React.useState<SearchResult[] | null>([]);
  const ruleGraph = useGraph();
  const { flatDocs, navigateToDocument: navigateToDocumentHooked } = useDocuments();
  const { t } = useTranslation();
  // -- pre-marshall

  const graphNodes = React.useMemo(() => (ruleGraph?.nodes || [])
    // .filter((node: any) => node.value.definedIn)
    .map((node: any) => {
      if (node.value.fk) return; // Don't include fk in the search. These are actually important, but confuse our rule authors. At some point I'll be asked to turn these back on :)
      // first get path with no extension
      const fullDocPathNoExt: string = trimDocName(node.value.definedIn || "");
      const documentName = fullDocPathNoExt.substring(fullDocPathNoExt.lastIndexOf("/") + 1);
      const documentPath = fullDocPathNoExt.substring(0, fullDocPathNoExt.lastIndexOf("/"));

      const isInput = isStrNullOrBlank(node.value.definedIn || "");
      const document = isInput ? null : flatDocs.find((doc) => fullDocPathNoExt === doc.name);

      const referencedBy = (node.value.usedIn || []).map((usedIn: any) => {
        // is just the path with the extension stripped
        // remove .docx or .xlsx from end of string using replace regex
        const usedInPathNoExt = trimDocName(usedIn);
        // doc.name is the full path without the extension
        const doc = flatDocs.find((doc) => usedInPathNoExt === doc.name);
        // if (!doc) {
        //   console.warn(`Could not find document for ${usedInPathNoExt}. It has likely been renamed or deleted.`);
        // }
        return doc;
      }).filter(Boolean);

      return ({
        v: node.v,
        ...(node.value),
        documentName: documentName || "",
        documentPath: documentPath || "/",
        documentId: document?.reference || "",
        document,
        referencedBy,
        isInput,
      });
    }).filter(x => x), [ruleGraph, flatDocs]);

  // -- search

  const searchDocs_ = (srchStr: string) => {
    const searchRegExp = new RegExp(escapeRegExp(normalizeText(srchStr)), "i");
    const searchResultsArr = graphNodes
      .map<{ val: string; original: any }>(node => ({
        original: node,
        val: String(node.description || node.publicId || node.text || node.name || node.label)
      }))
      .filter(({ val }) => Boolean(val.match(searchRegExp)))
      .map<SearchResult>(it => ({
        index: 0,
        original: it.original,
        score: 1,
        string: it.val
      }));

    const resultsInputs = searchResultsArr.filter((result) => result.original.isInput);
    const resultsNonInputs = searchResultsArr.filter((result) => !result.original.isInput);
    const uniqueResultsNonInputs = uniqWith(resultsNonInputs, (a: any, b: any) =>
      a.original.documentId === b.original.documentId &&
      a.original.type === b.original.type &&
      a.original.entity === b.original.entity &&
      a.original.definedIn === b.original.definedIn &&
      isEqual(a.original.usedIn, b.original.usedIn) &&
      a.original.description === b.original.description
    );
    setSearchResults([
      ...resultsInputs,
      ...uniqueResultsNonInputs,
    ]);
  };

  const searchDocs = React.useCallback(
    debounce(searchDocs_, 500), [graphNodes]
  );

  const searchNodes = () => {
    if (isStrNotNullOrBlank(searchValue) && ruleGraph) {
      searchDocs(searchValue);
    }
  };

  React.useEffect(() => {
    if (isStrNotNullOrBlank(searchValue) && ruleGraph) {
      searchNodes();
    } else {
      setSearchResults(null);
    }
  }, [searchValue, ruleGraph]);

  // -- clear/reset

  const onClear = () => {
    setSearchValue("");
    setSearchResults(null);
  };

  // -- navigation

  const navigateToDocument = (documentPath: string, documentId: string, newWind: boolean) => {
    if (documentPath && documentId) {
      console.log(`Navigate to doc ${documentPath} (${documentId}) in ${newWind ? "new" : "same"} window`);
      const postFunc = newWind ? undefined : onClear;
      navigateToDocumentHooked(documentPath, documentId, newWind, postFunc);
    }
  };

  // -- rendering

  return (
    <SearchAndSelect<SearchResult>
      // key={graphNodes?.[0]?.v ?? "no-graph"}
      appearance="default"
      placeholder={`${t('build.search_rules')}...`}
      value={searchValue}
      onChange={setSearchValue}
      onEnter={searchNodes}
      onClear={onClear}
      searchResults={searchResults}
      renderResultItem={(result, ref) =>
        <SearchAndSelectResultItem
          ref={ref}
          key={result.original.id}
          result={result}
          additionalInfo={{
            entityName: result.original.entity,
            type: result.original.type,
            matchingText: result.string,
            highlightStr: normalizeText(searchValue) || "",
          }}
          onDocumentOpen={navigateToDocument}
        />
      }
    />
  );
};

export default (SearchDocuments);
