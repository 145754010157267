import { all, put, select, takeLatest } from "redux-saga/effects";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";

import { CRUD_CREATE_SUCCESS, CRUD_UPDATE_SUCCESS, crudUpdate, crudGetOne, CRUD_GET_ONE_SUCCESS } from "@imminently/imminently_platform";
import { ActionCreator } from "../../../../redux/combinator.saga";

import { serialize, validate } from "../../../documents/OPM";
import { setLastSaved, setValidations } from "./documents.slice";

export const SAVE_DOCUMENT = "documents/save";

// converts /file/path/name => file.path.name
const toObjectPath = path => path.split("/").slice(1).join(".");

const getMimickedGetOneSuccessAction = (releaseId, data) => ({
  type: CRUD_GET_ONE_SUCCESS,
  payload: { data },
  requestPayload: { id: releaseId },
  meta: {
    resource: "releases",
    id: releaseId,
    type: "GET_ONE",
    fetchResponse: "GET_ONE",
    fetchStatus: "IMP/FETCH_END",
    mimickedActionFromDocumentSaga: true,
  },
});

function* updateRelease(action) {
  const { name, release, type, id, version } = action.payload.data;
  const releaseData = yield select(s => s.resources.releases.data[release]);
  const releaseId = releaseData.id;

  const { documents: documentsRaw } = releaseData;
  const documents = cloneDeep(documentsRaw);
  set(documents, toObjectPath(name), { name, type, reference: id, version });

  yield put(getMimickedGetOneSuccessAction(releaseId, { ...releaseData, documents }));
}

function* createFolder(action) {
  const { path, id } = action.payload.data;
  const release = yield select(s => s.resources.releases.data[id]);
  const releaseId = release.id;

  const { documents: documentsRaw } = release;
  const documents = cloneDeep(documentsRaw);
  set(documents, toObjectPath(path), {});

  yield put(getMimickedGetOneSuccessAction(releaseId, { ...release, documents }));
}

const saveAction = (id, contents, release) => ActionCreator({
  bootstrap: crudUpdate("documents", id, { contents }, { refresh: false }),
  waitFor: {
    type: CRUD_UPDATE_SUCCESS,
    validate: a => a.meta.resource === "documents" && a.payload.data.release === release,
  },
  toDispatch: [
    // update release as graph should have changed
    crudGetOne("releases", release),
    // update last saved on success
    setLastSaved(Date.now()),
  ],
});

function* saveDocument() {
  const release = yield select(s => s.scope.release);
  const { document, contents: value } = yield select(s => s.documents);
  const isLoading = yield select(s => s.resources.documents.loading?.[document.reference] ?? false);

  // don't allow saving if currently loading (ie possibly already saving)
  if (isLoading) return;

  console.log("document save action:", release, document, value);
  // Convert slate state into backend
  const contents = serialize(value);
  const errors = validate(contents);
  yield put(setValidations(errors));
  // if (errors.length === 0) {
  // save anyway
  const action = saveAction(document.reference, contents, release);
  console.log("saving document", action);
  yield put(action);
  // }
}


export default function* documentsSaga() {
  yield all([
    takeLatest(action => action.type === CRUD_CREATE_SUCCESS && action.meta?.resource === "documents", updateRelease),
    takeLatest(
      action => action.type === CRUD_CREATE_SUCCESS &&
        action.meta?.resource === "releases" &&
        action.meta?.customAction === "addfolder",
      createFolder,
    ),
    takeLatest(SAVE_DOCUMENT, saveDocument),
  ]);
}
