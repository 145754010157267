export const colors = {
  true: '#CBFED0',
  false: '#FFEEEE',
  undefined: '#E5E5E5',
  null: '#F9F9F9',

  shadeOfBlue0: '#ECF0FE',
  shadeOfBlue1: '#D8E0FD',
  shadeOfBlue2: '#C5D1FC',
};
