import type jspreadsheet from 'jspreadsheet';
import type { JSpreadSheetWrapCtxValue } from '@components/JSpreadSheetWrap/Ctx';
import {
  defaultDataTableJSpreadsheetHelperState,
  getDataTableJSpreadsheetHelperState,
  type DataTableJSpreadsheetHelperState,
} from '../JSpreadsheetHelperState';
import { global } from '../../../../../../global';
import { extractRowsFromResp } from './constants';
import { call } from 'redux-saga/effects';


export type SetNewPageArg = {
  helperState: JSpreadSheetWrapCtxValue[ 'value' ][ 'helperState' ];
  worksheets: jspreadsheet.worksheetInstance[];
  offset: number;
  setNextHelperState: ( s: DataTableJSpreadsheetHelperState ) => unknown;
};
export function* setNewPage( arg: SetNewPageArg ): Gen {
  const {
    worksheets,
    helperState,
    offset,
    setNextHelperState,
  } = arg;

  const [ first ] = worksheets;
  if( !first ) return null;

  const hState = getDataTableJSpreadsheetHelperState( helperState );

  /**
   * result of page number calculation based only on offset. It\
   * basically answers the question "what is the number of a page\
   * with this offset".\
   * E.g.:
   * - offset 0 => page 1
   * - offset 10 => page 2,
   * - offset 40 => page 5,
   * - etc
   * (and that's why we add +1 at the end)
   */
  const pageAfterOp = Math.ceil( offset / ( hState.perPage || defaultDataTableJSpreadsheetHelperState.perPage ) ) + 1;

  const withOffsetStr = offset === 0 ? '' : `?$offset=${ offset }`;
  const resp = yield call( ( url: string ) => global.client( url ), `documents/${ hState.documentId }${ withOffsetStr }` );
  const nextRows = extractRowsFromResp(resp, offset, hState.perPage);

  if ( nextRows.length > 0 ) {
    first.deleteRow( 0, ( first.getData() || [] ).length );
    first.setData( nextRows );
  }


  const nextHState: typeof hState = {
    ...hState,
    page: nextRows.length > 0 ? pageAfterOp : hState.page,
    lastPage: (
      /**
       * number of rows in BE response is equal to perPage => this tells\
       * us nothing about last page => leave unchanged
       */
      nextRows.length === hState.perPage
        ? hState.lastPage
        : (
          /**
           * no rows returned from BE => looks like current page is exactly\
           * the last one
           */
          nextRows.length === 0
            ? pageAfterOp - 1
            /**
             * number of rows returned is between 0 and perPage => this page\
             * is the last, and as we have captured it in pageAFterOp - return\
             * it
             */
            : pageAfterOp
        )
    ),
  };
  setNextHelperState(nextHState);
}
