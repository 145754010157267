import { all, cancel, ForkEffect, take, takeLatest, select, race, delay, put } from 'redux-saga/effects';
import { CRUD_GET_ONE_SUCCESS } from '@imminently/imminently_platform';
import * as reducer from './reducer';
import { ActionTypes as decisionATypes, IActions as DecisionActions } from '../../redux/reducer';


export function* saga(): Gen {
  yield all([
    (function* bootstrapEEContextBindings() {
      let ref: ForkEffect | null = null;

      while(true) {
        const { payload: { derive } }: reducer.Actions[ 'requestToInitEEContextBinding' ] = (
          yield take(reducer.aTypes.requestToInitEEContextBinding)
        );

        if(ref !== null) yield cancel(ref as any);

        ref = yield takeLatest(decisionATypes.setDecisionReportId, function* onSetDecReportId(a: DecisionActions[ 'setDecisionReportId' ]) {
          const { timeout } = yield race({
            timeout: delay(5_000),
            crudGetOneSuccess: take(CRUD_GET_ONE_SUCCESS),
          });
          if(timeout) return;

          const decisionReportId = a.payload;
          const store = yield select();
          const maybeReport = store.resources?.decisionreports?.data?.[ String(decisionReportId) ];
          if(!maybeReport) return;

          const parsedGraph = derive(s => s.parsedGraph);
          const { data, goal } = maybeReport?.input || { data: {}, goal: '' };

          const l = Object.keys(data).reduce< Record< string, any > >(
            (a, id) => {
              const maybeNode = parsedGraph.node(id);
              if(!maybeNode) return a;

              // eslint-disable-next-line no-param-reassign
              a[ maybeNode.description || maybeNode.id ] = data[ id ];
              return a;
            },
            {},
          );

          yield put(reducer.aCreators.setRaw(JSON.stringify({ data: l, goal }, null, 2)));
        });
      }
    }()),
  ]);
}
