import { EmptyContent } from "@common/EmptyContent";
import { Pane, SplitPane } from "@common/SplitPane";
import { SourceEditor, createSourcePlugins } from "@common/editor/RuleAuthor/SourceEditor";
import { FixedRichTextToolbar } from "@common/editor/RuleAuthor/toolbar/FixedToolbar";
import { FloatingToolbar } from "@common/editor/RuleAuthor/toolbar/FloatingToolbar";
import { AddSectionToolbarButton, RichTextToollbar } from "@common/editor/RuleAuthor/toolbar/components";
import { ExperimentalLabel } from "@components";
import type { Resource } from "@imminently/immi-query";
import { hideModal, showModal } from "@imminently/imminently_platform";
import Box from "@material-ui/core/Box";
import { type Action, ModalActions } from "@modals";
import { FloatingVerticalDivider, PlateProvider } from "@udecode/plate";
import { createAppResourceService } from "global";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { type SectionData, SectionProvider, type TSection, createSectionPlugin, useSection } from "../section";
import { deriveSectionName } from "../section/sectionUtils";

const EditorPanes = styled(SplitPane)`
  border: 1px solid ${(p) => p.theme.palette.background.border};
  border-radius: 0.5rem;
  flex: 1;
  max-height: calc(100% - 3.5rem);

  [data-slate-editor="true"] {
    padding: 1rem 0;
  }
`;

const Toolbar = styled(FixedRichTextToolbar)`
  overflow: visible hidden;
  min-height: 3.5rem;
`;

const StyledModalActions = styled(ModalActions)`
  justify-content: flex-end;
  gap: 1rem;
`;

const SOURCE_EDITOR_ID = "source-editor";
const SUGGEST_EDITOR_ID = "suggest-editor";

const sourcePlugins = createSourcePlugins();

const suggestPlugins = createSourcePlugins([createSectionPlugin({ options: { disableSuggestModals: true } })]);

interface SuggestionResource extends Resource {
  source: TSection[];
}

const AutoSectionModal = ({ source: content, onInsert, documentId }) => {
  const dispatch = useDispatch();
  const { sections, setSections } = useSection();
  const [source, setSource] = useState(() => {
    if (Array.isArray(content)) return content;
    return [content];
  });
  const [suggestion, setSuggestion] = useState<any[]>();
  const [suggestKey, setSuggestKey] = useState(0);
  // We do not yet support custom functions with id, workaround is use the subresource system
  const relativeService = createAppResourceService<any>(`documents/${documentId}`);
  const { isLoading, isError, mutateAsync } = relativeService.usePostCustom("splitDocument");

  // TODO this has to be custom, as using the default from showModal creates circular dependency for some reason
  const close = () => dispatch(hideModal());

  const generateSuggestion = async () => {
    console.log("requesting suggestion for", source);
    const res = (await mutateAsync({ source })) as SuggestionResource;
    console.log("AI generated:", res);
    // filter out sections that are empty
    const genSource = res.source.filter((s) => s.children.length > 0);
    // iterate and get all sections
    const sections = genSource.reduce((acc, el) => {
      const { id, type, title } = el;
      if (type !== "section") return acc;
      if (id && !acc.find((s) => s.id === id)) {
        const name = (title as string) ?? deriveSectionName(el.children);
        acc.push({ id, name, status: "" });
      }
      return acc;
    }, [] as SectionData[]);
    setSections(sections);
    setSuggestion(genSource);
    setSuggestKey(suggestKey + 1);
  };

  useEffect(() => {
    if (!suggestion) {
      generateSuggestion();
    }
  }, [suggestion]);

  const primaryAction: Action = {
    name: "Insert",
    onClick: () => {
      onInsert(suggestion, sections);
      close();
    },
    disabled: isLoading,
  };

  const secondaryActions: Action[] = [
    {
      name: "Regenerate",
      onClick: generateSuggestion,
      loading: isLoading,
      disabled: isLoading,
    },
  ];

  const paneProps = {
    sizes: [50, 50],
    minSize: [300, 300],
    // gutterSize: 8,
    gutterStyle: () => ({ display: "block", width: "8px" }),
  };

  const editableProps = {
    autoFocus: true,
    spellCheck: false,
  };

  return (
    <>
      <EditorPanes
        {...paneProps}
        snapOffset={0}
      >
        <Pane>
          <PlateProvider
            id={SOURCE_EDITOR_ID}
            plugins={sourcePlugins}
            value={source}
            onChange={setSource}
          >
            <Toolbar />
            <SourceEditor
              id={SOURCE_EDITOR_ID}
              editableProps={editableProps}
            >
              <FloatingToolbar>
                <RichTextToollbar />
              </FloatingToolbar>
            </SourceEditor>
          </PlateProvider>
        </Pane>
        <Pane>
          {isLoading || isError ? (
            <EmptyContent
              width="100px"
              messages={isLoading ? ["Generating suggestion..."] : ["Error generating suggestion"]}
            />
          ) : (
            <PlateProvider
              key={suggestKey}
              id={SUGGEST_EDITOR_ID}
              plugins={suggestPlugins}
              value={suggestion}
              onChange={setSuggestion}
            >
              <Toolbar>
                {/* <FloatingVerticalDivider />
                <AddSectionToolbarButton /> */}
              </Toolbar>
              <SourceEditor
                id={SUGGEST_EDITOR_ID}
                editableProps={editableProps}
              >
                <FloatingToolbar>
                  <RichTextToollbar />
                  {/* <FloatingVerticalDivider />
                  <AddSectionToolbarButton /> */}
                </FloatingToolbar>
              </SourceEditor>
            </PlateProvider>
          )}
        </Pane>
      </EditorPanes>
      <StyledModalActions
        primary={primaryAction}
        secondary={secondaryActions}
      />
    </>
  );
};

export const useAutoSectionModal = () => {
  const dispatch = useDispatch();
  return ({ source, onInsert, documentId }) => {
    dispatch(
      showModal(
        {
          open: true,
          title: (
            <Box
              display="flex"
              gridGap="1rem"
              alignItems="center"
            >
              <span>Suggest source sections</span>
              <ExperimentalLabel />
            </Box>
          ),
          // description: <Alert severity="warning">Experimental</Alert>,
          maxWidth: "xl",
          height: "80vh",
          style: { gap: "1rem", maxHeight: "calc(100% - 4rem)" },
        },
        <SectionProvider documentId={documentId}>
          <AutoSectionModal
            source={source}
            onInsert={onInsert}
            documentId={documentId}
          />
        </SectionProvider>,
      ),
    );
  };
};
