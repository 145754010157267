import moment from "moment";
import { useEffect } from "react";
import { useForceUpdate } from "../../../../../../../common/hooks/HooksGeneral";

export interface DurationSinceProps {
  value: number;
  verb?: string;
  className?: string;
}

const ONE_MINUTE = 1000 * 60;

const DurationSince = (props: DurationSinceProps) => {
  const { value, verb, ...otherProps } = props;

  const [forceUpdate] = useForceUpdate();

  const content = value < ONE_MINUTE ? "seconds ago" : `${moment.duration(value).humanize()} ago`;

  useEffect(() => {
    // re-render every minute
    const intervalId = setInterval(() => {
      forceUpdate();
    }, ONE_MINUTE);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <span {...otherProps}>
      {verb || "Saved"} {content}
    </span>
  );
};

export default DurationSince;
