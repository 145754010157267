import React from "react";
import {
  JSpreadSheetWrapWithResizeObserver,
  type JSpreadSheetWrapWithResizeObserverProps,
  generalWidthSync,
  clss,
  type GeneralWidthSyncArg,
} from '../JSpreadSheetWrap'
import styled from "styled-components";


const Wrap = styled(JSpreadSheetWrapWithResizeObserver)`
  .${ clss[">absoluteWrap"].wrapCore } .jtabs-content {
    height: 100%;
  }
`;

export type JSpreadsheetDataTableProps = {
  getSpreadsheetOptions: JSpreadSheetWrapWithResizeObserverProps[ 'getSpreadsheetOptions' ];
  className?: string;
  reservedWidthForScrollbar?: GeneralWidthSyncArg[ 'reservedWidthForScrollbar' ];
  minColWidth?: GeneralWidthSyncArg[ 'minColWidth' ];
}
export const JSpreadsheetDataTable: React.FC< JSpreadsheetDataTableProps > = React.memo(p => {
  const { getSpreadsheetOptions, className, reservedWidthForScrollbar, minColWidth } = p;

  return (
    <Wrap
      getSpreadsheetOptions={ getSpreadsheetOptions }
      syncSpreadsheetSizes={ React.useCallback((rect, ref) => (
        generalWidthSync({ ref, width: rect.width, reservedWidthForScrollbar, minColWidth })
      ), [ reservedWidthForScrollbar ]) }
      className={ className }
    />
  );
});
JSpreadsheetDataTable.displayName = 'components/JSpreadsheetDataTable';
