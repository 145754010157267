import React from 'react';

// this is the equivalent to the createStore method of Redux
// https://redux.js.org/api/createstore

const ReleaseContext = React.createContext();

export const useRelease = () => {
  const context = React.useContext(ReleaseContext)
  if (context === undefined) {
    throw new Error('useRelease must be used within a ReleaseContext')
  }
  return context
}

export default ReleaseContext;
