import type { ENode, TDescendant, TElement, TNode, Value } from "@udecode/plate-core";
import { ELEMENT_TD, ELEMENT_TH, ELEMENT_TR } from "@udecode/plate-table";
import { v4 as uuidv4 } from "uuid";

import type { DeepPartial } from "@imminently/immi-query";
import type { RuleDocContentsV2 } from "@packages/commons";
import type { DeserializedRuleDocContentsV2 } from "@pages/documents";
import type { TDecisionTable, TRow } from "../components/decisionTable/transforms";
import { ELEMENT_CONCLUSION, ELEMENT_CONDITION } from "../components/elements";
import type { TConnector } from "../components/operator/connector";
import type { TRule } from "../components/rule/rule.types";
import { createHR } from "../plugins/util";
import type {
  Connector,
  DecisionTable,
  LegacyDecisionTable,
  Operator,
  Rule,
  RuleData,
  RuleNode,
  Section,
  SubConclusion,
  TableDataCell,
  TableHeader,
} from "./author.types";

// copy of type from apps/portal/src/common/editor/components/section/section.types.ts
// TOOD need to fix cyclic dependency problem
type SectionData = {
  id: string;
  name: string;
  status: string;
};

const createRule = (type, text = "") => ({
  type: "rule",
  expression: type,
  children: [{ text }],
});

const createConnector = (children: any[] = []) => ({
  type: "connector",
  children,
});

const createOperator = (children: any[] = [], operator = "and") => ({
  type: "operator",
  operator,
  children,
});

export const createElement = (type: string, children: string | any[] = "", other: Record<string, any> = {}) => ({
  type,
  children: typeof children === "string" ? [{ text: children }] : children,
  id: uuidv4(),
  ...other,
});

export const createHeader = (text: string) =>
  createElement(ELEMENT_TR, [createElement(ELEMENT_TH, [createRule(ELEMENT_CONCLUSION, text)])], { isHeader: true });
export const createRow = (children: any[] = [], isLast = false) =>
  createElement(ELEMENT_TR, [createElement(ELEMENT_TD, children)], { isLast });
export const createFullRow = () =>
  createRow([createConnector([createRule("conclusion"), createOperator([createRule("condition")])])]);

// serialize = table => decision-table
// deserialize = decision-table => table

export const deserializeLegacyTable = (table: LegacyDecisionTable): TDecisionTable => {
  const { children: rows } = table;
  const header = (rows[0].children[0].children[0] as TableHeader).children[0].text;
  const otherwise = (rows[rows.length - 1].children[0].children[0] as TableDataCell).children[0].text;
  const subConclusions = rows.slice(1, -1).map((row) => {
    const data = row.children[0].children as TableDataCell[];
    return data.reduce(
      (acc, cell) => {
        const opmType = cell.type;
        const text = cell.children[0].text;
        if (opmType === "OPM-conclusion") {
          acc.conclusion = text;
        } else {
          acc.conditions.push(text);
        }
        return acc;
      },
      { conclusion: "", conditions: [] as string[] },
    );
  });

  return createElement("table", [
    createHeader(header),
    ...subConclusions.map((row) =>
      createRow([
        createConnector([
          createRule(ELEMENT_CONCLUSION, row.conclusion),
          createOperator(row.conditions.map((c) => createRule(ELEMENT_CONDITION, c))),
        ]),
      ]),
    ),
    createRow([createRule(ELEMENT_CONCLUSION, otherwise)], true),
  ]) as TDecisionTable;
};

export const deserializeTable = (table: DecisionTable): TDecisionTable => {
  const { header, otherwise, subConclusions } = table;
  return createElement("table", [
    // fallback to empty string if it doesn't exist for some reason
    createHeader(header?.text ?? ""),
    ...subConclusions
      .filter((row) => row.type) // filter out any empty rows
      .map((row) =>
        createRow([
          createConnector([
            // createRule(ELEMENT_CONCLUSION, row.label, row.text),
            expandNode(row as RuleNode),
            expandNode(row.conditions as Operator),
          ]),
        ]),
      ),
    // fallback to empty string if it doesn't exist for some reason
    createRow([createRule(ELEMENT_CONCLUSION, otherwise?.text ?? "")], true),
  ]) as TDecisionTable;
};

export const serializeTable = (node: TNode): DecisionTable => {
  const table = {
    type: "decision-table",
    subConclusions: [] as SubConclusion[],
  } as DecisionTable;

  const getRowRule = (row: TRow) => (row.children[0] as TElement).children[0] as TRule;
  const getInnerNodes = (row: TRow) => (row.children[0] as TElement).children[0] as TConnector;
  // const getRuleContent = (row: TRow) => {
  //   const rule = getRowRule(row);
  //   const label = getNodeString(rule.children[0]).trim();
  //   const text = getNodeString(rule.children[1]).trim();
  //   return { label, text, rule };
  // };

  const rows = node.children as TRow[];
  for (const row of rows) {
    if (row.isHeader) {
      // handle first row (heading)
      // is TR -> TH -> RULE
      table.header = simplifyNode(getRowRule(row)) as Rule;
    } else if (row.isLast) {
      // handle last row (alt conclusion)
      // is TR -> TD -> RULE
      table.otherwise = simplifyNode(getRowRule(row)) as Rule;
      // const { text, label } = getRuleContent(row);
      // table.sub_conclusions.push({
      //   text,
      //   label,
      //   type: "otherwise",
      //   // conditions: [
      //   //   {
      //   //     type: TYPES.ALT_CONCLUSION,
      //   //     text: "Otherwise",
      //   //   },
      //   // ],
      // });
    } else {
      // is TR -> TD -> connector [RULE, OPERATOR]
      const connector = getInnerNodes(row);
      const [rule, operator] = connector.children;
      // first node is conclusion rule
      // const { text, label } = getRuleContent(row);
      // second node is operator graph
      table.subConclusions.push({
        ...(simplifyNode(rule) as Rule),
        conditions: simplifyNode(operator) as Operator,
      } as SubConclusion);
    }
  }

  return table;
};

export function simplifyNode(node): RuleNode | RuleNode[] | null {
  if (!node) {
    return node;
  }

  if (node.type === "p") {
    return {
      type: "p",
      text: node.children[0].text,
    };
  }

  if (node.type === "rule") {
    const { expression, children } = node;
    // const [label, rule] = node.children;
    // TODO if text is empty, return null
    return {
      type: expression,
      text: children.map((c) => c.text).join(""),
    };
  }

  if (node.type === "operator") {
    return {
      type: "operator",
      operator: node.operator as "and" | "or",
      children: node.children.map(simplifyNode),
    };
  }

  if (node.type === "connector") {
    // connectors represent the first child as the parent, and the rest as children
    const [parent, ...children] = node.children;
    return {
      ...(simplifyNode(parent) as RuleNode),
      children: (children ?? []).map(simplifyNode) ?? [],
    } as RuleNode;
  }

  if (node.type === "section") {
    const sectionId = node.id;
    if (node.children.length === 0) {
      // we need to have at least one child, so make it an empty paragraph
      return [{ sectionId, type: "p", text: "" }];
    }
    return node.children.map((n) => ({ sectionId, ...simplifyNode(n) }));
  }

  if (node.type === "hr") {
    return { type: "hr" };
  }

  if (node.type === "table") {
    return serializeTable(node);
  }

  // node is not a valid type
  return null;
}

// eslint-disable-next-line complexity
export function expandNode(node: RuleNode | Section): TElement | null {
  if (node === null) return null;

  if (!node.type) {
    console.error("Node has no type", node);
    return null;
  }

  if (node.type === "p") {
    return {
      type: "p",
      children: [{ text: node.text }],
    };
  }

  if (node.type === "condition" || node.type === "conclusion") {
    // it may have children, if so, we need to make a connector
    if (node.children && node.children.length > 0) {
      return createConnector([
        createRule(node.type, node.text),
        ...node.children.map(expandNode).filter((n) => n !== null),
      ]);
    }
    return createRule(node.type, node.text);
  }

  if (node.type === "hr") {
    return createHR();
  }

  if (node.type === "operator") {
    return createOperator(
      node.children.map(expandNode).filter((n) => n !== null),
      node.operator,
    );
  }

  if (node.type === "connector") {
    // connectors will exist when auto migrating from v2 to v3
    // the node will be the same as its already been expanded, so run the expand code on children
    return createConnector(node.children.map(expandNode).filter((n) => n !== null));
  }

  if (node.type === "section") {
    let children = node.children.map(expandNode).filter((n) => n !== null) as TDescendant[];
    if (children.length === 0) {
      // we need to have at least one child, so make it an empty paragraph
      children = [{ type: "p", children: [{ text: "" }] }];
    }
    return {
      ...node,
      children,
    };
  }

  if (node.type === "decision-table") {
    const table = deserializeTable(node);
    return table;
  }

  // @ts-ignore handle legacy table - TODO should be safe to remove at some point
  if (node.type === "table") {
    const table = deserializeLegacyTable(node);
    // debugger;
    // don't do anything to it
    return table;
  }

  // handle if rule become corrupt
  // if (node.label !== undefined && node.text !== undefined) {
  //   // it should be a rule, but its type is wrong
  //   console.warn("Node has invalid type, converting to a rule", node);
  //   return createRule("condition", node.label, node.text);
  // }

  return null;
}

export const groupBySection = (data: DeepPartial<RuleData>, sections: SectionData[], skip: string[] = []) => {
  return data.reduce(
    (acc, node) => {
      if (!node) return acc;
      const sectionId = node.sectionId;
      // only group it if the section exists/is valid
      // otherwise leave it, and it will default back to global
      const sectionExists = sectionId && sections.some((s) => s.id === sectionId);
      // skip certain groups, ie don't group global
      if (sectionExists && !skip.includes(sectionId)) {
        const section = acc.find((n) => n.type === "section" && n.id === sectionId) as Section;
        if (section) {
          section.children.push(node as RuleNode);
        } else {
          acc.push({
            type: "section",
            id: sectionId,
            children: [node as RuleNode],
          });
        }
      } else {
        acc.push(node as RuleNode);
      }

      return acc;
    },
    [] as (RuleNode | Section)[],
  );
};

// a serialisation function that converts slate data into our own format
export const serializeDocumentV2Contents = (data: DeserializedRuleDocContentsV2): RuleDocContentsV2 => {
  try {
    if (!data?.rules || data.rules.length === 0)
      return {
        ...data,
        rules: [],
      };

    const rules = data.rules.flatMap(simplifyNode).filter((n) => n !== null);

    return {
      ...data,
      rules,
    };
  } catch (e) {
    console.error("Failed to serialise document", e);
    return {
      ...data,
      rules: [],
    };
  }
};

export const deserializeDocumentV2Contents = (contents: RuleDocContentsV2): RuleDocContentsV2 => {
  if (!contents || contents.rules.length === 0) return contents;
  const grouped = groupBySection(contents.rules, contents.sections ?? [], ["global"]);
  const expanded = grouped.map(expandNode);
  const filtered = expanded.filter((n) => n !== null) as TElement[];
  return {
    ...contents,
    rules: filtered,
  };
};

// need a deserialise function that takes v2 and converts to v3
// it needs to be able to convert conclusion with sibling operator into a connector
export function deserializeDocumentV2ContentsToV3(contents: RuleDocContentsV2): RuleDocContentsV2 {
  // first we want to convert any conclusion with a sibling operator into a connector
  const v3data = [] as RuleNode[];
  const rules = contents.rules;
  for (let i = 0; i < rules.length; i++) {
    const node = rules[i];
    if (node.type === "conclusion") {
      const next = rules[i + 1];
      if (next && next.type === "operator") {
        // since we are at the top level, move the sectionId to the connector
        const connector = createConnector([node, next]) as Connector;
        connector.sectionId = node.sectionId;
        v3data.push(connector);
        i++;
        continue;
      }
    }
    v3data.push(node);
  }
  // then just do the original deserialise
  const res = deserializeDocumentV2Contents({ ...contents, rules: v3data });
  return res;
}
