
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import { useWorkspace } from '@common';
import { SelectButton, Avatar, ContextMenuItem, Tooltip, AsyncSelectItemTooltipTitle } from '@components';
import { AddIcon, BrowseallIcon, ChevrondownIcon } from '@icons';
import { addWorkspace, browseWorkspaces } from '@modals';
import { setWorkspace, useScope } from '../redux/scope';
import { workspaceService } from 'services';
import { ImmiCombobox } from '@components/radix/UserSelect/AsyncSelect';
import { useTranslation } from 'react-i18next';

const workspaceWidth = 208;

const StyledAvatar = styled(Avatar)`
  border: 1px solid ${p => p.theme.header.color};
`;

const Selector = styled(SelectButton)`
  color: ${props => props.theme.header.color};
  background-color: ${props => props.theme.header.background} !important;
  border: none;
  box-shadow: none !important;
  min-width: ${workspaceWidth}px;
  max-width: ${workspaceWidth}px;
  &:hover {
    color: ${props => props.theme.header.color} !important;
    background-color: ${props => props.theme.header.background} !important;
    box-shadow: none;
  }
`;

const MenuItem = styled(ContextMenuItem)`
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5rem;
  border-radius: 0;
  min-height: 2.5rem;
`;

const Loading = styled(Skeleton)`
  background-color: rgba(255, 255, 255, 0.89);
  border-radius: 0.5rem;
`;

export const Workspaces = () => {
  const dispatch = useDispatch();
  const { workspace: workspaceId } = useScope();
  const workspace = useWorkspace(workspaceId);
  const { t } = useTranslation();
  const updateWorkspace = id => {
    dispatch(setWorkspace(id));
  };

  if (!workspace) {
    return (
      <div>
        <Loading variant='rect' width={workspaceWidth} height='2.5rem' />
      </div>
    );
  }

  // TODO use workspace avatar - needs implementation
  const icon = <StyledAvatar size='small' alt={workspace.name} src='/' />;

  const comboProps = {
    label: t('workspaces.title'),
    service: workspaceService,
    onSelect: (item) => updateWorkspace(item.id),
    renderItem: (item) => (
      <Tooltip title={<AsyncSelectItemTooltipTitle name={item.name} />} placement='right'>
        <MenuItem selected={item.id === workspaceId} >
          <StyledAvatar size='small'>{item.name.slice(0, 1)}</StyledAvatar>
          <Typography display="block" noWrap>
            {item.name}
          </Typography>
        </MenuItem>
      </Tooltip>
    ),
    actions: {
      primary: {
        name: t('browse_all'),
        icon: <BrowseallIcon />,
        type: 'tertiary',
        size: 'small' as const,
        onClick: () => {
          dispatch(browseWorkspaces());
        },
      },
      secondary: [{
        name: t('workspaces.create'),
        icon: <AddIcon />,
        type: 'tertiary',
        size: 'small' as const,
        onClick: () => {
          dispatch(addWorkspace());
        },
      }],
    }
  };

  return (
    <>
      <ImmiCombobox {...comboProps}>
        <Selector disableRipple value={workspace.name || ''} startIcon={icon} endIcon={<ChevrondownIcon />}>
          <Typography variant="button" display="block" noWrap>
            {workspace.name ?? ""}
          </Typography>
        </Selector>
      </ImmiCombobox>
    </>
  );
};
