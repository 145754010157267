
import { delay, takeLatest, select, put, all } from 'redux-saga/effects';
// @ts-ignore
import { crudGetList } from '@imminently/imminently_platform';


export const COMPONENT_ID_FOR_CURRENT_RELEASES = 'currentForReleases';
export const ASYNC_SELECT_LOCATION = 'forAsyncSelect';

export const ActionTypes = /** @type { const } */({
  load: 'asyncSelect/load',
  delayedLoad: 'asyncSelect/delayedLoad',
  loadCurrentReleases: 'asyncSelect/loadCurrentReleases',
});


// TODO ping BE to allow for regex queries sent by getFilterForSearch
/**
  @typedef ICRUDCommon
  @type {{
    resourceType?: 'releases' | 'models' | 'workspaces';
    location: string;
    componentId?: string;
    getFilterForSearch: (search: string) => any,
    perPage: number;
    workspace?: string;
    model?: string;
  }}
 */

/**
  @typedef IActions
  @type {{
    load: {
      type: typeof ActionTypes.load;
      payload: ICRUDCommon & {
        type: 'newSearch'  | 'nextPage';
        search: string;
      }
    };
    delayedLoad: {
      type: typeof ActionTypes.delayedLoad;
      payload: IActions[ 'load' ][ 'payload' ]
    };
    loadCurrentReleases: {
      type: typeof ActionTypes.loadCurrentReleases;
    }
  }}
 */
/**
  @type {{
    load: ( p: IActions[ 'load' ][ 'payload' ] ) => IActions[ 'load' ];
    delayedLoad: ( p: IActions[ 'delayedLoad' ][ 'payload' ] ) => IActions[ 'delayedLoad' ];
    loadCurrentReleases: () => IActions[ 'loadCurrentReleases' ];
  }}
 */
export const ActionCreators = {
  load: p => ({ type: ActionTypes.load, payload: p }),
  delayedLoad: p => ({ type: ActionTypes.delayedLoad, payload: p }),
  loadCurrentReleases: () => ({ type: ActionTypes.loadCurrentReleases }),
};

// ===================================================================================

export const getResourceHashAndResourceForId = ({
  state,
  resourceType,
  location,
  componentId,
}) => {
  const maybeParticularResourcesHash = state.resources?.[resourceType];
  if (maybeParticularResourcesHash === undefined) return null;

  const maybeResourceForId = maybeParticularResourcesHash?.[location]?.[componentId];
  if (maybeResourceForId === undefined) return null;

  /** @type {{ ids: string[]; total: number; }} */
  const list = maybeResourceForId.list || { ids: [], total: 0 };

  return {
    hash: maybeParticularResourcesHash,
    forId: maybeResourceForId,
    hasMore: list.ids.length < list.total
  };
};

const lastProjectToLoadReleasesFor = { current: '' };

export function* saga() {
  yield all([
    takeLatest(ActionTypes.load, function* onAsyncSelectLoadAction(/** @type { IActions[ 'load' ] } */a) {

      const {
        payload: {
          componentId,
          location,
          perPage,
          resourceType,
          getFilterForSearch,
          type,
          search,
          workspace: workspaceFromAction,
          model: modelFromAction,
        },
      } = a;

      if (undefined === resourceType || (resourceType === 'releases' && modelFromAction === '')) return;

      /** @type { any } */
      const state = yield select();
      const workspace = workspaceFromAction ?? state.scope.workspace;
      const project = modelFromAction ?? state.scope.project;
      const maybeHashAndForId = getResourceHashAndResourceForId({
        state,
        resourceType,
        componentId,
        location,
      });

      const calculatedPerPage = (function composePagination() {
        if (type === 'newSearch') {
          return perPage;
        }

        if (maybeHashAndForId === null) return null;

        return maybeHashAndForId.forId.list.params.pagination.perPage + perPage;
      }());

      // for some reason we couldn't derive perPage -> bail out
      if (calculatedPerPage === null) return;

      // if we have no more items to load -> bail out
      if (type === 'nextPage') {
        if (maybeHashAndForId === null) return;

        const { forId: { list: { total, ids } } } = maybeHashAndForId;
        if (total <= ids.length) return;
      }

      /** @type { { filter: any } } */
      const maybeWithFilter = (() => {
        if (resourceType === 'workspaces') {
          return { filter: type !== 'newSearch' || search === '' ? {} : getFilterForSearch(search) };
        }


        if (resourceType === 'models') {
          const withWorkspace = workspace === '' ? {} : { workspace };

          return type !== 'newSearch' || search === ''
            ? { filter: withWorkspace }
            : { filter: { ...withWorkspace, ...getFilterForSearch(search) } };
        }

        const withModel = { model: project };

        return type !== 'newSearch' || search === ''
          ? { filter: withModel }
          : { filter: { ...withModel, ...getFilterForSearch(search) } };
      })();

      const maybeWithSort = (() => {
        if (resourceType === 'releases') {
          return { sort: { field: 'releaseNo', order: 'desc' } };
        }
      })();

      /**
       * if we:
       * - are loading releases
       * - are loading releases through "newSearch" and not through "nextPage"
       * - have current project as the one we already loaded releases for
       * then we probably don't want to load again
       */
      if (resourceType === 'releases' && type === 'newSearch') {
        if (lastProjectToLoadReleasesFor.current === project) {
          return;
        }

        lastProjectToLoadReleasesFor.current = project;
      }

      const action = crudGetList(
        resourceType,
        location,
        {
          componentId,
          pagination: { page: 1, perPage: calculatedPerPage },
          ...maybeWithFilter,
          ...maybeWithSort,
        },
      );

      yield put(action);
    }),
    takeLatest(
      ActionTypes.delayedLoad,
      function* onAsyncSelectDelayedLoadAtion(/** @type { IActions[ 'delayedLoad' ] } */a) {
        yield delay(500);

        yield put(ActionCreators.load(a.payload));
      },
    ),
    takeLatest(
      ActionTypes.loadCurrentReleases,
      (() => {
        // store last projectId that we loaded releases for
        const ref = { current: '' };

        return function* onLoadCurrentReleases() {
          const { project } = yield select(s => s.scope);
          if (!project) return;
          if (ref.current === project) return;

          ref.current = project;

          const action = crudGetList(
            'releases',
            ASYNC_SELECT_LOCATION,
            {
              componentId: COMPONENT_ID_FOR_CURRENT_RELEASES,
              // should only be 2, 1 dev, 1 prod
              pagination: { page: 1, perPage: 2 },
              filter: { model: project, activeVersionFlag: true },
            },
          );

          yield put(action);
        };
      })(),
    ),
  ]);
}
