import React, { useState } from 'react';
import locale from 'react-json-editor-ajrm/locale/en';
import JSONInput from 'react-json-editor-ajrm';

const defaultColors = {
  default: '#767676',
  background: '#ffffff',
  // background_warning?: string | undefined;
  // string?: string | undefined;
  // number?: string | undefined;
  // colon?: string | undefined;
  // keys?: string | undefined;
  // keys_whiteSpace?: string | undefined;
  // primitive?: string | undefined;
};

const defaultTheme = 'light_mitsuketa_tribute';

/**
 * A component to allowing editing a json payload.
 *
 * Wraps the JSONInput component to provide a controlled component type interface.
 *
 * **NOTE** value is assigned only on mount, as JSONInput does not support controlled value.
 * If you want to change value externally, you will need to assign a key and update it accordingly.
 * This will reset cursor position, so only use it when changing the entire value.
 */

/** @typedef { import( 'react-json-editor-ajrm' ).default[ 'props' ] } LibProps */

/** @typedef {{ [key: string]: {} | undefined }} Json */

/**
  @typedef IProps
  @type {
    & Omit< LibProps, 'locale' >
    & { locale?: LibProps[ 'locale' ] }
    & { value: Json, onChange: ( v: Json ) => unknown }
  }
 */


/** @type { React.FC< IProps > } */
export const JsonEditor = React.memo(({ value, onChange, ...props }) => {
  const [state] = useState(value);

  const handleChange = React.useCallback(
    ({ jsObject: j }) => (j === undefined ? null : onChange(j)),
    [onChange],
  );

  return (
    <JSONInput
      placeholder={state}
      locale={locale}
      height='100%'
      width='100%'
      theme={defaultTheme}
      colors={defaultColors}
      onChange={handleChange}
      {...props}
    />
  );
});
JsonEditor.displayName = 'Common/JsonEditor';
