import React from "react";
import Moment from 'react-moment';
import styled from "styled-components";
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  type
  TimelineItemProps,
} from "@material-ui/lab";
import { Chip, Typography } from "@material-ui/core";
import { scrollable } from "@common";


//# region TablePaginationActions

const TablePaginationActionsWrap = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`;
const RangeTypography = styled( Typography )`
  white-space: nowrap;
`
type TablePaginationActionsProps = {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (e: unknown, page: number) => unknown;
}
const TablePaginationActions: React.FC< TablePaginationActionsProps > = (props) => {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 1);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage)));
  };

  const range = `${ (page - 1) * rowsPerPage + 1 }-${ Math.min( page * rowsPerPage, count) }`;

  return (
    <TablePaginationActionsWrap>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 1}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 1} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>

      <RangeTypography>
        { range } of { count }
      </RangeTypography>

      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage)}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage)}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </TablePaginationActionsWrap>
  );
}

//# endregion

const Wrap = styled( Timeline )`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 25%;
  flex-shrink: 0;
  flex-grow: 0;
  margin: 0;
  overflow: auto;
  gap: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
`;

const ChangesWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

/**
 * typecast here is kinda safe as TimelineItem will pass any props\
 * to the native element underneath (which is a div)
 */
type StyledTimelineItemT = React.ComponentType< TimelineItemProps & { onClick: () => unknown } >;
const StyledTimelineItem = styled(TimelineItem)`
  cursor: pointer;
  border-radius: 0.25rem;

  &:hover {
    background-color: rgba(155,155,155,0.15);
  }

  &.MuiTimelineItem-missingOppositeContent:before{
    display: none;
  }
` as StyledTimelineItemT;


const StyledTimelineContent = styled(TimelineContent)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: calc(100% - 1rem);
`;
const TimelineContentFirstLine = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
const TimelineContentFirstLineName = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledTablePagination = styled(TablePagination)`
  border: none;

  .MuiToolbar-root { min-height: auto; }

  .MuiTablePagination-caption { display: none; }
`;

export type ChangeHistoryTimelineProps = {
  changes: { pageData: any[] };
  setCurrentChange: (v: unknown) => unknown;
  pagination: { page: number; perPage: number; };
  count: number;
  setCurrentPage: (num: number) => unknown;
};

export const ChangeHistoryTimeline: React.FC< ChangeHistoryTimelineProps > = React.memo( p => {
  const { changes, setCurrentChange, pagination, count, setCurrentPage } = p;

  return (
    <Wrap>
      <ChangesWrap className={ scrollable }>
        {changes.pageData.map(change => (
          <StyledTimelineItem key={ change.id } onClick={() => void setCurrentChange(change)}>
            <StyledTimelineContent>
              <TimelineContentFirstLine>
                <Chip label={`${change.what.length} change${change.what.length > 1 ? 's' : ''}`}/>

                <TimelineContentFirstLineName
                  style={{ fontWeight: 400, fontSize: 14 }}
                  title={ change.who.name }
                >
                  by {change.who.name}
                </TimelineContentFirstLineName>
              </TimelineContentFirstLine>

              <Typography variant='caption' style={{ fontWeight: 400, marginLeft: '0.5rem' }}>
                <Moment fromNow>{change.when}</Moment>
              </Typography>
            </StyledTimelineContent>

            <TimelineSeparator >
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
          </StyledTimelineItem>
        ))}
      </ChangesWrap>

      <StyledTablePagination
        style={{ flexShrink: 0 }}
        rowsPerPageOptions={[10]}
        count={count}
        rowsPerPage={pagination?.perPage}
        page={pagination?.page}
        onPageChange={(_, p) => setCurrentPage(p)}
        // @ts-ignore
        ActionsComponent={TablePaginationActions}
      />
    </Wrap>
  )
});
ChangeHistoryTimeline.displayName = 'components/ChangeHistory/Timeline';
