
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import TimelinesChart from 'timelines-chart';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import { START_TIME, END_TIME } from '@components/TemporalValue/TempTimeline/redux';
import { formatValue } from '@components/TemporalValue/TempTimeline';


/** @type { ( v: import( './types' ).Range[ 'v' ] ) => ReturnType< typeof formatValue > } */
const localFormatValue = v => {
  if (typeof v === 'object' && v !== null) {
    return formatValue(v, 'value');
  }

  return formatValue(String(v), 'value');
};

export const Style = createGlobalStyle`
  .chart-tooltip.group-tooltip { display: none; }
  .chart-tooltip.segment-tooltip {
    background-color: rgba(0,0,140,1);
    >strong:first-child {
      position: absolute;
      top: 5px;
      left: 0;
      background-color: rgba(0,0,140,1);
      display: inline-block;
      width: 100%;
    }
  }
`;

// ===================================================================================


/** @typedef { import( './types' ).PrimitiveValue } PrimitiveValue */
/** @typedef { import( './types' ).Node } Node */

// ===================================================================================

/** @type {{ entries: Node[]; minDate: string | -1; maxDate: string | -1; }} */
const defaultAcc = {
  entries: [],
  minDate: -1,
  maxDate: -1,
};
const LINE_HEIGHT = 50;


/** @type { (v: import( './types' ).TemporalValue ) => typeof v } */
const normalizeTemporalDatesAndRemoveUnknowns = v => {
  const totalDays = v.temporal.ranges.reduce(
    (a, it) => (
      (it.r.start === START_TIME || it.r.end === END_TIME)
        ? a
        : a + moment(it.r.end).diff(moment(it.r.start), 'days')
    ),
    0,
  );
  const twoPercentDays = Math.floor(totalDays * 0.02);
  const start = moment(v.temporal.ranges[ 0 ].r.end).subtract(twoPercentDays, 'days').toISOString();
  const end = moment(v.temporal.ranges[ v.temporal.ranges.length - 1 ].r.start)
    .add(twoPercentDays, 'days').toISOString();

  return {
    temporal: {
      ranges: v.temporal.ranges.reduce(
        (a, it) => (it.v === undefined || it.v === 'Not Valid'
          ? a
          : a.concat(
            it.r.start === START_TIME
              ? { ...it, r: { ...it.r, start } }
              : it.r.end === END_TIME
                ? { ...it, r: { ...it.r, end } }
                : it,
          )),
        /** @type { typeof v.temporal.ranges } */([]),
      ),
    },
  };
};


/** @type { ( n : Node, acc: typeof defaultAcc ) => typeof acc } */
function aggregateInfo(node, acc = cloneDeep(defaultAcc)) {
  /** @type { typeof node } */
  const normalizedNode = isTemporal(node.value)
    ? { ...node, value: normalizeTemporalDatesAndRemoveUnknowns(node.value) }
    : node;


  if (normalizedNode.value !== undefined && normalizedNode.value !== 'Not Valid') {
    acc.entries.push(normalizedNode);
  }


  if (isTemporal(normalizedNode.value) && normalizedNode.value.temporal.ranges?.length > 0) {
    const { ranges } = normalizedNode.value.temporal;
    const minDate = ranges[ 0 ].r.start;
    const maxDate = ranges[ ranges.length - 1 ].r.end;

    if (acc.minDate === -1 || acc.minDate > minDate) {
      acc.minDate = minDate;
    }

    if (acc.maxDate === -1 || acc.maxDate < maxDate) {
      acc.maxDate = maxDate;
    }
  }


  if (!normalizedNode.children || normalizedNode.children.length === 0) return acc;

  return normalizedNode.children.reduce(
    (a, child) => aggregateInfo(child, a),
    acc,
  );
}

// ===================================================================================

/** @type { ( arg: Node[ 'value' ] ) => arg is Exclude< Node['value'], PrimitiveValue > } */
const isTemporal = arg => (
  typeof arg === 'object' &&
  arg !== null &&
  'temporal' in arg
);

/** @type { ( decision: { report: { [key: string ]: Node } } ) => import( 'timelines-chart' ).Group } */
const accumulateReportNodes = decision => {
  const report = typeof decision.report === 'string'
    ? JSON.parse(decision.report)
    : decision.report;

  if(!report) {
    /** @type { import( 'timelines-chart' ).Group } */
    const rtrn = { group: 'Global', data: [] };

    return rtrn;
  }

  const rootNode = report[ Object.keys(report)[ 0 ] ];
  const { entries, maxDate, minDate } = aggregateInfo(rootNode);
  console.log('maxDate', maxDate, 'minDate', minDate);
  const globalStart = new Date(minDate);
  const globalEnd = new Date(maxDate);

  /** @type { import( 'timelines-chart' ).Group } */
  const globalGroup = {
    group: 'Global',
    data: entries.map(it => {
      if (isTemporal(it.value)) {
        const totalDays = it.value.temporal.ranges.reduce(
          (a, it) => (
            (it.r.start === START_TIME || it.r.end === END_TIME)
              ? a
              : a + moment(it.r.end).diff(moment(it.r.start), 'days')
          ),
          0,
        );

        const twoPercentDays = Math.floor(totalDays * 0.02);

        /** @type { import( 'timelines-chart' ).Line } */
        const line = {
          label: it.text,
          data: it.value.temporal.ranges.map(it => {
            const start = new Date(
              it.r.start === START_TIME
                ? moment(it.r.end).subtract(twoPercentDays, 'days').toISOString()
                : it.r.start,
            );
            const end = new Date(
              it.r.end === END_TIME
                ? moment(it.r.start).add(twoPercentDays, 'days').toISOString()
                : it.r.end,
            );

            return {
              val: localFormatValue(it.v),
              timeRange: [start, end],
            };
          }),
        };

        return line;
      }


      /** @type { import( 'timelines-chart' ).Line } */
      const line = {
        label: it.text,
        data: [
          {
            val: localFormatValue(it.value),
            timeRange: [globalStart, globalEnd],
          },
        ],
      };

      return line;
    }),
  };

  return globalGroup;
};

// ===================================================================================


const Wrap = styled.div`
  width: 100%;
  overflow: auto;

  .legendG { display: none; }
  /* .series-segment{ height: 1.75rem; } */
`;


/** @type { React.FC< { decision: any } > } */
export const _ = React.memo(({ decision }) => {
  /** @type { React.RefObject< HTMLDivElement > } */
  const ref = React.useRef(null);

  const timelinesChart = React.useMemo(() => (
    TimelinesChart()
  ), []);

  React.useEffect(() => {
    if (!ref.current) return;

    const { width } = ref.current.getBoundingClientRect();
    const data = accumulateReportNodes(decision);
    /** @type { typeof data } */
    const withLastDummyRow = {
      ...data,
      data: data.data.concat({ label: '', data: [] }),
    };

    timelinesChart(ref.current)
      // .zScaleLabel('My Scale Units')
      .zQualitative(true)
      .width(width - 85)
      .maxLineHeight(LINE_HEIGHT)
      .data([withLastDummyRow])
      .maxHeight(data.data.length * LINE_HEIGHT)
      .timeFormat('%Y-%m-%d')
      .bottomMargin(20)
      .refresh();
  }, [decision, timelinesChart]);

  return (
    <Wrap>
      <div ref={ref} />
    </Wrap>
  );
});
_.displayName = 'DecisionDashboard/Reports/Details/UnifiedTimeline';
