import moment from "moment";
import type { Range } from "@pages/decisions/DecisionDashboard/Reports/Details/UnifiedTimeline/types";
// import { END_TIME, START_TIME } from "@pages/decisions/DecisionDashboard/Reports/Details/TempTimeline/redux";


type IncrementV = Extract< Range["v"], { increment: string } >;
const isIncrementValue = (v: Range["v"]): v is IncrementV => (
  typeof v === "object" && v !== null && typeof (v as IncrementV).increment === "string"
);

export const normalizeRanges = (rs: Range[]): typeof rs => (
  rs.reduce< typeof rs >(
    (a, range) => {
      const { v, r } = range;

      if (!isIncrementValue(v)) return a.concat(range);

      const offset = v.offset || 0;

      const start = moment.utc(r.start);
      const end = moment.utc(r.end);


      a.push( ...new Array( end.clone().diff(start, v.increment) ).fill(0).map< Range >((_, i) => ({
        v: offset + i,
        r: {
          start: start.clone().add(i, v.increment).toISOString(),
          end: start.clone().add(i + 1, v.increment).toISOString(),
        }
      })) );

      return a;
    },
    []
  )
);

// export const TEMP_fix_ranges = (rs: Range[]): typeof rs => {
//   debugger;
//   return (
//     rs.map(range => {
//       const { r } = range;

//       if (typeof r.start === "string" && typeof r.end === "string") {
//         return range;
//       }

//       const numStart = Number(r.start);
//       const numEnd = Number(r.end);

//       return {
//         ...range,
//         r: {
//           start: numStart === -8640000000000000 ? START_TIME : moment.utc(numStart).toISOString(),
//           end: numEnd === 8640000000000000 ? END_TIME : moment.utc(numEnd).toISOString(),
//         }
//       };
//     })
//   );
// };
