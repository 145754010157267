// @ts-nocheck Will fix later
import {
  type PlateEditor,
  type TElement,
  type TNode,
  getNextNode,
  getNode,
  getNodeAncestors,
  getNodeParent,
  getNodeString,
  getNodeTexts,
  getParentNode,
  getPreviousNode,
  getRange,
  insertNodes,
  moveNodes,
  removeNodes,
  setNodes,
  unwrapNodes,
  withoutNormalizing,
  wrapNodes,
} from "@udecode/plate";
import { Editor, type NodeEntry, Path } from "slate";
import { createParagraph, transformRange } from "../../plugins/util";
import { isInTable, isInTableHeader, isLastRow } from "../decisionTable/transforms";
import { ELEMENT_CONCLUSION, ELEMENT_CONDITION, ELEMENT_OPERATOR, ELEMENT_RULE } from "../elements";
import { ELEMENT_CONNECTOR, createConnector, insertConnector } from "../operator/connector";
import { createOperator } from "../operator/operatorTransforms";
import { createRule } from "./createRule";
import type { TRule } from "./rule.types";

// TODO make this into a util func
export const getNearestRule = (editor, path?: Path) => {
  if (path) {
    // check if path is a rule
    const node = getNode(editor, path);
    if (node.type === ELEMENT_RULE) return [node, path] as NodeEntry<TElement>;
  }
  // see if there is a rule above, ie we are in a ref or text
  const entry = Editor.above(editor, { at: path, match: (n) => (n as TNode).type === ELEMENT_RULE });
  if (entry !== undefined) {
    return entry;
  }
  throw new Error(`Path is not a rule: ${path}`);
};

export const isRule = (editor: PlateEditor, path: number[]) => {
  try {
    getNearestRule(editor, path);
    return true;
  } catch (e) {
    return false;
  }
};

export const getSelection = (editor: PlateEditor) => {
  return editor.selection?.anchor ?? undefined;
};

export const getRuleLevel = (editor: PlateEditor, path: number[], operatorOnly = false) => {
  const [...nodes] = getNodeAncestors(editor, path);
  return nodes.filter((n) => n[0].type === ELEMENT_OPERATOR || (!operatorOnly && n[0].type === ELEMENT_CONNECTOR))
    .length;
};

export const isLastChild = (editor: PlateEditor, path: number[]) => {
  const parent = getNodeParent(editor, path);
  if (parent.type === ELEMENT_OPERATOR) {
    console.log("isLastChild", parent.children.length === path[path.length - 1] + 1);
    return parent.children.length === path[path.length - 1] + 1;
  }
  // TODO if its at the root, should we compare against editor children?
  return false;
};

export const ruleToParagraphText = (node: TRule) => {
  // const text = node ? getNodeString(node) : "";
  const texts = node ? Array.from(getNodeTexts(node)) : [];
  const text = texts
    .map(([n]) => n.text)
    .map((t, i) => {
      // convert label into [label]
      if (texts.length > 1 && i === 0 && t.trim().length > 0) return `[${t}]`;
      return t;
    })
    .filter((t) => t.trim().length > 0)
    .join(" ");
  return text;
};

const findNodePath = (editor: PlateEditor, node: TNode) => {
  const nodes = Editor.nodes(editor, { mode: "all" });
  for (const [n, path] of nodes) {
    if (n === node) return path;
  }
  return undefined;
};

export const ruleToParagraph = (editor: PlateEditor, path: number[]) => {
  let node = getNode(editor, path);
  console.log("to para");
  if (getRuleLevel(editor, path) > 0) {
    // disallow transforming to paragraph this way
    //return;
  }
  // enforce path to point to rule
  if (node?.type !== ELEMENT_RULE) {
    const rule = getNearestRule(editor, path);
    node = rule[0];
    path = rule[1];
  }
  const text = ruleToParagraphText(node);
  withoutNormalizing(editor, () => {
    removeNodes(editor, { at: path });
    insertNodes(editor, createParagraph(text), { at: path, select: true });
  });
};

export const paragraphToRule = (editor: PlateEditor, path: number[], type = ELEMENT_CONCLUSION) => {
  const node = getNode(editor, path);
  const text = node ? getNodeString(node) : "";
  withoutNormalizing(editor, () => {
    removeNodes(editor, { at: path });
    insertNodes(editor, createRule(type, text), { at: path, select: true });
  });
};

export const getRuleType = (node: TElement) => {
  if (node.type !== ELEMENT_RULE) throw new Error("Cannot getRuleType: Node is not a rule node");
  // const [, text] = (node as TRule).children;
  // return text.type;
  return (node as TRule).expression;
};

export const setRuleType = (editor: PlateEditor, path: number[], type: string) => {
  console.log(`[setRuleType] at ${path} => ${type}`);
  // setNodes(editor, { type }, { at: [...path, 1] });
  setNodes(editor, { expression: type }, { at: path });
};

export const getSiblingNodes = (editor: PlateEditor, path: number[]) => {
  const prev = getPreviousNode(editor, { at: path }) ?? [undefined, undefined];
  const next = getNextNode(editor, { at: path }) ?? [undefined, undefined];
  console.log("siblings", { prev, next });
  return { prev, next };
};

export const isTableHeaderRow = (editor: PlateEditor, path: number[]) =>
  isInTable(editor, { at: path }) && isInTableHeader(editor);
export const isLastTableRow = (editor: PlateEditor, path: number[]) =>
  isInTable(editor, { at: path }) && isLastRow(editor, { at: path });

export const increaseRuleLevel = (editor: PlateEditor, path: number[]) => {
  const level = getRuleLevel(editor, path);
  // don't allow more than 6 levels
  if (level === 6) return;

  const [parentNode, parentPath] = getParentNode(editor, path);
  const { prev, next } = getSiblingNodes(editor, path);
  const [prevNode, prevPath] = prev;
  const [nextNode, nextPath] = next;

  // NEW conditons
  // we want to try an approach where we by default make things a child
  // if previous is an operator, we can still move into that
  // if previous is a connector, we can move into that
  // if previous is a rule, we can wrap into a connector

  if (parentNode.type === ELEMENT_CONNECTOR && path[path.length - 1] === 0) {
    // we actually just move the connector as if its the node
    return increaseRuleLevel(editor, parentPath);
  }

  // DISABLED as we want to allow using tab to insert operator at this location
  // when its conclusion with 1 child, the previous will be the conclusion
  // if (prevNode?.type === ELEMENT_RULE && getRuleType(prevNode) === ELEMENT_CONCLUSION) {
  //   if (parentNode?.type === ELEMENT_CONNECTOR) {
  //     // we dont to do anything if its the single child of a conclusion
  //     return true;
  //   }
  //   // fall through to default behaviour
  // }

  if (prevNode?.type === ELEMENT_OPERATOR && nextNode?.type === ELEMENT_OPERATOR) {
    // we need to join operators
    // first move into previous
    moveNodes(editor, { at: path, to: [...prevPath, prevNode.children.length] });
    // generic join operator code will apply to fix the rest
    return true;
  }

  if (prevNode?.type === ELEMENT_OPERATOR) {
    // move into previous operator
    moveNodes(editor, { at: path, to: [...prevPath, prevNode.children.length] });
    return true;
  }

  // no longer support tab in, instead, they must use hotkey, button or enter from within
  // exception, is level 0, we want to allow tabbing into the connector/operator
  // NOTE we want to check all previous node group possibilities first
  if (prevNode?.type === ELEMENT_CONNECTOR && level === 0) {
    // move into previous connector
    moveNodes(editor, { at: path, to: [...prevPath, prevNode.children.length] });
    return true;
  }

  // we want to check previous rule last, as the user likely wants to add to existing groups
  // we also want to check if level 0, as tab after that should insert operators
  if (prevNode?.type === ELEMENT_RULE && level === 0) {
    insertConnector(editor, path);
    return true;
  }

  // check the next operator after checking for a connector
  if (nextNode?.type === ELEMENT_OPERATOR) {
    // move into next operator
    moveNodes(editor, { at: path, to: [...nextPath, 0] });
    return true;
  }

  // do not check for a next connector, as that would be weird

  // you have hit tab on the first child node
  // do nothing, as we have nothing to child into and inserting an operator would be weird

  // LEGACY indent and add a new operator
  wrapNodes(editor, createOperator(), { at: path });
  return true;
};

export const decreaseRuleLevel = (editor: PlateEditor, path: number[]) => {
  const node = getNode(editor, path);
  const level = getRuleLevel(editor, path);

  // we have no parent, so the 'decrease' is to convert to plain text
  if (level === 0 && node?.type === ELEMENT_RULE) {
    // remove empty rule
    ruleToParagraph(editor, path);
    // no need to unwrap, just return
    return true;
  }

  // this may not be an operator
  // this is actually going to be the parent, that could be a connector or operator
  const [parent, parentPath] = getParentNode(editor, path);

  if (level === 1 && node?.type === ELEMENT_RULE) {
    // its going to level 0, ensure its a conclusion
    // don't move it, let the following code handle that
    setRuleType(editor, path, ELEMENT_CONCLUSION);
  }

  // if only child, unwrap
  if (parent.children.length === 1) {
    // the connector should auto do this via normalisation
    // so likely this only runs for an operator
    unwrapNodes(editor, { at: parentPath });
    return true;
  }

  // if first child, move before
  // OK these need to change for connectors...
  // the first child of a connector is the 'parent'
  // so what happens if you shift tab it?
  // connect needs special case, as first child is the parent and second is the 'first'
  // if we shift tab the parent, move the entire connector, unless its first level, then do nothing
  // if we shift tab the second, make it the new parent, previous parent is moved out
  if (parent.type === ELEMENT_CONNECTOR) {
    if (path[path.length - 1] === 0) {
      if (level === 1) return true; // do nothing
      // actually this needs to be a split, can we just pass decreaseRuleLevel for the parent?
      // moveNodes(editor, { at: path, to: parentPath });
      return decreaseRuleLevel(editor, parentPath);
    }
  } else if (parent.type === ELEMENT_OPERATOR) {
    // this is, move the first child before the parent
    if (path[path.length - 1] === 0) {
      const destination = parentPath;
      const grandParent = getParentNode(editor, parentPath);
      // if grand parent is connector, we actually want to make this the new parent
      if (grandParent && grandParent[0].type === ELEMENT_CONNECTOR) {
        withoutNormalizing(editor, () => {
          const target = Path.next(grandParent[1]);
          // move the operator to the destination
          moveNodes(editor, { at: parentPath, to: target });
          // then wrap in a connector
          wrapNodes(editor, createConnector(), { at: target });
          // unwrap the inner operator
          unwrapNodes(editor, { at: [...target, 0] });
        });
        return true;
      }
      moveNodes(editor, { at: path, to: destination });
      return true;
    }
  }

  // TODO add support for split when in a connector?
  // this is more complicated than it seems and has an impact on indenting as well

  // if last child, move after
  // this is working fine for connectors and seems to make sense for both
  // when we have a connector with 2 children, this will auto convert into 2 codes
  if (path[path.length - 1] === parent.children.length - 1) {
    let destination = parentPath;
    const grandParent = getParentNode(editor, parentPath);
    if (grandParent && grandParent[0].type === ELEMENT_CONNECTOR) {
      destination = grandParent[1]; // parent path
    }
    moveNodes(editor, { at: path, to: Path.next(destination) });
    return true;
  }

  // middle child, split it

  if (parent.type === ELEMENT_OPERATOR) {
    // NOTE ORDER IS VERY IMPORTANT
    // instead, wrapNode, move out, then move last node out
    const wrapFrom = Path.next(path);
    const wrapTo = [...parentPath, parent.children.length - 1];
    const wrapRange = Path.compare(wrapFrom, wrapTo) === 0 ? wrapFrom : getRange(editor, wrapFrom, wrapTo);

    // first wrap all next nodes into a new operator
    wrapNodes(editor, createOperator([], parent.operator), { at: wrapRange });
    // path is still og node
    // move og node next to existing operator
    const newNodePath = Path.next(parentPath);
    moveNodes(editor, { at: path, to: newNodePath });
    const fromOpPath = path; // new op is now in old path position
    const newOperatorPath = Path.next(newNodePath);
    // move new operator next to new node position
    moveNodes(editor, { at: fromOpPath, to: newOperatorPath });
  } else if (parent.type === ELEMENT_CONNECTOR) {
    // TODO implement connector split
    console.log("Connector split not implemented");
    //debugger;
  }

  return true;
};

export const handleTabCondition = (editor: PlateEditor, path: number[], shift = false) => {
  return shift ? decreaseRuleLevel(editor, path) : increaseRuleLevel(editor, path);
};

export const handleTabConclusion = (editor: PlateEditor, rulePath: number[], shift = false) => {
  // TODO do no action if table header, first conclusion of table row, or last row of table
  if (isInTable(editor, { at: rulePath }) || isLastTableRow(editor, rulePath)) {
    //debugger;
    return true;
  }

  // how do we determine levels now? operator only or include connectors?
  const level = getRuleLevel(editor, rulePath);
  // shift tab converts to paragraph, if it has no parents
  if (shift) {
    return decreaseRuleLevel(editor, rulePath);
  }

  return increaseRuleLevel(editor, rulePath);

  // what happens when we tab? need to look at previous node and determine appropriate behaviour
  const [prevNode, prevPath] = getPreviousNode<TElement>(editor, { at: rulePath }) ?? [undefined, undefined];
  if (prevNode && prevPath) {
    // we now have 4 previous node types
    // generally, we no longer want to convert it, as conclusions can be at any level
    // TODO i think this is just a basic version of increaseRuleLevel
    if (prevNode.type === ELEMENT_OPERATOR) {
      withoutNormalizing(editor, () => {
        // setRuleType(editor, rulePath, ELEMENT_CONDITION);
        // move into previous operator
        moveNodes(editor, { at: rulePath, to: [...prevPath, prevNode.children.length] });
      });
      return true;
    }
    if (prevNode.type === ELEMENT_CONNECTOR) {
      withoutNormalizing(editor, () => {
        // setRuleType(editor, rulePath, ELEMENT_CONDITION);
        // move into previous connector. just add to the end and it will handle itself
        moveNodes(editor, { at: rulePath, to: [...prevPath, prevNode.children.length] });
      });
      return true;
    }

    if (prevNode.type === ELEMENT_RULE) {
      const ptype = getRuleType(prevNode);
      withoutNormalizing(editor, () => {
        if (ptype === ELEMENT_CONDITION) {
          // do the original behaviour and create a new operator
          wrapNodes(editor, createOperator([]), { at: rulePath });
        } else {
          // is a conclusion, wrap into connector (ie make child)
          const range = {
            anchor: { path: prevPath, offset: 0 },
            focus: { path: rulePath, offset: 0 },
          };
          // here we WANT to convert it to a condition, as the user likely wants that
          setRuleType(editor, rulePath, ELEMENT_CONDITION);
          wrapNodes(editor, createConnector(), { at: range });
        }
        // LEGACY, changed the type and wrapped it in an operator
        // setRuleType(editor, rulePath, ELEMENT_CONDITION);
        // // make operator and move into it
        // wrapNodes(editor, createOperator([]), { at: rulePath });
      });
      return true;
    }
  }
  // otherwise no action
  return true;
};

export const handleTab = (editor: PlateEditor, shift = false) => {
  return transformRange(
    editor,
    (n) => n.type === ELEMENT_RULE,
    ([rule, path]) => {
      const type = getRuleType(rule);
      if (type === ELEMENT_CONDITION) handleTabCondition(editor, path, shift);
      if (type === ELEMENT_CONCLUSION) handleTabConclusion(editor, path, shift);
    },
  );
};
