export const OPM_TYPES = {
  CONDITION: 'OPM-Condition',
  CONCLUSION: 'OPM-Conclusion',
  ELSE: 'OPM-Else',
};
// TODO this guy seems to fail in Firefox
export const typeFromHeading = type => type.match(/(?<type>.*) Heading/).groups.type;

export const newSheet = title => ({
  title,
  headings: [{
    type: `${ OPM_TYPES.CONDITION } Heading`,
    legend: '',
  }, {
    type: `${ OPM_TYPES.CONCLUSION } Heading`,
    legend: '',
  }],
  rows: [
    [{
      type: OPM_TYPES.CONDITION,
      text: '',
    }, {
      type: OPM_TYPES.CONCLUSION,
      text: '',
    }],
    [{
      type: OPM_TYPES.ELSE,
      text: 'else',
    }, {
      type: OPM_TYPES.CONCLUSION,
      text: '',
    }],
  ],
});

export const newApplySheet = () => ({
  title: 'Apply',
  headings: [{
    type: `${ OPM_TYPES.CONDITION } Heading`,
    legend: 'Condition',
  }, {
    type: `${ OPM_TYPES.CONCLUSION } Heading`,
    legend: 'Apply Sheet',
  }],
  rows: [
    [{
      type: OPM_TYPES.CONDITION,
      text: '',
    }, {
      type: OPM_TYPES.CONCLUSION,
      text: '',
    }],
  ],
});
