import React from 'react';
import { Store, DeriveFromState } from './__store';


export const defaultCompare: < V >(a: V, b: V) => boolean = (a, b) => a === b;


export type Rtrn< StoreState > = {
  ctx: React.Context< Store< StoreState > >;
  useSelector: < V >(f: DeriveFromState< StoreState, V >, compare?: typeof defaultCompare) => V;
  useUpdate: () => Store< StoreState >[ 'update' ];
  useDerive: () => Store< StoreState >[ 'deriveFromState' ];
  WithCtx: React.ComponentType< React.PropsWithChildren< Record< string, unknown > > >;
};


export function init< StoreState >(
  ...[initalState, autoFreeze]: ConstructorParameters< typeof Store< StoreState > >
): Rtrn< StoreState > {
  const store = new Store(initalState, autoFreeze);
  const ctx = React.createContext(store);
  const { Provider } = ctx;

  return {
    ctx,
    useSelector: function useSelector(f, compare = defaultCompare) {
      const v = React.useContext(ctx);
      const [state, setState] = React.useState(v.deriveFromState(f));

      // ===================================================================================

      const fRef = React.useRef(f);
      if(fRef.current !== f) fRef.current = f;

      const compareRef = React.useRef(compare);
      if(compareRef.current !== compare) compareRef.current = compare;

      // ===================================================================================

      const subscribeHandler = React.useCallback< Parameters< typeof store.subscribe >[ 0 ] >(
        storeState => setState(prev => {
          const next = fRef.current(storeState);

          return compareRef.current(prev, next) ? prev : next;
        }),
        [],
      );

      // ===================================================================================

      /**
       * there exists a possibility that "update" to store runs after\
       * we have called setState(v.deriveFromState) here, but before \
       * useEffect has a chance to run. This causes the issue where \
       * subscriber cannot get the update (as it is not subscribed yet).\
       * Instead we will subscribe synchronously, using ref, but will \
       * unsubscribe in useEffect teardown function
       */
      const syncSubscribedRef = React.useRef(false);
      if(syncSubscribedRef.current === false) {
        syncSubscribedRef.current = true;
        v.subscribe(subscribeHandler);
      }

      // ===================================================================================

      React.useEffect(() => () => {
        v.unsubscribe(subscribeHandler);
      }, [v, subscribeHandler]);

      return state;
    },
    useUpdate: () => React.useContext(ctx).update,
    useDerive: () => React.useContext(ctx).deriveFromState,
    WithCtx: ({ children }) => (
      <Provider value={store}>{children}</Provider>
    ),
  };
}

