import type { TSection } from "@common/editor/components";
import { ELEMENT_SECTION } from "@common/editor/components/section/sectionConstants";
import { nodesToText } from "@common/editor/plugins/util";
import type { TNode } from "@udecode/plate-core";
import { v4 as uuidv4 } from "uuid";

/** Attempts to derive a reasonable auto section name */
export const deriveSectionName = (nodes: TNode[]) => {
  let sname =
    nodes.length > 0
      ? nodesToText(nodes[0])
          .join(" ")
          .split(/[:;,-]/)[0]
      : "";
  // strip trailing whitespace and convert tabs to single space
  sname = sname.replace(/\s+$/, "").replace(/\t/g, " ");
  // if name ends with a number of letter, attempt incrementing it
  // TODO the above split impacts this logic
  // TODO im not sure we want to do this hear, but we did want it somewhere
  // const match = sname.match(/(\d+|[a-z]+)$/i);
  // if (match) {
  //   const [, last] = match;
  //   if (last.match(/^\d+$/)) {
  //     sname = sname.replace(/\d+$/, (n) => (parseInt(n) + 1).toString());
  //   } else {
  //     // its a number, if integer, increment, if float, increase decemal
  //     const num = parseFloat(last);
  //     if (!isNaN(num)) {
  //       const dec = num - Math.floor(num);
  //       if (dec > 0) {
  //         sname = sname.replace(/(\d+)$/, (n) => (num + 0.1).toString());
  //       } else {
  //         sname = sname.replace(/(\d+)$/, (n) => (num + 1).toString());
  //       }
  //     }
  //   }
  // }
  return sname;
};

export const createSection = (id: string = uuidv4(), children: TNode[] = []) =>
  ({
    type: ELEMENT_SECTION,
    id,
    children,
  }) as TSection;
