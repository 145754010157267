import styled from "styled-components";
import { Link } from "react-router-dom";
import { Typography, Card } from "@material-ui/core";
import { Button } from "@components";

const Info = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 2rem 4rem;
`;

function Page404() {
  return (
    <Info>
      <Typography component="h1" variant="h1" align="center">
        404
      </Typography>

      <div>
        <Typography component="h2" variant="h5" align="center">
          Page not found.
        </Typography>
        <Typography variant="body1" align="center">
          The page you are looking for might have been removed.
        </Typography>
      </div>

      <Button component={Link} to="/" variant="contained">
        Return to website
      </Button>
    </Info>
  );
}

export default Page404;
