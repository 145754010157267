export const MuiTabStyle = (theme: any) => ({
  root: {
    '--tab-font-weight': 600,
    '--tab-selected-bg': theme.palette.secondary.main,
    '--tab-selected-txt': theme.palette.secondary.contrastText,
    '--tab-unselected-bg': theme.palette.secondary.contrastText,
    '--tab-unselected-txt': theme.palette.secondary.main,
    '--tab-hover-bg': theme.palette.secondary.hover,
    '--tab-border-color': theme.palette.secondary.main,
    '--tab-border-width': '1px',
  },
  tabContainer: {
    '.MuiTabs-root': {
      minWidth: 'unset',
      width: 'auto',
    },
    '.MuiTabs-flexContainer': {
      margin: 0,
      display: 'inline-flex',
    },
  },
  tab: {
    '&:hover': {
      backgroundColor: 'var(--tab-hover-bg)',
    },
    '&.Mui-selected': {
      backgroundColor: 'var(--tab-selected-bg)',
      color: 'var(--tab-selected-txt)',
    },
  },
  indicator: {
    display: 'none',
  },
});
