import type { TestCaseSortTItem } from '../../types';


export type ForEachSortItemArg = {
  item: TestCaseSortTItem;
  cb: ( item: TestCaseSortTItem ) => unknown;
};
export const forEachSortItem = (arg: ForEachSortItemArg): void => {
  const { cb, item } = arg;
  cb(item);

  item.children.forEach(child => forEachSortItem({ item: child, cb }));
}
