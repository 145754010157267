import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Link, matchPath, useLocation } from "react-router-dom";
import { ProSidebar, SidebarContent, Menu, MenuItem, SubMenu, SidebarFooter } from "react-pro-sidebar";
import { useLocalStorage } from "react-use";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { Badge, Switch, Checkbox } from "@components";
import { ArrowleftIcon, ArrowrightIcon } from "@icons";
import "./SidebarLayout.module.scss";
import SimpleBar from "simplebar-react";
import * as reduxNS from "./redux";
import { config } from "@config";
import { useTranslation } from "react-i18next";

const SideBanner = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  padding-bottom: 2rem;
  width: 2rem;
  color: ${ props => props.theme.sidebar.color };
  background: ${ props => props.theme.sidebar.background };
`;

const Copyright = styled(Typography)`
  line-height: 2rem;
  white-space: nowrap;
  transform-origin: center;
  transform: rotate(-90deg);
`;

const CopyrightOffset = styled(Typography)`
  /* line-height: calc(65px - 2rem; */
  white-space: nowrap;
  transform-origin: center;
  /* transform: rotate(-90deg) translate(0px, -0.625rem); */
  transform: rotate(-90deg) translate(-1rem, 0.125rem);
  transform-origin: top left;
  width: calc(100vh - 18.75rem);
  overflow: hidden;
  text-overflow: ellipsis;
`;

const HideButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.875rem;
  height: 1.875rem;
  background-color: ${ props => props.theme.palette.primary.main };
  border-radius: 50%;
  position: relative;
  left: -0.875rem;
  top: 37px;
  z-index: 110;
  box-shadow: 0px 0px 1rem rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border: none;

  &:focus-visible { outline: none; }

  svg {
    width: 1.375rem;
    height: 1.375rem;
  }
`;

const NoWidth = styled.div`
  position: relative;
  width: 0;
`;

const HiddenText = styled.span`
  font-size: 0.75rem;
  font-style: italic;
  color: ${ props => props.theme.sidebar.color };
`;

const SpacedGrid = styled(Grid)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: ${ props => props.space || "1rem" };
`;

const EditSwitch = withStyles(theme => ({
  switchBase: {
    "&$checked": {
      "& + $track": {
        border: `1px solid ${ theme.sidebar.light } !important`,
        backgroundColor: theme.sidebar.light,
      },
      "& $thumb": {
        backgroundColor: theme.sidebar.background,
      },
    },
  },
  thumb: {
    backgroundColor: theme.sidebar.light,
  },
  track: {
    border: `1px solid ${ theme.sidebar.light }`,
    backgroundColor: theme.sidebar.background,
  },
  checked: {},
}))(Switch);

const SidebarThemeProvider = styled.div`
  --sidebar-color: ${ props => props.theme.sidebar.color };
  --sidebar-background: ${ props => props.theme.sidebar.background };
  --sidebar-accent: ${ props => props.theme.palette.primary.main };
  --sidebar-hovermenu-color: ${ props => props.theme.sidebar.background };
  --sidebar-hovermenu-background: ${ props => props.theme.sidebar.light };
  --sidebar-hovermenu-hover: ${ props => props.theme.sidebar.hover };
  --box-shadow: ${ props => props.theme.body.boxShadow };
  ${ p => (p.collapsed ? ".simplebar-content {height: 100%;}" : "") }

  .pro-sidebar-content {
    height: 100%;
  }

  .collapsed {
    [data-hasactive="true"], .pro-menu-item:hover {
      color: var(--sidebar-accent);
    }
  }
`;

const EditContext = React.createContext(false);

const useEditContext = () => {
  return useContext(EditContext);
};

const MenuCheckbox = withStyles(theme => ({
  root: {
    color: theme.sidebar.background,
    borderColor: `${ theme.sidebar.light }!important`,
  },
}))(({ classes, ...props }) => <Checkbox color='inherit' classes={{ ...classes }} {...props} />);

const Items = ({ items }) => {
  const { search } = useLocation();
  const { t } = useTranslation();
  const { editMode, getChecked, setChecked, collapsed } = useEditContext();
  const getKey = item => (item.group ? `${ item.group.name }/${ item.name }` : item.name);
  const hasMixedChildValues = item => {
    if (item.items) {
      return !item.items.every((v, i, l) => {
        const a = getChecked(getKey(v));
        const b = getChecked(getKey(l[ i - 1 ] ?? v));
        return a === b;
      });
    }

    return false;
  };
  // eslint-disable-next-line complexity
  return items.map(item => {
    if (item.items) {
      // is group
      const mixed = hasMixedChildValues(item);
      const checked = mixed || getChecked(getKey(item.items[ 0 ]));

      const handleChange = e => {
        e.stopPropagation();
        const update = item.items.reduce((acc, i) => ({ ...acc, [ getKey(i) ]: !checked }), {});
        setChecked(update);
      };

      if (!editMode && !checked) return null;

      const itemProps = editMode
        ? {
          prefix: (
            <MenuCheckbox checked={checked} indeterminate={mixed || null} onChange={handleChange} />
          ),
          open: true,
          // onClick: handleChange,
        }
        : {
          icon: item.icon,
          // force open if collapsed otherwise hover won't work
          open: collapsed || undefined,
        };

      const hasActive = item.items.some(it => it.active);
      return (
        <SubMenu
          key={`sidebar-submenu-${ item.name }`}
          title={item.name}
          data-hasactive={String(hasActive)}
          defaultOpen={item.open || false}
          {...itemProps}
        >
          <Items items={item.items} />
        </SubMenu>
      );
    }

    const itemKey = getKey(item);
    const enabled = getChecked(itemKey);

    if (!editMode && !enabled) return null;

    const handleChange = e => {
      e.stopPropagation();
      setChecked({ [ itemKey ]: !enabled });
    };

    const itemProps = editMode
      ? {
        prefix: (
          <MenuCheckbox
            checked={enabled}
            indeterminate={hasMixedChildValues(item) || null}
            onChange={handleChange}
          />
        ),
        // onClick: handleChange,
      }
      : {
        icon: item.icon,
      };

    const link = (item.path instanceof Array ? item.path[ 0 ] : item.path) + search;
    return (
      <MenuItem
        data-link={link}
        key={`sidebar-item-${ item.name }`}
        active={item.active}
        {...itemProps}
      >
        {item.translationKey ? t(item.translationKey) : item.name}
        {!editMode && <Link to={link} />}
      </MenuItem>
    );
  });
};

const groupRoutes = (acc, route) => {
  const { sidebar, path, group, active } = route;
  const { name, icon, translationKey } = sidebar;
  const item = { name, path, icon, active, group, translationKey };
  if (group) {
    const g = acc.find(i => i.name === group.name);
    if (g) {
      g.items.push(item);
    } else {
      acc.push({ name: group.name, icon: group.icon, open: group.open, items: [item] });
    }
  } else {
    acc.push(item);
  }

  return acc;
};

const appVersion = `${config.APP_DATE} (#${config.APP_HASH})`;

const rbac = {
  user: {
    "/build/Testing/Run": true,
    "/build/Testing/Debug": true,
    "/build/Interview/Editor": true,
    "/build/Interview/Translate": true,
    "/build/Testing/Graph": true,
    "/build/Testing/Batch": true,
    "/build/Implementation/API": true,
    "/build/Implementation/Data model": true,
    "/build/Implementation/Rule graph": true,
    "/build/Documents": true,
    "/build/Reports": true,
    "/build/Designs/Conversation": false,
  },
};

/**
 *
 * @param {{routes: any[]}} props
 */
export const Sidebar = ({ routes, location, match }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(state => state.auth.user);
  const [editMode, setEditMode] = useState(false);
  const [sidebar, setSidebar] = useLocalStorage("sidebar", {});

  const collapsed = useSelector(s => reduxNS.getState(s).isCollapsed);
  const setIsCollapsed = React.useCallback(() => dispatch(reduxNS.aCreators.requestToToggle()), [dispatch]);

  const toggleSidebar = React.useCallback(() => {
    if (!collapsed) {
      // disable edit mode if collapsing sidebar
      setEditMode(false);
    }

    setIsCollapsed();
  }, [collapsed, setIsCollapsed]);

  useEffect(() => {
    const role = user.roles[ 0 ];
    const defaultSidebar = rbac[ role ];
    // load default sidebar settings with user overrides
    setSidebar({ ...defaultSidebar, ...sidebar });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // no dependencies as we want only on mount
  }, []);

  const matchPathProp = match.path;
  const getChecked = React.useCallback(key => {
    return sidebar[ `${ matchPathProp }/${ key }` ] ?? true;
  }, [sidebar, matchPathProp]);

  const setChecked = React.useCallback(update => {
    const settings = Object.keys(update)
      .reduce((acc, key) => ({ ...acc, [ `${ matchPathProp }/${ key }` ]: update[ key ] }), {});

    setSidebar({ ...sidebar, ...settings });
  }, [matchPathProp, sidebar, setSidebar]);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const editContext = React.useMemo(
    () => ({ editMode, collapsed, getChecked, setChecked }),
    [editMode, collapsed, getChecked, setChecked],
  );

  const copyrightText = `© ${ new Date().getFullYear() } ${t('powered_by_decisively')}`;

  if (!routes || routes.length === 0) {
    return (
      <SideBanner>
        <Copyright variant='caption'>{copyrightText} - {appVersion}</Copyright>
      </SideBanner>
    );
  }

  const pathBase = path => path.split("/")[ 0 ];
  const hiddenCount = Object.entries(sidebar).filter(
    ([k, v]) => pathBase(k) === pathBase(location.pathname) && v === false,
  ).length;

  const menuItems = routes
    // filter by items that should be in the sidebar
    .filter(r => r.sidebar)
    .map(r => ({ ...r, active: matchPath(location.pathname, r.path)?.isExact || false }))
    // change data structure to form groups
    .reduce(groupRoutes, []);

  return (
    <>
      <SidebarThemeProvider collapsed={collapsed}>
        <ProSidebar width={256} collapsed={collapsed}>
          <SimpleBar style={collapsed ? { height: "100%" } : { height: "calc(100% - 102px)" }}>
            <SidebarContent>
              <Menu iconShape='square'>
                <EditContext.Provider value={editContext}>
                  <Items items={menuItems} />
                </EditContext.Provider>
              </Menu>
              {collapsed && <CopyrightOffset variant='caption'>{copyrightText} - {appVersion}</CopyrightOffset>}
            </SidebarContent>
          </SimpleBar>
          <SidebarFooter hidden={collapsed}>
            <Grid
              container
              alignItems='center'
              justifyContent='space-between'
              wrap='nowrap'
              style={{ marginBottom: "1.5rem" }}
            >
              <EditSwitch label='Access' checked={editMode} onChange={toggleEditMode} name='editAccess' labelStyle={{ gap: "0.5rem" }} />
              <SpacedGrid item space='4px'>
                <Badge size='small'>{hiddenCount}</Badge>
                <HiddenText>Hidden</HiddenText>
              </SpacedGrid>
            </Grid>
            <Typography variant='caption'>{copyrightText} {appVersion}</Typography>
          </SidebarFooter>
        </ProSidebar>
      </SidebarThemeProvider>
      <NoWidth>
        <HideButton onClick={toggleSidebar}>{collapsed ? <ArrowrightIcon /> : <ArrowleftIcon />}</HideButton>
      </NoWidth>
    </>
  );
};
