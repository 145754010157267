import { AddrowbelowIcon, AddtableIcon, DeleterowIcon } from "@icons";

import { hotkeyTooltip } from "@common/HotkeyTooltip";
import { ELEMENT_PARAGRAPH } from "@udecode/plate";
import type { PlateEditor } from "@udecode/plate-core";
import { getSelectedNode, not } from "../../plugins/util";
import { isWithinOperator } from "../operator/operatorTransforms";
import { TableToolbarButton } from "./TableToolbarButton";
import { deleteRow, insertRow, insertTable, isInTable } from "./transforms";

const selectedNodeIsParagraph = (editor: PlateEditor) => {
  const [node] = getSelectedNode(editor);
  return node && node.type === ELEMENT_PARAGRAPH;
};

export const TablesToolbar = () => {
  return (
    <>
      <TableToolbarButton
        icon={<AddtableIcon />}
        tooltip="Insert Decision Table"
        // can only insert if in paragraph, not already in a table
        disable={(e) => !(selectedNodeIsParagraph(e) && !isInTable(e) && !isWithinOperator(e))}
        transform={insertTable}
      />
      <TableToolbarButton
        hideDisabled
        icon={<AddrowbelowIcon />}
        tooltip={hotkeyTooltip("Insert row", ["shift", "enter"])}
        transform={insertRow}
        disable={not(isInTable)}
      />
      <TableToolbarButton
        hideDisabled
        icon={<DeleterowIcon />}
        tooltip="Delete row"
        transform={deleteRow}
        disable={not(isInTable)}
      />
    </>
  );
};
