import { useCurrentRelease } from '@common/hooks';
import { Report } from './constants';
import { useScope } from '../../../../redux/scope';


export type Rtrn = undefined | Report;

export const defaultReport: Report = {
  goalId: '',
  id: '',
  nodes: {},
  name: '',
};

export const useCurrentReport = (): Rtrn => {
  const currentRelease = useCurrentRelease();
  const { decReport: reportId } = useScope();

  if(!currentRelease || !reportId) return undefined;

  const typedReports: Report[] = currentRelease.report || [];

  return typedReports.find(it => it.id === reportId);
};
