import { Tooltip, Typography } from "@material-ui/core";
import {
  ELEMENT_PARAGRAPH,
  type PlateEditor,
  type TElement,
  type TNode,
  createPluginFactory,
  getNextNode,
  getNode,
  getPreviousNode,
  insertNodes,
  moveNodes,
  setNodes,
  withoutNormalizing,
  wrapNodes,
} from "@udecode/plate";
import clsx from "clsx";
import { type MouseEventHandler, useState } from "react";
import { type Location, Path } from "slate";
import { ReactEditor } from "slate-react";
import styled from "styled-components";

import { HotkeyTooltip } from "@common/HotkeyTooltip";
import { getNearestElement, getSelectedNode, insertHR, normalizeRange, withHotKeys } from "@common/editor/plugins/util";
import { LinkButton } from "@components";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@components/radix";
import { WarningIcon } from "@icons";
import { ELEMENT_CONCLUSION, ELEMENT_CONDITION, ELEMENT_OPERATOR, ELEMENT_RULE } from "../elements";
import { createRule } from "../rule/createRule";
import { ELEMENT_CONNECTOR, insertConnector } from "./connector";
import { OPERATOR, type TOperator, createOperator, isValidParent, normalizeOperators } from "./operatorTransforms";
// import { useHotkey } from "@common/editor/RuleAuthor/util";
// import { Kbd } from "@components/kdb";

const OperatorContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  position: relative;

  // apply on the children, as the on the container
  // will conflict with parent operators
  > .and {
    --op-color: #a0f1a8;
  }
  > .or {
    --op-color: #d8b7f9;
  }

  > .op {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    /* margin: 0 0%.5 0 0; */
    position: relative;
    min-width: 3rem;
    max-width: 3rem;
    /* padding-top: 0.5rem; */

    &:before {
      content: "";
      position: absolute;
      top: 0.5rem;
      bottom: 0;
      border-left: 2px dashed #e5e5e5;
    }

    > span {
      /* display: none; // TODO  */
      color: black;
      background-color: var(--op-color);
      padding: 0 4px;
      border-radius: 8px;
      font-family: 'Montserrat';
      font-size: 12px;
      font-weight: 700;
      line-height: 1.5rem;
      min-width: 1.5rem;
      cursor: pointer;
      text-align: center;
      text-transform: capitalize;
      z-index: 1;
    }
  }

  > .content {
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    flex: 1;
  }

  &.multiChild > .content {
    // don't target inner text elements
    > :not(p):not(.slate-hr) {
      position: relative;
      padding: 0.25rem 0;
      --bottom-pos: calc(50% - 0.25rem);
      &.slate-operator {
        --bottom-pos: calc(100% - 1.25rem);
      }
      // vertical line
      &:before {
        content: "";
        position: absolute;
        top: -1rem;
        bottom: 0;
        left: calc(-1.5rem - 1px);
        width: 2px;
        background-color: var(--op-color);
        opacity: 1 !important;
      }
      // horizontal line
      &:not([data-slate-void="true"]):after {
        content: "";
        position: absolute;
        bottom: var(--bottom-pos);
        left: -1.5rem;
        width: 1rem;
        height: 2px;
        background-color: var(--op-color);
      }
      // if first child, set top on before element to 0.5rem
      &:first-child:before {
        top: 0.5rem;
      }
      // if last child, set bottom on before element to half way
      &:last-child:before {
        bottom: var(--bottom-pos);
      }
    }

    // inner text still requires the vertical bar, but draw using :after
    // as :before is used for the placeholder
    > p {
      position: relative;
      display: flex;
      align-items: center;
      min-height: 3rem;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: calc(-1.5rem - 1px);
        width: 2px;
        background-color: var(--op-color);
        opacity: 1 !important;
      }
      // different if p is first elem
      &:first-child:after {
        top: 0.5rem;
      }
    }

    > .slate-hr {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: calc(-1.5rem - 1px);
        width: 2px;
        background-color: var(--op-color);
        opacity: 1 !important;
      }
      // different if p is first elem
      &:first-child:after {
        top: 0.5rem;
      }
    }
  }

  &.warning {
    box-shadow: 0 0 0 2px ${(props) => props.theme.palette.warning.main};
    border-radius: 0.5rem;
    padding: 0.25rem 0.5rem 0.25rem 0;
  }

  /* &.multiChild {
    background-color: red;
  } */

  > .operator-warning {
    position: absolute;
    cursor: pointer;
    top: 0.5rem;
    right: 0.5rem;
    color: ${(props) => props.theme.palette.warning.main};
    border-radius: 0.5rem;
    background-color: ${(props) => props.theme.palette.warning.light};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const WarningContent = styled(HoverCardContent)`
  max-width: 20rem;
  min-width: 10rem !important;
  
  >.MuiTypography-root {
    padding: 0.5rem;
  }

  [data-actions] {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid ${(p) => p.theme.palette.background.border};
    padding: 0.5rem;
  }
`;

const toggleOperator = (editor: PlateEditor, current: OPERATOR, path: Location) => {
  const op = current === OPERATOR.OR ? OPERATOR.AND : OPERATOR.OR;
  setNodes<TNode>(editor, { operator: op }, { at: path });
};

const VALID_CHILDREN = [ELEMENT_RULE, ELEMENT_OPERATOR, ELEMENT_CONNECTOR];

type Warning = {
  message: string | null;
  fix: (() => void) | null;
  fixTooltip: string;
};

const getOperatorWarnings = (editor: PlateEditor, path: Path): Warning | null => {
  const node = getNode<TOperator>(editor, path);
  const hasRuleChildren = node?.children?.some((c) => VALID_CHILDREN.includes(c.type as string));
  if (!hasRuleChildren) {
    return {
      message: "Operator must have at least one rule",
      fix: () => insertNodes(editor, createRule(ELEMENT_CONDITION), { at: path.concat(0) }),
      fixTooltip: "Insert a new rule",
    };
  }

  const hasValidParent = isValidParent(editor, path);
  if (!hasValidParent) {
    // we can auto fix if the previous sibling is a connector or a conclusion
    let fix: (() => void) | null = null;
    let fixTooltip = "";
    const prev = getPreviousNode<TElement>(editor, { at: path });
    if (prev) {
      const [pNode, pPath] = prev;
      if (pNode.type === ELEMENT_CONNECTOR) {
        fix = () => moveNodes(editor, { at: path, to: pPath.concat(pNode.children.length) });
        fixTooltip = "Move into previous rule";
      }
      if (pNode.type === ELEMENT_RULE && pNode.expression === ELEMENT_CONCLUSION) {
        fix = () => insertConnector(editor, path); // same as pressing hotkey (ctrl+g) but on operator level
        fixTooltip = "Connect to previous conclusion";
      }
    }
    return {
      message: "Rule conditions must be part of a valid rule",
      fix,
      fixTooltip,
    };
  }

  return null;
};

const WarningTooltip = ({ warning: { message, fix, fixTooltip } }: { warning: Warning }) => {
  const [open, setOpen] = useState(false);

  // TODO doesn't work if focus is within plate :'(
  // useHotkey("mod+.", () => fix?.(), fix !== null && open);

  return (
    <HoverCard
      open={open}
      onOpenChange={setOpen}
    >
      <HoverCardTrigger className="operator-warning">
        <WarningIcon />
      </HoverCardTrigger>
      <WarningContent
        side="top"
        align="end"
      >
        <Typography
          contentEditable={false}
          variant="caption"
        >
          {message}
        </Typography>
        {fix ? (
          <div data-actions>
            <Tooltip
              title={fixTooltip}
              placement="left"
            >
              <LinkButton
                className="unselectable"
                contentEditable={false}
                onClick={fix}
              >
                Quick fix...
                {/* <Kbd inline variant="contained" delimiter="+" keys={["mod", "."]} /> */}
              </LinkButton>
            </Tooltip>
          </div>
        ) : null}
      </WarningContent>
    </HoverCard>
  );
};

export const Operator = ({ className, attributes, children, element, editor }: any) => {
  const path = ReactEditor.findPath(editor, element);
  // const level = getOperatorLevel(editor, path);

  const changeOperator: MouseEventHandler<HTMLSpanElement> = (e) => {
    e.preventDefault();
    toggleOperator(editor, element.operator, path);
  };

  // map operator to display label
  const displayLabel = element.operator === OPERATOR.OR ? "any" : "all";
  const warning = getOperatorWarnings(editor, path);

  return (
    <OperatorContainer
      className={clsx(className, { warning: warning !== null, multiChild: true })}
      {...attributes}
    >
      <div className={clsx("op", element.operator)}>
        <Tooltip
          title={
            <HotkeyTooltip
              text="Double click to change operator"
              keys={["mod", "shift", "o"]}
            />
          }
        >
          <span
            className="unselectable"
            contentEditable={false}
            onDoubleClick={changeOperator}
          >
            {displayLabel}
          </span>
        </Tooltip>
      </div>
      <div className={clsx("content", element.operator)}>{children}</div>
      {warning ? <WarningTooltip warning={warning} /> : null}
    </OperatorContainer>
  );
};

export const insertOperator = (editor: PlateEditor) => {
  // check if previous node is an operator, if so, auto insert a rule break as well
  withoutNormalizing(editor, () => {
    if (!editor.selection || getSelectedNode(editor)[0]?.type === ELEMENT_PARAGRAPH) return false;
    // record original path
    // base selection anchor is within text, so use the parent
    let path = Path.parent(normalizeRange(editor.selection).anchor.path);
    wrapNodes(editor, createOperator(), { at: editor.selection });
    const prev = getPreviousNode(editor, { at: path });
    if (prev && prev[0].type === ELEMENT_OPERATOR) {
      // insert after the previous node
      insertHR(editor, Path.next(prev[1]), false);
      // if we add a hr, the new operator just moved, so we need to update it
      path = Path.next(path);
    }
    const next = getNextNode(editor, { at: path });
    if (next && next[0].type === ELEMENT_OPERATOR) {
      // insert before the next node
      insertHR(editor, next[1], false);
    }
  });
};

export const createOperatorPlugin = createPluginFactory({
  key: ELEMENT_OPERATOR,
  isElement: true,
  component: Operator,
  // handlers: {
  //   onChange: (editor) => () => {
  //     // normalizeOperators(editor);
  //   },
  // },
  withOverrides: (editor: PlateEditor) => {
    const { normalizeNode } = editor;
    editor.normalizeNode = ([node, path]) => {
      if (node.type === ELEMENT_OPERATOR) {
        return normalizeOperators(editor, [node as TOperator, path]);
      }

      return normalizeNode([node, path]);
    };
    return editor;
  },
  ...withHotKeys([
    {
      keys: ["mod+shift+o"],
      action: (editor, plugin, node) => {
        const operator = getNearestElement<TOperator>(editor, ELEMENT_OPERATOR, node[1]);
        const [op, path] = operator;
        toggleOperator(editor, op.operator, path);
        return true;
      },
    },
    {
      keys: ["mod+o"],
      action: (editor) => {
        insertOperator(editor);
        return true;
      },
    },
  ]),
});
