import { call, select, takeEvery } from 'redux-saga/effects';
import type { ReleaseInResources }  from './index';
import { GET_ONE }                 from "@imminently/imminently_platform/dist/main";


export const ActionType = 'projects/requestToCleanUpReleaseFromSaga';

export interface IAction {
  type: typeof ActionType;
  payload: {
    releaseId: string;
    projectId: string;
  }
}


export const cleanUpRelease = (payload: IAction[ 'payload' ]): IAction => ({
  type: ActionType,
  payload,
});

// ===================================================================================


export function* saga(authProvider, dataProvider): Generator<any, any, any> {
  yield takeEvery(ActionType, function* onRequestToCleanUpRelease({ payload: p }: IAction) {
    const store: any   = yield select();
    const { releases } = store.resources;
    console.log(p)
    if (!releases) return;

    const { data } = releases;
    if (!data) return;

    const releasesForProject = (Object.values(data) as ReleaseInResources[])
      .filter(it => it.model === p.projectId);
    const releaseToCleanUp   = releasesForProject.find(it => it.id === p.releaseId);
    if (!releaseToCleanUp) return;

    const response = yield call(
      dataProvider,
      GET_ONE,
      "releases",
      {id: p.releaseId},
      {
        customAction: 'cleanUp',
      },
      authProvider,
    );

    console.log(response)
  });
}
