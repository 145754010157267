import type React from "react";
import {
  BorderColorIcon,
  BorderStyleIcon,
  BrokenImageIcon,
  CallSplitIcon,
  CheckBoxIcon,
  DateRangeIcon,
  EventIcon,
  FlipToBackIcon,
  FunctionsIcon,
  HelpIcon,
  ImageIcon,
  InputIcon,
  LabelIcon,
  ListIcon,
  MonetizationOnIcon,
  MuiIcon,
  PeopleAltIcon,
  PictureInPictureAltIcon,
  RadioButtonCheckedIcon,
  RepeatIcon,
  ScheduleIcon,
} from "../../../../../../icons";
import type { AttributeControlType, ContainerType } from "../../../../../../types/interfaces.rulegraph";

export const ControlIcons: Record<Exclude<AttributeControlType | ContainerType, "auto">, React.ComponentType> = {
  unassigned: HelpIcon,
  text: InputIcon,
  boolean: CheckBoxIcon,
  typography: LabelIcon,
  currency: MonetizationOnIcon,
  date: DateRangeIcon,
  time: EventIcon,
  options: ListIcon,
  entity: PictureInPictureAltIcon,
  radio: RadioButtonCheckedIcon,
  number: InputIcon,
  number_of_instances: FunctionsIcon,

  repeating_container: RepeatIcon,
  certainty_container: BrokenImageIcon,
  switch_container: CallSplitIcon,
};
