import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { PrimaryButton } from '@components/buttons';
import { JSpreadsheetDataTable } from '@components/JSpreadsheetDataTable';
import styled from 'styled-components';
import { WithJSpreadSheetWrapCtx } from '@components/JSpreadSheetWrap';


const StyledDialog = styled( Dialog )`
  .MuiDialog-paper {
    width: 80vw;
    height: 80vh;
    padding: 1rem;
  }
`;

export const JSX = React.createElement(() => {
  const [ open, setOpen ] = React.useState( false );

  return (
    <>
      <PrimaryButton onClick={ () => setOpen( true ) }>
        show JSpreadsheetDataTable
      </PrimaryButton>

      <StyledDialog open={ open } onClose={ () => setOpen( false ) } maxWidth={false}>
        <WithJSpreadSheetWrapCtx>
          <JSpreadsheetDataTable
            getSpreadsheetOptions={React.useCallback(() => ({
              worksheets: [
                {
                  minDimensions: [1, 0],
                  defaultColWidth: 40,
                  columns: [
                    {
                      title: "ID",
                      align: "left",
                      type: "text",
                      wrap: true,
                      width: 200,
                    },
                  ],
                  columnResize: false,
                  data: [
                    ["1"],
                    ["2"],
                    ["3"],
                    ["4"],
                    ["5"],
                    ["6"],
                    ["7"],
                    ["8"],
                  ],
                },
              ],
              // onselection: (_, px, py, ux, uy, origin) => {
              //   setValueCb((v) => ({ ...v, selection: { px, py, ux, uy } }));
              // },
              // onblur: () => {
              //   setValueCb((v) => ({ ...v, selection: null }));
              // },
              // oninsertrow: (worksheet, rows) => {
              //   const typedB = rows as Array<{ row: number }>;
              //   const [first] = typedB;
              //   if (first === undefined || !("row" in first)) return;

              //   setTimeout(() => {
              //     worksheet.updateSelectionFromCoords(0, first.row);
              //   }, 13);
              // },
              // contextMenu: (worksheet, x, y, e, items, section, mixedArg1, mixedArg2) => {
              //   e.preventDefault();
              //   if (section !== "cell") return false;

              //   setValueCb((s) => ({
              //     ...s,
              //     ctxMenuParts: {
              //       x: e.clientX,
              //       y: e.clientY,
              //       Comp: ManageEnumCtxMenu,
              //     },
              //   }));

              //   return false;
              // },
            }), [])}
          />
        </WithJSpreadSheetWrapCtx>
      </StyledDialog>
    </>
  )
});
