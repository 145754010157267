import moment from "moment";
import { useState } from "react";
import { useInterval } from "react-use";
import styled from "styled-components";

import { VersionhistoryIcon } from "@icons";
import { type EditorDocument, useNewDocumentSelector, useRuleDocument } from "@pages/documents";

const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  line-height: 150%;
  height: 1.5rem;

  > svg {
    margin-right: 0.5rem;
  }
`;

export type LegacyLastSavedProps = {
  document: EditorDocument;
};

export const LegacyLastSaved = ({ document }: LegacyLastSavedProps) => {
  const { lastSaved, document: storedDocument } = document;
  const { lastCompiled, sentForCompile } = storedDocument;
  // if we dont have lastSaved, we will return null
  // just have to fill it with something, as the useState needs to be initialized
  const compiled = typeof lastCompiled === "number" && lastCompiled > (lastSaved ?? 0);
  const time = compiled ? lastCompiled : lastSaved;
  const isCompiling = sentForCompile && !compiled;
  const msg = isCompiling ? "Sent for processing" : "Saved";

  const [value, setValue] = useState(moment(time).fromNow());

  if (!lastSaved) return null;

  // refresh 1 once per minute
  useInterval(() => {
    setValue(moment(time).fromNow());
  }, 60 * 1000);

  return (
    <Container>
      <VersionhistoryIcon />
      <span>{`${msg} ${value}`}</span>
    </Container>
  );
};

export const LastSaved = () => {
  const { document } = useRuleDocument();
  const lastSaved = document.lastSaved; //useNewDocumentSelector((documents) => documents[document.id!]?.lastSaved);
  // allow values to be undefined, will work just fine
  const { lastCompiled, sentForCompile } = document?.document ?? {};
  // if we dont have lastSaved, we will return null
  // just have to fill it with something, as the useState needs to be initialized
  const compiled = typeof lastCompiled === "number" && lastCompiled > (lastSaved ?? 0);
  const time = compiled ? lastCompiled : lastSaved;
  const isCompiling = sentForCompile && !compiled;
  const msg = isCompiling ? "Sent for processing" : "Saved";

  const [value, setValue] = useState(moment(time).fromNow());

  if (!lastSaved) return null;

  // refresh 1 once per minute
  useInterval(() => {
    setValue(moment(time).fromNow());
  }, 60 * 1000);

  return (
    <Container>
      <VersionhistoryIcon />
      <span>{`${msg} ${value}`}</span>
    </Container>
  );
};
