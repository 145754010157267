import React from 'react';
import { ReleaseTestCasesNS } from '@packages/commons';
import identity from 'lodash/identity';
import { TestCasesRootCtxState } from './types';
import { useDispatch } from 'react-redux';
import { aCreators } from './redux';


export const defaultTestCasesRootCtxValue: TestCasesRootCtxState[ 'value' ] = {
  goalId: '',
  tree: ReleaseTestCasesNS.rootTestCaseTItem,
  selectedId: null,
  testCasesTreeCompState: {
    tree: {
      __testCaseTreeItemId: ReleaseTestCasesNS.rootTestCaseTItem[ 'id' ],
      children: [],
    },
    mode: 'folderSelect',
    highlightedId: null,
    dialogContents: null,
  },
  actions: {
    setDebuggerPayload: identity,
    setDebuggerGoal: identity,
    createItem: async arg => {
      const { newTreeItem, setValue, idToAddTo } = arg;

      if( idToAddTo === null ) return;

      setValue(prev => ({
        ...prev,
        tree: ReleaseTestCasesNS.utilsNS.tCasesAdd({
          rootTreeItem: prev.tree,
          idToAddTo,
          itemToAdd: newTreeItem,
        }),
        selectedId: newTreeItem.type === 'payload' ? newTreeItem.id : prev.selectedId,
        testCasesTreeCompState: defaultTestCasesRootCtxValue.testCasesTreeCompState,
      }));
    },
    renameItem: () => Promise.resolve(),
    removeItem: () => Promise.resolve(),
    duplicateItem: () => Promise.resolve(),
    loadTestCaseLeafPayload: () => Promise.resolve({ goal: '', data: {} }),
    updateTestCaseLeaf: () => Promise.resolve(),
    getDebuggerPayoad: () => ({}),
  }
};
export const defaultTestCasesRootCtxState: TestCasesRootCtxState = {
  value: defaultTestCasesRootCtxValue,
  setValue: identity,
  valueRef: { current: defaultTestCasesRootCtxValue },
};


export const TestCasesRootCtx = React.createContext(defaultTestCasesRootCtxState);
TestCasesRootCtx.displayName = 'components/TestCasesTree/RootCtx';

export const useTestCasesRootCtx = () => React.useContext( TestCasesRootCtx );


export type WithTestCasesRootCtxProps = React.PropsWithChildren<{
  initialState?: typeof defaultTestCasesRootCtxState.value;
}>;
export const WithTestCasesRootCtx: React.FC< WithTestCasesRootCtxProps > = React.memo( p => {
  const { children, initialState = defaultTestCasesRootCtxState.value } = p;

  const [ value, setValue ] = React.useState( initialState );
  const valueRef = React.useRef(value);
  if(valueRef.current !== value) valueRef.current = value;

  const ctxValue = React.useMemo< TestCasesRootCtxState >(() => ({
    value,
    setValue,
    valueRef,
  }), [ value, setValue, valueRef ]);

  return (
    <TestCasesRootCtx.Provider value={ ctxValue }>
      { children }
    </TestCasesRootCtx.Provider>
  );
});
WithTestCasesRootCtx.displayName = 'components/TestCasesTree/RootCtx/WithTestCasesRootCtx';

/**
 * it can't be added to WithTestCasesRootCtx, because test cases context is \
 * added before redux is added. If it is added after - modals won't have access\
 * to it. So instead we connect it higher than imminently context wrapper and\
 * bind set value in components lower in React tree, near the routes wrapper
 */
export const EncloseSetValueFromTestCasesCtxComp = React.memo(() => {
  const dispatch = useDispatch();
  const { setValue } = useTestCasesRootCtx();

  React.useEffect(() => {
    dispatch(aCreators.encloseSetValue(setValue));
  }, [setValue, dispatch]);

  return null;
});
EncloseSetValueFromTestCasesCtxComp.displayName = 'TestCasesTree/RootCtx/EncloseSetValueFromTestCasesCtxComp';
