import { useState } from 'react';
import GraphExplorer from "@components/GraphExplorer";
import GraphContext from "@pages/models/release/GraphContext";
import ReleaseContext from "@pages/models/release/ReleaseContext";

const FocusModal = ({ release, graph, goal }) => {

  const [node, setNode] = useState(goal);
  const sidebarResizer = (node) ? {
    defaultSize: "70%",
    pane1Style: { minWidth: "50%", maxWidth: "75%", paddingBottom: "2rem" },
    pane2Style: { minWidth: "25%", maxWidth: "50%", height: "100%", paddingBottom: "2rem", marginTop: "-1rem" },
  } : {
    defaultSize: "100%",
    pane1Style: { minWidth: "100%", maxWidth: "100%", paddingBottom: "2rem" },
  };
  console.log('focus', release, graph, node)
  return (
    <ReleaseContext.Provider value={release}>
      <GraphContext.Provider value={graph}>
        <GraphExplorer
          graph={graph}
          focusNode={node}
          showHidden
          showValues={false}
        />
      </GraphContext.Provider>
    </ReleaseContext.Provider>
  );
}
export default FocusModal;