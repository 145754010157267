import styled from "styled-components";
import { TippyProps } from "@tippyjs/react";
import { Kbd, KbdProps } from "@components/kdb";

export const Tooltip = styled.div`
  color: ${p => p.theme.palette.secondary.contrastText};
  background-color: ${p => p.theme.palette.secondary.main};
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  box-shadow: ${p => p.theme.shadows[1]};
  ${p => p.theme.typography.caption};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.25rem;
`;

export type HotkeyTooltipProps = {
  text: string;
  keys?: string | string[];
  kbdProps?: Omit<KbdProps, "keys">;
}

export const HotkeyTooltip = ({ text, keys, kbdProps }: HotkeyTooltipProps) => (
  <Tooltip style={{ flexDirection: "column", justifyContent: "center" }}>
    {text}
    {keys && <Kbd variant="separate" color="dark" {...kbdProps} keys={keys} />}
  </Tooltip>
);

export const hotkeyTooltip = (name: string, keys?: string | string[]): TippyProps => ({
  content: (<HotkeyTooltip text={name} keys={keys} />)
});