import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { addWorkspace, editWorkspace, showConfirmation, showModal } from '@modals';
import { EmptyContent, ResourceCard, ResourceLoading, GridList, scrollable } from '@common';
import { Button, Menu, Search } from '@components';
import { Typography } from '@material-ui/core';
import { useScrollable } from '@common/hooks/useScrollable';
import { workspaceService } from 'services';
import { Workspace } from '@packages/commons';
import { useNotify } from '@common/hooks/HooksNotification';
import { useSearch } from '@components/Search';
import { setWorkspace, useScope } from 'redux/scope';
import { BackendResource } from '@imminently/immi-query';

const WORKSPACE_DESCRIPTION = "A workspace is where you'll save your projects and collaborate with others.";

const Content = styled.div`
  padding: 0 2rem 2rem 2rem;
`;

const Card = styled.div`
  width: var(--size);
  border-radius: 0.5rem;
  padding: 1rem;
  border: 1px solid ${p => p.theme.palette.secondary.light};
  background-color: ${p => p.theme.palette.background.paper};
  display: grid;
  place-items: center;
  height: 170px;
`;

const Actions = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  margin-top: 1rem;

  > .MuiTextField-root {
    width: 340px;
  }
`;

const useDeleteWorkspace = () => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const { mutateAsync } = workspaceService.useDelete();
  return (id: string) => dispatch(
    showConfirmation({
      title: 'Delete workspace',
      body: 'Delete this workspace and all associated projects?',
      actions: (close) => ({
        primary: {
          name: 'Yes, delete',
          error: true,
          onClick: async () => {
            try {
              await mutateAsync(id);
              notify.info('Workspace deleted');
              dispatch(setWorkspace(null)); // set null and it should trigger default flow
            } catch (e) {
              notify.error('Failed to delete workspace');
            } finally {
              close();
            }
          },
        },
      }),
    }),
  );
};

const WorkspaceMenu = ({ workspace }: { workspace: BackendResource<Workspace> }) => {
  const dispatch = useDispatch();
  const onDelete = useDeleteWorkspace();

  const items = [
    {
      name: 'Edit',
      onClick: () => {
        dispatch(editWorkspace(workspace as Workspace, browseWorkspaces));
      },
    },
    {
      name: 'Delete',
      className: 'error',
      onClick: () => onDelete(workspace.id),
    },
  ];

  return <Menu width='8rem' small items={items} />;
};

const skeleton = i => <ResourceLoading key={i} width='unset' />;

const WorkspacesModal = (props) => {
  const { close } = props;
  const dispatch = useDispatch();
  const { workspace } = useScope();
  const { filter, ...search } = useSearch({ placeholder: 'Search workspaces...' });
  const { data: workspaces, total, query, loadMore } = workspaceService.useInfiniteResources({
    perPage: 12,
    filter: {
      name: filter.length > 0 ? filter : undefined,
      // TODO we never actually sorted this before
      // sort: { field: "created", order: "desc" },
    },
    refetchOnWindowFocus: false, // disabled as this page doesn't need realtime updates
  });

  const canLoad = !query.isError && !query.isFetching && (query.hasNextPage ?? false);
  const scrollProps = useScrollable(loadMore, canLoad);

  const cardAction = id => ({
    name: 'Open',
    onClick: () => {
      dispatch(setWorkspace(id));
      close();
    },
  });

  const current: any[] =
    workspaces.map(w => ({
      ...w,
      // default is public
      label: w.visibility || 'public',
      action: cardAction(w.id),
      tags: w.id === workspace ? ['Current'] : [],
      // TODO might be a different value
      users: w.usersData ?? [],
      menu: <WorkspaceMenu workspace={w} />,
    })) || [];

  if (canLoad) {
    current.push({ isHint: true });
  }

  const renderItem = (item, i) => {
    if (item.isHint) {
      return (
        <Card key={i} onClick={loadMore} style={{ cursor: "pointer" }}>
          <Typography variant='h6'>Scroll to load more</Typography>
        </Card>
      );
    }

    const { name, ...rest } = item;
    return <ResourceCard key={i} width='unset' name={name} alt={name} {...rest} />;
  };

  return (
    <>
      <Actions>
        <Search {...search} />
        <Button type='primary' onClick={() => dispatch(addWorkspace(browseWorkspaces))}>
          Create workspace
        </Button>
      </Actions>
      {
        (!query.isLoading && total === 0) ? (
          <EmptyContent messages={filter.trim().length > 0
            ? ['No workspaces match filter']
            : ["It's looking a little empty in here.", 'Create your first workspace']} />
        )
          : (
            <Content className={scrollable} {...scrollProps}>
              <GridList
                title={`current • ${total}`}
                gap='1rem'
                minWidth='20rem'
                items={current}
                renderItem={renderItem}
                renderSkeleton={skeleton}
                isLoading={query.isFetchingNextPage}
              />
            </Content>
          )
      }
    </>
  );
};

export const browseWorkspaces = () => showModal(
  {
    open: true,
    title: 'Workspaces',
    description: WORKSPACE_DESCRIPTION,
    maxWidth: 'lg',
    height: '100vh',
    contentStyle: { gap: "1rem", overflow: "hidden", padding: 0 }
  },
  <WorkspacesModal />,
);

export const useBrowseWorkspaces = () => {
  const dispatch = useDispatch();
  return () => dispatch(browseWorkspaces());
};
