// @ts-nocheck

import {
  ELEMENT_PARAGRAPH,
  type PlateEditor,
  type PlatePlugin,
  type TNodeEntry,
  createPluginFactory,
  getLastNode,
  getNextNode,
  getNode,
  getNodeString,
  getParentNode,
  insertNodes,
  isFirstChild,
  removeNodes,
  useEditorState,
  wrapNodes,
} from "@udecode/plate";
import type { KeyboardEvent } from "react";
import { Editor, type Node, Path, Range, Text } from "slate";
import { ReactEditor } from "slate-react";
import styled from "styled-components";
import {
  createConclusion,
  createHR,
  createParagraph,
  getSelectedNode,
  isFocusedWithin,
  isLastChild,
} from "../../plugins/util";
import { isInTable } from "../decisionTable";
import { ELEMENT_CONCLUSION, ELEMENT_CONDITION, ELEMENT_OPERATOR, ELEMENT_RULE, ELEMENT_TABLE } from "../elements";
import { OPERATOR, createOperator } from "../operator";
import { ELEMENT_CONNECTOR, createConnector, insertConnector } from "../operator/connector";
import { createRule } from "./createRule";
import type { TRule } from "./rule.types";
import {
  decreaseRuleLevel,
  getRuleLevel,
  getRuleType,
  getSelection,
  handleTab,
  isLastTableRow,
  isTableHeaderRow,
  ruleToParagraphText,
} from "./ruleTransforms";

const Container = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.secondary.main};
  /* font-family: "Libre Baskerville"; */
  font-size: 1rem;
  gap: 1rem;
  line-height: 1.5;
  position: relative;

  &[data-expression="${ELEMENT_CONCLUSION}"] {
    min-width: 10rem;
    [data-slate-leaf="true"] {
      font-weight: bold;
    }
  }

  &[data-expression="${ELEMENT_CONDITION}"][data-inlineConclusion="true"] {
    min-width: 10rem;
    [data-slate-leaf="true"] {
      font-weight: bold;
    }
  }

  &[data-expression="${ELEMENT_CONDITION}"] {
    min-width: 9rem;
  }

  &[data-selected="true"] {
    > .body {
      background-color: ${(props) => props.theme.palette.secondary.hover};
    }
  }

  [draggable="true"] {
    opacity: 0;
  }

  &:hover {
    [draggable="true"] {
      opacity: 1;
    }
  }

  > .body {
    border: 1px solid ${(props) => props.theme.palette.background.border};
    border-radius: 1.25rem;
    padding: 0.25rem 1rem;
    min-height: 2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    /* min-width: 10rem; */
    /* !IMPORTANT this is what inserts placeholder label */
    ${(p) => p.css}

    span {
      min-width: 1px;
      cursor: text;
    }
  }
`;

const ConclusionLabel = styled.span`
  font-family: 'Montserrat';
  text-transform: capitalize;
  display: inline-block;
  font-size: 0.75rem;
  // want 1.5 + 3px padding, so easier to just set to 24px (1.5rem)
  line-height: 1.5rem;
  font-weight: bold;
  border-radius: 0.5rem;
  padding: 0 0.25rem;
  min-width: 1.25rem;
  text-align: center;
  position: relative;
  /* top: 0.25rem; */
  background-color: #b6c5fb; // darker blue lavendar (85%)
`;

// using a hook as we want this to always update
const useHasOperator = (path: Path) => {
  const editor = useEditorState();
  const node = getNode(editor, Path.next(path));
  return (node && node.type === ELEMENT_OPERATOR) ?? false;
};

const useInConnector = (path: Path) => {
  const editor = useEditorState();
  const node = getNode(editor, Path.parent(path));
  return (node && node.type === ELEMENT_CONNECTOR) ?? false;
};

export const Rule = ({ className, attributes, children, element, nodeProps, editor }: any) => {
  const rulePath = ReactEditor.findPath(editor, element);
  const node = getNode(editor, rulePath) as TRule;
  const selected = isFocusedWithin(editor, rulePath);
  const inConnector = useInConnector(rulePath);

  const isConclusion = node.expression === ELEMENT_CONCLUSION;

  // NOTE the styles must be extracted and used for placeholder support
  const { styles } = nodeProps;
  const placeholder = isConclusion ? "Enter conclusion..." : "Enter condition...";
  // if we have content, its 0, otherwise its 12rem for conclusion, 11 otherwise
  const minWidth = getNodeString(node).length === 0 ? (isConclusion ? "12rem" : "11rem") : 0;

  return (
    <>
      <Container
        className={className}
        {...attributes}
        css={styles?.root?.css}
        data-expression={node.expression}
        data-selected={selected}
      >
        <div
          className="body"
          placeholder={placeholder}
          style={{ minWidth }}
        >
          {children}
        </div>
        {isConclusion && inConnector && (
          <ConclusionLabel
            className="unselectable"
            contentEditable={false}
          >
            If
          </ConclusionLabel>
        )}
      </Container>
    </>
  );
};

const handleConditionKeyDown = (editor: PlateEditor, path: number[]): boolean => {
  const node = getNode(editor, path);
  // if enter on empty condition, we want to back out
  if (node && getNodeString(node).length === 0) {
    // we want operator depth only
    const level = getRuleLevel(editor, path, true);
    if (level === 1 && !isLastChild(editor, path)) {
      // special condition
      return false; // continue so it should insert a new rule?
    }
    return decreaseRuleLevel(editor, path);
  }

  return false;
};

const handleConclusionKeyDown = (editor: PlateEditor, path: number[]): boolean => {
  const node = getNode(editor, path);

  // do no action if first or last row of table
  if (isTableHeaderRow(editor, path) || isLastTableRow(editor, path)) return false;

  if (node && getNodeString(node).length === 0) {
    return decreaseRuleLevel(editor, path);
  }

  try {
    // if at start of line, insert paragraph above
    const { offset } = getSelection(editor);
    if (offset === 0) {
      const level = getRuleLevel(editor, path);
      insertNodes(editor, createParagraph(), { at: path });
      return true;
    }
    // if offset is not at the end, skip (ie default behaviour)
    if (offset !== getNodeString(node).length) return false;

    // MAY 15 2024 - we want to auto make a condition

    // first insert sibling condition
    // if we are in a connector, it will insert as sibling, and we will rely on the connector normalise function to fix it
    insertNodes(editor, createRule(ELEMENT_CONDITION), {
      at: Path.next(path),
      select: true,
    });

    // if not in connector, add a new connector with condition
    const parent = getParentNode(editor, path) as TNodeEntry; // will always exist, as it falls back to the editor itself
    if (parent.type !== ELEMENT_CONNECTOR) {
      insertConnector(editor, Path.next(path));
    }
    return true;
  } catch (err) {
    // there is no next node
    console.log("[CONCLUSION] failed to get next", node, path, Path.next(path), err);
  }
  return false;
};

// eslint-disable-next-line complexity
const handleRuleKeyDown = (editor: PlateEditor, plugin: PlatePlugin) => (e: KeyboardEvent) => {
  if (editor.selection === null) return false;
  const { path } = getSelection(editor);
  const [node, rulePath] = getParentNode(editor, path);

  // console.log("[handle rule plugin keydown]", editor, plugin, e, node, path);
  if (node.type !== plugin.type) return;

  const onEnter = node.expression === ELEMENT_CONDITION ? handleConditionKeyDown : handleConclusionKeyDown;
  const onTab = handleTab;
  const text = ruleToParagraphText(node);
  let res = false;

  switch (e.key) {
    case "Enter":
      if (e.shiftKey) {
        // insert empty paragraph
        insertNodes(editor, createParagraph(), { at: Path.next(rulePath), select: true });
        res = true;
      } else {
        res = onEnter(editor, rulePath);
      }
      if (res) e.preventDefault();
      return res;
    case "Backspace": {
      if (text.length === 0) {
        e.preventDefault();
        const ruleType = getRuleType(node);
        // cant only delete the node on empty if;
        // 1. its not the only condition in a table row
        // 2. its not a first level conclusion with children
        // 3. its not a conclusion in a table
        const isConclusion = ruleType === ELEMENT_CONCLUSION;
        const inTable = isInTable(editor, { at: rulePath });
        const parent = getParentNode(editor, rulePath);
        const level = getRuleLevel(editor, rulePath);
        // a parent of type connector means only 2 children, ie cannot remove
        const parentIsConnector = parent?.[0].type === ELEMENT_CONNECTOR;
        if (
          !(inTable && !isConclusion && parentIsConnector) &&
          !(isConclusion && parentIsConnector && level === 1) &&
          !(isConclusion && inTable)
        ) {
          // delete the rule
          removeNodes(editor, { at: rulePath });
        }
        return true;
      }

      const isFirst = isFirstChild(rulePath);
      const startOfLine = editor.selection.anchor.offset === 0;
      // don't delete connectors, we want those
      if (startOfLine && isFirst) {
        const [parent, parentPath] = getParentNode(editor, rulePath);
        if (parent && parent.type === ELEMENT_CONNECTOR) {
          // remove the rule before the connector
          e.preventDefault();

          const notFirstChild = !isFirstChild(parentPath);
          if (notFirstChild) {
            const prev = Path.previous(parentPath);
            // find the last rule in this previous node
            let [lastNode, lastPath] = getLastNode(editor, prev);
            if (Text.isText(lastNode)) {
              // we know we are a leaf, so parent will exist
              lastPath = getParentNode(editor, lastPath)[1];
            }
            // using joels suggestion for now, which is deny the action, ie do nothing
            return true;

            // TODO come to a final decision on what we should do
            const textLength = getNodeString(lastNode).length;
            if (textLength === 0) {
              // delete previous if its just empty whitespace
              removeNodes(editor, { at: lastPath });
            } else {
              // deleteBackward ends up removing the rule and connector, has its issues
              // Editor.deleteBackward(editor, { unit: "character" });
            }
          }
          return true;
        }
      }

      return false;
    }
    case "Tab":
      console.log("pressed tab within a rule!");
      res = onTab(editor, e.shiftKey);
      if (res) e.preventDefault();
      return res;
    case "[":
    case "]":
      // deny [] characters
      e.preventDefault();
      return true;
    default:
      return false;
  }
};

const findParent = (nodes: TNode[], level: number): TNode | null => {
  if (nodes.length === 0) return null;
  let parent = nodes[nodes.length - 1];
  // we are at top level, which means its a sibling
  if (parent.level === level) return null;
  for (let i = 1; i < level - 1; i++) {
    // we have found the parent
    if (!parent.children || parent.children.length === 0) break;
    // continue to iterate through the levels
    const p = parent.children[parent.children.length - 1];
    // if levels match, we are already in the correct parent
    if (p.level === level) break;
    // we don't want rules or other elements as parents
    if (p.type !== ELEMENT_OPERATOR) break;
    parent = p;
  }
  return parent;
};

const convertOPMFragments = (fragments: any[]) => {
  // OPM fragments are line by line
  // they are type OPM-conclusion
  // then type OPM-level1, OPM-level2 etc
  // where the level is the depth of the rule
  // if a level text is just any or all, then it is an operator
  // all subsequent children of higher depths are children
  // let currentLevel = 0;
  // eslint-disable-next-line complexity
  return fragments.reduce((acc, frag) => {
    const text = getNodeString(frag);
    if (frag.type === "OPM-conclusion") {
      // create conclusion in a connector
      // we dont want to have to modify the array, so assume it will have children
      // if not, the connector will be auto normalised out its normalise function
      // acc.push({ ...createConnector([createRule(ELEMENT_CONCLUSION, text)]), level: 0 });
      acc.push(createRule(ELEMENT_CONCLUSION, text));
    } else if (frag.type.startsWith("OPM-level")) {
      // extract level number using regex
      const level = Number.parseInt(frag.type.match(/\d+/)[0], 10);
      // is first thing to check the previous node/level?
      // if (acc.length > 0 && acc[acc.length - 1].type === ELEMENT_CONNECTOR && level > acc[acc.length - 1].level) {
      //   const connector = acc[acc.length - 1];
      //   connector.children.push();
      // }
      // const delta = level - currentLevel;
      const textLower = text.toLowerCase();
      if (textLower === "any" || textLower === "all") {
        // create operator
        const op = { ...createOperator([], textLower === "any" ? OPERATOR.OR : OPERATOR.AND), level };
        if (level === 1 || acc.length === 0) {
          acc.push(op);
        } else {
          const parent = findParent(acc, level);
          if (!parent) {
            // if previous node is an operator of the same type, add a rule break
            const prev = acc[acc.length - 1];
            if (prev && prev.type === ELEMENT_OPERATOR && prev.operator === op.operator) {
              const rb = createHR();
              acc.push(rb);
            }
            // we are copying rules at n depth, so allow raw rules
            acc.push(op);
          } else {
            // if previous node is an operator of the same type, add a rule break
            const prev = parent.children[parent.children.length - 1];
            if (prev && prev.type === ELEMENT_OPERATOR && prev.operator === op.operator) {
              const rb = createHR();
              parent.children.push(rb);
            }
            parent.children.push(op);
          }
        }
      } else {
        // create condition
        const rule = { ...createRule(ELEMENT_CONDITION, text), level };
        // if we are level 1, we need at least 1 operator
        if (level === 1) {
          const operator = { ...createOperator([rule]), level };
          acc.push(operator);
        } else {
          const parent = findParent(acc, level);
          if (parent === null) {
            // we are copying rules at n depth, so allow raw rules
            acc.push(rule);
          } else {
            parent.children.push(rule);
          }
        }
      }
      // currentLevel = level;
    } else {
      // create paragraph
      acc.push({ type: "p", children: [{ text }] });
    }
    return acc;
  }, []);
};

const converv2ToV3 = (data: any[]) => {
  const v3data = [] as RuleNode[];
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    if (node.type === "rule" && node.expression === "conclusion") {
      const next = data[i + 1];
      if (next && next.type === "operator") {
        // since we are at the top level, move the sectionId to the connector
        const connector = createConnector([node, next]) as Connector;
        // connector.sectionId = node.sectionId;
        v3data.push(connector);
        i++;
        continue;
      }
    }
    v3data.push(node);
  }
  return v3data;
};

export const createRulePlugin = createPluginFactory({
  key: ELEMENT_RULE,
  isElement: true,
  component: Rule,
  handlers: {
    onKeyDown: handleRuleKeyDown,
  },
  withOverrides: (editor) => {
    const { insertFragment, normalizeNode, getFragment } = editor;
    const elems = [ELEMENT_CONCLUSION, ELEMENT_CONDITION, ELEMENT_RULE];

    // handles rule bubble paste (including legacy)
    editor.insertFragment = (fragments) => {
      const [node] = getSelectedNode(editor);
      console.log("[RULE] insert fragment", fragments, node);
      if (fragments.some((f) => f.type?.startsWith("OPM"))) {
        const converted = convertOPMFragments(fragments);
        const v3data = converv2ToV3(converted);
        console.log("[RULE] converted", fragments, converted, v3data);
        // debugger;
        insertFragment(v3data);
        return true;
      }
      // allow pasting single plain text into rule bubble
      if (elems.includes(node.type) && fragments.length === 1) {
        const text = getNodeString(fragments[0]);
        Editor.insertText(editor, text);
        return true;
      }

      return insertFragment(fragments);
    };

    // handles rule bubble copy
    editor.getFragment = () => {
      if (!editor.selection) return getFragment();
      if (isInTable(editor)) {
        return getFragment();
      }

      const selection = Range.edges(editor.selection);
      // note that range needs to have 'anchor' and 'focus'
      const range = { anchor: selection[0], focus: selection[1] };
      const fragment = Editor.fragment(editor as PlateEditor, range) as N[];

      // create the frontier
      const rules = [];
      const stripRules = (node: Node) => {
        if (node.type === ELEMENT_RULE || node.type === ELEMENT_PARAGRAPH || node.type === ELEMENT_TABLE) {
          rules.push(node);
          return;
        }
        // if operator who has children that is just another operator
        if (
          node.type === ELEMENT_OPERATOR &&
          (node.children?.length > 1 || node.children[0].type !== ELEMENT_OPERATOR)
        ) {
          rules.push(node);
          return;
        }
        // if its a connector
        if (node.type === ELEMENT_CONNECTOR) {
          rules.push(node);
          return;
        }
        if (node.children) {
          node.children.forEach(stripRules);
        }
      };
      for (const node of fragment) {
        stripRules(node);
      }
      // we dont want to copy the top operator if its the only item, so strip it
      if (rules.length === 1 && rules[0].type === ELEMENT_OPERATOR) {
        return rules[0].children;
      }
      return rules.length > 0 ? rules : getFragment();
    };

    // use normalize here to enforce into para if outside section
    editor.normalizeNode = ([node, path]) => {
      if (node.type === ELEMENT_RULE) {
        // TODO temp disabled as it was adding confusion
        // const level = getRuleLevel(editor, path);
        // if (level === 0) {
        //   // if we are at level 0, we want to ensure its a conclusion
        //   setRuleType(editor, path, ELEMENT_CONCLUSION);
        //   return true;
        // }
      }
      return normalizeNode([node, path]);
    };

    return editor;
  },
});
