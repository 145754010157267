import React from "react";
import styled from "styled-components";
// import Typography from "@material-ui/core/Typography";
// import Box from "@material-ui/core/Box";
import { createToggleExpanded } from '@components/DottedTree/ctx';
import * as DottedTreeNS from '@components/DottedTree';
// import NodeValue from "@components/GraphVisualisation/components/AttributeInfo/NodeValue";


const StyledTree = styled( DottedTreeNS._ )`
  height: 100%;
  flex-grow: 1;
  padding: 1rem;
`;

export type WithLabelAndValue = { label: string;value: unknown; };
/**
 * uses generic props for dotted tree and combines those with\
 * WithLabel.
 */
export type RightTreeProps = DottedTreeNS.Props< WithLabelAndValue >;

const LabelCompWrap = styled.div`
  display: flex;
  align-items: center;
  padding-left: 0.5rem;

  &.isSelected .MuiTypography-root {
    font-weight: bold;
  }
`;

/**
 * this is the id for the wrapper element we add to\
 * items from Right props, because we need to show \
 * dotted lines for items, and if those are a direct \
 * children of a dottedTree - they have no vertical \
 * dotted lines. We won't show collapse button for \
 * the node with this id.
 */
export const __plusOneNestingLevelId = '__plusOneNestingLevel_nhXiix';

// type UseGetNodeByIdT = ( id: string ) => null | Record< string, unknown >;
// const createLabelComp = ( useGetNodeById: UseGetNodeByIdT ): RightTreeProps[ 'LabelComp' ] => {
//   const LabelComp: RightTreeProps[ 'LabelComp' ] = React.memo(p => {
//     const { id } = p;
//     const { idToItem, selected, onSelect } = DottedTreeNS.CtxNS.useDottedTreeCtx< WithLabelAndValue >();
//     const item = idToItem[ id ];
//     const isSelected = selected.indexOf(id) !== -1;

//     const maybeNode = useGetNodeById( id );

//     const onClick = React.useCallback(() => (
//       onSelect(id)
//     ), [ onSelect, id ]);

//     if ( !item ) return null;

//     return (
//       <LabelCompWrap className={ isSelected ? 'isSelected' : undefined } onClick={ onClick }>
//         <Box display='flex' flexDirection='column' gridGap='0.25rem'>
//           <Typography style={{ flexGrow: 1 }}>
//             { item.info.label }
//           </Typography>

//           <Box display='flex' gridGap='0.25rem'>
//             { maybeNode && <NodeValue node={ maybeNode } /> }
//           </Box>
//         </Box>

//         { id !== __plusOneNestingLevelId && <DottedTreeNS.LabelComponentPartsNS.CollapseToggle id={ id } />}
//       </LabelCompWrap>
//     );
//   });
//   LabelComp.displayName = 'components/TwoPaneDottedTree/Right/LabelComp';

//   return LabelComp;
// }


export type Item = RightTreeProps[ 'items' ][ 0 ];


const getIconById = () => null;
const selected: RightTreeProps[ 'selected' ] = [];

export type RightProps = {
  items: Item[] | null;
  LabelComp: RightTreeProps[ 'LabelComp' ];
  onSelect: RightTreeProps[ 'onSelect' ];
};

export const Right: React.FC< RightProps > = React.memo(p => {
  const { items, LabelComp, onSelect } = p;

  const [ expanded, setExpanded ] = React.useState< RightTreeProps[ 'expanded' ] >([__plusOneNestingLevelId])
  const toggleExpanded = React.useMemo< RightTreeProps[ 'toggleExpanded' ] >(() => (
    createToggleExpanded( setExpanded )
  ), [setExpanded]);

  const itemsPlus1Nestinglevel = React.useMemo< typeof items >(() => {
    if ( items === null ) return null;

    return [{
      id: __plusOneNestingLevelId,
      info: { label: '', value: 0 },
      children: items,
    }]
  }, [ items ]);

  if ( itemsPlus1Nestinglevel === null ) return null;

  return (
    <StyledTree
      LabelComp={ LabelComp }
      expanded={ expanded }
      toggleExpanded={ toggleExpanded }
      getIconById={ getIconById }
      items={ itemsPlus1Nestinglevel }
      selected={ selected }
      onSelect={ onSelect }
    />
  );
});
Right.displayName = 'components/TwoPaneDottedTree/Right';
