import debounce from "lodash/debounce";
import { type WheelEvent, useEffect, useRef, useState } from "react";
import { useDebounce } from "react-use";

interface ScrollableOptions {
  debounce?: number;
  autoScroll?: boolean;
}

export const useScrollable = (
  onLoad: (callback: () => void) => void,
  canLoad: boolean,
  options: ScrollableOptions = {},
) => {
  const { debounce: period = 300, autoScroll = false } = options;
  const ref = useRef<null | HTMLDivElement>(null);
  const [lastScroll, setLastScroll] = useState(0);
  const [deltaY, setDeltaY] = useState(0);

  const [, cancel] = useDebounce(
    () => {
      if (!ref.current) return;

      const { scrollHeight, clientHeight, scrollTop } = ref.current;
      // if we are at the bottom of the scrollable area
      // compare with 1px to account for rounding errors
      const scrolledToBottom = scrollHeight - clientHeight - scrollTop < 5;
      if (!canLoad || deltaY < 0 || !scrolledToBottom) return;

      onLoad(() => {
        if (ref.current === null || !autoScroll) return;

        ref.current.scrollTo({ top: ref.current.scrollHeight, behavior: "smooth" });
      });
    },
    period,
    [canLoad, deltaY],
  );

  const onScroll = (e) => {
    const current = e.currentTarget.scrollTop;
    const delta = current - lastScroll;
    setLastScroll(current);
    setDeltaY(e.detail?.emulated ? 1 : delta);
  };

  const onWheel = () => {
    const onWheelHandler = (curT, deltaY) => {
      const { current } = ref;
      if (deltaY < 0 || !canLoad || !current || curT.scrollHeight !== curT.clientHeight) return;

      const evt = new CustomEvent("scroll", { detail: { emulated: true } });

      current.dispatchEvent(evt);
    };
    const debounced = debounce(onWheelHandler, 100);

    return (e: WheelEvent<HTMLDivElement>) => debounced(e.currentTarget, e.deltaY);
  };

  // cancel the debounce on unmount
  useEffect(() => cancel, [cancel]);

  return { onScroll, onWheel, ref };
};
