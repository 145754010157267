import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import { TextField, Dropdown } from '@components';
import { useForm, Controller } from 'react-hook-form';
import { LOCALE_OPTIONS } from '@modals/ProjectModal_localeOptions';
import { ModalActions, showModal } from '@modals';
import { useNotify } from '@common/hooks/HooksNotification';
import { ModalComponentProps } from 'layouts/Modal.types';
import { Release } from '@packages/commons';
import { global } from 'global';
import { setRelease } from 'redux/scope';
import { releaseService } from 'services';
import { useTranslation } from 'react-i18next';

export interface Props {
  release: Release;
}

// endpoint types
// resource/{id}
// resource/{id}/custom/action
// resource/custom/action

export const DuplicateReleaseModal: React.FC<Props> = (props) => {
  const { close, release } = props as ModalComponentProps & Props;
  const dispatch = useDispatch();
  const notify = useNotify();
  const { t } = useTranslation();

  const service = releaseService.useCustomActions(release.id);
  // const service = useMemo(() => createAppResourceService(`releases/${release.id}/custom`), [release.id]);
  const { mutateAsync, isLoading } = service.useUpdate();
  const { control, handleSubmit } = useForm<Partial<Release>>({
    defaultValues: {
      description: release.description ?? '',
      locale: release.locale ?? '',
    },
  });

  const onSubmit = handleSubmit((data) => {
    // TODO "clone" is the custom endpoint, this is a hack due to library support
    mutateAsync({ id: "clone", payload: data })
      .then((res) => {
        if (res) {
          // TODO temp workaround while we figure out how to support custom properly
          global.queryClient.invalidateQueries('releases');
          dispatch(setRelease(res.id));
        }
      })
      .catch(() => {
        notify.error(t('errors.failed_to_duplicate'));
      })
      .finally(() => {
        close();
      });
  });

  const actions = {
    primary: {
      name: t('create'),
      onClick: onSubmit,
      disabled: isLoading,
      loading: isLoading,
    },
  };

  return (
    <Box display='flex' flexDirection='column' gridGap='0.5rem'>
      <Controller
        control={control}
        name='description'
        render={({ field: { value, onChange } }) => (
          <TextField
            value={value || ''}
            onChange={onChange}
            label={t('description')}
          />
        )}
      />

      <Controller
        control={control}
        name='locale'
        render={({ field: { value, onChange } }) => (
          <Dropdown
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value={value}
            // value={LOCALE_OPTIONS.find(it => it.value === value) || { key: value, value }}
            items={LOCALE_OPTIONS}
            onChange={e => onChange(e.target.value)}
          />
        )}
      />

      <ModalActions {...actions} />
    </Box>
  );
};

export const useDuplicateRelease = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (release: Release) => {
    dispatch(showModal({
      open: true,
      title: t('release_centre.new_release'),
      description: t('release_centre.duplicate_description'),
    }, <DuplicateReleaseModal release={release} />
    ));
  };
};
