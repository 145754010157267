import React, { useMemo } from 'react';
import get from 'lodash/get';
import { getNodeValue, roundToMaxDigitsAfterDot } from '@common/util';
import TemporalValueRender from '@components/TemporalValue';
import { Label } from '@components';
import styled from 'styled-components';
import { Tooltip, useTheme } from '@material-ui/core';
import { useGraphVisualisation } from '@components/GraphVisualisation/hooks/useGraphVisualisation';
import { forEach } from 'lodash';
import moment from 'moment';

Number.prototype.countDecimals = function () {

  if (Math.floor(this.valueOf()) === this.valueOf()) return 0;

  var str = this.toString();
  if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
      return str.split("-")[1] || 0;
  } else if (str.indexOf(".") !== -1) {
      return str.split(".")[1].length || 0;
  }
  return str.split("-")[1] || 0;
}

// not used...
export const nodeColour = (node) => {
  const theme = useTheme();

  let value = getNodeValue(node);
  if (typeof value === 'undefined' || value === null) return theme.palette.secondary.light;
  else if (typeof value === 'boolean') return value ? theme.labels.public : theme.labels.Author;
  else if ((typeof value === 'string' || typeof value === 'number') && value !== 'Entity') return theme.labels.public;
  else return theme.palette.secondary.light;
}

const getValueAtDate = (temporal, date) => {
  if (!temporal || !date || !temporal.ranges) return null;
  let value = null;
  let cmpdate = moment(date);
  forEach(temporal.ranges, (r => {
    if (cmpdate <= moment(r.r.end) && cmpdate >= moment(r.r.start)) {
      value = r.v;
      return false;
    }
  }))
  return value;
}
// eslint-disable-next-line complexity
const NodeValue = ({ node, showType = true, hideTemporal = false, temporalType = "bar", labelProps = {} }) => {
  let value;
  let display;
  let tooltip;
  const theme = useTheme();
  const { temporalSlice } = useGraphVisualisation();

  const labelColor = () => {
    if (display === 'Unknown' || display === 'Uncertain') return theme.palette.secondary.light;
    if(typeof value === 'boolean') {
      return value ? theme.labels.public : theme.labels.Author;
    }
    if ((typeof value === 'string' || typeof value === 'number') && value !== 'Entity' && value !== 'Undefined' && value !== 'uncertain') {
      return theme.labels.public;
    }

    return theme.palette.secondary.light;
  };

  if (typeof node.derived !== 'undefined' || typeof node.input !== 'undefined') {
    value = typeof node.input === 'undefined' ? node.derived : node.input;

    if ( typeof value === "string" && node.type === "date" ) {
      display = value.replace( /T.*$/, "" );
    }

    if (typeof value === 'undefined') display = 'Unknown';
    else if (typeof value === 'number') {
      display = roundToMaxDigitsAfterDot(value);
      if (value.countDecimals() > 4) tooltip = value;
    } else if (value === null) display = 'Uncertain';
    else if (value.temporal) {
      if (hideTemporal) return <Label color={theme.labels.public}>Temporal</Label>
      else if (temporalSlice) {
        display = getValueAtDate(value.temporal, temporalSlice);
        tooltip = `Displaying temporal value at ${moment(temporalSlice).format('YYYY-MM-DD')}`
      }
      else return <TemporalValueRender type={temporalType} node={node} value={value} />;
    } else display = value;
  } else display = 'Unknown';

  return (
    <>
      { tooltip ? (
        <Tooltip title={tooltip}>
          <span>
            <Label color={labelColor()} {...labelProps}>
              <span style={{ textDecoration: 'underline dashed'}}>{display}</span>
            </Label>
            { showType && typeof node.input !== 'undefined' && <Label style={{marginLeft: 10 }} color="lightgray">Input</Label> }
          </span>
        </Tooltip>
      ): (
        <>
          <Label color={labelColor()} {...labelProps}>
            { String(display) }
          </Label>
          { showType && typeof node.input !== 'undefined' && <Label style={{marginLeft: 10 }} color="lightgray">Input</Label> }
        </>
      )}
    </>
  )
};
export default NodeValue;
