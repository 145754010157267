
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";

import { getAuthHook, signOut } from "@imminently/imminently_platform";

import { Avatar, ActionDivider, ContextMenuItem, MenuC as Menu, useContextMenu } from "@components";
import { LogoIcon } from "@icons";
import find from "lodash/find";
import { showTenancy } from "@modals/TenancyModal";
import { config } from "../config";
import { Navigation } from "./Navigation";
import { Workspaces } from "./Workspaces";
import * as sidebarReduxNS from "./Sidebar/redux";
import { TaskButton } from "./tasks/TaskPanel";
import { useHotkey } from "./editor/RuleAuthor/util";
import { ReleaseNotifications } from "@pages/models/release/ReleaseNotifications/ReleaseNotifications";
import { Kbd } from "@components/kdb";
import { useRouting } from "./hooks/HooksRouting";
import { useIsAdmin } from "auth/permissions";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useErrorReporting } from "../ErrorReporting";

const UserAvatar = styled(Avatar)`
  border: 1px solid ${p => p.theme.header.color};
  cursor: pointer;

  &:hover {
    color: ${p => p.theme.palette.primary.main};
    border-color: ${p => p.theme.palette.primary.main};
  }
`;

const Logo = styled.img`
  height: 2.5rem;
  width: auto;
`;

const Spacer = styled.div`
  display: flex;
  flex-grow: 1;
`;

const AppBar = styled.header`
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 0 2rem;
  width: 100%;
  min-height: 3.5rem;
  color: ${props => props.theme.header.color};
  background-color: ${props => props.theme.header.background};
`;

const Icons = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.5rem;

  .MuiIconButton-root:hover {
    color: ${props => props.theme.palette.primary.main};
    background-color: ${props => props.theme.header.background};
  }
`;

const LogoWrap = styled.div< { isSidebarCollapsed: boolean } >`
  transition: transform 0.3s;
  ${p => (p.isSidebarCollapsed ? "transform: translateX(-1rem)" : "")};
`;

export const Header = () => {
  const { user } = getAuthHook();
  const { goToPage } = useRouting();
  const isAdmin = useIsAdmin();
  const dispatch = useDispatch();
  const isSidebarCollapsed = useSelector(s => sidebarReduxNS.getState(s).isCollapsed);
  const errorReporting = useErrorReporting();
  const { onContextMenu, isOpen, props, close } = useContextMenu();
  const [ lang, setLang ] = useState('en');
  const { t, i18n } = useTranslation();

  const toggleLanguage = () => {
    let newLang;
    switch (lang) {
      case 'en':
        newLang = 'it';
        break;
      case 'it':
        newLang = 'th';
        break;
      default:
        newLang = 'en';
    }
    console.log('toggling - was', lang, 'now', newLang);
    i18n.changeLanguage(newLang);
    setLang(newLang);
  }
  useHotkey("mod+shift+f", () => {
    errorReporting?.open();
  });

  const userMenuItems: any[] = [
    {
      node: <ActionDivider key="divider" style={{ margin: "0.5rem 0" }} />,
    }
  ];

  if (errorReporting) {
    userMenuItems.unshift({
      name: (
        <Box sx={{ display: "flex", gridGap: "1rem" }}>
          <span>
            <Trans i18nKey="feedback"></Trans>
          </span>
          <Kbd inline variant="separate" keys={["mod", "shift", "f"]} />
        </Box>
      ),
      onClick: () => {
        errorReporting.open();
      }
    });
  }
  userMenuItems.push({
    name: t('language_lang', {lang}),
    onClick: () => {
      // Change this to either popping up a model, or ideally a sub menu with the support languages
      toggleLanguage();
    }
  })

  if (isAdmin) {
    userMenuItems.push({
      name: t('administration'),
      onClick: () => {
        goToPage('/admin');
      }
    });
  }

  userMenuItems.push({
    name: t('sign_out'),
    onClick: () => {
      dispatch(signOut());
      // history.push('/loggedOut');
    },
  });

  if (user.groups) {
    const tenantId = localStorage.getItem("decisively.tenant");
    const region = localStorage.getItem("decisively.region");

    if (tenantId) {
      const tenant = find(user.groups, { id: tenantId });
      if (tenant) {
        const item = {
          name: tenant.name,
          onClick: () => dispatch(showTenancy()),
        };

        if (region) {
          item.name += ` (${region})`;
        }

        userMenuItems.unshift(item);
        // userMenuItems.unshift({
        //   node: <Typography variant='caption' style={{ padding: '0 1rem' }}>Tenancy (Region)</Typography>
        // });
      }
    }
  }

  // TODO pull account logo, example path: '/logo.svg'
  // TODO search and settings disabled until they are supported
  // @ts-ignore (this is inject by immi)
  const logoPath = window.__ENV__?.APP_LOGO || null;
  return (
    <AppBar>
      <Box display='flex' alignItems="center" gridGap='0.5rem'>
        <LogoWrap isSidebarCollapsed={isSidebarCollapsed}>
          {logoPath ? <Logo src={logoPath} alt='logo' /> : <LogoIcon />}
        </LogoWrap>
        <Workspaces />
      </Box>
      <Navigation />
      <Spacer />
      <Icons>
        <TaskButton />
        <ReleaseNotifications />
      </Icons>
      <UserAvatar mono size='medium' alt={user.first_name} src='/' onClick={onContextMenu} />
      {
        isOpen && (
          <Menu {...props}>
            {
              userMenuItems.map((item, i) => {
                if (item.node) {
                  return item.node;
                }

                const { name, onClick, ...props } = item;

                const handleClick = (e) => {
                  onClick(e);
                  close();
                }

                return (
                  <ContextMenuItem button key={i} onClick={handleClick} {...props}>
                    {name}
                  </ContextMenuItem>
                );
              })
            }
          </Menu>
        )
      }
    </AppBar>
  );
};
