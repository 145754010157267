import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Menu as MuiMenu, MenuItem, MenuProps, PopoverPosition, MenuItemProps } from "@material-ui/core";
import MenuItemNested from "./MenuItemNested";

export const MenuC = styled(MuiMenu)`
  .MuiPopover-paper {
    box-shadow: ${ props => props.theme.body.boxShadow };
    border-radius: 0.5rem;
  }

  .MuiList-root {
    padding: 0.75rem 0;
    border: none;
    box-shadow: none;
  }
`;

const menuItemStyles = css`
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.5rem;
  padding: 0.25rem 1rem;

  &:hover {
    background-color: ${ props => props.theme.palette.secondary.hover};
  }

  &.error {
    color: ${props => props.theme.palette.error.main};
    &:hover {
      color: ${props => props.theme.palette.common.white};
      background-color: ${props => props.theme.palette.error.main} !important;
    }
  }
`;
export const ContextMenuItem = styled(MenuItem)`${menuItemStyles}`;
/**
 * can be used infinitely to create multi-level nested menus:
 * ```tsx
 * <Menu
 *   {...menuProps}
 * >
 *   <ContextMenuItemNested
 *     label="Nested item..."
 *     parentMenuOpen={isOpen}
 *     onClick={nop}
 *   >
 *     <ContextMenuItem onClick={closeMenu}>Item 1</ContextMenuItem>
 *     <ContextMenuItem onClick={closeMenu}>Item 2</ContextMenuItem>
 *   </ContextMenuItemNested>
 * </Menu >
 * ```
 */
export const ContextMenuItemNested = styled(MenuItemNested)`${menuItemStyles}`;

// const ItemComp =

type ContextMenuItemProps = MenuItemProps & { name: string; };

export type ContextMenuProps = {
  position: PopoverPosition;
  open: boolean;
  onClose: () => void;
  items: ContextMenuItemProps[];
};

export const ContextMenu = ({ position, open, onClose, items }: ContextMenuProps) => {
  return (
    <MenuC
      keepMounted
      open={open}
      onClose={onClose}
      anchorReference='anchorPosition'
      anchorPosition={position}
    >
      {items.map(({ name, ...props }) => (
        // @ts-ignore stop complaining that true and boolean are not valid
        <ContextMenuItem key={name} {...props}>{name}</ContextMenuItem>
      ))}
    </MenuC>
  );
};

export type ContextMenuOptions = {
  onMenuOpen?: (event: React.MouseEvent) => void;
  onMenuClose?: () => void;
  /** Menu position offset from mouse */
  offset?: [number, number];
};

export const useContextMenu = (options: ContextMenuOptions = {}) => {
  const [offsetX, offsetY] = options.offset ?? [0, 0];
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const onContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - offsetX, mouseY: event.clientY - offsetY }
        : null,
    );
    options.onMenuOpen?.(event);
  };

  const close = () => {
    setContextMenu(null);
    options.onMenuClose?.();
  };

  const anchorPosition = contextMenu !== null
    ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
    : undefined;

  const props: MenuProps = {
    open: contextMenu !== null,
    onClose: close,
    anchorReference: "anchorPosition",
    anchorPosition: anchorPosition,
    // TODO does this need to be somewhere else? is it needed at all?
    // slotProps: {
    //   root: {
    //     onContextMenu: (e: React.MouseEvent) => {
    //       e.preventDefault();
    //       close();
    //     },
    //   },
    // }
  };

  return { onContextMenu, close, isOpen: contextMenu !== null, anchorPosition, contextMenu, props };
};