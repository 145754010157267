import React from 'react';


export type Item< T > = { id: string; info: T, children: Item< T >[] };

export type Value< T > = {
  items: Item< T >[];
  idToItem: Record< string, Value< T >[ 'items' ][ 0 ] >;
  expanded: string[];
  toggleExpanded: (id: string) => unknown;
  LabelComp: React.ComponentType< Pick< Item< T >, 'id' > >;
  selected: string[];
  onSelect: (id: string) => unknown;
  getIconById: (id: string) => React.ReactNode;
};


export const initialState: Value< unknown > = {
  idToItem: {},
  items: [],
  expanded: [],
  toggleExpanded: console.log,
  LabelComp: () => null,
  selected: [],
  onSelect: console.log,
  getIconById: () => null,
};

export const Ctx = React.createContext(initialState);
export type TypedCtx< T > = React.Context< Value< T > >;
export function useDottedTreeCtx<T>(): Value< T > {
  return React.useContext(Ctx) as Value< T >;
}

// ===================================================================================


export const createToggleExpanded = (
  (setExpanded: React.Dispatch< React.SetStateAction< string[] > >): Value< unknown >[ 'toggleExpanded' ] => (
    id => setExpanded(s => (s.some(it => it === id) ? s.filter(it => it !== id) : s.concat(id)))
  )
);
export const createOnSelectForSingle = (
  (setSelected: React.Dispatch< React.SetStateAction< string[] > >): Value< unknown >[ 'onSelect' ] => (
    id => setSelected([id])
  )
);


function gathrAllIdsWithChildren( node: Item< unknown >, acc: string[] = [] ): typeof acc {
  if ( node.children.length === 0 ) return acc;

  const accForReduce = acc.concat( node.id );
  return node.children.reduce(
    ( a, child ) => gathrAllIdsWithChildren( child, a ),
    accForReduce
  );
}
export function gatherIdsForExpandAll( items: Item< unknown >[] ): string[] {
  return gathrAllIdsWithChildren({ id: "", info: { desc: "", heading: "" }, children: items })
    .filter( Boolean );
}
