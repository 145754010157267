import { all, put, select, takeLatest } from "redux-saga/effects";
import * as sliceNS from "../slice";
import * as debugRedux from "../../../Debug/redux";


/**
 * several places in debugger are written using local state.\
 * For reset functionality we would need to call some of \
 * those, but that would add a lot of prop drilling. Instead\
 * what we can do is enclose those local methods in this hash\
 * and upon reset action dispatched to redux use them from \
 * here.
 */
type MethodRefs = {
  inner: {
    setGraph: (graph?: any) => unknown;
    resetCompact: () => unknown;
    /**
     * default value for raw gets generated based on current\
     * selected goal and graph. It's kinda unclear how to reliably\
     * access those. So instead let's store that generated payload\
     * here and hydrate raw state with it when we reset
     */
    defaultPayloadForRaw: string;
  }
}


export const aTypes = {
  encloseMethodRefs: "batch-debug-for-reset/enclose-method-refs",
  reset: "batch-debug-for-reset/reset",
} as const;

export type Actions = {
  encloseMethodRefs: {
    type: typeof aTypes.encloseMethodRefs;
    payload: Partial< MethodRefs[ "inner" ] >;
  };
  reset: {
    type: typeof aTypes.reset;
  }
}

export const aCreators: {
  [ N in keyof Actions ]: Actions[ N ] extends { payload: unknown }
    ? ( p: Actions[ N ][ "payload" ] ) => Actions[ N ]
    : () => Actions[ N ]
} = {
  encloseMethodRefs: p => ({ type: aTypes.encloseMethodRefs, payload: p }),
  reset: () => ({ type: aTypes.reset }),
};

export function* saga() {
  const methodRefs: MethodRefs = {
    inner: {
      setGraph: console.log,
      resetCompact: console.log,
      defaultPayloadForRaw: "{}",
    }
  };

  yield all([
    // eslint-disable-next-line require-yield
    takeLatest(aTypes.encloseMethodRefs, function* onEncloseMethodRefs(a: Actions[ "encloseMethodRefs" ]) {
      const { payload } = a;

      methodRefs.inner = {
        ...methodRefs.inner,
        ...payload,
      };
    }),
    takeLatest(aTypes.reset, function* onReset() {
      const state = yield select();
      const { tab } = debugRedux.getState(state);

      yield put({ type: "DCSVLY/BATCHCALL_RESET" });
      methodRefs.inner.setGraph();

      if ( tab === debugRedux.tabs[3].value ) {
        yield put(sliceNS.actions.setState( methodRefs.inner.defaultPayloadForRaw ));

        return;
      }

      if ( tab === debugRedux.tabs[2].value ) {
        methodRefs.inner.resetCompact();
      }
    })
  ]);
}
