import styled from "styled-components";
import { Size,
  getSize } from "../util/UtilStyling";

interface BadgeProps {
  size? : Size;
  labelType? : string;
  /** The inner text colour */
  color? : string;
  bgColorOverride? : string;
}

/**
 * TODO merge with badge in meta.jsx
 * TODO switch over to withStyles, and away from styled-components
 */
export const Badge = styled("span")<BadgeProps>(({ children, theme, size, labelType, color, bgColorOverride }) => {

  const sizeAbs = getSize(size, true);
  const sizeRem = getSize(size);

  const backgroundColor = typeof children === "string"
    ? bgColorOverride ?? ((labelType && theme.labels[labelType]) || theme.labels[children] || theme.palette.primary.main)
    : theme.palette.primary.main;

  return ({
    backgroundColor: backgroundColor,
    height         : sizeRem,
    // lineHeight     : sizeRem,
    minWidth       : sizeRem,
    borderRadius   : "8px",
    fontSize       : "0.75rem",
    textAlign      : "center",
    color          : color ?? theme.palette.primary.contrastText,
    padding        : `${ sizeAbs * 0.125 }rem ${ sizeAbs * 0.25 }rem`,
  });
});
