import { useMemo } from "react";
import ReleaseContext from "@pages/models/release/ReleaseContext";
import AttributeContext from "@pages/models/release/AttributeContext";
import RuleGraphProvider from "@components/GraphVisualisation/providers/RuleGraphProvider";
import MainExplanation from "@components/GraphVisualisation/components/AttributeInfo/AttributeExplanation";
import { useFullReleaseStrict } from "@common/hooks_useFullRelease";
import { GraphNode, ParsedRuleGraph } from "@packages/commons";
import { parseRawGraph } from "@components/RuleGraph_FullRelease/__parseRawGraph";

/** A basic wrapper which auto provides the required contexts */
export const AttributeExplanation = ({ node }: { node: GraphNode }) => {
  const release = useFullReleaseStrict();

  const graph = useMemo<ParsedRuleGraph>(() => parseRawGraph(release?.rule_graph), [release?.rule_graph]);
  
  return (
    <RuleGraphProvider graph={graph}>
      <ReleaseContext.Provider value={release}>
        <AttributeContext.Provider value={node}>
          <MainExplanation attr={node} />
        </AttributeContext.Provider>
      </ReleaseContext.Provider>
    </RuleGraphProvider>
  );
};