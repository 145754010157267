import cn from "clsx";
import escapeRegExp from "lodash/escapeRegExp";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import Tooltip from "@material-ui/core/Tooltip";
import { IconButton, Typography } from "@material-ui/core";
import { DatasheetIcon, DocumentsIcon, FileIcon, TableIcon, FolderIcon } from "@icons";

import stl from "./SearchAndSelectResultItem.module.scss";
import { Badge } from "@components/badge/Badge";
import { ReleaseDocument } from "@packages/commons";
import { SearchResult } from "./Search.types";
import { forwardRef } from "react";

export interface SearchAndSelectResultItemProps {
  result: SearchResult
  additionalInfo?: {
    entityName?: string;
    type?: string;
    matchingText?: string;
    highlightStr?: string;
  };
  onDocumentOpen?: (documentPath: string, documentId: string, newWind: boolean) => void;
}

const getIcon = (type: string, version: number) => {
  switch (type) {
    case "ruledoc":
      return version === 1 ? <FileIcon /> : <DocumentsIcon />;
    case "rulesheet":
      return <DatasheetIcon />;
    case "datatable":
      return <TableIcon />;
    default:
      return <FileIcon />;
  }
};

/**
 * Wraps any matching text in a span with a class name
 */
const HighlightedText = ({ text, highlight }: { text: string, highlight: string }) => {
  const parts = text.split(new RegExp(`(${escapeRegExp(highlight)})`, "gi"));
  return (
    <>
      {
        parts.map((part, i) => (
          <span
            key={i}
            className={cn(
              { [stl.highlight]: part.toLowerCase() === highlight.toLowerCase() },
            )}
          >
            {part}
          </span>
        ))
      }
    </>
  );
};

const SearchAndSelectResultItem = forwardRef<HTMLDivElement, SearchAndSelectResultItemProps>((props, ref) => {
  const { result: { original }, additionalInfo } = props;
  const { isInput, referencedBy, document, documentName, documentPath } = original;
  // filter to ensure array is valid elememts, we can't trust the data
  const referencedBySansDoc = (referencedBy || [])
    .filter(Boolean)
    .filter((ref) => ref.name !== document?.name);

  const openDocumentInNewWindow = (docPath: string, docId: string) => () => {
    if (props.onDocumentOpen) {
      props.onDocumentOpen(docPath, docId, true);
    }
  };

  const openDocumentInSameWindow = (docPath: string, docId: string) => () => {
    if (props.onDocumentOpen) {
      props.onDocumentOpen(docPath, docId, false);
    }
  };

  const usedByContainer = (usedByDocs: ReleaseDocument[]) => {
    if (!usedByDocs || usedByDocs.length === 0) {
      return null;
    }

    return (
      <div className={cn(stl.containerRow)}>
        <Typography variant="caption" className={cn(stl.nowrap, stl.bold)}>
          Used by
        </Typography>
        <div className={stl.rhs}>
          {
            usedByDocs.map((doc) => (
              <div
                key={doc.reference}
                className={stl.usedByDoc}
                onClick={openDocumentInSameWindow(doc.name, doc.reference)}
              >
                {getIcon(doc.type, doc.version)}
                <Typography variant="caption">{doc.name}</Typography>
              </div>
            ))
          }
        </div>
      </div>
    );
  };

  const actionsContainer = () => {
    if (!document) return null;
    return (
      <div className={stl.containerActions}>
        <Tooltip title="Open in new window" placement="left">
          <IconButton onClick={openDocumentInNewWindow(document.name, document.reference)}>
            <OpenInNewIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Open document" placement="left">
          <IconButton onClick={openDocumentInSameWindow(document.name, document.reference)}>
            <OpenInBrowserIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  const resultContainer = () => {
    return (
      <div className={stl.containerResult}>
        {
          !document
            ? null
            : (
              <div>
                <div className={stl.documentName}>
                  {getIcon(document.type, document.version)}
                  <Typography>{documentName}</Typography>
                  {actionsContainer()}
                </div>
                <div className={stl.documentPath}>
                  <FolderIcon />
                  <Typography>{documentPath}</Typography>
                </div>
              </div>
            )
        }
        <Tooltip disableFocusListener title={additionalInfo?.matchingText || ""}>
          <div className={cn(stl.attributeValue, stl.clamp3Lines)}>
            <HighlightedText
              text={additionalInfo?.matchingText || ""}
              highlight={additionalInfo?.highlightStr || ""}
            />
          </div>
        </Tooltip>
        <div className={stl.badgeRow}>
          {
            (additionalInfo?.entityName)
              ? <Badge labelType="primary">{additionalInfo.entityName}</Badge>
              : null
          }
          {
            isInput
              ? <Badge labelType="public">Input</Badge>
              : null
          }
          {
            (additionalInfo?.type)
              ? <Badge labelType="private">{additionalInfo.type}</Badge>
              : null
          }
        </div>
        {usedByContainer(referencedBySansDoc)}
      </div>
    );
  };

  return (
    <div ref={ref} className={stl.container}>
      {resultContainer()}
    </div>
  );
});

export default (SearchAndSelectResultItem);
