import { scrollableMixin, useCurrentRelease } from "@common";
import type { GraphNode, RawRuleGraph } from "@packages/commons";
import { useGraph } from "@pages/models/release/GraphContext";
import {
  ELEMENT_MENTION_INPUT,
  MentionCombobox,
  createMentionPlugin,
  createPluginFactory,
  getEditorString,
  getPlugin,
  getPointBefore,
  getRange,
  isSelectionInMentionInput,
  withMention,
} from "@udecode/plate";
import { graphlib } from "dagre";
import React from "react";
import { css } from "styled-components";
import { Item, getMentionOnSelectItem } from "../functions/functions";

const filter = (text) => (value) => value.text.toLowerCase().indexOf(text.toLowerCase()) !== -1;

/**
  @typedef Item
  @type {{
    key: string;
    text: string;
    value: string;
  }}
 */

export const AttributeComboboxJsx = React.createElement(() => {
  //const l = useCurrentRelease();
  let rawGraph: RawRuleGraph | null = null;
  // this is super-hacky - but I think Slate lives on in the DOM before unmounting
  // we were getting error when moving between pages and this no longer being in a GraphContext
  try {
    rawGraph = useGraph();
  } catch (error) {
    return null;
  }
  const graph = React.useMemo(
    () =>
      rawGraph
        ? /** @type { graphlib.Graph<{ id: String; description?: string }> } */ (graphlib.json.read(rawGraph))
        : null,
    [rawGraph],
  );

  const attributes = React.useMemo(() => {
    if (graph === null) return [];

    return graph.nodes().map((nodeId) => {
      const { id, description } = graph.node(nodeId) as unknown as GraphNode;
      const desc = description || id;

      /** @type { Item } */
      const item = {
        key: id,
        text: desc,
        value: desc,
      };
      return item;
    });
  }, [graph]);

  return (
    <MentionCombobox
      items={attributes}
      pluginKey="@"
      onRenderItem={Item}
      onSelectItem={getMentionOnSelectItem({ key: "@" })}
      filter={filter}
      styles={{
        root: [
          css`
            border-radius: 0.5rem;
            box-shadow: ${(p) => p.theme.body.boxShadow};
            width: 16rem;
            overflow: hidden;
            ${scrollableMixin};
          `,
        ],
      }}
    />
  );
});

const withCustomMention = (editor, plugin) => {
  // pass to original withMention
  const e = withMention(editor, plugin);
  const {
    options: { trigger, query, triggerPreviousCharPattern, inputCreation },
  } = plugin;
  const { insertText, insertNode } = e;
  const { type } = getPlugin(editor, ELEMENT_MENTION_INPUT);

  // override insertText with latest method
  // see https://github.com/udecode/plate/blob/main/packages/mention/src/withMention.ts
  e.insertText = (text) => {
    if (!editor.selection || text !== trigger || (query && !query(editor)) || isSelectionInMentionInput(editor)) {
      return insertText(text);
    }

    // Make sure a mention input is created at the beginning of line or after a whitespace
    const previousChar = getEditorString(
      editor,
      getRange(editor, editor.selection, getPointBefore(editor, editor.selection)),
    );
    const matchesPreviousCharPattern = triggerPreviousCharPattern?.test(previousChar);

    if (matchesPreviousCharPattern && text === trigger) {
      const data = {
        type,
        children: [{ text: "" }],
        trigger,
      };
      if (inputCreation) {
        data[inputCreation.key] = inputCreation.value;
      }
      return insertNode(data);
    }

    return insertText(text);
  };

  return e;
};

export const createAttributesPlugin = createPluginFactory({
  key: "attributes",
  plugins: [
    createMentionPlugin({
      key: "@",
      withOverrides: withCustomMention,
      options: {
        trigger: "@",
        // @ts-ignore
        triggerPreviousCharPattern: /^[\s(,]?$/,
        insertSpaceAfterMention: false,
      },
    }),
  ],
});
