
export const isStrNotNullOrBlank = (str: any): str is string => (!(/^\s*$/).test(str || ''));
export const isStrNullOrBlank = (str: any): str is (null | "") => !isStrNotNullOrBlank(str);
export const isStrUuid = (str: any) => (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i).test(str || '');

export const toTitleCase = (str: any) => {
  if (typeof str !== 'string') {
    return str;
  }

  return str.replace(/\p{L}+('\p{L}+)?/gu, txt => {
    return txt.charAt(0).toUpperCase() + txt.slice(1);
  });
};

export const isString = (str: any): str is string => typeof str === 'string' || str instanceof String;
