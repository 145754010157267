import { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { Box, Card, CardContent, CardHeader, IconButton, Typography } from "@material-ui/core";
import { PlateProvider, TEditableProps } from "@udecode/plate";
import { Avatar, Button } from "@components";
import { PlateRichTextEditor, richTextPlugins } from "@common/editor/components/PlateRichTextEditor";
import { EmptyContent } from "@common/EmptyContent";
import { scrollable } from "@common/scrollbar";
import { Comment } from "@packages/commons";
import { tasksService } from "services";
import { newParagraph } from "./taskUtils";
import styles from "./tasks.module.scss";
import { Skeleton } from "@material-ui/lab";
import { Sort } from "@imminently/immi-query";
import { useNotification } from "@common/hooks/HooksNotification";
import { LoadingDots } from "@icons";
import moment from "moment";

const editableProps = (props?: TEditableProps) => ({
  autoFocus: false,
  spellCheck: false,
  ...props,
});

const formatDate = (date: string) => {
  const created = moment(date);
  const now = moment();
  const sameDay = created.isSame(now, "day");
  return sameDay ? created.format("h:mm A") : created.format("MMM D, YYYY");
};

const CommentCard = ({ comment }: { comment: Comment }) => {
  const title = (
    <div className={styles.commentTitle}>
      <span>{comment.createdByName}</span>
      <span>{formatDate(comment.created)}</span>
    </div>

  );
  return (
    <Card className={styles.commentCard}>
      <CardHeader className={styles.commentCardHeader} avatar={<Avatar size="small" name={comment.createdByName} />} title={title} />
      <CardContent>
        <PlateProvider id={comment.id} plugins={richTextPlugins} value={comment.text} readOnly>
          <PlateRichTextEditor id={comment.id} editableProps={editableProps({ readOnly: true })} />
        </PlateProvider>
      </CardContent>
    </Card>
  );
};

const CardSkeleton = () => (
  <Card className={styles.commentCard}>
    <Skeleton variant="text" width="100%" height={40} />
    <Skeleton variant="rect" width="100%" height={100} />
  </Card>
);

export const CommentsPanel = ({ taskId }: { taskId: string; }) => {
  const notify = useNotification();
  const commentsService = tasksService.useSubresource<Comment>("comments", taskId);
  const { data: comments, total, query: { isLoading } } = commentsService.useResources({
    filter: {
      sort: { field: "created", order: "desc" } as Sort,
    }
  });

  const { mutateAsync: postComment, isLoading: isPosting } = commentsService.useCreate();

  const ref = useRef<HTMLDivElement>(null);
  const [key, setKey] = useState(0); // render key, update to force refresh on input
  const [comment, setComment] = useState<any>([newParagraph("")]);

  const scrollToBottom = () => {
    if (ref.current) {
      ref.current.scrollTo({ behavior: "smooth", top: ref.current.scrollHeight });
    }
  };

  const onSubmit = () => {
    console.log(comment);
    postComment({ text: comment })
      .then(() => {
        setComment([newParagraph("")]);
        setKey((prev) => prev + 1);
        scrollToBottom();
      })
      .catch((e) => {
        notify.error("Failed to post comment");
        console.error(e)
      });
  };

  useEffect(() => {
    if (!isLoading && !isPosting) {
      scrollToBottom();
    }
  }, [isLoading, isPosting]);

  // reverse is in-place, so copy the array
  const commentHistory = [...comments].reverse();

  return (
    <Box className={clsx(styles.flexGrow, styles.fullHeight)}>
      {/* TOOD make use of subresources for comments? */}
      <Box className={styles.header}>
        <Typography variant="h5" style={{ flex: 1, lineHeight: "1rem" }}>Comments</Typography>
      </Box>
      <div ref={ref} className={clsx(styles.content, styles.comments, scrollable)}>
        {
          isLoading ? (
            <>
              <CardSkeleton key="skele-1" />
              <CardSkeleton key="skele-2" />
              <CardSkeleton key="skele-3" />
            </>
          ) : (
            comments.length === 0 ? (
              <EmptyContent messages={["No comments"]} />
            ) : (
              <>
                {
                  total > comments.length
                    ? (
                      <Typography variant="caption" style={{ textAlign: "center", width: "100%" }}>
                        Showing {comments.length} of {total} comments
                        (TODO load more)
                      </Typography>
                    )
                    : null
                }
                {commentHistory.map((comment) => <CommentCard key={comment.id} comment={comment as Comment} />)}
              </>
            )
          )
        }
      </div>
      <Box className={styles.actions}>
        <Box className={clsx(styles.textBox, styles.commentTextArea)}>
          <PlateProvider key={key} id="comment-editor" plugins={richTextPlugins} value={comment} onChange={(v) => setComment(v)}>
            <PlateRichTextEditor id="comment-editor" editableProps={editableProps({ placeholder: "Write a comment..." })} />
          </PlateProvider>
          <Button variant="contained" color="primary" onClick={onSubmit} disabled={isPosting}>
            {isPosting ? <LoadingDots /> : "Send"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};