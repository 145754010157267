
// import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import SplitPane from 'react-split-pane';


/** @type { React.FC< any > } */
export const Resizable = styled(SplitPane)`
  .Resizer {
    background: none;
    /* width: 1px; */
    height: 100%;
    cursor: url(/slider.svg) 16 16, col-resize;
    /* border-left: 1px solid ${ props => props.theme.palette.background.border }; */
    padding: 2px;
    z-index: 5; // ensure draws above pane 2, and graph
    position: relative;
    transition: background-color .2s cubic-bezier(.785,.135,.15,.86) .2s;
    contain: strict;

    &:hover {
      background-color: ${ props => props.theme.palette.secondary.light };
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      background-color: ${ props => props.theme.palette.background.border };
      width: 1px;
      left: 0;
    }
  }

  .Pane2 {
    margin-left: -4px; // offset resize handle padding
  }

  &.disabled {
    .Resizer {
      cursor: default;
    }
  }
`;

