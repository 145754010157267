import { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { useRouting } from '@common/hooks/HooksRouting';

// https://www.robinwieruch.de/react-router-private-routes/
// https://dev.to/iamandrewluca/private-route-in-react-router-v6-lg5

// guards need to be outlets, as they might need to access redux etc

export type Guard = () => boolean;

export type ProtectedProps = {
  /** A react hook to enforce requirements. */
  useGuard: Guard;
  /** The desired route to redirect on failing to pass the guard. */
  redirect?: string;
} & PropsWithChildren;

export const Protected = ({ useGuard, redirect, children }: ProtectedProps) => {
  const permission = useGuard();
  let location = useLocation();
  const { goToPage } = useRouting();

  if (!permission) {
    if (redirect) {
      console.log('[Protected] redirecting', { from: location, to: redirect });
      goToPage(redirect, { from: location, replace: true });
    }
    return null;
  }

  return children ? <>{children}</> : <div>ERROR: GUARD HAS NO CHILDREN</div>;
};
