/* eslint-disable no-param-reassign */
// @ts-nocheck need to fix the weird p.css issue

import cn from "classnames";
import styled from "styled-components";

import {
  ELEMENT_PARAGRAPH,
  ELEMENT_TABLE,
  ELEMENT_TH,
  ELEMENT_TR,
  type PlaceholderProps,
  type PlateEditor,
  type TDescendant,
  type TElement,
  type TNodeEntry,
  TableCellElement,
  TableElement,
  type Value,
  createPluginFactory,
  createTablePlugin,
  exitBreak,
  getPreventDefaultHandler,
  getTableEntries,
  isElementEmpty,
  withGetFragmentTable,
  withPlaceholder,
  withSelectionTable,
} from "@udecode/plate";
import { type BaseEditor, Editor, type NodeEntry } from "slate";

import { SmallAdd } from "@icons";
import { getNodeById, getSelectedNode, handlePluginKeyDown, withHotKeys } from "../../plugins/util";
import { ELEMENT_CONCLUSION, ELEMENT_RULE } from "../elements";
import { getRuleType, ruleToParagraph } from "../rule";
import { tableStyles } from "./tables.styles";
import { type TRow, getParentTable, getRow, insertRow, insertRowAbove, insertRowBelow, isInTable } from "./transforms";
import { TYPES } from "./types";

export const tableGetFragment = (editor: PlateEditor, fragment: TDescendant[]) => {
  if (Array.isArray(fragment)) {
    return fragment.map((node) => {
      if (node.type === ELEMENT_TABLE) {
        if (node.children.length === 1) {
          // only 1 cell, do a normal copy
          return node.children[0]?.children[0]?.children[0];
        }
        const hasTh = node.children[0]?.children[0]?.type === ELEMENT_TH;
        if (!hasTh) {
          const entries = getTableEntries(editor);
          const table = entries?.table?.[0];
          if (table?.children) {
            const thRow = table.children.find((row) => row.children[0]?.type === ELEMENT_TH);
            if (thRow) {
              return {
                ...node,
                children: [{ children: thRow.children, type: "tr" }, ...node.children],
              };
            }
          }
        }
      }
      return node;
    });
  }
  return fragment;
};

const nodeIsConclusion = (node: TElement) => node.type === ELEMENT_RULE && getRuleType(node) === ELEMENT_CONCLUSION;

const isDataRow = (row) => {
  return !row.isHeader && !row.isLast;
};

const withTables = (editor: PlateEditor) => {
  const {
    deleteBackward,
    deleteForward,
    insertBreak,
    normalizeNode,
    insertFragment,
    getFragment,
    // deleteFragment
  } = editor;

  editor.normalizeNode = (n) => {
    const [node, path] = n;
    // TODO used to hard disallow paragraphs in tables
    // TODO determine if we need this anymore
    // if (node.type === ELEMENT_PARAGRAPH && isInTable(editor, { at: path })) {
    //   Transforms.removeNodes(editor, { at: path });
    //   insertNode(editor, create)
    //   return;
    // }
    // if we put a second conclusion in the row, convert to para
    if (node.type === ELEMENT_RULE && getRuleType(node as TElement) === ELEMENT_CONCLUSION) {
      // if we are in a row and second one, change to paragraph
      const [row] = getRow(editor, { at: path });
      // conclusion rule should be first child, so simply check the index
      if (row && !row.isHeader && !row.isLast && path[path.length - 1] !== 0) {
        console.log("alt conclusion in table, forcing to para", n);
        ruleToParagraph(editor, path);
      }
    }

    normalizeNode(n);
  };

  const handler =
    (callback, operation) =>
    (...args) => {
      let res = false;
      if (isInTable(editor)) {
        const row = getRow(editor);
        const node = getSelectedNode(editor);

        // FIXME selection when pasting seems to be forced into a single point
        // also, if we determine no action is desired, it still performs the
        // delete

        // if(Range.isExpanded(editor.selection)) {
        //   // handle edge case of selection range includes header or last
        //   // const [start, end] = Range.edges(editor.selection);
        //   const [startRow] = getRow(editor, { at: editor.selection.anchor });
        //   const [endRow] = getRow(editor, { at: editor.selection.focus });
        //   if(!isDataRow(startRow) || !isDataRow(endRow)) {
        //     // cant paste here
        //     Transforms.select(editor, editor.selection.focus);
        //     return;
        //   }
        // }

        res = callback(row, node, ...args);
      }

      if (!res) {
        operation(...args);
      }
    };

  // FIXME this doesnt fix the delete issue either
  // editor.deleteFragment = handler((row, node, fragments) => {
  //   console.log('delete fragment', row, node, 'frags?', fragments);

  //   // deny if you are trying to delete header or last row
  //   return !isDataRow(row);
  // }, deleteFragment);

  editor.getFragment = () => {
    const fragment = getFragment();
    return tableGetFragment(editor, fragment);
  };

  editor.insertFragment = (fragment) => {
    // covert table to just nodes on their own when pasting outside a table
    /*if (!isInTable(editor)) {
      fragment = fragment.flatMap(fragment => {
        if (fragment.type === ELEMENT_TABLE) {
          const result = [];
          for (const tr of fragment.children) {
            for (const td of tr.children) {
              result.push(...td.children);
            }
          }
          return result;
        }
        return fragment;
      })
    }*/

    return insertFragment(fragment);
  };

  editor.deleteBackward = handler(([row], [node]) => {
    // we know we have a selection as we are currently pressing delete
    const offset = editor.selection.anchor.offset;
    const startOfLine = offset === 0;

    if (row.isHeader) {
      return isElementEmpty(editor, node) || startOfLine;
    }

    if (row.isLast) {
      return isElementEmpty(editor, node) || startOfLine;
    }

    const isOnlyChild = row.children?.[0].children.length === 1;
    if (startOfLine && isOnlyChild) {
      const parent = getParentTable(editor as BaseEditor);
      // if table exists and has only 3 rows, allow delete
      return parent && parent[0].children.length <= 3;
    }

    return nodeIsConclusion(node) && (isElementEmpty(editor, node) || startOfLine);
  }, deleteBackward);

  editor.deleteForward = handler(([row], [node, path]) => {
    // we know we have a selection as we are currently pressing backspace
    const offset = editor.selection.anchor.offset;
    const end = Editor.end(editor as BaseEditor, path).offset;
    const endOfLine = offset === end;

    if (row.isHeader) {
      return isElementEmpty(editor, node) || endOfLine;
    }

    if (row.isLast) {
      return isElementEmpty(editor, node) || endOfLine;
    }

    const isOnlyChild = row.children?.[0].children.length === 1;
    if (endOfLine && isOnlyChild) {
      const parent = getParentTable(editor as BaseEditor);
      // if table exists and has only 3 rows, allow delete
      return parent && parent[0].children.length <= 3;
    }

    return nodeIsConclusion(node) && (isElementEmpty(editor, node) || endOfLine);
  }, deleteForward);

  editor.insertBreak = handler(([row]) => {
    if (row.isLast) {
      return exitBreak(editor, { level: 0, defaultType: ELEMENT_PARAGRAPH });
    }
  }, insertBreak);

  return editor;
};

const RowAction = styled(SmallAdd)`
  position: absolute;
  left: -4.5rem;
  z-index: 6;
  cursor: pointer;
  margin: 0 !important;
`;

export const TableCell = (props: any) => {
  const { children, editor, element } = props;
  const [, path] = getNodeById(editor, element.id);
  // We are the row, so this won't be undefined
  const [row, rowPath] = getRow(editor, { at: path }) as NodeEntry<TRow>;
  if (!row) {
    return <TableCellElement {...props}>{children}</TableCellElement>;
  }
  const hasAbove = !row.isHeader;
  const hasBelow = !row.isLast;

  return (
    <TableCellElement {...props}>
      {children}
      {hasAbove && (
        <RowAction
          className="action"
          style={{ top: "calc(-0.5rem - 1px)" }}
          onClick={getPreventDefaultHandler(insertRowAbove, editor, rowPath)}
        />
      )}
      {hasBelow && (
        <RowAction
          className="action"
          style={{ bottom: "-0.5rem" }}
          onClick={getPreventDefaultHandler(insertRowBelow, editor, rowPath)}
        />
      )}
    </TableCellElement>
  );
};

const HeaderElem = styled.div`
  // @ts-ignore unpack the css from the css prop
  ${(p) => p.css}

  ::before {
    opacity: 1;
    font-weight: bold;
    color: ${(p) => p.theme.palette.secondary.dark};
    position: relative;
    display: inline-block;
  }
`;

export const TableHeaderElem = ({ className, children, nodeProps = {}, ...otherProps }: any) => {
  const { styles, placeholder } = nodeProps;
  return (
    <HeaderElem
      className={cn(className, styles.root.className)}
      css={styles?.root?.css}
      placeholder={placeholder}
      {...otherProps}
    >
      {children}
    </HeaderElem>
  );
};

export const TableElem = (props: any) => {
  // set top and bottom explicitly so we don't override left and right
  return (
    <div style={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}>
      <TableElement {...props} />
    </div>
  );
};

export const createDecisionTablesPlugin = createPluginFactory({
  key: "tables",
  withOverrides: withTables,
  plugins: [
    createTablePlugin({
      key: ELEMENT_TABLE,
      props: {
        styles: {
          root: [tableStyles],
        },
      },
    }),
    {
      key: ELEMENT_TH,
      isElement: true,
      props: {
        className: "table-header",
      },
    },
    {
      key: ELEMENT_TR,
      isElement: true,
      props: {
        className: "table-row",
      },
      ...withHotKeys([
        {
          keys: "alt+enter",
          action: (editor) => {
            if (isInTable(editor)) {
              const [row] = getRow(editor);
              console.log("table shift enter!");
              insertRow(editor, { before: row?.isLast });
              return true;
            }
          },
          query: {
            // @ts-ignore the type is fine, its complaining cause its too locked down
            filter: (n: TNodeEntry<TRow>) => !n[0].isLast,
          },
        },
      ]),
    },
    // {
    //   key: ELEMENT_TD,
    //   isElement: true,
    //   component: TableCell,
    //   props: {
    //     className: 'table-cell',
    //   }
    // },
    {
      key: TYPES.HEADER,
      isElement: true,
      component: withPlaceholder(TableHeaderElem, {
        placeholder: "Enter conclusion",
        hideOnBlur: false,
      } as PlaceholderProps<Value>),
      handlers: {
        onKeyDown: handlePluginKeyDown((editor, event) => {
          const { key } = event;
          if (key === "Enter") {
            event.preventDefault();
            insertRow(editor);
          }
        }),
      },
    },
  ],
});
