import styled from "styled-components";
import { Link } from "react-router-dom";
import { Card, Typography } from "@material-ui/core";
import { Button } from "@components";

const Info = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 2rem 4rem;
`;

const LoggedOut = () => {
  return (
    <Info>
      <Typography component="h1" variant="h1" align="center">
        You are signed out
      </Typography>

      <Typography component="h2" variant="body1" align="center">
        You can safely close this browser window
      </Typography>

      <Button component={Link} to="/" variant="contained">
        Log In
      </Button>
    </Info>
  );
};

export default LoggedOut;


