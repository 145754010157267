import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";
import * as ReactPopover from "@radix-ui/react-popover";
import clsx from "clsx";
import styles from "./popover.module.scss";

const Popover = ReactPopover.Root;
const PopoverTrigger = ReactPopover.Trigger;

export type PopoverContentProps =
  ComponentPropsWithoutRef<typeof ReactPopover.Content>
  & { portal?: ComponentPropsWithoutRef<typeof ReactPopover.Portal> };

const PopoverContent = forwardRef<ElementRef<typeof ReactPopover.Content>, PopoverContentProps>(
  ({ className, align = "start", sideOffset = 4, portal: { container = document.body } = {}, ...props }, ref) => (
    <ReactPopover.Portal container={container}>
      <ReactPopover.Content
        ref={ref}
        align={align}
        sideOffset={sideOffset}
        className={clsx(styles.popover, className)}
        {...props}
      />
    </ReactPopover.Portal>
  )
);
PopoverContent.displayName = ReactPopover.Content.displayName;

export { Popover, PopoverTrigger, PopoverContent };