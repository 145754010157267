import { ELEMENT_CONCLUSION } from "@common/editor/components/elements";
import {
  type DOMHandlers,
  type EAncestorEntry,
  type EElementOrText,
  ELEMENT_CODE_BLOCK,
  ELEMENT_CODE_LINE,
  ELEMENT_HR,
  ELEMENT_PARAGRAPH,
  type PlateEditor,
  type PlatePlugin,
  type QueryNodeOptions,
  type TElement,
  type TNode,
  type Value,
  getNextSiblingNodes,
  getNode,
  getNodeEntries,
  getNodeParent,
  getParentNode,
  insertNodes,
  isElement,
  isType,
  queryNode,
  toggleList,
  unwrapList,
} from "@udecode/plate";
import isHotkey from "is-hotkey";
import { castArray } from "lodash";
import {
  type BaseEditor,
  type BaseRange,
  Editor,
  type Location,
  Node,
  type NodeEntry,
  Path,
  Range,
  Transforms,
} from "slate";
import { v4 as uuidv4 } from "uuid";

// create keydown handler util
export const handlePluginKeyDown = (cb) => (editor: PlateEditor, plugin: PlatePlugin) => (event) => {
  try {
    const { selection } = editor;
    const { path } = selection?.anchor || { path: [0], offset: 0 };
    const [parent] = Editor.parent(editor as BaseEditor, path) as NodeEntry<TNode>;
    if (parent.type === plugin.type) {
      cb?.(editor, event, parent);
    }
  } catch (err) {
    // likely no selection, eitherway don't process anything
  }
};

export const getSelectedNode = <T extends TNode = TNode>(editor: BaseEditor | PlateEditor, path?: Location) => {
  try {
    path = path ? path : (editor.selection?.anchor.path as Location);
    const node = Editor.node(editor as BaseEditor, path) as NodeEntry<T>;
    if (node[0].type) return node;

    return Editor.parent(editor as BaseEditor, path) as NodeEntry<T>;
  } catch (err) {
    return [undefined, undefined];
  }
};

export const getNearestNode = (editor: BaseEditor | PlateEditor, match: MatchFunction, path?: Path) => {
  if (path) {
    // check if path is a rule
    const node = getNode(editor as TNode, path);
    if (node && match(node)) return [node, path] as NodeEntry;
  }
  // see if there is a rule above, ie we are in a ref or text
  const entry = Editor.above(editor as BaseEditor, { at: path, match: match as any });
  if (entry !== undefined) {
    return entry;
  }
  throw new Error(`Path does not have a parent: ${path}`);
};

export const isTextElement = (node) => {
  return !!node.text || (node.children?.length === 1 && !!node.children[0].text);
};

export const getNodeById = (editor: BaseEditor, id: string) => {
  // @ts-ignore id is optional, not worth the type hassle
  const [node] = Editor.nodes(editor, { at: [], match: (n) => n.id === id });
  return node ?? [undefined, undefined];
};

export const getNodeIndex = (editor: BaseEditor, id: string): number => {
  const [, path] = getNodeById(editor, id);
  return path[path.length - 1];
};

export const normalizeRange = (range) => {
  if (Range.isBackward(range)) {
    // invert range to forward direction
    return {
      anchor: range.focus,
      focus: range.anchor,
    };
  }

  return range;
};

export const wrapNodeRange = (editor: BaseEditor, element) => {
  const { selection } = editor;
  const range = normalizeRange(selection);
  Transforms.wrapNodes(editor, element, { at: range });
};

type MatchFunction = (n: TNode) => boolean;

export const getNodesInRange = (editor: BaseEditor, range: BaseRange, match: MatchFunction) => {
  const [start, end] = Range.edges(range);
  const nodesInRange = [] as NodeEntry[];

  for (const entry of Node.nodes(editor, { from: start.path, to: end.path })) {
    const [node, path] = entry;
    if (!match(node as TNode)) continue;
    if (Path.compare(path, start.path) >= 0 && Path.compare(path, end.path) <= 0) {
      nodesInRange.push(entry);
    }
  }

  return nodesInRange;
};

export const transformRange = (editor: PlateEditor, match: MatchFunction, transform: (n: NodeEntry) => void) => {
  const range = normalizeRange(editor.selection) as Range;
  if (!range) return true;
  if (Path.equals(range.anchor.path, range.focus.path)) {
    // single node
    const path = range.anchor.path;
    const node = getNearestNode(editor, match, path);
    if (!node) return true;
    transform(node);
  } else {
    // we have a range, apply to each node
    const nodes = getNodesInRange(editor as BaseEditor, range, match);
    console.log("get nodes in range", range, nodes);
    for (const [node, _path] of nodes) {
      // problem is the node paths change after each tab transform, meaning the next ones are no longer correct
      // instead, use the id to find the latest node position and apply using that each time
      // @ts-ignore TODO id does exist, i cbf typing it right now
      const id = node.id;
      if (id) {
        const [, path] = getNodeById(editor as BaseEditor, id);
        transform([node, path]);
        continue;
      }
      transform([node, _path]);
    }
  }
  // default to no action
  return true;
};

export type HotkeyOptions = {
  keys: string | string[];
  action: (
    editor: PlateEditor<Value>,
    plugin: PlatePlugin,
    node: NodeEntry<TNode>,
    event: KeyboardEvent,
  ) => boolean | void;
  query?: QueryNodeOptions;
};

export const handleHotkeys: DOMHandlers["onKeyDown"] = (editor, plugin) => (event) => {
  const { options } = plugin;
  // const { hotkey } = options;
  // if(!hotkey) return;

  const hotkeys = castArray(options.hotkeys).filter((i) => i.keys && i.action);

  for (const hotkey of hotkeys) {
    const { keys, action, query } = hotkey;
    if (isHotkey(keys, event)) {
      const node = getSelectedNode(editor as BaseEditor);

      if (!query || (node[0] && queryNode(node as NodeEntry<TNode>, query))) {
        console.log("hotkey pressed", plugin.type, hotkey);
        const res = action(editor, plugin, node, event);
        // need to signal if we handled the hotkey, default to true if the function doesn't return anything
        const handled = res ?? true;
        if (handled) {
          // only prevent the default if we handled it, otherwise we require the event to still progress
          event.preventDefault();
        }
        // use result, or default to true if undefined
        return handled;
      }
    }
  }
};

export type HotkeysPlugin = { hotkeys: HotkeyOptions[] };

export const withHotKeys = (hotkeys: HotkeyOptions[]) => ({
  handlers: {
    onKeyDown: handleHotkeys,
  },
  options: {
    hotkeys: hotkeys,
  },
});

export const setNodeType = (editor: BaseEditor, type: string) => {
  Transforms.setNodes<TNode>(editor, { type });
};

const isMac = () => {
  if ("userAgentData" in navigator) {
    // @ts-ignore
    const platform = (navigator.userAgentData as any).platform;
    return platform.toLowerCase().indexOf("mac") !== -1 || platform === "iPhone";
  }

  return false;
};

// constant, so pre-calculate
export const modKey = isMac() ? "⌘" : "ctrl";
export const altKey = isMac() ? "⌥" : "alt";

export const not = (cb) => (e) => !cb(e);

export const preFormat = (editor) => unwrapList(editor);

export const format = (editor, customFormatting: any) => {
  if (editor.selection) {
    const parentEntry = getParentNode(editor, editor.selection);
    if (!parentEntry) return;
    const [node] = parentEntry;
    if (isElement(node) && !isType(editor, node, ELEMENT_CODE_BLOCK) && !isType(editor, node, ELEMENT_CODE_LINE)) {
      customFormatting();
    }
  }
};

export const formatList = (editor, elementType: string) => {
  format(editor, () =>
    toggleList(editor, {
      type: elementType,
    }),
  );
};

// export const withUUIDs = (nodes) => {
//   return nodes.map((n) => {
//     if (n.type === ELEMENT_RULE && n.id === undefined) {
//       n.id = uuidv4();
//     }
//     if (n.children) {
//       n.children = withUUIDs(n.children);
//     }
//     return n;
//   });
// };

export const createParagraph = (text = "") => ({ type: ELEMENT_PARAGRAPH, children: [{ text }] });
export const createConclusion = (text = "") => ({ type: ELEMENT_CONCLUSION, children: [{ text }] });

export const getSelectedNodes = (editor, match?: MatchFunction) => {
  const selectedNodes = getNodeEntries(editor, { at: editor.selection, match: match ?? ((n) => n.type !== undefined) });
  return Array.from(selectedNodes).map((n) => n[0]);
};

export const nodesToText = (nodes: TNode | TNode[]) => {
  return Array.isArray(nodes) ? nodes.map((n) => Node.string(n).trim()) : [Node.string(nodes).trim()];
};

export const nodesToTextNodes = (nodes: TNode | TNode[]) => {
  return nodesToText(nodes).map((n) => createParagraph(n));
};

export const getSelectionAsTextNodes = (editor) => {
  return nodesToTextNodes(getSelectedNodes(editor));
};

/**
 * Get the previous sibling nodes before a path.
 * (For some reason plate doesn't include this)
 * @param ancestorEntry Ancestor of the sibling nodes
 * @param path Path of the reference node
 */
export const getPreviousSiblingNodes = <V extends Value>(
  ancestorEntry: EAncestorEntry<V>,
  path: Path,
): EElementOrText<V>[] => {
  const [ancestor, ancestorPath] = ancestorEntry;

  const leafIndex = path[ancestorPath.length];

  const siblings: EElementOrText<V>[] = [];
  const ancestorChildren = ancestor.children as EElementOrText<V>[];

  for (let i = 0; i < leafIndex; i++) {
    siblings.push(ancestorChildren[i]);
  }

  return siblings;
};

export const isFocusedWithin = (editor: BaseEditor, parent: Path) => {
  const [selected, path] = getSelectedNode(editor);
  if (!selected || !path) return false;
  // is focused if path is equal to parent or if path is a descendant of parent
  return Path.isAncestor(parent, path);
};

export const splitNodes = (editor: PlateEditor, path: number[]) => {
  const parent = getParentNode(editor, path);
  const node = getNode(editor, path);
  if (!parent || !node) throw new Error("Cannot split without a parent and node");
  const prev = getPreviousSiblingNodes(parent, path);
  const next = getNextSiblingNodes(parent, path);
  return { prev, next, node };
};

export const getNearestElement = <T extends TElement>(editor: BaseEditor | PlateEditor, type: string, path?: Path) => {
  if (path) {
    // check if path is a rule
    const node = getNode(editor as TNode, path);
    if (node?.type === type) return [node, path] as NodeEntry<T>;
  }
  // see if there is a rule above, ie we are in a ref or text
  const entry = Editor.above<T>(editor as BaseEditor, { at: path, match: (n) => (n as TNode).type === type });
  if (entry !== undefined) {
    return entry;
  }
  throw new Error(`Path does not have a parent ${type}: ${path}`);
};

export const createHR = () => ({ type: ELEMENT_HR, children: [{ text: "&nbsp;" }] });

export const insertHR = (editor: PlateEditor, path?: Path, select = true) => {
  insertNodes(editor, createHR(), path ? { at: path, select } : undefined);
};

export const isLastChild = (editor: PlateEditor, path: number[]) => {
  const parent = getNodeParent(editor, path);
  return parent.children.length - 1 === path[path.length - 1];
};

// a util from plate, will make multiline into a single line of text
export const stripNewLineAndTrim = (text: string) => {
  return text
    .split(/\r\n|\r|\n/)
    .map((line) => line.trim())
    .join("");
};
