import React from 'react';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Stack } from '@components';
import { useFullRelease } from '@common/hooks_useFullRelease';
import GoalMenu from '@pages/models/release/GoalMenu';
import { ParsedGraphContextProvider, useParsedGraph } from '@pages/models/release/GraphContext';
import { AttributeInfoFormValues } from './useAttributeForm';
import { FormikCheckbox, FormikSelect } from '@components/forms/FormikControls';

type SelectControlProps = {
  name: string;
  label: string;
};

const SelectRequestMapping: React.FC<SelectControlProps> = ({ name, label }) => {
  const release = useFullRelease();
  const mappings = ((release?.mapping ?? []))
    .filter(it => it.type === "request")
    .map(it => ({ key: it.name, value: it.id }));

  const hasData = mappings.length > 0;
  const placeholder = hasData ? label : "No mappings available";

  return (
    <FormikSelect name={name} label={placeholder} options={mappings} disabled={!hasData} />
  );
};

const SelectConnection: React.FC<SelectControlProps> = ({ name, label }) => {
  const release = useFullRelease();
  const connections = (release?.connections ?? [])
    .map(it => ({ key: it.name, value: it.id }));

  const hasData = connections.length > 0;
  const placeholder = hasData ? label : "No connections available";

  return (
    <FormikSelect name={name} label={placeholder} options={connections} disabled={!hasData} />
  );
};

interface SegmentValue {
  type: string;
  value: string;
  useDescriptions: boolean
}

type EnrichmentSegmentProps = {
  name: string;
  // value: SegmentValue;
  typeOptions: Array<{ key: string; value: string; }>
  hideUseDescription?: boolean;
  control: React.ComponentType<{ name: string; type: string; }>;
};

const EnrichmentSegment: React.FC<EnrichmentSegmentProps> = (p) => {
  const { name, typeOptions, hideUseDescription = false, control: Control } = p;
  const { values } = useFormikContext<AttributeInfoFormValues>();

  const type = get(values, `${name}.type`, typeOptions[0].value);

  return (
    <Stack width="100%" gridGap='0.5rem'>
      <FormikSelect name={`${name}.type`} label="Type" options={typeOptions} />
      <Control name={`${name}.value`} type={type} />
      {
        hideUseDescription ? null : (
          <FormikCheckbox name={`${name}.useDescriptions`} label="Use descriptions" />
        )
      }
    </Stack>
  );
};

const ConnectionControl = ({ name, type }: any) => {
  const { values, setFieldValue, errors, touched } = useFormikContext<AttributeInfoFormValues>();
  const graph = useParsedGraph();

  const value = get(values, name);
  const setValue = v => setFieldValue(name, v);
  const error = (touched[name] && errors[name]) || false;

  if (type === "attribute") {
    return (
      <Stack>
        <GoalMenu
          graph={graph}
          goalId={value}
          onGoalIdChange={setValue}
          allowEmpty
        />
        {
          Boolean(error) ? (
            <FormHelperText error style={{ margin: "0 1rem", fontStyle: "italic" }}>
              {error}
            </FormHelperText>
          ) : null
        }
      </Stack>
    );
  }

  if (type === "constant") {
    return <SelectConnection name={name} label="Value" />;
  }

  return null;
}

const MappingControl = ({ name, type }: any) => {
  const { values, setFieldValue } = useFormikContext<AttributeInfoFormValues>();
  const graph = useParsedGraph();

  const value = get(values, name);
  const setValue = v => setFieldValue(name, v);

  if (type === "rule" || type === "goal") {
    return (
      <GoalMenu
        graph={graph}
        goalId={value}
        onGoalIdChange={setValue}
        allowEmpty
      />
    );
  }

  if (type === "file") {
    return <SelectRequestMapping name={name} label="Value" />;
  }

  return null;
};

export const AttributeEnrichmentForm = () => {
  const { values } = useFormikContext<AttributeInfoFormValues>();
  const triggerEnrichments = get(values, "triggerEnrichments", false);
  return (
    <ParsedGraphContextProvider>
      <Stack gridGap={"1rem"}>
        <Typography>Enrichments are a way to trigger an external API when the attribute is determined to have a value.</Typography>
        <FormikCheckbox name="triggerEnrichments" label="Enable enrichments?" />
        {
          triggerEnrichments ? (
            <>
              <Stack gridGap="0.5rem">
                <Typography variant="h5">Connection</Typography>

                <EnrichmentSegment
                  hideUseDescription
                  name="enrichment.connection"
                  control={ConnectionControl}
                  typeOptions={[
                    { key: "Constant", value: "constant" },
                    { key: "Attribute", value: "attribute" },
                  ]}
                />
              </Stack>

              <Stack gridGap="0.5rem">
                <Typography variant="h5">Mapping</Typography>

                <Stack gridGap="0.5rem" paddingLeft="1rem">
                  <Typography variant="h6">Outbound</Typography>

                  <EnrichmentSegment
                    name="enrichment.mapping.outbound"
                    control={MappingControl}
                    typeOptions={[
                      { key: "Rule", value: "rule" },
                      { key: "Goal", value: "goal" },
                      { key: "File", value: "file" },
                      { key: "Default", value: "default" },
                    ]}
                  />
                </Stack>

                <Stack gridGap="0.5rem" paddingLeft="1rem">
                  <Typography variant="h6">Inbound</Typography>

                  <EnrichmentSegment
                    name="enrichment.mapping.inbound"
                    control={MappingControl}
                    typeOptions={[
                      { key: "Rule", value: "rule" },
                      { key: "Goal", value: "goal" },
                      { key: "File", value: "file" },
                    ]}
                  />
                </Stack>
              </Stack>

              <Stack gridGap="0.5rem">
                <Typography variant="h5">Transform</Typography>

                <Stack gridGap="0.5rem" paddingLeft="1rem">
                  <Typography variant="h6">Outbound</Typography>

                  <EnrichmentSegment
                    name="enrichment.transform.outbound"
                    control={MappingControl}
                    typeOptions={[
                      { key: "Rule", value: "rule" },
                      { key: "Goal", value: "goal" },
                      { key: "File", value: "file" },
                    ]}
                  />
                </Stack>

                <Stack gridGap="0.5rem" paddingLeft="1rem">
                  <Typography variant="h6">Inbound</Typography>

                  <EnrichmentSegment
                    name="enrichment.transform.inbound"
                    control={MappingControl}
                    typeOptions={[
                      { key: "Rule", value: "rule" },
                      { key: "Goal", value: "goal" },
                      { key: "File", value: "file" },
                    ]}
                  />
                </Stack>
              </Stack>
            </>
          ) : null
        }
      </Stack>
    </ParsedGraphContextProvider>
  );
};
