import { all, takeLatest, select, put } from 'redux-saga/effects';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { crudGetList } from '@imminently/imminently_platform';


export const ACTION = 'projects/loadMoreProjects';
export type Action = {
  type: typeof ACTION;
  init?: true;
}
export const ActionCreator = (init?: Action['init']): Action => ({ type: ACTION, init });


// ===================================================================================

interface IParams {
  pagination: { page: number; perPage: number; }
  sort: { field: string; order: 'asc' | 'desc' }
}

interface ModelsResource {
  default: {
    list: {
      total: number;
      params: IParams;
    }
  }
}

interface IStore {
  scope: { workspace: string },
  resources: {
    models?: Record<string, ModelsResource>
  }
}

const sortByCreatedDesc: IParams['sort'] = {
  field: 'created',
  order: 'desc',
};

const defaultPagination: IParams['pagination'] = {
  page: 1,
  perPage: 10,
};

const getCrudGetListA = (
  workspace: any,
  pagination: Partial<IParams['pagination']> = defaultPagination,
  sort = sortByCreatedDesc,
) => crudGetList(
  'models',
  workspace,
  {
    filter: { workspace },
    pagination: {
      ...defaultPagination,
      ...pagination,
    },
    sort,
  },
);

// ===================================================================================


const extractWorkspaceObject: (s: IStore) => null | ModelsResource = s => {
  const { workspace } = s.scope;

  return s.resources.models?.[workspace] || null;
};


export const hasMoreProjects: (s: IStore) => boolean = s => {
  const maybeWorkspaceObject = extractWorkspaceObject(s);
  if (maybeWorkspaceObject === null) return false;

  const {
    default: {
      list: {
        total,
        params: {
          pagination: {
            page,
            perPage,
          },
        },
      },
    },
  } = maybeWorkspaceObject;

  return total > page * perPage;
};


// ===================================================================================

export const PER_PAGE = 10;

export function* saga(): Gen {
  yield all([
    takeLatest(ACTION, function* onRequestToLoadMoreprojects(a: Action) {
      const store: IStore = yield select();
      const { workspace } = store.scope;

      if (a.init) {
        yield put(getCrudGetListA(workspace));

        return;
      }

      const maybeWorkspaceObject = extractWorkspaceObject(store);
      if (maybeWorkspaceObject === null) return;
      if (hasMoreProjects(store) === false) return;

      const {
        default: {
          list: {
            params: {
              pagination: {
                perPage,
              },
            },
          },
        },
      } = maybeWorkspaceObject;


      yield put(getCrudGetListA(workspace, { perPage: perPage + PER_PAGE }));
    }),
  ]);
}
