import { Typography } from "@material-ui/core";
import { Suspense, lazy } from "react";
import PeopleIcon from '@material-ui/icons/People';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
const Users = lazy(() => import("../pages/admin/Users"));
const Tenancies = lazy(() => import("../pages/admin/Tenancies"));
const Keys = lazy(() => import("../pages/admin/Keys"));
const HelpManagement = lazy(() => import("../pages/admin/HelpManagement"));
import { ADMIN_ROLES, SUPER_ADMIN } from "auth/permissions";

const UsersGroup = {
  name: "Users",
  icon: <PeopleIcon />,
  open: true
}
const ManagementGroup = {
  name: "Management",
  icon: <VpnKeyIcon />,
  open: true
}

const subroute = ({ name, icon, role, group, comp, path, skeleton, ...props }: any) => ({
  path: path,
  component: () => {
    console.log('comp', comp)
    return <Suspense fallback={skeleton} >{comp}</Suspense>;
  },
  exact: true,
  role: role,
  group,
  sidebar: {
    name,
    icon,
  },
});

export const admin = [
  {
    path: "/admin",
    role: ADMIN_ROLES,
    layout: "decisively",
    children: [
      subroute({
        path: '/admin',
        role: ADMIN_ROLES,
        name: 'Users',
        group: UsersGroup,
        comp: <Users />,
        skeleton: undefined
      }),
      subroute({
        path: '/admin/tenancies',
        name: 'Tenancies',
        role: SUPER_ADMIN,
        group: ManagementGroup,
        comp: <Tenancies />,
        skeleton: undefined
      }),
      subroute({
        path: '/admin/keys',
        name: 'Keys',
        role: ADMIN_ROLES,
        group: ManagementGroup,
        comp: <Keys />,
        skeleton: undefined
      }),
      subroute({
        path: '/admin/help',
        name: 'Help',
        role: SUPER_ADMIN,
        group: ManagementGroup,
        comp: <HelpManagement />,
        skeleton: undefined
      })
    ]
  }
];