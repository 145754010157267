
import { castArray } from 'lodash';
import { all, takeEvery, delay, race, call, put, take } from 'redux-saga/effects';


/** milliseconds */
export const DEFAULT_TIMEOUT = 10000;
export const ACTION = 'combinator/FIRE';
/**
  @typedef {{
    type: typeof ACTION;
    payload: {
      waitFor: {
        type: string;
        validate: ( a: import('redux').Action ) => boolean
      };
      timeout?: number;
      toDispatch: import('redux').Action | import('redux').Action[];
      // toCall?: () => unknown;
      bootstrap: import('redux').Action;
    }
  }} IAction
*/
/** @type { ( payload: IAction[ 'payload' ] ) => IAction } */
export const ActionCreator = payload => ({ type: ACTION, payload });


// ===================================================================================


/** @type { (a: ReturnType< typeof ActionCreator >) => Generator< any, any, any > } */
function* onAction(a) {
  const {
    payload: {
      toDispatch,
      timeout,
      waitFor,
      bootstrap,
      // toCall,
    },
  } = a;


  const [raceResult] = yield all([
    race({
      timeout: delay(timeout || DEFAULT_TIMEOUT),

      takeResult: call(
        function* onTakeResult() {
          while (true) {
            /** @type { import( 'redux' ).Action } */
            const action = yield take(waitFor.type);

            if (waitFor.validate(action)) {
              return;
            }
          }
        },
      ),
    }),

    put(bootstrap),
  ]);

  if (raceResult.timeout) {
    return;
  }

  const actions = castArray(toDispatch);
  yield all(actions.map(a => put(a)));
}


// ===================================================================================


// export const SIDEEFFECT_ACTION = 'combinator/FIRE-sideffect';
// /**
//   @typedef {{
//     type: typeof SIDEEFFECT_ACTION;
//     payload: {
//       waitFor: {
//         type: string;
//         validate: ( a: import('redux').Action ) => boolean
//       };
//       timeout?: number;
//       sideEffect: () => unknown
//     }
//   }} ISideffectAction
// */
// /** @type { ( payload: ISideffectAction[ 'payload' ] ) => ISideffectAction } */
// export const SideffectActionCreator = ( payload ) => ({ type: SIDEEFFECT_ACTION, payload });

// // ===================================================================================

// /** @type { (a: ReturnType< typeof SideffectActionCreator >) => Generator< any, any, any > } */
// function* onSideeffectAction( a ) {
//   const {
//     payload: {
//       timeout,
//       waitFor,
//       sideEffect
//     }
//   } = a;

//   const [ raceResult ] = yield race({
//     timeout: delay( timeout || DEFAULT_TIMEOUT ),

//     takeResult: call(
//       function* () {
//         while( true ) {
//           /** @type { import( 'redux' ).Action } */
//           const action = yield take( waitFor.type );

//           if( waitFor.validate( action ) ) {
//             return;
//           }
//         }
//       }
//     )
//   });

//   if( raceResult.timeout ) {
//     return;
//   }

//   sideEffect();
// }


export function* saga() {
  yield all([
    takeEvery(ACTION, onAction),
    // takeEvery( SIDEEFFECT_ACTION, onSideeffectAction )
  ]);
}
