import produce from 'immer';
import { ReleaseTestCasesNS } from '@packages/commons';
import type { TestCasesRootCtxState } from '../../types';
import { forEachSortItem } from '../forEachSortItem';


export type SetExpandedOnAllInTestCasesSortTreeArg = {
  dataTree: TestCasesRootCtxState[ 'value' ][ 'tree' ];
  sortTree: TestCasesRootCtxState[ 'value' ][ 'testCasesTreeCompState' ][ 'tree' ];
  expandedValue: boolean;
};
export type SetExpandedOnAllInTestCasesSortTreeRtrn = SetExpandedOnAllInTestCasesSortTreeArg[ 'sortTree' ];


const deriveIdToDataTreeMapping = (
  item: ReleaseTestCasesNS.TestCaseTItem,
  acc: Record< string, ReleaseTestCasesNS.TestCaseTItem > = {}
): typeof acc => {
  acc[ item.id ] = item;

  if(item.type === 'payload') return acc;

  return item.children.reduce(
    (innerAcc, child) => deriveIdToDataTreeMapping(child, innerAcc),
    acc
  );
}

export const setExpandedOnAllInTestCasesSortTree = ( arg: SetExpandedOnAllInTestCasesSortTreeArg ): SetExpandedOnAllInTestCasesSortTreeRtrn => {
  const { dataTree, expandedValue, sortTree, } = arg;

  const idToDataTreeItemMapping = deriveIdToDataTreeMapping( dataTree );

  return produce( sortTree, draft => {
    forEachSortItem({
      item: draft,
      cb: item => {
        const dataItem = idToDataTreeItemMapping[ item.__testCaseTreeItemId ];
        if(dataItem === undefined || dataItem.type !== 'folder' ) return;

        item.expanded = expandedValue;
      }
    });
  } );
}
