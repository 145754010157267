/* eslint-disable camelcase */
import {
  crudGetOne,
  crudDelete,
  CRUD_GET_ONE_SUCCESS,
  CRUD_DELETE_SUCCESS,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from '@imminently/imminently_platform';
import { all, select, put, takeLatest } from 'redux-saga/effects';
import { graphlib } from 'dagre';
import * as reducer from './reducer';
import type { Report } from '../constants';
import { setDecReport } from '../../../../../redux/scope';
import * as combinatorV2 from '../../../../../redux/combinatorV2.saga';
import * as contextNS from '../context/immutable';


type Scope = import('../../../../../redux/scope').Scope

// ===================================================================================

function* onRequestToSetReportId(a: reducer.Actions[ 'requestToSetReportId' ]): Gen {
  const { reportId, setCtxValue } = a.payload;

  if(reportId === '') {
    yield put(setDecReport(''));
    setCtxValue(() => contextNS.defaultState.report);

    return;
  }

  const state = yield select();
  const { scope: { release: releaseId }, resources } = state;

  if(!releaseId) return;

  const currentRelease = resources?.releases?.data?.[ releaseId ];
  if(!currentRelease) return;

  const { rule_graph } = currentRelease;
  if(!rule_graph) return;

  const g: graphlib.Graph< any > = graphlib.json.read(rule_graph);
  const reports = (currentRelease.report || []) as Report[];

  const currentReport = reports.find(it => it.id === reportId);
  if(!currentReport) return;


  const { goalId, nodes: nodesInReport = {} } = currentReport;

  const processedNodes: Record< string, true > = {};
  type TreeData = contextNS.State[ 'report' ];

  const treeData = (function gather(id: string, path: string[]): TreeData[ 'data' ] {
    const acc: TreeData[ 'data' ] = [];

    const nodeInGraph = g.node(id);
    if(!nodeInGraph || processedNodes[ id ]) return acc;

    processedNodes[ id ] = true;

    const nodeInReport = nodesInReport[ id ];
    if(!nodeInReport) return acc;

    const successors = g.successors(id);


    type T = { items: typeof acc; indx: number };
    const { items: children }: T = (
      successors && successors.length
        ? successors.reduce< T >(
          ({ indx, items }, childId) => {
            const l = gather(childId, path.concat('children', String(indx)));

            return {
              items: items.concat(l),
              indx: l.length === 0 ? indx : indx + 1,
            };
          },
          { items: [], indx: 0 },
        )
        : { items: [], indx: 0 }
    );

    const node: TreeData[ 'data' ][ 0 ] = {
      children: children.length > 0 ? children : undefined,
      expanded: children.length > 0,
      _contents: {
        nodeInReport,
        nodeInGraph: {
          id,
          description: nodeInGraph.description,
          type: nodeInGraph.type,
          reference: nodeInGraph.reference,
        },
        path,
        parentPath: (() => {
          const elBeforeLastInPath = path[ path.length - 2 ];

          return elBeforeLastInPath === undefined
            ? []
            : path.slice(0, -2);
        })(),
      },
    };

    return [node];
  }(goalId, ['0']));

  setCtxValue(() => ({ data: treeData }), true);

  yield put(setDecReport(currentReport.id));
}

// ===================================================================================

export function* saga(): Gen {
  yield all([
    takeLatest(reducer.aTypes.requestToSetReportId, onRequestToSetReportId),
    takeLatest(reducer.aTypes.requestToDeleteReport, function* onRequestToDeleteReport(
      { payload: { releaseId, reportId, closeDeleteModal } }: reducer.Actions[ 'requestToDeleteReport' ],
    ) {
      const state: any = yield select();
      const { decReport: reportIdInState } = state.scope as Scope;

      if(reportId === reportIdInState) yield put(setDecReport(''));


      const dispatchOnSuccess = combinatorV2.ActionCreator({
        bootstrap: crudGetOne('releases', releaseId),
        success: {
          type: CRUD_GET_ONE_SUCCESS,
          validate: (v: any) => v.meta.resource === 'releases',
          call: closeDeleteModal,
        },
      });

      yield put(
        combinatorV2.ActionCreator({
          bootstrap: crudDelete('releases', releaseId, {
            subResource: 'report',
            id: reportId,
          }),
          success: {
            type: CRUD_DELETE_SUCCESS,
            validate: (v: any) => v.meta.resource === 'releases',
            dispatch: dispatchOnSuccess,
          },
        }),
      );
    }),
  ]);
}
