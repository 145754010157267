import { lazy } from "react";

const Projects = lazy(() => import("../pages/projects/Projects"));

export const projects = [
  {
    path: "/projects",
    layout: "decisively",
    role: "user",
    exact: true,
    component: Projects,
  },
];
