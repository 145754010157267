import React from "react";
import styled from "styled-components";
import debounce from "lodash/debounce";
import { JSpreadSheetWrapCore, type JSpreadSheetWrapCoreProps } from "./Comp";
import { useJSpreadSheetWrapCtx, type JSpreadSheetWrapCtxValue } from "./Ctx";


const SpreadsheetResizeWrapRelative = styled.div`
  position: relative;
  overflow: auto;
  flex-grow: 1;
  width: 100%;
`;
const SpreadsheetResizeWrapAbsolute = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;

  >div {
    height: 100%;
  }
`;

export const clss = {
  '>absoluteWrap': {
    _: 'absoluteWrap_G0iwS',

    wrapCore: 'jspreadsheetWrapCore_5BbCz',
  }
}


export type JSpreadSheetWrapWithResizeObserverProps = {
  getSpreadsheetOptions: JSpreadSheetWrapCoreProps[ "getSpreadsheetOptions" ];
  className?: string;
  /**
   * rect holds the sizes of absolutely positioned wrapper.\
   * **IMPORTANT**: it will be debounced for 60 seconds
   */
  syncSpreadsheetSizes: ( rect: DOMRectReadOnly, jspreadsheetCtxValueRef: JSpreadSheetWrapCtxValue[ 'valueRef' ] ) => unknown;
}
/**
 * Jspreadsheet library has a strict limitation in a way that it\
 * has to know the exact absolute sizes. TO do that we apply the\
 * following logic:
 * - create one wrapper, that is relative and should ideally be\
 * stretched to fit appropriate place with flex-grow or smth. It \
 * is positioned relatively
 * - its direct child is positioned absolutely, with width and \
 * height equal to parent's. And because of that we are able to add\
 * ResizeObserver to it and run some code on size changes. Inside\
 * that handler we will update sizing on jspreadsheet instance.
 */
export const JSpreadSheetWrapWithResizeObserver: React.FC< JSpreadSheetWrapWithResizeObserverProps > = React.memo((p) => {
  const { getSpreadsheetOptions, className, syncSpreadsheetSizes } = p;
  const { valueRef: JSpreadSheetValueRef } = useJSpreadSheetWrapCtx();

  // ===================================================================================

  const syncSpreadsheetSizesHandlerDebounced = React.useMemo(
    () => debounce< typeof syncSpreadsheetSizes >( syncSpreadsheetSizes, 60 ),
    [ syncSpreadsheetSizes ],
  );
  const spreadsheetResizeWrapObserver = React.useMemo(
    () =>
      new ResizeObserver((entries) => {
        const [first] = entries;
        if (first === undefined) return;

        syncSpreadsheetSizesHandlerDebounced(first.contentRect, JSpreadSheetValueRef);
      }),
    [ JSpreadSheetValueRef, syncSpreadsheetSizesHandlerDebounced ],
  );

  const spreadsheetResizeWrapRef = React.useRef(document.createElement("div"));
  const spreadsheetResizeWrapCbRef = React.useCallback<React.RefCallback<HTMLDivElement>>(
    (node) => {
      if (node === null) return;

      spreadsheetResizeWrapRef.current = node;
      spreadsheetResizeWrapObserver.observe(spreadsheetResizeWrapRef.current);
    },
    [spreadsheetResizeWrapObserver],
  );
  React.useEffect(
    () => () => {
      spreadsheetResizeWrapObserver.disconnect();
    },
    [spreadsheetResizeWrapObserver],
  );

  // ===================================================================================

  return (
    <SpreadsheetResizeWrapRelative className={ className }>
      <SpreadsheetResizeWrapAbsolute ref={ spreadsheetResizeWrapCbRef } className={ clss[ ">absoluteWrap" ]._ }>
        <JSpreadSheetWrapCore
          getSpreadsheetOptions={ getSpreadsheetOptions }
          className={ clss[ ">absoluteWrap" ].wrapCore }
        />
      </SpreadsheetResizeWrapAbsolute>
    </SpreadsheetResizeWrapRelative>
  );
});
JSpreadSheetWrapWithResizeObserver.displayName = "components/JSpreadSheetWrapWithResizeObserver";

export type GeneralWidthSyncArg = {
  width?: number;
  /** @default 55 */
  reservedWidthForScrollbar?: number;
  ref: JSpreadSheetWrapCtxValue[ 'valueRef' ];
  /** @default 0 */
  minColWidth?: number
};
export const generalWidthSync = ( arg: GeneralWidthSyncArg ): void => {
  const { ref, width, reservedWidthForScrollbar = 55, minColWidth = 0 } = arg;

  const [ worksheet ] = ref.current.worksheets;
  if ( !worksheet ) return;

  const effectiveWidth = width === undefined ? ref.current.holderRef.clientWidth : width;
  const cols = worksheet.getConfig().columns || [];
  const columnsLength = cols.length || 2;
  const colWidth = ( effectiveWidth - reservedWidthForScrollbar ) / columnsLength;

  Array(columnsLength).fill(0).forEach((_, i) => {
    worksheet.setWidth( i, Math.max( colWidth, minColWidth ) );
  });
}
