import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { type Actions, aCreators, aTypes, getState, normalizeThemeName } from "./reducer";

const LOCAL_STORAGE_KEY = "interview_theme_key";

function* onRequestToSetTheme(a: Actions["requestToSetTheme"]) {
  const rootState = yield select();
  const { theme } = getState(rootState);

  if (theme === a.payload) return;

  yield put(aCreators.setTheme(a.payload));
  localStorage.setItem(LOCAL_STORAGE_KEY, a.payload);
}

function* initFromInterviewThemeInLS() {
  const maybeName = localStorage.getItem(LOCAL_STORAGE_KEY);
  yield put(aCreators.requestToSetTheme(normalizeThemeName(maybeName)));
}

export function* saga() {
  yield all([takeLatest(aTypes.requestToSetTheme, onRequestToSetTheme), call(initFromInterviewThemeInLS)]);
}
