import React, { forwardRef } from "react";
import BaseTabs, { TabsProps as BaseTabsProps } from "@material-ui/core/Tabs";
import Tab, { TabProps } from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip, TooltipProps } from "@components/toolTip";

const StyledTabs = withStyles(theme => ({
  root: {
    "& .MuiTabs-indicator": { display: "none" },
    borderRadius: "0.5rem",
    border: `1px solid ${theme.palette.secondary.main}`,
    minHeight: "initial",

    "& .MuiTab-root": {
      maxWidth: "initial",
    },

    "& .MuiTab-wrapper": {
      display: "inline",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
}))(BaseTabs);


const StyledTab = withStyles(theme => ({
  root: {
    flexGrow: 1,
    minWidth: "initial",
    fontWeight: 600,
    fontSize: "0.75rem",
    lineHeight: 2,
    padding: "0.25rem 1rem",
    minHeight: "initial",
    color: "black",
    "&:not(:last-child)": {
      borderRight: `1px solid ${theme.palette.secondary.main}`,
    },
  },
  selected: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
}))(forwardRef<any, TabProps>((props, ref) => <Tab ref={ref} {...props} />));

export interface TabItem {
  label: string;
  value: string;
  icon?: any;
  disabled?: boolean;
  tooltip?: true | TooltipProps,
}

export type TabsProps = Omit<BaseTabsProps, "onChange"> & {
  tabs: Array<TabItem>;
  current: string;
  onChange: (_: any, v: string) => unknown;
  className?: string;
}

export const Tabs = React.memo((props: TabsProps) => {
  const { current, tabs, onChange, className, ...otherProps } = props;
  return (
    <StyledTabs value={current} onChange={onChange} className={className} {...otherProps}>
      {tabs.map((tab) => {
        const { value, label, tooltip, ...otherTabProps } = tab;
        const content = <StyledTab key={value} label={label} value={value} {...otherTabProps} />;

        if (tooltip) {
          const tooltipProps = tooltip === true ? {} : tooltip;
          return (
            // @ts-ignore - MUI issue - the value prop has to exist for tabs to not cause an error. But TS complains about type not being valid. Sigh
            <Tooltip key={value} value={value} title={label} placement={"bottom"} {...tooltipProps}>
              {content}
            </Tooltip>
          );
        }

        return content;
      })}
    </StyledTabs>
  );
});
export default Tabs;
Tabs.displayName = "Components/Tabs";

