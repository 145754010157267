/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React, { Fragment, useState, useEffect } from "react";
import get from "lodash/get";
import {
  IconButton,
  Popover,
  Table, TableHead, TableRow, TableCell, TableBody,
  Typography,
  Grid,
  useTheme,
  Dialog,
} from "@material-ui/core";
import Moment from "moment";
import HelpOutline from "@material-ui/icons/HelpOutline";
import styled from "styled-components";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, CategoryScale } from 'chart.js';
ChartJS.register(LineElement, PointElement, LinearScale, Title, CategoryScale)
import { extendMoment } from "moment-range";
import EditIcon from "@material-ui/icons/Edit";
import FlipCameraAndroidIcon from '@material-ui/icons/FlipCameraAndroid';
import {
  Sparkline,
  LineSeries,
  // BandLine,
  PatternLines,
  PointSeries,
  WithTooltip,
  VerticalReferenceLine,
} from "@data-ui/sparkline";
import { color, allColors } from "@data-ui/theme";
import * as TemporalEditor from "./TemporalEditor";
import { normalizeRanges } from "./normalizeRanges";
import { NOT_VALID } from "./TemporalEditor/temporalOps";
import { START_TIME, END_TIME } from "./TempTimeline/redux";
import TemporalValueModal from "./TemporalValueModal";
import { formatValue, TempTimeline } from "./TempTimeline";
import NodeValue from "@components/GraphVisualisation/components/AttributeInfo/NodeValue";
import { useSortableTreeRefCtx } from "@components/DecisionReport/SortableTreeRefCtx";
import { Label } from '@components';
import { autoFormatIgnoreTimezone, formatIgnoreTimezone } from "@common";

const StyledTempTimeline = styled(TempTimeline)`
  height: 100%;
  min-width: 10rem;
  max-width: 100%;
  height: 2rem;
`;

const moment = extendMoment(Moment);
const normalizeTemporalIncrementSegment = r => {
  const { increment } = r.v;
  const diffType = increment.match("year")
    ? "years"
    : increment.match("month")
      ? "months"
      : "days";

  const start = r.v.offset || 0;
  const end = start + moment(r.r.end).diff(moment(r.r.start), diffType);

  return [start, end];
};

const TemporalText = ({ value }) => {

  return value.temporal.ranges.map((r, rIndex) => {
    let prop = { node: { input: r.v }, showType: false }
    if (r.r.start === START_TIME && r.r.end === END_TIME) return <Fragment key={rIndex}>Always <NodeValue {...prop} /></Fragment>;
    else if (rIndex === 0) return <Fragment key={rIndex}><NodeValue {...prop} />| </Fragment>
    else return <Fragment key={rIndex}>{autoFormatIgnoreTimezone(r.r.start)}: <NodeValue {...prop} />{rIndex === value.temporal.ranges.length - 1 ? '' : '| '}</Fragment>
  })
}
const StyledLine = styled(Line)`
  height: 100px;
`;
// const PointerSpan = styled.span`
//   cursor: pointer;
// `;
const ChartWrapper = styled.div`
  height: 220px;
`;
ChartWrapper.displayName = "TemporalValueRender/ChartWrapper";

const PopoverContent = styled.div`
  max-height: 500px;
  width: 800px;
  padding: 10px;
`;

const possibleFormats = [
  "YYYY-MM-DD HH:mm:ss",
  "YYYY-MM-DD",
  "YYYY-MM-DDTHH:mm:ss",
  "YYYY-MM-DDTHH:mm:ss.SSSZ"
];
const TemporalSparkLine = ({ ranges }) => {
  let data = [];
  let labels = [];
  let values = [];
  let isBoolean = false;
  const theme = useTheme();

  const isIncrement = (ranges || []).some(it => it.v && it.v.increment !== undefined);

  // eslint-disable-next-line complexity
  (ranges || []).forEach((r, index) => {
    // console.log('range build', r)
    if (r.v && r.v.increment) {
      const [start, end] = normalizeTemporalIncrementSegment(r);

      data = data.concat([start, end]);
      values = values.concat([start, end]);
      labels = labels.concat([moment(r.r.start), moment(r.r.end).subtract(1, "day")]);

      // Special case - for optimisation purposes this range is just start to end, but should be split up using this increment
      // Add a whole heap of new ranges
      // const inc_range = moment.range(moment(r.r.start), moment(r.r.end));
      // let inc_start = moment(r.r.start);
      // let i = 0;
      // for (const inc of inc_range.by(r.v.increment)) {
      //   console.log('inc', inc, inc.format('YYYY-MM-DD'));
      //   labels = labels.concat([inc_start, inc]);
      //   inc_start = inc;
      //   data = data.concat([i, i]);
      //   values = values.concat([i, i]);
      //   i++;
      // }
      // console.log('inc', inc_range, inc_range.by);
    } else {
      // TODO why push twice? This needs an explanation
      // is this so we can clearly see the value at the start and end?
      values.push(r.v);
      values.push(r.v);
      // if (ranges.length === 1) labels = labels.concat(["Beginning of Time", "End of Time"]);
      // if (index === 0) labels = labels.concat(["Beginning of Time", moment(r.r.end).clone().add(-1, "day")]);
      // else if (index === ranges.length - 1) labels = labels.concat([moment(r.r.start), "End of Time"]);
      // else labels = labels.concat([moment(r.r.start), moment(r.r.end).clone().add(-1, "day")]);

      // 86b0xucne - set raw values so we can utilise our formatValue function as its more accurate
      labels = labels.concat([r.r.start, r.r.end]);

      if (r.v === true || r.v === false) isBoolean = true;
      if (r.v === true) data = data.concat([1, 1]);
      else if (r.v === false || typeof r.v === "undefined" || r.v === "Not Valid" || r.v === null) data = data.concat([0, 0]);
      else if (moment(r.v).isValid()) {
        data = data.concat([moment(r.v).valueOf(), moment(r.v).valueOf()]);
      } else if (isNaN(parseInt(r.v))) {
        data = data.concat([1, 1]);
      } else data = data.concat([1, 1]); // Force strings to be 1 (eg: something exists)
    }
  });

  const renderTooltip = ({ datum }) => {
    let value = values[datum.i];

    if (typeof value === "undefined") value = "Unknown";
    else if (value === null || value === NOT_VALID) value = "Uncertain";
    else if (moment(value, possibleFormats, true).isValid()) value = moment(value).format("YYYY-MM-DD");
    else if (value && (typeof value === "number" || value.includes && !value.includes(':') && parseFloat(value))) value = parseFloat(value).toFixed(2);
    else if (value.toString) value = value.toString();

    let label;
    if (get(labels, datum.i)) {
      label = formatValue(get(labels, datum.i), "datetime");
    }

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="caption" style={{ fontWeight: "bold" }}>{value}</Typography>
        <Typography variant="caption">{label}</Typography>
      </div>
    );
  };

  const renderLabel = (value) => isBoolean ? (value === 0 ? "False" : "True") : value;

  return (
    <WithTooltip renderTooltip={renderTooltip}>
      {({ onMouseMove, onMouseLeave, tooltipData }) => (
        <Sparkline
          ariaLabel='Temporal Value'
          width={260}
          height={70}
          data={data}
          margin={{ top: 30, bottom: 5, right: 60 }}
          onMouseLeave={onMouseLeave}
          onMouseMove={onMouseMove}
          onClick={e => e.preventDefault()}
        >
          <PatternLines
            id='area_pattern'
            height={4}
            width={4}
            stroke={allColors.indigo[4]}
            strokeWidth={1}
            orientation={["diagonal"]}
          />
          <LineSeries showArea stroke={allColors.indigo[5]} fill='url(#area_pattern)' curve='linear' />
          <PointSeries
            points={["all"]}
            stroke={(_, i) => {
              const v = values[i];

              return v === null
                ? theme.palette.secondary.hover
                : v === "Not Valid"
                  ? theme.palette.secondary.dark
                  : allColors.indigo[4];
            }}
            fill={(_, i) => {
              const v = values[i];
              // console.log(v);

              return v === null
                ? theme.palette.secondary.hover
                : v === "Not Valid"
                  ? theme.palette.secondary.dark
                  : "#fff";
            }}
            size={3}
          />
          <PointSeries
            points={[]}
            fill={allColors.indigo[5]}
            renderLabel={renderLabel}
            labelPosition='top'
          />
          {tooltipData && [
            <VerticalReferenceLine
              key='ref-line'
              strokeWidth={1}
              reference={tooltipData.index}
              strokeDasharray='4 4'
            />,
            <PointSeries
              key='ref-point'
              points={[tooltipData.index]}
              fill={color.categories[1]}
            />,
          ]}
        </Sparkline>
      )}
    </WithTooltip>
  );
};


const TemporalGraph = ({ ranges }) => {
  // const isBoolean = false;
  const isIncrement = ranges.some(it => it.v && it.v.increment !== undefined);

  const cleanValue = v => {
    return (v === null || typeof v === "undefined") ? 0 : v;
  };

  const options = {
    maintainAspectRatio: false,
    scales: {},
    legend: {
      display: false,
    },
  };

  const labels = ranges
    .map(t => {
      if (t.r.start === "-271821-04-20T00:00:00.000Z") return "Beginning of Time";

      // else if (t.r.end === '271821-04-20T00:00:00.000Z') return 'End of Time';
      return moment(t.r.start).format("D/M/YYYY");
    })
    // .concat(moment(ranges[ ranges.length - 1 ].r.start).add(1, 'seconds').toISOString())
    .concat("End of Time");


  const _data = (() => {
    if (isIncrement) {
      const typedRanges = (
        /** @type { import('../../decisions/DecisionDashboard/Reports/Details/UnifiedTimeline/types').Range[] } */(
          ranges
        )
      );
      /** @typedef { any[] } ImplodedRangeEntry */


      const implodedRange = typedRanges
        .reduce(
          (a, r) => {
            if (r.r.start === START_TIME || r.r.start === END_TIME) {
              return a.concat(null);
            }

            if (r.v.increment !== undefined) {
              const [start, end] = normalizeTemporalIncrementSegment(r);

              return a.concat(
                start,
                end,
              );
            }

            return a.concat(
              cleanValue(r.v),
              cleanValue(r.v),
            );
          },
          /** @type { ImplodedRangeEntry[] } */([]),
        );

      implodedRange[0] = null;
      implodedRange[implodedRange.length - 1] = null;

      return implodedRange;
    }

    let last;
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const _data = [];
    ranges.forEach((t, index) => {
      last = cleanValue(t.v);
      _data.push(last);
      if (index === ranges.length - 1) _data.push(0);
    });

    return _data;
  })();


  const data = {
    labels,
    datasets: [{
      label: "Value",
      data: _data,
      stepped: !isIncrement,
      borderColor: "blue",
    }],
  };

  options.scales.y = { ticks: { maxTicksLimit: 6 } };
  // options.scales.yAxes = [{
  //   ticks: {
  //     maxTicksLimit: 6,
  //   },
  // }];

  /* if (c.type === 'currency') {
    options.scales.yAxes[0].ticks.callback = value => {
      return '$' + value;
    }
  } */

  // if (isBoolean) {
  //   options.stepped = true;
  //   options.scales.y = {
  //     ticks: {
  //       fontStyle: 'bold',
  //       callback: value => {
  //         if (value === 0) return 'False';
  //         if (value === 1) return 'True';

  //         return '';
  //       },
  //     },
  //     gridLines: {
  //       display: false,
  //     },
  //   };
  // } else {

  // }


  return (
    <StyledLine data={data} options={options} />
  );
};

/**
  @type {
    React.FC<{
      ranges: Array<
        import( "../../../decisions/DecisionDashboard/Reports/Details/UnifiedTimeline/types" ).Range
      >;
      inline?: boolean;
      sparkline?: boolean;
      edit?: boolean;
      temporalEditorProps?: TemporalEditor.IOwnProps;
    }>
  }
 */
const TemporalValueRender = (p) => {
  const { node, ranges: rangesRaw, inline = false, onPopUp, onClick, forceType, value, sparkline = true, edit = false, temporalEditorProps } = p;

  const validTypes = ['bar', 'chart', 'text'];
  const [type, setType] = useState(forceType || localStorage.getItem('decisively.temporalViewPref') || 'bar');

  useEffect(() => {
    const handleStorageChange = () => {
      let newType = localStorage.getItem('decisively.temporalViewPref');
      if (newType && validTypes.indexOf(newType) > -1) setType(newType);
    }
    window.addEventListener('storage', handleStorageChange);
    return (() => window.removeEventListener('storage', handleStorageChange))
  });

  const ranges = (() => {
    if (value && value.temporal) return normalizeRanges(value.temporal.ranges);
    if (!rangesRaw) return;
    return normalizeRanges(rangesRaw)
  })();

  const [timelineOpen, setTimelineOpen] = React.useState(false);
  const handleClick = event => {
    event.preventDefault();
    setTimelineOpen(true);
  };
  const sortableTreeRefCtxValue = useSortableTreeRefCtx();
  const toggleView = (event) => {
    event.preventDefault();
    let newType;
    if (type === 'bar') newType = 'chart';
    else if (type === 'chart') newType = 'text';
    else newType = "bar";
    setType(newType);

    if (sortableTreeRefCtxValue.ref.current !== null) {
      const tree = sortableTreeRefCtxValue.ref.current;

      if (get(tree, ['wrappedInstance', 'current', 'recomputeRowHeights'])) {
        setTimeout(() => {
          tree.wrappedInstance.current.recomputeRowHeights();
        }, 20);
      }
    }

    localStorage.setItem('decisively.temporalViewPref', newType);
    window.dispatchEvent(new Event('storage'));
  }
  if (!ranges || !Array.isArray(ranges) || ranges.length < 1) return <>Invalid Value</>;

  return (
    <>
      <Grid container wrap={"nowrap"} alignItems="center">
        {!inline && (
          <Grid item>
            <IconButton onClick={toggleView} variant='contained'><FlipCameraAndroidIcon /></IconButton>
          </Grid>
        )}
        <Grid
          item
          style={{
            height: '100%',
            flexShrink: 0,
            display: 'flex',
            gap: '0.25rem',
            flexGrow: type === 'bar' ? 1 : undefined,
          }}
          onClick={onClick}
        >
          {type === 'chart' ?
            <TemporalSparkLine ranges={ranges} />
            : (type === 'bar' ?
              <StyledTempTimeline onClick={onPopUp} value={value} usePercentageWidth title={node.description} />
              : <TemporalText value={value} />)
          }
        </Grid>
        {!inline && (
          <Grid item xs={3}>
            <IconButton onClick={handleClick} variant='contained'><HelpOutline/></IconButton>
            {edit && <IconButton onClick={handleEditClick} variant='contained'><EditIcon /></IconButton>}
            {typeof node.input !== 'undefined' && <Label style={{ marginLeft: 10 }} color="lightgray">Input</Label>}
          </Grid>
        )}
        <Dialog open={timelineOpen}>
          {/* we must pass both node and value, as value may have been modified */}
          <TemporalValueModal close={() => setTimelineOpen(false)} node={node} value={value} />
        </Dialog>
      </Grid>

    </>
  );
};
TemporalValueRender.displayName = "models/release/TemporalValueRender";

export default TemporalValueRender;

/**
 *
 *
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <PopoverContent>
          <Typography variant='h3'>Temporal Value Explanation</Typography>

          { /*skipGraph ? null : (
            <ChartWrapper>
              <TemporalGraph ranges={ranges} />
            </ChartWrapper>
          )*}

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>From</TableCell>
                <TableCell>To</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ranges.map((r, index) => (
                <TableRow key={index}>
                  <TableCell>{renderDate(get(r, "r.start"))}</TableCell>
                  <TableCell>{renderDate(get(r, "r.end"))}</TableCell>
                  <TableCell>{valueRender(r.v)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </PopoverContent>
      </Popover>

      <Popover
        open={Boolean(editEl)}
        onClose={handleEditClose}
        anchorEl={editEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <PopoverContent>
          {
            temporalEditorProps === undefined
              ? "Edit Temporal Value"
              : <TemporalEditor._ {...temporalEditorProps} close={handleEditClose} />
          }

          {
            // timePoints={rangesToTimePoints(
            //   ranges.map(it => ({
            //     ...it,
            //     r: {
            //       start: typeof it.r.start === 'string' ? it.r.start : it.r.start.toISOString(),
            //       end: typeof it.r.end === 'string' ? it.r.end : it.r.end.toISOString(),
            //     },
            //   })),
            // )}
          }
          {/* Edit Temporal Value
          To do: Build editing form here in react-form-hook and then pass back the result to the underlying so it can update it's temporal value *}
        </PopoverContent>
      </Popover>
 */
