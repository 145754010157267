import { all, put, takeLatest } from "redux-saga/effects";
import { ReleaseTestCasesNS } from '@packages/commons';
import * as fullReleaseNS from "../../../redux/fullRelease";
import { TestCasesRootCtxState } from "@components/TestCasesTree/types";
import { defaultTestCasesRootCtxState } from "@components/TestCasesTree/RootCtx";
import type { Release } from "@packages/commons";
import produce from "immer";
import { defaultTestCasesRootCtxValue } from '../RootCtx';

const defaultSetValue = defaultTestCasesRootCtxState.setValue;
const testCasesSetValueRef: { cur: TestCasesRootCtxState[ 'setValue' ] } = { cur: defaultSetValue };


export const aTypes = {
  hydrateTestCasesCtxFromFullRelease: 'debugger-test-cases-tree/hydrate-test-cases-ctx-from-full-release',
  encloseSetValue: 'debugger-test-cases-tree/enclose-set-value',
} as const;
export type Actions = {
  hydrateTestCasesCtxFromFullRelease: {
    type: typeof aTypes.hydrateTestCasesCtxFromFullRelease;
    payload: Release;
  };
  encloseSetValue: {
    type: typeof aTypes.encloseSetValue;
    payload: typeof testCasesSetValueRef.cur;
  };
}
export const aCreators: {
  [ K in keyof Actions ]: Actions[ K ] extends { payload: unknown }
    ? (p: Actions[ K ][ "payload" ]) => Actions[K]
    : () => Actions[K]
} = {
  hydrateTestCasesCtxFromFullRelease: p => ({
    type: aTypes.hydrateTestCasesCtxFromFullRelease,
    payload: p,
  }),
  encloseSetValue: p => ({ type: aTypes.encloseSetValue, payload: p }),
};


export function* saga() {
  /**
   * it might happen so, (although feels almost impossible) that\
   * we will have fullRelease before proper setValue is enclosed.\
   * In that case we need to have a one time logic to run test \
   * cases context hydration algorithm right after we enclose \
   * valueRef, as opposed to typical "full release is updated - \
   * trigger rehydration". And we will
   */
  let oneTimeFullReleaseCatchUp: null | Release = null;

  yield all([
    takeLatest(
      aTypes.hydrateTestCasesCtxFromFullRelease,
      function* onHydrateTestCasesCtxFromFullRelease( a: Actions[ 'hydrateTestCasesCtxFromFullRelease' ] ) {
        const release = a.payload;
        const setValue = testCasesSetValueRef.cur;

        const { payloads } = release;
        if(payloads === undefined) {
          setValue(prev => ({
            ...prev,
            goalId: defaultTestCasesRootCtxValue.goalId,
            selectedId: defaultTestCasesRootCtxValue.selectedId,
            tree: defaultTestCasesRootCtxValue.tree,
            testCasesTreeCompState: defaultTestCasesRootCtxValue.testCasesTreeCompState,
          }));

          return;
        }


        setValue(prev => produce(prev, draft => {
          const rootWithPayloads: ReleaseTestCasesNS.TestCaseTItemDir = {
            ...ReleaseTestCasesNS.rootTestCaseTItem,
            children: payloads,
          };
          const augmentedNewTree = produce(rootWithPayloads, newTreeDraft => {
            if(prev.selectedId === null) return;

            /**
             * so if this saga ran after we have created new test case and\
             * requested "fullRelease actualize", hydrated tree will have all\
             * goalIds and payloads set to empty values. But that is not\
             * correct as we have just created new test case, and should \
             * prefill its counterpart in test cases context with that data,\
             * that was used during creation. And that seems to be possible\
             * if we take "selectedId", find it in "newTree" and set goalId\
             * and payload of this found node to "actions.getDebuggerPayoad" \
             * and "goalId" from prev. "selectedId" is correct because we \
             * set that right after getting success response from BE create \
             * request
             */
            const maybeMatch = ReleaseTestCasesNS.utilsNS.tCasesFindById({
              rootItem: newTreeDraft,
              id: prev.selectedId
            });
            if(maybeMatch !== null && maybeMatch.type === 'payload') {
              maybeMatch.goal = draft.goalId;
              maybeMatch.data = draft.actions.getDebuggerPayoad();
            }
          });

          draft.tree = augmentedNewTree;
        }));
      }
    ),
    takeLatest(aTypes. encloseSetValue, function* onEncloseTestCasesValueRef(a: Actions[ 'encloseSetValue' ]) {
      testCasesSetValueRef.cur = a.payload;

      if(oneTimeFullReleaseCatchUp === null) return;

      const fullRelease = oneTimeFullReleaseCatchUp;
      oneTimeFullReleaseCatchUp = null;

      yield put(aCreators.hydrateTestCasesCtxFromFullRelease(fullRelease));
    }),
    /**
     * will be used to reactively update test cases context after\
     * full release was updated
     */
    takeLatest(fullReleaseNS.aTypes.set, function* hydrateTestCasesFromFullRelease(
      a: fullReleaseNS.Actions[ 'set' ]
    ) {
      // no idea why we would set fullRelease as null, but oh well
      if(a.payload === null) return;

      if(testCasesSetValueRef.cur === defaultSetValue) {
        oneTimeFullReleaseCatchUp = a.payload;

        return;
      }


      yield put(aCreators.hydrateTestCasesCtxFromFullRelease(a.payload));
    }),
  ])
}
