import { FocusGraph } from "@components";
import RuleGraphProvider from "@components/GraphVisualisation/providers/RuleGraphProvider";
import { graphlib } from "dagre";
import styled from "styled-components";


export const AttributeGraph = ({attribute, release}) => {
  // Putting this outside causes a weird depedency error
  const StyledFocusGraph = styled(FocusGraph)`
    height: 100%;
  `;
  return (
    <RuleGraphProvider graph={graphlib.json.read(release.rule_graph)}>
      <StyledFocusGraph graph={release.rule_graph} focusNode={attribute} />
    </RuleGraphProvider>
  )
}
