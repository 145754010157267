export type DataTableJSpreadsheetHelperState = {
  __type: '__dataTableJSpreadsheetHelperState';
  /** starts from 1 */
  page: number;
  /**
   * for now it will be strictly 10 elements and no way to override\
   * it, but perhaps in the future it will be 50 elements.
   *
   * @default 10
   */
  perPage: number;
  /**
   * we can't really have total with the way datatables are implemented\
   * on the BE
   */
  // total: number;
  /**
   * instead we will use another approach and store the last page\
   * number. It will be populated when we get any page that is less\
   * than "perPage" items long (which would mean there are no pages\
   * after that one). And in UI we will disable "next" button if\
   * current page is equal to lastPage. For a default state we will\
   * use -1 which means "we don't yet got to the end of this datatable\
   * and have no way of knowing what the last page is"
   */
  lastPage: number;
  /**
   * to get next/prev set of rows we need to query BE with id of a\
   * datatable document, so we''l store this here
   */
  documentId: string;
}
export const defaultDataTableJSpreadsheetHelperState: DataTableJSpreadsheetHelperState = {
  __type: '__dataTableJSpreadsheetHelperState',
  page: 1,
  perPage: 10,
  lastPage: -1,
  documentId: '',
};

export const isDataTableJSpreadsheetHelperState = ( v: unknown ): v is DataTableJSpreadsheetHelperState => {
  if ( typeof v !== 'object' || v === null ) return false;

  const typed = v as DataTableJSpreadsheetHelperState;
  return typed.__type === defaultDataTableJSpreadsheetHelperState.__type;
}


export const getDataTableJSpreadsheetHelperState = ( maybeHelperState: unknown ): DataTableJSpreadsheetHelperState => (
  isDataTableJSpreadsheetHelperState( maybeHelperState ) ? maybeHelperState : (
    defaultDataTableJSpreadsheetHelperState
  )
);
