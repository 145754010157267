import {
  CalendartimeIcon,
  TimeIcon,
  TextfieldIcon,
  BooleanIcon,
  CheckboxIcon,
  CurrencyIcon,
  RadiobuttonIcon,
  AutoIcon,
  Level1Icon,
  TypographyIcon,
  ImplementationIcon,
  FunctionsIcon,
  WarningIcon,
  CalendarIcon,
  AutogenpenIcon,
  TargetIcon,
} from "@icons";
import { system } from "theme";
import { GraphNodeType } from "./utils";
import { ValueType } from "@packages/commons";

export const getAttributeIconAsComponentType = (attribTyp: ValueType, defaultRet?: any) => {

  switch (attribTyp as string || "") {
    case "datetime": return (CalendartimeIcon);
    case "date": return (CalendarIcon);
    case "time": return (TimeIcon);
    case "text": return (TextfieldIcon);
    case "typography": return (TypographyIcon);
    case "boolean": return (CheckboxIcon);
    case "currency": return (CurrencyIcon);
    case "options": return (BooleanIcon); // badly named but it looks good
    case "entity": return (ImplementationIcon);
    case "radio": return (RadiobuttonIcon);
    case "number": return (Level1Icon);
    case "number_of_instances": return (FunctionsIcon);
    case "auto": return (AutoIcon);
    default:
      return (defaultRet || null);
  }
};

const getAttributeIcon_ = (type: ValueType) => {
  const Icon = getAttributeIconAsComponentType(type);

  return Icon ? <Icon /> : <WarningIcon />;
}

/**
 * Given attribute type, get the appropriate icon
 * TODO
 * @deprecated would like to replace with `getAttributeIcon_` above - just check with Alex this is okay
 */
export const getAttributeIcon = (type: ValueType) => {
  switch (type) {
    case "datetime": return <CalendartimeIcon />;
    case "date": return <CalendarIcon />;
    case "time": return <TimeIcon />;
    case "text": return <TextfieldIcon />;
    // case "typography": return <TypographyIcon/>;
    case "boolean": return <CheckboxIcon />;
    case "currency": return <CurrencyIcon />;
    // case "options": return <BooleanIcon/>; // badly named but it looks good
    // case "entity": return <ImplementationIcon/>;
    // case "radio": return <RadiobuttonIcon/>;
    case "number": return <Level1Icon />;
    // case "number_of_instances": return <FunctionsIcon/>;
    case "auto": return <AutogenpenIcon />;
    default:
      return <WarningIcon />;
  }
};

export const getGraphNodeTypeColor = (type: GraphNodeType) => {
  switch (type) {
    case GraphNodeType.INPUT: return system.purpleSlate;
    case GraphNodeType.DERIVED: return system.orangeFiesta;
    case GraphNodeType.GOAL: return system.greenTea;
    case GraphNodeType.IDENTIFIER: return system.redBlush;
    default:
      return "#FF00FF"; // glowing pink/purple makes it obvious
  }
};