import EventEmitter from 'eventemitter3';
import { Immer, freeze } from 'immer';


export type Events< StoreState > = {
  'update': (v: StoreState) => unknown;
}

export type DeriveFromState< StoreState, V > = (v: StoreState) => V;


export class Store< StoreState > {
  __state: StoreState;

  __immerInstance: Immer;

  __emitter = new EventEmitter< Events< StoreState > >();

  /**
   * @param { StoreState } initialState
   * @param { boolean } [autoFreeze] (optional, default: false)
   */
  constructor(initialState: StoreState, autoFreeze = false) {
    this.__state = autoFreeze ? freeze(initialState, true) : initialState;
    this.__immerInstance = new Immer({ autoFreeze });
  }


  subscribe = (fn: (v: StoreState) => unknown): void => {
    this.__emitter.on( "update", fn );

    const listenerCount = this.__emitter.listenerCount( "update" );
    const errForPotentialMemoryLeak = "r6UxxGcfZe | could be a potential memory leak";
    if ( listenerCount >= 500 ) {
      console.error( `${ errForPotentialMemoryLeak } (500)` );
    }
    if ( listenerCount >= 1000 ) {
      console.error( `${ errForPotentialMemoryLeak } (1000)` );
    }
  };

  unsubscribe = (fn: (v: StoreState) => unknown): void => {
    this.__emitter.removeListener('update', fn);
  }


  update = (recipe: (draft: StoreState) => StoreState | void): void => {
    const nextState = this.__immerInstance.produce(this.__state, recipe);
    this.__state = nextState;

    this.__emitter.emit('update', this.__state);
  }

  deriveFromState: < V >(f: DeriveFromState< StoreState, V >) => ReturnType< typeof f > = f => f(this.__state);
}
