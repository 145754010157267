import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import { hideModal } from "@imminently/imminently_platform";
import { CloseIcon, ArrowleftIcon, EnlargeIcon, ShrinkIcon } from "@icons";
import { useFullRelease } from "@common/hooks_useFullRelease";
import { ParsedGraphContextProvider } from "@pages/models/release/GraphContext";
import { Tooltip } from "@components";

const Header = styled.div`
  margin: 2rem 2rem 0 2rem;
  display: flex;
  align-items: center;
`;

const Title = styled(Typography)`
  flex: 1;
  line-height: 2rem;
`;

const Description = styled.div`
  margin: 0.5rem 2rem 0 2rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2rem 2rem 2rem;
  height: 100%;
`;

/**
  @type { React.FC< {
    open: boolean;
    close?: () => unknown;
    maxWidth?: any;
    height?: any;
    title: any;
    headerSetRef: any;
    previous?: any;
    back?: any;
    description?: any;
    style: any;
    modalComponent: any;
    allowResize?: boolean;
    headerActions?: React.ReactNode;
  } > }
 */
export const ModalComp = (p) => {
  const {
    open,
    close,
    maxWidth,
    height,
    title,
    headerSetRef,
    previous,
    back,
    description,
    style,
    headerStyle,
    modalComponent,
    onDismiss,
    allowResize,
    headerActions,
    ...props
  } = p;
  const [history, setHistory] = useState([]);
  const [dynamicTitle, setDynamicTitle] = useState(() => title);
  // default to user provided, otherwise false if not provided
  const [fullScreen, setFullScreen] = useState(() => props.fullScreen ?? false);

  const handleBack = () => {
    if (back) back();
    if (history.length > 1) {
      let prevTitle = history.pop();
      setHistory([...history]);
    }
  };

  // TODO dynamic title isn't allowing second modal to set title
  // this is a workaround to ensure it updates
  useEffect(() => {
    if (title) {
      setDynamicTitle(title);
    }
  }, [title, setDynamicTitle]);

  const handleClose = () => {
    if (history.length) setHistory([]);
    if (onDismiss) onDismiss();
    if (close) close();
  };

  const handleExpand = () => {
    setFullScreen(!fullScreen);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={fullScreen ? false : (maxWidth ?? "sm")}
      PaperProps={{ style: { overflow: "hidden", height: fullScreen ? undefined : height } }}
      {...props}
      fullScreen={fullScreen}
    >
      {dynamicTitle && (
        <Header ref={headerSetRef} style={headerStyle}>
          {(previous || history.length > 1) && (
            <IconButton onClick={handleBack}>
              <ArrowleftIcon />
            </IconButton>
          )}
          <Title variant='h3' style={{ paddingLeft: previous ? "0.5rem" : 0 }}>
            {dynamicTitle}
          </Title>
          {headerActions}
          {
            allowResize ? (
              <Tooltip title={fullScreen ? "Shrink" : "Expand"} placement='left'>
                <IconButton aria-label='expand' onClick={handleExpand}>
                  {fullScreen ? <ShrinkIcon /> : <EnlargeIcon />}
                </IconButton>
              </Tooltip>
            ) : null
          }
          {
            close ? (
              <IconButton aria-label='close' onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            ) : null
          }
        </Header>
      )}
      {
        description ? (
          <Description>
            {
              typeof description === "string" ? (
                <Typography variant='body1' paragraph={false}>
                  {description}
                </Typography>
              ) : description
            }
          </Description>
        ) : null
      }
      <Content style={style}>
        {
          typeof modalComponent === "function"
            ? modalComponent({ history, setHistory, close, setDynamicTitle })
            // force inject props
            : React.cloneElement(modalComponent, { history, setHistory, close, setDynamicTitle })
        }
      </Content>
    </Dialog>
  );
};
ModalComp.displayName = "Layouts/Modal/ModalComp";

export const Modal = () => {
  const dispatch = useDispatch();
  const { modalProps, modalComponent } = useSelector(state => state.modal);
  const [ref, setRef] = useState();

  if (!modalComponent) return null;
  const {
    title,
    description,
    open,
    maxWidth,
    closeOverride,
    previous,
    height,
    canClose = true,
    contentStyle,
    headerStyle,
    ...props
  } = modalProps;

  const closeModal = () => {
    const defaultClose = () => dispatch(hideModal());
    if (closeOverride) {
      closeOverride(defaultClose);
    } else {
      defaultClose();
    }
  };

  const back = () => {
    if (previous && typeof previous === 'function') {
      dispatch(previous());
    }
  };

  const style = {
    height: ref ? `calc(100% - ${ref.offsetHeight}px)` : "100%",
    overflow: "auto",
    ...(contentStyle ?? {}),
  };

  return (
    <ParsedGraphContextProvider>
      <ModalComp {...{
        back,
        description,
        headerSetRef: setRef,
        height,
        maxWidth,
        modalComponent,
        open,
        previous,
        style,
        headerStyle,
        title,
        close: canClose ? closeModal : undefined,
        ...props,
      }}
      />
    </ParsedGraphContextProvider>
  );
};
