import { ReactNode, useCallback, useEffect, useState, useRef } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import copy from 'copy-to-clipboard';
import styled from 'styled-components';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { TextField } from './input';
import { CopyIcon } from '@radix-ui/react-icons';
import { TextFieldProps } from '@material-ui/core';

export type CopyTextFieldProps = Omit<TextFieldProps, 'value' | 'startAdornment'> & {
  value: string;
  startAdornment?: ReactNode;
};

const StyledTextField = styled(TextField)`
  .MuiInputBase-root { align-items: center; }

  input {
    font-family: Consolas, monospace;
    font-size: 15pt;
  }
`;

const popperProps = { disablePortal: false };

export const useCopyValue = (value: string) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [open, setOpen] = useState(false);
  const handleTooltipClose = useCallback(() => setOpen(false), []);

  const maybeClearTimeout = useCallback(() => {
    if (timeoutRef.current === null) return;

    clearTimeout(timeoutRef.current);
    timeoutRef.current = null;
  }, []);

  const copyHandler = useCallback(() => {
    copy(value);
    setOpen(true);

    maybeClearTimeout();
    timeoutRef.current = setTimeout(() => setOpen(false), 1_000);
  }, [value, maybeClearTimeout]);

  useEffect(() => {
    if (open) return;

    maybeClearTimeout();
  }, [open, maybeClearTimeout]);

  return { open, handleTooltipClose, copyHandler };
};

export type CopyIconButtonProps = {
  value: string;
  /** Tooltip to display on copy */
  tooltip?: string;
  icon?: ReactNode;
};

export const CopyIconButton = ({ value, tooltip, icon, ...props }: CopyIconButtonProps) => {
  const { open, handleTooltipClose, copyHandler } = useCopyValue(value);

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        PopperProps={popperProps}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={tooltip ?? 'Copied!'}
        placement='left'
      >
        <IconButton onClick={copyHandler} {...props}>
          { icon ?? <CopyIcon /> }
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
};

export const CopyTextField = ({ value, startAdornment, ...props }: CopyTextFieldProps) => {
  const { open, handleTooltipClose, copyHandler } = useCopyValue(value);
  const { InputProps, ...rest } = props;

  return (
    <StyledTextField
      value={value}
      fullWidth
      {...rest}
      InputProps={{
        ...InputProps,
        readOnly: true,
        startAdornment: startAdornment ? <InputAdornment position='start'>{startAdornment}</InputAdornment> : null,
        endAdornment: (
          <InputAdornment position='end'>
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <Tooltip
                PopperProps={popperProps}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title='Copied!'
              >
                <IconButton onClick={copyHandler}>
                  <CopyIcon />
                </IconButton>
              </Tooltip>
            </ClickAwayListener>
          </InputAdornment>
        ),
      }}
    />
  );
};
CopyTextField.displayName = 'components/CopyTextField';
