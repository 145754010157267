/* eslint-disable camelcase */
import React from 'react';
import ReactFlow, {
  Controls,
  ReactFlowProps,
  ReactFlowProvider,
  Node as FlowNodeFromLib,
  Edge as FlowEdgeFromLib,
  isNode,
} from 'react-flow-renderer';
import styled from 'styled-components';
import dagre from 'dagre';
import get from 'lodash/get';
import { AutoFitGraph, scrollableMixin } from '@common';
import { nodeTypes } from '@components/RuleGraph_FullRelease/__nodeTypes';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import * as FilterDatesControlNS from '@components/RuleGraph_FullRelease/DatesFilter';
import { isTemporal } from '@pages/decisions/DecisionDashboard/Reports/Details/UnifiedTimeline/types';
import type { Node } from '../../pages/models/release/Test/GraphTest/redux';
import TemporalValueRender from '@components/TemporalValue';
import GraphPallet from "../RuleGraph_FullRelease/components/GraphPallet";
import GraphNodeFocusMode from "../RuleGraph_FullRelease/components/GraphNodeFocusMode";

const NODE_WIDTH = 200;
const NODE_HEIGHT = 300;

export type RawGraphNode = {
  id: string;
  entity: string;
  parent?: string;
  index?: number;
  description: string;
  hidden?: boolean;
  usedIn?: string[];
  definedIn?: string;
  type: string;
  conditions?: any[];
  value?: unknown;
  input?: Node[ 'value' ];
  derived?: Node[ 'value' ];
};

export type GraphNodeForLayout = {
  label: string;
  node: dagre.Node< RawGraphNode >;
  value: RawGraphNode[ 'input' ] | RawGraphNode[ 'derived' ]
  type: 'byValue',
  width: number;
  height: number;
};

export type FlowNode = FlowNodeFromLib<{
  label: React.ReactNode;
  node: dagre.Node< RawGraphNode >;
  value: dagre.Node< GraphNodeForLayout >[ 'value' ],
}> & {
  width: number;
  height: number;
};
export type FlowEdge = FlowEdgeFromLib & {
  points: dagre.GraphEdge[ 'points' ];
};

const StyledControls = styled(Controls)`
  bottom: 10px;
  top: inherit;
`;

const StyledFlow = styled(ReactFlow)`
  .react-flow__node {
    width: 230px;
  }
`;

// ===================================================================================

const StyledBreadcrumbs = styled(Breadcrumbs)`
  background-color: white;
  white-space: nowrap;
  padding-bottom: 0.75rem;
  margin-bottom: -0.5rem;
  overflow: auto;
  ${ scrollableMixin };

  .MuiBreadcrumbs-ol { flex-wrap: nowrap; }
`;

const Breadcrumb = styled(Typography)`
  cursor: pointer;
  text-decoration: underline;
`;

export type BreadcrumbsCompProps = {
  clickHistory: Array<{
    id: string;
    description?: string | undefined;
  }>;
  navigate: (index: number) => unknown;
  clearFocusNodeClickHistory: () => unknown;
}

export const BreadcrumbsComp: React.FC< BreadcrumbsCompProps > = React.memo(p => {
  const { clickHistory, navigate, clearFocusNodeClickHistory } = p;

  const onClick = React.useCallback< React.MouseEventHandler< HTMLParagraphElement > >(e => {
    const { index } = e.currentTarget.dataset;
    if(index === undefined) return;

    navigate(Number(index));
  }, [navigate]);

  React.useEffect(() => () => (
    void clearFocusNodeClickHistory()
  ), [clearFocusNodeClickHistory]);

  return (
    clickHistory.length === 0
      ? <div />
      : (
        <StyledBreadcrumbs>
          { clickHistory.map(({ id, description }, i) => {
            const title = description || id;
            const shortTitle = title.length > 30
              ? `${ title.slice(0, 30) }…`
              : title;

            return (
              <Breadcrumb title={title} onClick={onClick} data-index={i} key={id}>
                {shortTitle}
              </Breadcrumb>
            );
          }) }
        </StyledBreadcrumbs>
      )
  );
});
BreadcrumbsComp.displayName = 'components/FocusGraph/BreadcrumbsComp';

// ===================================================================================

const buildNode = (data: dagre.Node<RawGraphNode>): GraphNodeForLayout => {
  return {
    label: data.description,
    node: data,
    value: get(data, 'input', get(data, 'derived')),
    type: 'byValue',
    width: NODE_WIDTH,
    // Approx 12 chars per line, each line needs about 50 pixels
    height: Math.max(NODE_HEIGHT, get(data, 'description', '1').length / 12 * 25),
  };
};
const buildNodeInner = buildNode;

const StyledFilterDatesControl = styled(FilterDatesControlNS._)`
  flex-shrink: 0;
`;

const TypedFlowProvider = ReactFlowProvider as React.FC< { children: React.ReactNode } >;

export type FocusGraphProps = {
  graph: {
    nodes: Array<{ v: string; value: RawGraphNode }>;
    edges: unknown[];
    options: Record< string, unknown >
  };
  focusNode: RawGraphNode;
  showHidden?: boolean;
  showValues?: boolean;
  datesFilter?: FilterDatesControlNS.Props[ 'value' ];
  setDatesFilter?: FilterDatesControlNS.Props[ 'set' ];
  BreadcrumbsJSX?: JSX.Element;
  onRawNodeClick: (n: dagre.Node<RawGraphNode>) => unknown;
}

const GRAPH_STYLES = { width: '100%' /* , minHeight: '600px' */ };

export const FocusGraph: React.FC< FocusGraphProps > = React.memo(p => {
  const {
    graph,
    focusNode,
    showHidden = false,
    showValues = true,
    datesFilter,
    setDatesFilter,
    BreadcrumbsJSX,
    onRawNodeClick,
  } = p;

  const p_graph = React.useMemo(
    () => dagre.graphlib.json.read(graph) as dagre.graphlib.Graph< RawGraphNode >,
    [graph],
  );

  // // useEffect(() => {
  // //   setFocusNode(findNode(graph.nodes, goal));
  // //   // Build the graph from this goal
  // // }, [goal, graph, setFocusNode]);

  const flowGraphElements: Array< FlowNode | FlowEdge > = React.useMemo(() => {
    if(!focusNode) return [];

    const buildNode = data => {
      // return buildNodeInner(data);
      if(!datesFilter || datesFilter[ 0 ] === null || datesFilter[ 1 ] === null) return buildNodeInner(data);

      return buildNodeInner({
        ...data,
        derived: FilterDatesControlNS.mergeValueAndDatesFilter(data.derived, datesFilter),
      });
    };

    // Now build the graph from this focus node
    const g = new dagre.graphlib.Graph< GraphNodeForLayout >();
    g.setGraph({
      nodesep: 100,
    });

    // Add the root node - everything will point to this
    const path = (() => {
      if (focusNode.entity === 'global') return focusNode.id;
      if (focusNode.hidden) return focusNode.id;
      const path = `${ focusNode.entity }/${ focusNode.index }/${ focusNode.id }`;

      return focusNode.parent ? `${ focusNode.parent }/${ path }` : path;
    })();


    const preds = (p_graph.predecessors(path) || []) as unknown as string[];
    const sucs = (p_graph.successors(path) || []) as unknown as string[];
    const firstNode = buildNode(focusNode);
    g.setNode(path, firstNode);

    preds.forEach(id => {
      const data = p_graph.node(id);
      if (!showHidden && data.hidden) return;

      g.setNode(id, buildNode(data));
      // Connect this to the root
      g.setEdge(id, path, { label: '' });
      // We have to set the parents of the parent so that we can colour the nodes correctly (eg: are they goals or intermediate?)
      const parent_preds = (p_graph.predecessors(id) || []) as unknown as string[];
      parent_preds.forEach(p_id => {
        g.setEdge(p_id, id, { label: '' });
      });
    });

    sucs.forEach(id => {
      const data = p_graph.node(id);
      if (!showHidden && data.hidden) return;

      g.setNode(id, buildNode(data));
      // Connect this to the root

      g.setEdge(path, id, { label: '' });
      const child_sucs = (p_graph.successors(id) || []) as unknown as string[];
      child_sucs.forEach(c_id => {
        g.setEdge(id, c_id, { label: '' });
      });
    });

    dagre.layout(g);
    const flowGraphElements = ((g, showValues = true) => {
      const nodes: FlowNode[] = [];
      const edges: FlowEdge[] = [];
      let type, style;
      g.nodes().forEach(i => {
        if (!g.node(i)) {
          return;
        }
        if (!showHidden && g.node(i).node.hidden) return;
        if (showValues) {
          type = 'byValue';
        } else if (Object.keys((g as any)._in[ i ]).length === 0) type = 'goal';
        else if (Object.keys((g as any)._out[ i ]).length === 0) type = 'source';
        else type = 'intermediate';

        const node = g.node(i);
        let valueDesc: React.ReactNode = '';
        let label: React.ReactNode = g.node(i).label;
        if (node.node.index) label = `${ label } (${ node.node.entity }:${ node.node.index })`;
        if (node.value === false) {
          valueDesc = 'false';
        } else if (node.value === null) {
          valueDesc = 'Uncertain';
        } else if (typeof node.value === 'undefined') {
          valueDesc = 'Unknown';
        } else if (get(node, 'value.temporal')) {
          valueDesc = <TemporalValueRender inline ranges={get(node, 'value.temporal.ranges') || []} />;
        } else valueDesc = node.value.toString();

        if (showValues) {
          label = (
            <div>
              <p>{label}</p>
              <em>{valueDesc}</em>
            </div>
          );
        }

        nodes.push({
          id: i,
          type,
          style,
          data: {
            label,
            node: g.node(i).node,
            value: g.node(i).value,
          },
          width: g.node(i).width,
          height: g.node(i).height,
          position: {
            x: g.node(i).x - g.node(i).width / 2,
            y: g.node(i).y - g.node(i).height / 2,
          },
        });
      });
      g.edges().forEach(e => {

        if (!g.node(e.v) || !g.node(e.w)) return;
        if (!showHidden && (g.node(e.v).node.hidden || g.node(e.w).node.hidden)) return;

        edges.push({
          id: `__${ e.v }__${ e.w }`,
          points: g.edge(e).points,
          source: e.v,
          target: e.w,
          animated: true,
          label: '',
        });
      });

      return [...nodes, ...edges];
    })(g, showValues);

    return flowGraphElements;
  }, [focusNode, p_graph, showHidden, showValues, datesFilter]);


  const onElementClick = React.useCallback< NonNullable< ReactFlowProps[ 'onElementClick' ] > >(
    (_, element) => {
      if(!isNode(element)) return;

      const { data } = (element as FlowNode);
      if(data === undefined) return;

      onRawNodeClick(data.node);
    },
    [onRawNodeClick],
  );


  const hideFilterDatesControl = React.useMemo(() => {
    const someValueIsTemporal = graph.nodes.some(it => isTemporal(it.value.derived));

    return someValueIsTemporal === false || undefined;
  }, [graph]);

  const minMax = React.useMemo(() => FilterDatesControlNS.deriveMinMaxFromNodeValues(
    graph.nodes.map(it => it.value.derived),
  ), [graph]);

  return (
    <TypedFlowProvider>
      <AutoFitGraph />
      <StyledFlow
        nodesDraggable
        nodeTypes={{
          ...(nodeTypes as any),
          // intermediate: GraphNodeFocusMode,
          // goal: GraphNodeFocusMode,
          // source: GraphNodeFocusMode,
          byValue: GraphNodeFocusMode,
        }}
        elements={flowGraphElements}
        minZoom={0.01}
        style={GRAPH_STYLES}
        nodesConnectable={false}
        onElementClick={onElementClick}
      >
        <StyledControls />
        <Box
          display='flex'
          justifyContent='space-between'
          position='relative'
          zIndex='4'
          bgcolor='#fff'
          alignItems='center'
          margin='0 2rem'
          paddingBottom='0.25rem'
          gridGap='1rem'
        >
          { BreadcrumbsJSX }
          {
            setDatesFilter === undefined ? null : (
              <StyledFilterDatesControl
                hide={hideFilterDatesControl}
                minMax={minMax}
                set={setDatesFilter}
                value={datesFilter}
              />
            )
          }
        </Box>
        <GraphPallet />
      </StyledFlow>
    </TypedFlowProvider>
  );
});
FocusGraph.displayName = 'components/FocusGraph';
