import React from "react";


export type SortableTreeRefCtxValue = {
  ref: { current: null | Record< string, unknown > };
};
export const defaultSortableTreeRefCtxValue: SortableTreeRefCtxValue = {
  ref: { current: null }
};

export const SortableTreeRefCtx = React.createContext(defaultSortableTreeRefCtxValue)
SortableTreeRefCtx.displayName = 'components/DecisionReport/SortableTreeRefCtx';

export const useSortableTreeRefCtx = () => React.useContext(SortableTreeRefCtx);

export const WithSortableTreeRefCtx: React.FC< React.PropsWithChildren > = React.memo(p => {
  return (
    <SortableTreeRefCtx.Provider value={defaultSortableTreeRefCtxValue}>
      { p.children }
    </SortableTreeRefCtx.Provider>
  )
})
