/* eslint-disable camelcase */
// import { CompletionKeys, TrendsKeys } from './constants';
import * as InteractiveReduxNS from '../Interactive/redux/reducer';

export type VisibleCompletionKeys = { [ key in import('./constants').CompletionKey ]: boolean };


export const reportTabs = ['overview', 'input', 'report', 'interactive'] as const;
// export const reportTabs = ['overview', 'input', 'report', 'timeline'] as const;

export type IState = {
  tableCollapsed: boolean;
  // visibleCompletionKeys: VisibleCompletionKeys;
  // completion: {
  //   data: Array<
  //     & { date: string; }
  //     & { [ key in keyof typeof CompletionKeys ]: number; }
  //   >;
  //   hover: (
  //     | { active: false }
  //     | { active: true, date: IState[ 'completion' ][ 'data' ][ 0 ][ 'date' ] }
  //   )
  // }
  // trendsData: Array<
  //   & { name: string; }
  //   & { [ key in keyof typeof TrendsKeys ]: number; }
  // >;
  // _DEPRECATED_projectId: string;
  // _DEPRECATED_releaseId: string;
  reportTab: typeof reportTabs[number];
  testRunForBatchTestReports: null | string;
  testScenario: null | string;
  decisionReportId: string | null;
  search: string;
  [InteractiveReduxNS.REDUX_PROP]: InteractiveReduxNS.State;
}


export const defaultState: IState = {
  tableCollapsed: false,
  // visibleCompletionKeys: {
  //   complete: true,
  //   error: true,
  //   incomplete: true,
  // },
  // completion: {
  //   data: [
  //     { date: '2021-09-10', [ CompletionKeys.complete ]: 4, [ CompletionKeys.incomplete ]: 15, [ CompletionKeys.error ]: 16 },
  //     { date: '2021-09-11', [ CompletionKeys.complete ]: 18, [ CompletionKeys.incomplete ]: 22, [ CompletionKeys.error ]: 48 },
  //     { date: '2021-09-12', [ CompletionKeys.complete ]: 49, [ CompletionKeys.incomplete ]: 8, [ CompletionKeys.error ]: 25 },
  //     { date: '2021-09-13', [ CompletionKeys.complete ]: 20, [ CompletionKeys.incomplete ]: 5, [ CompletionKeys.error ]: 5 },
  //     { date: '2021-09-14', [ CompletionKeys.complete ]: 31, [ CompletionKeys.incomplete ]: 20, [ CompletionKeys.error ]: 7 },
  //     { date: '2021-09-15', [ CompletionKeys.complete ]: 6, [ CompletionKeys.incomplete ]: 36, [ CompletionKeys.error ]: 12 },
  //     { date: '2021-09-16', [ CompletionKeys.complete ]: 40, [ CompletionKeys.incomplete ]: 46, [ CompletionKeys.error ]: 24 },
  //     { date: '2021-09-17', [ CompletionKeys.complete ]: 33, [ CompletionKeys.incomplete ]: 50, [ CompletionKeys.error ]: 4 },
  //     { date: '2021-09-18', [ CompletionKeys.complete ]: 12, [ CompletionKeys.incomplete ]: 42, [ CompletionKeys.error ]: 20 },
  //     { date: '2021-09-19', [ CompletionKeys.complete ]: 3, [ CompletionKeys.incomplete ]: 25, [ CompletionKeys.error ]: 15 },
  //     { date: '2021-09-20', [ CompletionKeys.complete ]: 42, [ CompletionKeys.incomplete ]: 3, [ CompletionKeys.error ]: 17 },
  //     { date: '2021-09-21', [ CompletionKeys.complete ]: 8, [ CompletionKeys.incomplete ]: 21, [ CompletionKeys.error ]: 47 },
  //     { date: '2021-09-22', [ CompletionKeys.complete ]: 0, [ CompletionKeys.incomplete ]: 14, [ CompletionKeys.error ]: 26 },
  //     { date: '2021-09-23', [ CompletionKeys.complete ]: 41, [ CompletionKeys.incomplete ]: 40, [ CompletionKeys.error ]: 33 },
  //     { date: '2021-09-24', [ CompletionKeys.complete ]: 14, [ CompletionKeys.incomplete ]: 47, [ CompletionKeys.error ]: 37 },
  //     { date: '2021-09-25', [ CompletionKeys.complete ]: 5, [ CompletionKeys.incomplete ]: 26, [ CompletionKeys.error ]: 0 },
  //     { date: '2021-09-26', [ CompletionKeys.complete ]: 26, [ CompletionKeys.incomplete ]: 29, [ CompletionKeys.error ]: 11 },
  //     { date: '2021-09-27', [ CompletionKeys.complete ]: 44, [ CompletionKeys.incomplete ]: 0, [ CompletionKeys.error ]: 21 },
  //     { date: '2021-09-28', [ CompletionKeys.complete ]: 35, [ CompletionKeys.incomplete ]: 12, [ CompletionKeys.error ]: 22 },
  //     { date: '2021-09-29', [ CompletionKeys.complete ]: 2, [ CompletionKeys.incomplete ]: 34, [ CompletionKeys.error ]: 3 },
  //     { date: '2021-09-30', [ CompletionKeys.complete ]: 37, [ CompletionKeys.incomplete ]: 35, [ CompletionKeys.error ]: 35 },
  //     { date: '2021-10-01', [ CompletionKeys.complete ]: 10, [ CompletionKeys.incomplete ]: 7, [ CompletionKeys.error ]: 9 },
  //     { date: '2021-10-02', [ CompletionKeys.complete ]: 13, [ CompletionKeys.incomplete ]: 31, [ CompletionKeys.error ]: 13 },
  //     { date: '2021-10-03', [ CompletionKeys.complete ]: 22, [ CompletionKeys.incomplete ]: 16, [ CompletionKeys.error ]: 39 },
  //     { date: '2021-10-04', [ CompletionKeys.complete ]: 43, [ CompletionKeys.incomplete ]: 27, [ CompletionKeys.error ]: 44 },
  //     { date: '2021-10-05', [ CompletionKeys.complete ]: 7, [ CompletionKeys.incomplete ]: 18, [ CompletionKeys.error ]: 31 },
  //     { date: '2021-10-06', [ CompletionKeys.complete ]: 11, [ CompletionKeys.incomplete ]: 30, [ CompletionKeys.error ]: 43 },
  //     { date: '2021-10-07', [ CompletionKeys.complete ]: 15, [ CompletionKeys.incomplete ]: 38, [ CompletionKeys.error ]: 38 },
  //     { date: '2021-10-08', [ CompletionKeys.complete ]: 23, [ CompletionKeys.incomplete ]: 19, [ CompletionKeys.error ]: 41 },
  //     { date: '2021-10-09', [ CompletionKeys.complete ]: 28, [ CompletionKeys.incomplete ]: 17, [ CompletionKeys.error ]: 50 },
  //   ],
  //   hover: { active: false },
  // },
  // trendsData: [
  //   { name: '', [ TrendsKeys.trends ]: 5 },
  //   { name: '', [ TrendsKeys.trends ]: 57 },
  //   { name: '', [ TrendsKeys.trends ]: 50 },
  //   { name: '', [ TrendsKeys.trends ]: 90 },
  //   { name: '', [ TrendsKeys.trends ]: 39 },
  //   { name: '', [ TrendsKeys.trends ]: 72 },
  //   { name: '', [ TrendsKeys.trends ]: 95 },
  //   { name: '', [ TrendsKeys.trends ]: 4 },
  //   { name: '', [ TrendsKeys.trends ]: 10 },
  //   { name: '', [ TrendsKeys.trends ]: 59 },
  //   { name: '', [ TrendsKeys.trends ]: 45 },
  //   { name: '', [ TrendsKeys.trends ]: 71 },
  //   { name: '', [ TrendsKeys.trends ]: 75 },
  //   { name: '', [ TrendsKeys.trends ]: 8 },
  //   { name: '', [ TrendsKeys.trends ]: 11 },
  //   { name: '', [ TrendsKeys.trends ]: 39 },
  //   { name: '', [ TrendsKeys.trends ]: 46 },
  //   { name: '', [ TrendsKeys.trends ]: 35 },
  //   { name: '', [ TrendsKeys.trends ]: 20 },
  //   { name: '', [ TrendsKeys.trends ]: 45 },
  //   { name: '', [ TrendsKeys.trends ]: 25 },
  //   { name: '', [ TrendsKeys.trends ]: 86 },
  //   { name: '', [ TrendsKeys.trends ]: 18 },
  //   { name: '', [ TrendsKeys.trends ]: 15 },
  //   { name: '', [ TrendsKeys.trends ]: 71 },
  //   { name: '', [ TrendsKeys.trends ]: 94 },
  //   { name: '', [ TrendsKeys.trends ]: 35 },
  //   { name: '', [ TrendsKeys.trends ]: 89 },
  //   { name: '', [ TrendsKeys.trends ]: 3 },
  //   { name: '', [ TrendsKeys.trends ]: 51 },
  // ],
  // _DEPRECATED_projectId: '',
  // _DEPRECATED_releaseId: '',
  reportTab: reportTabs[ 0 ],
  testRunForBatchTestReports: null,
  testScenario: null,
  decisionReportId: null,
  search: '',
  [ InteractiveReduxNS.REDUX_PROP ]: InteractiveReduxNS.defaultState,
};


export const REDUX_PROP = 'monitorDecisions';


export const ActionTypes = {
  setTableCollapsed: 'monitorDecisions/setTableCollapsed',
  // setVisibleCompletionKeys: 'monitorDecisions/setVisibleCompletionKeys',
  // toggleVisibleCompletionKey: 'monitorDecisions/toggleVisibleCompletionKey',
  // setCompletionHover: 'monitorDecisions/setCompletionHover',
  // setProjectId: 'monitorDecisions/setProjectId',
  // setReleaseId: 'monitorDecisions/setReleaseId',
  setReportTab: 'monitorDecisions/setReportTab',
  requestToLoadPage: 'monitorDecisions/requestToLoadPage',
  requestToSetPerPage: 'monitorDecisions/requestToSetPerPage',
  requestToLoadReportsForBatchTest: 'monitorDecisions/requestToLoadReportsForBatchTest',
  requestToLoadReportsForReporting: 'monitorDecisions/requestToLoadReportsForReporting',
  setTestRunForBatchTestReports: 'monitorDecisions/setTestRunForBatchTestReports',
  requestToSetTestRunForBatchTestReports: 'monitorDecisions/requestToSetTestRunForBatchTestReports',
  setTestScenario: 'monitorDecisions/setTestScenario',
  requestToSetTestScenario: 'monitorDecisions/requestToSetTestScenario',
  requestToLoadDecisionReport: 'monitorDecisions/requestToLoadDecisionReport',
  requestToRemoveTestScenario: 'monitorDecisions/requestToRemoveTestScenario',
  setDecisionReportId: 'monitorDecisions/setDecisionReportId',
  setSearch: 'monitorDecisions/setSearch',
  requestToSetSearchAndLoadReports: 'monitorDecisions/requestToSetSearchAndLoadReports',
  requestToGetReportIdFromURL: 'monitorDecisions/requestToGetReportIdFromURL',
} as const;

export type IActions = {
  setTableCollapsed: {
    type: typeof ActionTypes.setTableCollapsed;
    payload: boolean;
  };
  // setVisibleCompletionKeys: {
  //   type: typeof ActionTypes.setVisibleCompletionKeys;
  //   payload: IState[ 'visibleCompletionKeys' ];
  // };
  // toggleVisibleCompletionKey: {
  //   type: typeof ActionTypes.toggleVisibleCompletionKey;
  //   payload: keyof IState[ 'visibleCompletionKeys' ];
  // };
  // setCompletionHover: {
  //   type: typeof ActionTypes.setCompletionHover;
  //   payload: IState[ 'completion' ][ 'hover' ];
  // };
  // setProjectId: {
  //   type: typeof ActionTypes.setProjectId;
  //   payload: IState[ '_DEPRECATED_projectId' ];
  // };
  // setReleaseId: {
  //   type: typeof ActionTypes.setReleaseId;
  //   payload: IState[ '_DEPRECATED_releaseId' ];
  // };
  setReportTab: {
    type: typeof ActionTypes.setReportTab;
    payload: IState[ 'reportTab' ];
  };
  requestToLoadPage: {
    type: typeof ActionTypes.requestToLoadPage;
    payload: {
      index: number;
    };
  }
  requestToSetPerPage: {
    type: typeof ActionTypes.requestToSetPerPage;
    payload: number;
  }
  requestToLoadReportsForBatchTest: {
    type: typeof ActionTypes.requestToLoadReportsForBatchTest;
  }
  requestToLoadReportsForReporting: {
    type: typeof ActionTypes.requestToLoadReportsForReporting;
  };
  setTestRunForBatchTestReports: {
    type: typeof ActionTypes.setTestRunForBatchTestReports;
    payload: IState[ 'testRunForBatchTestReports' ];
  };
  requestToSetTestRunForBatchTestReports: {
    type: typeof ActionTypes.requestToSetTestRunForBatchTestReports;
    payload: IActions[ 'setTestRunForBatchTestReports' ][ 'payload' ]
  };
  setTestScenario: {
    type: typeof ActionTypes.setTestScenario;
    payload: IState[ 'testScenario' ];
  };
  requestToSetTestScenario: {
    type: typeof ActionTypes.requestToSetTestScenario;
    payload: IActions[ 'setTestScenario' ][ 'payload' ]
  };
  requestToLoadDecisionReport: {
    type: typeof ActionTypes.requestToLoadDecisionReport;
    payload: string;
  }
  requestToRemoveTestScenario: {
    type: typeof ActionTypes.requestToRemoveTestScenario;
    payload: string;
  }
  setDecisionReportId: {
    type: typeof ActionTypes.setDecisionReportId;
    payload: IState[ 'decisionReportId' ];
  }
  setSearch: {
    type: typeof ActionTypes.setSearch;
    payload: IState[ 'search' ];
  }
  requestToSetSearchAndLoadReports: {
    type: typeof ActionTypes.requestToSetSearchAndLoadReports;
    payload: IActions[ 'setSearch' ][ 'payload' ];
  }
  requestToGetReportIdFromURL: {
    type: typeof ActionTypes.requestToGetReportIdFromURL;
  }
}


export const ActionCreators: {
  [ K in keyof IActions ]: IActions[ K ] extends { payload: any }
    ? (p: IActions[ K ][ 'payload' ]) => IActions[ K ]
    : () => IActions[ K ]
} = {
  setTableCollapsed: payload => ({ type: ActionTypes.setTableCollapsed, payload }),
  // setVisibleCompletionKeys: payload => ({ type: ActionTypes.setVisibleCompletionKeys, payload }),
  // toggleVisibleCompletionKey: payload => ({ type: ActionTypes.toggleVisibleCompletionKey, payload }),
  // setCompletionHover: payload => ({ type: ActionTypes.setCompletionHover, payload }),
  // setProjectId: payload => ({ type: ActionTypes.setProjectId, payload }),
  // setReleaseId: payload => ({ type: ActionTypes.setReleaseId, payload }),
  setReportTab: payload => ({ type: ActionTypes.setReportTab, payload }),
  requestToLoadPage: payload => ({ type: ActionTypes.requestToLoadPage, payload }),
  requestToSetPerPage: payload => ({ type: ActionTypes.requestToSetPerPage, payload }),
  requestToLoadReportsForBatchTest: () => ({ type: ActionTypes.requestToLoadReportsForBatchTest }),
  requestToLoadReportsForReporting: () => ({ type: ActionTypes.requestToLoadReportsForReporting }),
  setTestRunForBatchTestReports: payload => ({ type: ActionTypes.setTestRunForBatchTestReports, payload }),
  requestToSetTestRunForBatchTestReports: payload => ({
    type: ActionTypes.requestToSetTestRunForBatchTestReports,
    payload,
  }),
  setTestScenario: payload => ({ type: ActionTypes.setTestScenario, payload }),
  requestToSetTestScenario: payload => ({
    type: ActionTypes.requestToSetTestScenario,
    payload,
  }),
  requestToLoadDecisionReport: payload => ({ type: ActionTypes.requestToLoadDecisionReport, payload }),
  requestToRemoveTestScenario: payload => ({ type: ActionTypes.requestToRemoveTestScenario, payload }),
  setDecisionReportId: payload => ({ type: ActionTypes.setDecisionReportId, payload }),
  setSearch: payload => ({ type: ActionTypes.setSearch, payload }),
  requestToSetSearchAndLoadReports: payload => ({ type: ActionTypes.requestToSetSearchAndLoadReports, payload }),
  requestToGetReportIdFromURL: () => ({ type: ActionTypes.requestToGetReportIdFromURL }),
};


export const reducer = (s = defaultState, a: IActions[ keyof IActions ] | InteractiveReduxNS.AllActions): typeof s => {
  switch (a.type) {
    case ActionTypes.setTableCollapsed:
      return {
        ...s,
        tableCollapsed: a.payload,
      };

      // case ActionTypes.setVisibleCompletionKeys:
      //   return {
      //     ...s,
      //     visibleCompletionKeys: a.payload,
      //   };


      // case ActionTypes.toggleVisibleCompletionKey:
      //   return {
      //     ...s,
      //     visibleCompletionKeys: {
      //       ...s.visibleCompletionKeys,
      //       [ a.payload ]: !s.visibleCompletionKeys[ a.payload ],
      //     },
      //   };

      // case ActionTypes.setCompletionHover:
      //   return {
      //     ...s,
      //     completion: {
      //       ...s.completion,
      //       hover: a.payload,
      //     },
      //   };

      // case ActionTypes.setProjectId:
      //   return {
      //     ...s,
      //     _DEPRECATED_projectId: a.payload,
      //   };

      // case ActionTypes.setReleaseId:
      //   return {
      //     ...s,
      //     _DEPRECATED_releaseId: a.payload,
      //   };

    case ActionTypes.setReportTab:
      return { ...s, reportTab: a.payload };

    case ActionTypes.setTestRunForBatchTestReports:
      return {
        ...s,
        testRunForBatchTestReports: a.payload,
      };

    case ActionTypes.setTestScenario:
      return {
        ...s,
        testScenario: a.payload,
      };

    case ActionTypes.setDecisionReportId:
      return {
        ...s,
        decisionReportId: a.payload,
      };

    case ActionTypes.setSearch:
      return {
        ...s,
        search: a.payload,
      };

    default:
      const interactiveState = s[ InteractiveReduxNS.REDUX_PROP ];
      const nextInteractiveState = InteractiveReduxNS.reducer(
        interactiveState,
        a as InteractiveReduxNS.AllActions,
      );

      if(interactiveState === nextInteractiveState) return s;

      return {
        ...s,
        [ InteractiveReduxNS.REDUX_PROP ]: nextInteractiveState,
      };
  }
};
