import react from "react";
import GraphNode, { GraphNodeProps } from "./GraphNode";


const GraphNodeFocusMode = (props: GraphNodeProps) =>
  <GraphNode
    {...props}
    includeContextMenu={false}
  />

export default GraphNodeFocusMode;
