import React from 'react';
import styled from 'styled-components';
import { ChevrondownIcon, ChevronupIcon, AddIcon } from '@icons';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';


const ChevronDownJSX = <ChevrondownIcon />;
const ChevronUpJSX = <ChevronupIcon />;

const AddBtn = styled(IconButton)``;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const HeadingWrap = styled.div`
  height: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  ${ AddBtn } { visibility: hidden; }
  :hover ${ AddBtn } { visibility: visible; }
`;

const Icons = styled.div`
  display: flex;
  align-items: center;
`;


export interface IProps {
  heading: string;
  content: JSX.Element;
  collapsed: boolean;
  toggleCollapsed: () => unknown;
  onAdd?: () => unknown;
  className?: string;
}

export const _: React.FC< IProps > = React.memo(p => {
  const { heading, content, toggleCollapsed, collapsed, className, onAdd } = p;
  const onAddClick: React.MouseEventHandler< HTMLButtonElement > | undefined = React.useMemo(
    () => (
      onAdd && (e => {
        e.stopPropagation();
        onAdd();
      })
    ),
    [onAdd],
  );

  return (
    <Wrap className={className}>
      <HeadingWrap onClick={toggleCollapsed}>
        <Typography variant='h6'>{heading}</Typography>

        <Icons>
          {
            onAddClick && (
              <AddBtn onClick={onAddClick}>
                <AddIcon />
              </AddBtn>
            )
          }
          { collapsed ? ChevronDownJSX : ChevronUpJSX }
        </Icons>
      </HeadingWrap>

      { collapsed ? null : content }
    </Wrap>
  );
});
_.displayName = 'components/Collapsable';
