export const themeVariants = [
  { label: "Default", value: "default" },
  { label: "Cobalt", value: "cobalt" },
  { label: "Ultraviolet", value: "ultraviolet" },
  { label: "Merlot", value: "merlot" },
] as const;

export type State = {
  theme: (typeof themeVariants)[number]["value"];
};
export const defaultState: State = {
  theme: "default",
};

export const normalizeThemeName = (v: any): State["theme"] => {
  if (typeof v !== "string") return themeVariants[0].value;

  const casted = v as State["theme"];
  switch (casted) {
    case themeVariants[1].value:
    case themeVariants[2].value:
    case themeVariants[3].value:
      return casted;
    default:
      return themeVariants[0].value;
  }
};

export const REDUX_PROP = "interview_redux";
export const getState = (s: any): State => s[REDUX_PROP];

export const aTypes = {
  setTheme: "interview_redux/setTheme",
  requestToSetTheme: "interview_redux/requestToSetTheme",
} as const;

export interface Actions {
  setTheme: {
    type: typeof aTypes.setTheme;
    payload: State["theme"];
  };
  requestToSetTheme: {
    type: typeof aTypes.requestToSetTheme;
    payload: Actions["setTheme"]["payload"];
  };
}
export type AllActions = Actions[keyof Actions];

export const aCreators: {
  [K in keyof Actions]: Actions[K] extends { payload: unknown }
    ? (p: Actions[K]["payload"]) => Actions[K]
    : () => Actions[K];
} = {
  setTheme: (p) => ({ type: aTypes.setTheme, payload: p }),
  requestToSetTheme: (p) => ({ type: aTypes.requestToSetTheme, payload: p }),
};

// biome-ignore lint: Allow early default param
export function reducer(s = defaultState, a: AllActions): typeof s {
  switch (a.type) {
    case aTypes.setTheme:
      return { ...s, theme: a.payload };
    default:
      return s;
  }
}
