import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { parse } from 'qs';
import styled from "styled-components";
import { Typography, Card } from "@material-ui/core";
import { handleAuthenticationCallback } from '@imminently/imminently_platform';
import { LoadingDots } from '@icons';

const Info = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 2rem 4rem;
`;

const useAuthState = () => {
  const dispatch = useDispatch();

  const { error, error_description, code } = parse(useLocation().search, { ignoreQueryPrefix: true });
  const {
    id_token, access_token, refresh_token, code: hash_code,
  } = parse(useLocation().hash, { ignoreQueryPrefix: true, delimiter: /[#&]/ });
  console.log('[Authcallback]', id_token, access_token, refresh_token, code, error);

  useEffect(() => {
    if (!error) {
      dispatch(handleAuthenticationCallback({
        accessToken: access_token,
        idToken: id_token,
        refreshToken: refresh_token,
        code,
        hash_code,
      }));
    }
  }, [error]);

  return { error, error_description };
};

const Authcallback = () => {
  const { error, error_description } = useAuthState();

  if (error) {
    return (
      <Info>
        <Typography component="h1" variant="h1" align="center" gutterBottom>
          Error
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          {error_description?.toString()}
        </Typography>
      </Info>
    );
  }

  return (
    <Info>
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        Signing in
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        You will be redirected to the application shortly.
      </Typography>
      <LoadingDots />
    </Info>
  );
}

export default Authcallback;


