import getBatchHook from './getBatchHook';
import { SILENT_AUTH_CHECK } from '@imminently/imminently_platform';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import get from 'lodash/get';
import find from 'lodash/find';

import {
  takeEvery,
  put,
  call,
  all,
  takeLatest
} from 'redux-saga/effects';


export const testgenCall = (release, graph, id) => ({
  type: 'DCSVLY/TESTGEN',
  release: release,
  graph: graph,
  id: id
});

const buildCases = (release, graph, attr_id) => {
  console.log('build', release, graph);
  return new Promise((res, rej) => {
    let children = graph.successors(attr_id);
    let attributes = [];
    let tests = [];
    children.forEach((child) => {
      let n = graph.node(child);
      attributes.push(child);
      // Now work out the test cases
      let cases = [];
      if (n.enum && find(release.enums, {id: n.enum})) {
        // grab the enum
        let options = find(release.enums, {id: n.enum}).options;
        options.forEach(o => {
          cases.push(o.value);
        })
      } else {
        switch (n.type) {
          case 'auto':        
          case 'text':
            cases.push('some value - ' + Math.ceil(Math.random() * 1000));
            break;
          case 'boolean':
            cases.push(true);
            cases.push(false);
            break;
          case 'currency':
          case 'number':
            cases.push(Math.ceil(Math.random() * 1000));
            break;
          default:
            console.log('unknown type', n.type, n);
            cases.push('some value');
        }
      }
      //let new_tests = cloneDeep(tests);
      let new_tests = [];
      if (tests.length === 0) {
        // No tests yet
        cases.forEach(c=>{
          let payload = {};
          payload[child] = c;
          new_tests.push(payload)
        });
      } else {
        // create more tests
        cases.forEach(c => {
          tests.forEach(t => {
            let payload = cloneDeep(t);
            payload[child] = c;
            new_tests.push(payload);
          })
        })
      }
      tests = new_tests;
    });

    res({attributes, tests});
  });
  //setAttributes(attributes);
  //setTests(tests);
  //setCustomTest(null);
}

const handleTestGen = function* (action) {
  try {
    console.log('TEST GEN!!!!!')
    yield put({
      type: 'DCSVLY/TESTGEN_START',
      release: action.release.id,
      id: action.id
    });
    // Now run this huge task

    // TODO
    let attributes = [];
    let cases = [];
    let response = yield call(buildCases, action.release, action.graph, action.id);
    yield put({
      type: 'DCSVLY/TESTGEN_SUCCESS',
      release: action.release.id,
      id: action.id,
      attributes: response.attributes,
      cases: response.tests
    });
    yield put({
      type: 'DCSVLY/TESTGEN_END',
      release: action.release.id,
      id: action.id
    });
  } catch (error) {
    yield put({
      type: 'DCSVLY/TESTGEN_END',
      release: action.release.id,
      id: action.id
    });
  }
}



export const testgenReducer = (previousState = {}, action) => {
  let new_state;
  switch (action.type) {
    case 'DCSVLY/TESTGEN_SUCCESS':

      new_state = cloneDeep(previousState);
      set(new_state, `${action.release}.${action.id}.record`, {cases: action.cases, attributes: action.attributes} );
      return new_state;
    case 'DCSVLY/TESTGEN_END':
    case 'DCSVLY/TESTGEN_ERROR':
      //let new_state = cloneDeep(previousState);
      new_state = cloneDeep(previousState);
      set(new_state, `${action.release}.${action.id}.loading`, false);
      return new_state
    case 'DCSVLY/TESTGEN_START':
      new_state = cloneDeep(previousState);
      set(new_state, `${action.release}.${action.id}.loading`, true);
      return new_state
    default:
      return previousState;
  }
}

export const testgenSaga = function* (authProvider) {
  yield all([
    takeLatest((action) => action.type === 'DCSVLY/TESTGEN', handleTestGen)
  ])
}


export { getBatchHook }
