import { useFormikContext } from "formik";
import styled from "styled-components";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { TextFieldProps } from "@material-ui/core/TextField";
import { formatUserFullName } from "@common/util";
import { Checkbox } from "@components";
import { Avatar, AvatarGroup } from "@components/avatar";
import ChipInput from "@components/chipInput";
import { Dropdown, TextField } from "@components/input";
import { Tooltip } from "@components/toolTip";
import { AssigneesData } from "@packages/commons";
import { SelectProps } from "@material-ui/core";

const Stack = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormikTextField = ({ name, label, helperText, ...props }: { name: string, label: string; helperText?: string; } & TextFieldProps) => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext<any>();
  return (
    <TextField
      name={name}
      value={values[name]}
      label={label}
      fullWidth
      helperText={(touched[name] && (errors[name] as string)) ?? helperText}
      error={touched[name] && Boolean(errors[name])}
      onChange={handleChange}
      onBlur={handleBlur}
      {...props}
    />
  );
};

type FormikSelectProps = {
  name: string;
  label: string;
  options: { value: string; key: string; }[];
} & SelectProps;

export const FormikSelect = ({ name, label, options, ...props }: FormikSelectProps) => {
  const { values, handleChange, errors } = useFormikContext<any>();
  const error = errors[name];
  return (
    <Dropdown
      fullWidth
      name={name}
      label={label}
      value={values[name]}
      items={options}
      onChange={handleChange}
      helperText={Boolean(error) ? error : undefined}
      {...props}
    />
  )
};

export const FormikCheckbox = ({ name, label }: { name: string; label: string; }) => {
  const { values, handleChange } = useFormikContext<any>();
  return (
    <FormControlLabel
      control={(
        <Checkbox
          name={name}
          checked={values[name]}
          onChange={handleChange}
          style={{ marginRight: '1rem' }}
        />
      )}
      label={label}
      style={{ margin: 0 }}
    />
  );
};

export const FormikChipInput = ({ name, label }: { name: string; label: string; }) => {
  const { values, setFieldValue } = useFormikContext<any>();
  return (
    <ChipInput
      fullWidth
      // @ts-ignore it is defined. Why do you forsake me TS
      color="secondary"
      name={name}
      label={label}
      defaultValue={values[name]}
      onChange={(tags) => setFieldValue(name, tags)}
      blurBehavior='add'
    />
  );
}

const assigneesTooltip = (assignees: AssigneesData[]) => (
  <Stack>
    {assignees.map((assignee) => <span key={assignee.id}>{assignee.name ?? formatUserFullName(assignee)}</span>)}
  </Stack>
);

export const AssigneeGroup = ({ users, max = 3 }: { users: AssigneesData[]; max?: number }) => (
  users && users.length > 0 ?
    (
      <Tooltip title={assigneesTooltip(users)}>
        <AvatarGroup max={max} size="small">
          {
            users.map((user) => (
              <Avatar key={user.id} src={user.avatar ?? "/"} alt={user.firstName} size="small" />
            ))
          }
        </AvatarGroup>
      </Tooltip>
    )
    : "No members assigned"
);

// export const FormikAssignees = ({ name, label }: { name: string; label: string; }) => {
//   const { values, touched, errors, handleChange, handleBlur } = useFormikContext<any>();
//   const users = values[name] ?? [];
//   return (
//     <UserSelect value={values.users} onChange={handleChange}>
//       {

//       }
//     </UserSelect>
//   );
// };