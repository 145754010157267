import React, { useCallback, useMemo, useState } from "react";
import { defaultPath, init, Session, SessionConfig, SessionInstance } from "@decisively-io/interview-sdk";
import { config } from "../../config";
import { useRaf } from "react-use";
import { useGetter } from "@common/hooks/HooksGeneral";


function flattenPrevVisitableSteps(s: Session[ "steps" ][ 0 ]): Array<typeof s> {
  const returnPrefix = s.skipped || s.visitable === false || (s.visited === false && s.current !== true)
    ? []
    : [ s ];
  if (s.steps === undefined || s.steps.length === 0) return returnPrefix;

  return returnPrefix.concat(...s.steps.map(it => flattenPrevVisitableSteps(it)));
}

const calculatePrev = (s: Session): Session[ "steps" ][0] | null => {
  const { steps, screen: { id } } = s;

  const stepsInOrder = flattenPrevVisitableSteps({
    id: "",
    complete: false,
    context: { entity: "" },
    current: false,
    skipped: false,
    title: "",
    visitable: false,
    visited: false,
    steps: steps,
  });
  const maybeCurrentStep = stepsInOrder.find(it => it.id === id);
  if (maybeCurrentStep === undefined) return null;

  const maybeCurrentStepIndx = stepsInOrder.indexOf(maybeCurrentStep);
  const prevStepIndx = maybeCurrentStepIndx - 1;

  return stepsInOrder[prevStepIndx] || null;

};


const host = config.BACKEND_URL as string;

const provider = init(host, defaultPath, {
  transformRequest: [ (data, headers) => {
    const endpoint = config.AUTH_ENDPOINT;
    const clientId = config.AUTH_CLIENT_ID;
    const isB2C = config.AUTH_IS_B2C === "true";

    const key = isB2C ? `oidc.user:${endpoint}:${clientId}` : `oidc.user:${endpoint}/v2.0:${clientId}`;
    const user = JSON.parse(sessionStorage.getItem(key) ?? "{}");
    if (user && headers) {
      // eslint-disable-next-line no-param-reassign
      headers.Authorization = `Bearer ${isB2C ? user.id_token : user.access_token}`;
      const tenancy = localStorage.getItem("decisively.tenant");
      // eslint-disable-next-line no-param-reassign
      headers["X-TENANCY"] = tenancy || "";
    }

    console.log("transform request", data, headers);

    return JSON.stringify(data);
  } ],
});

export interface InterviewHook {
  session: SessionInstance | null,
  startSession: () => Promise<SessionInstance>;
  setSession: (s: SessionInstance) => void;
  reset: () => void;
  resetState: { type: "resetting" | "idle" },
}

export const useInterview = (project: string, config: SessionConfig): InterviewHook => {

  const [ session, setSession ] = useState<SessionInstance | null>(null);
  const [ forceUpdate, setForceUpdate ] = useState(0);

  /** HACK-y way to determine when our session is being recreated on BE */
  const [ resetState, setResetState ] = React.useState<InterviewHook[ "resetState" ]>({ type: "idle" });

  const getProject = useGetter(project);
  const getConfig = useGetter(config);

  const startSession = useCallback(async () => {
      // console.log('getSession', project, config);
      const res = await provider.create(getProject(), getConfig(), () => {
        setForceUpdate(s => s + 1)
      });
      // console.log('getSession result', res);
      setSession(res);
      setResetState({ type: "idle" });
      return res;
  }, [ ]);


  const reset = () => {
    // console.log('reset session');
    setSession(null);
    provider.finish();

    setResetState({ type: "resetting" });
    startSession();


  };

  return {
    session,
    startSession,
    setSession,
    reset,
    resetState,
  };
};
