import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import styled from 'styled-components';
import { getSCTheme } from '@common';
import * as ctxNS from '../ctx';
import { WithId } from './__withId';


export type PropsWithClsnm = WithId & {
  // eslint-disable-next-line react/require-default-props
  className?: string;
}

export const CollapseToggle: React.FC< PropsWithClsnm > = React.memo(({ id, className }) => {
  const { idToItem, expanded, toggleExpanded } = ctxNS.useDottedTreeCtx();
  const item = idToItem[ id ];

  return React.useMemo(() => (
    item.children.length === 0 ? null : (
      <IconButton
        onClick={e => {
          e.stopPropagation();
          toggleExpanded(id);
        }}
        className={className}
      >
        { expanded.some(it => it === id) ? <ExpandLess /> : <ExpandMore />}
      </IconButton>
    )
  ), [id, expanded, item, className, toggleExpanded]);
});
CollapseToggle.displayName = 'components/DottedTree/LabelComponents/CollapseToggle';


// ===================================================================================


export type VerifyTextsProps = {
  heading: string;
  desc: React.ReactNode;
  className?: string;
}


const VerifyTextsHeading = styled(Typography)`
  font-weight: bold;
`;

export const VerifyTextsWrapClassnames = {
  selected: 'selected',
  emphasized: 'emphasized',
};


const VerifyTextsWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: ${ p => getSCTheme(p).palette.secondary.dark };
  opacity: 0.2;

  &.${ VerifyTextsWrapClassnames.selected } { color: ${ p => getSCTheme(p).palette.secondary.main }; }
  &.${ VerifyTextsWrapClassnames.emphasized } { opacity: 1; }
`;


export const VerifyTexts: React.FC< VerifyTextsProps > = React.memo(p => {
  const { className, heading, desc } = p;

  return (
    <VerifyTextsWrap className={className}>
      <VerifyTextsHeading variant='caption'>
        {heading}
      </VerifyTextsHeading>

      { desc }
    </VerifyTextsWrap>
  );
});
VerifyTexts.displayName = 'components/Dottedtree/LabelComponentParts/VerifiedTexts';
