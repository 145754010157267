import { useEffect } from 'react';
import { useZoomPanHelper } from 'react-flow-renderer';

export const AutoFitGraph = () => {
  const { fitView } = useZoomPanHelper();
  useEffect(() => {
    fitView();
  }, [fitView]);

  return null;
};
