import { createPluginFactory } from "@udecode/plate";
import { Transforms } from "slate";

const deserialize = (el: HTMLElement): any => {
  console.log(el);
};

export const createCopyPastePlugin = createPluginFactory({
  key: "copy-paste",
  withOverrides: (editor) => {
    const { insertData } = editor;
    editor.insertData = (data) => {
      const html = data.getData("text/html");

      if (html) {
        const parsed = new DOMParser().parseFromString(html, "text/html");
        const fragment = deserialize(parsed.body);
        if (fragment) {
          Transforms.insertFragment(editor as any, fragment);
          return;
        }
      }

      insertData(data);
    };
    return editor;
  },
});
