import React from "react";
import { ReleaseTestCasesNS } from '@packages/commons';
import { v4 as uuid } from 'uuid';
import styled from "styled-components";
import Typography, { TypographyProps } from "@material-ui/core/Typography";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import Chip from "@material-ui/core/Chip";
import { CloseIcon, TestingIcon, AddIcon, FolderIcon } from "@icons";
import { scrollable } from "@common/scrollbar";
import { useTestCasesRootCtx } from "@components/TestCasesTree/RootCtx";
import { derivePathState } from "./helpers";
import { useOpenSaveTestCaseAsModal } from '../SaveAsModalContents';
import produce from "immer";
import { useOpenPathDropdown } from "./useOpenPathDropdown";


//# region RootFolder

const RootFolderIconButton = styled( IconButton )`
  box-sizing: border-box;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.25rem;

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export type RootFolderProps = {
  onClick: NonNullable< IconButtonProps[ 'onClick' ] >;
}
export const RootFolder: React.FC< RootFolderProps > = React.memo( p => {
  return (
    <RootFolderIconButton onClick={p.onClick}>
      <FolderIcon />
    </RootFolderIconButton>
  );
});
RootFolder.displayName = 'TestCasesTree/Comp/Path/RootFolder';

//# endregion

//# region SimpleText

const SimpleTextTypography = styled( Typography )`
  text-decoration: underline;
  cursor: pointer;
  white-space: nowrap;
`;

export type SimpleTextProps = {
  text: string;
  id: ReleaseTestCasesNS.TestCaseTItem[ 'id' ];
}
export const SimpleText: React.FC< SimpleTextProps > = React.memo( ({ id, text }) => {
  const openPathDropdown = useOpenPathDropdown();
  const onClick = React.useCallback< NonNullable< TypographyProps[ 'onClick' ] > >((e) => {
    const { bottom, left } = e.currentTarget.getBoundingClientRect();

    openPathDropdown({
      compRootId: id,
      leftCoordinate: left,
      topCoordinate: bottom,
      isOpenedInCtxMenu: true,
    });
  }, [openPathDropdown, id]);

  return (
    <SimpleTextTypography onClick={onClick}>
      { text }
    </SimpleTextTypography>
  );
});
SimpleText.displayName = 'TestCasesTree/Comp/Path/SimpleText';

//# endregion


//# region PathChip

const PathChipC = styled( Chip )`
  gap: 0.5rem;
  background-color: ${ p => p.theme.palette.core.prayingMantis10 };
  padding: 0.25rem 0.75rem;

  &:focus {
    background-color: ${ p => p.theme.palette.core.prayingMantis10 };
  }

  &.withAdd {
    background-color: ${ p => p.theme.palette.secondary.light };

    &:focus {
      background-color: ${ p => p.theme.palette.secondary.light };
    }
  }
`;
const PathChipContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    color: ${ p => p.theme.palette.core.kindaBlack };
    width: 1rem;
    height: 1rem;
  }
`;
const PathChipTestingIcon = styled( TestingIcon )`
  width: 1.25rem;
  height: 1.25rem;
`;
const PathChipIconButton = styled( IconButton )`
  box-sizing: border-box;
  width: 1.25rem;
  height: 1.25rem;
`;

export type PathChipProps = {
  text: string;
  id: string;
  variant: (
    | { type: 'withDelete'; onDelete: (id: string) => unknown }
    | {
      type: 'withAdd';
      onAdd: (id: string) => unknown
      /**
       * if goalId in debugger is not yet selected - we don't\
       * want to allow clicking "add"
       */
      disabled?: boolean;
    }
  );
}
export const PathChip: React.FC< PathChipProps > = React.memo( p => {
  const { id, text, variant } = p;
  const onIconBtnClick = React.useCallback( () => {
    if( variant.type === 'withAdd' ) variant.onAdd( id );
    else variant.onDelete( id );
  }, [ id, variant ]);


  return (
    <PathChipC
      clickable={ variant.type === 'withAdd' }
      onClick={ variant.type === 'withAdd' && Boolean(variant.disabled) === false ? onIconBtnClick : undefined }
      className={ variant.type === 'withAdd' ? 'withAdd' : undefined }
      label={ (
        <PathChipContent>
          <PathChipTestingIcon />

          <Typography variant='caption'>
            { text }
          </Typography>

          {
            variant.type === 'withAdd' ? <AddIcon /> : (
              <PathChipIconButton onClick={ onIconBtnClick }>
                <CloseIcon />
              </PathChipIconButton>
            )
          }
        </PathChipContent>
      )}
      disabled={ variant.type === 'withAdd' && variant.disabled }
    />
  );
});

//# endregion

const Wrap = styled.div`
  display: flex;
  gap: 0.5rem;

  &::-webkit-scrollbar {
    width: 0.125rem;
    height: 0.125rem;
  }

  &::-webkit-scrollbar-thumb {
    border: none;
  }
`;

export type PathProps = {
  items: Array<{
    id: string;
    node: React.ReactNode;
  }>
}
export const Path: React.FC< PathProps > = React.memo(({ items }) => {
  const mapped = React.useMemo< typeof items >(
    () => items.reduce< typeof items >(
      ( a, it, i ) => (
        a
          .concat( it )
          .concat( ( i === items.length - 1 ) ? [] : ({
            id: `${ it.id }_slash`,
            node: <Typography>/</Typography>
          }))
      ),
      []
    ),
    [ items ]
  );

  return (
    <Wrap className={scrollable}>
      { mapped.map(it => (
        <React.Fragment key={ it.id }>
          { it.node }
        </React.Fragment>
      ))}
    </Wrap>
  );
});
Path.displayName = 'components/TestCasesTree/Comp/Path';


//# region ConnectedPath
/**
 * this will be a wrapper around Path component, that will have\
 * all necessary information to properly render our current state:\
 * if we have nothing selected and need to show just the root\
 * folder and "create" button, if we have something selected and\
 * need to show path to it with the ability to deselect, etc
 */
export const ConnectedPath = React.memo(() => {
  const { value: { selectedId, tree, goalId }, setValue, valueRef } = useTestCasesRootCtx();
  const openSaveAsModal = useOpenSaveTestCaseAsModal();
  const openPathDropdown = useOpenPathDropdown();

  const items = React.useMemo< PathProps[ 'items' ] >(() => {
    const pathRez = derivePathState({ selectedId, tree });
    const rootItem: PathProps[ 'items' ][ 0 ] = {
      id: '',
      node: <RootFolder
        onClick={ e => {
          const { bottom, left } = e.currentTarget.getBoundingClientRect();
          openPathDropdown({
            compRootId: '',
            leftCoordinate: left,
            topCoordinate: bottom,
            isOpenedInCtxMenu: true,
          });
        } }
      />
    };

    if( pathRez.type === 'unselected' ) {
      return [
        rootItem,
        {
          id: '1',
          node: <PathChip
            id="addTestCase"
            text="Create test case"
            variant={{
              type: 'withAdd',
              onAdd: () => openSaveAsModal({
                mode: 'folderSelect',
                initialItemToAdd: {
                  id: uuid(),
                  title: '',
                  type: 'payload',
                  data: valueRef.current.actions.getDebuggerPayoad(),
                  goal: goalId,
                }
              }),
            }}
          />
        }
      ]
    }

    return [rootItem].concat(
      pathRez.pathToId.slice(1).map( (it, i, arr) => ({
        id: it.id,
        node: (i === arr.length - 1)
          ? (
            <PathChip
              id={it.id}
              text={it.title}
              variant={{ type: 'withDelete', onDelete: () => {
                setValue(prev => produce(prev, draft => {
                  draft.selectedId = null;
                }));
              } }}
            />
          )
          : (
            <SimpleText
              text={it.title}
              id={it.id}
            />
          )
      }))
    );
  }, [selectedId, tree, setValue, openSaveAsModal, openPathDropdown, goalId]);


  return (
    <Path items={items} />
  );
});
ConnectedPath.displayName = 'TestCasesTree/CompV2/Path/ConnectedPath';

//# endregion
