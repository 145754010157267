import { scrollable, scrollableMixin } from "@common";
import {
  PortalBody,
  comboboxActions,
  comboboxSelectors,
  getComboboxStoreById,
  getComboboxStyles,
  useActiveComboboxStore,
  useComboboxControls,
  useComboboxSelectors,
} from "@udecode/plate";
import { isDefined, useEditorState, useEventEditorSelectors } from "@udecode/plate-core";
import { flip, getRangeBoundingClientRect, offset, shift, useVirtualFloating } from "@udecode/plate-floating";
import React, { useCallback, useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  max-height: 16rem;
  min-height: 16rem;
  ${(p) => p.css};

  > ul {
    padding: 0;
    margin: 0;
    width: 50%;
  }
`;

const ListItem = styled.li`
  ${(p) => p.css}
`;

const Description = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  background-color: ${(p) => p.theme.palette.background.light};
  width: 16rem;
  ${scrollableMixin};
`;

const ComboboxContent = (props) => {
  const { component: Component, items, onRenderItem, onRenderDetail } = props;

  const targetRange = useComboboxSelectors.targetRange();
  const filteredItems = useComboboxSelectors.filteredItems();
  const highlightedIndex = useComboboxSelectors.highlightedIndex();
  const floatingOptions = useComboboxSelectors.floatingOptions();

  const editor = useEditorState();
  const combobox = useComboboxControls();
  const activeComboboxStore = useActiveComboboxStore();
  const text = useComboboxSelectors.text();
  const storeItems = useComboboxSelectors.items();
  const filter = activeComboboxStore.use.filter?.();
  const maxSuggestions = activeComboboxStore.use.maxSuggestions?.() ?? storeItems.length;

  // const popperRef = React.useRef(null);

  // Update items
  useEffect(() => {
    if (items) comboboxActions.items(items);
  }, [items]);

  // Filter items
  useEffect(() => {
    if (!isDefined(text)) return;

    if (text.length === 0) {
      return comboboxActions.filteredItems(storeItems.slice(0, maxSuggestions));
    }

    const _filteredItems = storeItems
      .filter(filter ? filter(text) : (value) => value.text.toLowerCase().indexOf(text.toLowerCase()) !== -1)
      .slice(0, maxSuggestions);

    comboboxActions.filteredItems(_filteredItems);
  }, [filter, storeItems, maxSuggestions, text]);

  // Get target range rect
  const getBoundingClientRect = useCallback(
    () => getRangeBoundingClientRect(editor, targetRange),
    [editor, targetRange],
  );

  // Update popper position
  const { style, floating } = useVirtualFloating({
    placement: "bottom-start",
    getBoundingClientRect,
    middleware: [offset(4), shift(), flip()],
    ...floatingOptions,
  });

  const menuProps = combobox ? combobox.getMenuProps({}, { suppressRefError: true }) : { ref: null };

  const { root, item: styleItem, highlightedItem } = getComboboxStyles(props);

  const setHighlighted = (index) => {
    comboboxActions.highlightedIndex(index);
  };

  const itemHighlighted = filteredItems[highlightedIndex];

  return (
    <PortalBody>
      <Container
        ref={floating}
        style={style}
        css={root.css}
      >
        <ul
          {...menuProps}
          className={scrollable}
        >
          {Component ? Component({ store: activeComboboxStore }) : null}

          {filteredItems.map((item, index) => {
            const Item = onRenderItem ? onRenderItem({ item }) : item.text;

            const highlighted = index === highlightedIndex;

            return (
              <ListItem
                key={item.key}
                css={highlighted ? highlightedItem?.css : styleItem?.css}
                data-highlighted={highlighted}
                {...combobox.getItemProps({
                  item,
                  index,
                })}
                onMouseEnter={() => setHighlighted(index)}
                onMouseDown={(e) => {
                  e.preventDefault();

                  const onSelectItem = getComboboxStoreById(comboboxSelectors.activeId())?.get.onSelectItem();
                  onSelectItem?.(editor, item);
                }}
              >
                {Item}
              </ListItem>
            );
          })}
        </ul>
        {onRenderDetail && itemHighlighted && <Description>{onRenderDetail(itemHighlighted)}</Description>}
      </Container>
    </PortalBody>
  );
};

/**
 * Register the combobox id, trigger, onSelectItem
 * Renders the combobox if active.
 */
export const TwoPaneCombobox = ({
  id,
  trigger,
  searchPattern,
  onSelectItem,
  controlled,
  maxSuggestions,
  filter,
  ...props
}) => {
  const editor = useEditorState();
  const focusedEditorId = useEventEditorSelectors.focus?.();
  const combobox = useComboboxControls();
  const activeId = useComboboxSelectors.activeId();

  useEffect(() => {
    comboboxActions.setComboboxById({
      id,
      trigger,
      searchPattern,
      controlled,
      onSelectItem,
      maxSuggestions,
      filter,
    });
  }, [id, trigger, searchPattern, controlled, onSelectItem, maxSuggestions, filter]);

  if (!combobox || !editor.selection || focusedEditorId !== editor.id || activeId !== id) {
    return null;
  }

  return <ComboboxContent {...props} />;
};
