import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { TwoPaneDottedTree, type TwoPaneDottedTreeProps } from '@components/TwoPaneDottedTree';
import styled from 'styled-components';
import { __plusOneNestingLevelId } from '@components/TwoPaneDottedTree/Right';
import { PrimaryButton } from '@components/buttons';


const StyledTree = styled(TwoPaneDottedTree)`
  outline: 1px solid orange;
`;

export const JSX = React.createElement(() => {
  const [ open, setOpen ] = React.useState(false);
  const [ leftExpanded, leftSetExpanded ] = React.useState< TwoPaneDottedTreeProps[ 'leftExpanded' ] >( [] );
  const [ leftSelectedId, leftSetSelectedId ] = React.useState< TwoPaneDottedTreeProps[ 'leftSelectedId' ] >( null );

  return (
    <div style={{ margin: 16 }}>
      <PrimaryButton onClick={React.useCallback(() => setOpen(true), [])}>
        Show TwoPaneDottedTree
      </PrimaryButton>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          style: {
            padding: '1rem',
            width: '90vw',
            height: '90vh',
          }
        }}
        maxWidth={false}
      >
        <StyledTree
          leftItems={[
            {
              id: 'global',
              info: { label: 'Global' },
              children: [
                {
                  id: 'students',
                  info: { label: 'Students' },
                  children: [
                    {
                      id: 'students_1',
                      info: { label: '1' },
                      children: [],
                    },
                    {
                      id: 'students_2',
                      info: { label: '2' },
                      children: [],
                    },
                  ]
                }
              ]
            }
          ]}
          idToRightItems={{
            global: [
              {
                id: 'global_left_operand',
                info: { label: 'Left operand', value: 0 },
                children: [],
              },
              {
                id: 'global_right_operand',
                info: { label: 'Right operand', value: 0 },
                children: [],
              },
            ],
            students_1: [
              {
                id: 'students_1_first_name',
                info: { label: 'First name', value: 0 },
                children: [],
              },
              {
                id: 'students_1_last_name',
                info: { label: 'Last name', value: 0 },
                children: [],
              },
            ],
            students_2: [
              {
                id: 'students_2_first_name',
                info: { label: 'First name', value: 0 },
                children: [],
              },
              {
                id: 'students_2_last_name',
                info: { label: 'Last name', value: 0 },
                children: [],
              },
            ],
          }}
          LabelCompForRight={({ id }) => (
            <div>{ id }</div>
          )}
          onSelectRight={ console.info }
          leftExpanded={ leftExpanded }
          leftSetExpanded={ leftSetExpanded }
          leftSelectedId={ leftSelectedId }
          leftSetSelectedId={ leftSetSelectedId }
          hideLeftPane={ false }
        />
      </Dialog>
    </div>
  );
});
