import { ELEMENT_RULE } from "../elements";
import type { RuleType, TRule } from "./rule.types";

// export const createRule = (type: RuleType, label = "", text = "") => ({
//   type: ELEMENT_RULE,
//   children: [
//     // {
//     //   type: ELEMENT_LABEL,
//     //   children: [
//     //     {
//     //       text: label,
//     //     },
//     //   ],
//     // },
//     {
//       type,
//       children: [
//         {
//           text: text,
//         },
//       ],
//     },
//   ],
// }) as TRule;

export const createRule = (type: RuleType, text = "") =>
  ({
    type: ELEMENT_RULE,
    expression: type,
    children: [{ text }],
  }) as TRule;
