import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import { TextField, PrimaryButton, Dropdown } from '@components';
import { useForm, Controller } from 'react-hook-form';
import { LoadingDotsJSX } from '@icons';
import { LOCALE_OPTIONS } from '@modals/ProjectModal_localeOptions';


export type Props = {
  title: string;
  close: () => void;
  data?: {
    description: string;
    locale: string;
  };
  submitData: (v: NonNullable< Props[ 'data' ] >) => Promise< void >;
}

const defaultData: Props['data'] = {
  description: '',
  locale: '',
};


export const _: React.FC< Props > = React.memo(p => {
  const { title, close, data = defaultData, submitData } = p;
  const { control, handleSubmit, formState: { isSubmitting } } = useForm< typeof data >({
    defaultValues: data,
  });

  const onSubmit = React.useMemo(
    () => handleSubmit(submitData),
    [handleSubmit, submitData],
  );

  return (
    <Box padding='2rem' display='flex' flexDirection='column' gridGap='1.5rem' width='30rem'>
      <Box display='flex' justifyContent='space-between' gridGap='2rem'>
        <Typography variant='h3'>{title}</Typography>

        <IconButton onClick={close}>
          <Close />
        </IconButton>
      </Box>

      <form onSubmit={onSubmit}>
        <Box display='flex' flexDirection='column' gridGap='0.5rem'>
          <Controller
            control={control}
            name='description'
            render={({ field: { value, onChange } }) => (
              <TextField
                value={value || ''}
                onChange={onChange}
                label='Description'
              />
            )}
          />

          <Controller
            control={control}
            name='locale'
            render={({ field: { value, onChange } }) => (
              <Dropdown
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                value={value}
                // value={LOCALE_OPTIONS.find(it => it.value === value) || { key: value, value }}
                items={LOCALE_OPTIONS}
                onChange={e => onChange(e.target.value)}
              />
            )}
          />


          <Box display='flex' justifyContent='flex-end'>
            <PrimaryButton type='submit' disabled={isSubmitting}>
              { isSubmitting ? LoadingDotsJSX : 'Submit' }
            </PrimaryButton>
          </Box>
        </Box>
      </form>
    </Box>
  );
});
_.displayName = 'ProjectsReleaseCenter/ManageReleaseModal';
