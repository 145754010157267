import {
  ELEMENT_HR,
  ELEMENT_PARAGRAPH,
  type PlateEditor,
  type TNode,
  createAutoformatPlugin,
  createHorizontalRulePlugin,
  createPluginFactory,
  createSelectOnBackspacePlugin,
  getNode,
  insertNodes,
  moveNodes,
  setNodes,
} from "@udecode/plate";

import { ELEMENT_CONDITION, ELEMENT_OPERATOR } from "../../components";

import { type NodeEntry, Path } from "slate";
import { insertHR, isLastChild, withHotKeys } from "../../plugins/util";
import { createRule } from "../rule/createRule";

export const insertRuleBreak = (editor: PlateEditor, path: Path) => {
  // we are text or a hr
  const node = getNode(editor, path);
  // calcuate this first before any transforms
  const isLast = isLastChild(editor, path);
  console.log("rule break?", node, path, editor.selection);

  // insert as the next node regardless
  const hrPath = Path.next(path);
  insertHR(editor, hrPath);

  // if parent exists, is operator, and we are last node, move outside
  const parentPath = Path.parent(path);
  const parent = getNode(editor, parentPath);
  if (parent && parent.type === ELEMENT_OPERATOR && isLast) {
    // move next to operator
    const nextPath = Path.next(parentPath);
    moveNodes(editor, { at: hrPath, to: nextPath });
  }

  return true;
};

export const createRuleBreakPlugin = createPluginFactory({
  key: "rule-break",
  isVoid: true,
  plugins: [
    createHorizontalRulePlugin({
      ...withHotKeys([
        {
          keys: "mod+shift+enter",
          action: (editor, plugin, nodeEntry: NodeEntry<TNode>) => {
            const [, path] = nodeEntry;
            return insertRuleBreak(editor, path);
          },
        },
        {
          keys: "enter",
          action: (editor, plugin, nodeEntry: NodeEntry<TNode>) => {
            const [node, path] = nodeEntry;
            if (node.type === ELEMENT_HR) {
              insertNodes(editor, createRule(ELEMENT_CONDITION), { at: Path.next(path), select: true });
              return true;
            }
            return false;
          },
        },
      ]),
    }),
    createSelectOnBackspacePlugin({
      options: { query: { allow: [ELEMENT_HR] } },
    }),
    createAutoformatPlugin({
      options: {
        rules: [
          {
            mode: "block",
            type: ELEMENT_HR,
            match: ["---", "—-", "___ "],
            format: (editor) => {
              setNodes(editor, { type: ELEMENT_HR });
              insertNodes(editor, {
                type: ELEMENT_PARAGRAPH,
                children: [{ text: "" }],
              });
            },
          },
        ],
      },
    }),
  ],
});
