import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import { Avatar } from '@components';
import { ModalActions, ModalFields, showModal, useServiceForm } from '@modals';
import { Document } from "@packages/commons";
import { documentService, useUser } from "services";
import { useNotify } from "@common/hooks/HooksNotification";
import { FormikContext } from "formik";
import { Box, Tooltip, Typography } from "@material-ui/core";
import styled from "styled-components";
import { autoFormatIgnoreTimezone } from "@common";
import { useFullReleaseStrict } from "@common/hooks_useFullRelease";
import { get } from "lodash";
import { ProjectWriterGuard } from "auth/ComponentGuard";
import { PropsWithChildren } from "react";
import { FormikTextField } from "@components/forms/FormikControls";
import { Trans, useTranslation } from "react-i18next";

const SystemMeta = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > * {
    flex: 1;
  }
`;

const MetadataSchema = Yup.object().shape({
  source: Yup.string(),
  dateOfIssuance: Yup.string(),
  notes: Yup.string(),
  documentType: Yup.string(),
  jurisdiction: Yup.string(),
  status: Yup.string(),
  language: Yup.string(),
  confidentiality: Yup.string(),
});

// try pull from doc, otherwise fallback to empty string
const initialMetadata = (document: Document) => ({
  source: document.metadata?.source ?? '',
  dateOfIssuance: document.metadata?.dateOfIssuance ?? '',
  notes: document.metadata?.notes ?? '',
  documentType: document.metadata?.documentType ?? '',
  jurisdiction: document.metadata?.jurisdiction ?? '',
  status: document.metadata?.status ?? '',
  language: document.metadata?.language ?? '',
  confidentiality: document.metadata?.confidentiality ?? '',
});

const UserCard = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.5rem;
`;

const UserInfoCard = ({ name, children }: { name?: string; } & PropsWithChildren) => {
  return (
    <UserCard>
      <Tooltip title={name ?? ""}>
        <Avatar alt={name} src="/" size="medium" />
      </Tooltip>
      <Box display="flex" flexDirection="column">
        {children}
      </Box>
    </UserCard>
  );
};

const useLockState = (name: string) => {
  const user = useUser();
  // in order for us to have opened the document, the release has to already exist, so pull from there
  const release = useFullReleaseStrict();
  const path = name.split('/').filter(Boolean);
  const document = get(release.documents, path);
  return {
    lock: document?.locked,
    isLocked: document?.locked !== undefined,
    lockedByMe: document?.locked?.identity_id === user.id,
  };
};

const DocumentMetadataModal = ({ document, ...props }: { document: Document }) => {
  // @ts-ignore close comes from the modal layout, need to figure out TS definitions
  const { close } = props;
  const notify = useNotify();
  const { t } = useTranslation();
  const { isLocked, lockedByMe } = useLockState(document.name);
  const formik = useServiceForm({
    service: documentService,
    resource: document,
    formikValues: {
      initialValues: initialMetadata(document),
      validationSchema: MetadataSchema,
    },
    // map into metadata object with empty string values removed
    preSubmit: (values) => ({ metadata: Object.fromEntries(Object.entries(values).filter(([k, v]) => v !== '')) }),
  });

  const { submitForm, isSubmitting } = formik;
  let modalActions = {
    primary: {
      name: t('update'), // can only ever be update
      onClick: () => {
        submitForm()
          .catch(e => {
            console.error(e);
            notify.error(t('errors.failed_update_metadata'));
          })
          .finally(close);
      },
      disabled: isSubmitting,
      loading: isSubmitting,
    }
  };

  const readOnly = isLocked && !lockedByMe;

  return (
    <>
      <ModalFields>
        <SystemMeta>
          <UserInfoCard name={document.createdByName}>
            <Typography variant="h6"><Trans i18nKey="created_at"></Trans></Typography>
            <Typography variant="caption">{autoFormatIgnoreTimezone(document.created)}</Typography>
          </UserInfoCard>
          <UserInfoCard name={document.updatedByName}>
            <Typography variant="h6"><Trans i18nKey="updated_at"></Trans></Typography>
            <Typography variant="caption">{autoFormatIgnoreTimezone(document.lastModified)}</Typography>
          </UserInfoCard>
        </SystemMeta>
        <FormikContext.Provider value={formik}>
          <FormikTextField disabled={readOnly} name="source" label={t('metadata.source')} helperText={t('metadata.source_description')} />
          <FormikTextField disabled={readOnly} name="dateOfIssuance" label={t('metadata.date_of_issuance')} helperText={t('metadata.date_of_issuance_description')} />
          <FormikTextField disabled={readOnly} name="documentType" label={t('metadata.document_type')} />
          <FormikTextField disabled={readOnly} name="jurisdiction" label={t('metadata.jurisdiction')} />
          <FormikTextField disabled={readOnly} name="status" label={t('metadata.status')} />
          <FormikTextField disabled={readOnly} name="language" label={t('metadata.language')} />
          <FormikTextField disabled={readOnly} name="confidentiality" label={t('metadata.confidentiality')} />
          <FormikTextField disabled={readOnly} name="notes" label={t('metadata.notes')} multiline minRows={3} />
        </FormikContext.Provider>
      </ModalFields>
      <ProjectWriterGuard>
        <ModalActions {...modalActions} />
      </ProjectWriterGuard>
    </>
  );
};

export const useDocumentMetadataModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return async (documentId: string) => {
    const document = await documentService.getOne(documentId);
    dispatch(showModal({
      open: true,
      title: t('metadata.metadata_title'),
      maxWidth: 'md',
    }, <DocumentMetadataModal document={document as Document} />,));
  };
}