import type { TElement, TText } from "@udecode/plate-core";

export const getSourceText = (el: string | TText | TElement | TElement[]) => {
  if (!el) return "";
  if (typeof el === "string") {
    return el;
  }
  if ((el as TText).text) {
    return (el as TText).text;
  }

  // should be a TElement or TElement[]
  let list: any[] = [];
  if (Array.isArray(el)) {
    list = el;
  } else if (el.children) {
    list = (el as TElement).children;
  }
  const response: string[] = [];
  for (const child of list) {
    const value = getSourceText(child);
    if (value) response.push(value);
  }
  return response.join("\n");
};
