import type { Attribute } from "@packages/compiler";
import type { TElement } from "@udecode/plate-core";

//# region Api Response processing

export type SuccessAiResponse = {
  suggestion: Record<string, unknown>;
  newAttributes: Attribute[];
};
export const isSuccessAiResponse = (v: unknown): v is SuccessAiResponse => {
  if (typeof v !== "object" || v === null) return false;

  const typecasted = v as SuccessAiResponse;
  return typeof typecasted.suggestion === "object" && typecasted.suggestion !== null;
};

export const getRulesFromSuccessAiResponse = (v?: SuccessAiResponse): unknown[] =>
  v === undefined ? [] : Array.isArray(v.suggestion) ? v.suggestion : [v.suggestion];

export type ResponseState =
  | { type: "initial" }
  | {
      type: "success";
      value: SuccessAiResponse;
      rules: TElement[];
      /** HACk-y way to make plate provider reflect changed rules */
      key: string;
    }
  | { type: "error" };

//# endregion

export const SUGGEST_RULES_EDITOR_ID = "source-suggest-rules-WGz2V";
