import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import {
  ReactFlowProvider,
} from "react-flow-renderer";
import type {
  Elements,
} from "./types";
import * as DatesFilterNS from "./DatesFilter";
import {
  MsgFromWorker,
  MsgToWorker,
} from "./worker/types";
import { LoadingDotsJSX } from "@icons";
import { defaultTypeFunc } from "./__typeFunc";
import { collectPredecessorsOrSuccessors, useGraphVisualisation } from "@components/GraphVisualisation/hooks/useGraphVisualisation";
import { useFullRelease } from "@common/hooks_useFullRelease";
import { RawRuleGraph } from "@packages/commons";
import RuleGraphFullReleaseCore from "./components/RuleGraphFullReleaseCore";
import dagre from "dagre";
import get from 'lodash/get';

const worker = new Worker("/dagreWorker.js");

// const escapeParentheses = (s: string) => s.replaceAll("(", "\\(").replaceAll(")", "\\)");

export interface RuleGraphFullReleaseProps {
  rawGraph: RawRuleGraph;
  typeFunc?: typeof defaultTypeFunc;
  // // defaultNode: string;
  onNodeClick: (path: string, v: any) => unknown;
  onNodeContextClick?: (v: any) => unknown;
  datesFilter?: DatesFilterNS.Props["value"];
  setDatesFilter?: DatesFilterNS.Props["set"];
  showGoalToolbar?: boolean;
  showHidden?: boolean;
  refitKey?: string | number;
}

const CastedReactFlowProvider = ReactFlowProvider as React.FC<React.PropsWithChildren<Record<string, unknown>>>;

type ElementState = (
  | { mode: "initializing" | "empty", elements: undefined }
  | { mode: "initialized" | "refresh", elements: Elements }
);
export const RuleGraphFullRelease: React.FC<RuleGraphFullReleaseProps> = React.memo((props) => {

  const release = useFullRelease();
  const requiresRefit = React.useRef(false);

  useEffect(() => {
    if (release) {
      requiresRefit.current = true;
    }
  }, [release?.id]);

  const {
    rawGraph,
    showHidden,
    typeFunc,
    // datesFilter,
  } = props;
  // const showHidden = true;
  // console.log("rawGraph", rawGraph);

  const [elementsState, setElementsState] = React.useState<ElementState>({ mode: "empty", elements: undefined });
  const [refitKey, setRefitKey] = React.useState(0);
  const ignoreNewPositions = React.useRef<{
    repositionedNodes: { [key: string]: { x: number, y: number } };
    oldPositions: { [key: string]: { x: number, y: number } };
    // works nicely without these, but they might be useful in the future if you want even more control...
    // newNodesRootPos: { x: number, y: number };
    // newNodesRelativePosition: "above" | "below";
  } | null>(null);
  const resetLayout = React.useRef<boolean>(false);
  const additionalVisibleNodes = React.useRef<string[]>([]);
  const {
    setVisibleNodes,
    restrictedViewDepth,
    restrictedViewRootPath,
    restrictedViewVisibleNodes,
    restrictedViewNodesToMerge,
    setRestrictedViewNodesToMerge,
    isGraphViewRestricted,
    nodeRenderLimit,
    goal: currentGoal,
  } = useGraphVisualisation();

  useEffect(() => {
    requiresRefit.current = true;
  }, [restrictedViewRootPath]);
  const parsedGraph = (rawGraph
    ? (typeof rawGraph.nodes === "function" ? rawGraph : dagre.graphlib.json.read(rawGraph))
    : null) as (dagre.graphlib.Graph<{}> | null);

  const goalNodesOrdered = React.useMemo(() => {
    const nodes: any[] = [];
    if (parsedGraph) {
      parsedGraph.nodes().forEach((n) => {
        const node = parsedGraph.node(n) as any;
        if (!node) {
          return;
        }
        if (node.fk || node.identifier) {
          return;
        }

        if (!showHidden && node.hidden) {
          return;
        }

        //if (parsedGraph.successors(n).length === 0) return; // Only show goals and intermediate

        const cfg = {
          id: node.id,
          name: node.description,
          isGoal: !((parsedGraph.predecessors(n) || []).length > 0),
          path: node.path,
          entity: node.entity,
          isGlobal: node.entity === "global",
        };
        nodes.push(cfg);
      });
    }
    const goalNodes = ({
      goal: nodes.filter((n) => n.isGoal),
      intermediate: nodes.filter((n) => !n.isGoal),
    });
    const flatGoals = [
      ...goalNodes.goal,
      ...goalNodes.intermediate,
    ];

    if (currentGoal) {
      // if currentGoal is set, let's make sure that it's the first in the list, as we'll want to make sure it's visible
      const idx = flatGoals.findIndex((n) => n.id === currentGoal.id);
      if (idx > -1) {
        const currentGoalObj = flatGoals[idx];
        flatGoals.splice(idx, 1);
        flatGoals.unshift(currentGoalObj);
      }
    }

    return (flatGoals);
  }, [parsedGraph, currentGoal, showHidden]);

  const repositionNode = (nodeId: string, x: number, y: number) => {
    if (ignoreNewPositions.current) {
      ignoreNewPositions.current.repositionedNodes = {
        ...ignoreNewPositions.current.repositionedNodes || {},
        [nodeId]: { x, y },
      };
    } else {
      ignoreNewPositions.current = {
        oldPositions: {},
        repositionedNodes: {
          [nodeId]: { x, y },
        },
      };
    }
  };

  // -- web worker

  const workerListener = React.useCallback((e: MessageEvent<MsgFromWorker>) => {

    const {
      elements,
      visibleNodes,
    } = e.data;

    if (ignoreNewPositions.current) {
      console.log("Ignoring new positions");

      for (const newEl of elements) {
        if ((newEl as any).position) {
          const repositionData = ignoreNewPositions.current.repositionedNodes[newEl.id];
          const oldPosData = ignoreNewPositions.current.oldPositions[newEl.id];
          const replacementPosition = repositionData || oldPosData;
          if (replacementPosition) {
            newEl["position"] = { x: replacementPosition.x, y: replacementPosition.y };
          }
        }
      };
      // you don't need to hang onto ignoreNewPositions.current.repositionedNodes, because elements will get saved into
      // `elementsState` next, so this will find its way into ignoreNewPositions.current.oldPositions on the next expansion
      ignoreNewPositions.current = null;
      requiresRefit.current = false;
    }

    if (resetLayout.current) {
      setElementsState({ mode: "initialized", elements: [] });
      setTimeout(() => {
        setElementsState({ mode: "initialized", elements: elements });
      }, 1);
      resetLayout.current = false;
      ignoreNewPositions.current = null;
    } else {
      setElementsState({ mode: "initialized", elements: elements });
      setVisibleNodes(visibleNodes ?? null);
    }
    // console.log(`Number of (scoped) visible nodes on-screen is: ${visibleNodes?.length}`);

    if (requiresRefit.current) {
      requiresRefit.current = false;
      setRefitKey(i => i + 1);
    }
  }, []);

  React.useEffect(() => {
    worker.addEventListener("message", workerListener);

    return () => {
      worker.removeEventListener("message", workerListener);
    };
  }, [workerListener]);

  React.useEffect(() => {

    const msg: MsgToWorker = {
      rawGraph,
      showHidden,
      typeFunc: typeFunc === undefined ? undefined : "debugGraph",
      restrictedView: isGraphViewRestricted ? {
        rootPath: restrictedViewRootPath!, // safe to force because it's tested in isGraphViewRestricted
        graphDepth: restrictedViewDepth ?? 1,
        additionalVisibleNodes: [],
      } : undefined,
    };

    additionalVisibleNodes.current = [];
    if (!isGraphViewRestricted) {
      const countVisibleNodes = showHidden
                              ? (rawGraph.nodes || []).length
                              : (rawGraph.nodes || []).filter((n) => !n.value.hidden).length;
      if (countVisibleNodes > nodeRenderLimit && (goalNodesOrdered.length > 0)) {
        console.log(`Render limited to ${nodeRenderLimit} nodes. Preventing full display of ${countVisibleNodes} nodes.`);

        let newAdditionalNodes: string[] = [];
        const maxExpansion = 25;
        for (const gn of goalNodesOrdered) {
          let newExpandedNodes: string[] = [];
          for (let currDepth = 1; currDepth <= maxExpansion; currDepth++) {
            const currPath = gn.path || gn.id;
            const allPredecessors = collectPredecessorsOrSuccessors(parsedGraph!, currPath, currDepth, "predecessors");
            const allSuccessors = collectPredecessorsOrSuccessors(parsedGraph!, currPath, currDepth, "successors");

            const newNodes = [
              currPath,
              ...allPredecessors,
              ...allSuccessors,
            ];
            if (newNodes.length === newExpandedNodes.length || ((newNodes.length + newAdditionalNodes.length) > nodeRenderLimit)) {
              break;
            }
            newExpandedNodes = newNodes;
          }
          newAdditionalNodes = [...new Set([...newAdditionalNodes, ...newExpandedNodes])]
        }

        msg.restrictedView = {
          rootPath: goalNodesOrdered[0].path || goalNodesOrdered[0].id,
          graphDepth: 1,
          additionalVisibleNodes: newAdditionalNodes,
        };
        additionalVisibleNodes.current = newAdditionalNodes;
      }
    }

    setElementsState((state) => {

      if (state.mode === "initializing" || state.mode === "refresh") {
        return (state);
      }

      return ({ ...state, mode: state.mode === "initialized" ? "refresh" : "initializing" }) as any;
    });

    //console.log(`Showing graph ${isGraphViewRestricted ? "with" : "without"} restrictions`);
    worker.postMessage(msg);
  }, [showHidden, rawGraph, typeFunc, restrictedViewRootPath, restrictedViewDepth, nodeRenderLimit]);

  // -- expand nodes in restricted view

  React.useEffect(() => {

    // doing it this way goes back to the worker, and the whole graph gets re-layed out
    // we could append things to the existing elementsState.elements, but we'd have to work out ourselves where to put them
    // and it's nice to have the worker do the layout for us (even if we just tesselate the new nodes in the existing layout)

    const pathRestriction = restrictedViewRootPath || get(goalNodesOrdered, '0.path') || get(goalNodesOrdered, '0.id');
    if (restrictedViewNodesToMerge?.length && pathRestriction) {
      console.log(`Showing ${(restrictedViewNodesToMerge || []).length} additional nodes reachable from ${restrictedViewRootPath || "(Limitation not specified)"}`);

      additionalVisibleNodes.current = [
        ...(restrictedViewVisibleNodes || []),
        ...restrictedViewNodesToMerge,
      ];
      const msgToWorker: MsgToWorker = {
        rawGraph,
        showHidden,
        typeFunc: typeFunc === undefined ? undefined : "debugGraph",
        restrictedView: pathRestriction ? {
          rootPath: pathRestriction,
          graphDepth: restrictedViewDepth ?? 1,
          additionalVisibleNodes: additionalVisibleNodes.current,
        } : undefined,
      };
      setRestrictedViewNodesToMerge(null);
      // save old positions
      ignoreNewPositions.current = {
        repositionedNodes: ignoreNewPositions.current?.repositionedNodes || {},
        oldPositions: (elementsState.elements || []).reduce((acc, el: any) => {

          if (el.position) {
            acc[el.id] = { x: el.position.x, y: el.position.y };
          }
          return (acc);
        }, {} as { [key: string]: { x: number, y: number } }),
        // newNodes: restrictedViewNodesToMerge,
      }
      console.log("ignoreNewPositions.current", ignoreNewPositions.current);
      // seems counter intuitive, but we need to blank out the graph for a moment because whilst expanding
      // you can get weird artifacts like edges not drawing, despite the data being absolutely correct
      setElementsState((state) => ({ ...state, mode: "refresh", elements: [] } ));
      worker.postMessage(msgToWorker);
    }
  }, [restrictedViewNodesToMerge]);

  // -- layout

  const relayoutGraph = () => {

    if (elementsState.mode === "empty") {
      return;
    }

    console.log("Re-layout graph");


    const msgToWorker: MsgToWorker = {
      rawGraph,
      showHidden,
      typeFunc: typeFunc === undefined ? undefined : "debugGraph",
      restrictedView: isGraphViewRestricted ? {
        rootPath: restrictedViewRootPath!, // safe to force because it's tested in isGraphViewRestricted
        graphDepth: restrictedViewDepth ?? 1,
        additionalVisibleNodes: [
          ...(restrictedViewVisibleNodes || []),
        ],
      } : undefined,
    }

    // we might have some unfolds on an unrestricted graph that we need to preserve
    if (!isGraphViewRestricted && additionalVisibleNodes.current.length && goalNodesOrdered?.[0]) {
      msgToWorker.restrictedView = {
        rootPath: goalNodesOrdered[0].path || goalNodesOrdered[0].id,
        graphDepth: 1,
        additionalVisibleNodes: additionalVisibleNodes.current,
      };
    };

    setElementsState((state) => ({ ...state, mode: "refresh" } as any));
    requiresRefit.current = false;
    ignoreNewPositions.current = null;
    resetLayout.current = true;
    worker.postMessage(msgToWorker);
  }

  // -- rendering

  if (elementsState.mode === "empty") {
    return null;
  }

  return (
    <CastedReactFlowProvider>
      {
        elementsState.elements
          ?
          <RuleGraphFullReleaseCore
            {...props}
            refitKey={refitKey}
            elements={elementsState.elements}
            relayoutGraph={relayoutGraph}
            onRepositionNode={repositionNode}
          />
          :
          null
      }
      {
        (elementsState.mode === "refresh" || elementsState.mode === "initializing")
        ?
          <Box
            position={"absolute"}
            style={{ background: "rgba(255,255,255,0.5)" }}
            top={0}
            left={0}
            right={0}
            bottom={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {LoadingDotsJSX}
          </Box>
        :
          null
        }
    </CastedReactFlowProvider>
  );
});
RuleGraphFullRelease.displayName = "components/RuleGraphFullRelease";



// collect all the predecessors and successors down/up to the depth graphDepth
// I think probably don't do this recursively, but iteratively to avoid any stack issues on large graphs
// const allPredecessors: string[] = [];
// const allSuccessors: string[] = [];
// const collectPredecessors = (node, depth) => {
//   if (depth === 0) return;
//   const predecessors = (paredRawGraph.predecessors(node) || []) as unknown as string[];
//   allPredecessors.push(...predecessors);
//   predecessors.forEach(n => collectPredecessors(n, depth - 1));
// };
// const collectSuccessors = (node, depth) => {
//   if (depth === 0) return;
//   const successors = (paredRawGraph.successors(node) || []) as unknown as string[];
//   allSuccessors.push(...successors);
//   successors.forEach(n => collectSuccessors(n, depth - 1));
// };

// DO NOT POST MESSAGES BACK TO THE GRAPH COMPONENT...
// React.useEffect(() => {
//   const onMessage = (e: MessageEvent) => {
//     const msg = e.data;
//     if (msg.type === "graphShowHideAdditionalNodes") {
//       const {
//         srcNodeId,
//         additionalNodeIds,
//         action,
//       } = msg;
//       if (action === "show") {
//         setElementsState((state) => {
//           if (state.mode === "initializing" || state.mode === "refresh") {
//             return (state);
//           }
//           return ({ ...state, mode: "refresh" }) as any;
//         });

//         console.log(`Showing ${(additionalNodeIds || []).length} nodes for ${srcNodeId}`);

//         const msgToWorker: MsgToWorker = {
//           rawGraph,
//           showHidden,
//           typeFunc: typeFunc === undefined ? undefined : "debugGraph",
//           restrictedView: true ? {
//             rootPath: srcNodeId, // safe to force because it's tested in isGraphViewRestricted
//             graphDepth: restrictedViewDepth ?? 1,
//             additionalVisibleNodes: additionalNodeIds || [],
//           } : undefined,
//         };
//         worker.postMessage(msgToWorker);

//       } else if (action === "hide") {
//         console.log("ERR 54215:: hide nodes not implemented yet...")
//       }
//     }
//   };
//   window.addEventListener("message", onMessage);

//   return () => {
//     window.removeEventListener("message", onMessage);
//   };
// }, []);