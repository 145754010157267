import { useState } from "react";
import { LoadingDots, TasksIcon } from "@icons";
import { Box, Tooltip, Typography } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { Sort } from "@imminently/immi-query";

import { Task } from "@packages/commons";
import { Button, Label, MenuC as Menu, ContextMenuItem, useContextMenu, AvatarGroup, Avatar } from "@components";
import styles from "./tasks.module.scss";
import clsx from "clsx";
import { scrollable } from "@common/scrollbar";
import Select from "react-select";
import { colors } from "theme";
import { formatIgnoreTimezone } from "@common/util";
import { Sort as SortIcon } from "@material-ui/icons";
import { statusOptions, SelectOption, sortOptions, validateTask } from "./taskUtils";
import { useTaskModal } from "./TaskModal";
import { StatusLabel, PriorityLabel } from "./TaskComponents";
import { tasksService } from "services";
import { useScope } from "redux/scope";
import { EmptyContent } from "@common/EmptyContent";
import { ProjectWriterGuard } from "auth/ComponentGuard";
import { useScrollable } from "@common/hooks/useScrollable";
import { ResourceLoading } from "@common/ResourceCard";
import { AssigneeGroup } from "@components/forms/FormikControls";

const TaskCard = ({ task, onClick }: { task: Task, onClick: (task: Task) => void }) => {
  return (
    <Card className={styles.task} onClick={() => onClick(task)}>
      <CardHeader className={styles.test} title={task.title} />
      <CardContent className={styles.taskContent}>
        <Box className={styles.taskRow}>
          <StatusLabel status={task.status} />
          <PriorityLabel priority={task.priority} />
          <Tooltip title="Due Date" placement="left">
            <Typography>{formatIgnoreTimezone(task.dueDate, 'MMM DD, YYYY', undefined)}</Typography>
          </Tooltip>
        </Box>
        <Box className={styles.taskRow}>
          {
            task.tags.map((tag) => (
              <Label key={tag} className={styles.label} color={colors.sublteGrey}>{tag}</Label>
            ))
          }
          <AssigneeGroup users={task.assigneesData} />
        </Box>
      </CardContent>
    </Card>
  );
};

const SortBy = ({ value, onClick }: { value: SelectOption, onClick: (option: SelectOption) => void }) => {
  const { isOpen, props, onContextMenu, close } = useContextMenu();

  return (
    <>
      <Tooltip title="Sort By">
        <Button variant="outlined" color="default" className={styles.sort} onClick={onContextMenu} startIcon={<SortIcon />}>
          {value.label}
        </Button>
      </Tooltip>
      {
        isOpen && (
          <Menu {...props}>
            {
              sortOptions.map((option) => (
                <ContextMenuItem
                  key={option.value}
                  selected={value.value === option.value}
                  onClick={() => {
                    onClick(option);
                    close();
                  }}
                >
                  {option.label}
                </ContextMenuItem>
              ))
            }
          </Menu>
        )
      }
    </>
  );
};

export const TaskPanel = ({ open, setOpen }: any) => {
  const { workspace } = useScope();
  const [filter, setFilter] = useState<SelectOption>();
  const [sort, setSort] = useState<SelectOption>(sortOptions[0]);
  const openTaskModal = useTaskModal();

  const { data: tasks, total, query, loadMore } = tasksService.useInfiniteResources({
    perPage: 10,
    filter: {
      sort: { field: sort.value, order: "asc" } as Sort,
      status: filter?.value,
      workspace,
    },
    enabled: open,
  });

  const { isLoading } = query;
  const canLoad = !query.isError && !query.isFetching && (query.hasNextPage ?? false);
  const scrollLoad = useScrollable(loadMore, canLoad, { autoScroll: true });

  // a react mui side drawer that opens on the right side of the screen
  // contains a list of tasks
  // each task has a title, description, and a button to mark as complete

  const addTask = () => {
    // open create task modal
    openTaskModal();
    setOpen(false);
  };

  const openTask = (task: Task) => {
    // open task modal
    openTaskModal(task);
    setOpen(false);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => setOpen(false)}
    >
      <Box className={styles.header} style={{ minHeight: "4rem" }}>
        <Typography variant="h3">Tasks</Typography>
        <ProjectWriterGuard>
          <Button variant="contained" color="primary" onClick={addTask}>Add</Button>
        </ProjectWriterGuard>
      </Box>
      <Box className={styles.controls}>
        <Select
          className={styles.filter}
          options={statusOptions}
          value={filter}
          onChange={(e) => setFilter(e as SelectOption)}
          placeholder="Filter by status"
          isClearable
        />
        <SortBy value={sort} onClick={setSort} />
      </Box>
      <Box {...scrollLoad} className={clsx(styles.content, scrollable, styles.minWidth)}>
        {
          isLoading ? (<LoadingDots />)
            : (
              !isLoading && total === 0
                ? (<EmptyContent messages={["No tasks"]} />)
                : (
                  <>
                    <Typography variant="h6">Tasks • {total}</Typography>
                    {
                      tasks
                        .map(validateTask)
                        .map((task) => <TaskCard key={task.id} task={task} onClick={openTask} />)
                    }
                    {
                      query.isFetchingNextPage ? (<ResourceLoading width="400px" />) : null
                    }
                  </>
                )
            )
        }
      </Box>
    </Drawer>
  );
};

export const TaskButton = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Tooltip title="Tasks">
        <IconButton color='inherit' onClick={() => setOpen(true)}>
          <TasksIcon />
        </IconButton>
      </Tooltip>
      <TaskPanel open={open} setOpen={setOpen} />
    </>
  );
}