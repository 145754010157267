import { PropsWithChildren, Suspense } from "react";
import styled from "styled-components";
import { LoadingDots } from "@icons";

const Layout = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
`;

const SuspenseFallback = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const LoadingRoute = () => (
  <SuspenseFallback>
    <LoadingDots />
  </SuspenseFallback>
);

export const AsyncRoute = ({ children }: PropsWithChildren) => (
  <Suspense fallback={<LoadingRoute />}>
    {children}
  </Suspense>
);

export const AsyncLayout = ({ page }: any) => {
  return (
    <Layout>
      <AsyncRoute>
        {page}
      </AsyncRoute>
    </Layout>
  );
};