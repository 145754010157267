import React from "react";
import styled, { css } from "styled-components";

import { COMMANDS, type Command } from "@packages/compiler";
import {
  ELEMENT_MENTION,
  ELEMENT_MENTION_INPUT,
  comboboxActions,
  comboboxSelectors,
  createComboboxPlugin,
  createMentionPlugin,
  createPluginFactory,
  getPlugin,
} from "@udecode/plate";
import camelCase from "lodash/camelCase";
import sortBy from "lodash/sortBy";

import { Typography } from "@material-ui/core";

import { Editor, Transforms } from "slate";
import { HistoryEditor } from "slate-history";
import { MentionCombobox } from "./MentionCombobox";

const convertFunctionName = (value: string) => {
  const text = camelCase(value);
  return text[0].toUpperCase() + text.slice(1);
};

const convertFunction = (command: Command) => {
  const { id, arguments: args } = command;
  return `${convertFunctionName(id)}(${args
    .reduce((acc, arg) => {
      if (arg.required) {
        acc.push(arg.name);
      }
      return acc;
    }, [] as string[])
    .join(", ")})`;
};

const functionData = COMMANDS.map((f) => ({ text: convertFunctionName(f.id), data: f, value: convertFunction(f) }));

const ItemContainer = styled.div`
  padding: 0.25rem 0.5rem;
`;

const bold = { fontWeight: "bold" };
const italic = { fontStyle: "italic" };

type ItemProps = {
  item: { text: string };
};

export const Item = ({ item }: ItemProps) => {
  const { text } = item;
  return (
    <ItemContainer>
      <Typography
        variant="caption"
        style={bold}
      >
        {text}
      </Typography>
    </ItemContainer>
  );
};

type FunctionDeclarationProps = {
  data: Command;
};

export const FunctionDeclaration = ({ data }: FunctionDeclarationProps) => {
  const { id, arguments: args } = data;
  console.log(args);
  return (
    <>
      <span>{convertFunctionName(id)}</span>(
      {args.map((arg, i) => {
        const { name, required, variadic } = arg;
        const variadicString = variadic ? "..." : "";
        const optionalString = required ? "" : "?";

        return (
          <React.Fragment key={i}>
            {i > 0 && ", "}
            <span style={{ opacity: required ? 1 : 0.5 }}>{`${name}${optionalString}${variadicString}`}</span>
          </React.Fragment>
        );
      })}
      )
    </>
  );
};

export interface FunctionDetailsProps {
  data: Command;
}

export const Details = (props: FunctionDetailsProps) => {
  const { data } = props;
  if (!data) {
    return "Missing function data";
  }

  const { displayName, description } = data;

  return (
    <>
      <Typography
        variant="caption"
        style={bold}
      >
        {displayName}
      </Typography>
      <Typography
        variant="caption"
        style={italic}
      >
        <FunctionDeclaration data={data} />
      </Typography>
      {description ? (
        <Typography
          variant="caption"
          style={italic}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      ) : null}
    </>
  );
};

export const getMentionOnSelectItem =
  ({ key = ELEMENT_MENTION } = {}) =>
  (editor, item) => {
    const targetRange = comboboxSelectors.targetRange();
    if (!targetRange) return;

    const {
      options: { insertSpaceAfterMention },
    } = getPlugin(editor, key);

    Editor.withoutNormalizing(editor, () => {
      Transforms.select(editor, targetRange);

      HistoryEditor.withoutMerging(editor, () => {
        Transforms.removeNodes(editor, {
          match: (node) => (node as any).type === ELEMENT_MENTION_INPUT,
        });
      });

      let _text = item.value ?? item.text;
      if (insertSpaceAfterMention) {
        _text += " ";
      }
      // console.log("inserting", text, item);
      const plugin = editor.plugins.find((p) => p.key === "functions");
      const insertFn =
        plugin.options.onInsert ??
        ((e, text) => {
          // console.log("inserting", text);
          Transforms.insertNodes(e, { text });
        });

      insertFn(editor, _text);
      // Transforms.insertNodes(editor, { text });
    });
    return comboboxActions.reset();
  };

export const FunctionCombobox = () => {
  return (
    // @ts-ignore
    <MentionCombobox
      pluginKey="/"
      items={sortBy(functionData, "text")}
      onRenderItem={Item}
      onRenderDetail={Details}
      onSelectItem={getMentionOnSelectItem({ key: "/" })}
      styles={{
        root: [
          css`
            border-radius: 0.5rem;
            box-shadow: ${(p) => p.theme?.palette.body.boxShadow};
            width: 32rem;
            overflow: hidden;
          `,
        ],
      }}
    />
  );
};

export const createFunctionsPlugin = createPluginFactory({
  key: "functions",
  plugins: [
    createComboboxPlugin(),
    createMentionPlugin({
      key: "/",
      options: {
        trigger: "/",
        insertSpaceAfterMention: false,
      },
    }),
  ],
});
