import type { MenuProps } from "@material-ui/core/Menu";
import { all, put, takeLatest, delay } from "redux-saga/effects";
import { aCreators, aTypes, Actions } from "./reducer";


export type HolderState = {
  node: React.ReactNode;
  menuListStyle?: Actions[ "requestToShow" ][ 'payload' ][ 'menuListStyle' ];
  propsOverride?: Actions[ "requestToShow" ][ 'payload' ][ 'propsOverride' ];
};
export const holder: HolderState = {
  node: null
};

export function* saga() {
  yield all([
    takeLatest(aTypes.requestToShow, function* showContextMenu(a: Actions[ "requestToShow" ]) {
      holder.node = a.payload.content;
      holder.menuListStyle = a.payload.menuListStyle;
      holder.propsOverride = a.payload.propsOverride;
      yield put(aCreators.setPos(a.payload.pos));
    }),
    takeLatest(aTypes.requestToHide, function* hideContextmenu() {
      holder.node = null;
      holder.menuListStyle = undefined;
      holder.propsOverride = undefined;
      yield put(aCreators.setPos(null));
    }),
    takeLatest(aTypes.requestToHideWithTransition, function* hideContextMenuWithTransition() {
      yield put(aCreators.setPos(null));

      // 175ms is a typicall delay for Mui show/hide transitions
      yield delay(175);

      holder.node = null;
      holder.menuListStyle = undefined;
    }),
  ]);
}
