export const LOCALE_OPTIONS = [
  { key: 'af', value: 'af' },
  { key: 'ar-dz', value: 'ar-dz' },
  { key: 'ar-ly', value: 'ar-ly' },
  { key: 'ar-ma', value: 'ar-ma' },
  { key: 'ar-sa', value: 'ar-sa' },
  { key: 'ar-tn', value: 'ar-tn' },
  { key: 'ar', value: 'ar' },
  { key: 'az', value: 'az' },
  { key: 'be', value: 'be' },
  { key: 'bg', value: 'bg' },
  { key: 'bn', value: 'bn' },
  { key: 'bo', value: 'bo' },
  { key: 'bs', value: 'bs' },
  { key: 'ca', value: 'ca' },
  { key: 'cs', value: 'cs' },
  { key: 'cy', value: 'cy' },
  { key: 'da', value: 'da' },
  { key: 'de-at', value: 'de-at' },
  { key: 'de-ch', value: 'de-ch' },
  { key: 'de', value: 'de' },
  { key: 'el', value: 'el' },
  { key: 'en-au', value: 'en-au' },
  { key: 'en-ca', value: 'en-ca' },
  { key: 'en-gb', value: 'en-gb' },
  { key: 'en-ie', value: 'en-ie' },
  { key: 'en-nz', value: 'en-nz' },
  { key: 'en-us', value: 'en-us' },
  { key: 'es-do', value: 'es-do' },
  { key: 'es', value: 'es' },
  { key: 'eu', value: 'eu' },
  { key: 'fa', value: 'fa' },
  { key: 'fi', value: 'fi' },
  { key: 'fo', value: 'fo' },
  { key: 'fr-ca', value: 'fr-ca' },
  { key: 'fr-ch', value: 'fr-ch' },
  { key: 'fr', value: 'fr' },
  { key: 'gd', value: 'gd' },
  { key: 'he', value: 'he' },
  { key: 'hi', value: 'hi' },
  { key: 'hr', value: 'hr' },
  { key: 'hu', value: 'hu' },
  { key: 'hy-am', value: 'hy-am' },
  { key: 'id', value: 'id' },
  { key: 'is', value: 'is' },
  { key: 'it', value: 'it' },
  { key: 'ja', value: 'ja' },
  { key: 'ka', value: 'ka' },
  { key: 'kk', value: 'kk' },
  { key: 'km', value: 'km' },
  { key: 'kn', value: 'kn' },
  { key: 'ko', value: 'ko' },
  { key: 'lo', value: 'lo' },
  { key: 'lt', value: 'lt' },
  { key: 'lv', value: 'lv' },
  { key: 'mk', value: 'mk' },
  { key: 'ml', value: 'ml' },
  { key: 'mr', value: 'mr' },
  { key: 'ms-my', value: 'ms-my' },
  { key: 'ms', value: 'ms' },
  { key: 'my', value: 'my' },
  { key: 'nb', value: 'nb' },
  { key: 'ne', value: 'ne' },
  { key: 'nl-be', value: 'nl-be' },
  { key: 'nl', value: 'nl' },
  { key: 'pa-in', value: 'pa-in' },
  { key: 'pl', value: 'pl' },
  { key: 'pt-br', value: 'pt-br' },
  { key: 'pt', value: 'pt' },
  { key: 'ro', value: 'ro' },
  { key: 'ru', value: 'ru' },
  { key: 'sd', value: 'sd' },
  { key: 'sk', value: 'sk' },
  { key: 'sl', value: 'sl' },
  { key: 'sq', value: 'sq' },
  { key: 'sr-cyrl', value: 'sr-cyrl' },
  { key: 'sr', value: 'sr' },
  { key: 'sv', value: 'sv' },
  { key: 'sw', value: 'sw' },
  { key: 'ta', value: 'ta' },
  { key: 'te', value: 'te' },
  { key: 'th', value: 'th' },
  { key: 'ttl-phh', value: 'ttl-phh' },
  { key: 'tr', value: 'tr' },
  { key: 'uk', value: 'uk' },
  { key: 'ur', value: 'ur' },
  { key: 'uz-latn', value: 'uz-latn' },
  { key: 'uz', value: 'uz' },
  { key: 'vi', value: 'vi' },
  { key: 'yo', value: 'yo' },
  { key: 'zh-cn', value: 'zh-cn' },
  { key: 'zh-hk', value: 'zh-hk' },
  { key: 'zh-tw', value: 'zh-tw' },
];
