import styled from "styled-components";
import Skeleton from "@material-ui/lab/Skeleton";
import { PageWrapper } from "@imminently/imminently_platform";

const PageContent = styled(PageWrapper)`
  padding: 2rem 2rem 0 2rem;
`;

export const DefaultSkeleton = () => (
  <PageContent>
    <Skeleton style={{ paddingLeft: "100px", marginTop: 10 }} variant='rect' width='20%' height={30} />
    <Skeleton style={{ paddingLeft: "100px", marginTop: 10 }} variant='rect' width='80%' height={20} />
    <Skeleton style={{ paddingLeft: "100px", marginTop: 10 }} variant='rect' width='80%' height={20} />
    <Skeleton style={{ paddingLeft: "100px", marginTop: 10 }} variant='rect' width='80%' height={20} />
  </PageContent>
);