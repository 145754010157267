/* eslint-disable no-unused-vars */
/* eslint-disable no-multi-spaces */

export type Size = 'small' | 'large';

export function getSize(size?: Size, noUnits?: true): number;
export function getSize(size?: Size, noUnits?: false | undefined): string;

export function getSize(size?: Size, noUnits?: boolean): string | number {
  switch (size) {
    case 'small':
      return(noUnits ? 1 : '1rem');
    case 'large':
      return(noUnits ? 2 : '2rem');
    default:
      return(noUnits ? 1.5 : '1.5rem');
  }
};
