import type { MenuProps } from "@material-ui/core/Menu";


export const REDUX_PROP = "mainContextMenu";


export type State = {
  pos: null | {
    coordinates: {
      top: number; left: number
    };
    transformOrigin?: MenuProps[ "transformOrigin" ];
  };
};
export const defaultState: State = {
  pos: null,
};
export const getState = ( s: any ): State => s[ REDUX_PROP ];


export const aTypes = {
  setPos: "main-context-menu/set-pos",
  requestToShow: "main-context-menu/request-to-show",
  requestToHide: "main-context-menu/request-to-hide",
  requestToHideWithTransition: "main-context-menu/request-to-hide-with-transition",
} as const;

export type Actions = {
  setPos: {
    type: typeof aTypes.setPos;
    payload: State[ "pos" ];
  };
  requestToShow: {
    type: typeof aTypes.requestToShow;
    payload: {
      content: JSX.Element;
      menuListStyle?: NonNullable< MenuProps[ "MenuListProps" ] >[ "style" ];
      propsOverride?: Partial< MenuProps >;
      pos: Actions[ "setPos" ][ "payload" ];
    }
  };
  requestToHide: {
    type: typeof aTypes.requestToHide;
  };
  requestToHideWithTransition: {
    type: typeof aTypes.requestToHideWithTransition;
  };
};
export type AllActions = Actions[ keyof Actions ];
export const aCreators: {
  [ N in keyof Actions ]: Actions[ N ] extends { payload: unknown }
    ? ( p: Actions[ N ][ "payload" ] ) => Actions[ N ]
    : () => Actions[ N ]
} = {
  setPos: p => ({ type: aTypes.setPos, payload: p }),
  requestToShow: p => ({ type: aTypes.requestToShow, payload: p }),
  requestToHide: () => ({ type: aTypes.requestToHide }),
  requestToHideWithTransition: () => ({ type: aTypes.requestToHideWithTransition }),
};

export function reducer( s = defaultState, a: AllActions): typeof s {
  switch (a.type) {
    case aTypes.setPos: return { pos: a.payload };
    default: return s;
  }
}
