import React from 'react';
import { default as MAvatar } from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { isFragment } from 'react-is';

const getSize = size => {
  switch (size) {
    case 'xsmall':
      return '1.25rem';
    case 'small':
      return '1.5rem';
    case 'medium':
      return '2rem';
    case 'large':
      return '3rem';
    default:
      return '2.5rem';
  }
};

/** @type { React.FC< any > } */
export const Avatar = withStyles(theme => ({
  root: (props: any) => ({
    '--size': getSize(props.size),
    width: 'var(--size)',
    height: 'var(--size)',
    fontSize: 'calc(var(--size) / 2) !important',
    fontWeight: 700,
    textTransform: 'uppercase',
  }),
  colorDefault: (props: any) => ({
    color: props.invert ? theme.palette.secondary.main : theme.palette.secondary.contrastText,
    border: props.invert ? `1px solid ${theme.palette.secondary.main}` : 'none',
    backgroundColor: props.invert ? theme.palette.secondary.contrastText : theme.palette.secondary.main,
  }),
  img: (props: any) => ({
    filter: props.mono ? 'grayscale(1)' : 'none',
  }),
}))(React.forwardRef<any, any>(({ mono, invert, ...props }, ref) => <MAvatar ref={ref} {...props} />));
// don't pass custom props to MAvatar

/**
 * Custom Avatar Group override to use our design system. Original component
 * does not have the required customisation.
 */

const SPACINGS = {
  small: -16,
  medium: null,
};

export const styles = theme => ({
  /* Styles applied to the root element. */
  root: {
    display: 'flex',
  },
  /* Styles applied to the avatar elements. */
  avatar: {
    border: `2px solid ${theme.palette.background.default}`,
    marginLeft: -8,
    '&:first-child': {
      marginLeft: 0,
    },
  },
});

const AvatarGroupComponent = React.forwardRef<any, any>((props, ref) => {
  const { children: childrenProp, classes, className, max = 5, spacing = 'medium', size = 'default', ...other } = props;
  const clampedMax = max < 2 ? 2 : max;

  const children = React.Children.toArray(childrenProp).filter(child => {
    if (isFragment(child)) {
      console.error(
        [
          "Material-UI: The AvatarGroup component doesn't accept a Fragment as a child.",
          'Consider providing an array instead.',
        ].join('\n'),
      );
    }

    return React.isValidElement(child);
  });

  const extraAvatars = children.length > clampedMax ? children.length - clampedMax + 1 : 0;

  const marginLeft = spacing && SPACINGS[spacing] !== undefined ? SPACINGS[spacing] : -spacing;

  return (
    <div className={clsx(classes.root, className)} ref={ref} {...other}>
      {children.slice(0, children.length - extraAvatars).map((child: any, index) => {
        return React.cloneElement(child, {
          className: clsx(child.props.className, classes.avatar),
          style: {
            zIndex: index,
            marginLeft: index === 0 ? undefined : marginLeft,
            ...child.props.style,
          },
        });
      })}
      {extraAvatars ? (
        <Avatar
          invert
          size={size}
          className={classes.avatar}
          style={{
            zIndex: children.length + 1,
            marginLeft,
          }}
        >
          +
          {extraAvatars}
        </Avatar>
      ) : null}
    </div>
  );
});
AvatarGroupComponent.displayName = 'AvatarGroup';

export const AvatarGroup = withStyles(styles, { name: 'MuiAvatarGroup' })(AvatarGroupComponent);
