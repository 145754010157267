import * as Yup from 'yup';
import { FormikContext } from 'formik';

import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import { showModal } from '@imminently/imminently_platform';
import { Tabs, Button } from '@components';
import { useProjects, useWorkspace, formatText } from '@common';

import { useServiceForm } from './hooks';
import { ModalFields, ModalActions } from './components';
import { BodyProps, showConfirmation } from './ConfirmationModal';
import { projectService, workspaceService } from 'services';
import { Workspace } from '@packages/commons';
import { useRouting } from '@common/hooks/HooksRouting';
import { UserCombobox } from '@components/radix/UserSelect/UserSelect';
import { useNotify } from '@common/hooks/HooksNotification';
import { ModalComponentProps } from 'layouts/Modal.types';
import { AssigneeGroup, FormikTextField } from '@components/forms/FormikControls';
import { Box } from '@material-ui/core';

const WORKSPACE_DESCRIPTION = "A workspace is where you'll save your projects and collaborate with others.";

const VISIBILITY_OPTIONS = [
  {
    key: 'Public',
    label: 'Public',
    value: 'public',
  },
  {
    key: 'Private',
    label: 'Private',
    value: 'private',
  },
];

const Schema = Yup.object().shape({
  name: Yup.string().required('Required'),
});

type WorkspaceModalProps = {
  workspace?: Workspace;
};

const WorkspaceModal = (props: WorkspaceModalProps) => {
  const { workspace, close } = props as WorkspaceModalProps & ModalComponentProps;
  const notify = useNotify();
  const formik = useServiceForm({
    service: workspaceService,
    resource: workspace,
    filter: ['name', 'visibility', 'users', 'aiKey'],
    formikValues: {
      initialValues: {
        name: workspace?.name ?? '',
        visibility: workspace?.visibility ?? 'public',
        users: workspace?.usersData ?? [],
        // permissions: workspace?.permissions ?? {},
      },
      validationSchema: Schema,
    },
    preSubmit: values => {
      return {
        ...values,
        users: values.users.map(u => u.identity_id)
      };
    }
  });

  const { values, setFieldValue, submitForm, isSubmitting } = formik;
  const isCreate = workspace === undefined;
  const modalActions = {
    primary: {
      name: isCreate ? 'Create' : 'Update',
      onClick: () => {
        submitForm()
          .then(() => {
            // TODO temp workaround while we are inbetween the old and new API
            workspaceService.invalidateAllQueries();
            close();
          })
          .catch(e => {
            console.error(e);
            notify.error('Failed to update workspace');
          });
        // .finally(close);
      },
      disabled: isSubmitting,
      loading: isSubmitting,
    },
    // TODO do we actually want this here?
    // secondary: isCreate
    //   ? undefined
    //   : [
    //     {
    //       name: 'Delete',
    //       onClick: () => dispatch(deleteWorkspace(workspace)),
    //       error: true,
    //       type: "primary",
    //     },
    //   ],
  };

  const aiHelper = isCreate ? "Add key to enable AI features" : "Leave blank to preserve current key (if any)";

  return (
    <>
      <FormikContext.Provider value={formik}>
        <ModalFields>
          <FormikTextField name='name' label='Workspace name' />
          <FormikTextField name='aiKey' label='AI Key' helperText={aiHelper} />
          <Tabs tabs={VISIBILITY_OPTIONS} current={values.visibility} onChange={(_, val) => setFieldValue('visibility', val)} />
          {
            values.visibility === 'private' ? (
              <UserCombobox
                value={values.users}
                onChange={(value) => setFieldValue('users', value)}
                searchProps={{ placeholder: "Search..." }}
                popover={{ align: 'end' }}
              >
                <Button type="tertiary" fullWidth style={{ justifyContent: "space-between" }}>
                  <Typography variant="button">Assignees</Typography>
                  <AssigneeGroup users={values.users} max={10} />
                </Button>
              </UserCombobox>
            ) : null
          }
        </ModalFields>
      </FormikContext.Provider>
      <ModalActions {...modalActions} />
    </>
  );
};

export const addWorkspace = (previous?: any) => showModal(
  {
    open: true,
    title: 'Create a workspace',
    description: WORKSPACE_DESCRIPTION,
    previous,
  },
  <WorkspaceModal />,
);

export const editWorkspace = (workspace: Workspace, previous?: any) => {
  if (workspace === undefined) {
    throw new Error('editWorkspace requires a workspace object');
  }

  return showModal(
    {
      open: true,
      title: 'Edit workspace',
      previous,
    },
    <WorkspaceModal workspace={workspace} />,
  );
};

export const archiveWorkspace = id => {
  const Body = () => {
    const { name } = useWorkspace(id);
    const { total } = useProjects(id);
    const text = formatText(
      `Are you sure you want to archive this workspace **${name}**?
      This will affect ${total} projects.`,
    );
    return (
      <Typography variant='body1' paragraph={false}>
        {text}
      </Typography>
    );
  };

  return showConfirmation({
    title: 'Archive workspace',
    body: <Body />,
    actions: {
      primary: {
        name: 'Yes, archive',
        onClick: () => { /**  */ },
      },
    },
  });
};

export const deleteWorkspace = (workspace: Workspace) => {
  const Body = (props) => {
    // defining here so the usage of Body doesn't complain
    const { close } = props as BodyProps;
    const { goToPage } = useRouting();
    const { name } = workspace;
    const { total, query } = projectService.useResources({ filter: { workspace: workspace.id } });
    const { mutateAsync: remove, isLoading: isRemoving } = projectService.useDelete();

    if (query.isLoading) {
      return (
        <div>
          <Skeleton variant='rect' width='100%' height={16} style={{ marginBottom: '0.5rem' }} />
          <Skeleton variant='rect' width='100%' height={16} style={{ marginBottom: '0.5rem' }} />
          <Skeleton variant='rect' width='60%' height={16} />
        </div>
      );
    }

    const deleteWorkspace = () => {
      remove(workspace.id)
        .then(() => {
          goToPage('/projects');
          close();
        });
    };

    if (total === 0) {
      const actions = {
        primary: {
          name: 'Yes, delete',
          onClick: deleteWorkspace,
          error: true,
          disabled: isRemoving,
          loading: isRemoving,
        }
      };
      return (
        <>
          <Typography variant='body1' paragraph={false}>
            {
              formatText(
                `Are you sure you want to delete this workspace **${name}**?
                This can't be undone.`,
              )
            }
          </Typography>
          <ModalActions style={{ marginTop: '2rem' }} {...actions} />
        </>
      );
    }

    // has projects, cannot delete
    return (
      <>
        <Typography variant='body1' paragraph={false}>
          {
            formatText(
              `Workspace **${name}** is not empty.
              You must delete all related projects first.`,
            )
          }
        </Typography>
      </>
    );
  };

  return showConfirmation({
    title: 'Delete workspace',
    body: <Body key='delete-workspace' />,
    // defer actions to body
    actions: null,
  });
};
