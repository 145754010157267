
import React from 'react';
import styled from 'styled-components';
import { scrollableMixin } from '@common/scrollbar';
import { JsonEditorV2 } from '@common/JsonEditorV2';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import { PrimaryButton, SecondaryButton } from '@components/buttons';
import { useForm, Controller } from 'react-hook-form';
import { useCurrentRelease } from '@common/hooks';
import { graphlib } from 'dagre';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { groupGraphNodes } from '@common';
import { generatePayload } from '@components/Debugger/util';

export type IGoal = { label: string; value: string; group: string };


const StyledAutocomplete = (withStyles(theme => ({
  groupLabel: {
    color: theme.palette.secondary.main,
    fontSize: '0.875rem',
  },
}))(Autocomplete)) as React.FC< import('@material-ui/lab/Autocomplete').AutocompleteProps< IGoal, false, false, false > >;


const CommonJsonWrap = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: auto;
  margin-bottom: 1rem;

  [name="body"] span:nth-of-type(2){
    ${ scrollableMixin };
  }

  >*:first-child {
    flex-grow: 1;
    height: initial !important;
    overflow: auto;
  }
`;


const marignBottom05Rem = { marginBottom: '0.5rem' };

export const classes = {
  '>name': 'name',
  '>goal': 'goal',

  '>jsonLabel': 'jsonLabel',
  '>json': 'json',

  '>btns': 'btns',
};

const Wrap = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  >.${ classes[ '>goal' ] } {
    margin-bottom: 1rem;
  }
`;

const GridWrap = styled(Grid)`
  flex-grow: 1;
  height: calc(100% + 24px);
`;

const JsonRow = styled(Grid)`
  flex-grow: 1;
  overflow: auto;
`;

const JsonWrap = styled(Grid)`
  height: 100%;
  flex-wrap: nowrap;
`;

const GenerateWrap = styled(Grid)`
  gap: 1rem;
`;

const GenerateButton = styled(SecondaryButton)`
  max-height: initial;
  min-width: initial;

  >* { white-space: initial; }
`;

type Json = import('@common/JsonEditor').Json;


const groups = {
  goal: 'goal',
  intermediate: 'intermediate',
};

export type ITestScenario = {
  id: string;
  name: string;
  goal: string;
  outcome: Json;
  payload: Json;
  model: string;
  release: string;
  created: string;
  lastModified: string;
};

const isValidJson = (v: string | undefined) => {
  if (!v) return false;

  try {
    return Boolean(JSON.parse(v));
  } catch (e) {
    return false;
  }
};

const goalValidator = yup.string().required('This field is required');
const getStringJsonValidator = (
  () => yup.string().test(
    'validJSON',
    'Is not valid Json',
    isValidJson,
  )
);

const payloadValidator = (
  getStringJsonValidator()
    .test(
      'hasNoForbiddenFields',
      "Root fields ['data', 'goal', 'mode'] are not allowed",
      v => {
        if (!v) return false;
        if (!isValidJson(v)) return false;

        const forbiddenFields = ['data', 'goal', 'mode'];
        const json = JSON.parse(v);

        for (const field of forbiddenFields) {
          if (Object.prototype.hasOwnProperty.call(json, field)) {
            return false;
          }
        }

        return true;
      },
    )
);

const outcomeValidator = getStringJsonValidator();

const testScenarioSchemaShapeLite = {
  name: yup.string().required('This field is required'),
  goal: goalValidator,
  payload: payloadValidator,
};

const testScenarioSchemaLite = yup.object().shape(testScenarioSchemaShapeLite);
const testScenarioSchema = yup.object().shape({
  ...testScenarioSchemaShapeLite,
  outcome: outcomeValidator,
});

/* eslint-disable no-unused-vars */
export type IProps = {
  scenario: Pick<
    ITestScenario,
    | 'name'
    | 'goal'
  > & { outcome: string; payload: string; }
  onSubmit: (d: Pick<
    ITestScenario,
    | 'name'
    | 'goal'
    | 'outcome'
    | 'payload'
  >) => unknown;
  onGenerate?: (
    data: Pick< IProps[ 'scenario' ], 'payload' | 'goal' >,
    setOutput: (v: Json) => unknown
  ) => unknown;
  className?: string;
};
/* eslint-enable no-unused-vars */

export const _: React.FC< IProps > = React.memo(p => {
  const { scenario, className, onSubmit, onGenerate } = p;

  const [generating, setGenerating] = React.useState(false);
  const [payloadUsedForGenerate, setPayloadUsedForGenerate] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const maybeCurrentRelease = useCurrentRelease();
  const goals = React.useMemo(() => {
    if (!maybeCurrentRelease) return [];

    const g = graphlib.json.read(maybeCurrentRelease.rule_graph) as graphlib.Graph< any >;
    const { goals, derived } = groupGraphNodes(g);

    const allGoals = goals.map(n => ({
      value: n.id,
      label: n.description,
      group: groups.goal,
    }))
      .concat(derived.map(n => ({
        value: n.id,
        label: n.description,
        group: groups.intermediate,
      })));

    return allGoals.sort((a, b) => (
      a.group === groups.goal && b.group === groups.intermediate
        ? -1
        : 1
    ));
  }, [maybeCurrentRelease]);


  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    watch,
  } = useForm({
    defaultValues: scenario,
    resolver: yupResolver(testScenarioSchema),
  });

  const watchedName = watch('name');
  const watchedGoal = watch('goal');
  const watchedPayload = watch('payload');
  const watchedOutcome = watch('outcome');


  const generateDisabled = React.useMemo(() => {
    if (watchedPayload === payloadUsedForGenerate || generating) return true;

    try {
      testScenarioSchemaLite.validateSync({
        name: watchedName,
        goal: watchedGoal,
        payload: watchedPayload,
      });

      return false;
    } catch (e) {
      if (!(e instanceof yup.ValidationError)) throw e;

      return true;
    }
  }, [watchedName, watchedGoal, watchedPayload, generating, payloadUsedForGenerate]);

  const saveIsDisabled = React.useMemo(() => {
    try {
      testScenarioSchema.validateSync({
        name: watchedName,
        goal: watchedGoal,
        payload: watchedPayload,
        outcome: watchedOutcome,
      });

      return false;
    } catch (e) {
      if (!(e instanceof yup.ValidationError)) throw e;

      return true;
    }
  }, [watchedName, watchedGoal, watchedPayload, watchedOutcome]);


  // eslint-disable-next-line no-unused-vars
  type OnGoalChange = (_: unknown, e: string | IGoal | (string | IGoal)[] | null) => unknown;
  const onGoalChange = React.useCallback< OnGoalChange >((_, e) => {
    if (Array.isArray(e) || typeof e === 'string') return;

    setValue('goal', e === null ? '' : e.value, { shouldValidate: true });
    if (e === null) return;
    if (!maybeCurrentRelease) return;

    const payload = generatePayload(e.value, true, maybeCurrentRelease, maybeCurrentRelease.rule_graph);

    if (typeof payload !== 'object' || payload === null || !("data" in payload) || typeof payload.data !== 'object') return;

    setValue('payload', JSON.stringify(payload.data, null, 2));
  }, [setValue, maybeCurrentRelease]);


  const onGenerateHandler = React.useCallback(
    () => {
      if (onGenerate === undefined) return undefined;

      const { goal, payload } = getValues();

      Promise.all([
        goalValidator.validate(goal)
          .then(() => null)
          .catch(e => e.message),
        payloadValidator.validate(payload)
          .then(() => null)
          .catch(e => e.message),
      ])
        .then(([goalRez, payloadRez]) => {
          if (goalRez === null && payloadRez === null) {
            setGenerating(true);

            const { goal, payload } = getValues();

            onGenerate(
              { goal, payload },
              v => {
                setValue('outcome', JSON.stringify(v, null, 2));
                if (JSON.stringify(v) !== '{}') {
                  setPayloadUsedForGenerate(payload);
                }

                setGenerating(false);
              },
            );
          }

          if (typeof goalRez === 'string') {
            setError('goal', { message: goalRez });
          } else {
            setError('payload', { message: payloadRez });
          }
        });
    },
    [onGenerate, getValues, setError, setValue, setGenerating],
  );

  const onSubmitHandler = React.useMemo(() => handleSubmit(arg => {
    setIsSubmitting(true);

    onSubmit({
      ...arg,
      outcome: JSON.parse(arg.outcome),
      payload: JSON.parse(arg.payload),
    });
  }), [onSubmit, handleSubmit]);


  const circularProgressStyle = React.useMemo< any >(() => ({
    width: 30,
    height: 30,
    visibility: generating ? 'visible' : 'hidden',
  }), [generating]);

  return (
    <Wrap className={className} onSubmit={onSubmitHandler}>
      <GridWrap container direction='column' wrap='nowrap' spacing={6}>

        <Grid container item spacing={6}>
          <Grid item xs={6}>
            <Controller
              control={control}
              name='name'
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  fullWidth
                  variant='outlined'
                  value={value}
                  label='Name *'
                  // style={marginBottom1Rem}
                  onChange={onChange}
                  className={classes[ '>name' ]}
                  error={error !== undefined}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Controller
              control={control}
              name='goal'
              render={({ field: { value }, fieldState: { error } }) => (
                <StyledAutocomplete
                  // @ts-ignore
                  options={goals}
                  // @ts-ignore
                  value={goals.find(i => i.value === value) || null}
                  getOptionLabel={option => option.label}
                  renderInput={
                    params => (
                      <TextField
                        {...params}
                        label='Goal *'
                        variant='outlined'
                        error={error !== undefined}
                        helperText={error?.message}
                      />
                    )
                  }
                  onChange={onGoalChange}
                  groupBy={e => e.group}
                  className={classes[ '>goal' ]}
                />
              )}
            />
          </Grid>
        </Grid>

        <JsonRow container item spacing={6}>
          <JsonWrap container direction='column' item xs={5}>
            <InputLabel
              className={classes[ '>jsonLabel' ]}
              style={marignBottom05Rem}
            >
              Data payload
            </InputLabel>
            <CommonJsonWrap className={classes[ '>json' ]}>
              <Controller
                control={control}
                name='payload'
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <>
                    <JsonEditorV2
                      v={value}
                      onChange={(...args) => {
                        onChange(...args);
                        setPayloadUsedForGenerate('');
                      }}
                    />
                    {error && (
                      <FormHelperText error style={{ margin: '0.25rem 1rem 0' }}>
                        {error.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </CommonJsonWrap>
          </JsonWrap>

          <GenerateWrap item container xs={2} direction='column' alignItems='center' justifyContent='center'>
            {onGenerateHandler && (
              <>
                <GenerateButton
                  disabled={generateDisabled || generating}
                  onClick={onGenerateHandler}
                >
                  Generate output
                </GenerateButton>
                <CircularProgress style={circularProgressStyle} />
              </>
            )}
          </GenerateWrap>

          <JsonWrap container direction='column' item xs={5}>
            <InputLabel
              style={marignBottom05Rem}
              className={classes[ '>jsonLabel' ]}
            >
              Outcome
            </InputLabel>
            <CommonJsonWrap className={classes[ '>json' ]}>
              <Controller
                control={control}
                name='outcome'
                render={({ field: { value }, fieldState: { error } }) => (
                  <>
                    <JsonEditorV2
                      v={value}
                      disabled
                    />
                    {error && (
                      <FormHelperText error style={{ margin: '0.25rem 1rem 0' }}>
                        {error.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </CommonJsonWrap>
          </JsonWrap>
        </JsonRow>

        <Grid
          item
          container
          justifyContent='flex-end'
          alignItems='center'
          className={classes[ '>btns' ]}
          style={{ gap: '1rem' }}
        >
          {isSubmitting && <CircularProgress style={{ width: 30, height: 30 }} />}

          <PrimaryButton type='submit' disabled={isSubmitting || saveIsDisabled || generating}>
            Submit
          </PrimaryButton>
        </Grid>
      </GridWrap>
    </Wrap>
  );
});
_.displayName = 'Components/ManageTestScenario2';
