import React from "react";
import type jspreadsheet from "jspreadsheet";


export type JSpreadSheetWrapCtxValue = {
  value: {
    worksheets: jspreadsheet.worksheetInstance[];
    holderRef: HTMLDivElement;
    selection: (
      | null
      | { px: number, py: number, ux: number, uy: number }
    );
    ctxMenuParts: null | {
      x: number;
      y: number;
      Comp: React.ComponentType<{ close: () => unknown }>;
    };
    /**
     * sometimes we want to have some helper state, that would\
     * help with rendering and other jspreadsheet logic (for \
     * example real async pagination: we would store total pages\
     * and current page here and that wuld allow to render
     * pagination buttons. NOTE that we can't use out of the box\
     * JSpreadsheet pagination as that one seems to be client only).\
     * So this is where it should be placed.\
     * We could have made this type a generic template type, but\
     * from experience with TreeData, it adds more complexity and \
     * also we are always aware of the type of helperState property\
     * in context of a particular instance (and for datatable \
     * documents, for example, this will 100% hold pagination data)
     */
    helperState: Record< string, unknown >;
  };
  // setValue: ( v: JSpreadSheetWrapCtxValue[ "value" ] ) => unknown;
  setValueCb: ( cb: ( v: JSpreadSheetWrapCtxValue[ "value" ] ) => typeof v ) => void;
  valueRef: { current: JSpreadSheetWrapCtxValue[ "value" ] };
}

const defaultVal: JSpreadSheetWrapCtxValue[ "value" ] = {
  worksheets: [],
  holderRef: document.createElement( "div" ),
  selection: null,
  ctxMenuParts: null,
  helperState: {},
};
export const defaultJSpreadSheetCtxValue: JSpreadSheetWrapCtxValue = {
  value: defaultVal,
  // setValue: console.info,
  setValueCb: () => defaultVal,
  valueRef: { current: defaultVal }
};

export const JSpreadSheetWrapCtx = React.createContext( defaultJSpreadSheetCtxValue );
export const useJSpreadSheetWrapCtx = () => React.useContext( JSpreadSheetWrapCtx );

export const WithJSpreadSheetWrapCtx: React.FC< React.PropsWithChildren > = React.memo(p => {
  const [ value, setValue ] = React.useState( defaultJSpreadSheetCtxValue.value );
  const valueRef = React.useRef(value);
  if (valueRef.current !== value) valueRef.current = value;

  const ctxValue = React.useMemo< JSpreadSheetWrapCtxValue >(
    () => ({ value, setValueCb: setValue, valueRef }),
    [ value, valueRef, setValue ]
  );

  return (
    <JSpreadSheetWrapCtx.Provider value={ ctxValue }>
      { p.children }
    </JSpreadSheetWrapCtx.Provider>
  );
});
WithJSpreadSheetWrapCtx.displayName = "components/JSpreadSheetWrap/Ctx/WithJSpreadSheetWrapCtx";
