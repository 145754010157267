import React from 'react';
import { InterviewDesignDatePicker } from '@components/InterviewDesignDatePicker';


export const JSX = React.createElement(() => {
  const [ value, setValue ] = React.useState( '' );

  return (
    <InterviewDesignDatePicker
      value={ value }
      setValue={ setValue }
    />
  );
});
