import { useState } from 'react';
import styled from 'styled-components';

const Input = styled.input`
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  width: 100%;
  border: none;
  padding: 0;
  background: none;
`;

export type InlineEditProps = {
  value: string;
  onChange: (v: string) => unknown;
} & any;

export const InlineEdit = ({ value, onChange, ...props }: InlineEditProps) => {
  const [state, setState] = useState(value);
  const handleChange = (e) => {
    const { value } = e.currentTarget;
    setState(value);
    onChange(value);
  };

  return <Input value={state} onChange={handleChange} {...props} />;
};
InlineEdit.displayName = 'components/InlineEdit';
