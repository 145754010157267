/* eslint-disable camelcase */
import { all, select, put, takeLatest, delay } from 'redux-saga/effects';
import dagre from 'dagre';
import groupBy from 'lodash/groupBy';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import set from 'lodash/set';
import find from 'lodash/find';
import { v4 as uuid } from 'uuid';
import * as reducer from './reducer';
import {
  HashOfNodeIdToNode,
  ITreeViewNode,
  aggregateNodeChildren,
  deriveHashOfNodeIdToNodeFromNode,
  gatherAllIds,
  gatherLevel1Ids,
  filterBySearch,
  gatherHashOfNodeIdToParentId,
} from './__treeViewNode';
import { holder, getSelectedNode, getTree, gatherAncestors } from './__holder';
import { isTimePoints, timePointsToRanges } from '@components/TemporalValue/TemporalEditor/temporalOps';


const batchCall = (releaseId: string, id: string, payload: any, index: any) => ({
  type: 'DCSVLY/BATCHCALL',
  release: releaseId,
  id,
  payload,
  index,
});


const g = dagre.graphlib;
if(g === undefined) {
  throw new Error('graphLib is undefined');
}


const graphLib = g as any;


function* maybeDeriveFields(props:any): Gen {
  const state = yield select();
  const rawGraph = props.payload?.graph;
  const currentAttribute = getSelectedNode(reducer.getState(state));

  if(currentAttribute === null) return;

  const releaseId = state.scope.release;
  if(!releaseId) return;

  const maybeRelease = state.resources.releases.data[ releaseId ];
  if(!maybeRelease) return;


  try {
    console.log('here?', rawGraph);
    const graph = graphLib.json.read(rawGraph);
    if (!graph) return;

    const nodes: reducer.Template[] = [];
    const children: string[] = graph.successors(currentAttribute.id);

    children.forEach(c => {
      const node = graph.node(c);

      nodes.push(node);
    });

    const groupped = groupBy(nodes, 'entity');

    const templatesByEntity = Object.keys(groupped).reduce(
      (a, entity) => a.concat({
        entity,
        templates: [...groupped[ entity ]],
      }),
      [] as reducer.IState[ 'data' ][ 'templatesByEntity' ],
    );

    const values: reducer.IState[ 'data' ][ 'values' ] = templatesByEntity.map(
      it => ({
        entity: it.entity,
        rows: [
          {
            id: uuid(),
            values: it.templates.map(template => {
              const k: reducer.IState[ 'data' ][ 'values' ][ 0 ][ 'rows' ][ 0 ][ 'values' ][ 0 ] = {
                templateId: template.id,
                value: template.type === 'boolean' ? false : '',
              };

              return k;
            }),
          },
        ],
      }),
    );


    yield put(reducer.ActionCreators.setData({ templatesByEntity, values }));
  } catch (error) {
    console.error('maybeDeriveFields saga err:', error);
  }
}


export const storeDataToServerData: (
  // eslint-disable-next-line no-unused-vars
  (values: reducer.IState[ 'data' ]['values'], attributeEntity: string) => Record< string, any >
) = (values, attributeEntity) => {
  const entityNamesToAggregatedRows: { [ entityName: string ]: Array<Record< string, any >> } = values.reduce(
    (a, v) => ({
      ...a,
      [ v.entity ]: v.rows.map((row, i) => {
        const withId = { '@id': i + 1 };

        return row.values.reduce(
          (a, v) => ({
            ...a,
            [ v.templateId ]: isTimePoints(v.value)
              ? timePointsToRanges(v.value)
              : v.value,
          }),
          withId,
        );
      }),
    }),
    {},
  );

  const entityNames = Object.keys(entityNamesToAggregatedRows);
  if(entityNames.length > 1) return entityNamesToAggregatedRows;

  const entityName = entityNames[ 0 ];

  if(entityName !== attributeEntity) return entityNamesToAggregatedRows;

  const toRtrn = entityNamesToAggregatedRows[ entityName ][ 0 ] || {};
  delete toRtrn[ '@id' ];

  return toRtrn;
};


// ===================================================================================


export function* saga(): Gen {
  yield all([
    takeLatest(
      [reducer.ActionTypes.setSelectedId, reducer.ActionTypes.requestToGoBack],
      maybeDeriveFields,
    ),
    takeLatest(reducer.ActionTypes.requestToSubmitCustomTest, function* onReqToSubmitCustomTest(): Gen {
      const state = yield select();
      const { release: releaseId } = state.scope;
      const { data: { values } } = reducer.getState(state);

      const currentAttribute = getSelectedNode(reducer.getState(state));
      if(currentAttribute === null || currentAttribute.duplicate === true) return;


      let data = storeDataToServerData(values, currentAttribute.entity);

      const goal = currentAttribute.id;
      if (currentAttribute.entity !== 'global') {
        const release = state.resources.releases.data[ releaseId ];

        // We have to work out where an instance of this would occur
        // We have to basically create the entire structure down to this instance
        const new_data: any = {};
        let goal_path = '';
        const buildRelTree = (rel: any) => {
          let path = '';

          if (rel.source === 'global') {
            new_data[ rel.name ] = [{
              '@id': 1,
            }];
            path = `${ rel.name }.0`;
            goal_path = `${ rel.name }/1`;
          } else {
            // This is a sub child - traverse up
            const entity = find(release.relationships, { source: rel.source });
            path = `${ buildRelTree(entity) }.${ rel.name }`;
            set(new_data, path, [{
              '@id': 1,
            }]);
            goal_path = `${ goal_path }/${ rel.name }/1`;
          }

          return path;
        };
        const entity = find(release.relationships, { name: currentAttribute.entity });
        if (!entity) throw new Error('Invalid goal');

        // THis basically creates a payload structure that better aligns with the entity structure.
        const new_path = buildRelTree(entity);
        if (!get(data, new_path)) {

          // let new_data = {};
          // Now modify their data and goal so we search against the correct node
          set(new_data, new_path, data);
          // goal = `${goal_path}/${goal}`;
          data = new_data;
        }

      }

      yield put(
        batchCall(
          releaseId,
          currentAttribute.id,
          {
            mode: 'test',
            audit: 'return',
            goal,
            data,
          },
          'custom',
        ),
      );
    }),
    takeLatest(reducer.ActionTypes.requestToHydrateTree, function* onRequestToHydrateTree(
      a: reducer.IActions[ 'requestToHydrateTree' ],
    ): Gen {
      const store: any = yield select();
      const curRoots = reducer.getState(store).treeRoots;

      const maybeGraph = (() => {
        try {
          return dagre.graphlib.json.read(a.payload.g);
        } catch (error) {
          console.error('GraphTree read err:', error);
          return null;
        }
      })();

      if(maybeGraph === null) return;


      const sourceIds = maybeGraph.sources() as unknown[] as string[];

      const usedIds = {};
      const ns = sourceIds.reduce< ITreeViewNode[] >(
        (a, id) => a.concat(aggregateNodeChildren(maybeGraph, id, usedIds) || []),
        [],
      );

      const nodeIdToNode: HashOfNodeIdToNode = ns.reduce(
        (a, n) => ({
          ...a,
          ...deriveHashOfNodeIdToNodeFromNode(n),
        }),
        {},
      );

      const nodeIdToParentId = gatherHashOfNodeIdToParentId(ns);

      holder.set(nodeIdToNode);
      holder.setParentHash(nodeIdToParentId);

      const nextRoots = ns.map(it => it.id);

      const rootsDiffer = (() => {
        if(a.payload.releaseChanged) return true;

        if(nextRoots.length === 0) {
          return curRoots.length !== 0;
        }

        const [id] = nextRoots;
        return curRoots.some(it => it === id) === false;
      })();

      yield put(reducer.ActionCreators.hydrateTree({
        roots: nextRoots,
        rootsOnly: rootsDiffer ? undefined : true,
      }));

      if(rootsDiffer === false) return;

      const fullTree = getTree(nextRoots);

      const level1Expanded = fullTree.reduce< string[] >(
        (a, node) => a.concat(gatherLevel1Ids(node)),
        [],
      );

      yield put(reducer.ActionCreators.setExpanded(level1Expanded));
    }),
    takeLatest(reducer.ActionTypes.requestToSetSearch, function* onRequestToSetSearch(
      { payload: { search: nextSearch, immediate } }: reducer.IActions[ 'requestToSetSearch' ],
    ) {
      yield put(reducer.ActionCreators.setSearch(nextSearch));

      if(immediate === undefined) yield delay(800);

      const state = yield select();
      const fullTree = getTree(reducer.getState(state).treeRoots);

      const nextExpanded = (() => {
        if(nextSearch === '') {
          return fullTree.reduce< string[] >(
            (a, node) => a.concat(gatherLevel1Ids(node)),
            [],
          );
        }

        const { filteredTree } = filterBySearch(fullTree, nextSearch);
        if(!filteredTree || filteredTree.duplicate === true) return [];

        return filteredTree.children.reduce< string[] >(
          (a, node) => a.concat(gatherAllIds(node)),
          [],
        );
      })();

      yield put(reducer.ActionCreators.setPropagatableSearch(nextSearch));
      yield put(reducer.ActionCreators.setExpanded(nextExpanded));
    }),
    takeLatest(reducer.ActionTypes.requestToSetSelectedIdWithReveal, function* onRequestToSetSelectedIdWithReveal(
      { payload }: reducer.IActions[ 'requestToSetSelectedIdWithReveal' ],
    ) {
      const { id: nextSelectedId } = payload;
      if(nextSelectedId === null) return;

      const { expanded }: ReturnType< typeof reducer.getState > = yield select(reducer.getState);

      const ancestors = gatherAncestors(nextSelectedId);

      yield put(reducer.ActionCreators.setExpanded(
        uniq(expanded.concat(ancestors)),
      ));
      yield put(reducer.ActionCreators.setSelectedId(payload));
      yield put(reducer.ActionCreators.setSearch(''));
      yield put(reducer.ActionCreators.setPropagatableSearch(''));

      yield delay(600);

      const wrap = holder.getWrap().current;
      const el = wrap?.querySelector(`[data-treeid="${ nextSelectedId }"]`);
      el?.scrollIntoView();
    }),
    takeLatest(reducer.ActionTypes.requestToGoBack, function* onRequestToGoBack() {
      const { history }: ReturnType< typeof reducer.getState > = yield select(reducer.getState);
      const lastEntry = history[ history.length - 1 ];

      if(!lastEntry) return;

      yield put(reducer.ActionCreators.popFromHistory());
      yield put(reducer.ActionCreators.requestToSetSelectedIdWithReveal({ id: lastEntry, skipHistory: true }));
    }),
  ]);
}
