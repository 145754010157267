import graphlib from "@dagrejs/graphlib";
import { NAMESPACE_OPT_IN, type ParsedRuleGraph } from "@packages/commons";
import type { CommandExpression, Expression } from "@packages/compiler";
import { createCompilerContext, parse } from "@packages/compiler";
import { useGraph } from "@pages/models/release/GraphContext";
import { useMemo } from "react";

export const parseExpressions = (text: string, expressions: Expression[], depth = 0) => {
  return expressions
    .flatMap((expr) => {
      switch (expr.type) {
        case "literal":
        case "attribute":
        case "relationship":
          // case "comment":
          return {
            type: expr.type,
            token: expr,
            start: expr.start,
            end: expr.end,
            depth,
          };
        case "command": {
          const nodes: any[] = [];
          nodes.push({
            type: expr.type,
            token: expr,
            start: expr.start,
            end: expr.end,
            depth,
          });
          const fnText = text.substring(expr.start, expr.end);
          // if using foo(...) notation
          if (!expr.natural) {
            nodes.push({
              type: "command-fn",
              token: expr,
              start: expr.start,
              end: expr.start + fnText.indexOf("("),
              depth,
            });
          }
          nodes.push(...parseExpressions(text, (expr as CommandExpression).arguments, depth + 1));
          return nodes;
        }
        default:
          console.log("UNSUPPORTED SYNTAX EXPRESSION", expr);
          return null;
      }
    })
    .filter((n) => !!n);
};

export const useSyntaxHighlight = (release: any, documentName: string | undefined) => {
  const rawGraph = useGraph();

  const graph = useMemo(
    () =>
      rawGraph
        ? /** @type { graphlib.Graph<{ id: String; description?: string }> } */ (
            graphlib.json.read(rawGraph) as ParsedRuleGraph
          )
        : null,
    [rawGraph],
  );

  const enableNamespaces = release?.model_name?.includes(NAMESPACE_OPT_IN);

  const context = useMemo(
    () =>
      createCompilerContext({
        graph: graph || undefined,
        relationships: release?.relationships ?? [],
        documentName,
        enableNamespaces,
      }),
    [release, documentName, graph],
  );

  return (input: string) => parse({ input, context });
};
