import type { Action } from "@reduxjs/toolkit";
import type { DebugPayloadUtils } from "../../../Debug/EEContext";
import { REDUX_PROP } from "../slice";


export const sagaANames = {
  requestToSetGoal: `${ REDUX_PROP }/requestToSetGoal`,
  requestToEncloseReset: `${ REDUX_PROP }/requestToEncloseReset`,
  requestToSetGoalIdAfterBatchCall: `${ REDUX_PROP }/requestToSetGoalIdAfterBatchCall`,
  requestToExportRawPayload: `${ REDUX_PROP }/requestToExportRawPayload`,
  requestToImportRawPayload: `${ REDUX_PROP }/requestToImportRawPayload`,
} as const;

export type SagaActions = {
  requestToSetGoal: {
    type: typeof sagaANames.requestToSetGoal;
    payload: {
      goalId: string | null;
    }
  };
  requestToEncloseReset: {
    type: typeof sagaANames.requestToEncloseReset;
    payload: {
      reset: ReturnType< DebugPayloadUtils[ "useReset" ] >
    }
  };
  /**
   * welp, what this should basically do is init a saga, that will\
   * start waiting for "DCSVLY/BATCHCALL" event, which signalizes \
   * that we are querying BE with request to process batch. When \
   * this happens, we will extract goal id from raw payload and \
   * set that in redux. \
   * After that when batch_call succeeds, it will\
   * setGraph in local state of [BatchDebugV2](../BatchDebugV2__Old.jsx) \
   * (which is kinda unfortunate legacy decision, but rewriting that\
   * now would take a lot of resources), and inside that setGraph we \
   * also try to set current focus node based on currently selected \
   * goal (which we just set through our requestToSetGoalIdAfterBatchCall\
   * saga). And thus we kinda-sorta get expected behavior from pasting\
   * raw with goalId and not selecting goal in goal select.
   */
  requestToSetGoalIdAfterBatchCall: {
    type: typeof sagaANames.requestToSetGoalIdAfterBatchCall;
  };
  requestToExportRawPayload: {
    type: typeof sagaANames.requestToExportRawPayload;
    payload: { fileName: string; hideModal: () => Action; payload?: any };
  };
  requestToImportRawPayload: {
    type: typeof sagaANames.requestToImportRawPayload;
    payload: { value: string; }
  };
}

export type ACreatorsT = {
  [ K in keyof SagaActions ]: SagaActions[ K ] extends { payload: unknown }
    ? (p: SagaActions[ K ][ "payload" ]) => SagaActions[ K ]
    : () => SagaActions[ K ]
};
export const sagaACreators: ACreatorsT = {
  requestToSetGoal: p => ({ type: sagaANames.requestToSetGoal, payload: p }),
  requestToEncloseReset: p => ({ type: sagaANames.requestToEncloseReset, payload: p }),
  requestToSetGoalIdAfterBatchCall: () => ({ type: sagaANames.requestToSetGoalIdAfterBatchCall }),
  requestToExportRawPayload: p => ({ type: sagaANames.requestToExportRawPayload, payload: p }),
  requestToImportRawPayload: p => ({ type: sagaANames.requestToImportRawPayload, payload: p }),
};
