import { createGlobalStyle, css } from 'styled-components';
import { cName } from './constants';

export const scrollableMixin = css`
  --thumb-color: #a3a3a3;

  /* Web standard, firefox only */
  scrollbar-width: auto;
  scrollbar-color: transparent transparent;
  overflow: overlay;
  scrollbar-gutter: stable;

  &::-webkit-scrollbar {
    width: 1rem;
    height: 1rem;
    z-index: 9999;
  }

  &::-webkit-scrollbar-corner {
    background: rgba(0,0,0,0);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 9999px;
    border: 4px solid transparent;
    background-clip: padding-box;
    background-color: var(--thumb-color);
    display: none;
  }

  &:hover {
    scrollbar-color: var(--thumb-color) transparent;
    &::-webkit-scrollbar-thumb {
      display: block;
    }
  }
`;

export const Style = createGlobalStyle`
  .${ cName } {
    ${ scrollableMixin };
  }
`;

export const scrollable = cName;
