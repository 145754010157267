import React from 'react';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { all, delay, race, take, takeLatest, put, call } from 'redux-saga/effects';
import { CRUD_GET_ONE_SUCCESS, hideModal, CRUD_UPDATE_SUCCESS } from '@imminently/imminently_platform';
import { defaultJSpreadSheetCtxValue, useJSpreadSheetWrapCtx } from '@components/JSpreadSheetWrap/Ctx';
import { cleanupJSpreadsheetData } from '../utils';
import { defaultDataTableJSpreadsheetHelperState, getDataTableJSpreadsheetHelperState } from '../JSpreadsheetHelperState';
import {
  aTypes,
  type Actions,
  aCreators,
} from './constants';
import { setNewPage } from './setNewPage';


export function* saga() {
  let valueRef = defaultJSpreadSheetCtxValue.valueRef;
  let setValueCbRef = defaultJSpreadSheetCtxValue.setValueCb;

  yield all([
    takeLatest( aTypes.captureRefs, function* onCaptureRef( { payload: p }: Actions[ 'captureRefs' ] ) {
      valueRef = p.valueRef;
      setValueCbRef = p.setValueCb;

      yield put(aCreators.signalizeThatRefsAreCaptured( p ));
    } ),
    /**
     * to properly bind jspreadsheet to currently selected datatable\
     * document we will listne for crud_get_one_success action and \
     * will populate jspreadsheet instance using ref variable. It's\
     * a lot better than to bind in useEffect or smth as this \
     * establishes proper unidirectional flow of change (where statful\
     * jspreadsheet instance is a single source of truth) and it \
     * just needs to be bootstrapped once, when we load document \
     * after selecting it in left side menu
     */
    takeLatest( CRUD_GET_ONE_SUCCESS, function* hydrateDataTable(a) {
      const resourceName = get( a, [ 'meta', 'resource' ] );
      const docType = get( a, [ 'payload', 'data', 'type' ] );
      if ( resourceName !== 'documents' || docType !== 'datatable' ) return;


      /**
       * it happens so that success response returns before captureRef\
       * action is processed. So we will wait a bit (or timeout) and \
       * proceed only then.
       */
      if( valueRef.current.worksheets.length === 0 ) {
        const res = yield race({
          action: take( a => {
            const typed = a as Actions[ 'signalizeThatRefsAreCaptured' ];
            return typed.type === aTypes.signalizeThatRefsAreCaptured
              && typed.payload.valueRef.current.worksheets.length !== 0;
          } ),
          timeout: delay( 2_000 ),
        });

        if( res.timeout ) return;
      }

      const [ first ] = valueRef.current.worksheets;
      if( !first ) return;


      const columns: string[] = get( a, [ 'payload', 'data', 'contents', 'headers' ], ['__col1'] );
      const data = cleanupJSpreadsheetData(
        get( a, [ 'payload', 'data', 'contents', 'rows' ] ),
        columns.length
      );

      const config = first.getConfig();
      const colNumToDelete = (config.columns || []).length;

      /**
       * we do this "insert then delete" because there is no\
       * set columns and using columns through "setConfig" does\
       * not seem to work
       */
      columns.forEach((col, i) => {
        first.insertColumn([{
          options: {
            title: col,
            align: 'left',
            type: 'text',
            wrap: true,
            width: 200,
          }
        }]);
      });
      first.deleteColumn(0, colNumToDelete);

      first.deleteRow( 0, (first.getData() || [])?.length );
      first.setData( data );

      const nextHelperState: typeof defaultDataTableJSpreadsheetHelperState = {
        ...defaultDataTableJSpreadsheetHelperState,
        documentId: get( a, [ 'payload', 'data', 'id' ], '' )
      };
      setValueCbRef(v => ({ ...v, helperState: nextHelperState }));
    }),

    takeLatest( aTypes.requestToLoadPrevPage, function* onRequestToLoadPrevPage() {
      const hState = getDataTableJSpreadsheetHelperState( valueRef.current.helperState  );
      if( hState.page === 1 ) return;


      yield call( setNewPage, {
        helperState: hState,
        /**
         * we subtract 2 because
         * - page * perPage is an offset for the next page\
         * - (page - 1) * perPage is an offset for current page
         */
        offset: ( hState.page - 2 ) * hState.perPage,
        setNextHelperState: v => setValueCbRef(prev => ({ ...prev, helperState: v }) ),
        worksheets: valueRef.current.worksheets,
      } );
    } ),

    takeLatest( aTypes.requestToLoadNextPage, function* onRequestToLoadNextPage() {
      const hState = getDataTableJSpreadsheetHelperState( valueRef.current.helperState  );
      if( hState.page === hState.lastPage ) return;


      yield call( setNewPage, {
        helperState: hState,
        offset: hState.page * hState.perPage,
        setNextHelperState: v => setValueCbRef(prev => ({ ...prev, helperState: v }) ),
        worksheets: valueRef.current.worksheets,
      } );
    }),

    takeLatest( aTypes.requestToRefreshCurPage, function* onRequestToRefreshCurrent() {
      const hState = getDataTableJSpreadsheetHelperState( valueRef.current.helperState  );

      const reaceResult = yield race({
        action: take( a => a.type === CRUD_UPDATE_SUCCESS && get( a, ['meta', 'resource'] ) === 'documents' ),
        timeout: delay( 5_000 ),
      });
      if ( reaceResult.timeout ) return;


      yield call( setNewPage, {
        helperState: hState,
        offset: (hState.page - 1) * hState.perPage,
        setNextHelperState: v => setValueCbRef(prev => ({ ...prev, helperState: v }) ),
        worksheets: valueRef.current.worksheets,
      } );
      yield put( hideModal() );
    }),
  ]);
}


//# region EncloseRefComp

export const EncloseRefComp = React.memo(() => {
  const dispatch = useDispatch();
  const { valueRef, value, setValueCb } = useJSpreadSheetWrapCtx();

  React.useEffect(() => {
    dispatch(aCreators.captureRefs({ setValueCb, valueRef }));

    return () => {
      const { setValueCb, valueRef } = defaultJSpreadSheetCtxValue;
      dispatch(aCreators.captureRefs({ setValueCb, valueRef }));
    };
  }, [ valueRef, dispatch, value, setValueCb ]);

  return null;
});
EncloseRefComp.displayName = 'release/Documents/DataTable/saga/EncloseRefComp';

//# endregion
