import isHotkey from "is-hotkey";
import { useEffect } from "react";

export const useHotkey = (keys: string, action: () => void, enabled = true) => {
  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (enabled && isHotkey(keys, e)) {
        e.preventDefault();
        e.stopPropagation();
        action();
      }
    };
    document.addEventListener("keydown", handler);
    return () => document.removeEventListener("keydown", handler);
  }, [keys, action, enabled]);
};
