import produce from 'immer';
import { ReleaseTestCasesNS } from '@packages/commons';
import type { TestCaseSortTItem } from '../../types'
import { predicateFindSortTree } from '../predicateFindSortTree';


const gatherExpandedValues = (
  root: TestCaseSortTItem,
  acc: Map< string, boolean > = new Map()
): typeof acc => {
  acc.set(root.__testCaseTreeItemId, Boolean(root.expanded));
  if(root.children.length === 0) return acc;

  return root.children.reduce(
    (a, child) => gatherExpandedValues(child, a),
    acc,
  );
}


export type ToSortableTreeArg = {
  root: ReleaseTestCasesNS.TestCaseTItem;
  options?: {
    prevSortRoot?: TestCaseSortTItem;
    persistExpanded?: true;
  }
};
export type ToSortableTreeRtrn = TestCaseSortTItem;

export const toSortableTree = ( { root, options }: ToSortableTreeArg ): ToSortableTreeRtrn => {
  const nextRoot = {
    __testCaseTreeItemId: root.id,
    children: root.type === 'payload' ? [] : root.children.map( child => toSortableTree( { root: child, options } ) )
  };

  return produce(nextRoot, draft => {
    if(options === undefined) return;

    const { persistExpanded, prevSortRoot } = options;
    if(persistExpanded === true && prevSortRoot !== undefined) {
      const expandedValues = gatherExpandedValues(prevSortRoot);

      [ ...expandedValues.entries() ].forEach(([id, expanded]) => {
        const maybeItem = predicateFindSortTree({ treeItem: draft, predicate: it => it.__testCaseTreeItemId === id });
        if( maybeItem ) maybeItem.expanded = expanded || undefined;
      });
    }
  });
};
