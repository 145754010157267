export const attributeMatchesRegEx = /{{(.*?)}}/g;

export const trimAttributeInterpolation = (v: string): string => {
  const attributeMatches = v.matchAll(attributeMatchesRegEx);

  const valueWithNormalizedAttributes = [...attributeMatches].reduce((a, matchArr) => {
    const [matchStr] = matchArr;
    const cleanedMatch = matchStr.slice(2, -2);
    if (cleanedMatch === "") return a;

    const matchTrimmed = cleanedMatch.trim();
    if (matchTrimmed === cleanedMatch) return a;

    return a.replace(matchStr, `{{${matchTrimmed}}}`);
  }, v);

  return valueWithNormalizedAttributes;
};
