import React from "react";
import styled from "styled-components";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { CloseIcon, LoadingDotsJSX } from "@icons";
import { useTestCasesRootCtx } from "../RootCtx";
import produce from "immer";
import { TextField } from "@components/input";
import { PrimaryButton } from "@components/buttons";
import * as Yup from "yup";
import type { RequiredStringSchema } from "yup/lib/string";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";



const Wrap = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 2rem;
  width: 400px;

  .MuiFormHelperText-root.Mui-error.Mui-error {
    color: #E52917 !important;
  }
`;
const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type RhfFormData = { value: string };
export type SingleFieldFormProps = {
  title: string;
  initialValue: string;
  placeholder?: string;
  schema: RequiredStringSchema< string >;
  onSubmit: (value: string) => Promise< unknown >;
}
export const SingleFieldForm: React.FC< SingleFieldFormProps > = React.memo(p => {
  const { title, initialValue, schema: stringSchema, placeholder, onSubmit } = p;
  const { setValue: setRootCtxValue } = useTestCasesRootCtx();

  const close = React.useCallback(() => (
    setRootCtxValue(prev => produce(prev, draft => {
      draft.testCasesTreeCompState.dialogContents = null
    }))
  ), [setRootCtxValue]);


  const defaultValues = React.useMemo< RhfFormData >(() => ({ value: initialValue }), [ initialValue ]);
  const schema = React.useMemo< Yup.SchemaOf< RhfFormData > >(() => Yup.object().shape({
    value: stringSchema,
  }), [ stringSchema ]);
  const { handleSubmit, control, formState: { isSubmitting } } = useForm({
    defaultValues,
    resolver: yupResolver( schema ),
  });

  const onSubmitHandler = React.useMemo< React.FormEventHandler<HTMLFormElement> >(
    () => handleSubmit(
      data => onSubmit(data.value),
      e => console.error('7ANHESmXrh | Rhf invalid: ', e)
    ),
    [onSubmit]
  );

  return (
    <Wrap onSubmit={onSubmitHandler}>
      <TopRow>
        <Typography variant='h3'>
          { title }
        </Typography>

        <IconButton onClick={ close }>
          <CloseIcon />
        </IconButton>
      </TopRow>

      <Controller
        control={control}
        name="value"
        render={({ field: { value, onChange }, fieldState: { error } }) =>(
          <TextField
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            error={Boolean(error)}
            helperText={error && error.message}
          />
        )}
      />


      <PrimaryButton
        style={{ alignSelf: 'flex-end' }}
        type='submit'
        disabled={isSubmitting}
      >
        { isSubmitting ? LoadingDotsJSX : 'Submit' }
      </PrimaryButton>
    </Wrap>
  );
});
SingleFieldForm.displayName = 'TestCasesTree/Comp/SingleFieldForm';
