import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import * as DottedTreeNS from '@components/DottedTree';
import React from 'react';
import { AlertIcon } from '@icons';


type T = { test: 1 };
type Props = DottedTreeNS.Props< T >;

const getRandomBetween = (min: number, max: number) => Math.floor(Math.random() * (max - min)) + min;

const LabelComp: Props[ 'LabelComp' ] = React.memo(({ id }) => {
  const { getIconById, onSelect } = DottedTreeNS.CtxNS.useDottedTreeCtx< T >();
  const iconJSX = getIconById(id);
  const handleRootClick = React.useCallback(() => onSelect(id), [id, onSelect]);

  return (
    <Box display='flex' gridGap='0.25rem' alignItems='center' onClick={handleRootClick}>
      { iconJSX }

      <DottedTreeNS.LabelComponentPartsNS.VerifyTexts
        heading={id}
        desc={(
          <Typography variant='caption' style={{ fontStyle: 'italic' }}>
            (
            {getRandomBetween(0, 100)}
            %
            {' '}
            children verified)
          </Typography>
        )}
      />

      <DottedTreeNS.LabelComponentPartsNS.CollapseToggle id={id} />
    </Box>
  );
});
LabelComp.displayName = 'pages/design/DottedTree/LabelComp';


export const DottedTreeJSX = React.createElement(() => {
  const [expanded, setExpanded] = React.useState< Props[ 'expanded' ] >([]);
  const toggleExpanded = React.useCallback< Props[ 'toggleExpanded' ] >(id => (
    setExpanded(s => (s.some(it => it === id) ? s.filter(it => it !== id) : s.concat(id)))
  ), []);

  const [selected, setSelectedRaw] = React.useState< Props[ 'selected' ] >([]);
  const onSelect = React.useCallback< Props['onSelect'] >(id => setSelectedRaw([id]), []);

  const props = React.useMemo< Props >(() => ({
    items: [
      {
        id: '1',
        info: { test: 1 },
        children: [
          { id: '1.1', info: { test: 1 }, children: [] },
          {
            id: '1.2',
            info: { test: 1 },
            children: [
              { id: '1.2.1', info: { test: 1 }, children: [] },
              { id: '1.2.2', info: { test: 1 }, children: [] },
              { id: '1.2.3', info: { test: 1 }, children: [] },
            ],
          },
          {
            id: '1.3',
            info: { test: 1 },
            children: [
              { id: '1.3.1', info: { test: 1 }, children: [] },
              {
                id: '1.3.2',
                info: { test: 1 },
                children: [
                  { id: '1.3.2.1', info: { test: 1 }, children: [] },
                  { id: '1.3.2.2', info: { test: 1 }, children: [] },
                  { id: '1.3.2.3', info: { test: 1 }, children: [] },
                ],
              },
              { id: '1.3.3', info: { test: 1 }, children: [] },
            ],
          },
        ],
      },
    ],
    LabelComp,
    expanded,
    toggleExpanded,
    getIconById: () => <AlertIcon />,
    selected,
    onSelect,
  }), [expanded, toggleExpanded, selected, onSelect]);

  return (
    <Box height='15rem' overflow='auto' marginBottom='4rem' border='1px solid black' width='20rem'>
      <DottedTreeNS._ {...props} />
    </Box>
  );
});
