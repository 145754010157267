/** @typedef { true | false | undefined | null | number | string } PrimitiveValue */

/**
  @typedef Range
  @type { {
    v: PrimitiveValue | { increment: 'months' | 'years' | 'days'; offset?: number };
    r: {
      start: string;
      end: string;
    }
  } }
 */


/**
  @typedef TemporalValue
  @type { { temporal: { ranges: Range[] } } }
 */

/**
  @typedef Node
  @type { {
    children?: Node[];
    text: string;
    value: PrimitiveValue | TemporalValue;
    id: string;
  } }
 */

/**
  const payload1 = {
    'uuid1': false,
    'uuid2': 'temporal'
  }

  const payload2 = {
    'entityName': [
      {
        '@id': 1,
        'uuid1': false,
        'uuid2': 'temporal'
      }
    ]
  }
 */

/** @type { (arg: Node[ 'value' ]) => arg is TemporalValue } */
export const isTemporal = arg => (
  typeof arg === 'object' &&
  arg !== null &&
  'temporal' in arg
);
