/* eslint-disable camelcase */
import React from "react";
import { Scope } from "@common";
import styled from "styled-components";
import { Search as SearchC } from "@components";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";
import Box from "@material-ui/core/Box";
import * as redux from "../redux";


const SearchField = styled(SearchC)`
  margin-left: auto;
  width: 240px;
`;

const Search = () => {
  const searchInStore = useSelector(s => redux.getState(s).search);
  const dispatch = useDispatch();

  const [search, setSearch] = React.useState("");
  React.useEffect(() => setSearch(searchInStore), [searchInStore]);

  const updateSearchInRedux = React.useMemo(
    () => debounce(
      (s: string) => dispatch(redux.ActionCreators.requestToSetSearchAndLoadReports(s)),
      800,
    ),
    [dispatch],
  );


  const updateSearch = React.useCallback(
    value => {
      setSearch(value);
      updateSearchInRedux(value);
    },
    [setSearch, updateSearchInRedux],
  );

  const onClear = React.useCallback(() => (updateSearch("")), [updateSearch]);

  return <SearchField placeholder='Search reports' value={search} setValue={updateSearch} onClear={onClear} />;
};
Search.displayName = "decisions/Decisiondashboard/ScopeComponent/Search";


export const _ = () => {
  return (
    <Scope
      hideSearch={false}
    >
      <Box flexGrow='1' display='flex' justifyContent='flex-end'>
        <Search />
      </Box>
    </Scope>
  );
};
_.displayName = "decisions/Decisiondashboard/ScopeComponent";
