import {
  type PlateEditor,
  type TElement,
  type TNode,
  getNextNode,
  getNodeAncestors,
  getNodes,
  getParentNode,
  getPreviousNode,
  moveNodes,
  removeNodes,
  unwrapNodes,
} from "@udecode/plate";
import { type BaseEditor, type BaseElement, Editor, type NodeEntry, type Path } from "slate";
import { ELEMENT_OPERATOR } from "../elements";
import { ELEMENT_CONNECTOR } from "./connector";

export const OPERATOR = {
  AND: "and",
  OR: "or",
} as const;

export type OPERATOR = (typeof OPERATOR)[keyof typeof OPERATOR];

export interface TOperator extends TElement {
  type: typeof ELEMENT_OPERATOR;
  operator: OPERATOR;
}

export const createOperator = (children: any[] = [], operator: OPERATOR = OPERATOR.AND) => ({
  type: ELEMENT_OPERATOR,
  operator,
  children,
});

export const getOperatorLevel = (editor: PlateEditor, path: number[]) => {
  const [...nodes] = getNodeAncestors(editor, path);
  return nodes.filter((n) => n[0].type === ELEMENT_OPERATOR).length;
};

export const isWithinOperator = (editor: PlateEditor, path?: Path) =>
  getOperatorLevel(editor, path ?? editor.selection?.anchor.path ?? []) > 0;

// can only merge if they are both AND operators
// const canMergeOperators = (a: TOperator, b: TOperator) => a.operator === OPERATOR.AND && b.operator === OPERATOR.AND;
const canMergeOperators = (a: TOperator, b: TOperator) => a.operator === b.operator;

export const mergeOperators = (editor: PlateEditor, a: NodeEntry<TNode>, b: NodeEntry<TNode>) => {
  const [aNode, aPath] = a;
  const [, bPath] = b;

  const to = [...aPath, (aNode as TElement).children.length];

  // move operator into previous
  moveNodes(editor, { at: bPath, to });
  // since we merged b into a, a has not moved, so we want to unwrap the last element of a
  // which is where we moved it to
  unwrapNodes(editor, { at: to });
};

export const tryMergeOperator = (editor: PlateEditor, current: NodeEntry<TOperator>) => {
  try {
    const [node, path] = current;
    // if previous node is an operator, move this into it
    const [prev, prevPath] = getPreviousNode<TElement>(editor, { at: path }) ?? [undefined, undefined];
    if (prev?.type === ELEMENT_OPERATOR && prevPath && canMergeOperators(prev as TOperator, node)) {
      mergeOperators(editor, [prev, prevPath], [node, path]);
      return true;
    }
    // if next is an operator, move this into it
    const [next, nextPath] = getNextNode(editor, { at: path }) ?? [undefined, undefined];
    if (next?.type === ELEMENT_OPERATOR && nextPath && canMergeOperators(node, next as TOperator)) {
      mergeOperators(editor, [node, path], [next, nextPath]);
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const tryMergeOperators = (editor: PlateEditor) => {
  const operatorNodes = getNodes(editor, { pass: (n) => n[0].type === ELEMENT_OPERATOR });
  // iterate over operatorNodes generator, using a for loop
  for (const operator of operatorNodes) {
    console.log("operator", operator);
    tryMergeOperator(editor, operator as NodeEntry<TOperator>);
  }
};

export const normalizeOperators = (editor: PlateEditor, current: NodeEntry<TOperator>) => {
  // if the editor contains empty operators, remove them
  removeNodes(editor, {
    at: [],
    match: (n) => n.type === ELEMENT_OPERATOR && Editor.isEmpty(editor as BaseEditor, n as BaseElement),
  });
  // if the editor contains adjacent operators, merge them
  // tryMergeOperators(editor);
  return tryMergeOperator(editor, current);
};

export const isValidParent = (editor: PlateEditor, path: Path) => {
  const parent = getParentNode(editor, path);
  if (!parent) return false;
  const [node] = parent;
  return node.type === ELEMENT_CONNECTOR || node.type === ELEMENT_OPERATOR;
};
