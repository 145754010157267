import { EmptyContent } from "@common/EmptyContent";
import { Pane, SplitPane } from "@common/SplitPane";
import { SourceEditor, createSourcePlugins } from "@common/editor/RuleAuthor/SourceEditor";
import { FixedRichTextToolbar } from "@common/editor/RuleAuthor/toolbar/FixedToolbar";
import { FloatingToolbar } from "@common/editor/RuleAuthor/toolbar/FloatingToolbar";
import { RichTextToollbar } from "@common/editor/RuleAuthor/toolbar/components";
import { scrollable } from "@common/scrollbar";
import { ExperimentalLabel } from "@components";
import { hideModal, showModal } from "@imminently/imminently_platform";
import { Box } from "@material-ui/core";
import { type Action, ModalActions } from "@modals";
import { PlateProvider } from "@udecode/plate";
import { createAppResourceService } from "global";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Markdown from "react-markdown";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { getSourceText } from "./getSourceText";

const SUGGEST_EDITOR_ID = "source-suggest";
const sourcePlugins = createSourcePlugins();

const Panes = styled(SplitPane)`
  border: 1px solid ${(p) => p.theme.palette.background.border};
  border-radius: 0.5rem;
  flex: 1;
  max-height: calc(100% - 3.5rem);
`;

const Suggestions = styled(Markdown)`
  padding: 0.5rem 1rem;
`;

const SuggestModal = ({ source, releaseId, onInsert }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [suggestion, setSuggestion] = useState();
  const [newSource, setNewSource] = useState(() => {
    if (Array.isArray(source)) return source;
    return source.type === "section" ? source.children : [source];
  });
  // We do not yet support custom functions with id, workaround is use the subresource system
  const relativeReleaseService = createAppResourceService<any>(`releases/${releaseId}`);
  const { isLoading, mutateAsync } = relativeReleaseService.usePostCustom("suggestSource");

  // TODO this has to be custom, as using the default from showModal creates circular dependency for some reason
  const close = () => dispatch(hideModal());

  useEffect(() => {
    if (!suggestion) {
      // Build the source into something the backend will understand
      const text = getSourceText(source);
      // Ask the server to suggest additional content
      console.log("requesting suggestion for", text);

      mutateAsync({ source: text }).then((res) => {
        console.log("AI generated:", res);
        setSuggestion(res.suggestion);
      });
    }
  }, [suggestion]);

  const primaryAction: Action = {
    name: t("insert"),
    onClick: () => {
      onInsert(newSource);
      close();
    },
  };

  const paneProps = {
    sizes: [50, 50],
    minSize: [300, 300],
    // gutterSize: 8,
    gutterStyle: () => ({ display: "block", width: "8px" }),
  };

  const editableProps = {
    autoFocus: true,
    spellCheck: false,
  };

  return (
    <>
      <Panes
        {...paneProps}
        snapOffset={0}
      >
        <Pane>
          {isLoading ? (
            <EmptyContent
              width="100px"
              messages={[t("ai.generating_message")]}
            />
          ) : (
            <Suggestions className={scrollable}>{suggestion}</Suggestions>
          )}
        </Pane>
        <Pane>
          <PlateProvider
            id={SUGGEST_EDITOR_ID}
            plugins={sourcePlugins}
            value={newSource}
            onChange={setNewSource}
          >
            <FixedRichTextToolbar />
            <SourceEditor
              id={SUGGEST_EDITOR_ID}
              editableProps={editableProps}
            >
              <FloatingToolbar>
                <RichTextToollbar />
              </FloatingToolbar>
            </SourceEditor>
          </PlateProvider>
        </Pane>
      </Panes>
      <ModalActions primary={primaryAction} />
    </>
  );
};

export const useSuggestModal = () => {
  const dispatch = useDispatch();
  return ({ source, releaseId, onInsert }) => {
    dispatch(
      showModal(
        {
          open: true,
          title: (
            <Box
              display="flex"
              gridGap="1rem"
              alignItems="center"
            >
              <span>
                <Trans i18nKey="ai.suggest_source_description" />
              </span>
              <ExperimentalLabel />
            </Box>
          ),
          maxWidth: "xl",
          height: "80vh",
          style: { gap: "1rem", maxHeight: "calc(100% - 4rem)" },
        },
        <SuggestModal
          source={source}
          releaseId={releaseId}
          onInsert={onInsert}
        />,
      ),
    );
  };
};
