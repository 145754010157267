import { createPluginFactory } from "@udecode/plate";
import { Path, Transforms } from "slate";

import { getSelectedNode, normalizeRange, withHotKeys } from "../util";

export const createMovePlugin = createPluginFactory({
  key: "move",
  ...withHotKeys([
    {
      keys: ["mod+up"],
      action: (editor) => {
        try {
          const range = normalizeRange(editor.selection);
          const [_, path] = getSelectedNode(editor, range.anchor.path);
          Transforms.moveNodes(editor, { to: Path.previous(path), mode: "all" });
        } catch (err) {
          // desired path likely doesnt exist, ie start of document
        }
      },
      query: {
        exclude: [],
      },
    },
    {
      keys: ["mod+down"],
      action: (editor) => {
        try {
          const range = normalizeRange(editor.selection);
          const [, path] = getSelectedNode(editor, range.focus.path);
          Transforms.moveNodes(editor, { to: Path.next(path), mode: "all" });
        } catch (err) {
          // desired path likely doesnt exist, ie end of document
        }
      },
      query: {
        exlude: [],
      },
    },
  ]),
});
