/* eslint-disable no-param-reassign */
// Note slice in redux toolkit uses immer so we mutate state
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { TreeProps } from "../DataTree/constants";

export interface IState {
  state: string;
  goal: null | string;
  focusNode: {
    id: string;
    entity?: string;
    description?: string;
    hidden?: boolean;
    usedIn?: string[];
    definedIn?: string;
    type?: string;
    conditions?: string[];
    value?: any;
    derived?: boolean;
  };
  dataTree: {
    items: TreeProps[ "items" ];
    expanded: TreeProps[ "expanded" ];
  }
}


export const initialState: IState = {
  state: "{}",
  goal: null,
  focusNode: {
    id: "",
    entity: "global",
    description: "",
    hidden: false,
    usedIn: [],
    definedIn: "",
    type: "boolean",
    conditions: [],
    value: null,
    derived: false,
  },
  dataTree: {
    items: [],
    expanded: [],
  }
};

export const REDUX_PROP = "batchDebug_state";
export const getState = (s: any): IState => s[ REDUX_PROP ];

export type ActionPayloads = {
  setState: PayloadAction< IState[ "state" ] >;
  setGoal: PayloadAction< IState[ "goal" ] >;
  setFocusNode: PayloadAction< IState[ "focusNode" ] >;
  // ===================================================================================
  setDataTreeItems: PayloadAction< IState[ "dataTree" ][ "items" ] >;
  setExpanded: PayloadAction< IState[ "dataTree" ][ "expanded" ] >;
  toggleExpanded: PayloadAction< IState[ "dataTree" ][ "expanded" ][ 0 ] >;
}

const documentsSlice = createSlice({
  name: REDUX_PROP,
  initialState,
  reducers: {
    setState: (s, a: ActionPayloads[ "setState" ]) => {
      s.state = a.payload;
    },
    setGoal: (s, a: ActionPayloads[ "setGoal" ]) => {
      s.goal = a.payload;
    },
    setFocusNode: (s, a: ActionPayloads[ "setFocusNode" ]) => {
      s.focusNode = a.payload;
    },
    // ===================================================================================
    setDataTreeItems: (s, a: ActionPayloads[ "setDataTreeItems" ]) => {
      s.dataTree.items = a.payload;
    },
    setExpanded: (s, a: ActionPayloads[ "setExpanded" ]) => {
      s.dataTree.expanded = a.payload;
    },
    toggleExpanded: (s, a: ActionPayloads[ "toggleExpanded" ]) => {
      const id = a.payload;

      s.dataTree.expanded = s.dataTree.expanded.some(it => it === id)
        ? s.dataTree.expanded.filter(it => it !== id)
        : s.dataTree.expanded.concat(id);
    },
  },
});

export const { actions, reducer } = documentsSlice;
