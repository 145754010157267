import { hotkeyTooltip } from "@common/HotkeyTooltip";
import { LastSaved } from "@common/editor/components";
import { EditorToolbarButton, FillSpace, Group, ToolbarWithBorderBottom } from "@common/editor/plugins/muiToolbar";
import { LoadingDots, SaveIcon } from "@icons";
import { useModifyDocument, useRuleDocument } from "@pages/documents";
import { FloatingVerticalDivider } from "@udecode/plate";
import type { PropsWithChildren } from "react";
import { useMultiEditor } from "../multiEditor";
import { useHotkey } from "../util";
import {
  ActiveUsers,
  AutogenRulesToolbarButton,
  HistoryToolbar,
  MoreDropdownMenu,
  RichTextToollbar,
  RulesToolbar,
  SourceToolbar,
} from "./components";

export const FixedToolbar = () => {
  const { focused, editableProps } = useMultiEditor();
  const { id } = useRuleDocument();
  const { saving, saveDocument } = useModifyDocument();

  useHotkey("mod+s", () => {
    if (editableProps?.readOnly) return;
    saveDocument();
  });

  if (editableProps?.readOnly) {
    return (
      <ToolbarWithBorderBottom>
        <FillSpace />
        <ActiveUsers documentId={id} />
      </ToolbarWithBorderBottom>
    );
  }

  return (
    <ToolbarWithBorderBottom>
      <HistoryToolbar />
      <FloatingVerticalDivider />
      {focused === "source" ? (
        <SourceToolbar />
      ) : (
        <RulesToolbar>
          <AutogenRulesToolbarButton />
        </RulesToolbar>
      )}
      <FloatingVerticalDivider />
      <EditorToolbarButton
        key="save"
        icon={<SaveIcon />}
        tooltip={hotkeyTooltip("Save", ["mod", "s"])}
        onClick={() => saveDocument()}
        disable={saving}
      />
      <FillSpace />
      <Group>
        {saving ? <LoadingDots /> : <LastSaved />}
        <FloatingVerticalDivider />
        <ActiveUsers documentId={id} />
        <MoreDropdownMenu />
      </Group>
    </ToolbarWithBorderBottom>
  );
};

/**
 * A rich text only toolbar that has the basic controls
 */
export const FixedRichTextToolbar = ({ children, ...props }: any & PropsWithChildren) => {
  return (
    <ToolbarWithBorderBottom {...props}>
      <HistoryToolbar />
      <FloatingVerticalDivider />
      <RichTextToollbar />
      {children}
    </ToolbarWithBorderBottom>
  );
};
