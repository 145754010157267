import React from 'react';
import styled from 'styled-components';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { TertiaryButton } from './buttons';

export const ActionDivider = styled(Divider)`
  margin: 0 -1rem 0 -1rem;
`;

export const Actions = styled(Grid)`
  flex-flow: row nowrap;
  padding: 0.5rem 0;
`;

export const ActionButton = styled(TertiaryButton)`
  padding: 0.25rem;
`;

/** @type { React.FC< { actions: any[] } > } */
export const ActionsComp = React.memo(({ actions }) => {
  if(!actions) return null;

  const freeSpaceClick = e => {
    e.preventDefault();
  };

  return (
    <>
      <ActionDivider />
      <Actions container justifyContent='space-between' onClick={freeSpaceClick}>
        {actions.map(a => (
          <Grid item key={a.name}>
            <ActionButton size='small' startIcon={a.icon} onClick={a.onClick} disabled={a.disabled}>
              {a.name}
            </ActionButton>
          </Grid>
        ))}
      </Actions>
    </>
  );
});

ActionsComp.displayName = 'Menu/ActionsComp';
