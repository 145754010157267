import React, { useEffect, useState } from "react";
import clsx from "clsx";
import concat from "lodash/concat";
import uniq from "lodash/uniq";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import {
  AlertIcon,
  ChevrondownIcon,
  ChevronupIcon,
  DatasheetIcon,
  DocumentsIcon,
  FileIcon,
  FolderIcon,
  LoadingDots,
  MenudotsIcon,
  TableIcon,
} from "@icons";
import { Avatar, Menu, withMenu } from "@components";
import { EditableText, useVersion } from "@common";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import * as buildDocumentsState from "../redux/buildDocumentsState";
import { useIsDocumentDirty, useNewDocuments } from "@pages/documents";
import { useAuthorise } from "auth/permissions";


const dashElement = {
  content: "\"\"",
  position: "absolute",
  width: "1px",
  borderLeft: "1px dashed",
};

const folderDot = <div
  style={{ width: "0.5rem", height: "0.5rem", borderRadius: "50%", backgroundColor: "currentcolor" }}></div>;

const MoreMenu = withMenu(MenudotsIcon);

const StyledTreeItem = withStyles(theme => ({
  root: {
    display: "flex",
    flexFlow: "column nowrap",
    color: theme.palette.secondary.dark,

    "& .MuiTypography-caption[contenteditable=\"true\"]": {
      textOverflow: "initial",
    },

    "&.dirty": {
      "& .MuiTreeItem-iconContainer": {
        color: theme.palette.warning.main,
      },
    },
  },
  content: {
    position: "relative",
    margin: "0.125rem 0",
    "&:hover": {
      color: theme.palette.secondary.main,
      borderColor: `${theme.palette.secondary.dark}!important`,
    },
  },
  selected: {
    color: theme.palette.secondary.main,
  },
  iconContainer: {
    position: "absolute",
    right: 0,
    "& > svg": {
      fontSize: "1.5rem",
    },
    "& .close": {
      opacity: 0.3,
    },
  },
  label: {
    padding: 0,
    backgroundColor: "unset !important",
  },
  group: {
    margin: 0,
    padding: "0 0 0 2rem",
    "& > div > div > li": {
      position: "relative",
      "&:only-child > *:first-child:before": {
        ...dashElement,
        left: "-1.25rem",
        top: 0,
        height: "100%",
        transform: "translateY(-0.75rem)",
        borderColor: theme.palette.secondary.dark,
      },
      "&:not(:only-child)": {
        "&:first-child:before": {
          top: `${0}!important`,
        },
        "&:not(:last-child):before": {
          ...dashElement,
          left: "-1.25rem",
          top: "-1.25rem",
          bottom: "1.25rem",
          borderColor: theme.palette.secondary.dark,
        },
        "&:last-child > *:first-child:before": {
          ...dashElement,
          left: "-1.25rem",
          top: "0",
          height: "calc(100% + 0.75rem)",
          transform: "translateY(-1.5rem)",
          borderColor: theme.palette.secondary.dark,
        },
      },
      "& > div:first-child": {
        position: "relative",
        "&:after": {
          ...dashElement,
          left: "-1.25rem",
          top: "50%",
          bottom: 0,
          width: "0.75rem",
          borderLeft: 0,
          borderTop: "1px dashed",
          borderColor: theme.palette.secondary.dark,
        },
      },
    },
  },
}))(props => <TreeItem {...props} />);

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

const LoaderOverFile = styled(LoadingDots)`
  position: absolute;
  right: 0;
`;

/**
 * document name edition happens in ContentEditable. When we type\
 * forward or trailing spaces, those are converted into "&nbsp;"\
 * literals and can't be easily removed using .trim(). So this \
 * functiion would remove "&nbsp;" literals and spaces both from\
 * the start of the string and from the end
 */
const trimContentEditableValue = v => {
  let vLocal = String( v );
  const NBSP_CONST = '&nbsp;';
  const SPACE_CONST = ' ';

  let trimmedStart = false;
  while ( trimmedStart === false ) {
    if( vLocal.indexOf( NBSP_CONST ) === 0 ) {
      vLocal = vLocal.slice( 6 );
      continue;
    }

    if ( vLocal.indexOf( SPACE_CONST ) === 0 ) {
      vLocal = vLocal.slice( 1 );
      continue;
    }

    trimmedStart = true;
  }

  let trimmedEnd = false;
  while ( trimmedEnd === false ) {
    if ( vLocal.slice( -6 ) === NBSP_CONST ) {
      vLocal = vLocal.slice(0, -6);
      continue;
    }

    if ( vLocal.slice( -1 ) === SPACE_CONST ) {
      vLocal = vLocal.slice( 0, -1 );
      continue;
    }

    trimmedEnd = true;
  }

  return vLocal;
}

const cleanContentEditableValue = v => {
  const trimmed = trimContentEditableValue( v );

  return (
    trimmed
      .replace(/\n/g, '')
      .replace(/\t/g, '')
      .replace(/\—/g, '-')
      .replace(/['"]/g, '')
  );
}


const Label = withStyles(
  theme => ({
    root: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      paddingRight: 0,

      "&:hover": {
        color: theme.palette.secondary.main,
        "& $hover": {
          display: "block",
        },
        "& $isDirty": {
          display: "none",
        },
      },

      "&.isLoading": {
        cursor: "not-allowed",
        paddingRight: "1.5rem",
      },

      "&.deleted": {
        textDecoration: "line-through",
      },

      // add padding to folder as we need to cater for the collapse chevron
      "&[data-folder=\"true\"]": {
        paddingRight: "1.5rem",
      },

      // is phantom
      "&[data-phantom=\"true\"]": {
        "& $text": {
          color: theme.palette.error.main,
        },
        "& > svg": {
          color: theme.palette.error.main,
        },
      },
    },
    text: {
      margin: "0 0 0 0.5rem",
      width: "100%",
      fontWeight: props => (props.bold || props.isCurrent ? 700 : 400),
    },
    textWrap: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flex: "1",
      overflow: "hidden",
      textWrap: "nowrap",
      textOverflow: "ellipsis",
    },
    isDirty: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: 700,
      minWidth: "1.5rem",
      color: theme.palette.warning.main,
    },
    menu: {},
    lock: {
      padding: 0,
    },
    hover: {
      display: "none",
    },
  }),
  { name: "Label" },
)(({
  classes,
  name,
  path,
  icon,
  menu,
  locked,
  onClick,
  onRename,
  autoFocus = false,
  deleted,
  isLoading,
  isCurrent,
  isDirty,
  ...props
}) => {
  const [isOpen, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const authorise = useAuthorise();
  const items = menu ?
    menu.filter(({ role }) => role ? authorise(role) : true)
      .map(m => ({
        ...m,
        onClick: () => m.onClick(path, setEdit),
        disabled: typeof m.disabled === "function" ? m.disabled(path) : m.disabled,
      }))
    : null;

  const onContextClick = e => {
    e.preventDefault();
    setOpen(true);
  };

  return (
    <div className={clsx(classes.root, isLoading && "isLoading", deleted && "deleted")} onClick={onClick} onContextMenu={onContextClick} {...props}>
      {icon}

      {/* <Tooltip title={name}>
        <EditableText
          className={classes.text}
          value={name}
          autoFocus={autoFocus}
          onEdited={onRename}
          onClick={onClick}
          variant='caption'
          display='block'
          noWrap
        />
      </Tooltip> */}
      <div className={classes.textWrap}>
        <EditableText
          className={classes.text}
          value={name}
          autoFocus={autoFocus}
          onEdited={onRename}
          // onClick={onClick}
          variant="caption"
          display="block"
          noWrap
          title={name}
          editing={edit}
          setEditing={setEdit}
          autoHighlight
        />
        {isDirty && <Typography className={classes.isDirty} variant="caption">{isDirty}</Typography>}
      </div>

      {isLoading ? (
        <LoaderOverFile />
      ) : (
        <>
          {!!locked && (
            <Tooltip title={`Locked by: ${locked.full_name}`}>
              <Avatar
                src="/"
                alt={locked.full_name}
                size="xsmall"
                className={clsx(classes.lock, { [classes.hover]: !locked && !isOpen })}
              />
            </Tooltip>
          )}
          {items && (
            <MoreMenu className={clsx(classes.menu, classes.hover)} open={isOpen} setOpen={setOpen}>
              <Menu width="9rem" small items={items} />
            </MoreMenu>
          )}
        </>
      )}
    </div>
  );
});

const isFile = node => Object.prototype.hasOwnProperty.call(node, "name") || (
  Object.prototype.hasOwnProperty.call(node, "reference") &&
  typeof node.reference === "string"
);
// const isBlank = node => Object.prototype.hasOwnProperty.call(node, "blank");
const isBlank = node => Object.prototype.hasOwnProperty.call(node, "blank") && node.blank === true;

function getBlankPath(node, path = "") {
  if (path.match(/\*$/)) return path;
  if (typeof node !== "object" || node === null || isFile(node)) return null;

  return Object.entries(node).reduce((acc, [key, childNode]) => {
    if (acc !== null) return acc;

    return getBlankPath(childNode, `${path}/${key}`);
  }, null);
}

/**
 @type {
  (
  arg: {
  node: any;
  maxLevel?: number;
  level?: number;
  pathFromRoot?: string[];
  expandAll?: true
  }
  ) => string[]
  }
 */
export const expand = arg => {
  const {
    node,
    level = 0,
    maxLevel = 2,
    pathFromRoot = [],
    expandAll,
  } = arg;

  if ((expandAll !== true && level === maxLevel) || isFile(node)) return [];

  const folderKeys = Object.keys(node)
    .filter(name => isFile(node[name]) === false)
    .sort((a, b) => a.localeCompare(b));

  return folderKeys.flatMap((name, index) => {
    const newPathFromRoot = pathFromRoot.concat(name);
    // return Object.entries(node).flatMap(([name, value], index) => {
    const key = `${newPathFromRoot.join("/")}`;
    const value = node[name];

    return isFile(value)
      ? []
      : [
        key,
        ...expand({
          node: value,
          maxLevel,
          level: level + 1,
          pathFromRoot: newPathFromRoot,
          expandAll,
        }),
      ];
  });
};

const getNodeIcon = (node) => {
  if (node.isPhantom) return (
    <Tooltip title="Corrupt document - use 'View' to see the contents or 'Delete' the document">
      <AlertIcon />
    </Tooltip>
  );
  if (!isFile(node)) return <FolderIcon />;
  if (node.type === "rulesheet") return <DatasheetIcon />;
  if (node.type === "datatable") return <TableIcon />;
  if (node.version > 1) return <DocumentsIcon />;
  return <FileIcon />;
};

const getFolderFiles = (node) => {
  const files = [];
  Object.values(node).forEach((value) => {
    if (isFile(value)) {
      files.push(value);
    } else {
      files.push(...getFolderFiles(value));
    }
  });
  return files;
};

export const DirectoryTree = ({
  documents,
  onSelectFile,
  fileMenu,
  folderMenu,
  onRename,
  currentFileName,
  expanded,
  setExpanded,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const releaseIdToInfo = useSelector(s => {
    const releaseIdToInfo = buildDocumentsState.getState(s);
    /** @type { string } */
    const releaseId = s.scope.release;

    return releaseIdToInfo[releaseId];
  });
  // listens to version (aka refresh) updates
  const version = useVersion(); // eslint-disable-line

  useEffect(() => {
    if (releaseIdToInfo !== undefined && releaseIdToInfo.type !== "filenameOnly") {
      return;
    }

    const nextExpanded = uniq(concat(expand({ node: documents }), expanded));
    // debugger;
    setExpanded(nextExpanded);
  }, [setExpanded, documents, releaseIdToInfo]); // eslint-disable-line

  // try to find blank, and if present - expand all of its parents
  useEffect(() => {
    const maybePathToBlank = getBlankPath(documents);
    if (maybePathToBlank === null) return;

    const parentKeys = maybePathToBlank.split("/").filter(it => it !== "" && it !== "*");
    if (parentKeys.length === 0) return;

    const parentKey = parentKeys.join("/");
    if (expanded.includes(parentKey)) return;

    setExpanded(uniq([parentKey, ...expanded]));
  }, [documents, setExpanded, expanded]);

  // ===================================================================================
  // only persist structure on unmount
  useEffect(() => {
    return () => {
      dispatch(
        buildDocumentsState.ActionCreators.requestToSetReleaseInfo({
          type: "expandedOnly",
          expanded,
        }),
      );
    };
  }, [dispatch, expanded]);

  // only try to restore expand and file on mount
  useEffect(() => {
    if (releaseIdToInfo === undefined) return;

    if (releaseIdToInfo.type !== "filenameOnly") setExpanded(releaseIdToInfo.expanded);
    if (releaseIdToInfo.type !== "expandedOnly") {
      const maybeFile = get(documents, releaseIdToInfo.filename.split("/").filter(Boolean), undefined);
      if (!maybeFile) return;

      onSelectFile?.(maybeFile);
    }
  }, []);
  // ===================================================================================

  // eslint-disable-next-line complexity
  const TreeNode = ({ name, node, index, path = [] }) => {
    const currentPath = [...path, name];
    const key = `${currentPath.join("/")}`;
    const isLoading = node.loading || (isFile(node) && !node.hasOwnProperty("reference") && !node.hasOwnProperty("json"));
    const deleted = node.deleted;
    const documentStates = useNewDocuments();
    const isDirty = useIsDocumentDirty(node.reference);
    const isFolderDirty = isFile(node) ? false : getFolderFiles(node).some(file => {
      const id = file.reference;
      return documentStates[id] && documentStates[id].isDirty;
    });
    const locked = node.locked;

    const onClick = () => {
      if (isLoading || deleted) return; // do nothing

      onSelectFile?.(node);

      dispatch(
        buildDocumentsState.ActionCreators.requestToSetReleaseInfo({
          type: "filenameOnly",
          filename: node.name,
        }),
      );
    };

    const rename = value => {
      onRename?.(currentPath, cleanContentEditableValue(value));
    };

    const icon = getNodeIcon(node);

    if (isBlank(node)) {
      // @illia might be able to something here, however all node ids current use name and
      // index to avoid duplicates, which might be difficult to derive

      // !expanded.includes(key)
      // make sure blank is expanded
      // setExpanded(concat(expanded, key));
      const label = <Label bold autoFocus name="" icon={icon} path={currentPath} onRename={rename} />;

      return <StyledTreeItem key={key} nodeId={key} label={label} onLabelClick={null} />;
    }

    if (isFile(node)) {
      // include key as we need to force UI render on dirty state change
      const label = (
        <Label
          isDirty={isDirty && "M"}
          key={`${node.reference}-${name}`}
          name={name}
          icon={icon}
          path={currentPath}
          onRename={rename}
          menu={fileMenu}
          locked={locked}
          deleted={deleted}
          onClick={onClick}
          isLoading={isLoading}
          isCurrent={`/${currentPath.join("/")}` === currentFileName}
          data-phantom={node.isPhantom}
        />
      );

      return <StyledTreeItem key={key} nodeId={key} label={label} onLabelClick={null} />;
    }

    const label = (
      <Label name={name} icon={icon} path={currentPath} onRename={rename} menu={folderMenu} isLoading={isLoading}
        isDirty={isFolderDirty && folderDot} data-folder={true} />
    );
    // const nodes = Object.entries(node).filter(([k, v]) => v !== null && typeof v !== 'string').sort((a, b) => {
    //   return a[ 0 ].localeCompare(b[ 0 ]);
    // });
    const nodes = Object.entries(node).filter(([k, v]) => v !== null && typeof v !== "string");

    // in case we decide to show dirty state using the chevron
    // className={["folder", { dirty: isFolderDirty } ]}
    return (
      <StyledTreeItem key={key} className="folder" nodeId={key} label={label}>
        {!isLoading &&
          (nodes.length > 0 ? (
            nodes.map(([k, v], i) => <TreeNode key={`${k}-${i}`} name={k} node={v} index={i} path={currentPath} />)
          ) : (
            <StyledTreeItem
              key="no-files"
              nodeId="no-files"
              label={<Typography variant="caption">No files</Typography>}
            />
          ))}
      </StyledTreeItem>
    );
  };

  return (
    <TreeView
      className={classes.root}
      expanded={expanded}
      onNodeToggle={(e, nodeIds) => setExpanded(nodeIds)}
      defaultCollapseIcon={<ChevronupIcon />}
      defaultExpandIcon={<ChevrondownIcon />}
    >
      {Object.keys(documents).map((k, i) => (
        <TreeNode key={`${k}-${i}`} name={k} node={documents[k]} index={i} />
      ))}
    </TreeView>
  );
};
