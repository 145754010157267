import React from "react";
import * as CommonStyling from "../../../common/styles";
import { withStyles, createStyles  } from "../../../common/Styling";
import { Tab, TabProps, Tabs, TabsProps, Theme } from "@material-ui/core";

const TabsMini = withStyles((theme) => ({
  root: {
    // borderBottom: '1px solid #e8e8e8',
    backgroundColor: theme.palette.background.default,
  },
  indicator: {
    backgroundColor: theme.palette.primary.main, // prayingMantis,
  },
}))(Tabs);

const TabMini = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 72,
      width: 'auto',
      height: 'auto',
      minHeight: 'auto',
      // fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(1),
      lineHeight: '1',
      paddingTop: '5px',
      paddingBottom: '5px',
      paddingLeft: '0',
      paddingRight: '0',
      fontSize: '0.875rem',
      textAlign: 'left',
      // alignItems: 'flex-start !important',
      '.MuiTab-wrapper': {
        alignItems: 'flex-start',
      },
      '&:hover': {
        color: theme.palette.primary.main, // prayingMantis,
        opacity: 1,
      },
      '&$selected': {
        // color: theme.palette.primary.main, // prayingMantis
        fontWeight: theme.typography.fontWeightMedium,
      },
      // '&:focus': {
      //   color: '#40a9ff',
      // },
      // vertical tab max height

    },
    selected: {},
  }),
)((props: TabProps) =>
  <Tab
    disableRipple={true}
    {...props}
  />
);

export {
  TabsMini,
  TabMini
};