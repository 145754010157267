/* eslint-disable no-param-reassign */
// Note slice in redux toolkit uses immer so we mutate state
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sidebar: {
    collapsed: false,
  },
};

const designerSlice = createSlice({
  name: 'designer',
  initialState,
  reducers: {
    toggleCollapsed: state => {
      state.sidebar.collapsed = !state.sidebar.collapsed;
    },
  },
});

export const {
  toggleCollapsed,
} = designerSlice.actions;

export default designerSlice.reducer;
