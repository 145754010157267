import React from "react";
import styled from "styled-components";
import type { SplitProps } from 'react-split';
import { SplitPane, Pane } from '../../common/SplitPane'
import { Left, type LeftProps } from './Left';
import { Right, type RightProps } from './Right';


const Wrap = styled( SplitPane )`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  overflow: auto;
  /* border-top: 1px solid rgb(229, 229, 229); */

  .gutter::before {
    width: 1px;
    background-color: rgb(229, 229, 229);
  }
`;

const defaultSplitProps: SplitProps = {
  sizes: [25, 75],
  maxSize: [500],
  minSize: [200],
  gutterSize: 4,
}
export type TwoPaneDottedTreeProps = {
  className?: string;
  leftItems: LeftProps[ 'items' ];
  idToRightItems: Record< string, NonNullable< RightProps[ 'items' ] > >;
  LabelCompForRight: RightProps[ 'LabelComp' ];
  onSelectRight: RightProps[ 'onSelect' ];
  leftExpanded: LeftProps[ 'expanded' ];
  leftSetExpanded: LeftProps[ 'setExpanded' ];
  leftSelectedId: LeftProps[ 'selectedId' ];
  leftSetSelectedId: LeftProps[ 'setSelectedId' ];
  /**
   * if we have only one element in leftItems, we want to hide left\
   * pane and show only right one (as it makes no sense to select \
   * entity if we have only one)
   */
  hideLeftPane: boolean;
}
export const TwoPaneDottedTree: React.FC< TwoPaneDottedTreeProps > = React.memo(p => {
  const {
    className,
    leftItems,
    idToRightItems,
    LabelCompForRight,
    onSelectRight,
    leftExpanded,
    leftSetExpanded,
    leftSelectedId,
    leftSetSelectedId,
    hideLeftPane,
  } = p;

  const setSelectedLeftIdHandler = React.useCallback< LeftProps[ 'setSelectedId' ] >(id => {
    const maybeRightItems = idToRightItems[ id ];
    if( maybeRightItems === undefined ) return;

    leftSetSelectedId( id );
  }, [ idToRightItems, leftSetSelectedId ]);

  const rightItems = React.useMemo< RightProps[ 'items' ] >(() => (
    leftSelectedId === null ? null : (
      idToRightItems[ leftSelectedId ] || null
    )
  ), [ leftSelectedId, idToRightItems ]);

  const effectiveSplitProps = React.useMemo(() => (
    hideLeftPane === false ? defaultSplitProps : {
      ...defaultSplitProps,
      sizes: [0, 100],
      maxSize: [0],
      minSize: [0],
    }
  ), [ leftItems ]);

  return (
    <Wrap className={ className } { ...effectiveSplitProps }>
      <Pane>
        { hideLeftPane ? null : (
          <Left
            items={ leftItems }
            selectedId={ leftSelectedId }
            setSelectedId={ setSelectedLeftIdHandler }
            expanded={ leftExpanded }
            setExpanded={ leftSetExpanded }
          />
        )}
      </Pane>

      <Pane>
        <Right
          items={ rightItems }
          LabelComp={ LabelCompForRight }
          onSelect={ onSelectRight }
        />
      </Pane>
    </Wrap>
  );
});
TwoPaneDottedTree.displayName = 'components/TwoPaneDottedTree/Comp';
