import IconButton from "@material-ui/core/IconButton";
import Tippy, { type TippyProps } from "@tippyjs/react";
import { type ToolbarButtonProps as PlateToolbarButtonProps, useDropdownControls } from "@udecode/plate";
import {
  type PlateEditor,
  type ToggleMarkPlugin,
  type Value,
  type WithPlatePlugin,
  getPreventDefaultHandler,
  isMarkActive,
  someNode,
  toggleMark,
  toggleNodeType,
  useEventPlateId,
  usePlateEditorRef,
  usePlateEditorState,
} from "@udecode/plate-core";
import type { MouseEventHandler, ReactNode } from "react";
import styled from "styled-components";

// Custom ToolbarButton implementation
// PlateJS docs and discussions suggest creating a custom ToolbarButton as theres is just an example
// based off their own implementation:
// https://github.com/udecode/plate/blob/main/packages/ui/toolbar/src/ToolbarButton/ToolbarButton.tsx

interface ToolbarButtonProps extends Omit<PlateToolbarButtonProps, "tooltip"> {
  /* Disabled state or function to determine state */
  disable?: boolean | ((editor: any) => boolean);
  /* Tooltip text or Tippy props */
  tooltip?: string | TippyProps;
  /* Hide button if disabled */
  hideDisabled?: boolean;
  /* List of allowed elements based on selection. Will disable if element is not in the list. */
  allow?: string[];
}

export const Tooltip = styled.div`
  color: ${(p) => p.theme.palette.secondary.contrastText};
  background-color: ${(p) => p.theme.palette.secondary.main};
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  box-shadow: ${(p) => p.theme.shadows[1]};
  ${(p) => p.theme.typography.caption};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.25rem;
`;

const StyledIconButton = styled(IconButton)`
  border-radius: 0.5rem;

  /* &[data-active="true"]::before {
    position: absolute;
    inset: 0 0.5rem;
    content: '';
    border-bottom: 2px solid ${(p) => p.theme.palette.primary.main};
  } */

  &[data-active="true"] {
    background-color: ${(p) => p.theme.palette.secondary.light};
    border-radius: 0.5rem;
  }
`;

export const tippyProps = (text?: ReactNode): TippyProps => ({
  content: text ? <Tooltip>{text}</Tooltip> : undefined,
  arrow: false,
  offset: [0, 4],
  delay: 0,
  duration: [200, 0],
  placement: "bottom",
  hideOnClick: false,
});

/**
 * Custom ToolbarButton using MUI IconButton.
 * Adds support for easy tooltips, disabled state, hiding on disabled and allowing only certain elements.
 */
// eslint-disable-next-line complexity
export const ToolbarButton = (props: ToolbarButtonProps) => {
  const { id, icon, tooltip, hideDisabled, disable, active, allow, onMouseDown, ...rest } = props;
  const editor = usePlateEditorState(useEventPlateId(id));

  // if disable is not provided, default to false
  let disabled = disable ?? false;
  // allow disable to be a function
  if (disable instanceof Function) {
    disabled = editor && disable(editor);
  }

  // if allow is provided, check they are on a valid node
  // only check if not already disabled
  if (disabled === false && allow) {
    disabled = !!editor?.selection && someNode(editor, { match: { type: allow } });
  }

  //console.log('[ToolbarButton] disabled', tooltip, disable, disabled, active);

  if (hideDisabled && disabled) return null;

  const tooltipProps = typeof tooltip === "string" ? tippyProps(tooltip) : { ...tippyProps(), ...tooltip };

  const buttonProps = {
    onMouseDown,
    disabled: disabled as boolean,
    ...rest,
  };

  const button = (
    <StyledIconButton
      data-active={active}
      data-testid="ToolbarButton"
      {...buttonProps}
    >
      {icon}
    </StyledIconButton>
  );

  return tooltip ? <Tippy {...tooltipProps}>{button}</Tippy> : button;
};

export interface EditorToolbarButtonProps extends ToolbarButtonProps {
  onClick?: (editor: PlateEditor) => void;
}

/**
 * Toolbar button which passes the editor instance to the onClick handler.
 */
export const EditorToolbarButton = ({ id, onClick, active, ...props }: EditorToolbarButtonProps) => {
  // const editor = usePlateEditorState(useEventPlateId(id));
  const editor = usePlateEditorRef(id);

  return (
    <ToolbarButton
      id={id}
      active={typeof active === "function" ? active(editor) : active}
      onMouseDown={editor && getPreventDefaultHandler(onClick, editor)}
      {...props}
    />
  );
};

// See: https://github.com/udecode/plate/blob/main/packages/ui/toolbar/src/BlockToolbarButton/BlockToolbarButton.tsx

export interface BlockToolbarButtonProps extends ToolbarButtonProps {
  type: string;
  inactiveType?: string;
  toggle?: (editor: any, options: any, editorNodesOptions?: any) => void;
}

/**
 * Toolbar button to toggle the type of elements in selection.
 */
export const BlockToolbarButton = ({ id, type, inactiveType, active, toggle, ...props }: BlockToolbarButtonProps) => {
  const editor = usePlateEditorState(useEventPlateId(id));
  // if(toggle) { debugger; }
  const onClick = toggle ?? toggleNodeType;
  return (
    <ToolbarButton
      id={id}
      active={
        (typeof active === "function" ? active(editor) : active) ??
        (!!editor?.selection && someNode(editor, { match: { type } }))
      }
      onMouseDown={
        editor &&
        getPreventDefaultHandler(onClick, editor, {
          activeType: type,
          inactiveType,
        })
      }
      {...props}
    />
  );
};

// See: https://github.com/udecode/plate/blob/main/packages/ui/toolbar/src/MarkToolbarButton/MarkToolbarButton.types.ts

export interface MarkToolbarButtonProps<V extends Value>
  extends ToolbarButtonProps,
    Pick<WithPlatePlugin<V>, "type">,
    Pick<ToggleMarkPlugin, "clear"> {}

/**
 * Toolbar button to toggle the mark of the leaves in selection.
 */
export const MarkToolbarButton = <V extends Value>({ id, type, clear, ...props }: MarkToolbarButtonProps<V>) => {
  const editor = usePlateEditorState(useEventPlateId(id));

  return (
    <ToolbarButton
      id={id}
      active={!!editor?.selection && isMarkActive(editor, type)}
      onMouseDown={editor ? getPreventDefaultHandler(toggleMark, editor, { key: type, clear }) : undefined}
      {...props}
    />
  );
};

type ToolbarDropdownProps = {
  control: ReactNode;
  open: boolean;
  children: ReactNode;
  onOpen: MouseEventHandler;
  onClose?: (ev: MouseEvent) => void;
};

const DropdownContainer = styled.div`
  border: 1px solid ${(p) => p.theme.palette.background.border};
  background-color: ${(p) => p.theme.palette.background.default};
  z-index: 20 !important;
  border-radius: 0.5rem;
  box-shadow: ${(p) => p.theme.shadows[1]};
  display: flex;
  flex-direction: column;
  padding: 0.25rem;
  gap: 0.25rem;

  > * {
    min-width: calc(10rem - 0.25rem); // 8rem - 0.25rem padding
  }
`;

export const ToolbarDropdown = ({ control, children, open, onOpen, onClose }: ToolbarDropdownProps) => {
  const { styles, reference, floating } = useDropdownControls({
    open,
    onClose,
  });

  return (
    <>
      <div
        ref={reference}
        onMouseDown={onOpen}
      >
        {control}
      </div>

      {open && (
        <DropdownContainer
          ref={floating}
          style={styles}
        >
          {children}
        </DropdownContainer>
      )}
    </>
  );
};
