/* eslint-disable react/jsx-pascal-case */

import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import styled from 'styled-components';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { CloseIcon, LoadingDots, ClearIcon } from '@icons';
import {
  Box,
  IconButton,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { CreateForm, crudCreate, CRUD_CREATE_SUCCESS } from '@imminently/imminently_platform';
import { Field } from 'formik';
import * as combinatorNS from '../../redux/combinator.saga';
import * as populateProjectReleaseCentreNS from './redux';
import { Trans } from 'react-i18next';

// ===================================================================================

const StyledLoadingDots = styled(LoadingDots)`
  vertical-align: middle;
  margin-left: 1rem;
`;

const classes = {
  topRow: {
    _: 'topRow',

    title: 'title',
  },

  selectedFile: {
    _: 'selectedFile',

    name: 'name',
  },


};

const DialogTitle = styled.div`
  margin: 2rem 2rem 0 2rem;
  padding: 0;
  display: flex;
  align-items: center;
`;

const Wrap = styled.div`
  .${classes.topRow._} {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  .${classes.topRow.title} {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    margin-right: 20px;
  }
`;

/**
@type {
  ( arg: { filename: string; setFilename: ( arg: string ) => unknown }) =>
    React.FC< { field: any, form: any } >
}
*/
// eslint-disable-next-line react/display-name
const getCustomFileUpload = ({ setFilename, filename }) => React.memo(props => {
  const { field, form } = props;

  const resetFilename = React.useCallback(
    () => setFilename(''),
    [],
  );

  /** @type { ( e: React.FormEvent< HTMLInputElement > ) => void } */
  const handleChange = ({ currentTarget }) => {
    const { files } = currentTarget;
    if (!files) return;

    const uploadFile = files[0];
    const reader = new FileReader();

    reader.onload = function (event) {
      const base64String = String(event.target?.result);

      try {
        const parsed = JSON.parse(base64String);
        // TODO add error handling
        form.setFieldValue(field.name, parsed);
        setFilename(uploadFile.name);
      } catch (e) {
        console.error(e);
        resetFilename();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        currentTarget.value = null;
      }
    };

    reader.onerror = () => resetFilename();

    reader.readAsText(uploadFile);
  };

  if (filename) {
    return (
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography>{filename}</Typography>
        <IconButton onClick={resetFilename}>
          <ClearIcon />
        </IconButton>
      </Box>
    );
  }

  // TRANSLATEISSUE: The label for this is not translated. Change to the file upload for project import
  return <input type='file' label="File select" onChange={handleChange} accept='application/json' />;
});


// ===================================================================================


/**
@typedef {{
  close: () => unknown;
  project: string;
}} IProps
 */


const record = {};
/** @type { React.FC< IProps > } */
export const _ = React.memo(({ close, project }) => {
  const dispatch = useDispatch();

  const [isImporting, setIsImporting] = React.useState(false);
  const customSave = React.useCallback((/** @type { any } */values) => {
    /* let content = values.data.split(';');
    console.log('c', content);
    let payload = atob(content[1]);
    console.log(payload, content); */
    // console.log('create release somehow');
    setIsImporting(true);


    dispatch(combinatorNS.ActionCreator({
      bootstrap: crudCreate('releases', {
        model: project,
        payload: values.data.compressed,
      }, {
        customAction: 'import',
      }),
      waitFor: {
        type: CRUD_CREATE_SUCCESS,
        validate: a => {
          const broadAction = /** @type { any } */(a);

          return broadAction?.meta?.customAction === 'import';
        },
      },
      toDispatch: populateProjectReleaseCentreNS.aCreators.requestToActualizeReleases({
        projectId: project,
        componentId: 'test',
        type: 'maybePlusOnePagination',
      }),
      // toCall: close,
    }));
  }, [dispatch, project, setIsImporting]);

  const [filename, setFilename] = React.useState('');
  const CustomFileUpload = React.useMemo(
    () => getCustomFileUpload({ filename, setFilename }),
    [filename, setFilename],
  );


  return (
    <Wrap>
      <DialogTitle>
        <Typography variant='h3' style={{ textTransform: 'initial', flexGrow: 1 }}>
          <Trans i18nKey="release_centre.import_release"></Trans>
        </Typography>
        <IconButton onClick={close}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: '1.5rem 2rem 2rem' }}>
        <CreateForm
          resource='releases'
          record={record}
          customSave={customSave}
          hideSubmit={!filename}
          SubmitLoaderJSX={isImporting ? <StyledLoadingDots /> : null}
        >
          <Field required id='data' name='data' component={CustomFileUpload} />
        </CreateForm>
      </DialogContent>
    </Wrap>
  );
});
_.displayName = 'ProjectsReleaseCentre/ImportReleaseModal';
