/* eslint-disable react/sort-comp */

import React from 'react';
import moment from 'moment';
import { END_TIME, START_TIME } from '../TempTimeline/redux';
import memoize from 'memoize-one';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import { Button } from '@components';
import {
  addTimePointToTimepointsArr,
  removeTimePoint as removeTimePointOp,
  mapValueToUi,
  DATE_PICKER_FORMAT,
  editTimepoint,
  getGenericTimePoint,
  defaultTimePoints,
  isTimePoints,
} from './temporalOps';
import { EditTimePointDialog } from './EditTimePointDialog';
import * as redux from '@pages/models/release/Test/GraphTest/redux';

// ===================================================================================


/** @typedef { import( './temporalOps' ).TimePoint } TimePoint */
/** @typedef { import( './temporalOps' ).Range } Range */
/** @typedef { ( ts: TimePoint[] ) => JSX.Element } IDeriveTimePointJSX */


/**
  @typedef {{
    rowId: string;
    entity: string;
    templateId: string;
    close: () => unknown
  }} IOwnProps
 */
/** @typedef {{ timePoints: TimePoint[]; } & IOwnProps} IStateProps */
/** @typedef {{ dispatch: import('redux').Dispatch }} IDispatchProps */


/** @typedef { IStateProps & IDispatchProps } IProps */


/**
  @typedef IState
  @type {{
    currentTimePointId: null | TimePoint[ 'id' ];
    isEditMode?: true;
  }}
 */


/** @augments { React.PureComponent< IProps, IState > } */
class TemporalValueRender extends React.PureComponent {
  constructor(p) {
    super(p);

    /** @type { IState } */
    const defaultState = {
      currentTimePointId: null,
    };
    this.state = defaultState;

    // ===================================================================================

    this.updateTimePointsInStore = this.updateTimePointsInStore.bind(this);
    this.finalize = this.finalize.bind(this);
    this.discard = this.discard.bind(this);
    this.initiateAddTimePoint = this.initiateAddTimePoint.bind(this);
    this.updateTimePoint = this.updateTimePoint.bind(this);
    this.removeTimePoint = this.removeTimePoint.bind(this);
    this.initiateEdit = this.initiateEdit.bind(this);

    /** @type { IDeriveTimePointJSX } */
    this.memoizedGetJSXTimePoints = memoize(this.memoizedGetJSXTimePoints).bind(this);
  }

  /** @type { (value: TimePoint[]) => void } */
  updateTimePointsInStore(value) {
    const { entity, rowId, templateId, dispatch } = this.props;

    dispatch(redux.ActionCreators.setDataValue({
      entity, rowId, templateId, value,
    }));
  }


  finalize() {
    this.setState({ currentTimePointId: null });
  }

  discard() {
    this.setState(s => {
      const { timePoints } = this.props;
      const { currentTimePointId } = s;

      if (currentTimePointId === null) {
        return s;
      }

      const nextTimepoints = removeTimePointOp(timePoints, currentTimePointId);
      this.updateTimePointsInStore(nextTimepoints);

      return {
        ...s,
        currentTimePointId: null,
      };
    });
  }

  initiateAddTimePoint() {
    this.setState(s => {
      const { timePoints: timePointsInProps } = this.props;
      const { id, timePoints } = addTimePointToTimepointsArr(timePointsInProps);

      this.updateTimePointsInStore(timePoints);

      return {
        ...s,
        currentTimePointId: id,
      };
    });
  }

  /** @type { import('./EditTimePointDialog').IProps[ 'updateTimePoint' ] } */
  updateTimePoint(t) {
    const { timePoints } = this.props;

    this.updateTimePointsInStore(editTimepoint(timePoints, t));
    this.setState({ isEditMode: undefined });
  }

  /** @type { ( e: React.MouseEvent< HTMLButtonElement > ) => void } */
  removeTimePoint(e) {
    const { id } = e.currentTarget.dataset;
    if (id === undefined) return;

    const { timePoints } = this.props;

    this.updateTimePointsInStore(removeTimePointOp(timePoints, id));
  }


  /** @type { ( e: React.MouseEvent< HTMLButtonElement > ) => void } */
  initiateEdit(e) {
    const { id } = e.currentTarget.dataset;
    if (id === undefined) return;

    this.setState({
      currentTimePointId: id,
      isEditMode: true,
    });
  }

  /** @type { IDeriveTimePointJSX } */
  memoizedGetJSXTimePoints(ts) {
    const { removeTimePoint, initiateEdit } = this;

    return (
      <table style={{ borderSpacing: 10 }}>
        <thead>
          <tr key='heading'>
            <th>From</th>
            <th>To</th>
            <th>Value</th>
            <td />
            <td />
          </tr>
        </thead>
        <tbody>
          {
            ts.map((it, i, arr) => {
              if (i === arr.length - 1) return null;

              const nextTimepoint = arr[i + 1];

              return (
                <tr key={it.id}>
                  <td>
                    {
                      it.t === START_TIME
                        ? 'Beginning of Time'
                        : moment(it.t).format(DATE_PICKER_FORMAT)
                    }
                  </td>
                  <td>
                    {
                      nextTimepoint.t === END_TIME
                        ? 'End of Time'
                        : moment(nextTimepoint.t).format(DATE_PICKER_FORMAT)
                    }
                  </td>
                  <td>{mapValueToUi(it.v)}</td>
                  <td>
                    <IconButton data-id={it.id} onClick={initiateEdit}>
                      <EditIcon />
                    </IconButton>
                  </td>
                  <td>
                    {
                      it.t === START_TIME
                        ? null
                        : (
                          <IconButton data-id={it.id} onClick={removeTimePoint}>
                            <CancelIcon />
                          </IconButton>
                        )
                    }
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    );
  }


  // ===================================================================================


  render() {
    const {
      state: {
        currentTimePointId,
        isEditMode,
      },
      props: {
        timePoints,
        close,
      },
      finalize,
      discard,
      initiateAddTimePoint,
      updateTimePoint,
      memoizedGetJSXTimePoints,
    } = this;


    const jsxTimepoints = memoizedGetJSXTimePoints(timePoints);

    return (
      <div>
        <div>{jsxTimepoints}</div>

        <IconButton onClick={initiateAddTimePoint}>
          <AddCircleIcon />
        </IconButton>

        <EditTimePointDialog {...{
          open: currentTimePointId !== null,
          finalize,
          discard,
          timePoint: timePoints
            .find(it => it.id === currentTimePointId) || getGenericTimePoint(),
          updateTimePoint,
          isEditMode,
        }}
        />

        <br />

        <Button onClick={close}>
          Save
        </Button>
      </div>
    );
  }
}

/** @type { IStateProps } */
const defaultStateProps = {
  timePoints: defaultTimePoints,
  templateId: '',
  rowId: '',
  entity: '',
  close: console.log,
};


/** @type { ( s: any, own: IOwnProps ) => IStateProps } */
const mapStateToProps = (s, { entity, rowId, templateId, close }) => {
  const { data: { values } } = redux.getState(s);
  const maybeDataValue = redux.extractDataValue({
    entity, rowId, templateId, values,
  });

  if (maybeDataValue === null) return defaultStateProps;

  const { value } = maybeDataValue;
  if (!isTimePoints(value)) return defaultStateProps;

  return { timePoints: value, entity, templateId, rowId, close };
};

/** @type { ( dispatch: import('redux').Dispatch ) => IDispatchProps } */
const mapDispatchToProps = dispatch => ({ dispatch });

/** @type { React.ComponentType< IOwnProps > } */
export const _ = connect(mapStateToProps, mapDispatchToProps)(TemporalValueRender);
_.displayName = 'TemporalEditor';
