import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import type { BackendResource, DeepPartial } from "@imminently/immi-query";

const botsSlice = createSlice({
  name: "bots",
  initialState: {
    botsList: [] as BackendResource<any>[],
    selectedBotId: null as string | null,
    // selectedBot: {}, // {[releaseId: string]: string | null}
    botData: {}, // {[id: string]: {data: BackendResource<any>, isDirty: boolean}}
  },
  reducers: {
    setBots: (state, action: PayloadAction<BackendResource<any>[]>) => {
      state.botsList = action.payload;
    },
    // setSelectedBot: (state, action: PayloadAction<{release: string, id: string}>) => {
    //   state.selectedBotId[action.payload.release] = action.payload.id;
    setSelectedBot: (state, action: PayloadAction<string | null>) => {
      state.selectedBotId = action.payload;
      // preload bot data on first select if we can find it in the bots list
      if (action.payload && !state.botData[action.payload]) {
        const botData = state.botsList.find((bot) => bot.id === action.payload);
        if (botData) {
          state.botData[action.payload] = { data: botData, isDirty: false };
        }
      }
    },
    setBot: (state, action: PayloadAction<{ id: string, data: BackendResource<any> }>) => {
      if (!state.botData[action.payload.id]) {
        state.botData[action.payload.id] = {};
      }
      state.botData[action.payload.id].data = action.payload.data;
      state.botData[action.payload.id].isDirty = false;
    },
    updateBotContext: (state, action: PayloadAction<{ id: string, context: string }>) => {
      if (!state.botData[action.payload.id]) {
        state.botData[action.payload.id] = { data: {} };
      }
      state.botData[action.payload.id].data.context = action.payload.context;
      state.botData[action.payload.id].isDirty = true;
    },
    resetBotContext: (state, action: PayloadAction<string>) => { // bot id
      state.botData[action.payload].data.context = undefined; // could set this to the original context, but at the moment we don't maintain it, setting isDerity to false should trigger the back-end get action
      state.botData[action.payload].isDirty = false;
    }
  }
})

export const botActions = botsSlice.actions;

export const botSliceName = botsSlice.name;

export default botsSlice.reducer;