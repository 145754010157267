import { ELEMENT_TD, ELEMENT_TH, ELEMENT_TR } from "@udecode/plate";
import get from "lodash/get";
import { v4 as uuidv4 } from "uuid";
import type { RuleDocContentsV1 } from "@packages/commons";
import { DESERIALIZED_SYMBOL, type DeserializedRuleDocContentsV1 } from "@pages/documents/documents.slice.constants";

const TYPES = {
  PARAGRAPH: "p",
  BLANK_LINE: "OPM-blankline",
  CONCLUSION: "OPM-conclusion",
  ALT_CONCLUSION: "OPM-Alternativeconclusion",
  LEVEL1: "OPM-level1",
  LEVEL2: "OPM-level2",
  LEVEL3: "OPM-level3",
  LEVEL4: "OPM-level4",
  LEVEL5: "OPM-level5",
  LEVEL6: "OPM-level6",
  HEADER: "header",
};

const supportedTypes = [
  TYPES.CONCLUSION,
  TYPES.LEVEL1,
  TYPES.LEVEL2,
  TYPES.LEVEL3,
  TYPES.LEVEL4,
  TYPES.LEVEL5,
  TYPES.LEVEL6,
];

/**
 * table format
 * decision-table (aka root table)
 *  conclusion (aka header)
 *  sub_conclusions (aka rows)
 *    text (aka conclusion)
 *    conditions
 *      text
 *      type
 *  (last sub_conclusion) (aka last row)
 *    text (aka alt conclusion)
 *    conditions
 *      text = Otherwise
 *      type = OPM-Alternativeconclusion
 */

const createElement = (type, children: string | any[] = "", other: any = {}) => ({
  type,
  children: typeof children === "string" ? [{ text: children }] : children,
  id: uuidv4(),
  ...other,
});

// const createHeader = children => createElement(ELEMENT_TR, [createElement(ELEMENT_TH, children)], { isHeader: true });
const createHeader = (children) =>
  createElement(ELEMENT_TR, [createElement(ELEMENT_TH, [createElement(TYPES.HEADER, children)])], { isHeader: true });
const createRow = (children, isLast = false) =>
  createElement(ELEMENT_TR, [createElement(ELEMENT_TD, children)], { isLast });

export const deserializeTable = (table) => {
  const rows = table.sub_conclusions;
  return createElement("table", [
    createHeader([{ text: table.conclusion }]),
    ...rows.map((row, i) =>
      createRow(
        [
          createElement(TYPES.CONCLUSION, row.text),
          ...row.conditions.filter((c) => c.type !== TYPES.ALT_CONCLUSION).map((c) => createElement(c.type, c.text)),
        ],
        i === rows.length - 1,
      ),
    ),
  ]);
};

// Take the external JSON format and turn it into a format for Slate
export const deserialize = (json: RuleDocContentsV1 | undefined): DeserializedRuleDocContentsV1 => {
  if (!json || json.length === 0)
    return Object.assign([createElement(TYPES.PARAGRAPH)], {
      [DESERIALIZED_SYMBOL]: true,
    } as const);

  // console.log('deserialize', json);
  const data = json.map((item) => {
    const mappedItem = (() => {
      if (item.type === "decision-table") {
        return deserializeTable(item);
      }

      const type = item.type === TYPES.BLANK_LINE ? TYPES.PARAGRAPH : item.type;
      return createElement(type, item.text);
    })();

    return {
      ...mappedItem,
      id: item.id ?? uuidv4(),
    };
  });

  // console.log('deserialized slate', data);
  return Object.assign(data, {
    [DESERIALIZED_SYMBOL]: true,
  } as const);
};

export const serializeTable = (node) => {
  const table = {
    type: "decision-table",
    conclusion: "",
    sub_conclusions: [] as any[],
  };

  const firstChild = (n) => get(n, "children[0]", { text: "" });

  node.children
    // strip a level for easier processing
    .map((row) => row.children[0])
    .forEach((row, index) => {
      // row is td or th
      if (index === 0) {
        // handle first row (heading)
        // its now TH -> HEADER -> TEXT
        table.conclusion = firstChild(firstChild(row)).text;
      } else if (index === node.children.length - 1) {
        // handle last row (alt conclusion)
        const conclusion = firstChild(row);
        table.sub_conclusions.push({
          text: firstChild(conclusion).text,
          conditions: [
            {
              type: TYPES.ALT_CONCLUSION,
              text: "Otherwise",
            },
          ],
        });
      } else {
        const conclusion = firstChild(row);
        const conditions = row.children.slice(1);
        table.sub_conclusions.push({
          text: firstChild(conclusion).text,
          conditions: conditions.map((cond) => ({
            type: cond.type,
            text: firstChild(cond).text,
          })),
        });
      }
    });

  return table;
};

// Take slate and format back into server side JSON
export const serialize = (value) => {
  // console.log('serialize', value);
  const data = value.map((item) => {
    if (item.type === "table") {
      return serializeTable(item);
    }

    return {
      type: supportedTypes.indexOf(item.type) > -1 ? item.type : TYPES.BLANK_LINE,
      text: get(item, "children[0].text", ""),
    };
  });
  // console.log("converted slate", data);
  return data;
};

export const validate = (data) => {
  let prev;
  const errors: any[] = [];
  const levelIndex = {
    [TYPES.CONCLUSION]: 0,
    [TYPES.LEVEL1]: 1,
    [TYPES.LEVEL2]: 2,
    [TYPES.LEVEL3]: 3,
    [TYPES.LEVEL4]: 4,
    [TYPES.LEVEL5]: 5,
    [TYPES.LEVEL6]: 6,
  };

  data.forEach((l, index) => {
    if (l.type === prev) return;
    if (levelIndex[l.type] > levelIndex[prev] && levelIndex[l.type] - levelIndex[prev] !== 1) {
      errors.push({ message: `Invalid level indentation at line ${index + 1}` });
    }
    // TODO check table validity
    if (l.type !== TYPES.BLANK_LINE) prev = l.type;
  });

  return errors;
};
