import type { GraphNode } from "@packages/commons";
import { graphlib, type Node as NodeFromLib } from "dagre";
import produce from "immer";
import uniq from "lodash/uniq";
import { useMemo } from "react";

export enum GraphNodeType {
  GOAL = "goal",
  INPUT = "input",
  DERIVED = "derived",
  IDENTIFIER = "identifier",
};

export type GraphLibNode = NodeFromLib< GraphNode >;
export interface GraphNodeWithType extends GraphNode { nodeType: GraphNodeType };

export type NodeGroups = {
  /**
   * - It has no parents (predecessors)
   * - It is not an identifier (identifier: true in node)
   * - It is not a foreign key (fk: true in node)
   * - It can be on any entity
   */
  goals: GraphLibNode[];
  /**
   * - has no successors
   * - has no "condtions", nor "rows"
   */
  inputs: GraphLibNode[];
  /** node is an "identifier node" if it has "identifier: true" */
  identifiers: GraphLibNode[];
  /** neither a "goal", nor an "input" */
  derived: GraphLibNode[];
}

const defaultNodeGroups: NodeGroups = { goals: [], inputs: [], derived: [], identifiers: [] };

export const getNodeType = (g: graphlib.Graph<GraphNode>, n: string): GraphNodeType => {
  const node = g.node(n);
  const predcessors = g.predecessors(n) || [];

  // if(!node) throw "Cannot get node type! Node not found in graph.";
  // does not exist yet, so fallback to input
  if(!node) return GraphNodeType.INPUT;

  if(predcessors.length === 0 && !node.fk && !node.identifier) {
    return GraphNodeType.GOAL;
  }

  if(node.identifier) {
    return GraphNodeType.IDENTIFIER;
  }

  const successors = g.successors(n) || [];
  if(successors.length === 0 && !node.conditions && !node.rows) {
    return GraphNodeType.INPUT;
  }

  return GraphNodeType.DERIVED;
};

export const groupGraphNodes = (g: graphlib.Graph< GraphNode >, entity?: string): NodeGroups => (
  produce(defaultNodeGroups, draft => {
    g.nodes().forEach(n => {
      const node = g.node(n);
      if(entity !== undefined && node.entity !== entity) return void 1;
      if (node.fk) return void 1;
      const predcessors = g.predecessors(n) || [];

      if(predcessors.length === 0 && !node.fk && !node.identifier) {
        return void draft.goals.push(node);
      }

      if(node.identifier) {
        return void draft.identifiers.push(node);
      }

      const successors = g.successors(n) || [];
      if(successors.length === 0 && !node.conditions && !node.rows) {
        return void draft.inputs.push(node);
      }

      return void draft.derived.push(node);
    });
  })
);

export const useGroupedGraph = (graph: string | Record< string, any > | null, entity?: string) => {
  const _graph = useMemo(() => graph
    ? (graphlib.json.read(typeof graph === 'string' ? JSON.parse(graph) : graph) as graphlib.Graph<GraphNode>)
    : null, [graph]);

  let groups = defaultNodeGroups;

  if(_graph !== null) {
    groups = groupGraphNodes(_graph, entity);
  }

  const getGraphNodeType = (id: string) => _graph ? getNodeType(_graph, id) : GraphNodeType.INPUT;

  const getNode = (id: string) => {
    const node = _graph?.node(id);
    if(!node) return null;
    return {
      ...node,
      nodeType: getGraphNodeType(id),
    } as GraphNodeWithType;
  };

  return {
    ...groups,
    getNode,
    getGraphNodeType,
  };
};


// ===================================================================================

export const gatherInputNodesByGoalId = (graph: string | Record< string, any > | graphlib.Graph<GraphNode> | null, goalId: string): string[] => {

  if (graph === null) {
    return [];
  }
  // if graph has own property _nodes
  const isGraph = (g: any): g is graphlib.Graph<GraphNode> => g._nodes === undefined;

  const g = isGraph(graph) ? graph : graphlib.json.read(
    typeof graph === 'string' ? JSON.parse(graph) : graph,
  ) as graphlib.Graph< GraphNode >;

  const { inputs } = groupGraphNodes(g);
  const inputsHash = inputs.reduce< Record< string, true > >(
    (a, i) => ({ ...a, [ i.id ]: true }),
    {},
  );

  const inputNodes = (function gatherSuccessors(nId: string): string[] {
    const acc = inputsHash[ nId ] ? [nId] : [];

    const outEdges = g.outEdges(nId);
    if(!Array.isArray(outEdges)) return acc;

    const children = outEdges.map(it => it.w);
    return acc.concat(children.reduce< string[] >(
      (a, it) => a.concat(gatherSuccessors(it)),
      [],
    ));
  }(goalId));

  const uniqInputNodes = uniq(inputNodes);

  return uniqInputNodes;
};
