import type { TestCasesRootCtxState } from '../../types';
import { ReleaseTestCasesNS } from '@packages/commons';


export type DerivePathStateArg = {
  selectedId: TestCasesRootCtxState[ 'value' ][ 'selectedId' ];
  tree: TestCasesRootCtxState[ 'value' ][ 'tree' ];
};

export type DerivePathStateRtrnForUnselected = [
  { type: 'root' },
  { type: '' }
]
export type DerivePathStateRtrn = (
  | { type: 'unselected' }
  | {
    type: 'selected';
    pathToId: ReleaseTestCasesNS.utilsNS.TCasesGetPathToIdRtrn;
  }
)
export const derivePathState = ( arg: DerivePathStateArg ): DerivePathStateRtrn => {
  const { selectedId, tree } = arg;

  if( selectedId === null ) return { type: 'unselected' };

  return { type: 'selected', pathToId: ReleaseTestCasesNS.utilsNS.tCasesGetPathToId({ root: tree, id: selectedId }) };
}
