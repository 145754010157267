import { ELEMENT_MENTION, getMentionOnSelectItem } from "@udecode/plate";
import { getPluginOptions, usePlateEditorRef } from "@udecode/plate-core";
import React from "react";

import { TwoPaneCombobox } from "./TwoPaneCombobox";

export const MentionCombobox = ({
  items,
  component,
  onRenderItem,
  pluginKey = ELEMENT_MENTION,
  id = pluginKey,
  ...props
}) => {
  const editor = usePlateEditorRef();

  const { trigger } = getPluginOptions(editor, pluginKey);

  return (
    <TwoPaneCombobox
      id={id}
      trigger={trigger}
      controlled
      items={items}
      component={component}
      onRenderItem={onRenderItem}
      onSelectItem={getMentionOnSelectItem({
        key: pluginKey,
      })}
      {...props}
    />
  );
};
