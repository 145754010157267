import React, { useRef, useState, forwardRef } from "react";
import styled from "styled-components";

import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";

import { ChevrondownIcon, ChevronupIcon, ClearIcon } from "@icons";
import { ActionsComp as MenuActionsComp } from "./menuAction";
import { scrollable } from "../common/scrollbar";
import { Tooltip } from "@components/toolTip";

/** @type { React.FC< import("@material-ui/core/TextField").TextFieldProps & { action?: any; inputPointerEventsNone?: true } > } */
export const TextField = React.forwardRef(({ icon, action, inputPointerEventsNone, ...props }, ref) => {
  const inputProps = {
    startAdornment: icon ? <InputAdornment position="start">{icon}</InputAdornment> : undefined,
    endAdornment: action ? <InputAdornment position="end">{action}</InputAdornment> : undefined,
  };

  return <MuiTextField
    innerRef={ref}
    color="secondary"
    variant="outlined"
    InputProps={inputProps}
    inputProps={inputPointerEventsNone && { style: { pointerEvents: "none" } }}
    {...props}
  />;
});
TextField.displayName = "TextField";

/** @type { React.FC< any > } */
export const Search = forwardRef((p, ref) => {
  const { placeholder, value, setValue, onClear, noSetValueOnClear, ...props } = p;
  const updateValue = e => setValue(e.target.value);
  const clearvalue = () => {
    if (!noSetValueOnClear) setValue("");

    onClear?.();
  };

  const clear = value ? (
    <IconButton size="small" onClick={clearvalue}>
      <ClearIcon/>
    </IconButton>
  ) : null;

  return (
    <TextField
      ref={ref}
      placeholder={typeof placeholder === "string" ? placeholder : ""}
      action={clear}
      value={value}
      onChange={updateValue}
      {...props}
    />
  );
});

const DEFAULT_MAP = option => {
  if (typeof option === "string") {
    return { key: option, value: option };
  }
  if (typeof option === "number") {
    return { key: String(option), value: option };
  }

  return option;
};

const DropdownFooterContainer = styled.div`
  padding: 0 1rem;
  margin-bottom: -0.5rem;
`;

const MenuActionsContainer = styled.div`
  position: absolute;
  right: 0.5rem;

  // &:hover {
  //   color: theme.palette.primary.main;
  //   svg: {
  //     color: theme.palette.primary.main;
  //     fill: theme.palette.primary.main;
  //   }
  // }
`;

const ItemActions = ({ itemActions, itemValue, itemKey }) => {

  if (itemActions && itemActions.actions.length && !(itemActions?.exceptions ?? []).includes(itemValue)) {

    return (
      <MenuActionsContainer>
        {
          itemActions.actions.map((it, idx) => {

            if ((it?.exceptions ?? []).includes(itemValue)) {
              return (null);
            }

            const Icon = it.icon;
            const fnClick = evt => {
              evt.preventDefault();
              it.onClick([ itemKey, itemValue ]);
            };
            return (<Icon key={idx} onClick={fnClick}/>);
          })
        }
      </MenuActionsContainer>
    );
  }

  return (null);
};

/** @type { React.FC< any > } */
export const Dropdown = React.forwardRef(({
                                            label,
                                            items,
                                            children,
                                            icon,
                                            fullWidth,
                                            map = DEFAULT_MAP,
                                            variant,
                                            actions,
                                            itemActions,
                                            required,
                                            helperText,
                                            menuItemStyle = undefined,
                                            menuPaperStyleWidth = undefined,
                                            ...props
                                          }, ref) => {
  const [ anchorEl, setAnchorEl ] = useState();

  const menuProps = {
    anchorEl: anchorEl || undefined,
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    PaperProps: anchorEl
      ? {
        style: { width: menuPaperStyleWidth || anchorEl.offsetWidth, maxHeight: "22rem", overflowX: "hidden" },
        className: scrollable,
      }
      : {},
  };

  const selectProps = {
    startAdornment: icon ? <InputAdornment position="start">{icon}</InputAdornment> : undefined,
  };

  return (
    <FormControl required={required} variant={variant || "outlined"} color="secondary" fullWidth={fullWidth} ref={setAnchorEl}>
      <InputLabel id={`label-${label}`}>{label}</InputLabel>
      <Select
        innerRef={ref}
        MenuProps={menuProps}
        IconComponent={ChevrondownIcon}
        label={label}
        labelId={`label-${label}`}
        id={`select-${label}`}
        fullWidth
        {...props}
        {...selectProps}
      >
        {items
          ? items.map((option, i) => {
            const { key, value } = map(option);
            return (
              <MenuItem
                key={`${key}-${i}`}
                value={value}
                disabled={Boolean(option.disabled)}
                style={menuItemStyle}
              >
                {key}
                <ItemActions
                  itemValue={value}
                  itemKey={key}
                  itemActions={itemActions}
                />
              </MenuItem>
            );
          })
          : children}
        {
          (actions && actions.length) && (
            <DropdownFooterContainer>
              <MenuActionsComp
                actions={actions}
              />
            </DropdownFooterContainer>
          )
        }
      </Select>
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  );
});
Dropdown.displayName = "Dropdown";

export const SelectButton = styled(Button)`
  font-weight: 400;
  background-color: ${props => props.theme.palette.background.default};
  transition-property: background-color;

  &:hover {
    background-color: ${props => props.theme.palette.background.default} !important;
    box-shadow: inset 0 0 0px 1px;
  }

  .MuiButton-startIcon {
    margin-left: 0;
  }

  .MuiButton-endIcon {
    margin-left: auto;
    margin-right: 0;
  }
`;

/** @type { React.FC< any > } */
export const DropdownMenu = ({
                               showFullOnHover,
                               value,
                               icon,
                               anchorOrigin,
                               transformOrigin,
                               style,
                               children,
                               placeholder,
                               popoverProps,
                               ...props
                             }) => {
  const [ anchorEl, setAnchorEl ] = useState(null);

  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const InnerMenu = React.forwardRef((props, ref) => <>{children}</>);
  // eslint-disable-next-line
  anchorOrigin = anchorOrigin || {
    vertical: "bottom",
    horizontal: "left",
  };

  // eslint-disable-next-line
  transformOrigin = transformOrigin || {
    vertical: "top",
    horizontal: "left",
  };

  const childProps = {
    close: () => handleClose(),
  };

  const open = Boolean(anchorEl);

  const buttonStyle = open ? {
    ...style,
    boxShadow: "inset 0 0 0px 1px",
  } : style;

  const placeholderText = placeholder ?
    <Typography variant="button" display="block" color="textSecondary" noWrap>
      {placeholder}
    </Typography> : null;

  const button = <SelectButton
    disableRipple
    color="secondary"
    variant="contained"
    startIcon={icon}
    endIcon={open ? <ChevronupIcon/> : <ChevrondownIcon/>}
    onClick={handleClick}
    style={buttonStyle}
    {...props}
  >
    {
      value
      ?
        <Typography variant="button" display="block" noWrap>
          {value}
        </Typography>
      :
        placeholderText
   }
  </SelectButton>;

  return (
    <>
      {value && showFullOnHover ? <Tooltip interactive={true} title={value} enterDelay={500}>
        {button}
      </Tooltip> : button}
      <Popover
        id="menu"
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        {...popoverProps}
        // don't allow overriding these
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {/* <InnerMenu /> */}
        {typeof children === "function" ? children(childProps) : children}
      </Popover>
    </>
  );
};
/** @type { ( component: uknown, menuProps: unknown ) => React.FC< React.PropsWithChildren > } */
// disabling eslint as its a HOC
export const withMenu =
  // eslint-disable-next-line
  (Component, menuProps) => ({ open, setOpen, children, ...props }) => {
    const elem = useRef(null);
    const [ anchorEl, setAnchorEl ] = useState(null);

    // eslint-disable-next-line
    // const Comp = React.forwardRef((props, ref) => <Component ref={ref} {...props} />);

    const handleClick = e => {
      e.preventDefault();
      e.stopPropagation();
      setAnchorEl(e.currentTarget);
      setOpen?.(true);
    };

    const handleMenuClicked = e => {
      e.preventDefault();
      e.stopPropagation();
      handleClose();
    };

    const handleClose = () => {
      setAnchorEl(null);
      setOpen?.(false);
    };

    const anchorOrigin = {
      vertical: "bottom",
      horizontal: "right",
    };

    const transformOrigin = {
      vertical: "top",
      horizontal: "right",
    };

    const childProps = {
      close: () => handleClose(),
    };

    const anchor = anchorEl || (open && elem.current) || null;
    const isOpen = Boolean(anchor);

    return (
      <>
        <div ref={elem} style={{ display: "flex", alignItems: "center" }}>
          <Component style={isOpen ? { display: "block" } : undefined} onClick={handleClick} {...props} />
        </div>
        {isOpen && (
          <Popover
            id="menu"
            anchorEl={anchor}
            getContentAnchorEl={null}
            keepMounted
            open={isOpen}
            onClick={handleMenuClicked}
            onClose={handleClose}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            {...menuProps}
          >
            {typeof children === "function" ? children(childProps) : children}
          </Popover>
        )}
      </>
    );
  };
