import React from 'react';

// this is the equivalent to the createStore method of Redux
// https://redux.js.org/api/createstore

const AttributeContext = React.createContext();

export const useAttribute = () => {
  const context = React.useContext(AttributeContext)
  if (context === undefined) {
    throw new Error('useAttribute must be used within a AttributeContext')
  }
  return context
}

export default AttributeContext;
