import { createContext, forwardRef, PropsWithChildren, ReactNode, useContext } from "react";
import styled from "styled-components";
import Split, { SplitProps as _SplitProps } from "react-split";
import { useLocalStorage } from "react-use";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import { withProps } from "./util";
import { HidefilesIcon, ShowfilesIcon } from "@icons";
import { Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";

// re-export to make it easier to understand which one to use
export type SplitProps = _SplitProps;

const StyledSplit = styled(Split)`
  display: flex;
  flex-flow: row nowrap;
  flex: 1;

  --gutter-color: ${props => props.theme.palette.core.moreGrey};

  .gutter {
    /* cursor: col-resize; */
    cursor: url(/slider.svg) 16 16, col-resize;
    position: relative;

    &:hover, &:active {
      background-color: var(--gutter-color);
    }

    &::before {
      content: "";
      top: 0;
      bottom: 0;
      left: 50%;
      position: absolute;
      width: 1px;
      background-color: var(--gutter-color);
    }
  }
`;

interface PersistedState {
  sizes: number[];
  collapsed?: boolean;
};

type SplitPaneContextType = {
  collapsed: boolean;
  sizes: number[];
  onDragEnd: (sizes: number[]) => void;
  toggleCollapse: () => void;
};

const SplitPaneContext = createContext<SplitPaneContextType>(null as unknown as SplitPaneContextType);

export const useSplitPaneContext = () => {
  const context = useContext(SplitPaneContext);
  if (!context) throw new Error('useSplitPaneContext must be used within a SplitPaneProvider');
  return context;
};

export const SplitPane = withProps(StyledSplit, { cursor: "url(/slider.svg) 16 16, col-resize" });

export const Pane = styled.div`
  // splitjs claims works best with border box
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const usePersistedState = (key: string, initial: PersistedState) => {
  const [state, setState] = useLocalStorage(key, initial);

  if (!state) throw new Error('state requires initial value');

  return {
    collapsed: false, // default not collapsed
    ...state,
    onDragEnd: function (sizes) {
      setState({ ...state, sizes });
    },
    toggleCollapse: function () {
      console.log('[toggleCollapse]', state.collapsed, !state.collapsed);
      setState({ ...state, collapsed: !state.collapsed });
    }
  };
};

export type SplitPaneProviderProps = PropsWithChildren & {
  persistKey: string;
  initial: PersistedState;
};

export const SplitPaneProvider = ({ persistKey, initial, children }: SplitPaneProviderProps) => {
  const context = usePersistedState(persistKey, initial);
  return (
    <SplitPaneContext.Provider value={context}>
      {children}
    </SplitPaneContext.Provider>
  );
};

export type PersistedSplitPaneProps = SplitPaneProviderProps & {
  getPaneProps: (state: PersistedState) => SplitProps;
};

export const PersistedSplitPane = ({ persistKey, initial, getPaneProps, children }: PersistedSplitPaneProps) => {
  return (
    <SplitPaneProvider persistKey={persistKey} initial={initial}>
      <SplitPaneContext.Consumer>
        {
          (context) => {
            const { collapsed, sizes, toggleCollapse, ...props } = context;
            const paneProps = getPaneProps({ collapsed, sizes });
            return (
              <SplitPane {...props} {...paneProps}>
                {children}
              </SplitPane>
            );
          }
        }
      </SplitPaneContext.Consumer>
    </SplitPaneProvider>
  );
};

export type ShowHideButonProps = {
  hide: boolean;
  onClick: () => void;
} & IconButtonProps;

/**
 * A simple button that changes icon based on state
 */
export const ShowHideButon = forwardRef<any, ShowHideButonProps>(({ hide, onClick, ...props }, ref) => {
  return (
    <IconButton ref={ref} {...props} onClick={onClick}>
      {hide ? <ShowfilesIcon /> : <HidefilesIcon />}
    </IconButton>
  );
});

/**
 * A simple button to toggle the pane, requires being wrapped in a SplitPaneProvider
 */
export const TogglePaneButton = (props: IconButtonProps) => {
  const { collapsed, toggleCollapse } = useSplitPaneContext();
  const { t } = useTranslation();
  return (
    <Tooltip title={collapsed ? t('debugger.show') : t('debugger.hide')} placement="right">
      <ShowHideButon {...props} hide={collapsed} onClick={toggleCollapse} />
    </Tooltip>
  );
};