/* eslint-disable react/no-unused-prop-types, react/require-default-props */
import React from 'react';
import styled, { css } from 'styled-components';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import clsx from 'clsx';
import { scrollable } from '@common';
import memoizeOne, { EqualityFn } from 'memoize-one';
import shallowEqual from 'shallowequal';
import * as CtxNS from './ctx';


// ===================================================================================


const TreeItemMemo = React.memo(TreeItem);

const ItemComp = React.memo< { id: string } >(({ id }) => {
  const { idToItem, LabelComp } = CtxNS.useDottedTreeCtx();
  const { children } = idToItem[ id ];

  return React.useMemo(() => (
    <TreeItemMemo nodeId={id} label={<LabelComp id={id} />}>
      { children.map(it => <ItemComp key={it.id} id={it.id} />) }
    </TreeItemMemo>
  ), [id, children, LabelComp]);
});
ItemComp.displayName = 'components/DottedTree/ItemComp';


const ItemComps = React.memo< Pick< CtxNS.Value< unknown >, 'items' > >(({ items }) => {
  return <>{items.map(it => <ItemComp key={it.id} id={it.id} />)}</>;
});
ItemComps.displayName = 'components/DottedTree/ItemComps';

// ===================================================================================

const dashElementContent = css`
  content: '';
  position: absolute;
`;
const dashElementVertical = css`
  ${ dashElementContent };

  left: -0.725rem;
  top: 0;
  height: calc(100% - 2px);
  border-left: 1px dashed ${ p => p.theme.palette.secondary.dark };
`;

const dashElementHorizontal = css`
  ${ dashElementContent };

  width: 0.775rem;
  top: 50%;
  left: -0.725em;
  border-top: 1px dashed ${ p => p.theme.palette.secondary.dark };
`;

const StyledTreeView = styled(TreeView)`
  overflow: auto;
  height: 100%;

  .MuiTreeItem-iconContainer { display: none; }

  .MuiTreeItem-label { padding-left: 0; }

  .MuiTreeItem-root {
    // this is needed so that vertical dashed line is not disappearing during\
    // collapse transitions
    .MuiTreeItem-group {
      margin-left: 0;
      padding-left: 1.5rem;
    }


    .MuiTreeItem-root {
      position: relative;

      .MuiTreeItem-content {
        position: relative;

        :after {
          ${ dashElementHorizontal }
        }
      }

      &:last-of-type .MuiTreeItem-content:before {
        ${ dashElementVertical };

        height: calc(50% + 1px);
      }

      &:not(:last-of-type):before {
        ${ dashElementVertical };
      }
    }
  }

  .MuiTypography-root:focus-visible, .MuiTypography-root:hover,
  .MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label,
  .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
    background-color: initial;
  }
`;


export type Props< T > = Pick<
  CtxNS.Value< T >,
  | 'items'
  | 'expanded'
  | 'toggleExpanded'
  | 'LabelComp'
  | 'onSelect'
  | 'selected'
  | 'getIconById'
> & {
  className?: string;
}


// eslint-disable-next-line no-unused-vars
export type CalcCtxState<T> = (arg: (
  Pick<
    Props< T >,
    | 'items'
    | 'expanded'
    | 'toggleExpanded'
    | 'LabelComp'
    | 'onSelect'
    | 'selected'
    | 'getIconById'
  >
)) => CtxNS.Value< T >;


export class _< T > extends React.PureComponent< Props< T > > {
  // eslint-disable-next-line react/static-property-placement
  static displayName = 'components/DottedTree';

  __calcCtxState = (() => {
    console.log('calc state')
    const areCalcCtxStateParamsEqual: EqualityFn< CalcCtxState< T > > = ([p1], [p2]) => {
      console.log('shallow', p1, p2)
      return shallowEqual(p1, p2)
    };

    return memoizeOne< CalcCtxState< T > >(
      args => {
        const {
          items,
          expanded,
          toggleExpanded,
          LabelComp,
          onSelect,
          selected,
          getIconById,
        } = args;
        const idToItem: CtxNS.Value<T>[ 'idToItem' ] = {};
        console.log('gather')
        function gatherIdToItem(item: typeof items[ 0 ]): void {
          // this will only be true for our special root item\
          // used solely to call recursion more easily
          if(item.id !== '') {
            // eslint-disable-next-line no-param-reassign
            idToItem[ item.id ] = item;
          }

          item.children.forEach(child => gatherIdToItem(child));
        }

        const [first] = items;
        if(first !== undefined) {
          gatherIdToItem({ id: '', info: first.info, children: items });
        }

        return {
          items,
          idToItem,
          expanded,
          toggleExpanded,
          LabelComp,
          onSelect,
          selected,
          getIconById,
        };
      },
      areCalcCtxStateParamsEqual,
    );
  })();


  render(): JSX.Element {
    const {
      __calcCtxState,
      props: {
        className,
        items,
        LabelComp,
        expanded,
        toggleExpanded,
        onSelect,
        selected,
        getIconById,
      },
    } = this;

    const ctxState = __calcCtxState({
      items,
      expanded,
      toggleExpanded,
      LabelComp,
      onSelect,
      selected,
      getIconById,
    });

    console.log('render tree', expanded)
    return (
      <CtxNS.Ctx.Provider value={ctxState}>
        <StyledTreeView expanded={expanded} selected={selected} className={clsx(className, scrollable)}>
          <ItemComps items={items} />
        </StyledTreeView>
      </CtxNS.Ctx.Provider>
    );
  }
}


export * as CtxNS from './ctx';
export * as LabelComponentPartsNS from './LabelComponentParts';
