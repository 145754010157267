/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import styled from 'styled-components';
import { CloseIcon, LoadingDots } from '@icons';
import IconButton from '@material-ui/core/IconButton';
import { Button } from '@components';


const StyledLoadingDots = styled(LoadingDots)`
  g g { stroke: white; }
`;


const classes = {
  heading: {
    _: 'heading',

    title: 'title',
    cross: 'cross',
  },
  warning: 'warning',
  btns: {
    _: 'btns',

    cancel: 'cancel',
    yes: 'yes',
  },
};


const Wrap = styled.div`
  padding: 30px;
  width: 364px;

  font-family: Nunito;
  font-size: 16px;
  line-height: 24px;

  .${ classes.heading._ } {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 38px;
  }

  .${ classes.heading.title } {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
  }

  .${ classes.warning } {
    margin-bottom: 60px;
  }

  .${ classes.btns._ } {
    display: flex;
    justify-content: space-between;
  }

  .${ classes.btns.yes } {
    background-color: black;
    color: white;

    &:hover { background-color: black !important; }
  }
`;


export interface IProps {
  close: () => unknown;
  onDelete: () => unknown;
}


export const _: React.FC< IProps > = ({ close, onDelete }) => {
  const [isDeleting, setIsDeleting] = React.useState(false);
  const onDeleteHandler = React.useCallback(() => {
    setIsDeleting(true);
    onDelete();
  }, [onDelete]);

  return (
    <Wrap>
      <div className={classes.heading._}>
        <h3 className={classes.heading.title}>Delete release</h3>

        <IconButton
          onClick={close}
          className={classes.heading.cross}
        >
          <CloseIcon />
        </IconButton>
      </div>

      <div className={classes.warning}>
        Are you sure you want to delete this release? This can’t be undone.
      </div>

      <div className={classes.btns._}>
        <Button
          type='secondary'
          className={classes.btns.cancel}
          onClick={close}
        >
          Cancel
        </Button>

        <Button
          type='secondary'
          className={classes.btns.yes}
          onClick={onDeleteHandler}
          disabled={isDeleting}
        >
          { isDeleting ? <StyledLoadingDots /> : 'Yes, delete' }
        </Button>
      </div>
    </Wrap>
  );
};
