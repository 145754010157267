import type { useSetReport } from '../context/immutable';


export const PROP_NAME = 'decision_designer';


export const aTypes = {
  requestToSetReportId: `${ PROP_NAME }/requestToSetReportId`,
  requestToDeleteReport: `${ PROP_NAME }/requestToDeleteReport`,
} as const;

export type aTypesT = typeof aTypes;

export interface Actions {
  requestToSetReportId: {
    type: aTypesT[ 'requestToSetReportId' ];
    payload: {
      reportId: string;
      setCtxValue: ReturnType< typeof useSetReport >;
    }
  };
  requestToDeleteReport: {
    type: aTypesT[ 'requestToDeleteReport' ];
    payload: {
      releaseId: string;
      reportId: string;
      closeDeleteModal: () => unknown
    }
  }
}

export const aCreators: {
  [ K in keyof Actions ]: Actions[ K ] extends { payload: any }
    ? (p: Actions[ K ][ 'payload' ]) => Actions[ K ]
    : () => Actions[ K ]
} = {
  requestToSetReportId: p => ({ type: aTypes.requestToSetReportId, payload: p }),
  requestToDeleteReport: p => ({ type: aTypes.requestToDeleteReport, payload: p }),
};


