import { Typography } from "@material-ui/core";
import { DebugIcon } from "@icons";
import type React from "react";
import { Button } from "@components";
import styled from "styled-components";
import { Trans } from "react-i18next";
import { useErrorReporting } from "../ErrorReporting";

const Suggestions = styled.ul`
  text-align: left;
  > li {
    // copy body1, but since we are outside the theme, we need to manually set this
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
  }
`;

export interface ErrorInfoProps {
  extraSuggestions?: React.ReactNode;
}

const ReportIssue = () => {
  const errorReporting = useErrorReporting();
  return (
    <Button type="tertiary" size="small" onClick={() => errorReporting?.open()} startIcon={<DebugIcon />}>
      Report issue
    </Button>
  );
};

const ErrorInfo = (props: ErrorInfoProps) => {
  const { extraSuggestions } = props;
  return (
    <>
      <Typography
        component="h1"
        variant="h2"
        align="center"
        gutterBottom
      >
        <Trans i18nKey="fallback_error_title"></Trans>
      </Typography>
      <Typography
        variant="body1"
        align="center"
        gutterBottom
      >
        <Trans i18nKey="fallback_error_body"></Trans>
      </Typography>
      <ReportIssue />
      <Typography
        variant="h5"
        align="center"
        gutterBottom
        style={{ marginTop: "2rem" }}
      >
        <Trans i18nKey="fallback_error_next"></Trans>
      </Typography>

      <Suggestions>
        <li><Trans i18nKey="fallback_error_refresh"></Trans></li>
        <li>
          <Trans components={{
            a: <a/>
          }}  i18nKey={"fallback_error_contact"} />
          &nbsp; (<a href="mailto: support@decisively.io">support@decisively.io</a>)
        </li>
        {extraSuggestions}
      </Suggestions>
    </>
  );
};

export default ErrorInfo;
