import {
  deserializeDocumentV2Contents,
  deserializeDocumentV2ContentsToV3,
  serializeDocumentV2Contents,
} from "@common/editor/RuleAuthor/ruleSerialisation";
import {
  deserializeSheetsV1Contents,
  serializeSheetsV1Contents,
} from "@pages/models/release/Documents/Sheets/SheetViewer_helpers";
import {
  deserialize as deserializeDocumentV1Contents,
  serialize as serializeDocumentV1Contents,
} from "@pages/documents/OPM";
import type { Document } from "@packages/commons";
import produce from "immer";
import type { BackendResource, DeepPartial } from "@imminently/immi-query";
import type { DocumentContents, RuleDocContentsV2 } from "@packages/commons";
import {
  DESERIALIZED_SYMBOL,
  type DeserializedRuleDocContentsV2,
  type DeserializedDocumentContents,
} from "@pages/documents/documents.slice.constants";
import identity from "lodash/identity";

type SerializerFunc = (contents: DeserializedDocumentContents) => SerializedDocumentContents;
type SerializedDocumentContents = DocumentContents & { [DESERIALIZED_SYMBOL]?: never };

export const getSerializeFunc = (
  version: unknown,
  type: unknown,
): SerializerFunc => {
  if (version === 2 || version === 3) {
    // @ts-ignore
    return serializeDocumentV2Contents;
  }
  if (type === "rulesheet") {
    // @ts-ignore
    return serializeSheetsV1Contents;
  }
  if(type === "datatable") return identity;
  return serializeDocumentV1Contents;
};

export const serializeContents = (
  contents: DeserializedDocumentContents,
  version: number | undefined,
  type: string | undefined,
): SerializedDocumentContents | undefined => {
  const serialize = getSerializeFunc(version, type);
  if (!contents || Object.keys(contents).length === 0) return;
  return serialize(contents);
};

export const serializeDocument = (document: BackendResource<Document> & { contents: DeserializedDocumentContents }) => {
  const { version, type } = document;
  const serialize = getSerializeFunc(version, type);
  // TODO the return type of this is technically wrong
  return produce(document, (draft) => {
    (draft as any).contents = serialize(document.contents);
    if(version && version > 1 && type === "ruledoc") {
      // insert global scope for rules
      // we want this here and not in the generic serialisation as we want it separate to the isDirty check
      (draft as any).contents.rules =
        (draft as any).contents.rules.map(n => {
          if(n.sectionId && n.sectionId !== "global") return n;
          // store global section id as the file path
          return { ...n, sectionId: "global" };
        });
    }
  });
};

export type DeserializeFunc = (contents: undefined | DeepPartial<DocumentContents>) => DeserializedDocumentContents;
export const getDeserializeFunc = (version: number | undefined, type: string | undefined): DeserializeFunc => {
  if (version && version > 1) {
    return (contents) => {
      if (!contents) {
        return {[DESERIALIZED_SYMBOL]: true, rules: [], source: [], sections: [] }
      }
      const fn = version === 2 ? deserializeDocumentV2ContentsToV3 : deserializeDocumentV2Contents;
      return {
        [DESERIALIZED_SYMBOL]: true,
        ...fn(contents as RuleDocContentsV2),
      } as DeserializedRuleDocContentsV2;
    };
  }
  if (type === "rulesheet") {
    return deserializeSheetsV1Contents as any;
  }
  return deserializeDocumentV1Contents as any;
};

export const deserializeDocument = (
  document: BackendResource<Document>,
): BackendResource<Document>& {contents: DeserializedDocumentContents} => {
  const { version, type } = document;
  if(type === 'datatable') return {
    ...document,
    contents: {
      ...document.contents,
      headers: document.contents?.headers || [],
      rows: document.contents?.rows || [],
      [DESERIALIZED_SYMBOL]: true,
    },
  };
  const deserialize = getDeserializeFunc(version, type);
  return produce(document, (draft) => {
    (draft as any).contents = deserialize(document.contents);

    if (import.meta.env.DEV && !draft?.contents?.[DESERIALIZED_SYMBOL]) {
      console.error("Failed to deserialize document contents, missing deserialized symbol. Something isn't right.", draft.contents);
    }
  }) as BackendResource<Document>& {contents: DeserializedDocumentContents};
};
