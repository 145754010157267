
import { START_TIME, END_TIME } from '@components/TemporalValue/TempTimeline/redux';
import moment from 'moment';
import { v4 as uuid } from 'uuid';

/*
  uncertain - null
  unknown - undefined
  notValid - '**DCSVLY/NOTVALID**'
  boolean
  date - ISOString
  number
  increment
  other (strings)
*/

export const NOT_VALID = 'Not Valid';
export const DATE_PICKER_FORMAT = 'DD-MM-YYYY';
export const valueTypes = /** @type { const } */({
  uncertain: 'Uncertain',
  unknown: 'Unknown',
  notValid: 'Not Valid',
  boolean: 'Boolean',
  date: 'Date',
  number: 'Number',
  increment: 'Increment',
  other: 'Other',
});


/** @typedef { import( '../../../../decisions/DecisionDashboard/Reports/Details/UnifiedTimeline/types' ).Range } Range */
/** @typedef { import( '../../../../decisions/DecisionDashboard/Reports/Details/UnifiedTimeline/types' ).TemporalValue } TemporalValue */
/** @typedef { Extract< Range[ 'v' ], { increment: string } > } Increment */
/** @typedef { Increment & { dirtyOffset: string } } DirtyIncrement */

/** @type { ( v: Range[ 'v' ] ) => typeof valueTypes[ keyof typeof valueTypes ] } */
export const rangeValueToValueType = v => {
  switch (v) {
    case null: return valueTypes.uncertain;
    case undefined: return valueTypes.unknown;
    case NOT_VALID: return valueTypes.notValid;
    default:
  }

  if (typeof v === 'boolean') return valueTypes.boolean;
  if (typeof v === 'number') return valueTypes.number;
  if (typeof v === 'object') return valueTypes.increment;

  return moment(v).isValid()
    ? valueTypes.date
    : valueTypes.other;
};


/**
  @typedef TimePoint
  @type {
    & { t: string; id: ReturnType< typeof uuid > }
    & (
      | { type: typeof valueTypes.uncertain; v: null }
      | { type: typeof valueTypes.unknown; v: undefined }
      | { type: typeof valueTypes.notValid; v: typeof NOT_VALID }
      | { type: typeof valueTypes.boolean; v: boolean }
      | { type: typeof valueTypes.date; v: string }
      | { type: typeof valueTypes.number; v: number, dirtyValue: string }
      | { type: typeof valueTypes.increment; v: DirtyIncrement  }
      | { type: typeof valueTypes.other; v: string }
    )
  }
 */

/** @type { (arg: unknown) => arg is TimePoint[] } */
export const isTimePoints = arg => {
  if (!Array.isArray(arg)) return false;
  if (arg.length === 0) return true;

  const first = arg[0];
  if (typeof first !== 'object' || first === null) return false;

  return first.t !== undefined && first.id !== undefined;
};
// ===================================================================================

/** @type { (v: Range[ 'v' ] ) => string } */
export const mapValueToUi = v => {
  switch (v) {
    case null: return valueTypes.uncertain;
    case undefined: return valueTypes.unknown;
    case NOT_VALID: return valueTypes.notValid;
    default:
  }

  if (typeof v === 'object') {
    return `Incr: ${v.increment}${v.offset === undefined ? '' : ` (offset: ${v.offset})`}`;
  }

  return String(v);
};

// ===================================================================================

/** @type { ( r: Range ) => [ TimePoint, TimePoint ] } */
export const rangeToTimePoints = r => {
  const type = rangeValueToValueType(r.v);

  const appropriateValue = (() => {
    if (type !== valueTypes.increment) return r.v;

    const increment = /** @type { Increment } */(r.v);

    /** @type { DirtyIncrement } */
    const dirtyIncrement = {
      ...increment,
      dirtyOffset: increment.offset === undefined
        ? ''
        : String(increment.offset),
    };

    return dirtyIncrement;
  })();

  const start = /** @type { TimePoint } */({
    t: r.r.start,
    id: uuid(),
    type,
    v: appropriateValue,
    ...(type === valueTypes.number ? { dirtyValue: String(appropriateValue) } : {}),
  });

  return [
    start,
    { ...start, t: r.r.end, id: uuid() },
  ];
};


/** @type { ( rs: Range[] ) => TimePoint[] } */
export const rangesToTimePoints = rs => (
  rs
    .map(it => rangeToTimePoints(it)[0])
    .concat(rangeToTimePoints(rs[rs.length - 1])[1])
);

// ===================================================================================

/** @type { ( points: TimePoint[] ) => Range[] } */
export const timePointsToRanges = points => (
  points.reduce(
    (a, point, i, points) => {
      if (i === 0) return a;

      const prevPoint = points[i - 1];
      return a.concat({
        r: {
          start: prevPoint.t,
          end: point.t,
        },
        v: prevPoint.v,
      });
    },
    /** @type { Range[] } */([]),
  )
);


// ===================================================================================

/** @type { Range } */
export const defaultRange = {
  r: { start: START_TIME, end: END_TIME },
  v: 0,
};

/** @type { TemporalValue } */
export const defaultTemporal = { temporal: { ranges: [defaultRange] } };

/** @type { TimePoint[] } */
export const defaultTimePoints = rangesToTimePoints([defaultRange]);

/** @type { () => TimePoint } */
export const getGenericTimePoint = () => ({
  id: uuid(),
  t: new Date().toISOString(),
  type: valueTypes.unknown,
  v: undefined,
});

/** @type { ( ts: TimePoint [] ) => typeof ts } */
export const normalizeTimepointsSort = ts => ts
  .slice()
  .sort((a, b) => (
    /* eslint-disable operator-linebreak,indent */
    a.t === START_TIME ? -1 :
      a.t === END_TIME ? 1 :
        b.t === START_TIME ? 1 :
          b.t === END_TIME ? -1 :
            a.t > b.t ? 1 : -1
    /* eslint-enable operator-linebreak,indent */
  ));

// ===================================================================================


/** @type { ( ts: TimePoint[], t: TimePoint ) => { timePoints: typeof ts, id: TimePoint[ 'id' ] } } */
export function addTimePointToTimepointsArr(ts) {
  const t = getGenericTimePoint();

  const maybeExistingT = ts.find(it => it.t === t.t);
  if (maybeExistingT !== undefined) {
    return { timePoints: ts, id: maybeExistingT.id };
  }

  return {
    timePoints: normalizeTimepointsSort(ts.concat(t)),
    id: t.id,
  };
}

/** @type { ( ts: TimePoint[], t: TimePoint ) => typeof ts } */
export function editTimepoint(ts, t) {
  return normalizeTimepointsSort(ts.map(it => (it.id === t.id ? t : it)));
}


// ===================================================================================

/** @type { ( ts: TimePoint[], id: TimePoint[ 'id' ] ) => typeof ts } */
export function removeTimePoint(ts, id) {
  const nextTs = ts.length > 2
    ? ts.filter(it => it.id !== id && it.v !== START_TIME && it.v !== END_TIME)
    : ts;

  return nextTs.length === 2
    ? defaultTimePoints
    : nextTs;
}
