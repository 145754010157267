import React from "react";
import styled from "styled-components";

import { WarningIcon } from "@icons";
import { Typography } from "@material-ui/core";

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 2rem;
  background-color: ${(props) => props.theme.palette.warning.main};

  svg {
    height: 1rem;
    width: 1rem;
  }
`;

export type WarningProps = {
  message: string;
};

export const Warning = ({ message }: WarningProps) => {
  return (
    <Container>
      <WarningIcon />
      <Typography variant="caption">{message}</Typography>
    </Container>
  );
};
