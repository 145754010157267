import React from "react";
import styled from "styled-components";
import Box from '@material-ui/core/Box';
import { LinkButton } from "@components/buttons";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { createToggleExpanded } from '@components/DottedTree/ctx';
import * as DottedTreeNS from '@components/DottedTree';


const Wrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledTree = styled( DottedTreeNS._ )`
  flex-grow: 1;
  padding: 1rem;
`;

export type WithLabel = { label: React.ReactNode; };
/**
 * uses generic props for dotted tree and combines those with\
 * WithLabel.
 */
export type LeftTreeProps = DottedTreeNS.Props< WithLabel >;

const LabelCompWrap = styled.div`
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  height: 2rem;

  &.isSelected .MuiTypography-root {
    font-weight: bold;
  }
`;

const LabelComp: LeftTreeProps[ 'LabelComp' ] = React.memo(p => {
  const { id } = p;
  const { idToItem, selected, onSelect } = DottedTreeNS.CtxNS.useDottedTreeCtx< WithLabel >();
  const item = idToItem[ id ];
  const isSelected = selected.indexOf(id) !== -1;

  const onClick = React.useCallback(() => (
    onSelect(id)
  ), [ onSelect, id ]);

  if ( !item ) return null;

  return (
    <LabelCompWrap className={ isSelected ? 'isSelected' : undefined } onClick={ onClick }>
      <Typography style={{ flexGrow: 1 }}>
        { item.info.label }
      </Typography>

      <DottedTreeNS.LabelComponentPartsNS.CollapseToggle id={ id } />
    </LabelCompWrap>
  );
});
LabelComp.displayName = 'components/TwoPaneDottedTree/Left/LabelComp';


const getIconById = () => null;
export type Item = LeftTreeProps[ 'items' ][ 0 ];


export type LeftProps = {
  items: Item[];
  selectedId: string | null;
  setSelectedId: ( v: string ) => unknown;
  expanded: LeftTreeProps[ 'expanded' ];
  setExpanded: React.Dispatch<React.SetStateAction< string[] >>;
}
export const Left: React.FC< LeftProps > = React.memo(p => {
  const { items, selectedId, setSelectedId, expanded, setExpanded } = p;

  const toggleExpanded = React.useMemo< LeftTreeProps[ 'toggleExpanded' ] >(() => (
    createToggleExpanded( setExpanded )
  ), [setExpanded]);

  const collapseAll = React.useCallback(() => (
    setExpanded([])
  ), [ setExpanded ]);
  const expandAll = React.useCallback(() => (
    setExpanded( DottedTreeNS.CtxNS.gatherIdsForExpandAll( items ) )
  ), [ items, setExpanded ]);

  const selected = React.useMemo< LeftTreeProps[ 'selected' ] >(() => (
    selectedId === null ? [] : [ selectedId ]
  ), [ selectedId ]);
  const onSelect = React.useCallback< LeftTreeProps[ 'onSelect' ] >(id => (
    setSelectedId(id)
  ), [ setSelectedId ]);

  return (
    <Wrap>
      <Box display='flex' gridGap='1rem' padding='1rem'>
        <LinkButton size='small' onClick={ expandAll }>
          Expand all
        </LinkButton>

        <LinkButton size='small' onClick={ collapseAll }>
          Collapse all
        </LinkButton>
      </Box>

      <Divider style={{ backgroundColor: 'rgb(229, 229, 229)', marginRight: '-0.125rem' }} />

      <StyledTree
        LabelComp={ LabelComp }
        expanded={ expanded }
        toggleExpanded={ toggleExpanded }
        getIconById={ getIconById }
        items={ items }
        selected={ selected }
        onSelect={ onSelect }
      />
    </Wrap>
  );
});
Left.displayName = 'components/TwoPaneDottedTree/Left';
