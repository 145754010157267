import React from "react";
import {
  DefinedInIcon,
  TagIcon,
  BooleanIcon,  // enumeration
  InnovateIcon, // enrichment
  TooltipIcon,
  SourcecodeIcon,
  GuidIcon,
  DocumentsIcon,
} from "@icons";
import Tooltip from "@material-ui/core/Tooltip";
import { GraphNode } from "@packages/commons";
import { isStrNotNullOrBlank } from "../../util/UtilString";
import cn from "clsx";
import GraphNodeBarRules from "./GraphNodeBarRules";

import stl from "./GraphNode.module.scss";

interface GraphNodeBarProps {
  node: GraphNode;
}

const GraphNodeBar = (props: GraphNodeBarProps) => {

  const {
    node,
  } = props;
  const {
    id: nodeGuid = "",
    path = "",
    definedIn,
    usedIn,
    tags,
  } = node;
  const hasDefinedIn = definedIn && isStrNotNullOrBlank(definedIn);
  const usedInSansDefinedIn = hasDefinedIn ? (usedIn || []).filter((doc) => doc !== definedIn) : (usedIn || []);
  const hasUsedIn = usedInSansDefinedIn && usedInSansDefinedIn.length > 0;
  const publicName = node.publicId;
  const hasPublicName = publicName && isStrNotNullOrBlank(publicName);
  const explaination = node.explanation;
  const hasExplanation = explaination && isStrNotNullOrBlank(explaination);
  const enumeration = node.enum;
  const hasEnumeration = enumeration && isStrNotNullOrBlank(enumeration);
  const hasTags = tags && Array.isArray(tags) && tags.length > 0;

  // -- render

  const renderIcon = (Icon: any, rhsText?: string) => {

    return (
      <div
        className={cn(
          stl.circle_bg,
          {[stl.fit_width_content]: !!rhsText},
        )}
      >
        <Icon />
        {rhsText}
      </div>
    );
  };

  const renderDefinedIn = () => {

    if (!hasDefinedIn) {
      return null;
    }

    return (
      <Tooltip
        title={<span>Defined in:<br />{definedIn}</span>}
      >
        {renderIcon(DefinedInIcon)}
      </Tooltip>
    );
  };

  const renderUsedBy = () => {

    if (!hasUsedIn) {
      return null;
    }

    const usedInList = <ul style={{marginLeft: 0, paddingLeft: "1rem"}}>{usedInSansDefinedIn.map((doc, idx) => {
      return (<li key={idx}>{doc}</li>);
    })}</ul>;

    return (
      <Tooltip
        title={<span>Used in:<br />{usedInList}</span>}
      >
        {renderIcon(DocumentsIcon, `${usedInSansDefinedIn.length}`)}
      </Tooltip>
    );
  };

  const renderTags = () => {

    if (!hasTags) {
      return null;
    }

    return (
      <Tooltip
        title={<span>Tags:<br />{tags.join(", ")}</span>}
      >
        {renderIcon(TagIcon, `${tags.length}`)}
      </Tooltip>
    );
  };

  const renderPublicName = () => {

    if (!hasPublicName) {
      return null;
    }

    return (
      <Tooltip
        title={<span>Public name:<br />{publicName}</span>}
      >
        {renderIcon(SourcecodeIcon)}
      </Tooltip>
    );
  };

  const renderExplanation = () => {

    if (!hasExplanation) {
      return null;
    }

    return (
      <Tooltip
        title={<span>Explanation:<br />{explaination}</span>}
      >
        {renderIcon(TooltipIcon)}
      </Tooltip>
    );
  };

  const renderEnumeration = () => {

    if (!hasEnumeration) {
      return null;
    }

    return (
      <Tooltip
        title={<span>Enumeration:<br />{enumeration}</span>}
      >
        {renderIcon(BooleanIcon)}
      </Tooltip>
    );
  };

  const renderGuid = () => {

    const pathInfo = path
    ? <><br/><span>Path:<br/>{path}</span></>
    : null;

      return (
        <Tooltip
          title={<><span>GUID:<br />{nodeGuid}</span>{pathInfo}</>}
        >
          {renderIcon(GuidIcon)}
        </Tooltip>
      );
    }

  return(
    <div
      className={stl.container_bottom_bar}
    >
      {renderGuid()}
      <div
        className={stl.divider}
      />
      {renderDefinedIn()}
      {renderUsedBy()}
      {renderExplanation()}
      {renderEnumeration()}
      {renderPublicName()}
      {renderTags()}
      {/* <GraphNodeBarRules
        node={node}
      /> */}
    </div>
  );
};

export default (GraphNodeBar);