import { all, put, select, takeLatest } from 'redux-saga/effects';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { push as navigate } from 'connected-react-router';
import {
  crudGetOne,
  crudGetList,
  CRUD_GET_LIST_SUCCESS,
  CRUD_CREATE_SUCCESS,
  CRUD_DELETE_SUCCESS,
  CRUD_GET_ONE_FAILURE,
  SIGN_IN_SUCCESS,
} from '@imminently/imminently_platform';
// eslint-disable-next-line import/extensions
import { PER_PAGE as projectsPerPage } from './loadMoreProjects.saga';

export const SET_WORKSPACE = 'DEC/SET_WORKSPACE';
export const SET_PROJECT = 'DEC/SET_PROJECT';
export const SET_RELEASE = 'DEC/SET_RELEASE';
export const SET_CONVERSATION = 'DEC/SET_CONVERSATION';
export const SET_DEC_REPORT = 'DEC/SET_DEC_REPORT';
export const SET_SCOPE = 'DEC/SET_SCOPE';
const SCOPE_STORE_KEY = 'scope';

export const setWorkspace = id => ({
  type: SET_WORKSPACE,
  payload: id,
});

export const setProject = (id, meta) => ({
  type: SET_PROJECT,
  payload: id,
  meta,
});

export const setRelease = id => ({
  type: SET_RELEASE,
  payload: id,
});
export const setConversation = id => ({
  type: SET_CONVERSATION,
  payload: id,
});
export const setDecReport = id => ({
  type: SET_DEC_REPORT,
  payload: id,
});

export const setScope = scope => ({
  type: SET_SCOPE,
  payload: scope,
});

export const storeScope = scope => {
  localStorage.setItem(SCOPE_STORE_KEY, JSON.stringify(scope));
};

export const getScope = () => {
  const raw = localStorage.getItem(SCOPE_STORE_KEY);
  return raw ? JSON.parse(raw) : null;
};

const getOne = (model, id) => crudGetOne(model, id, { refresh: false, onFailure: {} });

export const initScope = function* initScope() {
  const currentScope = yield select(s => s.scope);

  // we already have a scope, simply do nothing
  if (currentScope.workspace) return;

  const scope = getScope();
  // ensure we have a valid stored scope
  if (scope && typeof scope.workspace === 'string') {
    if (!scope.conversation) scope.conversation = '';
    if (!scope.decReport) scope.decReport = '';

    // set entire scope
    const actions = [
      put(setScope(scope)),
    ];

    // get each element separately to avoid sagas
    if (scope.workspace !== null && scope.workspace !== undefined) {
      actions.push(put(getOne('workspaces', scope.workspace)));
    }
    if (scope.project !== null && scope.project !== undefined) {
      actions.push(put(getOne('models', scope.project)));
    }
    if (scope.release !== null && scope.release !== undefined) {
      actions.push(put(getOne('releases', scope.release)));
    }

    yield all(actions);
  } else {
    // no scope, load all workspaces to trigger default select sagas
    yield put(getWorkspaces());
  }
};

export const reducer = (previousState = { conversation: '' }, action) => {
  const { type, payload } = action;

  let newState;
  switch (type) {
    case SET_WORKSPACE:
      newState = { ...previousState, workspace: payload };
      break;
    case SET_PROJECT:
      newState = { ...previousState, project: payload };
      break;
    case SET_RELEASE:
      newState = { ...previousState, release: payload };
      break;
    case SET_CONVERSATION:
      newState = { ...previousState, conversation: payload };
      break;
    case SET_DEC_REPORT:
      newState = { ...previousState, decReport: payload };
      break;
    case SET_SCOPE:
      newState = { ...payload };
      break;
    default:
      newState = previousState;
  }

  if (newState !== previousState && !isEmpty(newState)) {
    // store to local storage
    storeScope(newState);
  }

  // store previous state for use in sagas
  const { _prev, ...prev } = previousState;
  newState._prev = prev;

  return newState;
};

/**
 * @typedef Scope
 * @property {string} [workspace]
 * @property {string} [project]
 * @property {string} [release]
 * @property {string} [conversation]
 * @property {string} [decReport]
 * @property { undefined
 *  | { workspace: string; project: string; release: string; conversation: string }
 * } [_prev]
 */

// currently get up to 100 workspaces, need to fix / change how pagination works
const getWorkspaces = () => crudGetList('workspaces', 'default', { pagination: { perPage: 100, page: 1 } });

const getProjects = (workspace, context) => crudGetList('models', workspace, {
  filter: { workspace },
  pagination: { perPage: projectsPerPage, page: 1 },
  sort: { field: 'created', order: 'desc' },
  context,
});

const getCurrentRelease = project => crudGetList('releases', project, {
  filter: { model: project, env: 'test', activeVersionFlag: true },
});

/**
 * Hook to retrive current scope and updates
 * @returns {Scope} The current scope
 */
export const useScope = () => useSelector(s => s.scope);

const workspaceCreated = function* workspaceCreated(action) {
  console.log('loadWorkspaces', action);
  const { payload } = action;
  yield all([
    // set created workspace as desired
    put(setWorkspace(payload.data.id)),
    // load all workspaces to update list
    // TODO change to dispatch the forAsyncLoader
    // put(getWorkspaces()),
    // go to projects
    put(navigate('/projects')),
  ]);
};

const workspaceDeleted = function* workspaceDeleted(action) {
  const { payload, requestPayload } = action;
  const { workspace } = yield select(s => s.scope);
  console.log('workspace deleted', payload, requestPayload, workspace);
  if (requestPayload.id === workspace) {
    // refresh list and trigger sages to get default
    yield put(getWorkspaces());
  }
};

const workspaceLoadFailed = function* workspaceLoadFailed(action) {
  const { payload, requestPayload } = action;
  const { workspace } = yield select(s => s.scope);
  console.log('workspace failed to load', payload, requestPayload, workspace);
  // trigger sages to get default
  yield put(getWorkspaces());
};

const projectCreated = function* projectCreated(action) {
  console.log('project created', action);
  const { data: project } = action.payload;
  const setProjectAction = setProject(
    project.id,
    { skipGetList: true, resetRelease: true },
  );
  const getProjectsAction = getProjects(project.workspace, 'afterCreateProject');

  yield all([
    // set created project as desired
    put(setProjectAction),
    // load all projects to update list
    put(getProjectsAction),
    // go to build
    put(navigate('/build')),
  ]);
};

const workspaceChanged = function* workspaceChanged(action) {
  const { payload: workspace } = action;
  const { _prev, project } = yield select(s => s.scope);
  console.log('scope saga, workspaceChanged:', workspace, project, _prev);

  const actions = [];
  if (workspace !== _prev.workspace) {
    console.log('workspace changed');
    // get the new workspace
    actions.push(put(crudGetOne('workspaces', workspace, { refresh: false, onFailure: {} })));
    if (_prev.workspace) {
      // unset project as it no longer applies to selected workspace
      actions.push(put(setProject(null)));
    }

    // get list of projects so we can select most recent
    actions.push(put(getProjects(workspace)));
  } else if (project) {
    // workspace has not changed, ensure we have selected project
    actions.push(
      put(crudGetOne('models', project, { refresh: false, onFailure: {} })),
    );
  } else {
    // don't have a project, get list so we can select current
    // TODO ideally this should get/set the newest project only
    actions.push(put(getProjects(workspace)));
  }

  yield all(actions);
};

const projectChanged = function* projectChanged(action) {
  const { payload: project, meta } = action;
  const { resetRelease } = meta ?? { resetRelease: false };
  const { _prev, release } = yield select(s => s.scope);
  console.log('scope saga, projectChanged:', project, release, _prev);

  if (project === null || project === undefined) {
    // unset release
    yield put(setRelease(null));
    return;
  }

  const actions = [];

  if (project !== _prev.project) {
    console.log('project changed');
    // ensure we have the project we changed to
    actions.push(put(crudGetOne('models', project, { refresh: false, onFailure: {} })));
    // don't auto unset release as it may have already been changed
  } else if (release) {
    // project has not changed, ensure we have selected release
    actions.push(
      put(crudGetOne('releases', release, { refresh: false, onFailure: {} })),
    );
  }

  if (resetRelease) {
    // unset release as it either no longer applies, or we want to default to current
    actions.push(put(setRelease(null)));
  }

  if (resetRelease || !release) {
    // get the current release
    actions.push(put(getCurrentRelease(project)));
  }

  yield all(actions);
};

const releaseChanged = function* releaseChanged(action) {
  const { payload: release } = action;
  const { _prev } = yield select(s => s.scope);
  console.log('scope saga, releaseChanged:', release, _prev);
  // release might be set to null or undefined, only fetch if truthy
  if (release) {
    // don't check if release changed, in case we just want to re-fetch
    // ensure we have the release
    yield put(crudGetOne('releases', release, { refresh: false, onFailure: {} }));
  }
};

const defaultRequired = (id, items) => !id || items.every(i => i.id !== id);

const workspacesUpdated = function* workspacesUpdated(action) {
  const { payload } = action;
  const { workspace } = yield select(s => s.scope);
  const workspaces = payload.data;
  console.log('scope saga, workspacesUpdated', workspace, workspaces);
  if (workspaces?.length > 0 && defaultRequired(workspace, workspaces)) {
    const def = workspaces.find(w => w.name === 'Default') || workspaces[ 0 ];
    console.log('selecting default workspace:', def.id);
    yield put(setWorkspace(def.id));
  }
};

const modelsUpdated = function* modelsUpdated(action) {
  const { payload, meta } = action;
  console.log('scope saga, modelsUpdated:', meta);
  const { workspace, project } = yield select(s => s.scope);
  const projects = payload.data;
  if (meta.location === workspace) {
    if (projects?.length > 0) {
      if (defaultRequired(project, projects)) {
        const def = projects[ 0 ].id;
        console.log('selecting default project:', workspace, def);
        yield put(setProject(def));
      }
    } else {
      yield put(setProject(null));
    }
  }
};

const releasesUpdated = function* releasesUpdated(action) {
  const { payload, meta } = action;
  console.log('scope saga, releasesUpdated:', meta);
  const { project, release } = yield select(s => s.scope);
  const releases = payload.data;
  if (meta.location === project && releases?.length > 0 && defaultRequired(release, releases)) {
    // select the current release, fallback to first
    const def = (releases.find(r => r.activeVersionFlag) || releases[ 0 ]).id;
    console.log('selecting default release:', project, def);
    yield put(setRelease(def));
  }
};

const isDefaultList = resource => ({ type, meta }) => {
  return type === CRUD_GET_LIST_SUCCESS &&
    meta?.resource === resource &&
    meta?.id === 'default' &&
    meta.location !== 'forAsyncSelect' &&
    meta.context !== 'afterCreateProject';
};

export const saga = function* saga() {
  yield all([
    // need get projects on workspace change
    takeLatest(SET_WORKSPACE, workspaceChanged),
    // need get releases on project change
    takeLatest(SET_PROJECT, projectChanged),
    // ensure we load release on select
    takeLatest(SET_RELEASE, releaseChanged),
    // need set default workspace on receive workspaces
    takeLatest(
      isDefaultList('workspaces'),
      workspacesUpdated,
    ),
    // need set default project on receive projects
    takeLatest(
      isDefaultList('models'),
      modelsUpdated,
    ),
    // need set default release on receive releases
    takeLatest(
      isDefaultList('releases'),
      releasesUpdated,
    ),
    takeLatest(
      action => action.type === CRUD_CREATE_SUCCESS && action.meta?.resource === 'workspaces',
      workspaceCreated,
    ),
    takeLatest(
      action => action.type === CRUD_CREATE_SUCCESS && action.meta?.resource === 'models',
      projectCreated,
    ),
    takeLatest(
      action => action.type === CRUD_DELETE_SUCCESS && action.meta?.resource === 'workspaces',
      workspaceDeleted,
    ),
    takeLatest(
      action => action.type === CRUD_GET_ONE_FAILURE && action.meta?.resource === 'workspaces',
      workspaceLoadFailed,
    ),
    // load scope after sign in success
    takeLatest(SIGN_IN_SUCCESS, initScope),
    // load scope after sign in success
    takeLatest(action => action.type === SET_SCOPE && action.payload === null, function* resetScope() {
      // no scope, load all workspaces to trigger default select sagas
      yield put(getWorkspaces());
    }),
  ]);
};
