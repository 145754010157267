import type { Theme } from "@material-ui/core";
import {
  type PlateEditor,
  type PlateRenderElementProps,
  type TElement,
  type Value,
  createPluginFactory,
} from "@udecode/plate";
import styled from "styled-components";
import { ELEMENT_BANNER } from "../elements";

export const Banner = { GREEN: "green", YELLOW: "yellow", RED: "red" } as const;
export type Banner = (typeof Banner)[keyof typeof Banner];
export interface TBanner extends TElement {
  type: typeof ELEMENT_BANNER;
  banner: Banner;
}

export const getBannerColour = (banner: Banner, theme: Theme) => {
  switch (banner) {
    case Banner.GREEN:
      return theme.palette.success.main;
    case Banner.YELLOW:
      return theme.palette.warning.main;
    case Banner.RED:
      return theme.palette.error.main;
  }
};

export const createBanner = (banner: Banner = Banner.GREEN, text = "") => ({
  type: ELEMENT_BANNER,
  banner,
  children: [{ text }],
});

const BannerContainer = styled.div`
  position: relative;

  &[data-banner="green"] {
    --banner-color: ${(props) => props.theme.palette.success.light};
    --banner-border: ${(props) => props.theme.palette.success.main};
  }
  &[data-banner="yellow"] {
    --banner-color: ${(props) => props.theme.palette.warning.light};
    --banner-border: ${(props) => props.theme.palette.warning.main};
  }
  &[data-banner="red"] {
    --banner-color: ${(props) => props.theme.palette.error.light};
    --banner-border: ${(props) => props.theme.palette.error.main};
  }

  > .banner {
    padding: 0.5rem 1rem;
    background-color: var(--banner-color);
    border-radius: 0.125rem 0.25rem 0.25rem 0.125rem;
    border-left: 2px solid var(--banner-border);
  }
`;

export const BannerRenderElement = ({ attributes, children, element }: PlateRenderElementProps<Value, TBanner>) => {
  return (
    <BannerContainer
      {...attributes}
      data-banner={element.banner}
    >
      <div className="banner">{children}</div>
    </BannerContainer>
  );
};

export const createBannerPlugin = createPluginFactory({
  key: ELEMENT_BANNER,
  isElement: true,
  component: BannerRenderElement,
  withOverrides: (editor: PlateEditor) => {
    const { normalizeNode } = editor;
    editor.normalizeNode = ([node, path]) => {
      if (node.type === ELEMENT_BANNER) {
        if (!node.banner) {
          node.banner = Banner.GREEN;
          return;
        }
      }
      return normalizeNode([node, path]);
    };
    return editor;
  },
});
