import React, { Fragment } from "react";
import styled from "styled-components";
import find from "lodash/find";
import HelpIcon from "@material-ui/icons/Help";
import get from "lodash/get";

import { IconButton, Popover, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

import {
  ConclusionBubble,
  displayNodeIndexInline,
  Level1Bubble,
  Level2Bubble,
  Level3Bubble,
  Level4Bubble,
  Level5Bubble,
  Level6Bubble,
} from "@common";
import { useAttribute } from "@pages/models/release/AttributeContext";
import { useGraphVisualisation } from "@components/GraphVisualisation/hooks/useGraphVisualisation";
import { useFullRelease } from "@common/hooks_useFullRelease";
import { filter } from "lodash";
import { useNotification } from "@common/hooks/HooksNotification";
import { Trans, useTranslation } from "react-i18next";

export const StyledTable = styled(Table)`
  border-radius: 0.5rem 0.5rem 0 0;
  overflow: hidden;

  td {
    border: 1px solid #E5E5E5;
  }
`;

export const StyledTableHead = styled(TableHead)`
  background-color: #0A0A0A;

  .MuiTableCell-head {
    color: #FFFFFF;
  }
`;


const getRelationship = (id, release) => {
  if (!release) return;
  // if (!rel || !model) return '';
  return find(release.relationships, { id });
  // return get(model, `${rel}.description`, rel);
};
const determineNegation = attr => {
  let negate = false;
  if (get(attr, 'value.type') === 'value' && get(attr, 'value.value') === false) negate = true;

  return negate;
};
const AttributeExplanation = ({ attr }) => {
  const { t } = useTranslation();
  if (!attr) return null;
  //        <LevelRender level={0}><Value element={{type: 'attribute', attributeId: attr.id}} /> if</LevelRender>
  if (attr.rows) {
    return (
      <>
        <p>{t('explanation.decison_table_heading')}</p>
        <StyledTable>
          <StyledTableHead>
            <TableRow>
              <TableCell>{t('explanation.decision_table_value_of')}</TableCell>
              <TableCell>{t('explanation.decision_table_conditions_met')}</TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            { attr.rows.map((row, rIndex) => (
              <TableRow key={rIndex}>
                <TableCell>
                  <ElementRender element={get(attr, `value.${ rIndex }`)} />
                </TableCell>
                <TableCell>
                  {row.map((c, index) => <ElementRender key={index} element={c} level={1} />)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </>
    );
  } else if (attr.conditions) {
    let complexValue = null;
    if (attr.value.type !== 'value') {
      // This has a complex value when the conditions are true
      complexValue = (
        <>
          {t('explanation.value_complex')}
          <ElementRender element={attr.value} level={0} extraText='given' />
          {attr.value.elements.map((c, index) => <ElementRender key={index} element={c} level={1} />)}
        </>
      );
    }

    return (
      <>
        <ElementRender negate={determineNegation(attr)} element={{ type: 'attribute', attributeId: attr.id }} level={0} extraText={t('explanation.if_label')} />
        {attr.conditions.map((c, index) => <ElementRender key={index} element={c} level={1} />)}
        { complexValue }
      </>
    );
  } else if (attr.value) {
    return (
      <>
        <ElementRender level={0} element={{ type: 'attribute', attributeId: attr.id }} extraText={t('explanation.determined_by')} />
        { (Array.isArray(attr.value) ? attr.value : [attr.value]).map((val, index) => <ElementRender key={index} element={val} level={1} />)}
      </>
    );
  } else if (attr.identifier) {
    return (
      <span>
        {t('explanation.identifier_description', {entity: attr.entity})}
      </span>
    );
  } else if (attr.fk) {
    return <ForeignKeyRender element={attr} />;
  }

  return <span><Trans i18nKey="explanation.input_node"></Trans></span>;

};

export const AttrLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const MultiAttrLink = ({children, onClick, options, graph}) => {
  const notify = useNotification();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  }
  const handleOnClick = (event) => {
    if (options.length === 1) onClick(options[0]);
    else if (options.length === 0) notify("Can't determine which instance of this attribute to navigate to");
    else setAnchorEl(event.currentTarget);
  }
  return (
    <>
      <AttrLink onClick={handleOnClick}>{children}</AttrLink>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{ padding: 10}}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ padding: 10}}>
          {t('explanation.which_instance')}
          <ul>
            {options.map(o => (
              <li><AttrLink onClick={() => onClick && onClick(o)}>{displayNodeIndexInline(o, graph)}</AttrLink></li>
            ))}
          </ul>
        </div>
      </Popover>
    </>
  )
}
const ForeignKeyRender = ({ element }) => {
  const { t } = useTranslation();
  const result = t('explanation.foreign_key_reference');
  let initial, condition;
  if (get(element, 'inferred.initial')) {
    initial = (
      <>
        {t('explanation.value_set')}
        <ElementRender element={element.inferred.initial} />
        .
      </>
    );
  }
  if (get(element, 'inferred.conditions')) {
    condition = (
      <>
        {t('explanation.but_only_if_true')}
        {element.inferred.conditions.map((c, index) => <ElementRender key={index} element={c} level={1} />)}
      </>
    );
  }

  return (
    <>
      {result}
      {' '}
      {initial}
      {' '}
      {condition}
      {' '}
    </>
  );
};
const ElementRender = ({ element, level, extraText, negate }) => {
  let child;
  const { graph, debug, selectedNode } = useGraphVisualisation();
  const { t } = useTranslation();
  const setAttribute = useAttribute();
  if (!element || !graph) return null;

  switch (element.type) {
    case 'value':
      if (element.special === 'CurrentTime') child = <span>{t('explanation.current_time')}</span>;
      else if (element.special === 'CurrentDate') child = <span>{t('explanation.current_date')}</span>;
      else if (element.special === 'CurrentLocale') child = <span>{t('explanation.current_locale')}</span>;
      else if (element.special === 'BeginningOfTime') child = <span>{t('explanation.beginning_of_time')}</span>;
      else if (element.special === 'EndOfTime') child = <span>{t('explanation.end_of_time')}</span>;
      else child = <span>{element.value === null ? t('explanation.uncertain') : element.value?.toString()}</span>;

      break;
    case 'value-at':
      if (!get(element, 'elements.0') || !get(element, 'elements.1')) return t('explanation.invalid_rule');

      child = (
        <>
          {t('explanation.value_of')}
          <ElementRender element={element.elements[ 1 ]} />
          {' '}
          {t('explanation.at')}
          <ElementRender element={element.elements[ 0 ]} />
        </>
      );
      break;
    case 'attribute':
      // Find in graph
      //const node = (find(get(graph, 'nodes'), { v: element.attributeId }) || {}).value;
      const node = graph.node(element.attributeId)
      const changeAttr = e => {
        if (setAttribute) setAttribute(node);
      };
      if (!node) return null;
      if (debug && node.hidden) {
        let options = filter(graph._nodes, (n) => {
          if (selectedNode) {
            // Check if we are in a node that matches the selected node entity - this means that we should only show nodes that are in the same index/context
            if (n.id === node.id && selectedNode.entity == node.entity && n.entity === node.entity && selectedNode.index === n.index) {
              return true;
            }
            // Otherwise if we are on a different entity, then show all possible instances as normal
            else if (selectedNode.entity !== node.entity && n.id === node.id && !n.hidden) {
              return true;
            }
            // Otherwise this is not a match
            return false;
          } else if (n.id === node.id && !n.hidden) return true;
          else return false;
        });
        child = <MultiAttrLink options={options} graph={graph} onClick={setAttribute}>{node.description}</MultiAttrLink>;
      } else {
        child = <AttrLink onClick={changeAttr}>{node.description}</AttrLink>;
      }
      break;
    case 'get-data':

      child = <span>
        <span>{t('explanation.get_data_column')} </span>
      <ElementRender element={element.elements[1]}/>
        <span> {t('explanation.get_data_from_table')} </span>
      <ElementRender element={element.elements[0]}/>
        <span> {t('explanation.get_data_with_value')} </span>
      <ElementRender element={element.elements[2]}/>
      </span>;
      break;
    case 'known':
      if (!get(element, 'elements.0')) return t('explanation.invalid_rule');

      child = (
        <>
          <ElementRender element={element.elements[ 0 ]} />
          {' '}
          {t('explanation.is_known')}
        </>
      );
      break;
    case 'add-weeks':
    case 'add-hours':
    case 'add-months':
    case 'add-years':
    case 'add-days':
    case 'year-difference':
    case 'month-difference':
    case 'day-difference':
    case 'hour-difference':
    case 'minute-difference':
    case 'day-difference-inclusive':
    case 'concatenate-datetime':
    case 'extract-date':
    case 'extract-date-time-of-day':
    case 'next-day-of-week':
      child = <DateCalculation element={element} />;
      break;
    case 'greater-than':
    case 'greater-than-equals':
    case 'less-than':
    case 'less-than-equals':
    case 'multiply':
    case 'xy':
    case 'equals':
    case 'not-equals':
    case 'divide':
    case 'plus':
    case 'minus':
    case 'modulo':
    case 'trunc':
    case 'round':
      child = <MathCondition element={element} level={level} />;
      break;
    case 'abs':
      child = <MathCondition element={element} level={level} />;
      break;
    case 'empty':
      child = <span>{t('explanation.otherwise')}</span>;
      break;
    case 'sub-branch':
      return (
        <>
          <LevelRender level={level}>{element.operator === 'and' ? t('explanation.all') : t('explanation.any')}</LevelRender>
          {(element.elements || []).map((e, index) => <ElementRender key={index} element={e} level={level + 1} />)}
        </>
      );
      break;
    case 'and':
    case 'or':
      return (
        <>
          <LevelRender level={level}>{element.type === 'and' ? t('explanation.all') : t('explanation.any')}</LevelRender>
          {(element.elements || []).map((e, index) => <ElementRender key={index} element={e} level={level + 1} />)}
        </>
      );
    case 'not':
      if (!get(element, 'elements.0')) return null;

      child = (
        <>
          <span>({t('explanation.not')})</span>
          {' '}
          <ElementRender element={element.elements[ 0 ]} />
        </>
      );
      break;
    case 'negate':
      if (!get(element, 'elements.0')) return null;

      child = (
        <>
          (
          <span>-</span>
          <ElementRender element={element.elements[ 0 ]} />
          )
        </>
      );
      break;
    case 'maximum':
    case 'minimum':
      child = <Comparison element={element} />;
      break;
    case 'for':
    case 'instance-count-if':
    case 'instance-count':
    case 'instance-maximum':
    case 'instance-minimum':
    case 'instance-maximum-if':
    case 'instance-minimum-if':
    case 'instance-sum':
    case 'instance-sum-if':
    case 'instance-value-if':
      child = <Collection element={element} />;
      break;
    case 'temporal-before':
    case 'temporal-on-or-before':
    case 'temporal-years-since':
    case 'temporal-months-since':
    case 'temporal-weeks-since':
    case 'temporal-days-since':
    case 'temporal-from-range':
    case 'temporal-on-or-after':
    case 'temporal-after':
    case 'temporal-from-start-date':
    case 'temporal-from-end-date':
    case 'temporal-always-days':
    case 'temporal-on':
    case 'temporal-day-of-the-week':
    case 'temporal-interval-on-day-of-the-week':
    case 'temporal-interval-on-day-cycle':
      child = <Temporal element={element} />;
      break;
    case 'when-next':
      child = <WhenNext element={element} level={level} />;
      break;
    case 'interval-always':
    case 'interval-daily-sum':
    case 'interval-daily-sum-if':
    case 'interval-consecutive-days':
    case 'interval-at-least-days':
    case 'interval-sometimes':
    case 'interval-count-if':
      child = <Interval type={element.type} element={element} level={level} />;
      break;
    case 'branch':
      // Special case for our branch
      child = (
        <BranchTag branch={element}>
          {element.collector === 'and' ? t('explanation.all') : t('explanation.any')}
          {' '}
          {t('explanation.branch')}
          {element.ref}
        </BranchTag>
      );
      break;
    case 'exists':
    case 'for-all':
      child = <Exists element={element} level={level} type={element.type} />;
      break;
    case 'certain':
    case 'uncertain':
    case 'unknown':
      child = <Certain element={element} level={level} />;
      break;
    case 'upper':
    case 'lower':
    case 'contains':
    case 'starts-with':
    case 'ends-with':
      child = <StringManipulation element={element} level={level} />;
      break;
    default:
      child = t('explanation.missing_rule', { type: element.type});
      console.log('unknown element', element);
  }

  if (typeof level !== 'undefined') {
    return (
      <LevelRender level={level}>
        {negate ? t('explanation.not') : ''}
        {' '}
        {child}
        {' '}
        {extraText}
      </LevelRender>
    );
  }

  return (
    <>
      {negate ? t('explanation.not') : ''}
      {' '}
      {child}
      {' '}
      {extraText}
    </>
  );
};
export { ElementRender };

const LevelRender = ({ level, children }) => {
  switch (level) {
    case 0:
      return <ConclusionBubble>{children}</ConclusionBubble>;
    case 1:
      return <Level1Bubble>{children}</Level1Bubble>;
    case 2:
      return <Level2Bubble>{children}</Level2Bubble>;
    case 3:
      return <Level3Bubble>{children}</Level3Bubble>;
    case 4:
      return <Level4Bubble>{children}</Level4Bubble>;
    case 5:
      return <Level5Bubble>{children}</Level5Bubble>;
    case 6:
      return <Level6Bubble>{children}</Level6Bubble>;
    default:
      return <div>{children}</div>;
  }
};

const BranchTag = ({ branch, children }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <span>{children}</span>
      <IconButton onClick={handleClick} variant='contained'><HelpIcon /></IconButton>
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ElementRender level={0} element={branch.element} />
      </Popover>

    </>
  );
};

///
/// Specific element renders
///
const DateCalculation = ({ element }) => {
  const { t } = useTranslation();
  if (!element) return t('explanation.invalid_rule');

  let op;
  switch (element.type) {
    case 'add-hours':
      if (!op) op = t('explanation.hours');
    case 'add-days':
      if (!op) op = t('explanation.days');
    case 'add-weeks':
      if (!op) op = t('explanation.weeks');
    case 'add-months':
      if (!op) op = t('explanation.months');
    case 'add-years':
      if (!op) op = t('explanation.years');
      if (!get(element, 'elements.0') || !get(element, 'elements.1')) return t('explanation.invalid_rule');

      return (
        <>
          <ElementRender element={element.elements[ 0 ]} />
          {t('explanation.plus')}
          <ElementRender element={element.elements[ 1 ]} />
          {op}
        </>
      );
    case 'hour-difference':
      if (!op) op = t('explanation.hours');
    case 'minute-difference':
      if (!op) op = t('explanation.minutes');
    case 'day-difference':
      if (!op) op = t('explanation.days');
    case 'day-difference-inclusive':
      if (!op) op = t('explanation.days_inclusive');
    case 'month-difference':
      if (!op) op = t('explanation.months');
    case 'year-difference':
      if (!op) op = t('explanation.years');
      if (!get(element, 'elements.0') || !get(element, 'elements.1')) return t('explanation.invalid_rule');

      return (
        <>
          {t('explanation.difference_in')}
          {' '}
          {op}
          {' '}
          {t('explanation.between')} &nbsp;
          <ElementRender element={element.elements[ 0 ]} />
          {t('explanation.and')}&nbsp;
          <ElementRender element={element.elements[ 1 ]} />
        </>
      );
    case 'concatenate-datetime':
      if (!get(element, 'elements.0') || !get(element, 'elements.1')) return t('explanation.invalid_rule');

      return (
        <>
          {t('explanation.combined_date_time')} &nbsp;
          <ElementRender element={element.elements[ 0 ]} />
          {t('explanation.and')}&nbsp;
          <ElementRender element={element.elements[ 1 ]} />
        </>
      );
    case 'extract-date':
      if (!get(element, 'elements.0')) return t('explanation.invalid_rule');

      return (
        <>
          {t('explanation.date_component_of')} &nbsp;
          <ElementRender element={element.elements[ 0 ]} />
        </>
      );
    case 'extract-time-of-day':
      if (!get(element, 'elements.0')) return t('explanation.invalid_rule');

      return (
        <>
          {t('explanation.time_component_of')} &nbsp;
          <ElementRender element={element.elements[ 0 ]} />
        </>
      );
    case 'next-day-of-week':
      if (!get(element, 'elements.0') || !get(element, 'elements.1')) return t('explanation.invalid_rule');

      return (
        <>
          {t('explanation.the_next')} &nbsp;
          <ElementRender element={element.elements[ 1 ]} />
          {t('explanation.on_or_after')}&nbsp;
          <ElementRender element={element.elements[ 0 ]} />
        </>
      );
    default:
      console.log('unknown date calculation', element);
      return t('explanation.unknown_rule');
  }
};

const AbsCondition = ({ element, level}) => {
  const { t } = useTranslation();

  return (
    <>
      {t('explanation.absolute_value')}
      <ElementRender element={element.elements[ 0 ]} />
    </>
  );
}

const MathCondition = ({ element, level }) => {
  const { t } = useTranslation();
  if (!get(element, 'elements.0') || !get(element, 'elements.1')) return t('explanation.invalid_rule');
  if (element.type === 'modulo') { // TODO: make easier to read
    return (
      <>
        <ElementRender element={element.elements[ 0 ]} />
        {t('explanation.modulo')}
        <ElementRender element={element.elements[ 1 ]} />
      </>
    );
  }
  if (element.type === 'trunc') {
    return (
      <>
        <ElementRender element={element.elements[ 0 ]} />
        {t('explanation.truncated_to')}
        <ElementRender element={element.elements[ 1 ]} />
        {t('explanation.decimal_places')}
      </>
    );
  }
  if (element.type === 'round') {
    return (
      <>
        <ElementRender element={element.elements[ 0 ]} />
        {t('explanation.rounded_to')}
        <ElementRender element={element.elements[ 1 ]} />
        {t('explanation.decimal_places')}
      </>
    );
  }
  let leftBrace, rightBrace = false;
  if (element.elements[0].elements && element.elements[0].elements.length > 0) leftBrace = true;
  if (element.elements[1].elements && element.elements[1].elements.length > 0) rightBrace = true;

  const op_map = { 'xy': t('explanation.power_of'), 'greater-than': '>', 'greater-than-equals': '>=', 'less-than': '<', 'less-than-equals': '<=', equals: '=', 'not-equals': t('explanation.does_not_equal'), multiply: 'x', divide: '/', plus: '+', minus: '-' };
  return (
    <>
      { leftBrace && '(' }
      <ElementRender element={element.elements[ 0 ]} />
      { leftBrace && ')' }
        &nbsp;
      {get(op_map, element.type, '?')}
      {' '}
&nbsp;
      { rightBrace && '(' }
      <ElementRender element={element.elements[ 1 ]} />
      { rightBrace && ')' }
    </>
  );

};

const Temporal = ({ element }) => {
  const release = useFullRelease();
  const { t } = useTranslation();
  let rel;
  switch (element.type) {
    case 'temporal-on-or-after':
      if (!get(element, 'elements.0')) return t('explanation.invalid_rule');

      return (
        <>
          on or after
          <ElementRender element={element.elements[ 0 ]} />
        </>
      );
    case 'temporal-after':
      if (!get(element, 'elements.0')) return t('explanation.invalid_rule');

      return (
        <>
          after
          <ElementRender element={element.elements[ 0 ]} />
        </>
      );
    case 'temporal-before':
      if (!get(element, 'elements.0')) return t('explanation.invalid_rule');

      return (
        <>
          before
          <ElementRender element={element.elements[ 0 ]} />
        </>
      );
    case 'temporal-on-or-before':
      if (!get(element, 'elements.0')) return t('explanation.invalid_rule');

      return (
        <>
          on or before
          <ElementRender element={element.elements[ 0 ]} />
        </>
      );
    case 'temporal-years-since':
      if (!get(element, 'elements.0') || !get(element, 'elements.1')) return t('explanation.invalid_rule');

      return (
        <>
          the temporal range of years between
          <ElementRender element={element.elements[ 0 ]} />
          {' '}
          and
          <ElementRender element={element.elements[ 1 ]} />
        </>
      );
    case 'temporal-months-since':
      if (!get(element, 'elements.0') || !get(element, 'elements.1')) return t('explanation.invalid_rule');

      return (
        <>
          the temporal range of months between
          <ElementRender element={element.elements[ 0 ]} />
          {' '}
          and
          <ElementRender element={element.elements[ 1 ]} />
        </>
      );
    case 'temporal-weeks-since':
      if (!get(element, 'elements.0') || !get(element, 'elements.1')) return t('explanation.invalid_rule');

      return (
        <>
          the temporal range of weeks (7 day periods) between
          <ElementRender element={element.elements[ 0 ]} />
          {' '}
          and
          <ElementRender element={element.elements[ 1 ]} />
        </>
      );
    case 'temporal-days-since':
      if (!get(element, 'elements.0') || !get(element, 'elements.1')) return t('explanation.invalid_rule');

      return (
        <>
          the temporal range of days between
          <ElementRender element={element.elements[ 0 ]} />
          {' '}
          and
          <ElementRender element={element.elements[ 1 ]} />
        </>
      );
    case 'temporal-from-range':
      if (!get(element, 'elements.0') || !get(element, 'elements.1') || !get(element, 'elements.2') || !get(element, 'relationshipId')) return t('explanation.invalid_rule');

      rel = getRelationship(element.relationshipId, release);
      if (!rel) return 'Invalid Rule (Relationship no longer exists - you may need to re-save documents)';

      return (
        <>
          the value  will be temporally defined as the range between
          <ElementRender element={element.elements[ 0 ]} />
          {' '}
          and
          <ElementRender element={element.elements[ 1 ]} />
          {' '}
          with the value of
          <ElementRender element={element.elements[ 2 ]} />
          {' '}
          for
          {rel.text}
        </>
      );
    case 'temporal-from-start-date':
      if (!get(element, 'elements.0') || !get(element, 'elements.1') || !get(element, 'relationshipId')) return t('explanation.invalid_rule');

      rel = getRelationship(element.relationshipId, release);
      if (!rel) return 'Invalid Rule (Relationship no longer exists - you may need to re-save documents)';

      return (
        <>
          the value  will be temporally defined as a series of ranges using
          <ElementRender element={element.elements[ 0 ]} />
          {' '}
          as the start date and
          <ElementRender element={element.elements[ 1 ]} />
          {' '}
          as the value until the next start date for&nbsp;
          {rel.text}
        </>
      );
    case 'temporal-from-end-date':
      if (!get(element, 'elements.0') || !get(element, 'elements.1') || !get(element, 'relationshipId')) return t('explanation.invalid_rule');

      rel = getRelationship(element.relationshipId, release);
      if (!rel) return 'Invalid Rule (Relationship no longer exists - you may need to re-save documents)';

      return (
        <>
          the value  will be temporally defined as a series of ranges using
          <ElementRender element={element.elements[ 0 ]} />
          {' '}
          as the end date and
          <ElementRender element={element.elements[ 1 ]} />
          {' '}
          as the value until the next end date for&nbsp;
          {rel.text}
        </>
      );
    case 'temporal-always-days':
      if (!get(element, 'elements.0') || !get(element, 'elements.1')) return t('explanation.invalid_rule');

      return (
        <>
          for each day, determine if for all of the previous
          <ElementRender element={element.elements[ 0 ]} />
          {' '}
          days the
          <ElementRender element={element.elements[ 1 ]} />
        </>
      );
    case 'temporal-week-day':
      if (!get(element, 'elements.0') || !get(element, 'elements.1')) return t('explanation.invalid_rule');

      return (
        <>
          the temporal range of the week days between
          <ElementRender element={element.elements[ 0 ]} />
          {' '}
          and
          <ElementRender element={element.elements[ 1 ]} />
        </>
      );
    case 'temporal-day-of-the-week':
      if (!get(element, 'elements.0') || !get(element, 'elements.1') || !get(element, 'elements.2')) return t('explanation.invalid_rule');

      return (
        <>
          every
          <ElementRender element={element.elements[ 0 ]} />
          {' '}
          between
          <ElementRender element={element.elements[ 1 ]} />
          {' '}
          and
          <ElementRender element={element.elements[ 2 ]} />

        </>
      );
    case 'temporal-interval-on-day-of-the-week':
      if (!get(element, 'elements.0') || !get(element, 'elements.1') || !get(element, 'elements.2') || !get(element, 'elements.3') || !get(element, 'elements.4')) return t('explanation.invalid_rule');

      return (
        <>
          between the times
          <ElementRender element={element.elements[ 0 ]} />
          {' '}
          and
          <ElementRender element={element.elements[ 1 ]} />
          every
          <ElementRender element={element.elements[ 2 ]} />
          {' '}
          bounded by
          <ElementRender element={element.elements[ 3 ]} />
          {' '}
          and
          <ElementRender element={element.elements[ 4 ]} />

        </>
      );
    case 'temporal-interval-on-day-cycle':
      if (!get(element, 'elements.0') || !get(element, 'elements.1') || !get(element, 'elements.2') || !get(element, 'elements.3') || !get(element, 'elements.4')) return t('explanation.invalid_rule');

      return (
        <>
          a temporal value between the times
          <ElementRender element={element.elements[ 0 ]} />
          {' '}
          and
          <ElementRender element={element.elements[ 1 ]} />
          starting
          <ElementRender element={element.elements[ 2 ]} />
          {' '}
          and then every
          <ElementRender element={element.elements[ 4 ]} />
          days until
          <ElementRender element={element.elements[ 3 ]} />

        </>
      );
    case 'temporal-on':
      if (!get(element, 'elements.0')) return t('explanation.invalid_rule');

      return (
        <>
          on the day of
          <ElementRender element={element.elements[ 0 ]} />
       </>
      );
    default:
      return t('explanation.invalid_rule');
  }
};

const Interval = ({ element, type, level }) => {
  const { t } = useTranslation();

  if (type === 'interval-always') {
    if (!get(element, 'elements.0') || !get(element, 'elements.1') || !get(element, 'elements.2')) return t('explanation.invalid_rule');

    return (
      <>
        between
        {' '}
        <ElementRender element={element.elements[ 0 ]} />
        {' '}
        and
        {' '}
        <ElementRender element={element.elements[ 1 ]} />
        {' '}
        <ElementRender element={element.elements[ 2 ]} />
        {' '}
        was always true
      </>
    );
  }
  if (type === 'interval-consecutive-days') {
    if (!get(element, 'elements.0') || !get(element, 'elements.1') || !get(element, 'elements.2') || !get(element, 'elements.3')) return t('explanation.invalid_rule');

    return (
      <>
        between
        {' '}
        <ElementRender element={element.elements[ 0 ]} />
        {' '}
        and
        {' '}
        <ElementRender element={element.elements[ 1 ]} />
        {' '}
        there is a period of
        {' '}
        <ElementRender element={element.elements[ 2 ]} />
        {' '}
        consecutive days where
        {' '}
        <ElementRender element={element.elements[ 3 ]} />
        {' '}
        is true
      </>
    );
  }
  if (type === 'interval-at-least-days') {
    if (!get(element, 'elements.0') || !get(element, 'elements.1') || !get(element, 'elements.2') || !get(element, 'elements.3')) return t('explanation.invalid_rule');

    return (
      <>
        between
        {' '}
        <ElementRender element={element.elements[ 0 ]} />
        {' '}
        and
        {' '}
        <ElementRender element={element.elements[ 1 ]} />
        {' '}
        there is atleast
        {' '}
        <ElementRender element={element.elements[ 2 ]} />
        {' '}
        days where
        {' '}
        <ElementRender element={element.elements[ 3 ]} />
        {' '}
        is true
      </>
    );
  }
  if (type === 'interval-daily-sum') {
    // if (!get(element, 'elements.0') || !get(element, 'elements.1') || !get(element, 'elements.2') || !get(element, 'elements.3')) return t('explanation.invalid_rule');
    return (
      <>
        between
        {' '}
        <ElementRender element={element.elements[ 0 ]} />
        {' '}
        and
        {' '}
        <ElementRender element={element.elements[ 1 ]} />
        {' '}
        return the
        {' '}
        <ElementRender element={element.elements[ 2 ]} />
        {' '}
        totalled by day
      </>
    );
  }
  if (type === 'interval-count-if') {
    // if (!get(element, 'elements.0') || !get(element, 'elements.1') || !get(element, 'elements.2') || !get(element, 'elements.3')) return t('explanation.invalid_rule');
    return (
      <>
        within
        {' '}
        <ElementRender element={element.elements[ 3 ]} />
        {' '}
        the number of
        {' '}
        <ElementRender element={element.elements[ 2 ]} />
        {' '}
        that is true between
        {' '}
        <ElementRender element={element.elements[ 1 ]} />
        {' '}
        and
        <ElementRender element={element.elements[ 0 ]} />
      </>
    );
  }
  if (type === 'interval-daily-sum-if ') {
    // if (!get(element, 'elements.0') || !get(element, 'elements.1') || !get(element, 'elements.2') || !get(element, 'elements.3')) return t('explanation.invalid_rule');
    return (
      <>
        between
        {' '}
        <ElementRender element={element.elements[ 0 ]} />
        {' '}
        and
        {' '}
        <ElementRender element={element.elements[ 1 ]} />
        {' '}
        return the
        {' '}
        <ElementRender element={element.elements[ 2 ]} />
        {' '}
        totalled by day where
        {' '}
        <ElementRender element={element.elements[ 3 ]} />
      </>
    );
  }
  if (type === 'interval-sometimes') {
    // if (!get(element, 'elements.0') || !get(element, 'elements.1') || !get(element, 'elements.2') || !get(element, 'elements.3')) return t('explanation.invalid_rule');
    return (
      <>
        between
        {' '}
        <ElementRender element={element.elements[ 0 ]} />
        {' '}
        and
        {' '}
        <ElementRender element={element.elements[ 1 ]} />
        {' '}
        there is atleast sometime where
        {' '}
        <ElementRender element={element.elements[ 2 ]} />
        {' '}
        is true
      </>
    );
  }

  return null;
};

const Exists = ({ element, type, level }) => {
  const release = useFullRelease();
  const { t } = useTranslation();

  if (!get(element, 'elements.0') || !get(element, 'relationshipId')) return t('explanation.invalid_rule');

  const rel = getRelationship(element.relationshipId, release);
  if (!rel) return 'Invalid Rule (Relationship no longer exists - you may need to re-save documents)';

  return (
    <>
      {type === 'for-all' ? 'for' : 'within atleast one of' }
      {' '}
      {rel.text}
      {' '}
      <ElementRender element={element.elements[ 0 ]} />
    </>
  );
};

const WhenNext = ({ element, level }) => {
  const { t } = useTranslation();

  return (
    <>
      from
      {' '}
      <ElementRender element={element.elements[ 0 ]} />
      {' '}
      when is the next day when
      {' '}
      <ElementRender element={element.elements[ 1 ]} />
      {' '}
      is true
    </>
  );
};

const Certain = ({ element, type, level }) => {
  const { t } = useTranslation();

  // let rel = getRelationship(element.relationshipId, release);
  if (element.type === 'uncertain') {
    if (!element.elements || element.elements.length === 0) return "it's uncertain";

    return (
      <>
        it's uncertain
        {' '}
        <ElementRender element={element.elements[ 0 ]} />
      </>
    );
  } else if (element.type === 'unknown') {
    if (!element.elements || element.elements.length === 0) return "it's unknown";

    return (
      <>
        <ElementRender element={element.elements[ 0 ]} />
        {' '}
        is unknown
      </>
    );
  }
  if (!element.elements || element.elements.length === 0) return "it's certain";

  return (
    <>
      it's certain
      {' '}
      <ElementRender element={element.elements[ 0 ]} />
    </>
  );

};
const Comparison = ({ element }) => {
  const { t } = useTranslation();

  switch (element.type) {
    case 'maximum':
      if (!get(element, 'elements.0') || !get(element, 'elements.1')) return t('explanation.invalid_rule');

      return (
        <>
          the maximum of
          <ElementRender element={element.elements[ 0 ]} />
          {' '}
          and
          <ElementRender element={element.elements[ 1 ]} />
        </>
      );
    case 'minimum':
      if (!get(element, 'elements.0') || !get(element, 'elements.1')) return t('explanation.invalid_rule');

      return (
        <>
          the minimum of
          <ElementRender element={element.elements[ 0 ]} />
          {' '}
          and
          <ElementRender element={element.elements[ 1 ]} />
        </>
      );
    default:
      return t('explanation.invalid_rule');
  }
};


const StringManipulation = ({ element }) => {
  const { t } = useTranslation();

  switch (element.type) {
    case 'upper':
      if (!get(element, 'elements.0')) return t('explanation.invalid_rule');

      return (
        <>
          the upper case value of
          <ElementRender element={element.elements[ 0 ]} />
        </>
      );
    case 'lower':
      if (!get(element, 'elements.0')) return t('explanation.invalid_rule');

      return (
        <>
          the lower case value of
          <ElementRender element={element.elements[ 0 ]} />
        </>
      );
    case 'contains':
      if (!get(element, 'elements.0') && !get(element, 'elements.1')) return t('explanation.invalid_rule');

      return (
        <>
          true if
          <ElementRender element={element.elements[ 0 ]} />
          &nbsp;contains
          <ElementRender element={element.elements[ 1 ]} />
        </>
      );
    case 'starts-with':
      if (!get(element, 'elements.0') && !get(element, 'elements.1')) return t('explanation.invalid_rule');

      return (
        <>
          true if
          <ElementRender element={element.elements[ 0 ]} />
          &nbsp;starts with
          <ElementRender element={element.elements[ 1 ]} />
        </>
      );
    case 'ends-with':
      if (!get(element, 'elements.0') && !get(element, 'elements.1')) return t('explanation.invalid_rule');

      return (
        <>
          true if
          <ElementRender element={element.elements[ 0 ]} />
          &nbsp;ends with
          <ElementRender element={element.elements[ 1 ]} />
        </>
      );
    default:
      return t('explanation.invalid_rule');
  }
};
const Collection = ({ element }) => {
  const { t } = useTranslation();
  const release = useFullRelease();
  let rel;
  switch (element.type) {
    case 'for':
      if (get(element, 'relationshipId')) {
        const rel = find(release.relationships, { id: element.relationshipId });
        if (!rel) return 'Invalid Rule (Relationship no longer exists - you may need to re-save documents)';

        let rel_text;
        if (element.relationshipReverse) {
          rel_text = rel.reverseText ? rel.reverseText : `the reverse relationship of ${ rel.textSingular }`;
        } else rel_text = rel.textSingular;

        return (
          <>
            {' '}
            in the scope of this instances'
            {' '}
            <em>{rel_text}</em>
            {' '}
            <ElementRender element={{ type: 'attribute', attributeId: element.attributeId }} />
          </>
        );
      }
      if (get(element, 'attributeId')) {
        return (
          <>
            {' '}
            <ElementRender element={{ type: 'attribute', attributeId: element.attributeId }} />
            {element.negate === true ? 'is not true' : ''}
          </>
        );
      }

      return t('explanation.invalid_rule');
    case 'instance-count':
      rel = getRelationship(element.relationshipId, release);
      return (
        <>
          the count of instances of
          {' '}
          {get(rel, 'text', '')}
        </>
      );
      break;
    case 'instance-count-if':
      rel = getRelationship(element.relationshipId, release);
      return (
        <>
          the count of instances of
          {get(rel, 'text', '')}
          {' '}
          where
          <ElementRender element={element.elements[ 0 ]} />
          {' '}
          is true
        </>
      );
      break;
    case 'instance-maximum':
      rel = getRelationship(element.relationshipId, release);
      return (
        <>
          the maximum of
          <ElementRender element={element.elements[ 0 ]} />
          {' '}
          for
          {get(rel, 'text', '')}
        </>
      );
      break;
    case 'instance-minimum':
      rel = getRelationship(element.relationshipId, release);
      return (
        <>
          the minimum of
          <ElementRender element={element.elements[ 0 ]} />
          {' '}
          for
          {get(rel, 'text', '')}
        </>
      );
      break;
    case 'instance-maximum-if':
      rel = getRelationship(element.relationshipId, release);
      return (
        <>
          the maximum of
          <ElementRender element={element.elements[ 0 ]} />
          {' '}
          for
          {get(rel, 'text', '')}
          {' '}
          where
          <ElementRender element={element.elements[ 1 ]} />
        </>
      );
      break;
    case 'instance-minimum-if':
      // let rel = getRelationship(element.relationshipId, release);
      rel = getRelationship(element.relationshipId, release);
      return (
        <>
          the minimum of
          <ElementRender element={element.elements[ 0 ]} />
          {' '}
          for
          {get(rel, 'text', '')}
          {' '}
          where
          <ElementRender element={element.elements[ 1 ]} />
        </>
      );
      break;
    case 'instance-sum':
      rel = getRelationship(element.relationshipId, release);
      return (
        <>
          the sum of
          <ElementRender element={element.elements[ 0 ]} />
          {' '}
          for
          {' '}
          {get(rel, 'text', '')}
        </>
      );
      break;
    case 'instance-sum-if':
      rel = getRelationship(element.relationshipId, release);
      return (
        <>
          the sum of
          <ElementRender element={element.elements[ 0 ]} />
          {' '}
          for
          {get(rel, 'text', '')}
          {' '}
          where
          <ElementRender element={element.elements[ 1 ]} />
        </>
      );
      break;
    case 'instance-value-if':
      rel = getRelationship(element.relationshipId, release);
      return (
        <>
          the value of
          <ElementRender element={element.elements[ 0 ]} />
          {' '}
          for&nbsp;
          {get(rel, 'text', '')}
          {' '}
          where
          <ElementRender element={element.elements[ 1 ]} />
          ,
          unless multiple results are valid in which case Uncertain
        </>
      );
      break;
    default:
      return t('explanation.invalid_rule');
  }
};


export default AttributeExplanation;
