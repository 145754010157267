import React from 'react';
// import Dialog from '@material-ui/core/Dialog';
// import Box from '@material-ui/core/Box';
// import { PrimaryButton } from '@components/buttons';
import { CompNS } from '@components/TestCasesTree';
// import { rootTestCaseTItemId, type TestCaseTItemId } from '@components/TestCasesTree/types'
// import { toSortableTree } from '@components/TestCasesTree/utils';
// import { FolderIcon } from '@icons';
import styled from 'styled-components';


//# region Debugger emulation

const DebuggerEmulationWrap = styled('div')`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 1rem;
  width: 95vw;
  height: 700px;
`;
const Left = styled( 'div' )`
  width: 600px;
  border-right: 1px solid black;
  padding: 0.5rem;
`;
const Right = styled( 'div' )`

`;

const DebuggerEmulation = React.memo(() => {
  return (
    <DebuggerEmulationWrap>
      <Left>
        <CompNS.PathNS.ConnectedPath />
        {/* <CompV2.PathNS.Path
          items={[
            { id: 'root', node: <FolderIcon /> },
            { id: '1', node: <CompV2.PathNS.SimpleText text='…' /> },
            { id: '2', node: <CompV2.PathNS.SimpleText text='Test case sub-folder 1' /> },
            {
              id: '3',
              node: <CompV2.PathNS.PathChip
                id='3'
                text='Test case 1'
                variant={ { type: 'withDelete', onDelete: console.info } }
              />
            },
            {
              id: '4',
              node: <CompV2.PathNS.PathChip
                id='4'
                text='Create test case'
                variant={ { type: 'withAdd', onAdd: console.info } }
              />
            },
          ]}
        /> */}
      </Left>

      <Right>
        right
      </Right>
    </DebuggerEmulationWrap>
  )
});
DebuggerEmulation.displayName = 'DesignSystem/TestCasesTree/DebuggerEmulation';

//# endregion

export const JSX = React.createElement(() => (
  <DebuggerEmulation />
));
