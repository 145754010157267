import styled from "styled-components";
import { Switch as SwitchRouter, Route } from "react-router-dom";

import { Header, Sidebar } from "@common";
import * as Scrollbar from "@common/scrollbar";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import * as TempTimeline from "@components/TemporalValue/TempTimeline";
import * as UnifiedTimeline from "../pages/decisions/DecisionDashboard/Reports/Details/UnifiedTimeline";
// necessary to setup chartjs plugins
//import "./chartjsSetup";
import { AsyncRoute } from "./AsyncRoute";
import { CtxMenuRootComp } from "../redux/contextMenu/Comp";
import { DocumentEditors } from "services";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  // subtract header height
  height: calc(100% - 3.5rem);
  background-color: ${ props => props.theme.sidebar.background };
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-top-left-radius: 1.5rem;
  background-color: ${ props => props.theme.body.background };
`;

export interface DecisilveyLayoutProps {
  page?: React.ReactNode;
  routes?: any[];
  childRoutes?: any[];
}

const DecisivelyLayout = (props: DecisilveyLayoutProps) => {
  // pulling routes as we dont want it in props as we unpack into sidebar
  const { page, routes, childRoutes, ...rest } = props;
  return (
    <Layout>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Scrollbar.Style />

        <TempTimeline.Style />
        <TempTimeline.Title />

        <UnifiedTimeline.Style />

        {/* defined here as its not really required app wide */}
        <DocumentEditors/>

        <Header />
        <Content>
          <Sidebar routes={childRoutes ?? []} {...rest} />
          <AppContent>
            <AsyncRoute>
              {childRoutes ? (
                <SwitchRouter>
                  {childRoutes.map(route => (
                    <Route key={route.path} {...route} />
                  ))}
                </SwitchRouter>
              ) : (
                page
              )}
            </AsyncRoute>
          </AppContent>
        </Content>
      </MuiPickersUtilsProvider>

      <CtxMenuRootComp />
    </Layout>
  );
};

export default DecisivelyLayout;
