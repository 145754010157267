import {
  GraphNodeType,
  type GraphNodeWithType,
  getAttributeIcon,
  getGraphNodeTypeColor,
  useGroupedGraph,
} from "@common/graph";
import { scrollableMixin } from "@common/scrollbar";
import { Flex, Label, Stack } from "@components/meta";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@components/radix";
import {
  BooleanIcon,
  CompareIcon,
  DocumentsIcon,
  ExternallinkIcon,
  GuidIcon,
  LabelIcon,
  SourcecodeIcon,
  TooltipIcon,
} from "@icons";
import { IconButton, Tooltip } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import type { AttributeExpression } from "@packages/compiler/src/Parser";
import { useEditAttributeModal } from "@pages/models/release/DataModel/route/Attributes/AttributeEditModal";
import { AttributeExplanation } from "@pages/models/release/DataModel/route/Attributes/AttributeExplanation";
import { AttributeIDField } from "@pages/models/release/DataModel/route/Attributes/AttributeIDField";
import { AttributeUsage } from "@pages/models/release/DataModel/route/Attributes/AttributeUsage";
import { useGraph } from "@pages/models/release/GraphContext";
import { FloatingVerticalDivider } from "@udecode/plate";
import { capitalize } from "lodash";
import styled from "styled-components";
import { hexToHSL } from "theme";

const TabToolbar = styled(TabsList)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 1rem;
  gap: 0.25rem;
  /* box-shadow: 0px -5px 10px 0px #0000001A; */

  .MuiIconButton-root {
    background-color: ${(p) => p.theme.palette.background.light};
    border: 1px solid ${(p) => p.theme.palette.background.border};
    &:hover {
      background-color: ${(p) => p.theme.palette.background.hover};
    }
    &[data-state="active"] {
      background-color: ${(p) => p.theme.palette.primary.main};
      ::before {
        content: "";
        position: absolute;
        top: calc(-0.5rem - 2px); // offset padding and border
        left: 0;
        width: 100%;
        height: 2px;
        background-color: ${(p) => p.theme.palette.primary.main};
        /* opacity: 0.1; */
      }
    }
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
`;

const TabRoot = styled(Tabs)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const TabContent = styled(TabsContent)`
  flex-direction: column;
  padding: 1rem;
  border-bottom: 1px solid ${(p) => p.theme.palette.background.border};
  outline: none;
  ${scrollableMixin};

  &[data-state="active"] {
    display: flex;
  }
`;

const AttributeContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 0.25rem 0.75rem;
  align-items: center;
  justify-content: flex-start;
  color: ${(p) => p.theme.palette.text.primary};
  border-top: 1px solid ${(p) => p.theme.palette.background.border};

  [data-icon] {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid ${(p) => p.theme.palette.background.default};
    height: 2rem;
    width: 2rem;
    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  .MuiTypography-root {
    flex: 1;
    font-style: italic;
    font-size: 1rem;
  }

  [data-actions] {
    [class*=FloatingVerticalDivider] {
      height: 1rem;
      margin: 0 0.25rem;
      background-color: ${(p) => p.theme.palette.background.contrastText};
    }
    .MuiIconButton-root {
      &:hover {
        background-color: #00000010;
      }
    }
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
`;

const RulesContent = ({ node }: { node: GraphNodeWithType }) => {
  return (
    <Stack style={{ fontSize: "0.75rem" }}>
      <AttributeExplanation node={node} />
    </Stack>
  );
};

const Attribute = ({ node, parent }: { node: GraphNodeWithType | null; parent: string }) => {
  const editAttributeModal = useEditAttributeModal();

  if (!node) {
    const nodeType = parent === "conclusion" ? GraphNodeType.DERIVED : GraphNodeType.INPUT;
    node = { id: "pending", entity: "Pending document save", nodeType, type: "auto" };
  }

  if (parent === "conclusion" && node.nodeType === GraphNodeType.INPUT) {
    // warnings.push("Derived attribute (pending document save; currently an input attribute)");
    // change it to derived
    // TODO the logic for this is technically not correct, as it would only be derived if it has a child
    // a conclusion by itself could just be an input
    node.nodeType = GraphNodeType.DERIVED;
  }

  const color = getGraphNodeTypeColor(node.nodeType);
  const darkColor = hexToHSL(color, 80);

  return (
    <AttributeContainer style={{ backgroundColor: color }}>
      <div
        data-icon
        style={{ backgroundColor: darkColor }}
      >
        {getAttributeIcon(node.type)}
      </div>
      <Typography>{capitalize(node.entity)}</Typography>
      {node.id === "pending" ? null : (
        <Flex data-actions>
          <FloatingVerticalDivider />
          <IconButton onClick={() => editAttributeModal(node.id)}>
            <ExternallinkIcon />
          </IconButton>
        </Flex>
      )}
    </AttributeContainer>
  );
};

type AttributeInfoProps = {
  expression: AttributeExpression;
  parent: string;
};

type TabValue = {
  value: string;
  label: string;
  icon: JSX.Element;
  content: JSX.Element;
};

export const AttributeInfo = ({ expression, parent }: AttributeInfoProps) => {
  const { attributeId } = expression;
  const graph = useGraph();

  const { getNode } = useGroupedGraph(graph);
  const node = getNode(attributeId);

  // render special attribute info
  if (!node)
    return (
      <Attribute
        node={null}
        parent={parent}
      />
    );

  const tabs = [
    {
      value: "docs",
      label: "Usage",
      icon: <DocumentsIcon />,
      content: <AttributeUsage node={node} />,
    },
    {
      value: "rules",
      label: "Rules",
      icon: <CompareIcon />,
      content: <RulesContent node={node} />,
    },
    node.tags
      ? {
          value: "tags",
          label: "Tags",
          icon: <LabelIcon />,
          content: (
            <Flex style={{ gap: "0.5rem", flexWrap: "wrap" }}>
              {node.tags.map((t) => (
                <Label
                  key={t}
                  color="purpleSlate"
                >
                  {t}
                </Label>
              ))}
            </Flex>
          ),
        }
      : null,
    node.explanation
      ? {
          value: "explanation",
          label: "Explanation",
          icon: <TooltipIcon />,
          content: <Typography>{node.explanation}</Typography>,
        }
      : null,
    node.enum
      ? {
          value: "enum",
          label: "Enum",
          icon: <BooleanIcon />,
          content: <Typography>{node.enum}</Typography>,
        }
      : null,
    node.publicId
      ? {
          value: "pubicName",
          label: "Public Name",
          icon: <SourcecodeIcon />,
          content: <Typography>{node.publicId}</Typography>,
        }
      : null,
    // {
    //   value: "enrichment",
    //   label: "Enrichment",
    //   icon: <InnovateIcon />,
    //   content: <Typography>TODO Enrichment</Typography>
    // },
    {
      value: "guid",
      label: "GUID",
      icon: <GuidIcon />,
      content: <AttributeIDField id={node.id} />,
    },
  ].filter((x) => x !== null) as TabValue[];

  console.log(tabs);

  return (
    <>
      <TabRoot defaultValue="docs">
        {tabs.map((tab) => (
          <TabContent
            key={`content-${tab.value}`}
            value={tab.value}
          >
            {tab.content}
          </TabContent>
        ))}
        <TabToolbar>
          {tabs.map((tab) => (
            <TabsTrigger
              asChild
              key={`tab-${tab.value}`}
              value={tab.value}
            >
              <Tooltip
                title={tab.label}
                placement="bottom"
              >
                <IconButton>{tab.icon}</IconButton>
              </Tooltip>
            </TabsTrigger>
          ))}
        </TabToolbar>
      </TabRoot>
      <Attribute
        node={node}
        parent={parent}
      />
    </>
  );
};

/*
const LegacyAttributeInfo = ({ expression, parent }: any) => {
  const { attributeId } = expression;
  const graph = useGraph();
  const editAttributeModal = useEditAttributeModal();
  const { getNode, getGraphNodeType } = useGroupedGraph(graph);
  // need to use useCurrentDocument as the syntax highlighter may not be within the document context
  const document = useCurrentDocument();
  // const attribute = graph.nodes.find((n) => n.v === attributeId)?.value as Attribute || null;
  const attribute = getNode(attributeId);
  const warnings: any = [];
  if (!attribute) {
    return (
      <Stack>
        <Typography>{parent === "conclusion" ? "Derived" : "Input"} attribute (pending document save)</Typography>
      </Stack>
    );
  }
  let type = getGraphNodeType(attributeId);
  const AttributeIcon = (ControlIcons as any)[attribute.type] || ControlIcons.unassigned;
  // const rawExplanation = (attribute.explanation || "none").split(".")[0];
  // const explanation = rawExplanation.length > 100 ? rawExplanation.slice(0, 100) + "..." : rawExplanation;
  if (parent === "conclusion" && type === "input") {
    warnings.push("Derived attribute (pending document save; currently an input attribute)");
    type = GraphNodeType.DERIVED;
  }


  return (
    <Stack>
      <Flex style={{ justifyContent: "space-between", gap: "1rem" }}>
        <Typography style={{ textTransform: "capitalize" }}>{type} attribute</Typography>
        <IconButton onClick={() => editAttributeModal(attributeId)}>
          <OpenInNew />
        </IconButton>
      </Flex>
      {warnings.length > 0 && (
        <>
          {warnings.map((w, i) => (
            <Typography
              variant="caption"
              key={i}
            >
              {w}
            </Typography>
          ))}
        </>
      )}
      <Flex style={{ gap: "0.25rem" }}>
        <Tooltip title={attribute.type || "auto"}>
          <AttributeIcon style={{ width: "1rem" }} />
        </Tooltip>

        <Typography variant="caption">{attribute.entity}</Typography>
      </Flex>
      {usageInfo}
    </Stack>
  );
};
*/
