/* eslint-disable camelcase */
import produce from 'immer';
import { defaultTimePoints, isTimePoints } from '@components/TemporalValue/TemporalEditor/temporalOps';
import { v4 as uuid } from 'uuid';

export type Node = import('../../../../../decisions/DecisionDashboard/Reports/Details/UnifiedTimeline/types').Node;
export type PrimitiveValue = import('../../../../../decisions/DecisionDashboard/Reports/Details/UnifiedTimeline/types').PrimitiveValue;

export type TimePoint = import('@components/TemporalValue/TemporalEditor/temporalOps').TimePoint;
export interface Template {
  entity: string
  type: string;
  id: string;
  description: string;
}


export type DataValue = PrimitiveValue | TimePoint[];


export interface IState {
  selectedId: null | string;
  history: NonNullable< IState[ 'selectedId' ] >[];
  data: {
    templatesByEntity: Array<{
      entity: string;
      templates: Template[];
    }>;
    values: Array<{
      entity: string;
      rows: Array<{
        id: string;
        values: Array<{
          templateId: string;
          value: DataValue;
        }>
      }>
    }>
  };
  /**
   * node ids that should be taken from holder (in "./saga.ts")\
   * to build tree for ui. This is kinda HACK-y implementation,\
   * cause we don't want to store graph in redux as it is really\
   * huge structure, but rather we store it in memory and when it\
   * updates -> new treeRoots will be generated -> we rerender
   */
  treeRoots: string[];
  expanded: string[];
  search: string;
  /**
   * this guy mirrors `search` but with some debounce, so that we\
   * don`t recount attributes graph according to search on each \
   * keystroke
   */
  propagatableSearch: string;
}


export const REDUX_PROP = 'build_graphTest';
export const getState: (s: any) => IState = s => s[ REDUX_PROP ];

export const ActionTypes = {
  popFromHistory: 'build_graphTest/popFromHistory',
  requestToGoBack: 'build_graphTest/requestToGoBack',
  resetAttrHistoryAndData: 'build_graphTest/resetAttrHistoryAndData',
  setData: 'build_graphTest/setData',
  setDataValue: 'build_graphTest/setDataValue',
  toggleTemporal: 'build_graphTest/toggleTemporal',
  addRow: 'build_graphTest/addRow',
  removeRow: 'build_graphTest/removeRow',
  requestToSubmitCustomTest: 'build_graphTest/requestToSubmitCustomTest',
  requestToHydrateTree: 'build_graphTest/requestToHydrateTree',
  hydrateTree: 'build_graphTest/hydrateTree',
  setSelectedId: 'build_graphTest/setSelectedId',
  setExpanded: 'build_graphTest/setExpanded',
  toggleExpanded: 'build_graphTest/toggleExpanded',
  // ===================================================================================
  setSearch: 'build_graphTest/setSearch',
  setPropagatableSearch: 'build_graphTest/setPropagatableSearch',
  requestToSetSearch: 'build_graphTest/requestToSetSearch',
  // ===================================================================================
  requestToSetSelectedIdWithReveal: 'build_graphTest/requestToSetSelectedIdWithReveal',
} as const;


export interface IActions {
  popFromHistory: { type: typeof ActionTypes.popFromHistory; };
  requestToGoBack: { type: typeof ActionTypes.requestToGoBack; };
  resetAttrHistoryAndData: { type: typeof ActionTypes.resetAttrHistoryAndData; };
  setData: {
    type: typeof ActionTypes.setData;
    payload: IState[ 'data' ];
  };
  setDataValue: {
    type: typeof ActionTypes.setDataValue;
    payload: { value: DataValue; entity: string; rowId: string; templateId: string };
  };
  toggleTemporal: {
    type: typeof ActionTypes.toggleTemporal;
    payload: { entity: string; rowId: string; templateId: string };
  };
  addRow: {
    type: typeof ActionTypes.addRow;
    payload: { entity: string }
  };
  removeRow: {
    type: typeof ActionTypes.removeRow;
    payload: { entity: string; rowId: string }
  };
  requestToSubmitCustomTest: {
    type: typeof ActionTypes.requestToSubmitCustomTest;
  };
  hydrateTree: {
    type: typeof ActionTypes.hydrateTree;
    payload: {
      roots: IState[ 'treeRoots' ];
      /**
       * used specifically when we rehydrate tree as "update current"\
       * (e.g. approve/flag some node) and not "replace current" (e.g.\
       * choose different project)
       */
      rootsOnly?: true;
    };
  };
  requestToHydrateTree: {
    type: typeof ActionTypes.requestToHydrateTree;
    payload: { g: any; releaseChanged: boolean }
  };
  setSelectedId: {
    type: typeof ActionTypes.setSelectedId;
    payload: {
      id: IState[ 'selectedId' ];
      skipHistory?: true;
      graph?: any;
    };
  }
  requestToSetSelectedIdWithReveal: {
    type: typeof ActionTypes.requestToSetSelectedIdWithReveal;
    payload: IActions[ 'setSelectedId' ][ 'payload' ]
  }
  setExpanded: {
    type: typeof ActionTypes.setExpanded;
    payload: IState[ 'expanded' ];
  };
  toggleExpanded: {
    type: typeof ActionTypes.toggleExpanded;
    payload: string;
  };
  // ===================================================================================
  setSearch: {
    type: typeof ActionTypes.setSearch;
    payload: IState[ 'search' ];
  };
  setPropagatableSearch: {
    type: typeof ActionTypes.setPropagatableSearch;
    payload: IActions[ 'setSearch' ][ 'payload' ];
  };
  requestToSetSearch: {
    type: typeof ActionTypes.requestToSetSearch;
    payload: {
      search: IActions[ 'setSearch' ][ 'payload' ];
      /** tells us whether to delay set of propagatableSearch */
      immediate?: true;
    };
  }
  // ===================================================================================
}


export const ActionCreators: {
  [ K in keyof IActions ]: IActions[ K ] extends { payload: any }
    ? (p: IActions[ K ][ 'payload' ]) => IActions[ K ]
    : () => IActions[ K ]
} = {
  popFromHistory: () => ({ type: ActionTypes.popFromHistory }),
  requestToGoBack: () => ({ type: ActionTypes.requestToGoBack }),
  resetAttrHistoryAndData: () => ({ type: ActionTypes.resetAttrHistoryAndData }),
  setData: payload => ({ type: ActionTypes.setData, payload }),
  setDataValue: payload => ({ type: ActionTypes.setDataValue, payload }),
  toggleTemporal: payload => ({ type: ActionTypes.toggleTemporal, payload }),
  addRow: payload => ({ type: ActionTypes.addRow, payload }),
  removeRow: payload => ({ type: ActionTypes.removeRow, payload }),
  requestToSubmitCustomTest: () => ({ type: ActionTypes.requestToSubmitCustomTest }),
  hydrateTree: payload => ({ type: ActionTypes.hydrateTree, payload }),
  requestToHydrateTree: payload => ({ type: ActionTypes.requestToHydrateTree, payload }),
  setSelectedId: payload => ({ type: ActionTypes.setSelectedId, payload }),
  setExpanded: payload => ({ type: ActionTypes.setExpanded, payload }),
  toggleExpanded: payload => ({ type: ActionTypes.toggleExpanded, payload }),
  // ===================================================================================
  setSearch: payload => ({ type: ActionTypes.setSearch, payload }),
  setPropagatableSearch: payload => ({ type: ActionTypes.setPropagatableSearch, payload }),
  requestToSetSearch: payload => ({ type: ActionTypes.requestToSetSearch, payload }),
  // ===================================================================================
  requestToSetSelectedIdWithReveal: payload => ({ type: ActionTypes.requestToSetSelectedIdWithReveal, payload }),
};


export const defaultState: IState = {
  history: [],
  data: {
    templatesByEntity: [],
    values: [],
  },
  /**
   * node ids that should be taken from holder (in "./saga.ts")\
   * to build tree for ui. This is kinda HACK-y implementation,\
   * cause we don't want to store graph in redux as it is really\
   * huge structure, but rather we store it in memory and when it\
   * updates -> new treeRoots will be generated -> we rerender
   */
  treeRoots: [],
  selectedId: null,
  expanded: [],
  search: '',
  propagatableSearch: '',
};


export const extractDataValue: (
  // eslint-disable-next-line no-unused-vars
  (arg: {
    entity: string;
    rowId: string;
    templateId: string,
    values: IState[ 'data' ][ 'values' ];
  }) => IState[ 'data' ][ 'values' ][ 0 ][ 'rows' ][ 0 ][ 'values' ][ 0 ] | null
) = ({ values, entity, rowId, templateId }) => {
  const maybeForEntity = values.find(it => it.entity === entity);
  if(maybeForEntity === undefined) return null;

  const maybeRow = maybeForEntity.rows.find(it => it.id === rowId);
  if(maybeRow === undefined) return null;

  return maybeRow.values.find(it => it.templateId === templateId) || null;
};


export const extractDataTemplate: (
  // eslint-disable-next-line no-unused-vars
  (arg: {
    entity: string;
    templateId: string,
    templatesByEntity: IState[ 'data' ][ 'templatesByEntity' ];
  }) => IState[ 'data' ][ 'templatesByEntity' ][ 0 ][ 'templates' ][ 0 ] | null
) = ({ entity, templateId, templatesByEntity }) => {
  const maybeForEntity = templatesByEntity.find(it => it.entity === entity);
  if(maybeForEntity === undefined) return null;

  return maybeForEntity.templates.find(it => it.id === templateId) || null;
};


export const reducer: (
  s: typeof defaultState | undefined,
  a: IActions[ keyof IActions ]
) => NonNullable< typeof s > = (s = defaultState, a) => {
  switch(a.type) {
    case ActionTypes.popFromHistory: {
      const newHistory = s.history.slice(0, -1);

      return {
        ...s,
        history: newHistory,
      };
    }

    case ActionTypes.resetAttrHistoryAndData:
      return {
        ...s,
        selectedId: null,
        history: [],
        data: defaultState.data,
      };

    case ActionTypes.setData:
      return {
        ...s,
        data: a.payload,
      };

    case ActionTypes.setDataValue: {
      const { value, templateId, entity, rowId } = a.payload;

      return produce(s, draft => {
        const { data: { values } } = draft;
        const maybeValue = extractDataValue({ entity, rowId, templateId, values });

        if(maybeValue === null) return;

        maybeValue.value = value;
      });
    }


    case ActionTypes.toggleTemporal: {
      const { templateId, entity, rowId } = a.payload;

      return produce(s, draft => {
        const { data: { values, templatesByEntity } } = draft;
        const maybeValue = extractDataValue({ entity, rowId, templateId, values });
        const maybeTemplate = extractDataTemplate({ entity, templateId, templatesByEntity });

        if(maybeValue === null || maybeTemplate === null) return;


        maybeValue.value = isTimePoints(maybeValue.value)
          ? maybeTemplate.type === 'boolean'
            ? false
            : ''
          : defaultTimePoints;
      });
    }

    case ActionTypes.addRow: {
      return produce(s, ({ data }) => {
        const { entity } = a.payload;

        const maybeForEntity = data.values.find(it => it.entity === entity);
        const maybeForEntityTemplates = data.templatesByEntity.find(it => it.entity === entity);

        if(maybeForEntity === undefined || maybeForEntityTemplates === undefined) {
          return;
        }

        /** @type { IState['data']['values'][0]['rows'][0] } */
        const newRow = {
          id: uuid(),
          values: maybeForEntityTemplates.templates
            .map(it => ({ templateId: it.id, value: it.type === 'boolean' ? false : '' })),
        };

        maybeForEntity.rows.push(newRow);
      });
    }

    case ActionTypes.removeRow: {
      return produce(s, ({ data }) => {
        const { entity, rowId } = a.payload;

        const maybeForEntity = data.values.find(it => it.entity === entity);

        if(maybeForEntity === undefined) {
          return;
        }

        maybeForEntity.rows = maybeForEntity.rows.filter(it => it.id !== rowId);
      });
    }

    case ActionTypes.hydrateTree:
      const nextState = {
        ...s,
        treeRoots: a.payload.roots,
      };

      return a.payload.rootsOnly ? nextState : {
        ...nextState,
        selectedId: null,
        history: [],
        search: '',
        expanded: [],
      };

    case ActionTypes.setSelectedId:
      const {
        selectedId,
        history,
      } = s;

      return {
        ...s,
        selectedId: a.payload.id,
        history: (a.payload.skipHistory || selectedId === null)
          ? history
          : history.concat(selectedId),
      };

    case ActionTypes.setExpanded:
      return {
        ...s,
        expanded: a.payload,
      };

    case ActionTypes.toggleExpanded:
      return {
        ...s,
        expanded: s.expanded.find(it => it === a.payload)
          ? s.expanded.filter(it => it !== a.payload)
          : s.expanded.concat(a.payload),
      };

    case ActionTypes.setSearch:
      return {
        ...s,
        search: a.payload,
      };

    case ActionTypes.setPropagatableSearch:
      return {
        ...s,
        propagatableSearch: a.payload,
      };

    default:
      return s;
  }
};
