
import { all, takeEvery, delay, race, call, put, take } from 'redux-saga/effects';


/** milliseconds */
export const DEFAULT_TIMEOUT = 10000;
export const ACTION = 'combinatorV2/FIRE';
/**
  @typedef {{
    type: typeof ACTION;
    payload: {
      bootstrap: import('redux').Action;
      success: {
        type: string;
        validate: ( a: import('redux').Action ) => boolean;
        dispatch?: import('redux').Action | ((a: import('redux').Action) => import('redux').Action);
        call?: (a?: import('redux').Action) => unknown;
      };
      error?: {
        type: string;
        validate: ( a: import('redux').Action ) => boolean;
        dispatch?: import('redux').Action;
        call?: () => unknown;
      }
      asFinally?: {
        dispatch?: import('redux').Action;
        call?: () => unknown;
      }
      timeout?: number;
    },
  }} IAction
*/
/** @type { ( payload: IAction[ 'payload' ] ) => IAction } */
export const ActionCreator = payload => ({ type: ACTION, payload });

// ===================================================================================


/** @type { (a: ReturnType< typeof ActionCreator >) => Generator< any, any, any > } */
function* onAction(a) {
  const {
    payload: {
      success,
      error,
      asFinally,
      timeout,
      bootstrap,
    },
  } = a;

  /** @type {{ timeout?: any; success?: any, error?: any }} */
  const raceArg = {
    timeout: delay(timeout || DEFAULT_TIMEOUT),

    success: call(
      function* onSuccess() {
        while (true) {
          /** @type { import( 'redux' ).Action } */
          const action = yield take(success.type);

          if (success.validate(action)) {
            return action;
          }
        }
      },
    ),
  };

  if (error !== undefined) {
    raceArg.error = call(
      function* onError() {
        while (true) {
          /** @type { import( 'redux' ).Action } */
          const action = yield take(error.type);

          if (error.validate(action)) {
            return action;
          }
        }
      },
    );
  }

  const [raceResult] = yield all([
    race(raceArg),

    put(bootstrap),
  ]);

  yield call(function* processRaceResult() {
    if (raceResult.success) {
      if (success.dispatch !== undefined) {
        yield put(
          typeof success.dispatch === 'function'
            ? success.dispatch(raceResult.success)
            : success.dispatch,
        );
      }
      if (success.call !== undefined) success.call(raceResult.success);

      return;
    }

    // should error on actual error, or timeout
    if (error === undefined) return;
    if (error.dispatch !== undefined) yield put(error.dispatch);
    if (error.call !== undefined) error.call();
  });

  if (asFinally === undefined) return;

  if (asFinally.dispatch !== undefined) yield put(asFinally.dispatch);
  if (asFinally.call !== undefined) asFinally.call();
}


export function* saga() {
  yield takeEvery(ACTION, onAction);
}
