
import { takeLatest, select, put } from 'redux-saga/effects';
// @ts-ignore
import { crudGetList, CRUD_GET_LIST_SUCCESS, hideModal } from '@imminently/imminently_platform';
import * as combinatorV2 from '../combinatorV2.saga';
/**
 * when we did smth with projects, e.g. removed a project\
 * we want to reload current projects list, but persist \
 * pagination, as it could be that we are showing 100 \
 * projects at the moment of deletion, and simple crudGetList\
 * would only return 10
 */
export const ACTION = 'loadProjectsForCurrentTotal/FIRE';

/**
 * About payload argument. Generally we would want to call \
 * hide modal after this guy fires, but perhaps we don't, so\
 * that's why we have optional payload parameter
  @typedef {{
    type: typeof ACTION;
    payload: {
      dontCloseModal?: true
    }
  }} IAction
*/
/** @type { ( payload?: IAction[ 'payload' ] ) => IAction } */
export const ActionCreator = (payload = {}) => ({ type: ACTION, payload });


// ===================================================================================


/** @type { (a: ReturnType< typeof ActionCreator >) => Generator< any, any, any > } */
function* onAction(a) {
  const state = yield select();
  const {
    scope: { workspace },
  } = state;


  const maybeProjetsForWorkspace = state.resources.models[workspace];
  if (maybeProjetsForWorkspace === undefined) return;

  const {
    list: {
      params: { pagination },
    },
  } = maybeProjetsForWorkspace.default;

  const getListAction = crudGetList('models', workspace, {
    filter: { workspace },
    sort: { field: 'created', order: 'desc' },
    pagination,
  });


  yield put(
    a.payload.dontCloseModal
      ? getListAction
      : (
        combinatorV2.ActionCreator({
          bootstrap: getListAction,
          success: {
            type: CRUD_GET_LIST_SUCCESS,
            validate: v => /** @type { any } */(v).meta.resource === 'models',
            dispatch: hideModal(),
          },
        })
      ),
  );
}


export function* saga() {
  yield takeLatest(ACTION, onAction);
}
