import type { parts } from '../../DecisionDashboard/EEContext';


export const tabs = ['Compact', 'Raw'] as const;

export type State = {
  collapsed: boolean;
  tab: typeof tabs[ number ];
  raw: string;
}

export const defaultState: State = {
  collapsed: false,
  tab: 'Raw',
  raw: '{}',
};


export const REDUX_PROP = 'interactive';


export const aTypes = {
  setCollapsed: 'decisions/interactive/set-collapsed',
  toggleCollapsed: 'decisions/interactive/toggle-collapsed',
  setTab: 'decisions/interactive/set-tab',
  requestToInitEEContextBinding: 'decisions/interactive/request-to-init-ee-context-binding',
  setRaw: 'decisions/interactive/set-raw',
} as const;

export type Actions = {
  setCollapsed: {
    type: typeof aTypes.setCollapsed;
    payload: State[ 'collapsed' ];
  };
  toggleCollapsed: {
    type: typeof aTypes.toggleCollapsed;
  }
  setTab: {
    type: typeof aTypes.setTab;
    payload: State[ 'tab' ];
  };
  requestToInitEEContextBinding: {
    type: typeof aTypes.requestToInitEEContextBinding;
    payload: {
      derive: ReturnType< typeof parts.useDerive >;
      update: ReturnType< typeof parts.useUpdate >;
    }
  }
  setRaw: {
    type: typeof aTypes.setRaw;
    payload: State[ 'raw' ];
  }
};
export type AllActions = Actions[ keyof Actions ];

export const aCreators: {
  [ K in keyof Actions ]: Actions[ K ] extends { payload: any }
    ? (p: Actions[ K ][ 'payload' ]) => Actions[ K ]
    : () => Actions[ K ]
} = {
  setCollapsed: p => ({ type: aTypes.setCollapsed, payload: p }),
  toggleCollapsed: () => ({ type: aTypes.toggleCollapsed }),
  setTab: p => ({ type: aTypes.setTab, payload: p }),
  requestToInitEEContextBinding: p => ({ type: aTypes.requestToInitEEContextBinding, payload: p }),
  setRaw: p => ({ type: aTypes.setRaw, payload: p }),
};


export function reducer(s = defaultState, a: AllActions): typeof s {
  switch(a.type) {
    case aTypes.setCollapsed:
      return { ...s, collapsed: a.payload };
    case aTypes.toggleCollapsed:
      return reducer(s, aCreators.setCollapsed(!s.collapsed));
    case aTypes.setTab:
      return { ...s, tab: a.payload };
    case aTypes.setRaw:
      return { ...s, raw: a.payload };
    default:
      return s;
  }
}
