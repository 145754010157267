import styled from "styled-components";
import { Card } from "@material-ui/core";
import ErrorInfo from "@common/ErrorInfo";
import CenteredLayout from "layouts/CenteredLayout";
import { Trans, useTranslation } from "react-i18next";

// NOTE this does NOT run within a MUI context, its a full app fallback

const family = [
  "Montserrat",
  "Nunito",
].join(",");

const Info = styled(Card)`
  font-family: ${family};
  padding: 2rem 4rem;
  text-align: center;
  width: 50%;
  border-radius: 0.5rem;

  h1 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.2;
  }
`;

const ErrorPage = () => {
  const { t } = useTranslation();
  const suggestions = (
    <>
      <li>
        <Trans components={{
          a: <a/>
        }} i18nKey="errors.return_to_home_suggestion">
          Return to the <a href="/">homepage</a>
        </Trans>
      </li>
    </>
  );

  const page = (
    <Info>
      <ErrorInfo extraSuggestions={suggestions} />
    </Info>
  );

  // use the centered layout component to get the page centered with background
  return <CenteredLayout page={page} />;
};

export default ErrorPage;
