import { ReactElement, cloneElement } from "react";
import styled from "styled-components";

import Typography from "@material-ui/core/Typography";
import { showModal } from "@imminently/imminently_platform";
import { ModalActions, Actions } from "./components";

const ConfirmationActions = styled(ModalActions)`
  margin-top: 2rem;
`;

export interface BodyProps {
  close: VoidFn;
}
export type VoidFn = () => void;

type ConfirmationModalProps = {
  close: () => void;
  body?: string | ReactElement<BodyProps>;
  actions?: Actions | ((close: VoidFn) => Actions | null) | null;
};

const Confirmation = ({ close, body, actions }: ConfirmationModalProps) => {
  const acts = actions === undefined ? {
    primary: {
      name: "Ok",
      onClick: close,
    },
  } : typeof actions === "function" ? actions(close) : actions;
  return (
    <>
      {body && (
        typeof body === "string"
          ? (
            <Typography variant='body1' paragraph={false}>
              {body}
            </Typography>
          )
          : cloneElement(body, { close })
      )}
      {acts && <ConfirmationActions {...acts} />}
    </>
  );
};

export type ConfirmationProps = {
  /** The title to display */
  title: string;
  /** The body of the message */
  body?: string | ReactElement<BodyProps>;
  /** The actions to display */
  actions: Actions | ((close: any) => Actions | null) | null;
} & Record<string, any>; // allow other modal props TODO type it

export const showConfirmation = (props: ConfirmationProps) => {
  const { title, body, actions, ...rest } = props;
  if (!props) {
    throw new Error("showConfirmations requires props: { title, body?, actions? }");
  }

  return showModal(
    {
      open: true,
      maxWidth: "xs",
      title,
      ...rest,
    },
    ({ close }) => <Confirmation close={close} body={body} actions={actions} />,
  );
};
