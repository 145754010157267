/* eslint-disable react/prop-types */
import React from "react";
import Popover from '@material-ui/core/Popover';
import jspreadsheet from "jspreadsheet";
import "jspreadsheet/dist/jspreadsheet.css";
import "jsuites/dist/jsuites.css";
import { config } from "@config";
import { useJSpreadSheetWrapCtx } from "./Ctx";
import styled from "styled-components";
import { scrollableMixin } from "@common/scrollbar";

jspreadsheet.setLicense(config.JSPREADSHEET_TOKEN);

const Wrap = styled.div`
  flex-direction: column;
  overflow: auto;
  flex-grow: 1;

  &&& {
    display: flex;
  }

  .jtabs-content {
    ${ scrollableMixin };

    &::-webkit-scrollbar {
      width: 0.25rem;
      height: 0.25rem;
    }

    &::-webkit-scrollbar-thumb {
      border: none;
    }
  }

  &&& thead th {
    background-color: #000;
    color: #fff;
    text-align: left;
  }

  &&& {
    thead th, tr td {
      height: 2.5rem;
    }

    tr td {
      color: rgb(51, 51, 51);
      border-left-color: rgb(229, 229, 229);
      border-top-color: rgb(229, 229, 229);

      &:last-of-type {
        border-right-color: rgb(229, 229, 229);
      }
    }

    tr:last-of-type td {
      border-bottom-color: rgb(229, 229, 229);
    }

    table {
      border-bottom-color: transparent;
      border-right-color: transparent;
    }

    tr td:first-of-type {
      background-color: #fff;
      position: relative;

      &:before {
        content: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.5 8C10.3284 8 11 7.32843 11 6.5C11 5.67157 10.3284 5 9.5 5C8.67157 5 8 5.67157 8 6.5C8 7.32843 8.67157 8 9.5 8ZM9.5 14C10.3284 14 11 13.3284 11 12.5C11 11.6716 10.3284 11 9.5 11C8.67157 11 8 11.6716 8 12.5C8 13.3284 8.67157 14 9.5 14ZM11 18.5C11 19.3284 10.3284 20 9.5 20C8.67157 20 8 19.3284 8 18.5C8 17.6716 8.67157 17 9.5 17C10.3284 17 11 17.6716 11 18.5ZM15.5 8C16.3284 8 17 7.32843 17 6.5C17 5.67157 16.3284 5 15.5 5C14.6716 5 14 5.67157 14 6.5C14 7.32843 14.6716 8 15.5 8ZM17 12.5C17 13.3284 16.3284 14 15.5 14C14.6716 14 14 13.3284 14 12.5C14 11.6716 14.6716 11 15.5 11C16.3284 11 17 11.6716 17 12.5ZM15.5 20C16.3284 20 17 19.3284 17 18.5C17 17.6716 16.3284 17 15.5 17C14.6716 17 14 17.6716 14 18.5C14 19.3284 14.6716 20 15.5 20Z' fill='%23121923'/%3E%3C/svg%3E");
        position: absolute;
        width: 1rem;
        height: 1rem;
        right: 0;
        top: calc(50% - 0.5rem);
        display: none;
      }
    }

    tr:hover td:first-of-type:before {
      display: block;
    }

    .jss_number {
      text-align: left;
    }

    tr:hover td {
      background-color: rgba(155, 155, 155, 0.1);
    }

    .jss_dragging {
      opacity: 0.3;

      td {
        background-color: rgba(155, 155, 155, 0.2) !important;
      }
    }
  }

  .jss_input .jdropdown-container {
    width: 100%;

    .jdropdown-item { white-space: normal; }
  }
`;

export type JSpreadSheetWrapCoreProps = {
  className?: string;
  getSpreadsheetOptions: () => jspreadsheet.Spreadsheet;
}
/**
 * uses WithJSpreadSheetWrapCtx provided somewhere higher in the hierarchy.\
 * It is named "Wrap" because it will be the div which will hold the \
 * jspreadsheet render results, effectively "wrapping" it
 */
export const JSpreadSheetWrapCore: React.FC< JSpreadSheetWrapCoreProps > = React.memo(({ className, getSpreadsheetOptions }) => {
  const { valueRef, setValueCb, value: { ctxMenuParts } } = useJSpreadSheetWrapCtx();

  const wrapCbRef = React.useCallback< React.RefCallback< HTMLDivElement > >(node => {
    if ( node === null || valueRef.current.worksheets.length !== 0 ) return;

    const worksheets = jspreadsheet( node, getSpreadsheetOptions() );

    setValueCb(() => ({ holderRef: node, worksheets, ctxMenuParts: null, selection: null, helperState: {} }) );
  }, [ setValueCb, valueRef, getSpreadsheetOptions ]);

  const closeCtxMenu = React.useCallback( () => (
    setValueCb( s => ({ ...s, ctxMenuParts: null }) )
  ), [ setValueCb ] );

  return (
    <>
      <Wrap ref={ wrapCbRef } className={ className } />

      <Popover
        anchorPosition={ctxMenuParts === null ? undefined : { left: ctxMenuParts.x, top: ctxMenuParts.y }}
        // for some reason it is required that I pass this prop,
        // but we are placing menu using coordinates, so I pass
        // body here just so MUi would stop complaining
        anchorEl={ document.body }
        anchorReference="anchorPosition"
        open={ ctxMenuParts !== null }
        onClose={ closeCtxMenu }
      >
        { ctxMenuParts && <ctxMenuParts.Comp close={ closeCtxMenu } /> }
      </Popover>
    </>
  );
});
JSpreadSheetWrapCore.displayName = "components/JSpreadSheetWrap/Comp/Core";
