import { Release } from "@packages/commons";

export const REDUX_PROP = "fullRelease";
export type State = {
  value: null | Release;
}
const defaultState: State = { value: null };

export const aTypes = {
  requestToActualize: "fullRelease/requestToActualize",
  set: "fullRelease/set",
}as const;

export type Actions = {
  requestToActualize: {
    type: typeof aTypes.requestToActualize;
  };
  set: {
    type: typeof aTypes.set;
    payload: State[ "value" ];
  }
}
export type AllActions = Actions[ keyof Actions ];

export const aCreators: {
  [ K in keyof Actions ]: Actions[ K ] extends { payload: unknown }
    ? (p: Actions[ K ][ "payload" ]) => Actions[K]
    : () => Actions[K]
} = {
  requestToActualize: () => ({ type: aTypes.requestToActualize }),
  set: p => ({ type: aTypes.set, payload: p })
};

export function reducer(s = defaultState, a: AllActions): typeof s {
  switch (a.type) {
    case aTypes.set: return { value: a.payload };
    default: return s;
  }
}
