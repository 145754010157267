import { PropsWithChildren, ReactNode } from "react";
import clsx from "clsx";
import { Controller, useFormContext } from "react-hook-form";
import { PlateProvider } from "@udecode/plate";
import { Box, Typography } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { FixedRichTextToolbar } from "@common/editor/RuleAuthor/toolbar/FixedToolbar";
import { PlateRichTextEditor, richTextPlugins } from "@common/editor/components/PlateRichTextEditor";
import { editableProps, statusOptions, priorityOptions } from "./taskUtils";
import { Button, ChipInput, InlineEdit } from "@components";
import { DropdownSelect } from "@components/radix";
import { UserCombobox } from "@components/radix/UserSelect/UserSelect";
import styles from "./tasks.module.scss";
import { PriorityLabel, StatusLabel } from "./TaskComponents";
import { AssigneeGroup } from "@components/forms/FormikControls";
import { useTranslation } from "react-i18next";

export const TaskDetail = ({ label, icon, children }: { label: string; icon: ReactNode; } & PropsWithChildren) => {
  return (
    <Box className={styles.detail}>
      <Box style={{ flex: 2 }} className={styles.detail}>
        {icon}
        <Typography style={{ flexShrink: 0 }}>{label}</Typography>
      </Box>
      <Box style={{ display: "flex", flex: 3 }}>
        {children}
      </Box>
    </Box >
  );
};

export const TitleField = () => {
  const { control, formState: { errors } } = useFormContext();
  const { t } = useTranslation();

  return (
    <Controller
      name="title"
      control={control}
      rules={{
        required: true
      }}
      render={({ field }) => (
        <Typography variant="h3" style={{ lineHeight: "2rem", border: errors.title ? '2px solid red' : '' }}>
          <InlineEdit className={styles.title} placeholder={`${t('tasks.title_placeholder')}...*`} value={field.value} onChange={field.onChange} />
        </Typography>

      )}
    />
  )
};


// const status = <Label className={styles.label} color={statusColor[task.status]}>{taskDisplayName}</Label>;
// const priority = <Label className={styles.label} color={priorityColor[task.priority]}>{task.priority}</Label>;

const statusSelectOptions = statusOptions.map(option => ({ ...option, label: <StatusLabel status={option.value} /> }));

export const StatusSelector = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <Controller
      name="status"
      control={control}
      render={({ field }) => (
        <DropdownSelect items={statusSelectOptions} value={field.value} onChange={field.onChange}>
          {
            field.value ? <StatusLabel status={field.value.value} /> : `${t('tasks.status_placeholder')}...`
          }
        </DropdownSelect>
      )}
    />
  );
};

export const AssigneeSelector = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <Controller
      name="assigneesData"
      control={control}
      render={({ field }) => (
        <>
          <UserCombobox
            value={field.value}
            onChange={(value) => field.onChange(value)}
            searchProps={{ placeholder: t('search') }}
          >
            <Button size="small" type="tertiary" fullWidth style={{ justifyContent: "flex-start" }}>
              <AssigneeGroup users={field.value} max={4} />
            </Button>
          </UserCombobox>
        </>
      )}
    />
  );
};

export const DateSelector = () => {
  const { control } = useFormContext();
  return (
    <Controller
      name="dueDate"
      control={control}
      render={({ field }) => (
        // inputFormat="dd/MM/yyyy" renderInput={(props) => <InlineEdit {...props} />}
        <DatePicker value={field.value} onChange={field.onChange} format="DD/MM/yyyy" />
      )}
    />
  );
};

const prioritySelectOptions = priorityOptions.map(option => ({ ...option, label: <PriorityLabel priority={option.value} /> }));

export const PrioritySelector = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();
  return (
    <Controller
      name="priority"
      control={control}
      render={({ field }) => (
        <DropdownSelect items={prioritySelectOptions} value={field.value} onChange={field.onChange}>
          {
            field.value ? <PriorityLabel priority={field.value.value} /> : `${t('tasks.priority_placeholder')}...`
          }
        </DropdownSelect>
      )}
    />
  );
};

export const TagsInput = () => {
  const { control } = useFormContext();
  return (
    <Controller
      name="tags"
      control={control}
      render={({ field }) => (
        <ChipInput
          fullWidth
          value={field.value}
          onChange={field.onChange}
          blurBehavior='add'
        />
      )}
    />
  );
};

export const DescriptionInput = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <Controller
      name="description"
      control={control}
      render={({ field }) => (
        <Box className={clsx(styles.textBox, styles.description)}>
          <PlateProvider id={"description-editor"} plugins={richTextPlugins} value={field.value} onChange={field.onChange}>
            <FixedRichTextToolbar className={styles.toolbar} />
            <PlateRichTextEditor id={"description-editor"} editableProps={editableProps({ placeholder: `${t('tasks.description_placeholder') }...`})} />
          </PlateProvider>
        </Box>
      )}
    />
  );
};