import {
  type TDescendant,
  type TEditor,
  type TElement,
  type TNode,
  createPluginFactory,
  insertNodes,
  removeNodes,
} from "@udecode/plate";

import { tableGetFragment } from "@common/editor/components/decisionTable";
import { ELEMENT_SECTION } from "@common/editor/components/section/sectionConstants";
import { type HotkeyOptions, createParagraph, getSelectedNodes, withHotKeys } from "../../plugins/util";
import { Section } from "./section";

export const createSectionFromNodes = (editor) => {
  console.log("create section", editor);
  const nodes = getSelectedNodes(
    editor,
    (n) =>
      (n as TEditor | TElement).children?.length > 0 &&
      (n as TEditor | TElement).children.every((c) => Object.prototype.hasOwnProperty.call(c, "text")),
  );
  if (Array.isArray(editor.onCreateSection)) {
    for (const f of editor.onCreateSection) {
      f(nodes);
    }
  }
};

export const createSectionPlugin = createPluginFactory<{
  disableSuggestModals?: boolean;
  hotkeys?: HotkeyOptions[];
}>({
  key: ELEMENT_SECTION,
  isElement: true,
  component: Section,
  withOverrides: (editor, plugin) => {
    const options = plugin.options;
    // @ts-ignore we are adding it
    editor.onCreateSection = [];
    const { getFragment, normalizeNode } = editor;
    editor.getFragment = <N extends TDescendant>() => {
      // for some reason this overrides the table get fragment, so run it here too, dunno why.
      const fragments = tableGetFragment(editor, getFragment());
      if (Array.isArray(fragments)) {
        return fragments.flatMap((f) => (f.type !== ELEMENT_SECTION ? f : f.children)) as N[];
      }
      return (fragments as TNode).type !== ELEMENT_SECTION ? fragments : ((fragments as TNode).children as N[]);
    };

    editor.normalizeNode = ([node, path]) => {
      if (node.type === ELEMENT_SECTION) {
        if (Array.isArray(node.children) && node.children.length === 0) {
          insertNodes(editor, createParagraph(""), { at: path.concat(0) });
        } else if (Array.isArray(node.children) && node.children.length === 1) {
          const child = node.children[0];
          if (child.type === undefined) {
            removeNodes(editor, { at: path.concat(0) });
            insertNodes(editor, createParagraph(""), { at: path.concat(0) });
          }
        }
      }

      return normalizeNode([node, path]);
    };

    // disallow deleting sections using backspace and delete
    // TODO this was breaking basic delete and backspace
    // editor.deleteForward = unit => {
    //   const { selection } = editor;

    //   if (selection && getNode(editor, Path.next(Path.parent(selection.focus.path)))?.type === ELEMENT_SECTION) {
    //     return;
    //   }
    //   deleteForward(unit);
    // };

    // editor.deleteBackward = unit => {
    //   const { selection } = editor;
    //   if (selection && selection.focus.offset === 0) {
    //     const [section] = getSelectedNodes(editor);
    //     if (section.type === ELEMENT_SECTION) {
    //       return;
    //     }
    //   }
    //   deleteBackward(unit);
    // };

    return editor;
  },
  ...withHotKeys([
    {
      keys: "mod+alt+s",
      action: (editor) => {
        createSectionFromNodes(editor);
      },
      query: { allow: [] },
    },
  ]),
});
