/**
 * An implementation of allow in place editing using contentEditable.
 *
 * Based off: https://github.com/lovasoa/react-contenteditable
 *
 */
import React from "react";
import { useState, useRef, useEffect, useCallback } from "react";
// import deepEqual from "fast-deep-equal";
import * as PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import { useMultiClick } from "./util";

// function normalizeHtml(str) {
//   return str && str.replace(/&nbsp;|\u202F|\u00A0/g, " ");
// }

function replaceCaret(el) {
  // Place the caret at the end of the element
  const target = document.createTextNode("");
  el.appendChild(target);
  // do not move caret if element was not focused
  const isTargetFocused = document.activeElement === el;
  if (target !== null && target.nodeValue !== null && isTargetFocused) {
    const sel = window.getSelection();
    if (sel !== null) {
      const range = document.createRange();
      range.setStart(target, target.nodeValue.length);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
    if (el instanceof HTMLElement) el.focus();
  }
}

/**
 * A simple component for an html element with editable contents.
 */
export class ContentEditable extends React.PureComponent {
  lastHtml = this.props.html;

  el = typeof this.props.innerRef === "function" ? { current: null } : React.createRef();

  getEl = () => (this.props.innerRef && typeof this.props.innerRef !== "function" ? this.props.innerRef : this.el).current;

  handlePaste = e => {
    // cancel paste
    e.preventDefault();

    // get text representation of clipboard
    const text = (e.originalEvent || e).clipboardData.getData("text/plain");
    console.log("onPaste", text, e.currentTarget);
    // insert text manually
    // document.execCommand('insertHTML', false, text);
    // e.currentTarget.textContent = text;
    const el = this.getEl();
    el.textContent = text;
    replaceCaret(el);
    this.emitChange(e);
    // e.currentTarget.focus();
  }

  render() {
    const { tagName, html, innerRef, handleBlur, ...props } = this.props;

    const elemProps = {
      ...props,
      ref:
        typeof innerRef === "function"
          ? current => {
            innerRef(current);
            this.el.current = current;
          }
          : innerRef || this.el,
      onInput: this.emitChange,
      onBlur: handleBlur || this.emitChange,
      onKeyUp: this.props.onKeyUp || this.emitChange,
      onKeyDown: this.props.onKeyDown || this.emitChange,
      contentEditable: !this.props.disabled,
      dangerouslySetInnerHTML: { __html: html },
      onPaste: this.handlePaste,
    };

    // return tagName && typeof tagName === "object"
    //   ? React.cloneElement(tagName, elemProps, this.props.children)
    return React.createElement(tagName || "div", elemProps, this.props.children);
  }

  //   shouldComponentUpdate(nextProps) {
  //     const { props } = this;
  //     const el = this.getEl();

  //     // We need not rerender if the change of props simply reflects the user's edits.
  //     // Rerendering in this case would make the cursor/caret jump

  //     // Rerender if there is no element yet... (somehow?)
  //     if (!el) return true;

  //     // ...or if html really changed... (programmatically, not by user edit)
  //     if (normalizeHtml(nextProps.html) !== normalizeHtml(el.innerHTML)) {
  //       return true;
  //     }

  //     // Handle additional properties
  //     return (
  //       props.disabled !== nextProps.disabled ||
  //       props.tagName !== nextProps.tagName ||
  //       props.className !== nextProps.className ||
  //       props.innerRef !== nextProps.innerRef ||
  //       props.placeholder !== nextProps.placeholder ||
  //       !deepEqual(props.style, nextProps.style)
  //     );
  //   }

  componentDidUpdate() {
    const el = this.getEl();
    if (!el) return;

    // Perhaps React (whose VDOM gets outdated because we often prevent
    // rerendering) did not update the DOM. So we update it manually now.
    if (this.props.html !== el.innerHTML) {
      el.innerHTML = this.props.html;
    }

    this.lastHtml = this.props.html;
    replaceCaret(el);
  }

  emitChange = originalEvt => {
    const el = this.getEl();
    if (!el) return;

    const html = el.innerHTML;
    if (this.props.onChange && html !== this.lastHtml) {
      // Clone event with Object.assign to avoid
      // "Cannot assign to read only property 'target' of object"
      const evt = { ...originalEvt,
        target: {
          value: html,
        } };
      this.props.onChange(evt);
    }

    this.lastHtml = html;
  };

  static propTypes = {
    html: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    tagName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    className: PropTypes.string,
    style: PropTypes.object,
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  };
}

const highlightElement = elem => {
  window.setTimeout(() => {
    let sel, range;
    if (window.getSelection && document.createRange) {
      range = document.createRange();
      range.selectNodeContents(elem);
      sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (document.body.createTextRange) {
      range = document.body.createTextRange();
      range.moveToElementText(elem);
      range.select();
    }
  }, 1);
};

export const withEditable = Component => React.forwardRef(({ value, onEdited, multiline, onClick, autoFocus, autoHighlight, editing, setEditing, ...props }, ref) => {
  const [_edit, _setEdit] = useState(false);
  const editRef = useRef(ref);
  const text = useRef(value);

  // allow for external control
  const edit = editing ?? _edit;
  const setEdit = setEditing ?? _setEdit;

  const triggerEdit = useCallback(() => {
    setEdit(true);
    // if (editRef?.current) {
    //   setTimeout(() => {
    //     editRef?.current?.focus();
    //     if (autoHighlight) {
    //       highlightElement(editRef.current);
    //     }
    //   }, 10);

    //   text.current = autoFocus ? "" : text.current;
    // }
  }, [setEdit, editRef, text, autoFocus]);

  useEffect(() => {
    if (autoFocus) {
      triggerEdit();
    }
  }, [autoFocus, triggerEdit]);

  useEffect(() => {
    if (edit && editRef?.current) {
      setTimeout(() => {
        editRef?.current?.focus();
        if (autoHighlight) {
          highlightElement(editRef.current);
        }
      }, 10);

      text.current = autoFocus ? "" : text.current;
    }
  }, [edit]);

  const handleClick = useMultiClick(
    () => {
      onClick?.();
    },
    () => {
      triggerEdit();
    },
  );

  const handleChange = e => {
    text.current = e.target.value;
    //   onChange?.(text.current);
  };

  const handleBlur = e => {
    if (text.current.length === 0) {
      // reset to start / dont allow empty
      text.current = value;
    }

    setEdit(false);
    onEdited?.(text.current);
  };

  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleBlur();
    }
  };

  return (
    <ContentEditable
      innerRef={editRef}
      disabled={!edit}
      tagName={Component}
      html={text.current}
      onChange={handleChange}
      handleBlur={handleBlur}
      onKeyDown={!multiline && handleKeyDown}
      onClick={edit ? undefined : onClick ? handleClick : triggerEdit}
      {...props}
    />
  );
});

export const EditableText = withEditable(Typography);
