

export type DataType = 'completion' | 'trends';

export const DataTypes: { [ K in DataType ]: K } = {
  completion: 'completion',
  trends: 'trends',
};

export type CompletionKey = 'complete' | 'incomplete' | 'error';
export const CompletionKeys: { [ K in CompletionKey ]: K } = {
  complete: 'complete',
  incomplete: 'incomplete',
  error: 'error',
};

export type TrendsKey = 'trends';
export const TrendsKeys: { [ K in TrendsKey ]: K } = {
  trends: 'trends',
};

export type DataKey = CompletionKey | TrendsKey;

export const decisionReportsResource = 'decisionreports';
export const decisionReportsLocation = 'monitoringDecReports';

export const dedicatedPageDecReportsLocation = 'decReportsForDedicatedPage';


/** returns false or reportUUID */
export const pathnameContainsReportUUID = () => {
  const [maybeMonitor, maybeReportId] = document.location.pathname.split('/').filter(Boolean);

  // check if we are on route starting with "monitor"
  if (maybeMonitor !== 'monitor') return false;


  return maybeReportId || false;
};
