import styled from "styled-components";
import SimpleBar from "simplebar-react";

export const ScrollablePageContent = styled(SimpleBar)`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  /* padding: 2rem 2rem 2rem 2rem; */
`;
