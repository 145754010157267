import { scrollable } from "@common/scrollbar";
import { Kbd } from "@components/kdb";
import { showModal } from "@imminently/imminently_platform";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";

const Content = styled.div`
  padding: 0 1rem 0 2rem;
  scrollbar-gutter: stable;
  margin: 0 -2rem;
  height: 70vh;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  > h5:not(:first-child) {
    padding-top: 0.5rem;
  }
`;

const HelpModal = () => {
  return (
    <Content className={scrollable}>
      <Typography variant="h5">Common editor controls</Typography>
      <Typography>
        The source and rule editors are both rich text editors. They share a lot of base features, however they do
        diverge as they solve different problems. At their core, most basic text editing features should be supported.
      </Typography>
      <Typography>
        The main feature that is hard to showcase is soft and exit breaks.
        <br />
        <strong>Soft breaks</strong> are created by pressing{" "}
        <Kbd
          inline
          variant="separate"
          keys={["shift", "enter"]}
        />
        . Currently this only works with paragraphs and will allow insertion of new lines. The default behaviour if you
        press enter multiple times is to back out of the current block. Soft break exists to allow inserting multiple
        paragraphs within a single block.
        <br />
        <strong>Exit breaks</strong> are used by pressing{" "}
        <Kbd
          inline
          variant="separate"
          keys={["mod", "enter"]}
        />
        . This will hard exit the current block and create a new paragraph at the bottom. You can also use{" "}
        <Kbd
          inline
          variant="separate"
          keys={["mod", "shift", "enter"]}
        />{" "}
        to create a new paragraph at the top. Note this backs out of all blocks and inserts a new line at the root.
      </Typography>
      <Typography variant="h5">Source editor controls</Typography>
      <Typography>
        The source editor is a rich text editor that allows you to create and edit source documents. It aims to support
        most of the features you would expect from a modern rich text editor. The experience should be familiar to
        anyone who has used a word processor or similar. Most expected behaviours, hotkeys etc should work as expected.
        <br />
        The editor also supports{" "}
        <a
          href="https://www.markdownguide.org/basic-syntax/"
          target="_blank"
          rel="noreferrer"
        >
          Markdown syntax
        </a>
        . This means you can write Markdown in the editor and it will be auto converted.
      </Typography>
      <Typography variant="h5">Rule editor controls</Typography>
    </Content>
  );
};

export const showHelp = () => {
  return showModal(
    {
      open: true,
      title: "Rule Author Help",
    },
    <HelpModal />,
  );
};
