import { useSections } from "@pages/documents";
import React, { createContext } from "react";
import type { ISectionContext, SectionProviderProps } from "./section.types";

export const SectionContext = createContext<ISectionContext>(null as unknown as ISectionContext);

/**
 * TODO If we make section provider rely on Plate, we could modify the section actions
 * to use the editor and insert the actions into the history. This would allow undo/redo.
 *
 * Do we make transforms, or do we add it to the editor as actions?
 * Adding to the editor would allow usage without hooks, ie in other editor actions.
 * Adding to editor may not be able to hook up to redux. How do we get redux outside of a hook?
 *
 * Right now, this only acts as a basic context wrapper over the redux document sections.
 */

export const SectionProvider = ({ children, documentId }: SectionProviderProps) => {
  const { sections, getSection, setSection, addSection, removeSection, setSections } = useSections(documentId);
  const [selected, setSelected] = React.useState<string | null>(null);

  const selectSection = (section: string | null) => {
    setSelected(section);
  };

  const context: ISectionContext = {
    sections,
    sectionId: selected,
    selectSection,
    getSection,
    setSection,
    addSection,
    removeSection,
    setSections,
  };

  return <SectionContext.Provider value={context}>{children}</SectionContext.Provider>;
};

export const useSection = () => {
  const context = React.useContext(SectionContext);
  if (!context) {
    throw new Error("useSection must be used within a SectionProvider");
  }
  return context;
};
