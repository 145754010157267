import { all, put, select, takeLatest } from "redux-saga/effects";
import { config } from "../../config";
import { SET_RELEASE, SET_SCOPE } from "../scope";
import { aCreators, aTypes } from "./reducer";
import { releaseService } from "../../services";

export function* saga(authProvider: any) {
  yield all([
    takeLatest([SET_RELEASE, SET_SCOPE, aTypes.requestToActualize], function* loadFullRelease() {
      const state = yield select();
      const { release } = state.scope;
      if (!release) return;

      try {
        const resp = yield releaseService.getOne(release);
        yield put(aCreators.set(resp));
      } catch (e) {
        console.error("Failed to load full release", release);
      }
    }),
  ]);
}
