import { createPluginFactory } from "@udecode/plate";

import { withHotKeys } from "../util";

export const createTabPlugin = createPluginFactory({
  key: "tab",
  ...withHotKeys([
    {
      keys: "tab",
      action: (editor) => {
        editor.insertText("\t");
      },
      query: {
        exclude: [],
      },
    },
  ]),
});
