import type { DataTableContents, RuleDocContentsV1, RuleDocContentsV2, RuleSheetContents, DocumentContents } from "@packages/commons";
import type { ENode, Value } from "@udecode/plate-core";
import type { DeepPartial } from "@imminently/immi-query";
import { deserializeDocumentV2Contents } from "@common/editor/RuleAuthor/ruleSerialisation";

/**
 * We use this symbol to make sure the types of the deserialized documents are distinct from the serialized ones
 * This is to prevent accidentally sending a deserialized document to the backend
 */
export const DESERIALIZED_SYMBOL = Symbol("deserialized");

export interface DeserializedRuleSheet {
  title: string;
  columns: any[];
  data: any[];
}

export type DeserializedRuleDocContentsV1 = RuleDocContentsV1 & {
  [DESERIALIZED_SYMBOL]: true;
};

export interface DeserializedRuleDocContentsV2 extends Omit<RuleDocContentsV2, "rules"> {
  [DESERIALIZED_SYMBOL]: true;
  rules: ENode<Value>[];
}

export interface DeserializedRuleSheetContents extends Omit<RuleSheetContents, "sheets"> {
  [DESERIALIZED_SYMBOL]: true;
  applySheet: DeserializedRuleSheet;
  sheets: DeserializedRuleSheet[];
}

export type DeserializedDocumentContents = (
  | DeserializedRuleDocContentsV2
  | DeserializedRuleDocContentsV1
  | DeserializedRuleSheetContents
  | DataTableContents
) & {
  // all deserialized documents should have this symbol
  [DESERIALIZED_SYMBOL]: true;
};


export type DeserializeFunc = (contents: undefined | DeepPartial<DocumentContents>) => DeserializedRuleDocContentsV2;
/**
 * TODO remove this method and use `getDeserializeFunc from `documents.slice.helpers`
 *
 * this one is duplicated and extracted from `getDeserializeFunc` because of\
 * cyclic dependencies, but ideally should not be separate. As fixing those\
 * seems like too much effort right now, decided to take only what I need and\
 * leave refactoring for later
 */
export const deserializeFuncForSuggestRulesModal: DeserializeFunc = (contents) => {
  if (!contents) {
    return {[DESERIALIZED_SYMBOL]: true, rules: [], source: [], sections: [] }
  }
  const fn = deserializeDocumentV2Contents;
  return {
    [DESERIALIZED_SYMBOL]: true,
    ...fn(contents as RuleDocContentsV2),
  } as DeserializedRuleDocContentsV2;
};
