import { takeLatest, select, all, take, delay, race, put } from 'redux-saga/effects';
import {
  CRUD_DELETE_SUCCESS,
  CRUD_UPDATE_SUCCESS,
  crudDelete,
  crudUpdate,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from '@imminently/imminently_platform';
import type { ReleaseInResources } from './index';
import * as combinatorV2NS from '../../redux/combinatorV2.saga';
import * as populateProjectReleaseCentreNS from './redux';


export const ActionType = 'projects/requestToDeleteReleaseFromSaga';
export interface IAction {
  type: typeof ActionType;
  payload: {
    releaseId: string;
    projectId: string;
  }
}


export const AC = (payload: IAction[ 'payload' ]): IAction => ({
  type: ActionType,
  payload,
});


// ===================================================================================


// export const promoteReleaseAC = (p: IAction[ 'payload' ]) => combinatorV2NS.ActionCreator({
//   bootstrap: crudUpdate('releases', p.releaseId, {}, {
//     customAction: 'clone',
//   }),
//   success: {
//     type: CRUD_UPDATE_SUCCESS
//   }

//   toDispatch: populateProjectReleaseCentreNS.ActionCreator(project),
//   waitFor: {
//     type: CRUD_UPDATE_SUCCESS,
//     validate: a => {
//       const broadAction = /** @type { any } */(a);

//       return broadAction.meta?.id === id &&
//         broadAction?.meta?.customAction === 'clone';
//     },
//   },
//   ,
// });


// ===================================================================================


export function* saga(): Generator< any, any, any > {
  yield takeLatest(ActionType, function* onRequestToDeleterelease({ payload: p }: IAction) {
    const store: any = yield select();
    const { releases } = store.resources;
    if(!releases) return;

    const { data } = releases;
    if(!data) return;

    const releasesForProject = (Object.values(data) as ReleaseInResources[])
      .filter(it => it.model === p.projectId);
    const releaseToDelete = releasesForProject.find(it => it.id === p.releaseId);
    if(!releaseToDelete) return;


    const current = releasesForProject.find(it => it.activeVersionFlag);
    const remainingReleases = releasesForProject.filter(it => it.id !== p.releaseId);
    const latestRemainingRelease = remainingReleases.reduce< null | ReleaseInResources >(
      (a, it) => (a === null ? it : (
        it.releaseNo > a.releaseNo ? it : a
      )),
      null,
    );

    if(latestRemainingRelease === null) return;

    const nextCurrentReleaseId = (current === undefined || current.id === p.releaseId)
      ? latestRemainingRelease.id
      : current.id;
    const latestShouldBePromoted = nextCurrentReleaseId !== current?.id;

    const rez = yield race({
      timeout: delay(10_000),

      onDelete: (function* onDelete() {
        yield all([
          (function* waitForDeleteSuccess(): Generator< any, any, any> {
            while(true) {
              const a = (yield take(CRUD_DELETE_SUCCESS));
              if(a.requestPayload?.id === p.releaseId) {
                return;
              }
            }
          }()),
          put(crudDelete('releases', p.releaseId, { refresh: false })),
        ]);
      }()),
    });

    if(rez.timeout) return;

    const populateAction = populateProjectReleaseCentreNS.aCreators.requestToActualizeReleases({
      type: 'paginationUnchanged',
      componentId: releaseToDelete.env === 'test' ? 'test' : 'prod',
      projectId: p.projectId,
    });

    if(latestShouldBePromoted === false) {
      yield put(populateAction);

      return;
    }

    yield put(combinatorV2NS.ActionCreator({
      bootstrap: crudUpdate('releases', nextCurrentReleaseId, {}, { customAction: 'activate', refresh: false }),
      success: {
        type: CRUD_UPDATE_SUCCESS,
        validate: (a: any) => a.meta?.id === nextCurrentReleaseId,
        dispatch: populateAction,
      },
    }));
  });
}
