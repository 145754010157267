// @ts-check
import { all, takeLatest, put, select } from 'redux-saga/effects';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { crudGetList } from '@imminently/imminently_platform';


// const ACTION = 'projects/populateProjectReleaseCentre';
// export const ActionCreator: (projectId: string) => { type: typeof ACTION, payload: typeof projectId } = (
//   payload => ({ type: ACTION, payload })
// );


export const aTypes = {
  requestToPopulateProjectReleaseCentre: 'projects/releaseCenter/requestToPopulateProjectReleaseCentre',
  requestToActualizeReleases: 'projects/releaseCenter/requestToActualizeReleases',
  requestToActualizeTestAndProd: 'projects/releaseCenter/requestToActualizeTestAndProd',
  requestToLoadMoreReleases: 'projects/releaseCenter/requestToLoadMoreReleases',
} as const;

export type Actions = {
  requestToPopulateProjectReleaseCentre: {
    type: typeof aTypes.requestToPopulateProjectReleaseCentre;
    payload: {
      projectId: string;
    }
  };
  requestToActualizeReleases: {
    type: typeof aTypes.requestToActualizeReleases;
    payload: {
      type: 'paginationUnchanged' | 'maybePlusOnePagination';
      projectId: string;
      componentId: 'test' | 'prod';
    }
  };
  requestToActualizeTestAndProd: {
    type: typeof aTypes.requestToActualizeTestAndProd;
    payload: {
      projectId: string;
    }
  };
  requestToLoadMoreReleases: {
    type: typeof aTypes.requestToLoadMoreReleases;
    payload: {
      componentId: 'test' | 'prod';
      projectId: string;
    }
  }
}

export type ACreatorsT = {
  [ K in keyof Actions ]: Actions[ K ] extends { payload: unknown }
    ? (p: Actions[ K ][ 'payload' ]) => Actions[ K ]
    : () => Actions[ K ]
};


export const aCreators: ACreatorsT = {
  requestToPopulateProjectReleaseCentre: p => ({ type: aTypes.requestToPopulateProjectReleaseCentre, payload: p }),
  requestToActualizeReleases: p => ({ type: aTypes.requestToActualizeReleases, payload: p }),
  requestToActualizeTestAndProd: p => ({ type: aTypes.requestToActualizeTestAndProd, payload: p }),
  requestToLoadMoreReleases: p => ({ type: aTypes.requestToLoadMoreReleases, payload: p }),
};

// ===================================================================================

const fields = [
  'versionNo',
  'env',
  'id',
  'lastModified',
  'model',
  'releaseNo',
  'basedOn',
  'promotedAs',
  'activeVersionFlag',
];

const sort = { field: 'created', order: 'desc' };

const PER_PAGE = 10;
// const defaultListInfo = { pagination: { page: 1, perPage: 0 }, total: 0 };
const paginationForPopulate = { page: 1, perPage: PER_PAGE };

// ===============================================================================================

export type ReleaseList = {
  ids: string[];
  total: number;
  params: {
    pagination: { page: number; perPage: number };
  }
  loading: boolean;
  loadedOnce: boolean;
};

export const extractReleaseList = (s: any, projectId: string, list: 'test' | 'prod'): undefined | ReleaseList => {
  return s?.resources?.releases?.[ projectId ]?.[ list ]?.list;
};

// ===============================================================================================

function* onRequestToPopulateReleaseCentre(a: Actions[ 'requestToPopulateProjectReleaseCentre' ]): Gen {
  const { payload: { projectId: id } } = a;
  const state = yield select();

  const testList = extractReleaseList(state, id, 'test');
  const prodList = extractReleaseList(state, id, 'prod');

  if(testList === undefined || !testList.loadedOnce) {
    yield put(crudGetList(
      'releases',
      id,
      {
        filter: { model: id, env: 'test' },
        pagination: paginationForPopulate,
        componentId: 'test',
        fields,
        sort,
      },
    ));
  }

  if(prodList === undefined || !prodList.loadedOnce) {
    yield put(crudGetList(
      'releases',
      id,
      {
        filter: { model: id, env: 'prod' },
        pagination: paginationForPopulate,
        componentId: 'prod',
        fields,
        sort,
      },
    ));
  }
}


function* onRequestToActualizeReleases(a: Actions[ 'requestToActualizeReleases' ]): Gen {
  const { componentId, type, projectId } = a.payload;
  const state = yield select();

  const curData: null | { perPage: number; numLoaded: number } = (() => {
    const list = extractReleaseList(state, projectId, componentId);
    if(!list) return null;

    return { perPage: list.params.pagination.perPage, numLoaded: list.ids.length };
  })();

  if(curData === null) return;


  const nextPerPage = ((): number => {
    if(type === 'paginationUnchanged') return curData.perPage;

    const { numLoaded, perPage } = curData;
    return numLoaded + 1 <= perPage ? perPage : perPage + 1;
  })();


  yield put(crudGetList(
    'releases',
    projectId,
    {
      filter: { model: projectId, env: componentId },
      pagination: { page: 1, perPage: nextPerPage },
      componentId,
      fields,
      sort,
    },
  ));
}


function* onRequestToActualizeTestAndProd(a: Actions[ 'requestToActualizeTestAndProd' ]): Gen {
  const { projectId } = a.payload;

  yield put(aCreators.requestToActualizeReleases({
    type: 'paginationUnchanged',
    componentId: 'test',
    projectId,
  }));

  yield put(aCreators.requestToActualizeReleases({
    type: 'maybePlusOnePagination',
    componentId: 'prod',
    projectId,
  }));
}

function* onRequestToLoadMoreReleases(a: Actions[ 'requestToLoadMoreReleases' ]): Gen {
  const { componentId, projectId } = a.payload;
  const state = yield select();

  const list = extractReleaseList(state, projectId, componentId);
  if(!list) return;
  if(list.ids.length >= list.total) return;

  yield put(crudGetList(
    'releases',
    projectId,
    {
      filter: { model: projectId, env: componentId },
      pagination: { page: 1, perPage: list.params.pagination.perPage + PER_PAGE },
      componentId,
      fields,
      sort,
    },
  ));
}

// ===============================================================================================

export function* saga(): Gen {
  yield all([
    takeLatest(aTypes.requestToPopulateProjectReleaseCentre, onRequestToPopulateReleaseCentre),
    takeLatest(aTypes.requestToActualizeReleases, onRequestToActualizeReleases),
    takeLatest(aTypes.requestToActualizeTestAndProd, onRequestToActualizeTestAndProd),
    takeLatest(aTypes.requestToLoadMoreReleases, onRequestToLoadMoreReleases),
  ]);
}
