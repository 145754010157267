

// keys are releaseIds
// `expanded` are paths to folders "open in tree view"
// `filename` is full path to open filename
/**
@typedef {
  Record< string, (
    | { type: 'expandedOnly', expanded: string[] }
    | { type: 'filenameOnly', filename: string }
    | { type: 'all', expanded: string[], filename: string }
  ) >
} IState
*/

export const REDUX_PROP = 'buildDocumentsState';
/** @type { ( s: unknown ) => IState } */
export const getState = s => s[REDUX_PROP];


/**
@type {{
  setReleaseInfo: 'buildDocumentsState/setReleaseInfo';
  requestToSetReleaseInfo: 'buildDocumentsState/requestToSetReleaseInfo';
}}
 */
export const ActionTypes = {
  setReleaseInfo: 'buildDocumentsState/setReleaseInfo',
  requestToSetReleaseInfo: 'buildDocumentsState/requestToSetReleaseInfo',
};

/**
@typedef {{
  setReleaseInfo: {
    type: typeof ActionTypes.setReleaseInfo;
    payload: {
      releaseId: string,
      info: IState[ keyof IState ];
    }
  }
  requestToSetReleaseInfo: {
    type: typeof ActionTypes.requestToSetReleaseInfo;
    payload: IState[ keyof IState ];
  }
}} IActions
 */


/**
@type {{
  setReleaseInfo: ( arg: IActions[ 'setReleaseInfo' ][ 'payload' ] ) => IActions[ 'setReleaseInfo' ];
  requestToSetReleaseInfo: ( arg: IActions[ 'requestToSetReleaseInfo' ][ 'payload' ] ) => IActions[ 'requestToSetReleaseInfo' ];
}}
 */
export const ActionCreators = {
  setReleaseInfo: payload => ({
    type: ActionTypes.setReleaseInfo,
    payload,
  }),
  requestToSetReleaseInfo: payload => ({
    type: ActionTypes.requestToSetReleaseInfo,
    payload,
  }),
};


/** @type { IState } */
export const defaultState = {};


/**
@type {(
   s: typeof defaultState | undefined,
   a: IActions[ keyof IActions ]
) => NonNullable< typeof s > }
 */
export const reducer = (s = defaultState, a) => {
  switch (a.type) {
    case ActionTypes.setReleaseInfo:
      return {
        ...s,
        [a.payload.releaseId]: a.payload.info,
      };

    default:
      return s;
  }
};
