import React, { Ref, useEffect, useRef, useState } from "react";
import { LoadingDots, SearchIcon } from "@icons";
import { Paper } from "@material-ui/core";
import { VariableSizeList as List } from 'react-window';
import cn from "clsx";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Search } from "../input";
import { withStyles } from "../../common/Styling";
import { useToggle } from "../../common/hooks/HooksGeneral";
import { isStrNotNullOrBlank } from "../util/UtilString";
import stl from "./SearchAndSelect.module.scss";
import { useTranslation } from "react-i18next";

const useDynamicList = () => {
  const [, setRender] = useState(0);
  const listRef = useRef<any>(null);
  const rowHeights = useRef({});

  const getRowHeight = (index: number) => rowHeights.current[index] ?? 100;
  const setRowHeight = (index: number, size: number) => {
    const current = rowHeights.current[index];
    // don't change and re-render if the size is the same
    if (current === size) return;
    listRef.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
    setRender((prev) => prev + 1); // increment render count to force re-render
  };

  return { listRef, rowHeights, getRowHeight, setRowHeight };
};

interface SearchAndSelectProps<T> {
  appearance: "default" | "compact";
  alignPopover?: "right" | "left";
  placeholder?: string;
  value?: string;
  onChange?: (value: string) => void;
  onEnter?: () => void;
  onClear?: () => void;
  /** where [] is "no results" and null is "no search performed yet" */
  searchResults: T[] | null;
  renderResultItem: (item: T, ref: Ref<any>) => React.ReactNode;
}

/**
 * Reusable search with select popover control. The following should be implemented by the parent:
 * - generation of the search results
 * - rendering of each individual result item
 *
 * See _SearchDocuments.tsx_ for an example of how to use this component.
 */
const SearchAndSelect = <T,>({
  placeholder,
  value,
  onChange,
  onEnter,
  onClear,
  appearance,
  alignPopover,
  searchResults,
  renderResultItem,
  classes,
}: SearchAndSelectProps<T> & { classes: any }) => {
  const isHovered = useToggle(false);
  const isFocused = useToggle(false);
  const [open, setOpen] = useState(() => isStrNotNullOrBlank(value));
  const isShowInput = isHovered.value || isFocused.value;
  const alignPopoverDefaulted = alignPopover || "right";

  const inputValue = value || "";
  const setInputValue = (newValue?: string) => {
    if (onChange) {
      onChange(newValue || "");
    }
  };

  useEffect(() => {
    setOpen(isStrNotNullOrBlank(inputValue));
  }, [searchResults, inputValue]);

  const onClearInput = () => {
    setInputValue("");
    setOpen(false);
    if (onClear) {
      onClear();
    }
  };

  const defocusCtrl = () => {
    isFocused.forceFalse();
    onClearInput();
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setInputValue(e.target.value);
  };

  const renderCompactSearch = () => {
    return (
      <div
        className={cn(
          stl.container,
          { [stl.active]: isShowInput },
        )}
        onMouseEnter={isHovered.forceTrue}
        onMouseLeave={isHovered.forceFalse}
        onFocus={isFocused.forceTrue}
        onBlur={defocusCtrl}
      >
        <input
          className={cn(
            stl.searchInput,
            { [stl.active]: isShowInput },
          )}
          type="text"
          placeholder={placeholder || "Search..."}
          value={inputValue}
          onChange={onChangeInput}
          onKeyDown={(event) => {
            if (event.key === "Escape") {
              defocusCtrl();
            } else if (event.key === "Enter" || event.key === "NumpadEnter") {
              event.preventDefault();
              event.stopPropagation();
              if (onEnter) {
                onEnter();
              }
            }
          }}
        />
        {
          isShowInput
            ? <ArrowForwardIcon className={stl.iconExpanded} />
            : <SearchIcon className={stl.iconCollapsed} />
        }
      </div>
    );
  };

  const renderDefaultSearch = () => {
    return (
      <Search
        value={inputValue}
        setValue={setInputValue}
        onClear={onClearInput}
        placeholder={placeholder || "Search..."}
        icon={<SearchIcon />}
        onKeyDown={(event) => {
          if (event.key === "Escape") {
            onClearInput();
          } else if (event.key === "Enter" || event.key === "NumpadEnter") {
            event.preventDefault();
            event.stopPropagation();
            if (onEnter) {
              onEnter();
            }
          }
        }}
      />
    );
  };

  const renderPopoverContent = () => {
    const { t } = useTranslation();
    const { listRef, getRowHeight, setRowHeight } = useDynamicList();

    const height = () => {
      if (searchResults && searchResults.length > 0) {
        let calc = 0;
        for (let i = 0; i < searchResults.length; i++) {
          calc += getRowHeight(i);
          if (calc > 600) {
            return 600;
          }
        }
        return calc;
      }
      return 600;
    };

    if (searchResults && searchResults?.length > 0) {
      return (
        <List ref={listRef} height={height()} itemCount={searchResults.length} itemSize={getRowHeight} width={532}>
          {({ index, style }) => {
            const rowRef = useRef<HTMLDivElement>(null);
            useEffect(() => {
              if (rowRef.current) {
                setRowHeight(index, rowRef.current.clientHeight);
              }
            }, [rowRef]);

            return (
              <div key={index} style={style} className={stl.rowItem}>
                {renderResultItem(searchResults[index], rowRef)}
              </div>
            );
          }}
        </List>
      );
    }

    if (searchResults === null && inputValue.length > 0) {
      // we are loading the results
      return (
        <div className={stl.noResults}>
          <LoadingDots />
        </div>
      );
    }

    return (
      <div className={stl.noResults}>
        {t('no_results')}
      </div>
    );
  };

  return (
    <div className={cn(classes.root, stl.wrapper)}>
      {
        appearance === "compact"
          ? renderCompactSearch()
          : renderDefaultSearch()
      }
      <Paper
        elevation={0}
        variant="outlined"
        className={cn(
          stl.popoverContainer,
          { [stl.active]: open },
          { [stl.alignRight]: (appearance === "default" && alignPopoverDefaulted === "right") },
          { [stl.alignLeft]: (appearance === "default" && alignPopoverDefaulted === "left") },
        )}
      >
        {renderPopoverContent()}
      </Paper>
    </div>
  );
};

// the ugly typing is there because withStyles doesn't play nice with a generic component
// and I didn't want to introduce an HOC to work around it
export default (withStyles((theme: any) => ({
  root: {
    "--accent-color": theme.palette.primary.main,
    "--muted-color": theme.palette.background.darkBorder,
    "--ctl-background-color-collapsed": theme.palette.secondary.main,
    "--ctl-border-color-collapsed": theme.palette.secondary.contrastText,
  },
}))(SearchAndSelect)) as new <T>(props: SearchAndSelectProps<T>) => React.Component<SearchAndSelectProps<T>>;
