//@ts-nocheck - complains about change to ConcatArray in the useMemo - but can't find this type - I hate TS
import React, { useEffect } from 'react';
import styled from 'styled-components';
import dagre from 'dagre';
import { DropdownMenu, Menu } from '@components';
import { scrollableMixin } from '@common';
import { Chip, ListItemText } from '@material-ui/core';
import { groupGraphNodes } from '@common/graph';


const StyledDropDown = styled(DropdownMenu)`
  width: 100%;
  border-color: ${ props => (props.error ? props.theme.palette.error.main : 'inherit') };
`;

const MenuWrap = styled.div`
  >div>div:nth-of-type(2) {
    overflow: auto;
    ${ scrollableMixin };
  }
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .MuiListItemText-root {
    margin: 0;
  }

  .MuiChip-root {
    margin-left: 0.5rem;
    cursor: pointer;
  }
`;

type NodeTextProps = {
  node: {
    description: string;
    entity: string;
  }
}
const primaryTypographyProps = { noWrap: true };
const NodeText = React.forwardRef< HTMLDivElement, NodeTextProps >(({ node, ...props }, ref) => (
  <TextContainer ref={ref} {...props}>
    <ListItemText primary={node.description} primaryTypographyProps={primaryTypographyProps} />
    <Chip variant='outlined' size='small' label={node.entity} />
  </TextContainer>
));
NodeText.displayName = 'models/release/GoalMenuV3/NodeText';

export type Props = {
  goalId: string | null;
  onGoalIdChange: (s: string) => unknown;
  graph: any;
  includeInputs?: boolean;
  disabled?: boolean;
  disabledNodes?: Set<String>,
  placeHolder?: string
}


export const GoalMenu: React.FC< Props > = React.memo(p => {
  const {
    goalId,
    graph,
    onGoalIdChange,
    // allowEmpty = false,
    disabled,
    disabledNodes,
    placeHolder,
    includeInputs
    // error = false,
  } = p;
  if (!graph) return null;

  // Depends if the graph has already been generated
  const _graph = React.useMemo(
    () => (typeof graph.nodes === 'function'
      ? graph
      : dagre.graphlib.json.read(graph)),
    [graph],
  );

  const nodes = React.useMemo(() => {
    const { goals, derived, inputs } = groupGraphNodes(_graph);
    return goals.map(n => ({
      id: n.id,
      name: n.description,
      text: <NodeText node={n} />,
      type: 'goal',
      disabled: disabledNodes ? disabledNodes.has(n.id) : false
    }))
      .concat(derived.map(n => ({
        id: n.id,
        name: n.description,
        text: <NodeText node={n} />,
        type: 'intermediate',
        disabled: disabledNodes ? disabledNodes.has(n.id) : false
      })))
      .concat(includeInputs ? inputs.map(n => ({
        id: n.id,
        name: n.description,
        text: <NodeText node={n} />,
        type: 'input',
        disabled: disabledNodes ? disabledNodes.has(n.id) : false
      })) : []);
  }, [_graph]);

  const currentGoal = React.useMemo(
    () => nodes.find(it => it.id === goalId)?.name || null,
    [nodes, goalId],
  );

  let groupBy = [{
    name: 'Goal',
    filter: i => i.type === 'goal'
  },{
    name: 'Intermediate',
    filter: i => i.type === 'intermediate'
  }];
  if (includeInputs) groupBy.push({
    name: 'Input',
    filter: i => i.type === 'input'
  });

  return (
    <StyledDropDown
      value={currentGoal || placeHolder}
      disabled={disabled}
      // error={error}
    >
      {({ close }) => {
        return (
          <MenuWrap>
            <Menu
              onOptionClick={g => {
                onGoalIdChange(g.id);
                close();
              }}
              search
              width={500 as any}
              height={400 as any}
              groupBy={groupBy as any}
              items={nodes}
              actions={undefined}
              className={undefined}
            />
          </MenuWrap>
        );
      }}
    </StyledDropDown>
  );
});
GoalMenu.displayName = 'models/release/GoalMenuV3';
