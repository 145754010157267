const loadConfig = () => {
  let cfg = {};
  if (window.__ENV__) {
    // These come from the docker run time.
    // We can't use env as they get replaced at build time by the build process in react
    cfg = window.__ENV__;
  } else {
    // See if we can grab from env (which are injected during development)

    // vite config
    if (import.meta?.env?.DEV) {
      Object.keys(import.meta.env).forEach((key) => {
        if (key.startsWith('VITE_')) {
          const newKey = key.replace('VITE_', '');
          cfg[newKey] = import.meta.env[key];
        }
      });
    }

    // set for later use
    window.__ENV__ = cfg;
  }

  console.log('CONFIG', cfg);
  return cfg;
};

export const config = loadConfig();

/** FLAG for something to only work on local dev */
export const IS_DEV = import.meta && import.meta.env && import.meta.env.DEV;