import React, { useCallback, useState } from "react";
import { useStore } from "react-redux";
import { v4 as uuidv4 } from "uuid";

export const useToggle = (
  initalState = false,
): {
  value: boolean;
  toggle: () => void;
  forceTrue: () => void;
  forceFalse: () => void;
  setState: (val: boolean) => void;
} => {
  const [state, setState] = React.useState<boolean>(initalState);
  const toggle = () => setState(!state);

  const forceTrue = () => setState(true);
  const forceFalse = () => setState(false);

  return { value: state, toggle, forceTrue, forceFalse, setState };
};

export const useGetter = <T>(input: T): (() => T) => {
  const inputRef = React.useRef(input);
  inputRef.current = input;

  return React.useCallback(() => inputRef.current, []);
};

export const useForceUpdate = (): [() => void, string] => {
  const [key, setKey] = useState(uuidv4());

  const forceUpdate = useCallback(() => {
    setKey(uuidv4());
  }, []);

  return [forceUpdate, key];
};
