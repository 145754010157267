import graphlib from "@dagrejs/graphlib";
import { isTemporal } from "../../pages/decisions/DecisionDashboard/Reports/Details/UnifiedTimeline/types";
import { mergeValueAndDatesFilter } from "./DatesFilter_mergeValueAndDatesFilter";
import type { Props as DatesFilterProps } from "./DatesFilter_types";
import type { ParsedRuleGraph, RawRuleGraph } from "@packages/commons";


export const parseRawGraph = (rawGraph: RawRuleGraph, datesFilter?: DatesFilterProps["value"]) => {
  const [start, end] = datesFilter || [null, null];

  // if empty object, return empty graph
  if (!rawGraph || Object.keys(rawGraph).length === 0) {
    return new graphlib.Graph();
  }

  const parsedGraph: ParsedRuleGraph = graphlib.json.read(
    start === null || end === null ? rawGraph : (({
      ...rawGraph,
      nodes: rawGraph.nodes.map(itRaw => {
        if (typeof itRaw !== "object" || itRaw === null) return itRaw;

        const it = itRaw as Record< string, any >;
        const derived = it?.value?.derived;

        if (!isTemporal(derived)) return it;

        return {
          ...it,
          value: { ...it.value, derived: mergeValueAndDatesFilter(derived, datesFilter) },
        };

      }),
    })),
  ) as ParsedRuleGraph;

  return parsedGraph;
};
