import styled from "styled-components";
import Typography, { TypographyProps } from "@material-ui/core/Typography";
import { omit } from "lodash";
import { isValidElement, ReactNode } from "react";

const EmptyImage = styled.img`
  margin-bottom: 1rem;
`;

const EmptyWrapper = styled.div`
  flex-flow: column nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const Text = styled(Typography)`
  /* margin-top: 1rem; */
  text-align: center;
`;

type MessageObject = {
  text: string;
} & Omit<TypographyProps, "children">;

type Message = string | ReactNode | MessageObject;

interface EmptyContentProps {
  messages: Message[];
  img?: string | null;
  variant?: TypographyProps["variant"];
  width?: string;
}

export const EmptyContent = ({ messages, img = "/images/add-files.svg", variant = "h3", width = "200px" }: EmptyContentProps) => (
  <EmptyWrapper>
    {img ? <EmptyImage style={{ width }} src={img} /> : null}
    {
      messages.map((msg, i) => {
        if (isValidElement(msg)) return msg;

        if (typeof msg === "string") {
          return (
            <Text key={i} variant={variant}>
              {msg || "No Records"}
            </Text>
          );
        }

        const { text, ...props } = msg as MessageObject;
        return (
          <Text key={i} {...props}>
            {text || "No Records"}
          </Text>
        );
      }
      )
    }
  </EmptyWrapper>
);
