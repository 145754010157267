import React, { forwardRef } from "react";
import { withStyles } from "@material-ui/core";
import { ButtonProps, default as MuiButton } from "@material-ui/core/Button";
import { default as MuiCheckbox } from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import { LoadingDots } from "@icons";

import { StaremptyIcon, StarfilledIcon, CheckIcon, IndeterminateIcon, LockedIcon, UnlockedIcon } from "@icons";

const getTypeProps = type => {
  switch (type) {
    case "secondary":
      return { variant: "contained", color: "secondary" };
    case "tertiary":
      return { variant: "text" };
    default:
      return { variant: "contained", color: "primary" };
  }
};

const getErrorVariants = (theme, variant) => {
  switch (variant) {
    case "outlined":
      return {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        "&:hover": {
          color: theme.palette.error.main,
          borderColor: theme.palette.error.main,
          backgroundColor: theme.palette.error.light
        },
      };
    case "text":
      return {
        color: theme.palette.error.main,
        "&:hover": {
          backgroundColor: theme.palette.error.light,
        },
      };
    default: // contained
      return {
        color: theme.palette.error.contrastText,
        backgroundColor: theme.palette.error.main,
        "&:hover": {
          backgroundColor: theme.palette.error.dark
        },
      };
  }
};

const getWarningVariants = (theme, variant) => {
  switch (variant) {
    case "outlined":
      return {
        color: theme.palette.warning.main,
        borderColor: theme.palette.warning.main,
        "&:hover": {
          color: theme.palette.warning.main,
          borderColor: theme.palette.warning.main,
          backgroundColor: theme.palette.warning.light
        },
      };
    case "text":
      return {
        color: theme.palette.warning.main,
        "&:hover": {
          backgroundColor: theme.palette.warning.light,
        },
      };
    default: // contained
      return {
        color: theme.palette.warning.contrastText,
        backgroundColor: theme.palette.warning.main,
        "&:hover": {
          backgroundColor: theme.palette.warning.dark
        },
      };
  }
};

interface ButtonCustomProps {
  error?: boolean;
  warning?: boolean;
  noBgColor?: boolean;
  loading?: boolean;
};

// type CustomButton = ButtonProps & ButtonCustomProps;
// type CustomButton<C extends React.ElementType = any> = ButtonProps<C, ButtonCustomProps>;
type CustomButton<C extends React.ElementType = any> = ButtonProps<C, { component?: C }> & ButtonCustomProps;

export const Button = withStyles<"root", {}, CustomButton>(theme => ({
  root: (props) => ({
    ...(
      props.error
        ? getErrorVariants(theme, props.variant)
        : props.warning
          ? getWarningVariants(theme, props.variant)
          : { color: "currentColor" }
    ),
    ...(props.noBgColor ? { "&&&": { backgroundColor: "transparent" } } : {}),
  }),
}))(
  forwardRef<any, any>(({ type, children, error, warning, loading, noBgColor, ...props }, ref) => (
    // @ts-ignore - go away TS
    <MuiButton ref={ref} fullWidth={false} {...getTypeProps(type)} {...props as ButtonProps}>
      {loading ? <LoadingDots /> : children}
    </MuiButton>
  ))
);

export const PrimaryButton = ({ children, ...props }) => (
  <MuiButton variant='contained' color='primary' fullWidth={false} {...props}>
    {children}
  </MuiButton>
);

export const PrimaryLoadingButton = ({ children, loading = false, ...props }) => (
  <MuiButton variant='contained' color='primary' fullWidth={false} {...props}>
    {loading ? <LoadingDots /> : children}
  </MuiButton>
);

export const SecondaryButton = ({ children, ...props }) => (
  <MuiButton variant='contained' color='secondary' fullWidth={false} {...props}>
    {children}
  </MuiButton>
);

export const TertiaryButton = ({ children, ...props }) => (
  <MuiButton variant='text' {...props}>
    {children}
  </MuiButton>
);

export const LinkButton = forwardRef<any, any>(({ children, ...props }, ref) => (
  <Link ref={ref} color='secondary' component='button' underline='always' {...props}>{children}</Link>
));

export const Favorite = props => <MuiCheckbox icon={<StaremptyIcon />} checkedIcon={<StarfilledIcon />} {...props} />;
export const Lock = props => (
  <MuiCheckbox disableRipple icon={<UnlockedIcon />} checkedIcon={<LockedIcon />} {...props} />
);

export const Checkbox = withStyles(theme => ({
  root: {
    // cant use border as it will override color
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "0.25rem",
    padding: 0,
    width: "1.5rem",
    height: "1.5rem",
    color: theme.palette.secondary.contrastText,
    borderColor: theme.palette.secondary.light,
    backgroundColor: theme.palette.secondary.contrastText,
    "&:hover": {
      borderWidth: "2px",
      borderColor: theme.palette.secondary.main,
      backgroundColor: theme.palette.secondary.contrastText,
    },
  },
  checked: {
    color: `${theme.palette.secondary.contrastText}!important`,
    borderColor: theme.palette.secondary.main,
    backgroundColor: `${theme.palette.secondary.main}!important`,
  },
  indeterminate: {
    borderColor: theme.palette.secondary.main,
    backgroundColor: `${theme.palette.secondary.main}!important`,
  },
}))(forwardRef<any, any>((props, ref) => (
  <MuiCheckbox ref={ref} icon={<div />} checkedIcon={<CheckIcon />} indeterminateIcon={<IndeterminateIcon />} {...props} />
)));
