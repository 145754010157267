import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from "styled-components";
import { Typography, Link } from "@material-ui/core";
import { signIn } from '@imminently/imminently_platform';
import { Button } from "@components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 2rem 4rem;
  margin: auto;
  border-radius: 1rem;
  border: 1px solid ${p => p.theme.palette.background.border};
`;

const SignIn = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(signIn());
    }, 2000);
  }, []);

  return (
    <Wrapper>
      <Typography component="h1" variant="h1" align="center">
        Redirecting
      </Typography>
      <Typography component="h2" variant="body1" align="center">
        Redirecting to our login server. If you are not redirected in a few seconds, please return to the site and try again
      </Typography>
      <Button component={Link} to="/" type="primary">
        Return to website
      </Button>
    </Wrapper>
  );
}

export default SignIn;


