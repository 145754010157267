import { EmptyContent } from "@common/EmptyContent";
import { Pane, SplitPane } from "@common/SplitPane";
import { RuleEditor, createRulePlugins } from "@common/editor/RuleAuthor/RuleEditor";
import { HistoryToolbar, RulesToolbar } from "@common/editor/RuleAuthor/toolbar/components";
import {
  type SectionData,
  SectionProvider,
  createSection,
  createSectionPlugin,
} from "@common/editor/components/section";
import { ToolbarWithBorderBottom } from "@common/editor/plugins/muiToolbar";
import { scrollable } from "@common/scrollbar";
import { ExperimentalLabel } from "@components/meta";
import { hideModal, showModal } from "@imminently/imminently_platform";
import { LinearProgress } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { type Action, ModalActions } from "@modals";
import type { Attribute } from "@packages/compiler";
import { useCurrentDocument } from "@pages/documents";
import { deserializeFuncForSuggestRulesModal } from "@pages/documents/documents.slice.constants";
import {
  FloatingVerticalDivider,
  PlateProvider,
  type PlateProviderProps,
  type TElement,
  type Value,
} from "@udecode/plate";
import { createAppResourceService, global } from "global";
import { once } from "lodash";
import get from "lodash/get";
import React, { Suspense } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { getSourceText } from "../getSourceText";
import { SuggestRulesLeftPane } from "./LeftPane";
import { type ResponseState, getRulesFromSuccessAiResponse, isSuccessAiResponse } from "./constants";

const createRightHandSidePlugins = once(() =>
  createRulePlugins([
    createSectionPlugin({
      options: { disableSuggestModals: true },
    }),
  ]),
);

const EditorPanes = styled(SplitPane)`
  border: 1px solid ${(p) => p.theme.palette.background.border};
  border-radius: 0.5rem;
  flex: 1;
  max-height: calc(100% - 3.5rem);

  [data-slate-editor="true"] {
    padding: 1rem 0;
  }
`;

const Toolbar = styled(ToolbarWithBorderBottom)`
  overflow: visible hidden;
  min-height: 3.5rem;
`;

export type SuggestSection = SectionData & {
  source: TElement;
};

const StyledModalActions = styled(ModalActions)`
  justify-content: flex-end;
  gap: 1rem;
`;

type SuggestRulesModalProps = {
  releaseId: string;
  sections: SuggestSection[];
  onInsert: (section: SuggestSection, rules: TElement[]) => unknown;
};

const SuggestRulesModal = React.memo<SuggestRulesModalProps>(({ releaseId, onInsert, sections }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [sourceLocal, setSourceLocal] = React.useState<Value>(() => {
    return sections.flatMap((section) => section.source);
  });
  const sourceLocalRef = React.useRef(sourceLocal);
  if (sourceLocalRef.current !== sourceLocal) sourceLocalRef.current = sourceLocal;

  const [prompt, setPrompt] = React.useState("");
  React.useEffect(() => {
    global.client("/auth/getPrompt").then((res) => {
      setPrompt(String(get(res, ["data", "content"], "")));
    });
  }, [setPrompt]);

  const [responseState, setResponseState] = React.useState<ResponseState>({ type: "initial" });

  // We do not yet support custom functions with id, workaround is use the subresource system
  const relativeReleaseService = React.useMemo(
    () => createAppResourceService<any>(`releases/${releaseId}`),
    [releaseId],
  );

  const [loadingCount, setLoadingCount] = React.useState(0);
  const [successCount, setSuccessCount] = React.useState(0);
  const { isLoading, mutateAsync } = relativeReleaseService.usePostCustom("suggestRules");

  const close = React.useCallback(() => dispatch(hideModal()), [dispatch]);

  const primaryAction = React.useMemo<Action>(
    () => ({
      name: t("insert"),
      onClick: () => {
        if (responseState.type === "success") {
          for (let i = 0; i < sections.length; i++) {
            const section = sections[i];
            const rules = responseState.rules[i];
            if (rules) {
              onInsert(section, rules.children as any);
            }
          }
        }

        close();
      },
      disabled: isLoading,
    }),
    [responseState, isLoading, onInsert],
  );

  const secondaryActions = React.useMemo<Action[]>(
    () => [
      {
        name: t("generate"),
        onClick: async () => {
          setLoadingCount(0);
          setSuccessCount(0);

          let newAttributes: Array<Attribute> = [];

          for (const section of sections) {
            const sectionElement = sourceLocalRef.current.find((el) => el.id === section.id);
            if (!sectionElement) throw new Error(t("errors.section_missing"));

            const source = getSourceText(sectionElement);

            setLoadingCount((i) => i + 1);
            try {
              if (!source?.trim()) {
                setSuccessCount((i) => i + 1);
                setResponseState((responseState) => {
                  const existingRules = responseState.type === "success" ? responseState.rules : [];

                  const existingSectionElement = existingRules.find((el) => el.id === section.id);

                  let newRules: any[];

                  if (existingSectionElement) {
                    existingSectionElement.children = [];
                    newRules = existingRules;
                  } else {
                    const sectionElement = createSection(section.id, [
                      {
                        type: "paragraph",
                        children: [{ text: "" }],
                      },
                    ]);
                    newRules = [...existingRules, sectionElement];
                  }

                  return {
                    type: "success",
                    value: {
                      rules: [],
                    } as any,
                    rules: newRules,
                    key: String(Math.random()),
                  };
                });
                continue;
              }

              const res = await mutateAsync({ source, attributes: newAttributes });
              if (isSuccessAiResponse(res)) {
                newAttributes = res.newAttributes;
                let rules = deserializeFuncForSuggestRulesModal({ rules: getRulesFromSuccessAiResponse(res) })
                  .rules as TElement[];

                if (Array.isArray(rules) && rules.length === 1) {
                  const rule = rules[0];
                  if (rule.type === "section") {
                    rules = rule.children as TElement[];
                  }
                }

                setSuccessCount((i) => i + 1);
                setResponseState((responseState) => {
                  const existingRules = responseState.type === "success" ? responseState.rules : [];

                  const existingSectionElement = existingRules.find((el) => el.id === section.id);

                  let newRules: any[];

                  if (existingSectionElement) {
                    existingSectionElement.children = rules;
                    newRules = existingRules;
                  } else {
                    const sectionElement = createSection(section.id, rules);
                    newRules = [...existingRules, sectionElement];
                  }

                  return {
                    type: "success",
                    value: res,
                    rules: newRules,
                    key: String(Math.random()),
                  };
                });
              }
            } catch (e) {
              setResponseState({ type: "error" });
              break;
            }
          }
        },
        disabled: isLoading,
        loading: isLoading,
      },
    ],
    [mutateAsync, responseState, isLoading],
  );

  const paneProps = React.useMemo(
    () => ({
      sizes: [50, 50],
      minSize: [500, 500],
      gutterStyle: () => ({ display: "block", width: "8px" }),
    }),
    [],
  );

  const rules = responseState.type === "success" ? responseState.rules : [];
  const plateProviderKey = responseState.type === "success" ? responseState.key : "0";
  const setRules = React.useCallback<NonNullable<PlateProviderProps["onChange"]>>(
    (v) => {
      setResponseState((prev) => {
        if (prev.type !== "success") return prev;

        return { ...prev, rules: v };
      });
    },
    [setResponseState],
  );

  return (
    <>
      <EditorPanes
        {...paneProps}
        snapOffset={0}
      >
        <SuggestRulesLeftPane
          sourceLocal={sourceLocal}
          setSourceLocal={setSourceLocal}
          prompt={prompt}
          setPrompt={setPrompt}
        />

        <Pane className={scrollable}>
          {(() => {
            if (responseState.type === "success") {
              return (
                <Suspense fallback={<pre>{JSON.stringify(rules, null, 2)}</pre>}>
                  <PlateProvider
                    id={"suggest-rules-editor"}
                    plugins={createRightHandSidePlugins()}
                    value={rules}
                    onChange={setRules}
                    key={plateProviderKey}
                  >
                    <Toolbar>
                      <HistoryToolbar />
                      <FloatingVerticalDivider />
                      <RulesToolbar editorId="suggest-rules-editor" />
                    </Toolbar>

                    <RuleEditor
                      id={"suggest-rules-editor"}
                      editableProps={{ autoFocus: true, spellCheck: false }}
                    />
                  </PlateProvider>
                </Suspense>
              );
            }

            const message = (() => {
              if (isLoading) return t("ai.generating_message");

              return responseState.type === "error"
                ? t("errors.source_generation_error_1")
                : t("errors.source_generation_error_2");
            })();

            return (
              <EmptyContent
                width="100px"
                messages={[message]}
              />
            );
          })()}
        </Pane>
      </EditorPanes>

      <Box
        width={"100%"}
        gridGap={"1rem"}
        justifyContent={"space-between"}
        alignItems={"center"}
        display={"flex"}
        flexDirection={"row"}
      >
        <div>
          {successCount} / {sections.length}
        </div>
        <LinearProgress
          variant={isLoading ? "buffer" : "determinate"}
          style={{ flex: 1 }}
          valueBuffer={(successCount / sections.length) * 100}
          value={(successCount / sections.length) * 100}
        />

        <StyledModalActions
          primary={primaryAction}
          secondary={secondaryActions}
        />
      </Box>
    </>
  );
});

export type OpenSuggestRulesModalFArg = SuggestRulesModalProps;

export type OpenSuggestRulesModalF = (arg: OpenSuggestRulesModalFArg) => unknown;

export const useOpenSuggestRulesModal = (): OpenSuggestRulesModalF => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const document = useCurrentDocument();

  if (!document) throw new Error(t("errors.suggest_rules_valid_doc"));

  return ({ releaseId, onInsert, sections }) => {
    const name = sections.length === 1 ? sections[0].name : t("ai.sections");

    dispatch(
      showModal(
        {
          open: true,
          title: (
            <Box
              display="flex"
              gridGap="1rem"
              alignItems="center"
            >
              <span>
                <Trans i18nKey="ai.suggest_rules_title" /> {name}
              </span>
              <ExperimentalLabel />
            </Box>
          ),
          maxWidth: "xl",
          height: "80vh",
          style: { gap: "1rem", maxHeight: "calc(100% - 4rem)" },
        },
        <SectionProvider documentId={document?.id}>
          <SuggestRulesModal
            releaseId={releaseId}
            onInsert={onInsert}
            sections={sections}
          />
        </SectionProvider>,
      ),
    );
  };
};
