import { config } from "../config";
import { put, call, all, takeLatest } from "redux-saga/effects";
import { SILENT_AUTH_CHECK, showNotification } from "@imminently/imminently_platform";
import { cloneDeep, set } from "lodash";

export const sessionCall = (modelId, releaseId, session, payload) => ({
  type: "DCSVLY/SESSIONCALL",
  release: releaseId,
  model: modelId,
  payload,
  session,
});

const host = config.BACKEND_URL;

export const SESSION_CALL = "DCSVLY/SESSIONCALL";

function* handleSessionCall(authProvider, action) {
  // Call the remote back API
  try {
    const validAuth = yield call(authProvider, "validAuth");
    if (!validAuth) {
      yield put({
        type: SILENT_AUTH_CHECK,
        forceLogin: true,
      });

      return false;
    }

    let headers = {
      "Content-Type": "application/json",
    };
    headers = authProvider("applyAuthentication", headers);
    headers["X-TENANCY"] = localStorage.getItem("decisively.tenant");

    if (!action.model || !action.release) throw { message: 'Invalid action' };
    let res;
    if (!action.session) {
      // We need to create a session
      yield put({
        type: "DCSVLY/SESSIONCALL_CREATE",
        release: action.release,
        model: action.model,
      });
      const uri = `${host}/decisionapi/session/${action.model}/${action.release}`;
      res = yield fetch(uri, {
        method: "POST",
        headers,
        body: JSON.stringify(action.payload),
      });
    } else {
      // Continuing a session
      yield put({
        type: "DCSVLY/SESSIONCALL_RESUME",
        release: action.release,
        session: action.session,
        model: action.model,
      });
      const uri = `${host}/decisionapi/session/${action.model}/${action.release}?session=${action.session}`;
      res = yield fetch(uri, {
        method: "PATCH",
        headers,
        body: JSON.stringify(action.payload),
      });
    }
    const payload = yield res.json();
    if (!res.ok) throw payload;
    yield put({
      type: "DCSVLY/SESSIONCALL_SUCCESS",
      release: action.release,
      session: payload.sessionId || payload.session,
      model: action.model,
      payload,
    });

    /*yield put({
      type: "DCSVLY/SESSIONCALL_END",
      release: action.release,
      session: payload.sessionId,
      model: action.model
    });*/
  } catch (error) {
    if (error.message) {
      yield put(showNotification(error.message, "error"));
    }
    yield put({
      type: "DCSVLY/SESSIONCALL_ERROR",
      release: action.release,
      session: action.session,
      model: action.model
    });
  }
}
const defaultSessionState = {};
export const sessionReducer = (previousState = defaultSessionState, action) => {
  let new_state;
  switch (action.type) {
    case "DCSVLY/SESSIONCALL_SUCCESS":
      new_state = cloneDeep(previousState);
      set(new_state, `${action.model}.${action.release}.${action.session}.record`, action.payload);
      set(new_state, `${action.model}.${action.release}.${action.session}.loading`, false);
      set(new_state, `${action.model}.${action.release}.loading`, false);
      set(new_state, `${action.model}.${action.release}.NEW_SESSION.lastId`, action.session);

      return new_state;
    case "DCSVLY/SESSIONCALL_END":
    case "DCSVLY/SESSIONCALL_ERROR":
      // let new_state = cloneDeep(previousState);
      new_state = cloneDeep(previousState);
      set(new_state, `${action.model}.${action.release}.${action.session}.loading`, false);
      set(new_state, `${action.model}.${action.release}.loading`, false);
      return new_state;
    case "DCSVLY/SESSIONCALL_RESUME":
      new_state = cloneDeep(previousState);
      set(new_state, `${action.model}.${action.release}.${action.session}.loading`, true);
      set(new_state, `${action.model}.${action.release}.loading`, true);

      return new_state;
    case "DCSVLY/SESSIONCALL_CREATE":
      // Issue with this is there is only NEW_SESSION allowed at a time
      new_state = cloneDeep(previousState);
      set(new_state, `${action.model}.${action.release}.NEW_SESSION.loading`, true);
      set(new_state, `${action.model}.${action.release}.loading`, true);
      return new_state;

    case "DCSVLY/SESSIONCALL_RESET": return defaultSessionState;

    /* case 'DCSVLY/TESTGEN_SUCCESS':

      new_state = cloneDeep(previousState);
      set(new_state, `${action.release}.${action.id}.record`, {cases: action.cases, attributes: action.attributes} );
      return new_state;
    */
    default:
      return previousState;
  }
};

export function* sessionSaga(authProvider) {
  yield all([
    takeLatest(
      action => action.type === "DCSVLY/SESSIONCALL",
      handleSessionCall,
      authProvider,
    )
  ]);
}
