import React from "react";
import cn from "clsx";
import { graphlib } from "dagre";

import stl from "./GraphNode.module.scss";

interface GraphNodeCountEarProps {
  parsedGraph: graphlib.Graph;
  currNodeId: string;
  isTargetNode: boolean;
  isDragging?: boolean;
  visibleNodeIds: string[] | null;
  addVisibleNodes: (nodeIds: string[]) => void;
  showHidden?: boolean;
}

const GraphNodeCountEar = (props: GraphNodeCountEarProps) => {

  const {
    parsedGraph,
    currNodeId,
    isTargetNode,
    visibleNodeIds, // if null then no restrictions are being applied, and we can assume the graph is fully expanded
    addVisibleNodes,
    showHidden = true,
    isDragging,
  } = props;
  const predecessorsAll = (parsedGraph.predecessors(currNodeId) || []) as unknown as string[];
  const successorsAll = (parsedGraph.successors(currNodeId) || []) as unknown as string[];
  const predecessors = showHidden ? predecessorsAll : predecessorsAll.filter((nodeId) => !((parsedGraph.node(nodeId) as any)?.hidden ?? false));
  const successors = showHidden ? successorsAll : successorsAll.filter((nodeId) => !((parsedGraph.node(nodeId) as any)?.hidden ?? false));

  const predecessorsExpanded = (() => {
    // if (!visibleNodeIds) {
    //   return false;
    // }
    if (0 === predecessors?.length || visibleNodeIds === null) {
      return true;
    }
    return predecessors.every((nodeId) => (visibleNodeIds || []).includes(nodeId));
  })();
  const successorsExpanded = (() => {
    // if (!visibleNodeIds) {
    //   return false;
    // }
    if (0 === successors?.length || visibleNodeIds === null) {
      return true;
    }
    return successors.every((nodeId) => (visibleNodeIds || []).includes(nodeId));
  })();

  const successorsNotClickable = (0 === (successors?.length || 0)) || successorsExpanded;
  const predecessorsNotClickable = (0 === (predecessors?.length || 0)) || predecessorsExpanded;

  // -- handlers

  const onClickExpandSuccessors = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (!successorsExpanded && successors?.length) {
      addVisibleNodes(successors);
    }
  };

  const onClickExpandPredecessors = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (!predecessorsExpanded && predecessors?.length) {
      addVisibleNodes(predecessors);
    }
  };

  // -- rendering

  return(
    <div
      id={`graph_ignoreclick_ear_${currNodeId}`}
      className={cn(
        stl.count_ear,
        {[stl.target_node]: isTargetNode},
        {[stl.dragging]: isDragging},
      )}
    >
      <div
        className={cn(
          stl.row,
          stl.upper,
          {[stl.expanded]: predecessorsExpanded},
          {[stl.not_clickable]: predecessorsNotClickable},
        )}
        onClick={onClickExpandPredecessors}
      >
        {predecessors?.length || "-"}
      </div>
      <div
        className={cn(
          stl.row,
          stl.lower,
          {[stl.expanded]: successorsExpanded || 0 === successors?.length},
          {[stl.not_clickable]: successorsNotClickable},
        )}
        onClick={onClickExpandSuccessors}
      >
        {successors?.length || "-"}
      </div>
    </div>
  );
};

export default (GraphNodeCountEar);
