import type { TestCaseSortTItem } from '../../types'


export type PredicateFindSortTreeArg = {
  treeItem: TestCaseSortTItem;
  predicate: ( treeItem: TestCaseSortTItem ) => boolean;
}
export type PredicateFindSortTreeRtrn = null | TestCaseSortTItem;
export const predicateFindSortTree = ( { predicate, treeItem }: PredicateFindSortTreeArg ): PredicateFindSortTreeRtrn => {
  if( predicate( treeItem ) ) return treeItem;
  if(treeItem.children.length === 0) return null;

  return treeItem.children.reduce< PredicateFindSortTreeRtrn >(
    (a, child) => a === null ? predicateFindSortTree( { predicate, treeItem: child } ) : a,
    null
  );
}
