/* eslint-disable react/sort-comp */

import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { DatePicker } from '@material-ui/pickers';
import { START_TIME } from '@components/TemporalValue/TempTimeline/redux';
import Select from '@material-ui/core/Select';
import { Button, Checkbox } from '@components';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';
import { valueTypes, NOT_VALID, DATE_PICKER_FORMAT } from './temporalOps';


/** @typedef { import( './temporalOps' ).TimePoint } TimePoint */
/** @typedef { import( './temporalOps' ).DirtyIncrement } DirtyIncrement */


/** @type { DirtyIncrement } */
const defaultIncrement = {
  increment: 'days',
  dirtyOffset: '',
};

// ===================================================================================


const valueTypeOptionsJSX = Object.keys(valueTypes)
  .map(key => valueTypes[key])
  .map(v => <MenuItem key={v} value={v}>{v}</MenuItem>);

/** @type { DirtyIncrement[ 'increment' ][] } */
const valuesOfIncrement = ['days', 'months', 'years'];
const optionsFromValuesOfIncrement = valuesOfIncrement
  .map(v => <MenuItem value={v} key={v}>{v}</MenuItem>);

// ===================================================================================


/**
  @typedef IProps
  @type {{
    open: boolean;
    finalize: () => unknown;
    discard: () => unknown;
    timePoint: TimePoint;
    updateTimePoint: ( t: IProps[ 'timePoint' ] ) => void;
    isEditMode?: true;
  }}
 */


const dialogTitleId = 'temporal-editor-dialog-title';
const NUMERIC_REGEXP = /^\d+(\.\d*)?$/;
/** @type { ( v: string ) => typeof v } */
const removePreceedingZeros = v => v.replace(/^0+/, '');

const Row = styled.div`
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
`;
const Title = styled(Typography)`
  width: 8rem;
`;
const Value = styled.div`
  flex-grow: 1;
  width: 13rem;
`;
const StyledSelect = styled(Select)`width: 100%;`;

/**
 * @augments { React.PureComponent< IProps > }
 */
export class EditTimePointDialog extends React.PureComponent {
  constructor(p) {
    super(p);

    // ===================================================================================

    this.setTime = this.setTime.bind(this);
    this.setValueTypeHandler = this.setValueTypeHandler.bind(this);
    this.setValueHandler = this.setValueHandler.bind(this);

    this.toggleHasOffset = this.toggleHasOffset.bind(this);
    this.setDirtyOffset = this.setDirtyOffset.bind(this);
    this.setIncrement = this.setIncrement.bind(this);
  }


  /**
    @type {
      (
        this: EditTimePointDialog,
        v: Parameters< React.ComponentProps< typeof DatePicker >[ 'onChange' ] > [ 0 ]
      ) => void
    }
  */
  setTime(e) {
    if (e === null) return;

    const {
      timePoint,
      updateTimePoint,
    } = this.props;

    updateTimePoint({
      ...timePoint,
      t: e.toISOString(),
    });
  }

  /** @type { ( this: EditTimePointDialog, v: React.ChangeEvent<{ name?: string; value: string }> ) => void } */
  setValueTypeHandler(e) {
    const type = /** @type { IProps[ 'timePoint' ][ 'type' ] } */(e.target.value);

    const { timePoint, updateTimePoint } = this.props;
    const { id, t } = timePoint;
    /** @type { Pick< typeof timePoint, 'id' | 't' > } */
    const common = { id, t };

    /** @type { typeof timePoint } */
    const nextTimepoint = (() => {
      switch (type) {
        case valueTypes.uncertain:
          return { ...common, type, v: null };
        case valueTypes.notValid:
          return { ...common, type, v: NOT_VALID };
        case valueTypes.boolean:
          return { ...common, type, v: false };
        case valueTypes.date:
          return { ...common, type, v: new Date().toISOString() };
        case valueTypes.number:
          return { ...common, type, v: 0, dirtyValue: '0' };
        case valueTypes.other:
          return { ...common, type, v: '' };
        case valueTypes.increment:
          return { ...common, type, v: defaultIncrement };
        default:
          return { ...common, type, v: undefined };
      }
    })();

    updateTimePoint(nextTimepoint);
  }

  /** @type { ( this: EditTimePointDialog, v: any ) => void } */
  setValueHandler(e) {
    const maybeCurrentTarget = e.currentTarget;

    const { timePoint, updateTimePoint } = this.props;

    /** @type { typeof timePoint } */
    const nextTimepoint = (() => {
      switch (timePoint.type) {
        case valueTypes.boolean:
          return { ...timePoint, v: maybeCurrentTarget.checked };
        case valueTypes.number:
          const { value } = maybeCurrentTarget;

          if (value !== '' && value.match(NUMERIC_REGEXP) === null) {
            return timePoint;
          }

          return {
            ...timePoint,
            v: Number(value),
            dirtyValue: removePreceedingZeros(value),
          };
        case valueTypes.date: {
          if (e === null) return timePoint;

          return {
            ...timePoint,
            v: e.toISOString(),
          };
        }
        case valueTypes.other:
          return {
            ...timePoint,
            v: maybeCurrentTarget.value,
          };
        default: return timePoint;
      }
    })();

    updateTimePoint(nextTimepoint);
  }


  /** @type { ( this: EditTimePointDialog ) => void } */
  toggleHasOffset() {
    const { timePoint, updateTimePoint } = this.props;

    if (timePoint.type !== valueTypes.increment) {
      return;
    }

    /** @type { typeof timePoint.v } */
    const nextV = timePoint.v.offset === undefined
      ? { ...timePoint.v, offset: 0, dirtyOffset: '0' }
      : { increment: timePoint.v.increment, dirtyOffset: '0' };

    updateTimePoint({ ...timePoint, v: nextV });
  }

  /** @type { ( this: EditTimePointDialog, e: React.ChangeEvent< HTMLInputElement > ) => void } */
  setDirtyOffset(e) {
    const { value: v } = e.currentTarget;
    const { timePoint, updateTimePoint } = this.props;


    if (timePoint.type !== valueTypes.increment) {
      return;
    }

    if (v !== '' && v.match(NUMERIC_REGEXP) === null) {
      return;
    }


    updateTimePoint({
      ...timePoint,
      v: {
        ...timePoint.v,
        dirtyOffset: removePreceedingZeros(v),
        offset: Number(v),
      },
    });
  }

  /** @type { ( this: EditTimePointDialog, v: React.ChangeEvent<{ name?: string; value: string }> ) => void } */
  setIncrement(e) {
    const increment = /** @type { DirtyIncrement[ 'increment' ] } */(e.target.value);
    const { timePoint, updateTimePoint } = this.props;

    if (timePoint.type !== valueTypes.increment) {
      return;
    }


    updateTimePoint({
      ...timePoint,
      v: {
        ...timePoint.v,
        increment,
      },
    });
  }


  // ===================================================================================

  render() {
    const {
      props: {
        finalize,
        discard,
        open,
        timePoint,
        isEditMode,
      },
      setValueTypeHandler,
      setTime,
      setValueHandler,
      toggleHasOffset,
      setIncrement,
      setDirtyOffset,
    } = this;

    const isBeginningOfTime = timePoint.t === START_TIME;

    return (
      <Dialog onClose={isEditMode ? finalize : discard} aria-labelledby={dialogTitleId} open={open}>
        <DialogTitle id={dialogTitleId}>
          <Typography variant='h3'>
            Edit
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Row>
            <Title variant='h5'>
              Time point
            </Title>
            <Value>
              {
                isBeginningOfTime
                  ? 'Beginning of Time'
                  : (
                    <DatePicker
                      format={DATE_PICKER_FORMAT}
                      value={timePoint.t}
                      onChange={setTime}
                    />
                  )
              }
            </Value>
          </Row>

          <Row>
            <Title variant='h5'>
              Type
            </Title>
            <Value>
              <StyledSelect
                value={timePoint.type}
                onChange={setValueTypeHandler}
              >
                {valueTypeOptionsJSX}
              </StyledSelect>
            </Value>
          </Row>

          {(() => {
            const value = (() => {
              switch (timePoint.type) {
                case valueTypes.boolean: return (
                  <Checkbox
                    checked={timePoint.v}
                    onChange={setValueHandler}
                  />
                );
                case valueTypes.other:
                  return <TextField value={timePoint.v} onChange={setValueHandler} />;
                case valueTypes.number:
                  return <TextField value={timePoint.dirtyValue} onChange={setValueHandler} />;
                case valueTypes.date:
                  return (
                    <DatePicker
                      format={DATE_PICKER_FORMAT}
                      value={timePoint.v}
                      onChange={setValueHandler}
                    />
                  );
                case valueTypes.increment:
                  const { v } = timePoint;
                  const hasOffset = timePoint.v.offset !== undefined;

                  return (
                    <div>
                      <StyledSelect value={v.increment} onChange={setIncrement}>
                        {optionsFromValuesOfIncrement}
                      </StyledSelect>

                      <div style={{ display: 'flex', marginTop: '1rem' }}>
                        <Typography
                          style={{ marginRight: '1rem' }}
                          variant='body1'
                        >
                          Has offset:
                        </Typography>

                        <Checkbox
                          checked={hasOffset}
                          onChange={toggleHasOffset}
                        />
                      </div>

                      {
                        hasOffset === false ? null : (
                          <TextField
                            value={v.dirtyOffset}
                            onChange={setDirtyOffset}
                          />
                        )
                      }

                    </div>
                  );
                default: return null;
              }
            })();

            if (value === null) return null;

            return (
              <Row>
                <Title variant='h5'>
                  Value
                </Title>
                <Value>{value}</Value>
              </Row>
            );
          })()}


          <br />

          <Button onClick={finalize}>
            Finalize
          </Button>
        </DialogContent>
      </Dialog>
    );
  }
}
EditTimePointDialog.displayName = 'TemporalEditor/EditTimePointDialog';
