/* eslint-disable camelcase */
import { SILENT_AUTH_CHECK, showNotification } from "@imminently/imminently_platform";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import { put, call, all, takeLatest } from "redux-saga/effects";
import { saga as AsyncSelectSaga } from "@components/asyncSelect/saga";
// import get from "lodash/get";
import getBatchHook from "./getBatchHook";
import getTestHook from "./getTestHook";
import { testgenCall, testgenReducer, testgenSaga } from "./testgensaga";
import { exportSaga, exportModel, exportRelease, exportTestData } from "./export.saga";
import { reducer as scopeReducer, saga as scopeSaga, useScope, setWorkspace, setProject, setRelease } from "./scope";
import * as buildDocumentsState from "./buildDocumentsState";
import * as loadMoreProjects from "./loadMoreProjects.saga";
import * as populateProjectReleaseCentre from "../pages/ProjectsReleaseCentre/redux";
import * as combinator from "./combinator.saga";
import * as combinatorV2 from "./combinatorV2.saga";
import * as decisionsRedux from "../pages/decisions/redux";
import * as loadProjectsForCurrentTotal from "./sagas/loadProjectsForCurrentTotal.saga";
import * as TempTimelineRedux from "@components/TemporalValue/TempTimeline/redux";
import * as GraphTestRedux from "../pages/models/release/Test/GraphTest/redux";
import * as BuildDebugRedux from "../pages/models/release/Debug/redux";
import designerReducer from "../pages/models/release/Documents/designer.slice";
import documentsReducer from "../pages/models/release/Documents/documents.slice";
import newDocumentsReducer, { newDocumentsName } from "../pages/documents/documents.slice";
import botsReducer from "../pages/bots/bots.slice";
import documentsSaga from "../pages/models/release/Documents/documents.saga";
import { isTemporal } from "../pages/decisions/DecisionDashboard/Reports/Details/UnifiedTimeline/types";
import * as deleteRelease from "../pages/ProjectsReleaseCentre/deleteRelease.saga";
import * as cleanUpRelease from "../pages/ProjectsReleaseCentre/cleanUpRelease.saga";
import * as workspacesNS from "./workspaces.saga";
import * as JsonInputV2Redux from "../pages/models/release/BatchDebug/redux/slice";
import * as decisionDesignerRedux from "../pages/models/release/Decision/redux";
import { config } from "../config";
import * as buildSidebarCollapsedNS from "../common/Sidebar/redux";
import * as BatchDebugSagaNs from "../pages/models/release/BatchDebug/redux/saga";
import * as interviewReduxNS from "../pages/models/release/Interview/redux";
import * as fullReleaseRedux from "./fullRelease";
import * as contextMenuNS from "./contextMenu";
import * as sessionNS from './session.saga';
import * as dataTableSagaNS from '../pages/models/release/Documents/DataTable/saga'
import * as TestCasesTreeReduxNS from '../components/TestCasesTree/redux'

const host = config.BACKEND_URL;

export const batchCall = (releaseId, id, payload, index = 0) => ({
  type: "DCSVLY/BATCHCALL",
  release: releaseId,
  id,
  payload,
  index,
});

export const DRY_BATCH_CALL = "DCSVLY/DRY_BATCHCALL";
export const dryBatchCall = (releaseId, id, payload, cb) => ({
  type: DRY_BATCH_CALL,
  release: releaseId,
  id,
  payload,
  cb,
});


// TODO: add error handling, and loading messages
function* handleBatchCall(authProvider, action) {
  // Call the remote back API
  try {
    const validAuth = yield call(authProvider, "validAuth");
    if (!validAuth) {
      yield put({
        type: SILENT_AUTH_CHECK,
        forceLogin: true,
      });

      return false;
    }

    let headers = {
      "Content-Type": "application/json",
    };
    headers = authProvider("applyAuthentication", headers);
    headers["X-TENANCY"] = localStorage.getItem("decisively.tenant");

    yield put({
      type: "DCSVLY/BATCHCALL_START",
      release: action.release,
      id: action.id,
      index: action.index,
    });
    // TODO: Hardcoded as we can't get config down here. Waiting on Alex to provide fix
    const uri = `${host}/decisionapi/batch/${action.id}/${action.release}`;
    console.log("uri", uri);
    const res = yield fetch(uri, {
      method: "POST",
      headers,
      body: JSON.stringify(action.payload),
    });
    const payload = yield res.json();
    if (!res.ok) throw new Error(payload.message);

    yield put({
      type: "DCSVLY/BATCHCALL_SUCCESS",
      release: action.release,
      id: action.id,
      payload,
      index: action.index,
    });

    yield put({
      type: "DCSVLY/BATCHCALL_END",
      release: action.release,
      id: action.id,
      index: action.index,
    });
  } catch (error) {
    if (error.message) {
      yield put(showNotification(error.message, "error"));
    }

    yield put({
      type: "DCSVLY/BATCHCALL_ERROR",
      release: action.release,
      id: action.id,
      index: action.index,
    });
  }
}


/**
  @typedef SuccessPayload
  @type {{
    results?: Array<{
      result: import( '../pages/decisions/DecisionDashboard/Reports/Details/UnifiedTimeline/types' ).Node[ 'value' ];
    }>
  }}
*/

/** @type { ( p: SuccessPayload ) => Record< string, unknown> } */
const extractOutput = payload => {
  let first;
  console.log("p", payload);
  if (payload.goal) {
    first = payload;
  } else {
    const { results } = payload;
    if (results === undefined) return {};

    first = results[0];
  }
  if (first === undefined) return {};


  const { result } = first;

  return {
    outcome: isTemporal(result) ? result.temporal.ranges.map(it => it.v) : result,
  };
};

function* handleDryBatchCall(authProvider, action) {
  const validAuth = yield call(authProvider, "validAuth");
  if (!validAuth) {
    yield put({
      type: SILENT_AUTH_CHECK,
      forceLogin: true,
    });

    return false;
  }

  let headers = {
    "Content-Type": "application/json",
  };
  headers = authProvider("applyAuthentication", headers);
  headers["X-TENANCY"] = localStorage.getItem("decisively.tenant");

  const uri = `${host}/decisionapi/batch/${action.id}/${action.release}`;

  const res = yield fetch(uri, {
    method: "POST",
    headers,
    body: JSON.stringify(action.payload),
  });
  const payload = yield res.json();

  if (!res.ok) {
    yield put(showNotification(payload.message, "error"));

    return false;
  }

  action.cb(extractOutput(payload));
}
const defaultBatchState = {};
const batchReducer = (previousState = defaultBatchState, action) => {
  let new_state;
  switch (action.type) {
    case "DCSVLY/BATCHCALL_SUCCESS":
      new_state = cloneDeep(previousState);
      set(new_state, `${action.release}.${action.id}.${action.index}.record`, action.payload);

      return new_state;
    case "DCSVLY/BATCHCALL_END":
    case "DCSVLY/BATCHCALL_ERROR":
      // let new_state = cloneDeep(previousState);
      new_state = cloneDeep(previousState);
      set(new_state, `${action.release}.${action.id}.${action.index}.loading`, false);

      return new_state;
    case "DCSVLY/BATCHCALL_START":
      new_state = cloneDeep(previousState);
      set(new_state, `${action.release}.${action.id}.${action.index}.loading`, true);

      return new_state;

    case "DCSVLY/BATCHCALL_RESET": return defaultBatchState;

    /* case 'DCSVLY/TESTGEN_SUCCESS':

      new_state = cloneDeep(previousState);
      set(new_state, `${action.release}.${action.id}.record`, {cases: action.cases, attributes: action.attributes} );
      return new_state;
    */
    default:
      return previousState;
  }
};

function* batchSaga(authProvider) {
  yield all([
    takeLatest(
      action => action.type === "DCSVLY/BATCHCALL",
      handleBatchCall,
      authProvider,
    ),
    takeLatest(DRY_BATCH_CALL, handleDryBatchCall, authProvider),
  ]);
}

export {
  exportModel,
  exportRelease,
  exportTestData,
  getBatchHook,
  testgenCall,
  getTestHook,
  useScope,
  setWorkspace,
  setProject,
  setRelease,
};

export const sagas = [
  batchSaga,
  testgenSaga,
  scopeSaga,
  exportSaga,
  documentsSaga,
  buildDocumentsState.saga,
  loadMoreProjects.saga,
  populateProjectReleaseCentre.saga,
  combinator.saga,
  combinatorV2.saga,
  loadProjectsForCurrentTotal.saga,
  AsyncSelectSaga,
  decisionsRedux.saga,
  GraphTestRedux.saga,
  BuildDebugRedux.saga,
  deleteRelease.saga,
  workspacesNS.saga,
  decisionDesignerRedux.saga,
  buildSidebarCollapsedNS.saga,
  BatchDebugSagaNs.saga,
  interviewReduxNS.saga,
  fullReleaseRedux.saga,
  cleanUpRelease.saga,
  contextMenuNS.saga,
  sessionNS.sessionSaga,
  dataTableSagaNS.saga,
  TestCasesTreeReduxNS.saga,
];

export const reducers = {
  batch: batchReducer,
  testgen: testgenReducer,
  scope: scopeReducer,
  [buildDocumentsState.REDUX_PROP]: buildDocumentsState.reducer,
  [decisionsRedux.REDUX_PROP]: decisionsRedux.reducer,
  [TempTimelineRedux.REDUX_PROP]: TempTimelineRedux.reducer,
  [GraphTestRedux.REDUX_PROP]: GraphTestRedux.reducer,
  [BuildDebugRedux.REDUX_PROP]: BuildDebugRedux.reducer,
  documents: documentsReducer,
  designer: designerReducer,
  [JsonInputV2Redux.REDUX_PROP]: JsonInputV2Redux.reducer,
  [buildSidebarCollapsedNS.REDUX_PROP]: buildSidebarCollapsedNS.reducer,
  [newDocumentsName]: newDocumentsReducer,
  bots: botsReducer,
  [ interviewReduxNS.REDUX_PROP ]: interviewReduxNS.reducer,
  [ fullReleaseRedux.REDUX_PROP ]: fullReleaseRedux.reducer,
  [ contextMenuNS.REDUX_PROP ]: contextMenuNS.reducer,
  session: sessionNS.sessionReducer
};

export const middleware = [];
