import styled, { css } from 'styled-components';
import React from 'react';
import TreeView, { TreeViewProps } from '@material-ui/lab/TreeView';
import Box from '@material-ui/core/Box';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';


const dashElement = css`
  content: '';
  position: absolute;
  border-left: 1px dashed ${ p => p.theme.palette.secondary.dark };
`;

export const DashedTree = css`

  .MuiTreeItem-group {
    > div > div > li {
      &.Mui-expanded {
        &:last-child {
          > .MuiTreeItem-content:before {
            ${ dashElement };
            left: -1.125rem;
            top: 0;
            bottom: 50%;
          }
        }
        &:not(:last-child):before {
          ${ dashElement };
          left: -1.125rem;
          top: 0;
          bottom: -4px;
        }
      }
      &:not(.Mui-expanded) {
        &:not(:last-child) {
          > div:before {
            ${ dashElement };
            left: -1.125rem;
            top: 0;
            bottom: -4px;
          }
        }
        &:last-child {
          > div:before {
            ${ dashElement };
            left: -1.125rem;
            top: 0;
            bottom: 50%;
          }
        }
      }
    }

    .MuiTreeItem-content {
      position: relative;

      :after {
        content: '';
        position: absolute;
        left: -1rem;
        top: 50%;
        width: 10px;
        border-top: 1px dashed ${ p => p.theme.palette.secondary.dark };
      }
    }
  }
`;

// ===================================================================================

const DATASET_PROP_NAME = 'muitreenodeid';

const StyledTreeView = styled(TreeView)`
  .MuiTreeItem-content {
    position: relative;
    padding: 0.5rem;
    /* border: 1px solid black; */

    :hover{ background-color: #F3F3F3; }
  }

  .MuiTreeItem-group {
    margin-left: 7px;
    padding-left: 18px;

    .MuiTreeItem-root {
      position: relative;

      :before {
        ${ dashElement };
        left: -0.5rem;
        top: 0;
        bottom: 0;
      }

      :last-child {
        :before { display: none; }

        >.MuiTreeItem-content:before {
          ${ dashElement };
          left: -0.75rem;
          top: 0;
          bottom: 50%;
        }
      }
    }

    .MuiTreeItem-content {
      margin-left: 0.25rem;
      width: calc(100% - 0.25rem);

      :after {
        content: '';
        position: absolute;
        left: -0.75rem;
        top: 50%;
        width: 10px;
        border-top: 1px dashed ${ p => p.theme.palette.secondary.dark };
      }
    }
  }

  .MuiTypography-root:focus-visible, .MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label {
    background-color: initial;
  }

  .MuiTreeItem-root.Mui-selected {
    > .MuiTreeItem-content {
      background-color: rgba(112, 240, 88, 0.12);

      .MuiTreeItem-label {
        background-color: initial;
      }
    }
  }

  .MuiTreeItem-label:hover {
    background-color: initial;
  }
` as React.FC< TreeViewProps >;

export type Item = (
  & Pick< TreeItemProps, 'nodeId' | 'icon' >
  & { childNodes?: Item[]; label?: string; }
);

const getLabel = (i: Item): string => String(i.label || i.nodeId);


type LabelWithChevronProps = {
  l: string;
  toggleExpanded: IconButtonProps[ 'onClick' ];
  hasChildren: boolean;
  isExpanded: boolean;
};
const LabelWithChevron = React.memo< LabelWithChevronProps >(
  ({ l, toggleExpanded, hasChildren, isExpanded }) => (
    <Box display='flex' justifyContent='space-between' alignItems='center' paddingLeft='0.5rem'>
      { l }

      {
        hasChildren === false ? null : (
          <IconButton onClick={toggleExpanded}>
            { isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
          </IconButton>
        )
      }
    </Box>
  ),
);
LabelWithChevron.displayName = 'common/styles/MuiTree/LabelWithChevron';


type ItemCProps = Pick< LabelWithChevronProps, 'toggleExpanded' > & {
  i: Item;
  selectItem: React.MouseEventHandler< HTMLDivElement >;
  label: JSX.Element;
  expanded: string[];
}

const ItemC = React.memo< ItemCProps >(({
  i: { nodeId, childNodes, icon },
  label,
  selectItem,
  toggleExpanded,
  expanded,
}) => (
  <TreeItem
    nodeId={nodeId}
    label={label}
    icon={icon}
    // so this guy gets attached to direct child of <li> element
    // which is <div>, but types say that it should be
    // MouseEventHandler< HTMLLIElement >. No idea why, so let's
    // just typecast
    onClick={selectItem as any}
    {...{ [ `data-${ DATASET_PROP_NAME }` ]: nodeId }}
  >
    { (childNodes === undefined || childNodes.length === 0) ? null : (
      childNodes.map(it => (
        <ItemC
          key={it.nodeId}
          i={it}
          selectItem={selectItem}
          label={(
            <LabelWithChevron
              l={getLabel(it)}
              toggleExpanded={toggleExpanded}
              hasChildren={it.childNodes !== undefined && it.childNodes.length !== 0}
              isExpanded={expanded.indexOf(it.nodeId) !== -1}
            />
          )}
          expanded={expanded}
          toggleExpanded={toggleExpanded}
        />
      ))
    )}
  </TreeItem>
));
ItemC.displayName = 'common/styles/MuiTree/Item';


export interface Props {
  className?: string;
  items: Item[];
  toggleExpanded: (s:string) => unknown;
  expanded: string[];
  selected?: string;
  setSelected: (s?: string) => unknown
}


export const Tree: React.FC< Props > = React.memo(p => {
  const { className, items, expanded, selected, setSelected, toggleExpanded } = p;
  const selectedForTree = React.useMemo< string[] >(
    () => (selected ? [selected] : []),
    [selected],
  );

  const setSelectedFromDataset = React.useCallback< ItemCProps[ 'selectItem' ] >(
    ({ currentTarget: { parentElement } }) => {
      // we take parent element because TreeItem assigns "onClick"
      // handler not to the <li> element, but to its direct child
      // <div>. However, TreeItem also assigns data-<whatever> properties
      // directly to <div> (yeah, I also find that completely logical).
      // so, that why we have need to work with parent and search for [DATASET_PROP_NAME] on it
      if(parentElement === null) return;

      const { [ DATASET_PROP_NAME ]: nodeId } = parentElement.dataset;
      if(nodeId === undefined) return;

      setSelected(nodeId);
    },
    [setSelected],
  );

  const toggleExpandedFromDataset = React.useCallback< NonNullable< LabelWithChevronProps['toggleExpanded'] > >(
    e => {
      e.stopPropagation();
      const { currentTarget } = e;

      const maybeNodeId = (function inner(el: HTMLElement | null): string | null {
        if(el === null) return null;

        const { [ DATASET_PROP_NAME ]: nodeId } = el.dataset;
        return nodeId === undefined ? inner(el.parentElement) : nodeId;
      }(currentTarget));

      if(maybeNodeId === null) return;

      toggleExpanded(maybeNodeId);
    },
    [toggleExpanded],
  );

  return (
    <StyledTreeView
      className={className}
      expanded={expanded}
      selected={selectedForTree}
    >
      { items.map(it => (
        <ItemC
          key={it.nodeId}
          i={it}
          selectItem={setSelectedFromDataset}
          toggleExpanded={toggleExpandedFromDataset}
          label={(
            <LabelWithChevron
              l={getLabel(it)}
              toggleExpanded={toggleExpandedFromDataset}
              hasChildren={it.childNodes !== undefined && it.childNodes.length !== 0}
              isExpanded={expanded.indexOf(it.nodeId) !== -1}
            />
          )}
          expanded={expanded}
        />
      )) }
    </StyledTreeView>
  );
});
Tree.displayName = 'common/styles/MuiTree';
