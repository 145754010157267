

/**
  @typedef IActiveState
  @type {{
    x: number;
    y: number;
    value: any;
    start: string;
    end: string;
    title: string
  }}
 */
/**
  @typedef IState
  @type {
    | { active: false }
    | { active: true; } & IActiveState
  }
*/

export const REDUX_PROP = 'temporalTimelineTitle';
/** @type { ( s: any ) => IState } */
export const getState = s => s[ REDUX_PROP ];


export const START_TIME = '-271821-04-20T00:00:00.000Z';
export const END_TIME = '+275760-09-13T00:00:00.000Z';


/**
  @type {{
    set: 'temporalTimelineTitle/set';
    reset: 'temporalTimelineTitle/reset';
  }}
 */
export const ActionTypes = {
  set: 'temporalTimelineTitle/set',
  reset: 'temporalTimelineTitle/reset',
};

/**
  @typedef {{
    set: {
      type: typeof ActionTypes.set;
      payload: IState;
    };
    reset: {
      type: typeof ActionTypes.reset;
    }
  }} IActions
 */


/**
  @type {{
    set: ( arg: IActions[ 'set' ][ 'payload' ] ) => IActions[ 'set' ];
    reset: () => IActions[ 'reset' ];
  }}
 */
export const ActionCreators = {
  set: payload => ({ type: ActionTypes.set, payload }),
  reset: () => ({ type: ActionTypes.reset }),
};


/** @type { IState } */
export const defaultState = { active: false };


/**
  @type {(
    s: typeof defaultState | undefined,
    a: IActions[ keyof IActions ]
  ) => NonNullable< typeof s > }
 */
export const reducer = (s = defaultState, a) => {
  switch (a.type) {
    case ActionTypes.set:
      return a.payload;

    case ActionTypes.reset:
      return defaultState;

    default:
      return s;
  }
};
