import { Roles } from "auth/permissions";
import { lazy } from "react";

const Verify = lazy(() => import("../pages/verify"));

export const verify = [
  {
    path: "/verify",
    component: Verify,
    layout: "decisively",
    role: Roles.Tester,
    exact: true,
  },
];
