
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// @ts-ignore
import { useList } from '@imminently/imminently_platform';
import fastDeepEqual from 'fast-deep-equal';
// import debounce from 'lodash/debounce';
import {
  ActionCreators,
  getResourceHashAndResourceForId,
  ASYNC_SELECT_LOCATION,
  COMPONENT_ID_FOR_CURRENT_RELEASES,
} from './saga';
import { AsyncSelectItem } from '../menu';
import { Avatar } from '../avatar';
import { Favorite } from '../buttons';
import * as Core from './Core';


export const classes = Core.classes;

/** @typedef {{ name: string; id: string | number; description?: string; avatar?: string; env?: string; }} IOption */

/**
  @typedef IMenuItemOption
  @type {
    & IOption
    & {
      icon?: JSX.Element;
      action?: JSX.Element;
      disabled: boolean;
    }
  }
  */

/**
  @typedef IProps
  @type { import('./saga').ICRUDCommon & {
    resourceEntryToOption?: (e: any) => IOption;
    className?: string;
    actions?: any[];
    groupBy?: Array<{ name: string; filter: ( o: IOption ) => boolean }>;
    onOptionClick: Parameters< typeof Core._ >[0][ 'lower' ][ 'onClick' ];
    dontClearSearchOnClick?: true;
    upperHeading?: string;
    lowerHeading?: string;
    lowerCollapsable?: boolean,
    hideSearch?: true;
    options?: IOption[];
    currentSelectedId?: string;
    placeholder?: string;
    upperPredicate?: (option: IOption) => boolean,
    hideFavourites?: boolean,
  }}
*/

/** @type { React.FC } */
const Control = () => null;
Control.displayName = 'AsyncSelect/Control';

const idForLoadingItem = '__loading';

export const defaultLocation = 'forAsyncSelect';
export const defaultComponentId = 'default';


/** @type { React.FC< IProps > } */
export const _ = React.memo(({
  resourceType,
  location = defaultLocation,
  componentId = defaultComponentId,
  resourceEntryToOption,
  perPage,
  getFilterForSearch,
  className,
  actions = [],
  onOptionClick,
  dontClearSearchOnClick,
  workspace,
  model,
  upperHeading,
  lowerHeading,
  lowerCollapsable,
  hideSearch,
  options,
  currentSelectedId: currentSelectedIdFromProps,
  placeholder,
  upperPredicate,
  hideFavourites,
}) => {
  const dispatch = useDispatch();
  const currentSelectedIdFromState = useSelector((/** @type { any } */state) => {
    const { scope: s } = state;

    return resourceType === 'workspaces'
      ? s.workspace
      : resourceType === 'models'
        ? s.project
        : s.release;
  });

  const currentSelectedId = currentSelectedIdFromProps ?? currentSelectedIdFromState;

  /** @type {{ pageData: any[] }} */
  const data = useList(
    'releases',
    ASYNC_SELECT_LOCATION,
    COMPONENT_ID_FOR_CURRENT_RELEASES,
  );

  /**
    @type {
      (
        arg: Pick< Parameters< typeof ActionCreators.load >[0], 'type' | 'search' > & {
          delayed?: true
        }
      ) => unknown
    }
  */
  const loadByTypeAndSerach = React.useCallback(
    ({ type, search, delayed }) => {
      const AC = delayed
        ? ActionCreators.delayedLoad
        : ActionCreators.load;

      dispatch(AC({
        type,
        search,
        componentId,
        location,
        perPage,
        resourceType,
        getFilterForSearch,
        workspace,
        model,
      }));
    },
    [
      dispatch,
      componentId,
      location,
      perPage,
      resourceType,
      getFilterForSearch,
      workspace,
      model,
    ],
  );


  /** @type { IOption[] } */
  const resolvedOptions = useSelector(
    (/** @type { any } */s) => {
      if (options) {
        return options;
      }

      const maybeHashAndForId = getResourceHashAndResourceForId({
        state: s,
        resourceType,
        componentId,
        location,
      });
      if (maybeHashAndForId === null) return [];

      const { hash, forId } = maybeHashAndForId;
      /** @type {{ list: { ids: string[] } }} */
      const { list: { ids } } = forId;

      return ids.reduce(
        (acc, id) => {
          const resourceEntry = hash.data[id];

          return acc.concat(
            resourceEntry === undefined
              ? []
              : resourceEntryToOption(resourceEntry),
          );
        },
        /** @type { IOption[] } */([]),
      );
    },
    fastDeepEqual,
  );

  /** @type { boolean } */
  const hasMore = useSelector(
    (/** @type { any } */s) => {
      if (options) return false;

      const maybeHashAndForId = getResourceHashAndResourceForId({
        state: s,
        resourceType,
        componentId,
        location,
      });
      if (maybeHashAndForId === null) return false;

      return maybeHashAndForId.hasMore;
    },
  );

  const isLoading = useSelector(
    s => {
      const maybeHashAndForId = getResourceHashAndResourceForId({
        state: s,
        resourceType,
        componentId,
        location,
      });

      return maybeHashAndForId === null
        ? false
        : maybeHashAndForId.forId.list.loading;
    },
  );


  const [searchStr, setSearchStrRaw] = React.useState('');
  const setSearch = React.useCallback((/** @type { string } */v) => {
    setSearchStrRaw(v);
    loadByTypeAndSerach({ type: 'newSearch', search: v, delayed: true });
  }, [loadByTypeAndSerach, setSearchStrRaw]);

  // React.useEffect(() => () => void setSearch(''), [setSearch]);

  const menuItemOptions = React.useMemo(() => {
    /** @type { IMenuItemOption[] } */
    const mappedOptions = resolvedOptions
      .map(it => {
        /** @type { IMenuItemOption } */
        const mapped = {
          ...it,
          icon: <Avatar size='small'>{it.avatar || it.name.slice(0, 1)}</Avatar>,
          // onClick: () => {
          //   onOptionClick(it);
          //   if(dontClearSearchOnClick === undefined) setSearchStrRaw('');
          // },
          disabled: isLoading,
          action: hideFavourites ? null : <Favorite style={{ padding: 0 }} size='small' />,
        };

        return mapped;
      });

    /** @type { typeof mappedOptions } */
    const nextOptions = isLoading
      ? mappedOptions.concat({ name: 'Loading...', id: idForLoadingItem, disabled: true })
      : mappedOptions;

    return nextOptions;
  }, [resolvedOptions, isLoading]);

  const searchPlaceholder = React.useMemo(() => (
    resourceType === 'models'
      ? 'Search projects'
      : resourceType === 'releases'
        ? 'Search releases'
        : placeholder || 'Search workspaces'
  ), [resourceType, placeholder]);


  const search = React.useMemo(() => {
    /** @type { Core.IProps[ 'search' ] } */
    const rtrn = hideSearch ? { hidden: true } : {
      hidden: false,
      placeholder: searchPlaceholder,
      set: setSearch,
      onClear: () => setSearch(''),
      value: searchStr,
    };

    return rtrn;
  }, [searchStr, setSearch, searchPlaceholder, hideSearch]);

  const upper = React.useMemo(
    () => {

      let items = [];
      if (resourceType === 'releases') {
        items = data.pageData
          .map(it => resourceEntryToOption(it));
      } else if (upperPredicate) {
        items = menuItemOptions.filter(upperPredicate);
      }


      /** @type { Core.IProps[ 'upper' ] } */
      const rtrn = {
        hasMore: false,
        heading: upperHeading,
        items: items.map(it => ({
          i: <AsyncSelectItem
            {...it}
            isRelease={resourceType === 'releases'}
            releaseEnv={it.env}
            isCurrent={currentSelectedId === it.id}
          />,
          key: it.id,
        })),
        loadMore: console.log,
        onClick: key => {
          onOptionClick(key);
          if (dontClearSearchOnClick === undefined) setSearch('');
        },
      };

      return rtrn;
    },
    [
      upperHeading,
      resourceType,
      data.pageData,
      resourceEntryToOption,
      onOptionClick,
      setSearch,
      dontClearSearchOnClick,
      currentSelectedId,
    ],
  );


  const lower = React.useMemo(
    () => {
      /** @type { Core.IProps[ 'lower' ] } */
      const rtrn = {
        heading: lowerHeading,
        items: menuItemOptions
          .filter(it => !upper.items.some(i => i.key === it.id))
          .map(it => ({
            i: <AsyncSelectItem
              {...it}
              isRelease={resourceType === 'releases'}
              releaseEnv={it.env}
              isCurrent={currentSelectedId === it.id}
            />,
            key: it.id,
            isHelper: it.id === idForLoadingItem,
          }))
          .concat(
            (hasMore && !isLoading)
              ? {
                i: (
                  <AsyncSelectItem
                    name='Scroll to load more...'
                    isCurrent={false}
                    disabled
                  />
                ),
                key: 'scrollToLoadMore',
                isHelper: true
              }
              : []
          ),
        loadMore: search => loadByTypeAndSerach({ type: 'nextPage', search }),
        onClick: key => {
          onOptionClick(key);
          if (dontClearSearchOnClick === undefined) setSearch('');
        },
        collapsable: lowerCollapsable,
      };

      return rtrn;
    },
    [
      lowerCollapsable,
      lowerHeading,
      menuItemOptions,
      loadByTypeAndSerach,
      resourceType,
      onOptionClick,
      dontClearSearchOnClick,
      setSearch,
      upper,
      currentSelectedId,
      hasMore,
      isLoading,
    ],
  );

  return (
    <Core._ {...{
      actions,
      search,
      upper,
      lower,
      className,
    }}
    />
  );
});
_.displayName = 'AsyncSelect';
