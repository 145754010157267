import type React from "react";
import Moment from 'react-moment';
import styled from "styled-components";
import { formatters } from "jsondiffpatch";
import "jsondiffpatch/dist/formatters-styles/html.css";
import { Grid, Typography } from "@material-ui/core";
import { scrollable } from "@common";


const Flex = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;


const SingleCurrentChangeWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const ChangesWrap = styled(SingleCurrentChangeWrap)`
  gap: 0.5rem;
`;
const SingleChangeC = styled(ChangesWrap)`
  gap: 0.25rem;
`;

const SingleCurrentChange = ({change}) => {
  const getFormattedDiff = (c) => {
    let __html = '';

    try {
      __html = formatters.html.format(
        c,
        // TODO provide proper original
        {}
      );
    } catch (error) {
      console.log('MWKAWvfHB1 | error processing diff', error);
    }
    if (!__html) __html = "Error rendering diff"
    return __html;
  }

  return (
    <SingleCurrentChangeWrap>
      <Flex style={{ gap: '1rem' }}>
        <Typography>
          <Typography variant='h6' style={{ display: 'inline-block' }}>Who:</Typography>{' '}

          { change.who.name }
        </Typography>
        <Typography>
          <Typography variant='h6' style={{ display: 'inline-block' }}>When:</Typography>{' '}

          <Moment format="YYYY-MM-DD HH:mm:ss">{change.when}</Moment>
        </Typography>
      </Flex>

      <ChangesWrap>
        {change.what.map((c, index) => (
          <SingleChangeC key={index}>
            <Typography variant="h4">
              Change #{index + 1}
            </Typography>

            {c.description}

            {c.diff && (
              <>
              <Typography variant="h6">Diff</Typography>
              <div dangerouslySetInnerHTML={{__html: getFormattedDiff(c.diff)}} />
              </>
            )}
          </SingleChangeC>
        ))}
      </ChangesWrap>
    </SingleCurrentChangeWrap>
  );
}


const Wrap = styled.div`
  display: flex;
  height: 100%;
  flex-grow: 1;
`;

const WrapForEmpty = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
const Empty = () => (
  <WrapForEmpty>
    <Typography variant='h6'>Select a change on the left</Typography>
  </WrapForEmpty>
);


export type CurrentChangeProps = {
  currentChange: any;
}
export const CurrentChange: React.FC< CurrentChangeProps > = ({ currentChange }) => (
  <Wrap className={ scrollable }>
    { Boolean( currentChange ) === false ? <Empty /> : (
      <SingleCurrentChange change={ currentChange } />
    ) }
  </Wrap>
)
CurrentChange.displayName = 'components/changeHistory/CurrentChange';
