import { call, all, put, select, takeLatest } from 'redux-saga/effects';


export const LOCAL_STORAGE_NAME = 'is_build_sidebar_collapsed';
export const REDUX_PROP = 'buildSidebarCollapsed';


export type State = {
  isCollapsed: boolean;
};
export const defaultState: State = { isCollapsed: false };
export const getState = (s: any): State => s[ REDUX_PROP ];


export const aTypes = {
  set: 'buildSidebarCollapsed/set',
  requestToToggle: 'buildSidebarCollapsed/requestToToggle',
} as const;

export type Actions = {
  set: { type: typeof aTypes.set; payload: State[ 'isCollapsed' ]; };
  requestToToggle: { type: typeof aTypes.requestToToggle };
}
export type AllActions = Actions[ keyof Actions ];


export const aCreators: {
  [ K in keyof Actions ]: Actions[ K ] extends { payload: unknown }
    ? (p: Actions[ K ][ 'payload' ]) => Actions[K]
    : () => Actions[K]
} = {
  set: p => ({ type: aTypes.set, payload: p }),
  requestToToggle: () => ({ type: aTypes.requestToToggle }),
};


export const reducer = (s = defaultState, a: AllActions): typeof s => {
  switch(a.type) {
    case aTypes.set: return { isCollapsed: a.payload };
    default: return s;
  }
};

export function* saga(): Gen {
  yield all([
    yield takeLatest(aTypes.requestToToggle, function* onRequestToToggle() {
      const state = yield select();
      const { isCollapsed } = getState(state);

      const nextValue = !isCollapsed;
      localStorage.setItem(LOCAL_STORAGE_NAME, String(nextValue));
      yield put(aCreators.set(nextValue));
    }),
    call(function* hydrateReduxOnMount() {
      const localStorageValue = localStorage.getItem(LOCAL_STORAGE_NAME);
      yield put(aCreators.set(localStorageValue === 'true'));
    }),
  ]);
}
