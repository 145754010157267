import { useFloatingToolbar } from "@udecode/plate";
import type { PropsWithChildren } from "react";
import styled from "styled-components";

const ToolbarContainer = styled.div`
  border: 1px solid ${(p) => p.theme.palette.background.border};
  background-color: ${(p) => p.theme.palette.background.default};
  z-index: 20 !important;
  border-radius: 0.5rem;
  box-shadow: ${(p) => p.theme.shadows[1]};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.25rem;
  gap: 0.25rem;
`;

export const FloatingToolbar = ({ readOnly = false, children }: { readOnly?: boolean } & PropsWithChildren) => {
  const { floating, style, open } = useFloatingToolbar({ floatingOptions: { placement: "bottom" } });

  // no floating toolbar on readonly or not open
  if (readOnly || !open) null;

  return (
    <ToolbarContainer
      ref={floating}
      style={style}
    >
      {children}
    </ToolbarContainer>
  );
};
