import React from "react";
import styled from "styled-components";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { Button, Search } from "@components";
import { Scope } from "@common";
import { SearchProps } from "@components/Search";

const Container = styled(Grid)`
  padding: 2rem 2rem 0 2rem;

  > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  margin-bottom: 20px;
`;

const Searchbar = styled(Search)`
  width: 340px;
`;

const Actions = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 1.5rem;
`;

// TODO page header might instead take children as a function and pass down search, sort and filter

export type PageHeaderProps = {
  title: string;
  actions?: { name: string; type?: "primary" | "secondary" | "tertiary" | "danger" | "link"; onClick?: () => void }[];
  scope?: boolean;
  search?: SearchProps;
  sortBy?: { value: string; setValue: (value: string) => void };
  filterBy?: { value: string; setValue: (value: string) => void };
  scopeComponent?: any;
  children?: React.ReactNode;
}

export const PageHeader = ({
                             title,
                             actions,
                             scope,
                             children,
                             search,
                             sortBy,
                             filterBy,
                             scopeComponent,
                           }: PageHeaderProps) => {
  return (
    <Container container direction="column" className="common-page-header">
      <Grid container justifyContent="space-between" alignItems={"center"}>
        <Grid container style={{ gap: "1.5rem", width: "unset" }}>
          <Typography variant="h1">{title}</Typography>
          {scope && (
            <Grid item>
              {/* @ts-ignore - Can type Scope later */}
              {scopeComponent ? React.createElement(scopeComponent) : <Scope/>}
            </Grid>
          )}
        </Grid>
        <Actions>
          {actions &&
            actions.map(({ name, type, ...rest }) => (
              <Button key={name} type={type} {...rest}>
                {name}
              </Button>
            ))}
        </Actions>
        {children}
      </Grid>
      {(search || sortBy || filterBy) && (
        <Grid container justifyContent="space-between">
          {search && <Searchbar {...search} />}
          <Actions>
            {sortBy && (
              <Button type="tertiary" size="small">
                Sort by
              </Button>
            )}
            {filterBy && (
              <Button type="tertiary" size="small">
                Filter by
              </Button>
            )}
          </Actions>
        </Grid>
      )}
    </Container>
  );
};
