import React from "react";
import Menu from "@material-ui/core/Menu";
import { getState, aCreators } from "./reducer";
import { useDispatch, useSelector } from "react-redux";
import { holder } from "./saga";


export const CtxMenuRootComp = React.memo(() => {
  const dispatch = useDispatch();

  const pos = useSelector(s => getState(s).pos);
  const handleClose = React.useCallback(() => (
    dispatch( aCreators.requestToHide() )
  ), [ dispatch ]);

  return (
    <Menu
      keepMounted
      open={pos !== null}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={
        pos === null
          ? undefined
          : { top: pos.coordinates.top, left: pos.coordinates.left }
      }
      transformOrigin={pos === null ? undefined : pos.transformOrigin}
      MenuListProps={ holder.menuListStyle ? { style: holder.menuListStyle } : undefined }
      {...holder.propsOverride}
    >
      { holder.node }
    </Menu>
  );
});
CtxMenuRootComp.displayName = "redux/contextMenu/Comp";
