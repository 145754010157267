import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { CalendarIcon, CloseIcon } from '@icons';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { TextField } from '@components';
import Popover, { PopoverProps } from '@material-ui/core/Popover';
import styled from 'styled-components';
import moment from 'moment';
import clsx from 'clsx';
import 'react-datepicker/dist/react-datepicker.css';
import { isTemporal } from '@pages/decisions/DecisionDashboard/Reports/Details/UnifiedTimeline/types';
import { END_TIME, START_TIME } from '@components/TemporalValue/TempTimeline/redux';
import type { Node } from '@pages/models/release/Test/GraphTest/redux';
import type { Props } from './DatesFilter_types';


const DatePickerWrap = styled.div`
  .react-datepicker {
    margin-bottom: -7px;
  }
`;
DatePickerWrap.displayName = 'components/RuleGraphFullRelease/DatesFilter/DatePickerWrap';

const Wrap = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  height: 2.5rem;
  &.hide { display: none; }
`;

const PaperProps: PopoverProps[ 'PaperProps' ] = { style: {
  borderRadius: '0.3rem',
} };


const DATE_FORMAT = 'YYYY/MM/DD';



export const _: React.FC< Props > = React.memo(p => {
  const { className, hide, set: setDatesFilter, value: datesFilter, minMax } = p;
  const [start, end] = datesFilter || [null, null];

  const onChange = React.useCallback< ReactDatePickerProps< never, false >[ 'onChange' ] >(
    v => setDatesFilter([v && v.toISOString(), v && v.toISOString()]),
    [setDatesFilter],
  );

  const hasValue = start !== null && end !== null;

  const onIconButtonClick = React.useCallback< React.MouseEventHandler< HTMLButtonElement > >(e => {
    if(!hasValue) return;

    e.stopPropagation();
    setDatesFilter([null, null]);
  }, [hasValue, setDatesFilter]);

  const startDate = React.useMemo(() => (start ? new Date(start) : null), [start]);
  // const endDate = React.useMemo(() => (end ? new Date(end) : null), [end]);
  const textFieldValue = React.useMemo(() => {
    if(start === null || end === null) return '';

    // return `${ moment(start).format(DATE_FORMAT) } - ${ moment(end).format(DATE_FORMAT) }`;
    return moment(start).format(DATE_FORMAT);
  }, [start, end]);


  const [anchorEl, setAnchorEl] = React.useState< HTMLDivElement | null >(null);
  const openPopover = React.useCallback< React.MouseEventHandler< HTMLDivElement > >(
    ({ currentTarget }) => setAnchorEl(currentTarget),
    [],
  );
  const handleClose = React.useCallback(() => setAnchorEl(null), []);


  return (
    <>
      <Wrap onClick={openPopover} className={clsx(className, hide && 'hide')}>
        { hasValue && <TextField value={textFieldValue} disabled /> }

        <IconButton onClick={onIconButtonClick}>
          { hasValue ? <CloseIcon /> : <CalendarIcon /> }
        </IconButton>
      </Wrap>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={React.useMemo(() => ({
          vertical: 'bottom',
          horizontal: 'right',
        }), [])}
        transformOrigin={React.useMemo(() => ({
          vertical: 'top',
          horizontal: 'right',
        }), [])}
        PaperProps={PaperProps}
      >
        <DatePickerWrap>
          <DatePicker
            selected={startDate}
            // startDate={startDate}
            // endDate={endDate}
            onChange={onChange}
            inline
            showYearDropdown
            yearDropdownItemNumber={30}
            scrollableYearDropdown
            minDate={minMax && minMax[ 0 ]}
            maxDate={minMax && minMax[ 1 ]}
            // selectsRange
          />
        </DatePickerWrap>
      </Popover>
    </>
  );
});
_.displayName = 'components/RuleGraph/DatesFilter';

export * from "./DatesFilter_types";
export * from "./DatesFilter_mergeValueAndDatesFilter";

// export const mergeValueAndDatesFilter = (v: Node['value'], datesFilter: Props[ 'value' ]): typeof v => {
//   if(!datesFilter || !isTemporal(v)) return v;

//   const [dFilt0, dFilt1] = datesFilter;
//   if(dFilt0 === null || dFilt1 === null) return v;

//   const start = dFilt0.split('T')[ 0 ];
//   const end = dFilt1.split('T')[ 0 ];

//   const nextDerived = v.temporal.ranges.reduce< Node['value'] >(
//     (a, { r, v }) => {
//       if(a !== undefined) return a;

//       const [rangeStart] = r.start.split('T');
//       const [rangeEnd] = r.end.split('T');
//       if(r.start !== START_TIME && rangeStart > start) return a;
//       if(r.end !== END_TIME && rangeEnd < end) return a;

//       return typeof v === 'object' && v !== null ? undefined : v;
//     },
//     undefined,
//   );

//   return nextDerived;
// };


export const deriveMinMaxFromNodeValues = (vs: Node[ 'value' ][]): Props[ 'minMax' ] => {
  const dfltAcc: { min: string | null; max: string | null } = { max: null, min: null };

  const { min, max } = vs.reduce(
    (acc, derived) => {
      if(!isTemporal(derived)) return acc;

      return derived.temporal.ranges.reduce(
        (a, { r: { end, start } }) => {
          const rtrn: typeof acc = {
            max: end === END_TIME ? a.max : (
              a.max === null ? end : (
                a.max < end ? end : a.max
              )
            ),
            min: start === START_TIME ? a.min : (
              a.min === null ? start : (
                a.min > start ? start : a.min
              )
            ),
          };

          return rtrn;
        },
        acc,
      );
    },
    dfltAcc,
  );

  return min === null || max === null ? undefined : (
    [new Date(min), new Date(max)]
  );
};
