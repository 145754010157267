import React from "react";
import styled from "styled-components";

import { PageWrapper } from "@imminently/imminently_platform";
import { PageHeader, EmptyContent } from "@common";

const PageContent = styled(PageWrapper)`
  padding: 2rem 2rem 0 2rem;
`;

export const BuildPage = ({ name, children = null }) => {
  return (
    <PageContent>
      <PageHeader title={name} scope />
      {children ? children : <EmptyContent messages={["Add your first documents..."]} />}
    </PageContent>
  );
};
