import React from "react";
import { ReleaseTestCasesNS } from '@packages/commons';
import { defaultTestCasesRootCtxValue, useTestCasesRootCtx } from "@components/TestCasesTree/RootCtx";
import { useDispatch } from "react-redux";
import produce from "immer";
import { toSortableTree } from "@components/TestCasesTree/utils";
import * as ctxMenuNS from '../../../../redux/contextMenu';
import { TestCasesTree } from "../core";


export type OpenPathDropdownFArg = {
  compRootId: ReleaseTestCasesNS.TestCaseTItem[ 'id' ];
  isOpenedInCtxMenu?: boolean;
  topCoordinate: number;
  leftCoordinate: number;
}
export type OpenPathDropdownF = ( arg: OpenPathDropdownFArg ) => unknown;
export const useOpenPathDropdown = (): OpenPathDropdownF => {
  const dispatch = useDispatch();
  const { valueRef, setValue } = useTestCasesRootCtx();

  return React.useCallback(arg => {
    const { compRootId, isOpenedInCtxMenu, leftCoordinate, topCoordinate } = arg;

    const maybeMatched = ReleaseTestCasesNS.utilsNS.tCasesFindById({
      rootItem: valueRef.current.tree,
      id: compRootId,
    });
    if( maybeMatched === null ) return;

    setValue(prev => produce(prev, draft => {
      draft.testCasesTreeCompState = {
        ...defaultTestCasesRootCtxValue.testCasesTreeCompState,
        mode: 'leafSelect',
        tree: {
          ...toSortableTree( { root: maybeMatched } ),
          expanded: true,
        },
        highlightedId: null,
        isOpenedInCtxMenu,
      };
    }));

    dispatch(ctxMenuNS.aCreators.requestToShow({
      content: <TestCasesTree />,
      pos: { coordinates: { top: topCoordinate, left: leftCoordinate } },
      propsOverride: {
        PaperProps: {
          style: { width: 360, padding: '0.5rem 1.5rem' }
        }
      }
    }));
  }, [valueRef, setValue, dispatch]);
}
