import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import { makeStyles, useTheme, IconButton, Tooltip } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Link from '@material-ui/core/Link';
import capitalize from 'lodash/capitalize';

import { Avatar, AvatarGroup, Favorite, Label, Tag, Button } from '@components';
import { MenudotsIcon } from '@icons';
import { withMenu } from '../components/input';

const Tags = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-flow: row wrap;
`;

const MenuButton = props => <IconButton {...props}><MenudotsIcon /></IconButton>;
const MoreMenu = withMenu(MenuButton);

const useStyles = makeStyles(theme => ({
  card: {
    width: 'var(--size)',
    display: 'flex',
    flexFlow: 'column nowrap',
    borderRadius: '0.5rem',
    padding: '1rem',
    border: `1px solid ${ theme.palette.secondary.light }`,
    '&:hover': {
      boxShadow: theme.body.boxShadow,
    },
    '&:hover .MuiCardHeader-action': {
      display: "flex",
    },
  },
  archive: {
    backgroundColor: 'transparent',
    border: `1px solid ${ theme.palette.secondary.main }`,
  },
  header: {
    padding: 0,
    paddingBottom: '0.5rem',
    gap: "1rem",
    '& .MuiCardHeader-avatar': {
      margin: 0,
    },
    '& .MuiCardHeader-action': {
      margin: 0,
      flexFlow: 'row nowrap',
      justifyContent: 'flex-end',
      display: "none",
    },
    '& .MuiCardHeader-content': {
      overflow: "hidden", // allows shrinking
    },
  },
  description: {
    fontWeight: 600,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  content: {
    display: 'flex',
    padding: '0.5rem 0',
    alignItems: 'flex-start',
    gap: '0.5rem',
    flex: "1",
  },
  actions: {
    padding: 0,
    paddingTop: '0.5rem',
  },
  ml: {
    marginLeft: 'auto !important',
    cursor: 'pointer',
  },
}));

const AvatarPopperProps = { modifiers: { offset: { offset: '0, -10' }, flip: { enabled: false } } };

export const ResourceCard = ({
  id,
  name,
  description,
  icon = '/',
  users,
  label,
  labelColour,
  tags,
  numResources,
  width,
  archive = false,
  menu,
  action,
  noAvatar,
  alt,
  // onNumResourcesClick = () => {},
}) => {
  const theme = useTheme();
  const styles = useStyles();
  // const onNumResourcesClickHandler = React.useCallback(() => {
  //   onNumResourcesClick( id );
  // }, [ id, onNumResourcesClick ]);

  // check if menu is a react component that returns null

  const actions = menu ? <MoreMenu>{menu}</MoreMenu> : null;

  const size = width
    ? {
      '--size': typeof width === 'string' ? width : `${ width }px`,
    }
    : {};

  return (
    <Card elevation={0} className={clsx(styles.card, { [ styles.archive ]: archive })} style={size}>
      <CardHeader
        className={styles.header}
        avatar={noAvatar ? null : <Avatar alt={typeof name === "string" ? name : alt} src={icon} size='large' invert={archive} />}
        action={actions}
        title={name}
        subheader={description}
        titleTypographyProps={{ variant: 'h4', display: 'block', noWrap: true }}
        subheaderTypographyProps={{
          color: 'inherit',
          variant: 'caption',
          display: 'block',
          noWrap: true,
          className: styles.description,
        }}
      />
      <CardContent className={styles.content}>
        {label && <Label color={theme.labels[ labelColour || label.toLowerCase() ]}>{capitalize(label)}</Label>}
        <Tags>
          {tags && tags.slice(0, 5).map((t, i) => <Tag key={i} label={t} />)}
          {tags && tags.length > 5 && <Tag key='more tags' label={`+${ tags.length - 5 }`} />}
        </Tags>
        {numResources && (
          <Link
            color='secondary'
            variant='caption'
            underline='always'
            className={styles.ml}
            // onClick={ onNumResourcesClickHandler }
          >
            {numResources}
          </Link>
        )}
      </CardContent>
      <CardActions disableSpacing className={styles.actions}>
        <AvatarGroup spacing="small" size="medium">
          {users && users.map(u => (
            <Tooltip key={u.id} title={u.name} placement="bottom" PopperProps={AvatarPopperProps}>
              <Avatar key={u.id} alt={u.name} src={u.avatar || "/"} size="medium" />
            </Tooltip>
          ))}
        </AvatarGroup>
        {
          action ? (
            <Button type='secondary' size='small' onClick={action.onClick} className={styles.ml}>
              {action.name}
            </Button>
          ) : null
        }
      </CardActions>
    </Card>
  );
};

export const ResourceLoading = ({ width = '512px' }) => {
  const styles = useStyles();
  const size = {
    '--size': typeof width === 'string' ? width : `${ width }px`,
  };
  return (
    <Card className={styles.card} style={size}>
      <CardHeader
        className={styles.header}
        avatar={<Skeleton variant='circle' width={48} height={48} />}
        action={(
          <>
            <Skeleton key='1' variant='circle' width={24} height={24} />
            <Skeleton key='2' variant='circle' width={24} height={24} />
          </>
        )}
        title={<Skeleton variant='text' width='50%' height={24} />}
        subheader={<Skeleton variant='text' width='80%' height={16} />}
      />
      <CardContent className={styles.content}>
        <Skeleton variant='text' width={100} height={24} />
        <Tags>
          <Skeleton key='1' variant='text' width={40} height={24} />
          <Skeleton key='2' variant='text' width={40} height={24} />
        </Tags>
        <Skeleton variant='text' width={64} height={24} className={styles.ml} />
      </CardContent>
      <CardActions disableSpacing className={styles.actions}>
        <AvatarGroup spacing='small'>
          {[1, 2, 3, 4].map(i => (
            <Skeleton key={i} variant='circle' width={32} height={32} />
          ))}
        </AvatarGroup>
        <Skeleton variant='rect' width={40} height={18} className={styles.ml} />
      </CardActions>
    </Card>
  );
};
