
import { all, takeLatest, put, select } from 'redux-saga/effects';
import { ActionTypes, ActionCreators, getState } from './reducer';


/** @typedef { import( './reducer' ).IActions[ 'requestToSetReleaseInfo' ] } ReqToSetReleaseInfo */


export function* saga() {
  yield all([
    takeLatest(
      ActionTypes.requestToSetReleaseInfo,
      function* (/** @type { ReqToSetReleaseInfo } */{ payload }) {
        const state = yield select();
        const { scope: { release: releaseId } } = state;
        const releaseIdToInfo = getState(state);

        /** @type { typeof releaseIdToInfo[ keyof typeof releaseIdToInfo ] } */
        const releaseInfo = releaseIdToInfo[releaseId];

        /** @type { typeof releaseInfo } */
        const nextReleaseInfo = (() => {
          if (payload.type === 'all' || releaseInfo === undefined) return payload;

          if (releaseInfo.type === 'all') {
            return {
              ...releaseInfo,
              ...(payload.type === 'expandedOnly'
                ? { expanded: payload.expanded }
                : { filename: payload.filename }),
            };
          }

          if (releaseInfo.type === 'expandedOnly') {
            if (payload.type === 'expandedOnly') {
              return {
                type: 'expandedOnly',
                expanded: payload.expanded,
              };
            }

            return {
              type: 'all',
              expanded: releaseInfo.expanded,
              filename: payload.filename,
            };
          }


          if (payload.type === 'filenameOnly') {
            return {
              type: 'filenameOnly',
              filename: payload.filename,
            };
          }

          return {
            type: 'all',
            expanded: payload.expanded,
            filename: releaseInfo.filename,
          };
        })();

        yield put(ActionCreators.setReleaseInfo({
          info: nextReleaseInfo,
          releaseId,
        }));
      },
    ),
  ]);
}
