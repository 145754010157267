import styled from "styled-components";

export const Toolbar = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // padding + 1.5 for icon buttons
  height: 3.5rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
export const ToolbarWithBorderBottom = styled(Toolbar)`
  border-bottom: 1px solid ${(props) => props.theme.palette.background.border};
`;
ToolbarWithBorderBottom.displayName = "plugins/muiToolbar/Toolbar/WithBorderBottom";

export const Spacer = styled.span`
  width: 1rem;
  height: 2rem;
`;

export const FillSpace = styled.span`
  flex: 1;
`;

export const Group = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`;
