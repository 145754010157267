import { init } from '@common/EEContext';
import React from 'react';
import type dagre from 'dagre';
import forEach from 'lodash/forEach';

export type StoreState = {
  baselineDebugPayload: Record< string, unknown >;
  latestDebugPayload: StoreState[ 'baselineDebugPayload' ]
};
export const defaultState: StoreState = {
  baselineDebugPayload: {},
  latestDebugPayload: {},
};

export const DebugEEContextNS = init(defaultState);
DebugEEContextNS.WithCtx.displayName = 'release/Debug/EEContext/WithCtx';

// ===================================================================================

type BaseT = StoreState[ 'baselineDebugPayload' ]
export type DebugPayloadUtils = {
  useChange: () => (v: BaseT) => unknown;
  useReset: () => (transferLatestToBase?: true) => unknown
};
export const debugPayloadUtils: DebugPayloadUtils = {
  useChange: () => {
    const update = DebugEEContextNS.useUpdate();

    return React.useCallback < ReturnType< DebugPayloadUtils[ 'useChange' ] > >(
      v => void update(draft => {
        if(Object.keys(draft.baselineDebugPayload).length === 0) {
          // eslint-disable-next-line no-param-reassign
          draft.baselineDebugPayload = v;
        }

        // eslint-disable-next-line no-param-reassign
        draft.latestDebugPayload = v;
      }),
      [update],
    );
  },
  useReset: () => {
    const update = DebugEEContextNS.useUpdate();

    return React.useCallback < ReturnType< DebugPayloadUtils[ 'useReset' ] > >(
      transferLatestToBase => void update(draft => {
        // eslint-disable-next-line no-param-reassign
        draft.baselineDebugPayload = transferLatestToBase
          ? draft.latestDebugPayload
          : defaultState.baselineDebugPayload;

        // eslint-disable-next-line no-param-reassign
        draft.latestDebugPayload = defaultState.latestDebugPayload;
      }),
      [update],
    );
  },
};

export function replaceIdsWithDescriptions(
  data: Record< string, unknown>,
  graph: dagre.graphlib.Graph<{ id: string; description: string}>,
): typeof data {
  return Object.keys(data).reduce(
    (a, id) => {
      const maybeN = graph.node(id);
      const key = maybeN && maybeN.description || id;

      // eslint-disable-next-line no-param-reassign
      a[ key ] = data[ id ];
      return a;
    },
    {},
  );
}

const findInGraph = (graph, value, key = 'description') => {
  let result;
  forEach(graph.nodes(), id => {
    let node = graph.node(id);
    if (node && node[key] === value) {
      result = node;
      return false; // Stop searching
    }
  })
  return result;
}

export function replaceDescriptionWithIds(
  data: Record< string, unknown>,
  graph: dagre.graphlib.Graph<{ id: string; description: string}>,
): typeof data {
  return Object.keys(data).reduce(
    (a, desc) => {
      const maybeN = findInGraph(graph, desc)
      const key = maybeN && maybeN.id || desc;

      // eslint-disable-next-line no-param-reassign
      a[ key ] = data[ desc ];
      return a;
    },
    {},
  );
}
// ===================================================================================

// (DebugEEContextNS.ctx.Consumer as any)._currentValue.subscribe(s => {
//   console.log(5555, s.baselineDebugPayload, s.latestDebugPayload);
// });
