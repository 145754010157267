import Typography from '@material-ui/core/Typography';
import { showModal } from '@imminently/imminently_platform';
import { TextField, Dropdown, ChipInput, Switch } from '@components';
import { useProject, useReleases } from '@common';
import * as Yup from 'yup';
import { useScope } from '../redux/scope';
import { useCrudForm } from './hooks';
import { ModalFields, ModalActions } from './components';
import { showConfirmation } from './ConfirmationModal';
import { LOCALE_OPTIONS } from './ProjectModal_localeOptions';
import { Project } from '@packages/commons';
import { useDispatch } from 'react-redux';
import { projectService } from 'services';
import { Trans, useTranslation } from 'react-i18next';


const ProjectSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  //description: Yup.string().required('Required'),
});

const ProjectModal = ({ project }: { project?: Project }) => {
  const { workspace } = useScope();
  const { t } = useTranslation();

  const formik = useCrudForm({
    resource: 'models',
    id: project?.id ?? undefined,
    formikValues: {
      initialValues: {
        name: project?.name ?? '',
        description: project?.description ?? '',
        tags: project?.tags ?? [],
        visibility: project?.visibility ?? 'public',
        locale: project?.locale ?? 'en-au',
        locked: project?.locked ?? false,
        // users: [],
        // permissions: {},
        workspace,
      },
      validationSchema: ProjectSchema,
    },
    filter: ['name', 'description', 'tags', 'visibility', 'workspace', 'locale', 'locked'],
  });

  const { values, handleChange, handleBlur, submitForm, isSubmitting, setFieldValue, errors, touched } = formik;

  const isCreate = project === undefined;
  const modalActions = {
    primary: {
      name: isCreate ? t('create') : t('update'),
      onClick: () => {
        submitForm()
          .then(res => {
            // TODO temp workaround while we are inbetween the old and new API
            projectService.invalidateAllQueries();
          })
      },
      disabled: isSubmitting,
      loading: isSubmitting,
    },
  };

  const handleSetTags = (tags) => {
    setFieldValue('tags', tags);
  };

  // const setMembers = users => {
  //   setFieldValue('users', users);
  // };

  // const setAccess = access => {
  //   setFieldValue('permissions', access);
  // };

  const capitaliseFirstLetter = (string: string): string => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  return (
    <>
      <ModalFields>
        <TextField
          name='name'
          value={values.name}
          helperText={touched.name && (errors.name as string)}
          error={touched.name && Boolean(errors.name)}
          onChange={handleChange}
          onBlur={handleBlur}
          label={t('projects.project_name')}
          fullWidth
        />

        <TextField
          name='description'
          value={values.description}
          helperText={touched.description && (errors.description as string)}
          error={touched.description && Boolean(errors.description)}
          onChange={handleChange}
          onBlur={handleBlur}
          label={t('description')}
          fullWidth
        />

        <ChipInput
          fullWidth
          label={t('tags')}
          defaultValue={values.tags}
          onChange={handleSetTags}
          blurBehavior='add'
        />

        {/* @ts-ignore TS not my problem right now */}
        <Dropdown fullWidth
          name='visibility'
          label={t('projects.visibility_label')}
          value={values.visibility}
          items={[
            {
              key: capitaliseFirstLetter(t('projects.visibility_public')),
              value: 'public',
            },
            {
              key: capitaliseFirstLetter(t('projects.visibility_private')),
              value: 'private',
            }
          ]}
          onChange={handleChange}
        />

        {/* @ts-ignore TS not my problem right now */}
        <Dropdown fullWidth
          name='locale'
          label={t('projects.locale_label')}
          value={values.locale}
          items={LOCALE_OPTIONS}
          onChange={handleChange}
        />

        <Switch
          name='locked'
          label={t('projects.lock_project')}
          checked={values.locked}
          onChange={handleChange}
        />

        {/* <MemberList
          members={values.users}
          access={values.permissions}
          setMembers={setMembers}
          setAccess={setAccess}
        /> */}
      </ModalFields>
      <ModalActions {...modalActions} />
    </>
  );
};

export const addProject = (t) => showModal(
  {
    open: true,
    title: t('projects.create_label'),
  },
  <ProjectModal />,
);

export const useEditProject = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (project?: Project) =>
    dispatch(
      showModal(
        {
          open: true,
          title: project ? t('projects.edit_project') : t('projects.create_label'),
        },
        <ProjectModal project={project} />,
      )
    );
};

export const archiveProject = id => {
  const { t } = useTranslation();
  const Body = () => {
    const { name } = useProject(id);
    const { total } = useReleases(id);
    return (
      <Typography variant='body1' paragraph={false}>
        <Trans i18nKey="projects.archive_project_description">
        {/* @ts-ignore */}
        Are you sure you want to archive this this project <b>{{name}}</b>? This will affect {{total}} releases.
        </Trans>
      </Typography>
    );
  };

  return showConfirmation({
    title: t('projects.archive_project'),
    body: <Body />,
    actions: {
      primary: {
        name: t('projects.confirm_archive'),
        onClick: () => { /**  */ },
      },
    },
  });
};

export const deleteProject = id => {
  const { t } = useTranslation();

  const Body = () => {
    const { name } = useProject(id);
    const { total } = useReleases(id);
    return (
      <Typography variant='body1' paragraph={false}>
        <Trans i18nKey="projects.delete_project_description">
          {/* @ts-ignore */}
          Are you sure you want to delete this project <b>{{name}}</b>? This will affect {{total}} releases.
        </Trans>
      </Typography>
    );
  };

  return showConfirmation({
    title: t('projects.delete_project'),
    body: <Body />,
    actions: {
      primary: {
        name: t('documents.confirm_delete'),
        onClick: () => { /** */ },
      },
      secondary: [
        {
          name: t('export_and_delete'),
          onClick: () => { /** */ },
        },
      ],
    },
  });
};
