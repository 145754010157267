import React from "react";
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import FormHelperText from '@material-ui/core/FormHelperText';
import type { TextFieldProps } from '@material-ui/core/TextField';
import DateRangeIcon from '@material-ui/icons/DateRange';
import dayjs from 'dayjs';
import { DatePicker, type DatePickerProps } from "@material-ui/pickers";
import { SecondaryButton } from '../buttons'
import { TextField } from '../input';
import { trimAttributeInterpolation } from "@pages/models/release/Interview/Designer/__trimAttributeInterpolation";

const Wrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;
const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
`;
const HiddenDatePicker = styled( DatePicker )`
  display: none;
`;
const DateRangeIconButton = styled( IconButton )`
  width: 2.5rem;
`;

export type InterviewDesignDatePickerProps = {
  className?: string;
  value: string;
  setValue: ( v: string ) => unknown;
  label?: string;
  helperText?: string;
  isError?: boolean;
};
export const InterviewDesignDatePicker: React.FC< InterviewDesignDatePickerProps > = React.memo( p => {
  const { value, className, setValue, label, helperText, isError } = p;
  const setNow = React.useCallback(() => {
    setValue( 'now' );
  }, [ setValue ]);

  const manualOnChange = React.useCallback< NonNullable< TextFieldProps[ 'onChange' ] > >((e) => {
    const { value } = e.currentTarget;
    const withTrimmedAttrInterpolation = trimAttributeInterpolation( value )

    setValue( withTrimmedAttrInterpolation );
  }, []);


  const [ datePickerOpen, setDatePickerOpen ] = React.useState( false );
  const closeDatePicker = React.useCallback(() => (
    setDatePickerOpen(false)
  ), [ setDatePickerOpen ]);
  const openHiddenDatepicker = React.useCallback(() => {
    setDatePickerOpen( true );
  }, [ setDatePickerOpen ]);

  const setDateInDatePicker = React.useCallback< DatePickerProps[ 'onChange' ] >(v => {
    if(!v) {
      setValue( '' );
      return;
    }

    setValue( dayjs(v.toISOString()).format('YYYY-MM-DD') );
  }, []);

  return (
    <Wrap className={ className }>
      <TopRow>
        <TextField
          variant='outlined'
          value={ value }
          onChange={ manualOnChange }
          label={ label }
        />

        <HiddenDatePicker
          value={ null }
          onChange={ setDateInDatePicker }
          open={ datePickerOpen }
          onClose={ closeDatePicker }
        />

        <DateRangeIconButton onClick={ openHiddenDatepicker }>
          <DateRangeIcon />
        </DateRangeIconButton>

        <SecondaryButton onClick={ setNow }>
          "now"
        </SecondaryButton>
      </TopRow>

      { helperText === undefined ? null : (
        <FormHelperText error={ isError }>
          { helperText }
        </FormHelperText>
      ) }
    </Wrap>
  );
});
InterviewDesignDatePicker.displayName = 'components/InterviewDesignDatePicker/Comp';
