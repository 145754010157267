import { ReactNode } from "react";
import styled from "styled-components";
import { Button } from "@components/buttons";
import type { PropsFor } from "@material-ui/system";

const ActionArea = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

const SecondaryActions = styled.div`
  > *:not(:first-child) {
    margin-left: 0.5rem;
  }
`;

export interface Action extends PropsFor<typeof Button> {
  name: string;
  icon?: ReactNode;
}

export type Actions = {
  primary: Action;
  secondary?: Action[];
};

export interface ModalActionsProps extends PropsFor<typeof ActionArea> {
  primary: Action;
  secondary?: Action[];
}

export const ModalActions = ({ primary, secondary, ...aProps }: ModalActionsProps) => {
  const { name: pName, icon: pIcon, ...rest } = primary;
  return (
    <ActionArea {...aProps}>
      <SecondaryActions>
        {secondary &&
          secondary.map(({ name, icon, ...props }) => (
            <Button key={name} aria-label={name} type='secondary' startIcon={icon} {...props}>
              {name}
            </Button>
          ))}
      </SecondaryActions>
      <Button type='primary' aria-label={pName} startIcon={pIcon} {...rest}>
        {pName}
      </Button>
    </ActionArea>
  );
};

export const ModalFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
`;
