/* eslint-disable @typescript-eslint/no-shadow */
import { isTemporal, Node } from "../../pages/decisions/DecisionDashboard/Reports/Details/UnifiedTimeline/types";
import type { Props } from "./DatesFilter_types";


const START_TIME = "-271821-04-20T00:00:00.000Z";
const END_TIME = "+275760-09-13T00:00:00.000Z";


export const mergeValueAndDatesFilter = (v: Node["value"], datesFilter: Props[ "value" ]): typeof v => {
  if (!datesFilter || !isTemporal(v)) return v;

  const [dFilt0, dFilt1] = datesFilter;
  if (dFilt0 === null || dFilt1 === null) return v;

  const start = dFilt0.split("T")[ 0 ];
  const end = dFilt1.split("T")[ 0 ];

  const nextDerived = v.temporal.ranges.reduce< Node["value"] >(
    (a, { r, v }) => {
      if (a !== undefined) return a;

      const [rangeStart] = r.start.split("T");
      const [rangeEnd] = r.end.split("T");
      if (r.start !== START_TIME && rangeStart > start) return a;
      if (r.end !== END_TIME && rangeEnd < end) return a;

      return typeof v === "object" && v !== null ? undefined : v;
    },
    undefined,
  );

  return nextDerived;
};
