import { useHistory, useLocation } from "react-router";

/**
 * Routing hook to insulate against changes to `react-router`.
 */
export const useRouting = () => {
  const history = useHistory();
  const location = useLocation();

  const goToPage = (route: string, state?: any) => {
    history.push(route, state);
  };

  return {
    goToPage,
    routingState: location.state,
  };
};
