import { type HotkeyOptions, createParagraph, isLastChild, transformRange } from "@common/editor/plugins/util";
import { ELEMENT_PARAGRAPH } from "@udecode/plate";
import { type TElement, getNodeString, insertNodes, moveNodes } from "@udecode/plate-core";
import { Path } from "slate";
import { ELEMENT_CONCLUSION, ELEMENT_CONDITION } from "../elements";
import { getOperatorLevel } from "../operator";
import { getRuleLevel, paragraphToRule } from "../rule";
import { isWithinSection } from "../section";

export const paragraphTabbing: HotkeyOptions = {
  keys: "tab",
  action: (editor) => {
    return transformRange(
      editor,
      (n) => n.type === ELEMENT_PARAGRAPH,
      ([, path]) => {
        // TODO add shift tab
        // if level is > 0, convert to condition
        // otherwise if previous is conclusion or operator, convert to condition and move
        // otherwise conclusion
        const level = getRuleLevel(editor, path);
        const type = level > 0 ? ELEMENT_CONDITION : ELEMENT_CONCLUSION;
        paragraphToRule(editor, path, type);
      },
    );
  },
};

export const paragraphEnter: HotkeyOptions = {
  keys: "enter",
  action: (editor, plugin, nodeEntry) => {
    const [node, path] = nodeEntry;
    if (node.type === ELEMENT_PARAGRAPH) {
      const isEmpty = getNodeString(node).length === 0;
      const level = getOperatorLevel(editor, path);
      const inSection = isWithinSection(editor, path);
      const lastChild = isLastChild(editor, path);
      // const previous = getPreviousNode(editor, { at: path });
      // const previousIsEmptyPara = previous?.[0].type === ELEMENT_PARAGRAPH && getNodeString(previous[0]).length === 0;
      // if empty and is last child
      // TODO shift enter is interesting as is used by rule break
      if (isEmpty && lastChild) {
        // if we are pressing enter on the second empty paragraph, break out of the operator
        if (level > 0 || inSection) {
          moveNodes(editor, { at: path, to: Path.next(path.slice(0, -1)) });
          // we should remove the duplicate empty one as well
          // removeNodes(editor, { at: previous[1] });
          return true;
        }
      }
    }
    return false;
  },
};

export const headingEnter: HotkeyOptions = {
  keys: "enter",
  action: (editor, plugin, nodeEntry) => {
    const [node, path] = nodeEntry;
    if ((node as TElement).type.match(/^[hH][0-6]/)) {
      if (!editor.selection) return false;
      const { offset } = editor.selection.anchor;
      // if at start, insert before
      if (offset === 0) {
        insertNodes(editor, createParagraph(), { at: path, select: true });
        return true;
      }
      // if at end, insert after
      if (offset === getNodeString(node).length) {
        insertNodes(editor, createParagraph(), { at: Path.next(path), select: true });
        return true;
      }
    }
    return false;
  },
};

export const paragraphShiftEnter: HotkeyOptions = {
  keys: "shift+enter",
  action: (editor, plugin, nodeEntry, event) => {
    const [node, path] = nodeEntry;
    if (node.type === ELEMENT_PARAGRAPH) {
      console.log("paragraph shift enter!", { path, shift: event.shiftKey });
      // const isEmpty = getNodeString(node).length === 0;
      // const level = getOperatorLevel(editor, path);
      // const inSection = isWithinSection(editor, path);
      // const lastChild = isLastChild(editor, path);
      // const previous = getPreviousNode(editor, { at: path });
      // const previousIsEmptyPara = previous?.[0].type === ELEMENT_PARAGRAPH && getNodeString(previous[0]).length === 0;

      // shift enter should insert a new paragraph
      // have to manually create, as passing on will lead into other plugins
      insertNodes(editor, createParagraph(), { at: Path.next(path), select: true });

      return true;
    }
    return false;
  },
};
