import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Typography, IconButton, Tooltip } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Avatar, Dropdown } from '@components';
import { ClearIcon, TooltipIcon } from '@icons';

import { crudGetList } from '@imminently/imminently_platform';
import { useUser } from './hooks';
import { UserDropdown } from './UserDropdown';
import { scrollable } from './scrollbar';

const ListItem = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.5rem;
  height: 3rem;
  padding: 0 1rem;

  &:hover {
    background-color: ${ p => p.theme.palette.secondary.hover };
  }
`;

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  justify-content: center;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const Members = styled.div`
  border-radius: 0.5rem;
  border: 1px solid ${ p => p.theme.palette.background.border };
  background-color: ${ p => p.theme.palette.background.light };
  padding: 0.5rem 0;
  // max is padding + 3 items + border
  max-height: calc(1rem + (3 * 3rem) + 2px);
  min-height: calc(1rem + 3rem + 2px);
`;

const tooltips = {
  Owner: 'User has full access',
  Edit: 'User can edit but cannot delete',
  Read: 'User can read-only',
};

const getUser = id => crudGetList('auth', 'default', { 
  filter: { identity_id: id },
  subResource: 'users'
});

const UserItem = ({ id, access, onRemove, onChangeAccess }) => {
  const dispatch = useDispatch();
  const user = useUser(id);

  useEffect(() => {
    if(!user) {
      dispatch(getUser(id));
    }
  }, [dispatch, user, id]);

  // need to wait for user to load
  if(!user) {
    return (
      <ListItem key={id}>
        <Skeleton variant='circle' width='2.5rem' height='2.5rem' />
        <UserDetails style={{ justifyContent: 'space-evenly' }}>
          <Skeleton variant='rect' width='60%' height='0.75rem' />
          <Skeleton variant='rect' width='45%' height='0.75rem' />
        </UserDetails>
        <Actions>
          <Skeleton variant='circle' width='1.5rem' height='1.5rem' />
          <Skeleton variant='circle' width='1.5rem' height='1.5rem' />
        </Actions>
      </ListItem>
    );
  }

  // const pProps = { modifiers: { offset: { offset: '0, -10' }, flip: { enabled: false } } };
  // user.email = 'test@example.com';
  return (
    <ListItem key={id}>
      <Avatar size='default'>{user.first_name[0]}{user.last_name[0]}</Avatar>
      <UserDetails>
        <Typography variant='caption' noWrap><b>{user.first_name}</b> {user.last_name}</Typography>
        <Typography variant='caption' noWrap>{user.email}</Typography>
      </UserDetails>
      {/* <Dropdown
        style={{ width: '10rem' }}
        value={access}
        items={['Owner', 'Edit', 'Read']}
        onChange={(e, v) => onChangeAccess(user, v.props.value)}
      /> */}
      <Actions>
        {/* <Tooltip title={tooltips[ access ]} placement='left' PopperProps={pProps}>
          <TooltipIcon style={{ margin: '0 0.25rem' }} />
        </Tooltip> */}
        <IconButton onClick={() => onRemove(id)}><ClearIcon /></IconButton>
      </Actions>
    </ListItem>
  );
};

export const MemberList = ({ members = [], access = {}, setMembers, setAccess }) => {
  const onChange = users => {
    setMembers(users);
    // multi means use value from access, default to read
    // console.log('setting users', users, access);
    setAccess(users.reduce((acc, u) => {
      const id = u;
      acc[ id ] = access[ id ] ?? 'Read';
      return acc;
    }, {}));
  };

  const setUserAccess = (user, value) => {
    // console.log('setUserAccess', access, user, value);
    setAccess({ ...access, [ user ]: value });
  };

  const removeUser = user => {
    onChange(members.filter(u => u !== user));
  };

  return (
    <div>
      <UserDropdown value={members} onChange={onChange} />
      <Members className={scrollable}>
        {
          members.length > 0 ? members.map(u => (
            <UserItem
              key={u}
              id={u}
              access={access[ u ]}
              onRemove={removeUser}
              onChangeAccess={setUserAccess}
            />
          )) : <Typography style={{ lineHeight: '3rem' }} align='center' variant='body1'>No members assigned</Typography>
        }
      </Members>
    </div>
  );
};
