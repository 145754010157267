import React from "react";
import cn from "clsx";
import { useToggle } from "../../../common/hooks/HooksGeneral";
import { ClickAwayListener, Tab, Tabs, Typography } from "@material-ui/core";
import { PallettIcon as PallettIcon_} from "@icons"
import { withStyles } from "../../../common/Styling";
import { graphNodeStyles } from "./GraphNode";
import {TabsMini, TabMini} from "../../tabs/mini/TabsMini";
import { GraphColorScheme } from "../../GraphVisualisation/providers/RuleGraphProvider";
import { useGraphVisualisation } from "../../GraphVisualisation/hooks/useGraphVisualisation";

import stl from "./GraphPallet.module.scss";

const PallettIcon = PallettIcon_ as any;
interface GraphPalletProps {

}

/**
 * React Flow
 * @param props
 * @returns
 */
const GraphPallet = (props: GraphPalletProps & { classes?: any }) => {

  const {
    colorScheme,
    setColorScheme,
    debug,
    entityColorMapping,
  } = useGraphVisualisation();
  const isExpanded = useToggle(false);
  const tabs: { value: GraphColorScheme , label: string, ifDebug: boolean[] }[] = [
    {value: "by-value", label: "By Value", ifDebug: [true]},
    {value: "by-type", label: "By Type", ifDebug: [false]},
    {value: "by-entity", label: "By Entity", ifDebug: [true, false]},
  ];
  const visibleTabs = tabs.filter((it) => it.ifDebug.some((it2) => it2 === debug));
  const [tabIndex, setTabIndex] = React.useState<number>(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
    const newColorScheme = visibleTabs[newValue].value;
    setColorScheme(newColorScheme);
  };

  React.useEffect(() => {
    const tabIndexForColorScheme = visibleTabs.findIndex((tab) => (tab.value === colorScheme));
    if (!debug && colorScheme === "by-value") {
      setColorScheme("by-type");
    } else if (!debug && tabIndexForColorScheme !== -1 && tabIndexForColorScheme !== tabIndex) {
      setTabIndex(tabIndexForColorScheme);
    } else {
      setTabIndex(0);
      setColorScheme(visibleTabs[0].value);
    }
  }, [debug]);

  // -- render

  const renderToggleButton = () => {
    return (
      <div
        className={stl.container_btn}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          isExpanded.toggle();
        }}
      >
        <div
          className={stl.svg_icon}
        >
          <PallettIcon
            style={{
              fill: isExpanded.value ? "#70F058" : "#000000",
            }}
          />
        </div>
        {/* <div
          className={stl.colored_circle}
        /> */}
      </div>
    );
  };

  const renderSwatchCard = (key: number, color: string, label: string) => {

    const backgroundColor = (() => {
      if (color.startsWith("--")) {
        return (`var(${color})`);
      }
      return (color);
    })();

    return (
      <div
        key={key}
        className={stl.swatch_card}
      >
        <div
          className={stl.swatch}
          style={{
            backgroundColor,
          }}
        />
        <div
          className={stl.swatch_label}
        >
          <Typography
            variant="caption"
          >
            {label}
          </Typography>
        </div>
      </div>
    );
  };

  const renderPalletByType = () => {

    return (<>
      {renderSwatchCard(1, "--input-bg-color", "Source/ Input")}
      {renderSwatchCard(2, "--intermediate--bg-color", "Intermediate")}
      {renderSwatchCard(3, "--goal-bg-color", "Goal")}
    </>);
  };

  const renderPalletByValue = () => {

    return (<>
      {renderSwatchCard(1, "--value-color-unknown", "Unknown")}
      {renderSwatchCard(2, "--value-color-uncertain", "Uncertain")}
      {renderSwatchCard(3, "--value-color-falsy", "Falsey")}
      {renderSwatchCard(4, "--value-color-default", "Truthy")}
    </>);
  };

  const renderPalletByEntity = () => {

    const entitySwatches = entityColorMapping.map((mapping, idx) => renderSwatchCard(idx, mapping.colorKey, mapping.entity));

    return (<>
      {entitySwatches}
    </>);
  };

  const renderPalletSwatches = () => {

    switch (colorScheme) {
      case "by-type":
        return renderPalletByType();
      case "by-value":
        return renderPalletByValue();
      case "by-entity":
        return renderPalletByEntity();
      default:
        return null;
    }

    return (null);
  };

  const renderPallet = () => {

    return (
      <ClickAwayListener
        mouseEvent={isExpanded.value ? "onClick" : false}
        touchEvent={isExpanded.value ? "onTouchStart" : false}
        onClickAway={isExpanded.forceFalse}
      >
        <div
          className={cn(
            stl.container_pallet,
            {[stl.expanded]: isExpanded.value},
          )}
        >
          <div
            className={cn(
              stl.col_lhs,
              {[stl.hidden]: false},
            )}
          >
            <TabsMini
              orientation="vertical"
              value={tabIndex}
              onChange={handleTabChange}
            >
              {
                visibleTabs.map((tab, idx) => (
                  <TabMini
                    key={idx}
                    label={tab.label}
                    wrapped={true}
                  />
                ))
              }
            </TabsMini>
          </div>
          <div
            className={stl.col_rhs}
          >
            {renderPalletSwatches()}
          </div>
        </div>
      </ClickAwayListener>
    );
  };

  return(
    <div
      className={props?.classes?.root}
    >
      {renderToggleButton()}
      {renderPallet()}
    </div>
  );
};

export default withStyles(graphNodeStyles)(GraphPallet);
