import type { PlateProps } from "@udecode/plate-core";
import { useLocalStorage } from "@uidotdev/usehooks";
import produce from "immer";
import { type PropsWithChildren, createContext, useContext, useState } from "react";
import type { SplitProps } from "react-split";
import styled from "styled-components";

import { AntSwitch, Label, Tabs } from "@components";
import type { ReleaseDocument } from "@packages/commons";
import { useIsProjectWriter } from "auth/permissions";
import { getCurrentUser } from "global";
import { useTranslation } from "react-i18next";
import { FillSpace } from "../plugins/muiToolbar";

export enum EditorState {
  source = "source",
  rules = "rules",
  compare = "compare",
}

export type EditorContextType = {
  state: EditorState;
  focused: string;
  isCompare: boolean;
  isSource: boolean;
  isRules: boolean;
  toggleCompare: () => void;
  toggleSource: () => void;
  setEditorState: (state: EditorState) => void;
  paneProps: any; // Replace with the actual type
  editableProps: PlateProps["editableProps"];
};

const ControlBar = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 1.25rem;
  gap: 1rem;
  height: 4rem;
`;

const getPaneProps = (state: EditorState): SplitProps => {
  switch (state) {
    case EditorState.source:
      return {
        sizes: [100, 0],
        minSize: [100, 0],
        maxSize: [100, 0],
        gutterSize: 0,
        gutterStyle: () => ({ display: "none" }),
      };
    case EditorState.rules:
      return {
        sizes: [0, 100],
        minSize: [0, 100],
        maxSize: [0, 100],
        gutterSize: 0,
        gutterStyle: () => ({ display: "none" }),
      };
    case EditorState.compare:
      return {
        sizes: [50, 50],
        minSize: [300, 300],
        // gutterSize: 8,
        gutterStyle: () => ({ display: "block", width: "8px" }),
      };
  }
};

export const EditorContext = createContext<EditorContextType | undefined>(undefined);

type DocumentSettings = {
  editorState: EditorState;
};

type StoredSettings = Record<string, DocumentSettings>;

const defaultSettings = (rulesOnly: boolean): DocumentSettings => ({
  editorState: rulesOnly ? EditorState.rules : EditorState.compare,
});

const DOCUMENT_SETTINGS_KEY = "immi.settings.documents";

// load user persist settings for the document stored in localStorage
const usePersistedSettings = (id: string, rulesOnly = false) => {
  // using new useLocalStorage hook, as the react-use one is being weird with TS
  // our custom one isnt exporting correctly in the package
  const [settings, setSettings] = useLocalStorage<StoredSettings>(DOCUMENT_SETTINGS_KEY, {});
  // get doc settings
  const docSettings = settings[id] ?? defaultSettings(rulesOnly);

  // editor state set function
  const setEditorState = (state: EditorState) => {
    const newState = produce(settings, (draft) => {
      if (!draft[id]) {
        draft[id] = defaultSettings(rulesOnly);
      }
      draft[id].editorState = state;
    });
    setSettings(newState);
  };

  // don't export full setSettings, as we want to control the shape of the settings object
  return { settings: docSettings, setEditorState };
};

export const EditorProvider = ({ document, children }: { document: ReleaseDocument } & PropsWithChildren) => {
  const isProjectWriter = useIsProjectWriter();
  const { settings, setEditorState } = usePersistedSettings(document.reference);
  const [focusedEditor, setFocusedEditor] = useState(
    () =>
      // document.rulesOnly ? EditorState.rules : EditorState.source,
      EditorState.source,
  );

  const { editorState } = settings;
  const paneProps = getPaneProps(editorState);

  const editableProps: PlateProps["editableProps"] = {
    autoFocus: editorState !== EditorState.compare,
    readOnly:
      !isProjectWriter || (document.locked ? document.locked.identity_id !== getCurrentUser()?.profile.oid : false),
    onFocus: (e) => {
      const id = e.target.getAttribute("data-slate-editor-id");
      if (id) {
        setFocusedEditor(id as EditorState);
      }
    },
  };

  const focused = editorState === EditorState.compare ? focusedEditor : editorState;
  const isCompare = editorState === EditorState.compare;
  const isSource = editorState === EditorState.source;
  const isRules = editorState === EditorState.rules;

  const toggleCompare = () => {
    setEditorState(isCompare ? EditorState.source : EditorState.compare);
  };

  const toggleSource = () => {
    setEditorState(isSource ? EditorState.rules : EditorState.source);
  };

  return (
    <EditorContext.Provider
      value={{
        state: editorState,
        focused,
        isCompare,
        isSource,
        isRules,
        toggleCompare,
        toggleSource,
        setEditorState,
        paneProps,
        editableProps,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};

export const useMultiEditor = () => {
  const context = useContext(EditorContext);
  if (context === undefined) {
    throw new Error("useMultiEditor must be used within a EditorProvider");
  }
  return context;
};

export type EditorControlsProps = {
  editor: EditorState;
};

const StyledTabs = styled(Tabs)`
  .MuiTabs-flexContainer {
    height: 1.5rem;
  }
`;

export const EditorControls = ({ editor }: EditorControlsProps) => {
  const { state, toggleCompare, setEditorState } = useMultiEditor();
  const { t } = useTranslation();
  const hideCompare = state === EditorState.compare && editor === EditorState.source;

  const setTabHandler = (_, value: string) => {
    setEditorState(value as EditorState);
  };

  const tabs = [
    { label: t("metadata.source"), value: EditorState.source },
    { label: t("build.rules"), value: EditorState.rules },
  ];

  return (
    <ControlBar>
      {state !== EditorState.compare ? (
        <StyledTabs
          current={state}
          tabs={tabs}
          onChange={setTabHandler}
        />
      ) : (
        <Label
          color="#e5e5e5"
          style={{ textTransform: "capitalize" }}
        >
          {/* find label in tabs */}
          {tabs.find((tab) => tab.value === editor)?.label ?? editor}
        </Label>
      )}
      <FillSpace />
      {!hideCompare && (
        <AntSwitch
          smallSwitch
          invertOnCheck
          label={t("documents.side_by_side")}
          checked={state === EditorState.compare}
          onChange={toggleCompare}
        />
      )}
    </ControlBar>
  );
};
