import { lazy } from "react";
import styled from "styled-components";

import ReleaseWrapper from "../pages/models/release/ReleaseWrapper";
import * as ScopeComponentNS from "../pages/decisions/DecisionDashboard/ScopeComponent";
import { Roles } from "auth/permissions";

const DecisionDashboard = lazy(() => import("../pages/decisions/DecisionDashboard"));
const StandAlone = lazy(() => import("../pages/decisions/DecisionDashboard/Reports/Report/StandAlone"));

const DecisionDashboardWrap = styled.div`
  height: 100% ;
  display: flex ;
  flex-direction: column ;
  overflow: auto;

  .common-page-header >div >div {
    width: 100% !important;

    >.MuiGrid-item {
      flex-grow: 1;
    }
  }
`;

export const monitor = [
  {
    path: "/monitor",
    component: () => (
      <DecisionDashboardWrap>
        <ReleaseWrapper title='Monitor' scopeComponent={ScopeComponentNS._}>
          <DecisionDashboard />
        </ReleaseWrapper>
      </DecisionDashboardWrap>
    ),
    layout: "decisively",
    role: Roles.ReportViewer,
    exact: true,
  },
  {
    path: "/monitor/:reportId",
    layout: "async",
    component: StandAlone,
    role: Roles.ReportViewer,
    exact: true,
  },
];
