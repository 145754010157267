import React from 'react';
import type { Dispatch } from 'redux';
import {
  CRUD_GET_ONE_SUCCESS,
  crudUpdate,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from '@imminently/imminently_platform';
import get from 'lodash/get';
import set from 'lodash/set';
import * as EEContextNS from '@common/EEContext';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector as useSelectorRedux } from 'react-redux';
import * as combinatorV2 from '../../../../../redux/combinatorV2.saga';
import * as reduxScopeNS from '../../../../../redux/scope';
import type { DecReportTreeData, Report } from '../constants';
import { useTreeRef } from './mutable';
import { useCurrentReport } from '../__useCurrentReport';


export type State = {
  report: { data: DecReportTreeData; };
  selected: DecReportTreeData[0];
}

export const defaultState: State = {
  report: { data: [] },
  selected: {
    _contents: {
      path: [],
      parentPath: [],
      nodeInGraph: { description: '', id: '', type: '' },
      nodeInReport: { id: '' },
    },
  },
};


const { useDerive, useSelector, useUpdate, WithCtx } = EEContextNS.init(defaultState);

export const WithImmutableCtx = WithCtx;
WithImmutableCtx.displayName = 'release/Decision/context/immutable';

type ReportInState = State[ 'report' ];
export const useReport = (): ReportInState => useSelector(s => s.report);
export const useTreeData = (): ReportInState[ 'data' ] => useSelector(s => s.report.data);

// ===================================================================================

type UpdateSingletonState = (
  | { type: 'idle' }
  | { type: 'updating' }
  | { type: 'updatingAndHasQueuedUpdate' }
)

const UPDATE_SINGLETON_DEBOUNCE_PERIOD = 1_300;

const updateSingleton = new class {
  __state: UpdateSingletonState = { type: 'idle' };

  update = debounce(
    (arg: {
      getTreeData: () => ReportInState[ 'data' ];
      report: Report;
      dispatch: Dispatch;
      releaseId: string;
    }) => {
      const { releaseId, getTreeData, dispatch, report } = arg;
      const treeData = getTreeData();

      if(treeData[ 0 ] === undefined) return;

      if(this.__state.type === 'updatingAndHasQueuedUpdate') return;
      if(this.__state.type === 'updating') {
        this.__state = { type: 'updatingAndHasQueuedUpdate' };

        return;
      }

      // ===================================================================================

      this.__state = { type: 'updating' };

      const updatedReport: Report = {
        ...report,
        nodes: (function gatherNodes({ _contents: { nodeInReport }, children = [] }: typeof treeData[0]): Report['nodes' ] {
          return {
            [ nodeInReport.id ]: nodeInReport,
            ...(Array.isArray(children) ? children.reduce< Report['nodes' ] >(
              (a, it) => ({ ...a, ...gatherNodes(it) }),
              {},
            ) : {}),
          };
        }(treeData[ 0 ])),
      };

      const afterUpdate = () => {
        const { __state: stateRightAfterUpdate } = this;

        if(stateRightAfterUpdate.type === 'idle') {
          throw new Error('JirHMJoWuf this shouldn\'t happen');
        }

        this.__state = { type: 'idle' };
        if(stateRightAfterUpdate.type === 'updatingAndHasQueuedUpdate') {
          this.update(arg);
        }
      };

      dispatch(
        combinatorV2.ActionCreator({
          bootstrap: crudUpdate('releases', releaseId, updatedReport, {
            subResource: 'report',
            id: updatedReport.id,
          }, { refresh: false }),
          success: {
            type: CRUD_GET_ONE_SUCCESS,
            validate: (v: any) => v.meta.resource === 'releases' && v.meta.id === releaseId,
            call: afterUpdate,
          },
        }),
      );
    },
    UPDATE_SINGLETON_DEBOUNCE_PERIOD,
  )
}();

// ===================================================================================

export const useSetReport = (): (recipe: (report: ReportInState) => ReportInState | void, skipSave?: true) => unknown => {
  const update = useUpdate();
  const dispatch = useDispatch();
  const derive = useDerive();
  const getTreeData = React.useCallback(() => derive(s => s.report.data), [derive]);
  const currentReleaseId = useSelectorRedux((s: any) => (s.scope as reduxScopeNS.Scope).release);
  const curDecReport = useCurrentReport();


  return React.useCallback((fn, skipSave) => {
    update(draft => {
      const rez = fn(draft.report);

      // eslint-disable-next-line no-param-reassign
      if(rez !== undefined) draft.report = rez;
    });

    if(skipSave || !curDecReport || !currentReleaseId) return;

    updateSingleton.update({
      dispatch,
      getTreeData,
      report: curDecReport,
      releaseId: currentReleaseId,
    });
  }, [update, currentReleaseId, curDecReport, dispatch, getTreeData]);
};

export const useSetReportWithRecalcHeights = (): ReturnType< typeof useSetReport > => {
  const setReport = useSetReport();
  const treeRef = useTreeRef();

  return React.useCallback(recipe => {
    setReport(recipe);

    setTimeout(() => {
      const tree = treeRef.current as any;
      if(get(tree, ['wrappedInstance', 'current', 'recomputeRowHeights'])) {
        tree.wrappedInstance.current.recomputeRowHeights();
      }
    }, 0);
  }, [setReport, treeRef]);
};

const useSetReportDebounced = (): ReturnType< typeof useSetReportWithRecalcHeights > => {
  const setReport = useSetReportWithRecalcHeights();

  return React.useMemo(
    () => debounce< ReturnType< typeof useSetReport > >(setReport, 600),
    [setReport],
  );
};


// ===================================================================================

type Selected = State[ 'selected' ];
export const useSelected = (): Selected => useSelector(s => s.selected);
export const useIsSelectedDefault = (): boolean => useSelector(s => s.selected === defaultState.selected);

export const useSetSelected = (): (recipe: (v: Selected) => Selected | void, propagate?: 'debounced' | 'immediate') => unknown => {
  const update = useUpdate();
  const setReportDebounced = useSetReportDebounced();
  const setReportWithRecalcHeights = useSetReportWithRecalcHeights();
  const derive = useDerive();

  return React.useCallback((fn, propagate) => {
    update(v => {
      const rez = fn(v.selected);

      // eslint-disable-next-line no-param-reassign
      if(rez !== undefined) v.selected = rez;
    });

    if(propagate === undefined) return;


    const selected = derive(s => s.selected);
    if(selected === defaultState.selected) return;

    const setReportFn = propagate === 'debounced' ? setReportDebounced : setReportWithRecalcHeights;

    setReportFn(({ data }) => {
      const { path } = selected._contents;
      const maybeNode = get(data, path);
      if(!maybeNode) return;

      const typedMaybeNode = maybeNode as DecReportTreeData[ 0 ];
      if(typeof typedMaybeNode._contents !== 'object' || typedMaybeNode._contents === null) return;

      const rez = fn(typedMaybeNode);
      if(rez !== undefined) set(data, path, rez);
    });
  }, [update, derive, setReportWithRecalcHeights, setReportDebounced]);
};

export const useResetSelected = (): () => unknown => {
  const setSelected = useSetSelected();

  return React.useCallback(() => setSelected(() => defaultState.selected), [setSelected]);
};
