import React from "react";
import { graphlib } from "dagre";
import { setWith } from "lodash";
import { all, delay, put, race, take, takeLatest, call } from "redux-saga/effects";
import { actions } from "../slice";
import { debugPayloadUtils } from "../../../Debug/EEContext";
import { useDispatch } from "react-redux";
import { onReqToExportRawPayload, onReqToImportRawPayoad } from "./forRaw";
import { SagaActions, sagaACreators, sagaANames } from "./constants";
import type { TreeProps } from "../../DataTree/constants";
import * as forResetNS from "./forReset";

// #region Data Tree utils

type Items = TreeProps[ "items" ];

function sortTreeItemsAsc( item: Items[ 0 ] ): typeof item {
  const { children } = item;

  if (children.length === 0) return item;

  const mappedChildren = children.map(child => sortTreeItemsAsc(child));

  return {
    ...item,
    children: mappedChildren.slice().sort((a, b) => a.info.text.localeCompare(b.info.text))
  };
}

function gatherAllIds(n: Items[ 0 ]): string[] {
  return [n.id].concat(
    n.children.reduce< string[] >((a, child) => a.concat(gatherAllIds(child)), []),
  );
}

// #endregion


export function* saga(): Gen {
  const resetRef: { current: SagaActions[ "requestToEncloseReset" ][ "payload" ][ "reset" ] } = {
    current: console.log
  };

  yield all([
    // eslint-disable-next-line require-yield
    takeLatest(sagaANames.requestToEncloseReset, function* onRequestToSetGoal(a: SagaActions[ "requestToEncloseReset" ]) {
      resetRef.current = a.payload.reset;
    }),
    takeLatest(sagaANames.requestToSetGoal, function* onRequestToSetGoal(a: SagaActions["requestToSetGoal"]) {
      yield put(actions.setGoal(a.payload.goalId));
      resetRef.current();
    }),
    takeLatest(sagaANames.requestToSetGoalIdAfterBatchCall, function* onRequestToSetGoalIdAfterBatchCall() {
      const { timeout, action } = yield race({
        timeout: delay(7_000),
        action: take("DCSVLY/BATCHCALL")
      });
      if (timeout) return;

      yield put(sagaACreators.requestToSetGoal({ goalId: action.payload.goal }));
    }),
    takeLatest("DCSVLY/BATCHCALL_SUCCESS", function* prepareDataTree(action: any) {
      const { graph: __batchGraph } = action.payload;
      yield;

      if (!__batchGraph || !__batchGraph.nodes) return;

      const nodes = __batchGraph.nodes as any[];
      const parsed = graphlib.json.read(__batchGraph);

      // ===================================================================================

      // we just need "some" type to describe nodes. It does not
      // need to be precise, just (kinda) distinct
      type SimpleNode = { id: string, description?: string; derived?: unknown; input?: unknown; };
      type PathBasedTree = {
        [ uuidOrEntityName: string ]: SimpleNode | { [ instanceId: string ]: PathBasedTree }
      }
      const pathBasedTree = nodes.reduce< PathBasedTree >(
        (a, it) => {
          const maybeNode = parsed.node( it.v );
          const shouldOmitNode = Boolean(
            false
            || !maybeNode
            || ( maybeNode as any ).hidden
            || ( maybeNode as any ).identifier
            || parsed.successors( it.v )?.length
          );
          if ( shouldOmitNode ) return a;

          return setWith( a, it.v.split( "/" ), it.value, Object );
        },
        {} as PathBasedTree
      );

      // ===================================================================================

      const pathBasedTreeNodeToItem = (n: PathBasedTree, key: string): Items[ 0 ] => {
        const keys = Object.keys(n);

        return {
          id: key,
          info: { text: key.replace("global/", ""), value: key === "global" ? "entity" : "instance" },
          children: keys.map< Items[ 0 ] >(instanceProp => {
            const node = n[ instanceProp ];

            if ("id" in node) {
              const typedN = node as SimpleNode;

              return {
                id: [ key, node.id ].join( "/" ),
                children: [],
                info: {
                  text: typedN.description || "",
                  value: (typedN.input || typedN.derived || undefined) as any,
                }
              };
            }

            const instanceIds = Object.keys( node );
            const id = [key, instanceProp].join( "/" );

            return {
              id: id,
              info: { text: instanceProp, value: "entity" },
              children: instanceIds.map(
                instanceId => pathBasedTreeNodeToItem( node[ instanceId ], [ id, instanceId ].join( "/" ) )
              )
            };
          })
        };
      };

      const items = sortTreeItemsAsc( {
        id: "",
        children: [ pathBasedTreeNodeToItem( pathBasedTree, "global" ) ],
        info: { text: "" }
      } ).children;

      yield put(actions.setDataTreeItems(items));

      yield put(
        actions.setExpanded(
          gatherAllIds({ id: "", children: items, info: { text: "", value: "" } })
            .filter(Boolean),
        )
      );
    }),
    takeLatest(sagaANames.requestToExportRawPayload, onReqToExportRawPayload),
    takeLatest(sagaANames.requestToImportRawPayload, onReqToImportRawPayoad),
    call(forResetNS.saga)
  ]);
}


export const EncloseResetComp = React.memo(() => {
  const reset = debugPayloadUtils.useReset();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(sagaACreators.requestToEncloseReset({ reset }));
  }, [reset]);

  return null;
});
EncloseResetComp.displayName = "batchDebug/redux/saga/EncloseReset/Comp";
