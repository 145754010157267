
import React from 'react';
import moment from 'moment';
import styled, { createGlobalStyle } from 'styled-components';
import { useDispatch } from 'react-redux';
import { operateHolder } from './Title';
import { START_TIME, END_TIME } from './redux';
import { colors } from './colorScheme';


const classesPrefix = 'singleTemporalTimeline';
export const constantColorClasses = {
  true: `${ classesPrefix }_true`,
  false: `${ classesPrefix }_false`,
  /** unknown */
  undefined: `${ classesPrefix }_undefined`,
  /** uncertain */
  null: `${ classesPrefix }_null`,
};


const shadesOfBlueClasses = [
  `${ classesPrefix }_shadeOfBlue_0`,
  `${ classesPrefix }_shadeOfBlue_1`,
  `${ classesPrefix }_shadeOfBlue_2`,
];

export const Style = createGlobalStyle`
  .${ constantColorClasses.true } { background-color: ${ colors.true }; }
  .${ constantColorClasses.false } { background-color: ${ colors.false }; }
  .${ constantColorClasses.undefined } { background-color: ${ colors.undefined }; }
  .${ constantColorClasses.null } { background-color: ${ colors.null }; }

  .${ shadesOfBlueClasses[ 0 ] } { background-color: ${ colors.shadeOfBlue0 }; }
  .${ shadesOfBlueClasses[ 1 ] } { background-color: ${ colors.shadeOfBlue1 }; }
  .${ shadesOfBlueClasses[ 2 ] } { background-color: ${ colors.shadeOfBlue2 }; }
`;


const TOTAL_WIDTH = 500;

/**
  @typedef IProps
  @type {{
    value: import('../UnifiedTimeline/types').TemporalValue;
    title?: string;
    width?: number;
    usePercentageWidth?: true;
    className?: string;
  }}
  */

/** @typedef { import('../UnifiedTimeline/types').Range } IRange */

/**
  @typedef IMappedRange
  @type {
    & IRange
    & {
      diffInDays: number | '-1'
    }
  }
 */


const TempTimelineWrap = styled.div`
  display: inline-flex;
  cursor: pointer;
  width: 100%;
`;


const Segment = styled.div`
  display: inline-block;
  height: 20;
`;


/** @type { React.FC< IProps > } */
export const TempTimeline = React.memo(({ node, value, width, className, onClick, usePercentageWidth, title }) => {
  const dispatch = useDispatch();
  const effectiveWidth = width || TOTAL_WIDTH;
  const { daysSum, entries } = React.useMemo(() => {
    if (!value || !value.temporal) return {daysSum: 0, entries: []};
    return value.temporal.ranges.reduce(
      (acc, it) => {
        /** @type { IMappedRange } */
        const mapped = {
          ...it,
          diffInDays: (it.r.start === START_TIME || it.r.end === END_TIME)
            ? '-1'
            : moment(it.r.end).diff(moment(it.r.start), 'seconds'),
        };

        return {
          daysSum: mapped.diffInDays === '-1' ? acc.daysSum : acc.daysSum + mapped.diffInDays,
          entries: acc.entries.concat(mapped),
        };
      },
      {
        daysSum: 0,
        entries: /** @type { IMappedRange[] } */ ([]),
      },
    );
  }, [value]);

  const jsx = React.useMemo(() => {
    const { jsxArr } = entries.reduce(
      (a, it, i, arr) => {
        const width = (() => {
          if(it.r.start === START_TIME && it.r.end === END_TIME) return '100%';

          switch(true) {
            case it.r.start === START_TIME && arr[ i + 1 ] !== undefined && arr[ i + 1 ].r.end === END_TIME:
            case it.r.end === END_TIME && arr[ i - 1 ] !== undefined && arr[ i - 1 ].r.start === START_TIME:
              return '50%';
            default:
          }

          switch(true) {
            case it.diffInDays === '-1':
              return usePercentageWidth ? '2%' : `${ 0.02 * effectiveWidth }px`;
            default: {
              const percent = Math.max(it.diffInDays, 1) / daysSum;
              // 96, meaning "full width without start/end segments, that
              // correspong to START_TIME/END_TIME segments and occupy 2%"
              return usePercentageWidth ? `${ (percent * 96) }%` : `${ percent * effectiveWidth }px`;
            }
          }
        })();

        const { className, count } = (() => {
          const value = it.v;
          const defaultRtrn = { className: '', count: a.count };

          switch (value) {
            case false: return { ...defaultRtrn, className: constantColorClasses.false };
            case true: return { ...defaultRtrn, className: constantColorClasses.true };
            case undefined: return { ...defaultRtrn, className: constantColorClasses.undefined };
            case 'Not Valid':
            case null: return { ...defaultRtrn, className: constantColorClasses.null };
            default: {
              const nextCount = a.count + 1;

              return {
                className: shadesOfBlueClasses[ nextCount % shadesOfBlueClasses.length ],
                count: nextCount,
              };
            }
          }
        })();

        const dataValue = typeof it.v === 'object' && it.v !== null && 'increment' in it.v
          ? (() => {
            const { increment, offset } = it.v;
            const offsetText = offset === undefined
              ? ''
              : ` (Offset ${ offset })`;

            return `By ${ increment.replace(/./, ch => ch.toUpperCase()) }${ offsetText }`;
          })()
          : String(it.v);

        return {
          count,
          jsxArr: a.jsxArr.concat(
            <Segment
              key={dataValue + i}
              className={className}
              data-value={dataValue}
              data-start={it.r.start}
              data-end={it.r.end}
              data-title={title}
              style={{
                width,
              }}
            />,
          ),
        };
      },
      {
        count: 0,
        jsxArr: /** @type { JSX.Element[] } */([]),
      },
    );

    return jsxArr;
  }, [entries, daysSum, effectiveWidth]);


  const onMouseMove = React.useCallback(
    (/** @type { React.MouseEvent } */{ clientX: x, clientY: y }) => {
      operateHolder.debouncedOperate(dispatch, { x, y });
    },
    [dispatch],
  );
  const onMouseLeave = React.useCallback(
    () => operateHolder.reset(dispatch),
    [dispatch],
  );


  return (
    <TempTimelineWrap onClick={() => onClick && onClick(node)} onMouseMove={onMouseMove} onMouseLeave={onMouseLeave} className={className}>
      {jsx}
    </TempTimelineWrap>
  );
});
TempTimeline.displayName = 'DecisionDashboard/Reports/Details/TempTimeline';


export * from './Title';
