import produce from 'immer';
import { ReleaseTestCasesNS } from '@packages/commons';
import type { TestCaseSortTItem } from '../../types';
import { predicateFindSortTree } from '../predicateFindSortTree';


export type ToggleExpandedArg = {
  sortTreeRoot: TestCaseSortTItem;
  dataTreeRoot: ReleaseTestCasesNS.TestCaseTItem;
  id: ReleaseTestCasesNS.TestCaseTItem[ 'id' ];
};
export type ToggleExpandedRtrn = ToggleExpandedArg[ 'sortTreeRoot' ];


export const toggleExpanded = ( { id, dataTreeRoot, sortTreeRoot }: ToggleExpandedArg ): ToggleExpandedRtrn => {
  return produce( sortTreeRoot, draft => {
    const maybeDataTItem = ReleaseTestCasesNS.utilsNS.tCasesFindById({ rootItem: dataTreeRoot, id });
    if(maybeDataTItem === null || maybeDataTItem.type !== 'folder') return;

    const maybeSortTItem = predicateFindSortTree({ treeItem: draft, predicate: it => it.__testCaseTreeItemId === id });
    if ( maybeSortTItem === null ) return;

    maybeSortTItem.expanded = !maybeSortTItem.expanded;
  } );
}
