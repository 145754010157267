import { put, select } from "redux-saga/effects";
import { showNotification } from "@imminently/imminently_platform";
import { actions, getState } from "../slice";
import type { Scope } from "../../../../../../redux/scope";
import type { SagaActions } from "./constants";


type Payload = {
  goal: string;
  data: Record< string, unknown >;
}
type FullSerializedPayload = Payload & {
  workspaceId: string;
  projectId: string;
  releaseId: string;
}
type FullSerializedPayloadLike = {
  [ key in keyof FullSerializedPayload ]?: unknown
};

type ValidatePayloadRtrn = (
  | true
  | "Invalid workspace id"
  | "Invalid project id"
  | "Invalid release id"
  | "Invalid goal id"
  | "Invalid data payload"
)
const validatePayload = (v: FullSerializedPayloadLike): ValidatePayloadRtrn => {
  const { data, goal, projectId, releaseId, workspaceId } = v;

  if (typeof workspaceId !== "string") return "Invalid workspace id";
  if (typeof projectId !== "string") return "Invalid project id";
  if (typeof releaseId !== "string") return "Invalid release id";
  if (typeof goal !== "string") return "Invalid goal id";
  if (typeof data !== "object" || data === null) return "Invalid data payload";

  return true;
};


export function* onReqToExportRawPayload( a: SagaActions[ "requestToExportRawPayload" ]) {
  const { payload: { fileName, hideModal, payload } } = a;

  const storeState = yield select();
  const { workspace, project, release } = storeState.scope as Scope;
  if ( !workspace || !project || !release ) return;
  let parsedState;
  if (payload) {
    parsedState = payload;
  } else {
    const { state } = getState(storeState);

    parsedState = (() => {
      try {
        return JSON.parse(state);
      } catch {
        return {};
      }
    })();
  }
  const payloadLike: FullSerializedPayloadLike = {
    ...parsedState,
    workspaceId: workspace,
    projectId: project,
    releaseId: release
  };

  const validationRes = validatePayload(payloadLike);
  if (validationRes !== true) {
    yield put(showNotification(validationRes, "error"));

    return;
  }

  const blob = new Blob([JSON.stringify(payloadLike, null, 2)], { type: "application/json" });
  const href = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = href;
  link.download = `${ fileName }.json`;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  yield put(hideModal());
}

export function* onReqToImportRawPayoad( a: SagaActions[ "requestToImportRawPayload" ]) {
  const { payload: { value } } = a;

  type Rtrn = Exclude< ValidatePayloadRtrn, true > | FullSerializedPayload | "incorrectPayload";
  const processRes = ((): Rtrn => {
    const defaultErrRtrn: Rtrn = "incorrectPayload";

    try {
      const parsed = JSON.parse( value );
      if (typeof parsed !== "object" || parsed === null) return defaultErrRtrn;

      const validateRes = validatePayload(parsed);
      return validateRes === true ? parsed as FullSerializedPayload : validateRes;

    } catch {
      return defaultErrRtrn;
    }
  })();

  if (typeof processRes !== "object") {
    yield put(showNotification(processRes === "incorrectPayload" ? "Incorrect payload" : processRes, "error"));

    return;
  }

  const storeState = yield select();
  const { workspace, project, release } = storeState.scope as Scope;
  const maybeMismatchStr = ((): string | false => {
    if (workspace !== processRes.workspaceId) return "Workspace mismatch";
    if (project !== processRes.projectId) return "Project mismatch";
    if (release !== processRes.releaseId) return "Release mismatch";

    return false;
  })();

  if (maybeMismatchStr !== false) {
    yield put(showNotification(maybeMismatchStr, "error"));

    return;
  }

  const val: Payload = { goal: processRes.goal, data: processRes.data, };
  yield put(actions.setState(JSON.stringify(val, null, 2)));
}
