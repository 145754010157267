
import { withStyles } from '@material-ui/core/styles';
import Switch, { SwitchProps } from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';


const AntSwitchC = withStyles(theme => ({
  root: {
    width: 48,
    height: 24,
    padding: 0,
    display: 'flex',
    '& .MuiButtonBase-root:hover': {
      backgroundColor: 'transparent',
    },
  },
  switchBase: {
    padding: 4,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(24px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const withSmallSwitchClsn = 'withSmallSwitch';
const invertedClsnm = 'inverted';
const reverseOrderClsnm = 'reverseOrder';

const StyledFormGroup = styled(FormGroup)`
  .MuiFormControlLabel-root {
    margin: 0;
    gap: 1rem;
  }
  /* .MuiFormControlLabel-label{ margin-right: 1rem; } */

  &.${withSmallSwitchClsn} {
    .MuiSwitch-root {
      height: 1rem;
      width: 2rem;

      .MuiButtonBase-root {
        padding: 3px 4px;

        &.Mui-checked {
          transform: translateX(14px);
        }
      }

      .MuiSwitch-thumb {
        width: 0.6rem;
        height: 0.6rem;
      }
    }
  }

  &.${invertedClsnm} {
    .MuiButtonBase-root.Mui-checked {
      .MuiSwitch-thumb { background-color: white; }

      & + .MuiSwitch-track.MuiSwitch-track {
        background-color: black;
        border-color: black;
      }
    }
  }

  &.${reverseOrderClsnm} .MuiFormControlLabel-root {
    flex-direction: row;
  }
`;

export type IProps = {
  label: React.ReactNode;
  className?: string;
  smallSwitch?: true;
  invertOnCheck?: true;
  reverseOrder?: true
  checked?: boolean;
  onChange?: import('@material-ui/core/Switch').SwitchProps['onChange'];
  antSwtichProps?: Partial<SwitchProps>;
};


export const AntSwitch: React.FC<IProps> = React.memo(p => {
  const {
    label,
    className,
    smallSwitch,
    invertOnCheck,
    reverseOrder,
    checked,
    onChange,
    antSwtichProps,
  } = p;

  return (
    <StyledFormGroup
      className={clsx(
        className,
        smallSwitch && withSmallSwitchClsn,
        invertOnCheck && invertedClsnm,
        reverseOrder && reverseOrderClsnm,
      )}
    >
      <FormControlLabel labelPlacement='start' control={<AntSwitchC {...{ checked, onChange, ...antSwtichProps }} />} label={label} />
    </StyledFormGroup>
  );
});
AntSwitch.displayName = 'Components/AntSwitch';
