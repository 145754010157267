import {
  put,
  call,
  all,
  takeLatest,
  select,
} from 'redux-saga/effects';
import { GET_ONE, showNotification } from '@imminently/imminently_platform';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { deriveFileName } from "./export.saga.deriveFilename";
import { notify } from '@common/notifications';

export const exportModel = (id, options) => ({
  type: Actions.EXPORT_MODEL,
  id,
  options
});

export const exportRelease = (id, options) => ({
  type: Actions.EXPORT_RELEASE,
  id,
  options
});

export const exportDocument = (id, options) => ({
  type: Actions.EXPORT_DOCUMENT,
  id,
  options
});


export const exportTestData = (id) => ({
  type: Actions.EXPORT_TESTDATA,
  id
});

export const useExport = id => useSelector(s => get(s, `export.${id}`));

const Actions = {
  EXPORT_MODEL: 'DCSVLY/EXPORT_MODEL',
  EXPORT_RELEASE: 'DCSVLY/EXPORT_RELEASE',
  EXPORT_TESTDATA: 'DCSVLY/EXPORT_TESTDATA',
  EXPORT_START: 'DCSVLY/EXPORT_START',
  EXPORT_END: 'DCSVLY/EXPORT_END',
  EXPORT_ERROR: 'DCSVLY/EXPORT_ERROR',
  EXPORT_DOCUMENT: 'DCSVLY/EXPORT_DOCUMENT',
};

const handleDownload = async (data, fileName) => {
  const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
  const href = await URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = `${fileName}.json`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};


const handleExport = function* handleExport(dataProvider, authProvider, action) {
  const toastId = notify('Exporting...', { autoClose: false, isLoading: true });
  try {
    let resource;
    let payload = { id: action.id };
    if (action.options) payload = { ...payload, ...action.options }
    switch (action.type) {
      case Actions.EXPORT_MODEL:
        resource = 'models';
        break;
      case Actions.EXPORT_RELEASE:
        resource = 'releases';
        break;
      case Actions.EXPORT_TESTDATA:
        resource = 'scenarios';
        break;
      case Actions.EXPORT_DOCUMENT:
        resource = 'documents';
        break;
      default:
        throw new Error(`Invalid action type for export: ${action.type}`);
    }
    console.log('resource in handleExport', resource);


    yield put({
      type: Actions.EXPORT_START,
      id: action.id,
    });

    const response = yield call(
      dataProvider,
      GET_ONE,
      resource,
      payload,
      {
        customAction: 'export',
      },
      authProvider,
    );

    delete response.data.id;
    let fileName;
    if (action.type === Actions.EXPORT_DOCUMENT) {
      fileName = action.options?.name ? action.options.name : action.id;
    } else {
      const storeState = yield select();
      fileName = yield call(
        deriveFileName,
        {
          id: action.id,
          type: resource,
          storeState,
          fallbackProjectName: action.options ? action.options.fallbackProjectName : undefined,
        }
      );
    }

    notify.update(toastId, { render: 'Complete', isLoading: false, type: 'success', autoClose: 3000 });

    yield call(handleDownload, response.data, fileName);

    yield put({
      type: Actions.EXPORT_END,
      id: action.id,
    });
  } catch (error) {
    const msg = typeof error === 'string' ? error : error.message;
    if (msg) {
      notify.update(toastId, { render: msg, isLoading: false, type: 'error', autoClose: 5000 });
      // yield put(showNotification(msg, 'error'));
    }

    yield put({
      type: Actions.EXPORT_ERROR,
      id: action.id,
    });
  }
};

export function* exportSaga(authProvider, dataProvider) {
  yield all([
    takeLatest([Actions.EXPORT_MODEL, Actions.EXPORT_RELEASE, Actions.EXPORT_TESTDATA, Actions.EXPORT_DOCUMENT], handleExport, dataProvider, authProvider),
  ]);
}
