import React from "react";
import { flattenObject, objectToArray } from "../../components/util/UtilObj";
import { useCurrentRelease } from "../hooks";
import { useNotify } from "./HooksNotification";
import { useRouting } from "./HooksRouting";

/** Trims the extension from a document name, html tags and trims whitespace */
export const trimDocName = (name: string) =>
  name
    .replace(/\.(docx|xlsx)$/i, "")
    .replace(/<[^>]*>/g, "") // remove html tags
    .trim();

/** A hook to allow navigating to a document based on path only */
export const useNavigateToDocument = () => {
  const release = useCurrentRelease();
  const flatDocs = objectToArray(flattenObject(release?.documents || {}, "reference"));
  const { goToPage } = useRouting();
  const { error } = useNotify();

  return (path: string, newWind = false) => {
    // first, find the document
    const document = flatDocs.find((doc) => path === doc.name);
    if (document) {
      // then navigate to it
      if (newWind) {
        window.open(
          `/doc/${document.reference}`,
          "_blank",
          "scrollbars=yes,resizable=yes,top=500,left=500,width=1280,height=720",
        );
      } else {
        goToPage("/build", { documentPath: path, documentId: document.reference });
      }
    } else {
      error(`Could not find document for ${path}. It has likely been renamed or deleted.`);
    }
  };
};

export const useDocuments = () => {
  const release = useCurrentRelease();
  const flatDocs = objectToArray(flattenObject(release?.documents || {}, "reference"));
  const { goToPage } = useRouting();

  // --

  const navigateToDocument = (documentPath: string, documentId: string, newWind: boolean, postAction?: () => void) => {
    if (documentPath && documentId) {
      console.log(`Navigate to doc ${documentPath} (${documentId}) in ${newWind ? "new" : "same"} window`);

      if (newWind) {
        window.open(
          `/doc/${documentId}`,
          "_blank",
          "scrollbars=yes,resizable=yes,top=500,left=500,width=1280,height=720",
        );
      } else {
        goToPage("/build", { documentPath, documentId });
      }

      if (postAction) {
        postAction();
      }
    }
  };

  const navigateToDocumentUsingPath = (documentFullPath: string, newWind: boolean, postAction?: () => void) => {
    if (documentFullPath) {
      // const documentName = documentFullPath.substring(documentFullPath.lastIndexOf("/") + 1);
      // const documentPath = documentFullPath.substring(0, documentFullPath.lastIndexOf("/"));
      const fullDocPathNoExt = documentFullPath.substring(0, documentFullPath.lastIndexOf("."));
      const documentId = flatDocs.find(
        (doc) => fullDocPathNoExt === doc.name || documentFullPath.startsWith(doc.name),
      )?.reference;
      if (documentId) {
        navigateToDocument(documentFullPath, documentId, newWind, postAction);
      }
    }
  };

  // --

  return {
    navigateToDocument,
    navigateToDocumentUsingPath,
    flatDocs,
  };
};
