/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  crudGetList,
  crudGetOne,
  hideModal,
  crudDelete,
  CRUD_DELETE_SUCCESS,
  CRUD_GET_LIST_SUCCESS,
  showNotification,
  // @ts-ignore
} from '@imminently/imminently_platform';
import { all, takeLatest, select, put, call, race, delay, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  decisionReportsLocation,
  decisionReportsResource,
  dedicatedPageDecReportsLocation,
  pathnameContainsReportUUID,
} from './constants';
import * as redux from './reducer';
import { getState } from './reducer_getState';
import * as combinatorV2 from '../../../redux/combinatorV2.saga';
import { SET_RELEASE } from '../../../redux/scope';
import { saga as interactiveSaga } from '../Interactive/redux/saga';


type Gen = Generator<any, any, any>;
type GenRtrn<T extends () => Generator> = T extends (() => Generator<any, infer R, any>) ? R : never;

const sort = { field: 'lastModified', order: 'desc' };

interface IFilter {
  type: string[];
  model?: string;
  release?: string;
  'batch'?: string;
  'test'?: string;
}

function* maybeGetFilter(): Generator<any, IFilter | null, any> {
  const state = yield select();
  const { project, release } = state.scope;
  if (!project || !release) return null;

  const filter = ((): IFilter => {
    const { search, testRunForBatchTestReports, testScenario } = getState(state);
    const maybeTestScenarioObj = state.resources?.scenarios?.data?.[ String(testScenario) ];

    if (!document.location.pathname.match(/verify/)) {
      const withType = {
        type: ['INTERVIEW_FINISHED', 'BATCH_JOB_FINISHED'],
      };

      const withTypeAndSearch = search === '' ? withType : {
        ...withType,
        reportId: `*${ search }*`,
      };

      return withTypeAndSearch;
    }

    const withType = { type: ['TEST_JOB_FINISHED'] };

    return {
      ...withType,
      batch: testRunForBatchTestReports || undefined,
      test: maybeTestScenarioObj?.name || undefined,
    };
  })();

  if (project) filter.model = project;
  if (release) filter.release = release;

  return filter;
}
type MaybeGetFilterRtrn = GenRtrn<typeof maybeGetFilter>;

const FIELDS = ['type', 'lastModified', 'status', 'processingTime', 'id', 'outcome', 'test', 'reportId', 'batch', 'test_result', 'interviewTitle', 'goalDescription', 'user'];

export function* saga(): Gen {
  yield all([
    takeLatest([
      redux.ActionTypes.requestToLoadReportsForBatchTest,
      redux.ActionTypes.requestToLoadReportsForReporting,
    ], function* loadDecisionReports(): Gen {
      const maybeFilter: MaybeGetFilterRtrn = yield call(maybeGetFilter);
      if (maybeFilter === null) return;


      yield put(crudGetList(
        decisionReportsResource,
        decisionReportsLocation,
        {
          filter: maybeFilter,
          sort,
          fields: FIELDS,
        },
      ));
    }),

    takeLatest(redux.ActionTypes.requestToLoadPage, function* loadDecisionReportsPage(
      a: redux.IActions['requestToLoadPage'],
    ): Gen {
      const state = yield select();
      const {
        params: {
          sort,
          filter,
          pagination,
        },
      } = state.resources[ decisionReportsResource ][ decisionReportsLocation ].default.list;

      const maybeFilter: MaybeGetFilterRtrn = yield call(maybeGetFilter);
      if (maybeFilter === null) return;


      yield put(crudGetList(
        decisionReportsResource,
        decisionReportsLocation,
        {
          sort,
          filter,
          fields: FIELDS,
          pagination: {
            ...pagination,
            page: a.payload.index + 1,
          },
        },
      ));
    }),

    takeLatest(redux.ActionTypes.requestToSetPerPage, function* loadDecisionReportsPage(
      a: redux.IActions['requestToSetPerPage'],
    ): Gen {
      const state = yield select();
      const {
        params: {
          sort,
          filter,
        },
      } = state.resources[ decisionReportsResource ][ decisionReportsLocation ].default.list;

      const maybeFilter: MaybeGetFilterRtrn = yield call(maybeGetFilter);
      if (maybeFilter === null) return;


      yield put(crudGetList(
        decisionReportsResource,
        decisionReportsLocation,
        {
          sort,
          filter,
          fields: FIELDS,
          pagination: {
            perPage: a.payload,
            page: 1,
          },
        },
      ));
    }),

    takeLatest(
      redux.ActionTypes.requestToSetTestRunForBatchTestReports,
      function* onRequestToSetTestRunForBatchTestReports(
        a: redux.IActions['requestToSetTestRunForBatchTestReports'],
      ): Gen {
        yield put(redux.ActionCreators.setTestRunForBatchTestReports(a.payload));
        yield put(redux.ActionCreators.requestToLoadReportsForBatchTest());
      },
    ),
    takeLatest(
      redux.ActionTypes.requestToSetTestScenario,
      function* onRequestToSetTestScenario(
        a: redux.IActions['requestToSetTestScenario'],
      ): Gen {
        yield put(redux.ActionCreators.setTestScenario(a.payload));
        yield put(redux.ActionCreators.requestToLoadReportsForBatchTest());
      },
    ),

    takeLatest(
      redux.ActionTypes.requestToLoadDecisionReport,
      function* onRequestToLoadDecisionReport(
        a: redux.IActions['requestToLoadDecisionReport'],
      ): Gen {
        const id = a.payload;
        yield put(crudGetOne(decisionReportsResource, id));
        yield put(redux.ActionCreators.setDecisionReportId(id));
      },
    ),

    takeLatest(
      redux.ActionTypes.requestToRemoveTestScenario,
      function* onRequestToRemoveTestScenario(
        a: redux.IActions['requestToRemoveTestScenario'],
      ): Gen {
        const { scope: { release } } = yield select();

        yield put(
          combinatorV2.ActionCreator({
            bootstrap: crudDelete('scenarios', a.payload),
            success: {
              type: CRUD_DELETE_SUCCESS,
              // type: '*',
              validate: v => (v as any).meta.resource === 'scenarios',
              // validate: () => true,
              dispatch: crudGetList(
                'scenarios',
                'default',
                // { filter: { release }, pagination: { page: 1, perPage: 1 } },
                { filter: { release }, pagination: { page: 1, perPage: 10 } },
              ),
            },
            asFinally: { dispatch: hideModal() },
          }),
        );
      },
    ),
    takeLatest(
      redux.ActionTypes.requestToSetSearchAndLoadReports,
      function* onReqToSetSearchAndLoadReports(a: redux.IActions['requestToSetSearchAndLoadReports']): Gen {
        yield put(redux.ActionCreators.setSearch(a.payload));
        yield put(redux.ActionCreators.requestToLoadReportsForReporting());
      },
    ),
    takeLatest(
      redux.ActionTypes.requestToGetReportIdFromURL,
      function* onRequestToGetReportIdFromURL(): Gen {
        const state = yield select();

        type Rtrn = null | string | { redirectToMonitor: true };

        const rtrn: Rtrn = yield call(function* initFromURL(): Generator< any, Rtrn, any > {
          const maybeReportId = pathnameContainsReportUUID();
          if(maybeReportId === false) {
            // we are not on '/monitor' or we don't have UUID
            return null;
          }

          yield put(redux.ActionCreators.setTableCollapsed(true));
          yield put(crudGetList(
            decisionReportsResource,
            dedicatedPageDecReportsLocation,
            {
              pagination: { page: 1, perPage: 1 },
              filter: { reportId: maybeReportId },
            },
          ));

          const rez: { timeout?: any; rez?: any } = yield race({
            timeout: delay(10_000),
            rez: call(function* () {
              while(true) {
                const a: any = yield take(CRUD_GET_LIST_SUCCESS);

                if(a.meta.location === dedicatedPageDecReportsLocation) return a;
              }
            }),
          });
          if(rez.timeout) {
            // something went wrong, should redirect to "/monitor"
            return { redirectToMonitor: true };
          }

          const nextDecReportId = rez?.rez?.payload?.data?.[ 0 ]?.id || null;
          if(!nextDecReportId) {
            // something went wrong, should redirect to "/monitor"
            return { redirectToMonitor: true };
          }

          yield put(redux.ActionCreators.requestToLoadDecisionReport(nextDecReportId));

          // return report id
          return String(nextDecReportId);
        });

        if(typeof rtrn === 'string') {
          // we are on '/monitor/xxxxxxxxxxx' and we loaded specicif report
          // bail out
          return;
        }

        if(rtrn !== null) {
          yield put(showNotification('Couldn\'t load specific report', 'error'));
          yield put(push('/monitor'));

          return;
        }

        const { release: releaseId, project: projectId } = state.scope;
        if(projectId && releaseId) {
          yield put(redux.ActionCreators.requestToLoadReportsForReporting());
        }
      },
    ),
    takeLatest(
      SET_RELEASE,
      function* onSetRelease() {
        yield put(redux.ActionCreators.setTestScenario(null));
        yield put(redux.ActionCreators.setTestRunForBatchTestReports(null));
      },
    ),
    interactiveSaga(),
  ]);
}
