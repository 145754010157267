import { Label } from "@components";
import { FlagIcon } from "@icons";
import { statusOptions, priorityColor, priorityOptions, statusColor } from "./taskUtils";
import styles from "./tasks.module.scss";
import { TaskPriority, TaskStatus } from "@packages/commons";

// allowing generic string as TS is too annoying to bother with this

export const StatusLabel = ({ status }: { status: TaskStatus }) => (
  <Label className={styles.label} color={statusColor[status]}>
    {statusOptions.find(o => o.value === status)?.label ?? status}
  </Label>
);

export const PriorityLabel = ({ priority }: { priority: TaskPriority }) => (
  <>
    <FlagIcon style={{ color: priorityColor[priority] }} />
    <span>{priorityOptions.find(p => p.value === priority)?.label ?? priority}</span>
  </>
);